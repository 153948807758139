import { ref_Indexables, ref_IndexablesId } from "./ref_Indexables.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_SourcesId = rid;

export class ref_Sources extends V implements ref_Indexables {
	"@rid"?: ref_SourcesId;
	URI: string;

}