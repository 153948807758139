import * as React from "react";
import { getIcon, VertexAutocomplete } from "adwone-lib";
import { ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import moment from "moment";
import { Chip, Grid, GridSize } from "@material-ui/core";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import history from "../../../utils/history";
import { Client } from "hub-lib/client/client.bin";
import { ClassProperty } from "hub-lib/models/VertexProperty.bin";

let timeout: any = null;

export class TProps {
    table?: string = ref_Messages.name;
    urlToRedirect?: string = "/messages";
}

export function FilterConfigurationWidget({
    table = ref_Messages.name,
    urlToRedirect = "/messages",
}: TProps) {
    const [config, setConfig] = React.useState<ref_FilterConfigurations>();

    const canBeDisplay = (name: string, filter: string | string[]): boolean => {
        if (name === "ViewMode" || name === "End" || (Array.isArray(filter) && !filter.length)) {
            return false;
        }
        return true;
    };

    const [metaData, setMetaData] = React.useState<ClassProperty[]>(null);
    const fetchData = async (): Promise<void> => {
        const data = (await Client.getMetadata(table)).data.results;
        setMetaData(data);
    };

    const [gridSize, setGridSize] = React.useState<GridSize>(4);
    const ref = React.useRef(null);
    const resize = () => {
        if (ref.current) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const size = ref.current.clientWidth;
                if (size > 600) {
                    setGridSize(3);
                } else if (size < 300) {
                    setGridSize(6);
                } else {
                    setGridSize(4);
                }
            }, 100);
        }
    }
    window.removeEventListener('resize', resize);
    window.addEventListener('resize', resize);

    React.useEffect(() => {
        if (!metaData) {
            fetchData();
        }
        resize();
    });

    return (
        <div style={{ height: "calc(100% - 100px)", marginTop: 50, marginBottom: 50 }}>
            <VertexAutocomplete
                disableClearable={true}
                key={`ref_FilterConfigurations-select`}
                label={Trad("filter_model")}
                type={ref_FilterConfigurations.name}
                params={{
                    [propertyOf<ref_FilterConfigurations>("Table")]: table,
                    properties: ["*", "Filters.AdvertiserGroup.Name as AdvertiserGroupName"],
                    Active: true,
                }}
                onChange={(conf: ref_FilterConfigurations) => {
                    setConfig(conf);
                }}
            />
            {config && (
                <>
                    <div  ref={ref} style={{ marginTop: 10, overflowY: "auto", height: "calc(100% - 50px)" }}>
                        <Grid container item xs={12}>
                            {Object.keys(config.Filters)
                                .filter((key) => canBeDisplay(key, config.Filters[key]))
                                .map((key) =>
                                    key === "Start" ? (
                                        <ConfigurationDisplay
                                            name={Trad("date")}
                                            date={{ Start: config.Filters["Start"], End: config.Filters["End"] }}
                                            gridSize={gridSize}
                                        />
                                    ) : (
                                        <ConfigurationDisplay
                                            name={key}
                                            filter={config.Filters[key]}
                                            metaData={metaData}
                                            gridSize={gridSize}
                                        />
                                    )
                                )}
                        </Grid>
                    </div>
                    <div style={{ position: "absolute", bottom: 0, right: 0, padding: 10 }}>
                        <CustomButton
                            startIcon={getIcon("next")}
                            Label={Trad(`go_to_${table}`)}
                            className="custom_btn_primary"
                            onClick={() => {
                                history.push(`${urlToRedirect}?config=${config["@rid"]}`);
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

class TPropsConfigurationDisplay {
    name: string | "Date";
    filter?: string | string[];
    date?: { Start: Date; End?: Date };
    metaData?: ClassProperty[];
    gridSize: GridSize
}

function ConfigurationDisplay({ name, filter, date, metaData, gridSize }: TPropsConfigurationDisplay) {
    const [open, setOpen] = React.useState(false);

    const [isOverflow, setIsOverflow] = React.useState(null);
    const refParent = React.useRef(null);
    const ref = React.useRef(null);

    const [toDisplay, setToDisplay] = React.useState<string[]>([]);

    const fetchData = async (ids: string[]): Promise<void> => {
        const meta = metaData.find((e) => e.name === name);
        if (meta.linkedClass) {
            const find = await Client.searchVertex(meta.linkedClass, { "@rid": ids });
            setToDisplay(find.data.results.map((e) => e.Name));
        } else {
            setToDisplay(ids);
        }
    };

    React.useEffect(() => {
        if (ref.current && refParent.current) {
            setIsOverflow(isOverflow || ref.current.clientHeight > refParent.current.clientHeight);
        }
        if (!toDisplay.length && metaData) {
            fetchData(Array.isArray(filter) ? [...filter] : [filter]);
        }
    });

    return (
        <Grid item xs={gridSize} style={{ paddingBottom: 20 }} key={`grid-${gridSize}`}>
            <span className="subtitle-CustomLayout">
                {Trad(metaData?.find((e) => e.name === name)?.linkedClass ?? name)}
            </span>
            {date ? (
                <div>
                    <div>{Trad("from") + ": " + moment(date.Start).format("DD/MM/YYYY")}</div>
                    <div>{Trad("to") + ": " + moment(date.End).format("DD/MM/YYYY")}</div>
                </div>
            ) : (
                <div ref={refParent} className={`container-chip-widget ${open ? "open" : ""}`}>
                    <div ref={ref}>
                        {toDisplay.map((s: string) => (
                            <Chip variant="outlined" label={s.toString()} className="chip-element chip-widget" />
                        ))}
                    </div>
                </div>
            )}
            {isOverflow && (
                <div style={{ position: "relative" }}>
                    <span style={{ position: "absolute", right: "50%" }} onClick={() => setOpen(!open)}>
                        {open ? getIcon("less") : getIcon("more")}
                    </span>
                </div>
            )}
        </Grid>
    );
}
