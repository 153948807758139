
import { CreateIndicateur, Indicateur } from "adwone-engine/index.bin";
import { GetCellTemplate } from "format-lib/index.bin";
import { Client } from "hub-lib/client/client.bin";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { extractSub, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { Trad, TradProp, TradValue } from "trad-lib";
import { RootState } from "../../../redux/store";
import history from "../../../utils/history";
import { AnchorRow, Row, Title } from "../../Tools";
import { PanelDisable, SubTitle } from "../Generic/Common.bin";
import { DiffusionValidator, OfferValidatior, PricingValidatior } from "./MessageEditorValidators";

type MessageEditorOverview = { children?: any }
export function MessageEditorOverview({ children }: MessageEditorOverview) {
    return <div className='MessageEditorOverview'>
        {children}
        <CampaignOverview />
        <OfferOverview />
        <PricingOverview />
        <MetricsOverview />
        <DiffusionOverview />
    </div>;
}

function CampaignOverview() {

    const [campaign, setCampaign] = React.useState<ref_Campaigns['Name']>('');
    const [advertisergroup, setAdvertisergroup] = React.useState<ref_AdvertiserGroups['Name']>('');
    const [advertiser, setAdvertiser] = React.useState<ref_Advertisers['Name']>('');
    const [brand, setBrand] = React.useState<ref_Brands['Name']>('');
    const [product, setProduct] = React.useState<ref_Products['Name']>('');
    const [group, setGroup] = React.useState<ref_Groups['Name']>('');

    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign', 'Group', 'AdvertiserGroup', 'Advertiser', 'Brand', 'Product']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_Campaigns, setter: setCampaign, value: message.Campaign },
        { type: ref_AdvertiserGroups, setter: setAdvertisergroup, value: message.AdvertiserGroup },
        { type: ref_Advertisers, setter: setAdvertiser, value: message.Advertiser },
        { type: ref_Brands, setter: setBrand, value: message.Brand },
        { type: ref_Products, setter: setProduct, value: message.Product },
        { type: ref_Groups, setter: setGroup, value: message.Group }
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <Row>
        <SubTitle anchor='#campaignpicker'>{Trad('Campaign')}</SubTitle>
        <Field field='Campaign' value={campaign} anchor='#campaignpicker' />
        {!Boolean(message?.Group)
            ? <>
                <Field field='AdvertiserGroup' value={advertisergroup} anchor='#campaignpicker' />
                <Field field='Advertiser' value={advertiser} anchor='#campaignpicker' />
                <Field field='Brand' value={brand} anchor='#campaignpicker' />
                <Field field='Product' value={product} anchor='#campaignpicker' />
            </>
            : <>
                <Field field='Group' value={group} anchor='#campaignpicker' />
            </>}

    </Row>;
}

function OfferOverview() {

    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Support', 'BroadcastArea', 'Format', 'Placement', 'Start', 'End', 'Campaign']), JSONEqualityComparer) ?? {};

    const [support, setSupport] = React.useState<ref_Supports['Name']>('');
    const [broadcastArea, setBroadcastArea] = React.useState<ref_BroadcastAreas['Name']>('');
    const [placement, setPlacement] = React.useState<ref_Property['Name']>('');
    const [format, setFormat] = React.useState<ref_Property['Name']>('');

    const propCheck = [
        { type: ref_Supports, setter: setSupport, value: message.Support },
        { type: ref_BroadcastAreas, setter: setBroadcastArea, value: message.BroadcastArea },
        { type: ref_Property, setter: setPlacement, value: message.Placement },
        { type: ref_Property, setter: setFormat, value: message.Format }
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <OfferValidatior>
        <Row>
            <SubTitle anchor='#offerpicker'>{Trad('Offer')}</SubTitle>
            <Field field='Support' value={support} anchor='#offerpicker' />
            <Field field='BroadcastArea' value={broadcastArea} anchor='#offerpicker' />
            <Field field='Start' value={GetCellTemplate(ePropType.Date)(message.Start)} anchor='#parutiondetailspicker' />
            <Field field='End' value={GetCellTemplate(ePropType.Date)(message.End)} anchor='#parutiondetailspicker' />
            <Field field='Format' value={TradValue(ref_Property.name, "Name", format)} anchor='#parutiondetailspicker' />
            <Field field='Placement' value={TradValue(ref_Property.name, "Name", placement)} anchor='#parutiondetailspicker' />
        </Row>
    </OfferValidatior>;
}

const priceFormater = GetCellTemplate(ePropType.Double);
function PricingOverview() {

    const [currency, setCurrency] = React.useState<ref_Currencies['Name']>('');
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['KPIs', 'Currency']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_Currencies, setter: setCurrency, value: message.Currency },
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <PricingValidatior>
        <Row>
            <SubTitle anchor='#pricingpicker'>{Trad('Pricing')}</SubTitle>
            <Field field='Currency' value={currency} anchor='#offerpicker' />
            <Field field={'KPIs.Gross' as any} value={priceFormater(message.KPIs.Gross)} anchor='#pricingpicker' />
            <Field label={Trad("net_space")} field={'KPIs.NetCO' as any} value={priceFormater(message.KPIs.NetCO)} anchor='#pricingpicker' />
            <ComputedIndicatorField name="netCoFTHono" />
            <Field field={'KPIs.TotalCO' as any} value={priceFormater(message.KPIs.TotalCO)} anchor='#pricingpicker' />
            <ComputedIndicatorField name="total_frais" />
        </Row>
    </PricingValidatior>;
}

function ComputedIndicatorField({ name }: { name: string }) {
    const indicateurName = Trad(name);
    const message = useSelector((root: RootState) => root.messageEditor.getMessage(), JSONEqualityComparer);
    const [indicateur, setIndicateur] = React.useState<Indicateur>(null);
    const [value, setValue] = React.useState<string>("");

    React.useEffect(() => {
        IndicateursProvider.GetInstance().Provide()
            .then(indicateurs => indicateurs.find(i => i.indicateur.name == indicateurName)?.indicateur)
            .then(_indicateur => setIndicateur(CreateIndicateur(_indicateur)))
    }, [Boolean(indicateur)]);


    React.useEffect(() => {
        if (indicateur && message)
            Promise.resolve(indicateur.Compute([message]))
                .then(res => setValue(priceFormater(res)));
    })

    // priceFormater((Math.round(message.KPIs.TotalCO * 100 - message.KPIs.NetCO * 100) / 100).toFixed(2))
    return <Field label={indicateurName} value={value} anchor='#pricingpicker' />;
}


function MetricsOverview() {
    return <PricingValidatior>
        <Row>
            <SubTitle anchor='#metricspicker'>{Trad('metrics')}</SubTitle>
        </Row>
    </PricingValidatior>;
}

function DiffusionOverview() {
    return <DiffusionValidator>
        <Row>
            <SubTitle anchor='#diffusionpicker'>{Trad('Diffusion')}</SubTitle>
        </Row>
    </DiffusionValidator>;
}

type FieldProps = {
    field?: keyof ref_Messages
    value: string
    anchor?: string
    label?: string
}

function Field({ field, value, anchor, label }: FieldProps) {
    return <Element label={label ?? TradProp(field, ref_Messages)} value={value} anchor={anchor} />;
}

type ElementProps = {
    label: string
    value: any
    anchor?: string
}

function Element({ label, value, anchor }: ElementProps) {
    return <div className='overview-container'>
        <AnchorRow anchor={anchor}>
            <span className={`overview-field`} >
                {label + ': '}
            </span>
            {value}
        </AnchorRow>
    </div>;
}