import * as React from "react";
import { SortDescriptor } from "@progress/kendo-data-query";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin"
import { AdwRow, TooltipManager } from "adwone-lib/index";
import { MessagesDialog } from './MessagesDialog.bin'
import Loader from "../../layout/Loader";
import { Client } from "hub-lib/client/client.bin";
import { GetSort } from "format-lib/index.bin";
import { Notify, NotifyError } from "../../../utils/Notify.bin";
import { GetCurrentLocale, Trad } from "trad-lib";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { CustomAddButtons } from '../Generic/CustomAddButtons.bin';
import { propertyOf, Typed, ClearEmptyValues, duplicate, compareObjects, serializeObject, clone, SanitizeDuplication } from "hub-lib/tools.bin";
import { ExportStorage, FilterStorage } from "../../../utils/localstorage.bin";
import { CrossedTableTelerikTree, RowWrapper } from "../../crossedTable/CrossedTableTelerikTree.bin";
import { UpdateFromServer } from "../../crossedTable/DataGetter.bin";
import { CrossedTableConfig } from "../../crossedTable/CrossedTableConf.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { GanttTelerik } from "../../crossedTable/GanttTelerik.bin";
import { ACrossedTable } from "../../crossedTable/CrossedTable.bin";
import { dicoViewModeIcons, ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import history from '../../../utils/history'
import { IsIndicateurReturned } from "adwone-engine/index.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { lnk_ChangeRate } from "hub-lib/models/lnk_ChangeRate.bin";
import { SchedulerConfigDialog } from "./SchedulerConfig/SchedulerConfigDialog";
import { GroupComponent } from "../../crossedTable/scheduler/GroupComponent";
import { HistoryStateArg } from "hub-lib/types";
import { recurse } from "tools-lib";
import { ToolbarSelectedItems } from "./ToolbarSelectedItems";
import { RootState, store } from "../../../redux/store";
import { selectItems, setGridFilter, setSchedulerTemplate, setSort } from "../../../redux/gridSlice";
import { ConfigurationPanel } from "./ConfigurationPanel";
import { ConfigurationPanelContainer } from "../Generic/ToolbarAdw";
import { useDispatch, useSelector } from "react-redux";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { Filter, ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_ProjectConfigurations } from "hub-lib/models/orientdb/ref_ProjectConfigurations.bin";
import { CreateGrid, GetDefaultColumnConfiguration } from "../Generic/ModeleGrid/MessagesGridCreator";
import { CustomIconButton } from "../Generic/CustomIconButton";
import CsvFile from "../../../utils/csv-file.bin";
import { setFilters, setTable } from "../../../redux/projectSlice";
import { ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { ErrorBoundary } from "../../../ErrorBoundary.bin";
import { MessagesToolbar } from "./MessagesToolbar";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { disableRepeat, setMessageSync } from "../../../redux/messageEditorSlice";
import { MenuItemFilters } from "../Filters/MenuItemFilters";
import { TableComponent } from "./MenuItems/TableComponent";
import { ExportConfiguration } from "./MenuItems/ExportConfiguration";
import { FormSuperConfiguration } from "./MenuItems/FormSuperConfiguration";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { IconButton } from "@material-ui/core";
import { Dashboard } from "../../Dashboard/Dashboard";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ModeleGrid } from "../Generic/ModeleGrid/ModeleGrid";
import { DataGrid } from "../Generic/ModeleGrid/DataGrid";

let timeout: any = null;
type TState = {
    //editDiffusion: boolean;
    messageEdited?: ref_Messages;
    dialogMode?: eDialogMode;
    grid?: DataGrid<ref_Messages>;
    sort?: SortDescriptor[];
    medias?: ref_Media[];
    selectedMedia?: ref_Media;
    version: number;
    importRid?: string;
    openExport: boolean;
    //template?: ref_SchedulerConfigurations;
    ridsToSelect?: ref_Messages["@rid"][];
    initialized?: boolean;
}

export function MessagesGrid() {

    const [refresh, setRefresh] = React.useState(false);
    const historyState = history.location.state as HistoryStateArg;
    const projectInitilized = useSelector((root: RootState) => root.project.isInitialized);
    const repeat = useSelector((root: RootState) => root.messageEditor.repeat);
    const filters = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    const modeleTable = duplicate(useSelector((root: RootState) => root.project.columnsModele, compareObjects));

    if (filters?.Start) filters.Start = new Date(filters.Start);
    if (filters?.End) filters.End = new Date(filters.End);

    const [state, _setState] = React.useState<TState>({
        version: 0,
        //editDiffusion: false,
        openExport: false,
        importRid: undefined,
        initialized: false
    });

    const { dialogMode, grid, importRid, version } = state;

    // React.useEffect(() => {
    //     if (modeleTable.ViewMode === "Scheduler") refreshContent(false)
    // }, [serializeObject(state?.template)]);

    /**
     * Initialize (Component Did Mount)
     */
    React.useEffect(() => {
        if (!state.initialized)
            initializeState().then(newState => setState(newState));
    });

    /**
     * Trigger 1ere initialization
     */
    React.useEffect(() => {
        if (projectInitilized && state.initialized && filters)
            redraw()
    }, [state.initialized, projectInitilized]);

    /**
     * Trigger changement de configuration
     */
    React.useEffect(() => {
        if (state.initialized) onConfChange(filters);
    }, [serializeObject(filters)]);


    React.useEffect(() => {
        if (state.initialized && state?.grid?.props) {
            state.grid.props.configuration = { ...new ref_TableConfigurations, ...(modeleTable ?? {}) };
            refreshContent();
        }
    }, [serializeObject(modeleTable)]);

    const setState = (newState: Partial<TState>) => _setState({ ...state, ...newState });

    const onConfChange = (conf: Partial<Filter>) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // désélectionn sur changement de conf
            // todo: pour le moment le tableau n'est pas capable de comparer le selectedItems de redux avec les nouvelles instances de rows
            store.dispatch(selectItems([]));
            if (state.grid) {
                const newParams: any = ClearEmptyValues(conf);
                const vertexParams = state.grid.props.vertexParams;
                for (let [key2] of Object.entries(new Filter()))
                    delete vertexParams[key2]
                state.grid.props.vertexParams = { ...vertexParams, ...newParams };
                refreshContent(false);
            }
        }, 500)
    }

    const getName = async (className: string, _rid: string) =>
        (await Client.searchVertex<{ Name: string }>(className, { "@rid": _rid, properties: ["Name"] }))?.data?.results?.[0]?.Name ?? Trad("unknown")

    const initializeState = async () => {
        await DiscountManager.Load();
        const newState: Partial<TState> = {
            initialized: true,
            //template: SchedulerStorage.get(),
            medias: await Client.searchVertexTyped<ref_Media>(ref_Media)
        };

        if (historyState?.type == "import")
            Object.assign(newState, Typed<Partial<TState>>({
                importRid: historyState.importRid
            }));

        return newState;
    }

    const createGrid = async () => {
        const vertexParams = (historyState?.type == "import") ?
            { Source: ["ADWONE"], CacheInfos: { Key: historyState.importRid, Type: "Import" } } :
            duplicate(filters);

        const newGrid = await CreateGrid({ vertexParams });
        return newGrid;
    }

    const redraw = async () => {
        setState({
            grid: await createGrid(),
            sort: GetSort<ref_Messages>(ref_Messages)
        });
    }

    const selectRid = (_rids: ref_Messages["@rid"] | ref_Messages["@rid"][], newState?: Partial<TState>) => {
        if (!Array.isArray(_rids))
            _rids = [_rids];

        if (modeleTable.ViewMode === "Table") {
            _rids.forEach(rid => grid.elementsToSelect.push(rid));
            if (newState) setState(newState);
        }
        else {
            setState({ ridsToSelect: _rids, ...newState });
        }
    }

    const onValidateDialog = async (m: ref_Messages) => {
        let start = m?.Start?.toLocaleDateString(GetCurrentLocale())
        let end = m?.End?.toLocaleDateString(GetCurrentLocale())
        let support = await getName(ref_Supports.name, m.Support)
        let format = await getName(ref_Property.name, m.Format)
        let placement = m.Placement ? await getName(ref_Property.name, m.Placement) : ""

        try {
            let notifType = "";
            if (m["@rid"]) {
                //  update
                await Client.updateVertex(ref_Messages.name, m, false);
                notifType = Trad("modify_success_male");
                if (!repeat) {
                    selectRid(m["@rid"], { dialogMode: undefined });
                }
            } else {
                // creation
                delete m.Deversement;
                delete m["Volume"];
                const newElement: ref_Messages = (await Client.createVertex(ref_Messages.name, m, false))?.data?.results;
                notifType = Trad("creation_succes_male");
                if (!repeat) {
                    selectRid(newElement["@rid"], { dialogMode: undefined });
                }
            }

            // if (editDiffusion)
            //     setState({ editDiffusion: false });
            Notify(`${Trad("message")} : ${start} - ${end} ${support} ${format} ${placement} ${notifType}`, "success");
            if (!repeat) {
                store.dispatch(setMessageSync(null));
                refreshContent(false);
                setRefresh(false);
            } else {
                setRefresh(true);
                return true;
            }
        } catch (e: any) {
            NotifyError(e?.response?.data)
            return true
        }
    }

    const duplicateMessage = (m: ref_Messages) => {
        const msgToDuplicate = clone(m);
        SanitizeDuplication(msgToDuplicate);
        return msgToDuplicate;
    }

    const refreshContent = async (updateColumns: boolean = true) => {
        if (grid?.props?.configuration?.ViewMode === "Table") {
            if (updateColumns)
                grid.Initialize();
            else
                await grid.UpdateRows();
        }
        else if (grid?.props?.configuration?.ViewMode === "Dashboard") {
            await grid.UpdateRows();
        } else
            setState({ version: state.version + 1, dialogMode: undefined });
    }

    const onEdit = async (row: ref_Messages) => {
        const [messageEdited] = await Client.searchVertexTyped(ref_Messages, { "@rid": row["@rid"] });
        setState({ messageEdited, dialogMode: eDialogMode.modify })
    }

    //const onEditDiffusion = (row: ref_Messages) => setState({ messageEdited: row, editDiffusion: true })
    const onDuplicate = async (row: ref_Messages) => {
        const [messageEdited] = await Client.searchVertexTyped(ref_Messages, { "@rid": row["@rid"] });
        setState({ messageEdited: duplicateMessage(messageEdited), dialogMode: eDialogMode.duplicate })
    }

    const generateConfigCrossedTable = async () => {
        // TODO: Legacy
        if (!modeleTable.CrossedtableConfig?.rowventils) modeleTable.CrossedtableConfig = {
            rowventils: [{
                field: propertyOf<ref_Messages>("Campaign"),
                type: "@rid",
                linkedClass: ref_Campaigns.name
            }]
        };

        const confCT = { ...new CrossedTableConfig(), ...filters };
        confCT.rowventils = modeleTable.CrossedtableConfig.rowventils;
        confCT.hideDetailsRows = false;
        const conf: ref_TableConfigurations = modeleTable ?? await GetDefaultColumnConfiguration(ref_Messages.name);
        /** Check si on a droit aux devises restituées, si non, alors on les fitre */
        let cols = RightManager.hasRight(lnk_ChangeRate.name, eRights.read) ? conf.Columns : conf.Columns.filter(c => !IsIndicateurReturned(c));
        if (/*conf.HidedColumns && */conf.ViewMode === "Scheduler") {
            cols = cols.filter(c => !c.isSchedulerHidden);
        }
        confCT.selectedIndicateurs = cols;

        const copy = JSON.parse(JSON.stringify(confCT));
        if (copy.Start) copy.Start = new Date(copy.Start);
        if (copy.End) copy.End = new Date(copy.End);
        return copy;
    }

    const getFrozenNumber = () => {
        const { FrozenPosition, Columns, ViewMode } = modeleTable;

        const cols = Columns.slice(0, FrozenPosition);
        if (ViewMode === "Scheduler") {
            const hidden = cols.filter(c => c.isSchedulerHidden);
            return FrozenPosition - hidden.length;
        }

        return FrozenPosition;
    }

    if (!state.grid)
        return <Loader text={Trad("loading_grid")} />;

    if (!filters)
        return <Loader text={Trad("loading_configuration")} />;

    let { ViewMode } = modeleTable;

    const confComponent = <ConfigurationPanel
        elements={[(RightManager.hasRight(eFunctions.ref_Messages, eRights.create) && {
            type: "component",
            component: <CustomAddButtons
                tooltip={Trad("create_message")}
                className="no-shadow no-radius custom_btn_primary no-shadow"
                isIcon
                disableMoreIcon
                options={state.medias}
                //addNew={() => setState({ editMode: true, messageEdited: undefined, mode: eDialogMode.create })}
                onChanged={(media) => setState({ selectedMedia: media, messageEdited: undefined, dialogMode: eDialogMode.create })} />
        }), {
            type: "icon",
            title: () => Trad(`${ViewMode}_view`.toLocaleLowerCase()),
            icon: dicoViewModeIcons[ViewMode] ?? "settings",
            element: <TableComponent grid={grid as any} />
        }, MenuItemFilters(),
        (RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && ViewMode === "Scheduler" && {
            type: "icon",
            title: () => Trad("configure_scheduler"),
            icon: "palette",
            element: <SchedulerConfigDialog
                onResizeValue={() => { refreshContent() }}
                onChange={(template) => {
                    store.dispatch(setSchedulerTemplate(template))
                    //setState({ template });
                }} />
        }), {
            type: "icon",
            title: () => Trad("export"),
            icon: "download",
            element: <>
                <div className="view-icons" style={{ display: "flex" }}>
                    <CustomIconButton
                        onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad("CSV") })}
                        // className="custom_btn_primary"
                        disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.export)}
                        onClick={async () => {
                            const grid = await createGrid();
                            // await grid.InitializeComponent();
                            grid.exportExcel("csv");
                        }}><CsvFile /></CustomIconButton>
                </div>
                <ExportConfiguration
                    grid={grid as any}
                    filters={filters}
                    generateConfigCrossedTable={generateConfigCrossedTable}
                    open={state.openExport}
                    close={() => setState({ openExport: false })}
                />
            </>
        }, (RightManager.hasRight(eFunctions.ref_ProjectConfigurations, eRights.read) && {
            type: "icon",
            title: () => Trad("projects"),
            icon: "bookmarks",
            element: <FormSuperConfiguration onConfChange={async (conf: ref_ProjectConfigurations) => {
                const defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
                if (conf.ConfTableActive) {
                    let [confTable] = await Client.searchVertexTyped(ref_TableConfigurations, conf.ConfTable ? { Active: true, "@rid": conf.ConfTable } : defaultParams);
                    if (!confTable) {
                        [confTable] = await Client.searchVertexTyped(ref_TableConfigurations, defaultParams);
                        Notify(Trad("ref_TableConfigurations_not_found"), "warning");
                    }
                    // Legacy
                    if (!confTable.ViewMode)
                        confTable.ViewMode = "Table";
                    store.dispatch(setTable(confTable));
                    grid.props.configuration = confTable;
                    refreshContent();
                }

                if (conf?.ConfFilterActive) {
                    let [confFilter] = await Client.searchVertexTyped(ref_FilterConfigurations, conf.ConfFilter ? { Active: true, "@rid": conf.ConfFilter } : defaultParams);
                    if (!confFilter) {
                        [confFilter] = await Client.searchVertexTyped(ref_FilterConfigurations, defaultParams);
                        Notify(Trad("ref_FilterConfigurations_not_found"), "warning");
                    }
                    store.dispatch(setFilters(confFilter));
                }

                if (conf?.ConfExportActive) {
                    let [confExport] = await Client.searchVertexTyped(ref_ExportConfigurations, conf.ConfExport ? { Active: true, "@rid": conf.ConfExport } : defaultParams);
                    if (!confExport) {
                        [confExport] = await Client.searchVertexTyped(ref_ExportConfigurations, defaultParams);
                        Notify(Trad("ref_ExportConfigurations_not_found"), "warning");
                    }
                    ExportStorage.set(confExport);
                }
            }} />
        })
        ]} />

    const onReferenceCrossedTable = async (ctComponent: ACrossedTable<any, any>) => {
        const confCT = await generateConfigCrossedTable();
        const res = await UpdateFromServer(confCT);
        await ctComponent.Load(res, confCT);

        const { ridsToSelect } = state;
        if (ridsToSelect?.length) {
            const selectedRows = [];
            recurse(res.Rows, "Children", (e) => {
                if (e.Data?.length == 1 && ridsToSelect.includes(e.Data[0]["@rid"])) {
                    (e as RowWrapper).isSelected = true;
                    selectedRows.push(e);
                }
            });
            ctComponent.forceUpdate();

            store.dispatch(selectItems(selectedRows));
            setState({ ridsToSelect: null })
        }
    }

    return (
        <>
            <div id={'anchor-popup'}></div>
            <div style={{ width: '100%' }}>
                {dialogMode &&
                    <MessagesDialog
                        onValidate={onValidateDialog}
                        importRid={state?.importRid}
                        mode={dialogMode}
                        selectedMedia={state.selectedMedia?.["@rid"]}
                        open
                        message={state.messageEdited}
                        handleClose={() => {
                            setState({ dialogMode: undefined });
                            if (refresh) {
                                refreshContent(false);
                                setRefresh(false);
                            }
                            store.dispatch(disableRepeat());
                        }}
                        canRepeat
                    />}
                {/* {editDiffusion &&
                    <DiffusionDialog
                        onValidate={onValidateDialog}
                        message={state.messageEdited}
                        handleClose={() => setState({ editDiffusion: false })}
                    />} */}
                <div>
                    <MessagesToolbar>

                        {/** Toolbar qui apparait si des éléments sont sélectionnés */}
                        <ToolbarSelectedItems
                            grid={grid as any}
                            onRemove={() => refreshContent(false)}
                            onChange={(rids) => {
                                selectRid(rids);
                                refreshContent(false);
                            }} />
                        <ErrorBoundary>
                            <ConfigurationPanelContainer>
                                {!Boolean(importRid) && confComponent}
                            </ConfigurationPanelContainer>
                        </ErrorBoundary>
                    </MessagesToolbar>

                    {(ViewMode === "Table" || !ViewMode) && <ModeleGrid
                        groupable={false}
                        gridHeight={`calc(100vh - 140px)`}
                        footer
                        onEdit={(row) => onEdit(row.dataItem)}
                        onDuplicate={(row) => onDuplicate(row.dataItem)}
                        sort={state.sort}
                        grid={state.grid}
                        isCopyDisable={!RightManager.hasRight(eFunctions.ref_Messages, [eRights.create, eRights.update]) || Boolean(importRid)}
                        uneditable={!RightManager.hasRight(eFunctions.ref_Messages, eRights.update)}
                        selectionChange={(_selectedRows) => store.dispatch(selectItems(_selectedRows))}
                        // lock MAP message edition
                        commandCellArgs={{ isEditable: (r: AdwRow<ref_Messages>) => r?.dataItem?.Source !== "MAP" && RightManager.hasRight(Boolean(importRid) ? eFunctions.cache : eFunctions.ref_Messages, eRights.update) }}
                        isSelectable={(r: AdwRow<ref_Messages>) => r?.dataItem?.Source !== "MAP"}
                        hideToolbar
                        selectable
                        onRowInitialized={(rows) => {
                            const selectedRows = rows.filter(r => r.selected);
                            if (selectedRows.length)
                                store.dispatch(selectItems(selectedRows));
                        }}
                        headerFiltersChanged={headerFilters => store.dispatch(setGridFilter(headerFilters))}
                        onSortChanged={sort => store.dispatch(setSort(sort))}
                    />}

                    {(ViewMode === "CrossedTable" || ViewMode === "Scheduler") &&
                        <div>
                            <div className="clearfix" key={`adwct-${version}`}>
                                {ViewMode === "CrossedTable" &&
                                    <CrossedTableTelerikTree
                                        customMessageHeight={`calc((100vh - 140px) - 25px)`}
                                        editable={RightManager.hasRight(eFunctions.ref_Messages, eRights.update)}
                                        frozenPosition={grid.props.configuration?.FrozenPosition}
                                        onEdit={(row) => onEdit(row.Data[0])}
                                        onCopy={(row) => onDuplicate(row.Data[0])}
                                        isEditable={(row) => !row?.Children?.length}
                                        onRef={onReferenceCrossedTable}
                                        // expandAll
                                        selectable />}
                                {ViewMode === "Scheduler" &&
                                    <GanttTelerik
                                        height={`calc((100vh - 140px) - 25px)`}
                                        loaderHeight={`calc(100vh - 140px)`}
                                        onRef={onReferenceCrossedTable}
                                        frozenPosition={getFrozenNumber()}
                                        editable={RightManager.hasRight(eFunctions.ref_Messages, eRights.update)}
                                        onEdit={(row: RowWrapper) => onEdit(row.Data[0])}
                                        onCopy={(row: RowWrapper) => onDuplicate(row.Data[0])}
                                        isEditable={(row: RowWrapper) => !row?.Children?.length}
                                        // expandAll
                                        selectable
                                        renderGroup={row => <GroupComponent row={row} />}
                                        renderMessage={row => <GroupComponent row={row} />}
                                    />}
                            </div>
                        </div>
                    }

                    {ViewMode === "Dashboard" && <Dashboard />}
                    <FooterCellOptions grid={grid} />
                </div>
            </div>
        </>
    )
}


const tabs: Array<any> = [
    { title: 'table_view', viewMode: "Table", icon: getIcon("listView") },
    { title: 'crossedtable_view', viewMode: 'CrossedTable', icon: getIcon("tableView") },
    { title: 'scheduler_view', viewMode: "Scheduler", icon: getIcon("schedulerView") },
    // { title: 'dashboard_view', viewMode: "Dashboard", icon: getIcon("dashboard") }
];

const TitleTab = (props: any) => {
    return (
        <div onClick={() => props.onChangeMode(props.content.viewMode)}>
            <IconButton
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad(props.content.viewMode.toLocaleLowerCase() + "_view") })}
                key={`icon-${props.content.viewMode}`}
                className={"navigation " + (props.content.viewMode == props.currentView ? "is-selected" : "")}
                aria-label="edit">
                {props.content.icon}
            </IconButton>
            <span className="k-spacer" />
            {Trad(props.content.title)}
        </div>
    )
};

export default function FooterCellOptions({ grid }) {
    const [selected, setSelected] = React.useState<number>(0);
    const currentView = FilterStorage.getLocalStorageValue(ref_TableConfigurations.name)?.ViewMode ?? "Table";
    const tabIndex = tabs.findIndex(tab => tab.viewMode === currentView);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setSelected(tabIndex)
    }, [tabIndex])

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };
    const onChangeMode = async (value: string) => {
        let configuration = grid.props.configuration;
        configuration.ViewMode = value;

        FilterStorage.setLocalStorageValue(ref_TableConfigurations.name, configuration);
        dispatch(setTable(configuration));
    };

    return (
        <TabStrip tabContentStyle={{ display: 'none' }} selected={selected} onSelect={handleSelect}>
            {tabs.map((item, index) => {
                return (
                    <TabStripTab
                        title={<TitleTab content={item} onChangeMode={onChangeMode} currentView={currentView} />}
                        key={index}>
                    </TabStripTab>
                );
            })}
        </TabStrip>
    );
};
