import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'hub-lib/client/client.bin';
import { ref_ExportConfigurations } from 'hub-lib/models/orientdb/ref_ExportConfigurations.bin';
import { AdvertiserHierarchyFilter, Filter, ref_FilterConfigurations } from 'hub-lib/models/orientdb/ref_FilterConfigurations.bin';
import { ref_ProjectConfigurations } from 'hub-lib/models/orientdb/ref_ProjectConfigurations.bin';
import { ref_TableConfigurations } from 'hub-lib/models/orientdb/ref_TableConfigurations.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { eBusinessCode } from 'hub-lib/models/types.bin';
import { clearEmptyValues, ClearEmptyValues, clone, duplicate, extractSub, firstOrDefault, toArray } from 'hub-lib/tools.bin';
import { DateNoZone } from 'tools-lib';
import { Trad } from 'trad-lib';
import { eventEmitter } from '../components/GenericModal';
import { ExportStorage, FilterStorage, GetUser, MetadataStorage, ProjectStorage, TableStorage } from '../utils/localstorage.bin';

export interface ProjectState {
    project: ref_ProjectConfigurations;
    filtersModele: ref_FilterConfigurations;
    columnsModele: ref_TableConfigurations;
    filters: Partial<Filter>;
    exportModele: ref_ExportConfigurations;
    isInitialized: boolean;
}

const initialState: ProjectState = {
    project: null,
    filtersModele: null,
    columnsModele: null,
    exportModele: null,
    isInitialized: false,
    filters: null,
}

type SetProjectReturn = {
    project: ref_ProjectConfigurations,
    table?: ref_TableConfigurations,
    filter?: ref_FilterConfigurations,
    export?: ref_ExportConfigurations,
}

export const selectProject = createAsyncThunk(
    'project/selectProject',
    async (projectRid: ref_ProjectConfigurations["@rid"], thunkAPI): Promise<SetProjectReturn> => {
        // TODO, gérer les nouveaux filtres
        if (!projectRid || projectRid === "default") {
            const defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
            const project = firstOrDefault(await Client.searchVertexTyped(ref_ProjectConfigurations, defaultParams));
            const table = firstOrDefault(await Client.searchVertexTyped(ref_TableConfigurations, defaultParams));
            const filter = firstOrDefault(await Client.searchVertexTyped(ref_FilterConfigurations, defaultParams));
            const exportConf = firstOrDefault(await Client.searchVertexTyped(ref_ExportConfigurations, defaultParams));
            return {
                project,
                table,
                filter,
                export: exportConf
            }
        }
        const fullKeyTable = "FullConfFilter";
        const fullKeyFilter = "FullConfTable";
        const fullKeyExport = "FullConfExport";
        const params = {
            "@rid": projectRid,
            Active: true,
            properties: ["*", `ConfFilter.* as ${fullKeyFilter}`, `ConfTable.* as ${fullKeyTable}`, `ConfExport.* as ${fullKeyExport}`]
        }
        const project = firstOrDefault(await Client.searchVertexTyped(ref_ProjectConfigurations, params));
        return {
            project,
            table: project[fullKeyTable],
            filter: project[fullKeyFilter],
            export: project[fullKeyExport],
        };
    }
)

export const initialize = createAsyncThunk(
    'project/initialize',
    async (_, thunkAPI): Promise<any> => {
        await MetadataStorage.initialize();
        const defaultFilters = { ...new ref_FilterConfigurations(), ...FilterStorage.get() };
        defaultFilters.Filters = ClearEmptyValues(defaultFilters.Filters);
        const filters = ClearEmptyValues({ ...FilterStorage.getAdvancedFilters(), ...new AdvertiserHierarchyFilter(), ...defaultFilters.Filters });

        if (!GetUser().customer?.DisplayMAPData) {
            filters.Source = ["ADWONE"];
        }
        return {
            columnsModele: TableStorage.get(),
            filtersModele: defaultFilters,
            exportModele: ExportStorage.get(),
            filters,
            project: duplicate(ProjectStorage.get())
        }
    })

async function _saveFilters(filters: ref_FilterConfigurations) {
    if (filters['@rid']) {
        try {
            await Client.updateVertex(ref_FilterConfigurations.name, filters, false)
            const cloneFilters = clone(filters);
            cloneFilters.Filters = AdvertiserHierarchyFilter.getFilters(cloneFilters.Filters);
        } catch (error: any) {
            if (error?.response?.data.error.data.type == eBusinessCode.AlreadyExist) {
                const handleConfirm = async (filters) => {
                    const cloneFilters = clone(filters);
                    cloneFilters.Filters = AdvertiserHierarchyFilter.getFilters(cloneFilters.Filters);
                    const configExist = await Client.searchVertexTyped(ref_FilterConfigurations, { 'Filters': filters.Filters, 'Active': true });

                    const configToDelete = configExist.filter(config => config["@rid"] !== filters["@rid"]).map(config => config["@rid"])
                    await Client.deleteVertex(ref_FilterConfigurations.name, configToDelete, false)
                    await Client.updateVertex(ref_FilterConfigurations.name, filters, false)
                }

                const content = Trad("hierarchy_exists") + filters.Name + ', '  + Trad("overwrite_element");
                modalEvent(content, handleConfirm)
            }
        }
    }
}


function _setFilters(state: ProjectState, config: ref_FilterConfigurations) {
    FilterStorage.set(config);
    state.filtersModele = duplicate(config as any);
    if (state.columnsModele?.DashboardConfig?.filters)
        state.columnsModele.DashboardConfig.filters = {};
    if (state.filtersModele?.Filters)
        state.filtersModele.Filters = ClearEmptyValues(state.filtersModele.Filters);
    state.filters = ClearEmptyValues({ ...FilterStorage.getAdvancedFilters(), ...new AdvertiserHierarchyFilter(), ...state.filtersModele.Filters });
}

function _setTable(state: ProjectState, config: ref_TableConfigurations) {
    TableStorage.set(config);
    state.columnsModele = duplicate(config as any);
}

function _setExport(state: ProjectState, config: ref_ExportConfigurations) {
    ExportStorage.set(config);
    state.exportModele = duplicate(config);
}

async function _saveExport(config: ref_ExportConfigurations) {
    if (config['@rid'])
        await Client.updateVertex(ref_ExportConfigurations.name, config, false)
            .catch(e => console.error(e));
}

async function _saveTable(table: ref_TableConfigurations) {
    console.log('columns')
    if (table['@rid'])
        try {
            await Client.updateVertex(ref_TableConfigurations.name, table, false)
        } catch (error: any) {
            if (error?.response?.data.error.data.type == eBusinessCode.AlreadyExist) {
                const configExist = await Client.searchVertexTyped(ref_TableConfigurations, { 'Columns': table.Columns, 'Active': true });
                const configToDeleteName = configExist.filter(config => config["@rid"] !== table["@rid"]).map(config => config.Name)
                const handleConfirm = async () => {
                    const configToDelete = configExist.filter(config => config["@rid"] !== table["@rid"]).map(config => config["@rid"])
                    await Client.deleteVertex(ref_TableConfigurations.name, configToDelete, false)
                    await Client.updateVertex(ref_TableConfigurations.name, table, false)
                    eventEmitter.emit("close");
                }

                const content = Trad("the_table") + configToDeleteName + Trad("content_replacement_validation");
                modalEvent(content, handleConfirm)
            }
        }
}
const modalEvent = (content, handleConfirm) => {
    eventEmitter.emit("push", {
        title: Trad("confirmation"),
        content: content,
        buttons: [
            {
                label: Trad("cancel"),
                handleButton: () => { eventEmitter.emit("close") },
                className: "custom_btn_primary_cancel",
            },
            {
                label: Trad("yes"),
                handleButton: handleConfirm,
                className: "custom_btn_danger",
            }
        ]
    })
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(selectProject.fulfilled, (state, action: PayloadAction<SetProjectReturn>) => {
            ProjectStorage.set(action.payload.project);
            state.project = duplicate(action.payload.project);
            if (action.payload.table) _setTable(state, action.payload.table);
            if (action.payload.filter) {

                _setFilters(state, action.payload.filter);

            }
            if (action.payload.export) ExportStorage.set(action.payload.export);
        });
        builder.addCase(initialize.fulfilled, (state, action: PayloadAction<ProjectState>) => {
            if (action.payload.filters) state.filters = action.payload.filters;
            if (action.payload.columnsModele) state.columnsModele = action.payload.columnsModele;
            if (action.payload.filtersModele) state.filtersModele = action.payload.filtersModele;
            if (action.payload.project) state.project = action.payload.project;
            if (action.payload.exportModele) state.exportModele = action.payload.exportModele;
            state.isInitialized = true;
        })
    },
    reducers: {
        setFilters: (state, action: PayloadAction<ref_FilterConfigurations>) => {
            _setFilters(state, action.payload);
            state.filters = ClearEmptyValues({ ...state.filters, ...new AdvertiserHierarchyFilter(), ...action.payload?.Filters });
        },
        saveFilters: (state, action: PayloadAction<ref_FilterConfigurations>) => {
            _saveFilters(action.payload);
        },
        setTable: (state, action: PayloadAction<ref_TableConfigurations>) => {
            _setTable(state, action.payload);
        },
        saveTable: (state, action: PayloadAction<ref_TableConfigurations>) => {
            _saveTable(action.payload);
        },
        setChartConfig: (state, action: PayloadAction<({field: string, value: string})[]>) => {
            const modele = clone(state.columnsModele);
            const isDate = action.payload.some(({field}) => field === "Start" || field === "End");
            let newFilters = { ...modele.DashboardConfig.filters };
            if (isDate) {
                const exist = extractSub(modele.DashboardConfig.filters, ["Start", "End"]);
                const start = action.payload.find(({field}) => field === "Start")?.value;
                const end = action.payload.find(({field}) => field === "End")?.value;
                if (exist) {
                    // if selected date is the same as the existing one, remove it
                    if (new Date(start).getTime() === new Date(exist["Start"] as string).getTime()
                        && new Date(end).getTime() === new Date(exist["End"] as string).getTime()) {
                        delete newFilters["Start"];
                        delete newFilters["End"];
                    } else {
                        // if selected date is included in the existing one, remove it
                        if (new Date(start).getTime() >= new Date(exist["Start"] as string).getTime() && new Date(end).getTime() <= new Date(exist["End"] as string).getTime()) {
                            newFilters = {
                                ["Start"]: start,
                                ["End"]: end,
                            };
                        } else {
                            // if selected date is not included in the existing one, extend them
                            newFilters = {
                                ["Start"]: new Date(start).getTime() < new Date(exist["Start"] as string).getTime() ? start : newFilters["Start"],
                                ["End"]: new Date(end).getTime() > new Date(exist["End"] as string).getTime() ? end : newFilters["End"],
                            };
                        }
                    }
                } else {
                    newFilters = {
                        ["Start"]: start,
                        ["End"]: end,
                    };
                }
            } else {
                for (const {field, value} of action.payload) {
                    const exist = extractSub(modele.DashboardConfig.filters, [field]);
                    if (exist) {
                        // remove from array
                        const array = toArray(newFilters[field]);
                        const index = array.indexOf(value);
                        if (index > -1) {
                            array.splice(index, 1);
                        } else {
                            array.push(value);
                        }
                        newFilters = {
                            [field]: array,
                        };
                    } else {
                        newFilters = {
                            [field]: toArray(value),
                        };
                    }
                }
            }
            modele.DashboardConfig.filters = clearEmptyValues(newFilters);
            _setTable(state, modele);
            _saveTable(modele);
        },
        clearChartConfig: (state) => {
            const modele = clone(state.columnsModele);
            modele.DashboardConfig.filters = {};
            _setTable(state, modele);
            _saveTable(modele);
        },
        setExport: (state, action: PayloadAction<ref_ExportConfigurations>) => {
            _setExport(state, action.payload);
        },
        saveExport: (state, action: PayloadAction<ref_ExportConfigurations>) => {
            _saveExport(action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { setFilters, setFilters: selectFilters, setTable, setChartConfig, clearChartConfig, saveFilters, saveTable, setExport, saveExport } = projectSlice.actions

export const projectReducer = projectSlice.reducer;