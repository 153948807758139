import * as React from "react";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { Trad } from "trad-lib";
import { Client } from "hub-lib/client/client.bin";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { FormHelperText, FormControl } from "@material-ui/core";
import { VertexAutocomplete } from "adwone-lib/index";
import { IsValidProperty, IsValid } from "validation-lib/index.bin";
import { propertyOf, distinct } from "hub-lib/tools.bin";
import Loader from '../../layout/Loader';
import Grid from '@material-ui/core/Grid'
import { IRid } from "hub-lib/models/IRid.bin";
import { AdvertiserHierarchy, IAdvertiserHierarchy } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";


let none: any = {};


export abstract class HierarchyConfig<THierarchy> {
    store: THierarchy;
    multi?: boolean = false;
    mandatory?: {
        [prop in keyof THierarchy]?: boolean
    };
    disableProperty?: {
        [prop in keyof THierarchy]?: boolean
    };
    multiDetails?: {
        [prop in keyof THierarchy]?: boolean
    };

    onConfChanged?: (conf: THierarchy) => void;
}
class TProps extends HierarchyConfig<IAdvertiserHierarchy> {
    onRef?: (ptr: HierarchyComboManager) => void;
    styles?: React.CSSProperties;
    readonly?: boolean;
    lockMode?: boolean | (keyof TProps["store"])[] = false;
    addCampaigns?: boolean = false;
    lockable?: boolean;
    hideBrand?: boolean;
    hideProduct?: boolean;
    classInitializer?: string;
    validationMode?: boolean = false;
    prototypeStore?: new () => any = undefined;
    noPadding?: boolean = false;
    additionMargin?: number = 0;
    fullWidth?: boolean;
    nullDefault?: {
        [prop in keyof IAdvertiserHierarchy]?: boolean
    } | boolean;
    isDisableClearable?: {
        [prop in keyof IAdvertiserHierarchy]?: boolean
    } | boolean;
    hideProperty?: {
        [prop in keyof IAdvertiserHierarchy]?: boolean
    };
}

class TState {
    store: AdvertiserHierarchy = new AdvertiserHierarchy();
    previous: {
        store: AdvertiserHierarchy;
    } = { store: undefined }

    advertiserGroups: ref_AdvertiserGroups[] = [];
    advertisers: ref_Advertisers[] = [];
    brands: ref_Brands[] = [];
    products: ref_Products[] = [];
    campaigns: ref_Campaigns[] = [];

    loading: boolean = true;
    version: number = 0;
}

export class HierarchyComboManager extends React.Component<TProps, TState> {

    hierarchy = [
        ref_AdvertiserGroups.name,
        ref_Advertisers.name,
        ref_Brands.name,
        ref_Products.name,
        ref_Campaigns.name
    ]

    getValue = {
        [ref_AdvertiserGroups.name]: (c: any) => c.AdvertiserGroup,
        [ref_Advertisers.name]: (c: any) => c.Advertiser,
        [ref_Brands.name]: (c: any) => c.Brand,
        [ref_Products.name]: (c: any) => c.Product,
        [ref_Campaigns.name]: (m: any) => m.Campaign,
    }

    getStateKey = {
        [ref_AdvertiserGroups.name]: 'advertiserGroups',
        [ref_Advertisers.name]: 'advertisers',
        [ref_Brands.name]: 'brands',
        [ref_Products.name]: 'products',
        [ref_Campaigns.name]: 'campaigns'
    }

    getCmpKey: { [prop: string]: (keyof AdvertiserHierarchy) } = {
        [ref_AdvertiserGroups.name]: propertyOf<AdvertiserHierarchy>('AdvertiserGroup'),
        [ref_Advertisers.name]: propertyOf<AdvertiserHierarchy>('Advertiser'),
        [ref_Brands.name]: propertyOf<AdvertiserHierarchy>('Brand'),
        [ref_Products.name]: propertyOf<AdvertiserHierarchy>('Product'),
        [ref_Campaigns.name]: propertyOf<AdvertiserHierarchy>('Campaign')
    }

    getConfigKey: any = {
        "ref_AdvertiserGroups": "AdvertiserGroup",
        "ref_Advertisers": "Advertiser",
        "ref_Products": "Product",
        "ref_Brands": "Brand",
        "ref_Campagns": "Campaign"
    }

    /**
     *
     */
    constructor(props: TProps) {
        super(props);

        none = { "@rid": "default", Name: Trad("None") };

        let newState = new TState();

        if (this.props.store) {
            newState.store = this.props.store;
            newState.previous.store = { ...this.props.store };
        }



        this.state = newState;
        this.props.onRef?.(this);
    }

    getLockObject: () => IAdvertiserHierarchy = () => {
        if (!this.props.lockMode) return undefined;
        if (typeof this.props.lockMode == "boolean") return { ...this.props.store };
        return this.props.lockMode.map(p => ({ [p]: true })).reduce((a, b) => ({ ...a, ...b }), {})
    }

    getParams = (...args: string[]) => {
        let params: any = {};
        args.forEach(a => {
            if (!params.parents) params.parents = [];
            let val = (this.state.store as any)[this.getConfigKey[a]] ?? [];

            let newParents: any[] = params.parents;
            if (params.parents) {
                if (Array.isArray(params.parents)) newParents = params.parents.concat(newParents)
                else newParents.push(params.parents)
            }

            if (val) {
                if (Array.isArray(val)) newParents = newParents.concat(val)
                else newParents.push(val)
            }

            params[a] = Array.from(new Set(newParents));
        });
        Object.keys(params).forEach((key: string) => {
            if (params[key] === undefined || (Array.isArray(params[key]) && params[key].length === 0))
                delete params[key];
        });
        return params;
    }

    updateCampaigns = async () => {
        const search = await this.getItems<ref_Campaigns>(ref_Campaigns, this.getCampaignParams());
        this.setState({ campaigns: search?.data?.results }, () => this.redrawCombo());
    }

    updateAvailable = () => {
        let { store } = this.state;

        return Promise.all([
            this.props.addCampaigns ? this.getItems<ref_Campaigns>(ref_Campaigns, this.getCampaignParams()) : { data: { results: [] } },
            this.getItems<ref_AdvertiserGroups>(ref_AdvertiserGroups),
            this.getItems<ref_Advertisers>(ref_Advertisers, this.getParams(ref_AdvertiserGroups.name), store.Advertiser),
            this.getItems<ref_Brands>(ref_Brands, this.getParams(ref_AdvertiserGroups.name, ref_Advertisers.name), store.Brand),
            this.getItems<ref_Products>(ref_Products, this.getParams(ref_AdvertiserGroups.name, ref_Advertisers.name, ref_Brands.name), store.Product)
            // this.getItems<ref_Supports>(ref_Supports)
        ]).then(res => {
            this.setState({
                campaigns: res[0].data.results,
                advertiserGroups: res[1].data.results,
                advertisers: res[2].data.results,
                brands: res[3].data.results,
                products: res[4].data.results,
                // supports: res[5].data.results,
                loading: false
            })
        });
    }

    async componentDidMount() {
        this.updateAvailable();
        if (this.props.classInitializer) {
            await this.onValueChanged(this.props.classInitializer)
            this.refreshAll()
        }

    }

    private getItems<T>(prototype: new () => T, params?: any, current?: rid | rid[]) {
        return Promise.resolve().then(() => {
            if (params)
                return Client.searchVertex(prototype.name, { ...params, properties: ["@rid", "Name"], Active: true }).then(res => {

                    let { results } = res.data;

                    if (current?.length > 0 && !results.some((r: any) => r["@rid"] === current)) {
                        return Client.get<any>(prototype, { "@rid": current }).then(res2 => {
                            results = [...res2.data.results, ...results];
                            results = distinct(results, (r: any) => r["@rid"]);

                            return { data: { results } };
                        })
                    }

                    return res;
                });
            return Client.searchVertex(prototype.name, { properties: ["@rid", "Name"] });
        }).then((res: any) => {
            return { data: { results: !this.props.multi ? [none, ...res.data.results] : [...res.data.results] } };
        });
    }
    isMulti = (key: keyof AdvertiserHierarchy) => {
        let multi = this.props.multi
        if (this.props.multiDetails) {
            if (this.props.multiDetails.hasOwnProperty(key))
                multi = (this.props.multiDetails as any)[key]
        }
        return multi
    }

    isDisableClearable = (key: keyof AdvertiserHierarchy) => {
        const { isDisableClearable } = this.props;
        if (isDisableClearable && typeof isDisableClearable === "boolean")
            return true;
        return isDisableClearable?.[key];
    }

    isNullDefault = (key: keyof AdvertiserHierarchy) => {
        const { nullDefault } = this.props;
        if (nullDefault && typeof nullDefault === "boolean")
            return true;
        return nullDefault?.[key];
    }
    isMandatory = (key: keyof AdvertiserHierarchy) => {
        return this.props.mandatory?.[key];
    }
    isPropertyDisabled = (key: keyof AdvertiserHierarchy) => {
        return this.props.disableProperty?.[key];
    }
    isPropertyHidden = (key: keyof AdvertiserHierarchy) => {
        return this.props.hideProperty?.[key];
    }

    private InitStore = () => {
        const { store } = this.state;
        if (this.isMulti("AdvertiserGroup"))
            if (!store.AdvertiserGroup) store.AdvertiserGroup = [];
        if (this.isMulti("Advertiser"))
            if (!store.Advertiser) store.Advertiser = [];
        if (this.isMulti("Brand"))
            if (!store.Brand) store.Brand = [];
        if (this.isMulti("Product"))
            if (!store.Product) store.Product = [];
    }

    private EpureStore = (classname: string) => {
        let cmp: any = this.state.store;
        let currentIndex = this.hierarchy.indexOf(classname);

        let copyIndex = currentIndex + 1;
        while (copyIndex < this.hierarchy.length) {

            let key = this.getCmpKey[this.hierarchy[copyIndex]];
            if (!this.isMulti(key))
                if (key != "Campaign" || !this.props.lockMode)
                    delete cmp[key];
            copyIndex++;
        }
    }

    private OnChangeMulti = async () => {

        if (!(this.props.multi && this.state.previous?.store)) {
            return;
        }

        let { store } = this.state;
        let cmp: any = this.state.store;

        // propriétés qui nous interessent
        let properties = this.hierarchy.map(h => this.getCmpKey[h]).filter(p => p != "Campaign");

        const hasBeenRemoved: any = {};
        properties.forEach((key: keyof AdvertiserHierarchy) => {
            let val: rid[] | rid = (this.state.previous.store as any)[key];
            if (val) {
                val = Array.isArray(val) ? val : [val];
                const removed = val?.filter((v: string) => !(store as any)[key]?.includes(v));
                if (removed?.length > 0)
                    hasBeenRemoved[key] = removed;
            }
        });

        let entries = Object.entries(hasBeenRemoved);

        for (const entry of entries) {

            let key: keyof AdvertiserHierarchy = entry[0] as any;

            let val: rid[] = entry[1] as any;
            let removed: rid[] = [];

            let indexProp = properties.indexOf(key as any) + 1;
            while (indexProp <= properties.length - 1) {
                let currentKey = properties[indexProp];
                let currentType = this.hierarchy[indexProp];

                let check = val.concat(removed);
                if (check.length > 0) {
                    let children: rid[] = (await Client.searchVertex(currentType, {
                        parents: val.concat(removed),
                        properties: ["@rid", "Name"],
                        Active: true
                    })).data.results.map((r: IRid) => r["@rid"]);
                    let currentValue: rid[] = cmp[currentKey];
                    cmp[currentKey] = currentValue.filter(r => !children.includes(r));
                    removed = currentValue.filter(r => children.includes(r));
                }

                indexProp++;
            }
        }
    }

    private recurseParent = async (idx: number, parents: rid[]): Promise<any> => {
        let cmp: any = this.state.store;
        /** Niveau courant */
        const stateKey = this.getStateKey[this.hierarchy[idx]];
        const cmpKey = this.getCmpKey[this.hierarchy[idx]]
        if (!this.props.addCampaigns && cmpKey === propertyOf<AdvertiserHierarchy>("Campaign"))
            return;

        /** Est ce que tous les choix sont disponibles */
        const all = parents.length == 1 && (parents[0] == undefined || parents[0]?.length == 0);
        let paramParents = all ? undefined : { parents, Active: true };

        if (stateKey == "campaigns")
            paramParents = this.getCampaignParams();

        try {
            const res = await Client.searchVertex(this.hierarchy[idx], { ...paramParents, properties: ["@rid", "Name"] })

            /** Choix possibles d'une dimension */
            const newparams = this.isMulti(cmpKey) ? [...res.data.results] : [none, ...res.data.results];

            if (this.isMulti(cmpKey)) {
                const current = cmp[this.getCmpKey[this.hierarchy[idx]]];
                if (current) {
                    cmp[cmpKey] = current.filter((c: any) => newparams.some((p: any) => p["@rid"] == c))
                }
            }

            this.setState({
                [stateKey]: newparams
            } as any)

            if (idx + 1 < this.hierarchy.length) {
                let rids = res.data.results.map((r: any) => r["@rid"]);
                let ridParent = this.getValue[this.hierarchy[idx]](cmp);
                if (this.isMulti(cmpKey)) {
                    if (ridParent?.length > 0) rids = ridParent;
                } else {
                    if (ridParent) rids = [ridParent];
                }

                return this.recurseParent(idx + 1, all ? [undefined] : rids);
            }

        } catch (error) {
            return this.recurseParent(idx + 1, [undefined]);
        }
    }

    private recurse = async (idx: number, callRecurse: boolean = true): Promise<any> => {
        let cmp: any = this.state.store;
        const cmpKey = this.getCmpKey[this.hierarchy[idx]]
        const cmpKeyParent = this.getCmpKey[this.hierarchy[idx - 1]]
        let currentValue = cmp[cmpKey];
        if (currentValue) {

            let params = currentValue;
            if (!this.isMulti(cmpKey)) params = [params];

            try {
                let res = params.length ? (await Client.searchVertex(this.hierarchy[idx - 1], { children: params, properties: ["@rid", "Name"], Active: true }))?.data?.results : []
                if (this.isMulti(cmpKeyParent)) {
                    let existing = cmp[cmpKeyParent];
                    let toAdd = res?.map((obj: any) => {
                        return obj["@rid"]
                    });
                    cmp[cmpKeyParent] = Array.from(new Set([...existing, ...toAdd]));
                } else {
                    if (!cmp[cmpKeyParent])
                        cmp[cmpKeyParent] = res?.[0]?.["@rid"];
                }
            } catch (error) {

            }

            if (idx > 1 && callRecurse)
                return this.recurse(idx - 1);
        }
    }

    startLoading = () => {
        return new Promise((res) => {
            this.setState({ loading: true }, () => res(undefined));
        })
    }

    endLoading = () => {
        return new Promise((res) => {
            this.setState({ loading: false }, () => res(undefined));
        })
    }


    onValueChanged = async (classname: string) => {

        await this.startLoading();

        const { store } = this.state;
        const cmp: any = this.state.store;

        this.InitStore();
        if (classname === ref_Campaigns.name) {
            const res = await Client.searchVertex(ref_Campaigns.name, { "@rid": store.Campaign, properties: ["@rid", "Name"] });
            if (this.props.multi) {
                res?.data?.results?.forEach((c: ref_Campaigns) => {
                    (store.AdvertiserGroup as any[]).push(c.AdvertiserGroup);
                    (store.Advertiser as any[]).push(c.Advertiser);
                    (store.Brand as any[]).push(c.Brand);
                    (store.Product as any[]).push(c.Product);
                });
                store.AdvertiserGroup = distinct(store.AdvertiserGroup as rid[], r => r).filter(r => r);
                store.Advertiser = distinct(store.Advertiser as rid[], r => r).filter(r => r);
                store.Brand = distinct(store.Brand as rid[], r => r).filter(r => r);
                store.Product = distinct(store.Product as rid[], r => r).filter(r => r);
            }
            await this.endLoading();
            return;
        }

        this.EpureStore(classname);

        await this.OnChangeMulti();

        const currentIndex = this.hierarchy.indexOf(classname);
        if (currentIndex > 0)
            await this.recurse(currentIndex)

        const firstCmpKey = this.getCmpKey[this.hierarchy[0]]
        const content = cmp[this.getCmpKey[this.hierarchy[0]]];

        let params = [content];
        if (this.isMulti(firstCmpKey))
            params = (content?.length == 0) ? [undefined] : content;

        await this.recurseParent(1, params)

        if (this.props.multi)
            this.state.previous.store = { ...store };


        await this.endLoading();
    }


    generateCombo = (classname: string, trad: string,
        disabled: boolean = false
    ) => {

        const { store } = this.props;
        const { loading } = this.state;
        disabled = loading || disabled;

        const isFullWidth = (this.props.hideBrand || this.props.hideProduct || this.props.fullWidth);
        const cmpKey = this.getCmpKey[classname];
        let cmp = this.state.store;
        let data = (this.state as any)[this.getStateKey[classname]];
        let key = `key_combo_${trad}_${this.state.version}_${this.props.multi}_loading${loading}`;
        let helper = this.GetHelper(cmpKey);
        let check = this.props.prototypeStore && IsValidProperty(this.props.prototypeStore.name, this.state.store, this.getCmpKey[classname] as any);
        let checkLockable = (this.getValue[classname](cmp)) ? true : false;
        if (!this.isMulti(cmpKey))
            return (
                <div key={key} className={isFullWidth ? "adw-row" : ""} style={{ width: "100%" }}>
                    {data &&
                        <FormControl variant="outlined" error={!this.IsValid(this.getCmpKey[classname] as any)} style={{ width: "100%", marginBottom: this.props.additionMargin ?? 0 }}>
                            <VertexAutocomplete
                                loading={loading}
                                disableClearable={this.isDisableClearable(cmpKey)}
                                disabled={this.props.readonly || disabled || this.isPropertyDisabled(cmpKey)}
                                options={data as IRid[]}
                                nullOnClear={this.isNullDefault(cmpKey)}
                                label={((this.props.hideBrand || this.props.hideProduct) ? `${Trad(trad)} *` : Trad(trad)) + ((check || this.isMandatory(cmpKey)) ? " *" : "")}
                                type={classname}
                                defaultValue={(values: IRid[]) => {

                                    const valuesFound = values.find(v => v["@rid"] == this.getValue[classname](cmp))
                                    const defaultValues = valuesFound ?? (this.isNullDefault(cmpKey) ? null : values?.[0]);

                                    if (data.length && !valuesFound) {
                                        // s'il y a des données et que la valeur actuelle n'est pas trouvé alors on met à null.
                                        // (c'est le cas si on change un parent et que la valeur actuelle n'est plus dans ses enfants)
                                        cmp[this.getCmpKey[classname]] = this.isNullDefault(cmpKey) ? null : undefined;
                                    }

                                    return defaultValues;
                                }}
                                onChange={async (value: any) => {
                                    if (value?.["@rid"] == "default") value = undefined;
                                    cmp[this.getCmpKey[classname]] = value?.["@rid"] ?? undefined;
                                    try {
                                        await this.onValueChanged(classname);
                                    } catch (err) {
                                        console.error(err)
                                    } finally {
                                        this.refreshAll();
                                    }
                                }} />

                            {helper && <FormHelperText style={{ background: 'white' }}>{helper}</FormHelperText>}
                        </FormControl>}
                </ div >
            );

        let warning: string = undefined;
        let notNull = [];
        if (data?.length) {
            const defaultValues = cmp[cmpKey] as IRid["@rid"][] ?? [];
            const defaultV = defaultValues?.map(e => (data as IRid[])?.find(v => v["@rid"] == e)) ?? [];
            notNull = defaultV.filter(v => v);

            if (notNull.length != defaultValues?.length) {
                warning = Trad("invalid_filters");
            }
        }

        return (
            <div key={key} className="adw-row">
                {(data && !this.isPropertyHidden(cmpKey)) &&
                    <div style={{ width: '100%', display: "inline-block" }}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>

                            <VertexAutocomplete
                                warning={warning}
                                loading={loading}
                                disabled={this.props.readonly || disabled || (this.props.lockable && checkLockable) || this.isPropertyDisabled(cmpKey)}
                                multiple={true}
                                options={data as IRid[]}
                                label={Trad(trad) + (check ? " *" : "")}
                                type={classname}
                                defaultValue={(values: IRid[]) => notNull}
                                onChange={(value: any) => {
                                    cmp[this.getCmpKey[classname]] = value?.map((v: any) => v["@rid"]);
                                    this.onValueChanged(classname).then(this.refreshAll)
                                }} />

                        </FormControl>

                    </div>}
            </ div >
        );
    }

    redrawCombo() {
        this.setState({ version: this.state.version + 1 })
    }

    refreshAll = () => {
        this.redrawCombo();
        this.props?.onConfChanged?.(this.state.store);
    }

    IsValid = (property: keyof ref_Campaigns) => {
        if (!this.props.validationMode) return true;
        let check = IsValid<any>(this.props.prototypeStore, this.state.store, property)
        if (!check) return true;
        return check.isValid;
    }

    GetHelper = (property: keyof AdvertiserHierarchy) => {
        if (!this.props.validationMode) return false;
        let valid = IsValid<any>(this.props.prototypeStore, this.state.store, property);
        return (valid?.isValid === false) ? valid?.errorText : "";
    }

    getCampaignParams = () => {
        let { store } = this.state;
        let params: any = {
            AdvertiserGroup: store?.AdvertiserGroup,
            Advertiser: store?.Advertiser,
            Brand: store?.Brand,
            Product: store?.Product,
            Start: store?.Start,
            End: store?.End,
            Active: true
        };
        Object.keys(params).forEach((key: string) => {
            if (params[key] === undefined || (Array.isArray(params[key]) && params[key].length === 0))
                delete params[key];
        });

        if ((store as any)?.Source)
            params.Source = (store as any)?.Source;

        return params;
    }

    render() {
        const isFullWidth = (this.props.hideBrand || this.props.hideProduct || this.props.fullWidth);
        const isBrandFullWidth = (this.props.hideProduct || this.props.fullWidth);
        const lockObject = this.getLockObject();
        return (
            <>
                {this.props.multi &&
                    <>
                        <Grid item xs={isFullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_leftcombo"}>
                            {this.generateCombo(ref_AdvertiserGroups.name, "advertiser_group")}
                        </Grid>
                        <Grid item xs={isFullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_rightcombo"}>
                            {this.generateCombo(ref_Advertisers.name, "advertiser")}
                        </Grid>
                        {!this.props.hideBrand &&
                            <Grid item xs={isFullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_rightcombo"}>
                                {this.generateCombo(ref_Brands.name, "brand")}
                            </Grid>
                        }
                        {(!this.props.hideBrand && !this.props.hideProduct) && this.generateCombo(ref_Products.name, "product")}
                        {this.props.addCampaigns &&
                            this.generateCombo(ref_Campaigns.name, "campaign")}

                    </>}

                {!this.props.multi &&
                    <>
                        <Grid item xs={isFullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_leftcombo"}>
                            {this.generateCombo(ref_AdvertiserGroups.name, "advertiser_group", lockObject?.AdvertiserGroup != undefined)}
                        </Grid>
                        <Grid item xs={isFullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_rightcombo"}>
                            {this.generateCombo(ref_Advertisers.name, "advertiser", lockObject?.Advertiser != undefined)}
                        </Grid>
                        {(!this.props.hideBrand) && <Grid item xs={isBrandFullWidth ? 12 : 6} className={isBrandFullWidth ? "" : "message_details_leftcombo"}>
                            {this.generateCombo(ref_Brands.name, "brand", lockObject?.Brand != undefined)}
                        </Grid>}
                        {(!this.props.hideBrand && !this.props.hideProduct) && <Grid item xs={this.props.fullWidth ? 12 : 6} className={isFullWidth ? "" : "message_details_rightcombo"}>
                            {this.generateCombo(ref_Products.name, "product", lockObject?.Product != undefined)}
                        </Grid>}
                    </>
                }
            </>
        );
    }
}
