import * as React from "react";
import { extractSub, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../redux/store";
import { PanelDisable } from "../Generic/Common.bin";

export function OfferValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign']), JSONEqualityComparer) ?? {};

    return <PanelDisable disabled={!Boolean(message.Campaign)} text={Trad('select_campaign')} >
        {children}
    </PanelDisable>
}

export function PricingValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign', 'Currency']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign) || !Boolean(message.Currency)} >
        {children}
    </PanelDisable>
}

export function DiffusionValidator({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign)}>
        {children}
    </PanelDisable>
}

export function MetricsValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign', 'Currency']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign) || !Boolean(message.Currency)} >
        {children}
    </PanelDisable>
}