import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_Model } from "./orientdb/ref_Model.bin";
import { lnk_HasKPI } from "./orientdb/lnk_HasKPI.bin";
import { ref_SourceTypesId, ref_SourceTypes } from "./orientdb/ref_SourceTypes.bin";
import { ref_KPIs } from "./orientdb/ref_KPIs.bin";
import { ePropType } from "./VertexProperty.bin";
import { eCompare, Operator } from "../operators.bin";
import { lnk_HasPropertyType } from "./orientdb/lnk_HasPropertyType.bin";
import { ref_Property } from "./orientdb/ref_Property.bin";
import { ref_Messages } from "./ref_Messages.bin";
import { DataProvider } from "../provider";
import { Trad, TradValue } from "trad-lib";

export enum eKPI {
    Total = "Nombre total",
    Paid = "Nombre payant",
    Free = "Nombre gracieux",
    GrossFormat = "Brut format défaut",
    GrossFormatRate = "Taux format défaut",
    GrossFormatValRate = "Taux valorisé format défaut",
    //Device = "Device",
    //TypeBuy = "Type d'achat",
    //TypePack = "Type de package",
    SOV = "SOV",
    //TypeActivity = "Type d'activité",
    ViewRate = "Viewability rate",
    //Position = "Position",
    //TypeNav = "Type de navigation",
    Net = "Net",
    PaidCirculation = "Diffusion payée",
    TotalCirculation = "Diffusion totale",
    Subscriptions = "Abonnements",
    Release = "Tirage",
    ToDeliver = "Nb ex à livrer (passe incluse)"
}

export enum eKPIType {
    Number = "Number",
    Decimal = "Decimal",
    Percent = "Percent",
    Rid = "@rid",
    String = "String",
    Price = "Price",
    PriceReturned = "PriceReturned",
    PriceBound = "PriceBound",
    Date = "Date",
    DateTime = "DateTime",
    FullDecimal = "FullDecimal",
    Rate = "Rate",
}

export enum eTags {
    FullWidth = "FullWidth",
    TextEditor = "TextEditor",
    Website = "Website",
    Upload = "Upload"
}

export const eHandleTypes = {
    [ePropType.Decimal]: eKPIType.Number,
    [ePropType.Integer]: eKPIType.Number,
    [ePropType.Short]: eKPIType.Number,
    [ePropType.Long]: eKPIType.Number,
    [ePropType.Float]: eKPIType.Number,
    [ePropType.Double]: eKPIType.Number,
    [ePropType.Binary]: eKPIType.Number,
    [ePropType.Byte]: eKPIType.Number,
    [ePropType.Datetime]: eKPIType.Date,
    [ePropType.Date]: eKPIType.Date,
    [ePropType.String]: eKPIType.String,
    [ePropType.Link]: eKPIType.Rid,
    [ePropType.LinkSet]: eKPIType.Rid
}

export const eHandleTypesReverse = {
    [eKPIType.Decimal]: ePropType.Double,
    [eKPIType.Number]: ePropType.Integer,
    [eKPIType.Date]: ePropType.Date,
    [eKPIType.String]: ePropType.String,
    [eKPIType.Rid]: ePropType.Link,
    [eKPIType.Price]: ePropType.Double,
}

export function ToEKPIType(e: ePropType) {
    return eHandleTypes[e] || eKPIType.String;

}

export function ToEPropType(e: eKPIType) {
    // ce serait plus logique de mettre string par défaut mais ça a des répercussions dans les colonnes de KPIs
    return eHandleTypesReverse[e] ?? ePropType.Integer;

}

export function IsNumber(type: eKPIType) {
    return [eKPIType.Decimal, eKPIType.Rate, eKPIType.FullDecimal, eKPIType.Number, eKPIType.Percent, eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}

export function IsDate(type: eKPIType) {
    return [eKPIType.Date, eKPIType.DateTime].includes(type);
}

export function IsPrice(type: eKPIType) {
    return [eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}

export interface IModelMessageInfo {
    "@class"?: string;
    Id: string;
    Name: string;
    ValueType: eKPIType;
}

export class lnk_HasKPIExtended extends lnk_HasKPI implements IModelMessageInfo {
    SourceType: string;
    Name: eKPI;
    NameLnk: string;
}

type ValueChoiceType = { rid: string, value: string, "@class"?: string };
export class lnk_HasPropertyTypeExtended extends lnk_HasPropertyType implements IModelMessageInfo {
    ValueChoices: ValueChoiceType[];
    Sort?: (a: ValueChoiceType, b: ValueChoiceType) => number;

    Name: string; //ref_PropertyType.Type
    Id: string; //ref_PropertyType.Type
    ValueType: eKPIType; //ref_PropertyType.ValueType
    Tags: (string | eTags)[];
    Rank?: number;
}

export class MessageModelManager {

    //static vertexProvider: <Ref>(vertexName: string, params?: any, op?: Operator[]) => Promise<Ref[]>;
    //static metadataProvider: (vertexName: string) => Promise<ClassProperty[]>;

    kpis: lnk_HasKPIExtended[];
    properties: lnk_HasPropertyTypeExtended[];
    model: ref_Model;
    media: ref_MediaId;
    documentType: string;
    source: ref_SourceTypesId; // en dur, temporaire: src AdwOne

    constructor(media: ref_MediaId, documentType: string) {
        this.media = media;
        this.documentType = documentType;
    }

    static async GetRefKPI(kpi: eKPI): Promise<ref_KPIs> {
        return (await DataProvider.search<ref_KPIs>(ref_KPIs.name, {
            Name: kpi
        }))?.[0];
    }

    static async GetRefKPIs(): Promise<ref_KPIs[]> {
        return DataProvider.search<ref_KPIs>(ref_KPIs.name);
    }

    static async GetRefLnkKPIs(): Promise<lnk_HasKPI[]> {
        return DataProvider.search<lnk_HasKPI>(lnk_HasKPI.name);
    }

    async GetModel(): Promise<ref_Model> {
        const results = await DataProvider.search<ref_Model>(ref_Model.name, {
            DocumentType: this.documentType, MediaTypes: [this.media]
        });
        return results?.[0];
    }

    async GetSource(): Promise<ref_SourceTypesId> {
        const results = await DataProvider.search<ref_SourceTypes>(ref_SourceTypes.name, { Name: "AdwOne" });
        return results?.[0]?.["@rid"];
    }

    async GetKPIs(): Promise<lnk_HasKPIExtended[]> {

        if (!this.model)
            this.model = await this.GetModel();

        if (!this.source)
            this.source = await this.GetSource();

        let results = await DataProvider.search<lnk_HasKPIExtended>(lnk_HasKPI.name, {
            out: this.model["@rid"], in: this.source, properties: ["*", "in.Name as SourceType", "KPI.Name as Name", "Name as NameLnk"]
        });
        results = results.filter(r => r.Id);

        this.kpis = results;

        return results;
    }

    async GetProperties(): Promise<lnk_HasPropertyTypeExtended[]> {

        if (!this.model)
            this.model = await this.GetModel();

        const results = await DataProvider.search<lnk_HasPropertyTypeExtended>(lnk_HasPropertyType.name, {
            out: this.model["@rid"], properties: ["*", "in.Type as Name", "in.Type as Id", "in.ValueType as ValueType", "in.Tags as Tags", "in.Rank as Rank"]
        });

        const RidType = results.filter(r => r.ValueType === eKPIType.Rid).map(r => r.in);

        if (RidType.length) {
            const operator: Operator = { property: "PropertyType", value: RidType, compare: eCompare.ContainsAny }
            const properties = await DataProvider.search<ref_Property>(ref_Property.name, { Active: true }, [operator]);
            for (const result of results.filter(r => r.ValueType === eKPIType.Rid)) {
                result.ValueChoices = properties.filter(p => p.PropertyType.includes(result.in)).map(p => ({ rid: p["@rid"], value: TradValue(ref_Property.name, "Name", p.Name), "@class": p["@class"] }));
                //result.ValueChoices = properties.filter(p => p.PropertyType === result.in).map(p => p.Name);
            }
        }

        this.properties = results;

        return results;
    }

    GetKey(kpi: eKPI): string {
        return this.kpis.find(k => k.Name === kpi)?.Id;
    }

    GetInfoValue(message: ref_Messages, info: IModelMessageInfo) {
        if (IsNumber(info.ValueType)) {
            if (info.Name == eKPI.Total) {
                const total: number = (((message.KPIs as any)[this.GetKey(eKPI.Paid)] ?? 0) + ((message.KPIs as any)[this.GetKey(eKPI.Free)]) ?? 0);
                (message.KPIs as any)[this.GetKey(eKPI.Total)] = total;
                return total;
            }
            else
                return message.KPIs?.[info.Id] ?? 0;
        }
        if (info.ValueType == eKPIType.Date || info.ValueType == eKPIType.DateTime)
            return message.ModelProperties?.[info.Id] ? new Date(message.ModelProperties?.[info.Id]) : null;
        if ((info as any).ValueChoices)
            return (info as any).ValueChoices.find(v => v.rid == message.ModelProperties?.[info.Id]);
        return message.ModelProperties?.[info.Id];
    }

}

export class KPIsManagerCache {

    private static instances: { [prop: string]: KPIsManagerCache } = {};
    docType: string;
    dico: { [prop: string]: lnk_HasKPIExtended[] } = {};

    async GetKPIs(media: ref_MediaId): Promise<lnk_HasKPIExtended[]> {

        if (this.dico[media])
            return this.dico[media];

        let mgr = new MessageModelManager(media, this.docType);
        this.dico[media] = await mgr.GetKPIs();

        return this.dico[media];
    }

    constructor(docType: string) {
        this.docType = docType;
    }

    public static GetInstance(docType: string): KPIsManagerCache {

        if (!KPIsManagerCache.instances[docType]) {

            KPIsManagerCache.instances[docType] = new KPIsManagerCache(docType);
        }

        return KPIsManagerCache.instances[docType];
    }
}
