import { rid } from "../orientdb/CommonTypes.bin";
import { lnk_HasModule } from "../orientdb/lnk_HasModule.bin";
import { ref_Customers } from "../orientdb/ref_Customers.bin";

export class ref_CustomersExtended extends ref_Customers {
    EstimatesAPI: string;
	FinancialAPI: string;
    MediaManagerAPI: string;
    Children: { "@rid": rid, Name: string }[]
    lnkHasModule: lnk_HasModule[]
    AllocatedLicences: number
    DisplayMAPData : boolean
}