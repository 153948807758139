import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_BroadcastAreasId = rid;

export class ref_BroadcastAreas extends V implements Referentials {
	"@rid"?: ref_BroadcastAreasId;
	Code: string;
	End: Date;
	Name: string;
	Start: Date;
	Active: boolean;

}