import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IItemRow } from "adwone-lib/index";
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/models/ref_SchedulerConfigurations.bin';
import { clone, duplicate, GetSubElement } from 'hub-lib/tools.bin';
import { SchedulerStorage } from '../utils/localstorage.bin';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export interface GridState {
    selectedItems: IItemRow<ref_Messages>[];

    data: ref_Messages[];
    schedulerTemplate: ref_SchedulerConfigurations;

    // Contient les styles filtrés (schedulerTemplate.Styles) sur les data présentes (présent dans data)
    dataStyles: ref_SchedulerConfigurations['Style'];

    sort: SortDescriptor[];
    gridFilter: CompositeFilterDescriptor;
}

const initialState: GridState = {
    selectedItems: [],
    data: [],
    schedulerTemplate: SchedulerStorage.get(),
    dataStyles: {},
    sort: [],
    gridFilter: { logic: 'and', filters: [] }
}

const createDataStyles = (data: ref_Messages[], schedulerTemplate: ref_SchedulerConfigurations) => {

    const dataStyles: ref_SchedulerConfigurations['Style'] = {};

    if (!schedulerTemplate?.Style)
        return dataStyles;

    const keys = new Set<string>(Object.values(schedulerTemplate.Style).map(v => v.key));
    if (schedulerTemplate.Style && data?.length > 0 && keys.size > 0)
        data?.forEach(d => {
            keys.forEach(key => {
                const value = GetSubElement(d, key);
                const template = schedulerTemplate.Style[value];
                if (template)
                    dataStyles[value] = clone(template);
            })
        });
    return dataStyles;
}

export const authSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setSort: (state, action: PayloadAction<GridState['sort']>) => {
            state.sort = clone(action.payload);
        },
        setGridFilter: (state, action: PayloadAction<GridState['gridFilter']>) => {
            state.gridFilter = clone(action.payload);
        },
        selectItems: (state, action: PayloadAction<any>) => {
            state.selectedItems = duplicate(action.payload);
        },
        setData: (state, action: PayloadAction<ref_Messages[]>) => {
            const time8540 = new Date().getTime();
            state.data = duplicate(action.payload);
            const _time8540 = new Date().getTime();
            console.log(`[PERF] REDUX setData duplicate  Elapsed ${_time8540 - time8540}ms`);

            const time8533 = new Date().getTime();
            state.dataStyles = createDataStyles(action.payload, clone(state.schedulerTemplate));
            const _time8533 = new Date().getTime();
            console.log(`[PERF] REDUX createDataStyles Elapsed ${_time8533 - time8533}ms`);

            state.selectedItems = duplicate(state.selectedItems)?.filter(i => state.data?.some(d => d['@rid'] == i?.dataItem?.['@rid']));
        },
        setSchedulerTemplate: (state, action: PayloadAction<ref_SchedulerConfigurations>) => {
            state.schedulerTemplate = duplicate(action.payload);
            state.dataStyles = createDataStyles(clone(state.data), action.payload);
        },
        initializeSchedulerTemplate: (state, action: PayloadAction<void>) => {
            const newValue = SchedulerStorage.get();
            state.schedulerTemplate = newValue;
            state.dataStyles = createDataStyles(clone(state.data), newValue);
        }
    },
});

// Action creators are generated for each case reducer function
export const { selectItems, setData, setSchedulerTemplate, initializeSchedulerTemplate, setSort, setGridFilter } = authSlice.actions

export const gridReducer = authSlice.reducer;