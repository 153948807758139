import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_AdvertisingCompanyRoleId = rid;

export class ref_AdvertisingCompanyRole extends V implements Referentials {
	"@rid"?: ref_AdvertisingCompanyRoleId;
	Name: string;
	Active: boolean;

}