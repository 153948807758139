import * as React from 'react'
import Template from '../Template';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { GridBase } from '../../VertexGrid/Generic/GridBase.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ADWColumn, AdwRow, VertexAutocomplete, getIcon } from 'adwone-lib';
import { Trad, TradClassName, TradProp } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';
import { EstimateStatus, ref_Estimates } from 'hub-lib/models/ref_Estimates.bin';
import { src_AdwOne } from 'hub-lib/models/orientdb/src_AdwOne.bin';
import { GetSubElement, Typed, groupBy, propertyOf } from 'hub-lib/tools.bin';
import { ref_Sources } from 'hub-lib/models/orientdb/ref_Sources.bin';
import { vw_mm_HasAdvertiser } from 'hub-lib/models/orientdb/vw_mm_HasAdvertiser.bin';
import { vw_mm_HasBrand } from 'hub-lib/models/orientdb/vw_mm_HasBrand.bin';
import { vw_mm_HasAdvertiserGroup } from 'hub-lib/models/orientdb/vw_mm_HasAdvertiserGroup.bin';
import { vw_mm_HasProduct } from 'hub-lib/models/orientdb/vw_mm_HasProduct.bin';
import { vw_mm_HasMedia } from 'hub-lib/models/orientdb/vw_mm_HasMedia.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_Products } from 'hub-lib/models/orientdb/ref_Products.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin';
import { CustomIconButton } from '../../VertexGrid/Generic/CustomIconButton';
import { Notify } from '../../../utils/Notify.bin';
import { vw_mm_HasAgencie } from 'hub-lib/models/orientdb/vw_mm_HasAgencie.bin';
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin';

class Row {
    name: string;
    linkType: string;
    estimates: ref_Estimates[];
    children: { externalId: string, name: string, estimates: ref_Estimates[] }[]
}

function InvalidEstimatesComponent() {
    const [rows, setRows] = React.useState<Row[]>(null);
    const [grid, setGrid] = React.useState(null);

    const [customer, setCustomer] = React.useState<{ rid: ref_Customers['@rid'], collection: string, source: ref_Sources['@rid'], sourceMM: ref_Sources['@rid'] }>(null);

    React.useEffect(() => {

        Promise.resolve().then(async () => {

            if (!customer?.collection) return;
            //const allCustomers = await Client.searchVertexTyped(ref_Customers, { properties: ['Company.Name as CompanyName', 'Sources.URI as SourcesURIs', 'Sources.@class as SourcesClasses'] });
            await ReloadRows();
        });
    }, [customer?.collection])

    React.useEffect(() => {
        Promise.resolve().then(async () => {


            if (!rows?.length) return;

            const nameCol = new ADWColumn<Row>(`${TradProp("Name", Row)}`, "name", ePropType.String);
            const countCol = new ADWColumn<Row>(`${Trad('Count')}`, "estimates", ePropType.String);
            countCol.cellValue = (row: Row, dataItem) => dataItem.dataItem.estimates.length;

            nameCol.width = 600;
            countCol.width = 300;

            const actualizationCol = new ADWColumn<Row>(`${Trad('Refresh')}`, "", ePropType.String);
            actualizationCol.width = 80;
            actualizationCol.cellValue = (_: Row, row: AdwRow<Row>) => {
                return <CustomIconButton
                    className="primary_color"
                    onClick={async () => {
                        Notify('please_wait', 'info');
                        const entities = row.dataItem.estimates.map(e => e['Import'].Data)
                        Client.Post('/masterdata/estimates', { customerId: customer.rid, entities })
                            .then(() => {
                                Notify(Trad('Success'), 'success');
                                setGrid(null);
                                setRows([]);
                                ReloadRows();
                            })
                            .catch(() => Notify(Trad('Error'), 'error'))
                    }}>
                    {getIcon("reload")}
                </CustomIconButton>
            };

            const gridBase = new GridBase({
                objectPrototype: Row,
                rows: rows ?? [],
                columns: [nameCol, countCol, actualizationCol]
            });

            console.log(`[InvalidEstimatesComponent] gridBase`, gridBase);

            setGrid(gridBase);
        });
    }, [rows]);

    return <>

        <div style={{ width: 500, marginTop: 10 }}>
            <VertexAutocomplete
                label='Client'
                type={ref_Customers.name}
                getOptionLabel={(option: ref_Customers) => option?.['CompanyName'] ?? ""}
                params={{ properties: ['Company.Name as CompanyName', 'Sources.URI as SourcesURIs', 'Sources.@class as SourcesClasses', 'Sources'] }}
                onChange={v => {
                    const idxMM = v.SourcesClasses.indexOf(src_MM.name);
                    const idx = v.SourcesClasses.indexOf(src_AdwOne.name);
                    setGrid(null);
                    setRows(null);
                    setCustomer({ rid: v['@rid'], collection: v.SourcesURIs[idx], source: v.Sources[idx], sourceMM: v.Sources[idxMM] });
                }}
            />
        </div>
        <div key={`grid_${customer?.collection}_${rows?.length}`}>
            {grid && rows.length && <AdwTelerikGrid
                scrollable='scrollable'
                isCopyDisable
                grid={grid}
                DetailsComponent={props => {
                    console.log(`[InvalidEstimatesComponent] props`, props);
                    const row = props.dataItem.dataItem as Row;
                    return <DetailsInvalidEstimates data={row.children} source={customer.sourceMM} linkType={row.linkType} />
                }}
                uneditable={true} />}
        </div>
    </>

    async function ReloadRows() {
        const properties = {
            [propertyOf<ref_Estimates>('AdvertiserGroup')]: { type: ref_AdvertiserGroups, field: 'Import.Data.groupId', link: vw_mm_HasAdvertiserGroup },
            [propertyOf<ref_Estimates>('Advertiser')]: { type: ref_Advertisers, field: 'Import.Data.advertiserId', link: vw_mm_HasAdvertiser },
            [propertyOf<ref_Estimates>('Brand')]: { type: ref_Brands, field: 'Import.Data.brandId', link: vw_mm_HasBrand },
            [propertyOf<ref_Estimates>('Product')]: { type: ref_Products, field: 'Import.Data.productId', link: vw_mm_HasProduct },
            [propertyOf<ref_Estimates>('Media')]: { type: ref_Media, field: 'Import.Data.media.id', link: vw_mm_HasMedia },
            [propertyOf<ref_Estimates>("Agency")]: { type: ref_Agencies, field: 'Import.Data.locationId', link: vw_mm_HasAgencie }
        };

        const rowsData = (await Promise.all(Object.entries(properties)
            .map(([k, v]) => Client.searchVertexTyped(ref_Estimates, { collection: customer.collection, [k]: "#-1:-1", properties: ['Import'] }))))
            .map((estimates, i) => {

                const key = Object.keys(properties)[i];
                const groups = Object.entries(groupBy(estimates, e => GetSubElement(e, properties[key].field)))
                    .map(([k, v]) => ({ externalId: k, name: "not found", estimates: v }));

                return Typed<Row>({
                    name: TradClassName(properties[key].type.name),
                    linkType: properties[key].link.name,
                    estimates: estimates,
                    children: groups
                });
            });

        console.log(`[InvalidEstimatesComponent] rowsData`, rowsData);

        setRows(rowsData);
    }
}

type DetailsInvalidEstimatesProps = {
    data: Row['children'],
    source: ref_Sources['@rid'],
    linkType: string
}

function DetailsInvalidEstimates({ data, source, linkType }: DetailsInvalidEstimatesProps) {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (!data) return;
            const res = (await Client.searchVertex(linkType, {
                'out.ExternalID': data.map(d => d.externalId),
                'in': source,
                properties: ['*', 'out.Label as Name', 'out.ExternalID as externalId', 'Referential.Name as refName']
            }))?.data?.results;

            const newRows = data.map(d => {
                const e = res.find(r => r.externalId == d.externalId)
                return ({
                    externalId: d.externalId,
                    name: e?.Name ?? d.name,
                    estimates: d.estimates,
                    reported: d.estimates.find(e => e.Import?.Status === EstimateStatus.Reported) !== undefined,
                    refName: e?.refName ?? ""
                })
            });

            // sort newRows, put import.status = reported first
            newRows.sort((a, b) => {
                if (a.reported) return -1;
                if (b.reported) return 1;
                return 0;
            });

            const idCol = new ADWColumn<Row>(`id`, "externalId", ePropType.String);
            idCol.cellValue = (cellValue: string, dataItem: any) => {
                return <div className="flex center between">
                    <div>{cellValue}</div>
                    {dataItem.dataItem.reported && getIcon("warning", {}, "warning_color")}
                </div>;
            };
            const nameCol = new ADWColumn<Row>(`${TradProp("Name", Row)}`, "name", ePropType.String);
            const refCol = new ADWColumn<Row>(`${Trad('Référentiel')}`, "refName", ePropType.String);
            const countCol = new ADWColumn<Row>(`${Trad('Count')}`, "estimates", ePropType.String);
            countCol.cellValue = (row: Row, dataItem) => dataItem.dataItem.estimates.length;
            nameCol.width = 350;
            countCol.width = 100;
            const gridBase = new GridBase({
                objectPrototype: Row,
                rows: newRows ?? [],
                columns: [idCol, nameCol, countCol, refCol]
            });

            setGrid(gridBase);
        });
    }, [data]);

    return <>
        {grid && <AdwTelerikGrid
            scrollable='scrollable'
            isCopyDisable
            grid={grid}
            uneditable={true} />}
    </>
}


export const InvalidEstimatesGrid = Template(InvalidEstimatesComponent);