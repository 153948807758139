
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { AdwAutocomplete, getIcon } from "adwone-lib";
import { GetNumericFormat, GetNumericStep } from "format-lib/index.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { store } from "../../../redux/store";
import { ContainerComponent } from "../Generic/ContainerComponent";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { colWidth, scrollSize } from "./DiscountEditor/DiscountEditor.bin";

const padding = 10;
const paddingRight = 12;

type DiscountCategoryProps = {
    firstCol: number | string;
    lastCol: number;
    hideValue?: boolean;
    hideCommandCells?: boolean;
    hideIntervalCells?: boolean;
    styles?: React.CSSProperties;
    value?: number | string;
    currencyCode?: string;
    label: string;
    editValue?: boolean;
    baseClasses?: ref_DiscountClasses[];
    onChange?: (base_Class: ref_DiscountClasses, base: number) => any;
}

export class DCState {
    base_class: ref_DiscountClasses;
    inEdit: boolean;
    baseValue: number;
}

export class DiscountCategory extends React.Component<DiscountCategoryProps, DCState>  {

    constructor(props: DiscountCategoryProps) {
        super(props);
        this.state = {
            base_class: props?.baseClasses?.find(c => c.Name == "Exceptionnelle"),
            baseValue: 0,
            inEdit: false
        }
    }

    render() {
        const { firstCol, lastCol, hideCommandCells, hideIntervalCells, styles, hideValue, editValue, baseClasses, onChange } = this.props
        return (<div style={{ display: 'flex' }} className='gray-back'>
            {!this.state.inEdit &&
                <>
                    <div style={{ width: firstCol, padding, paddingLeft: 20, ...styles }}>
                        {this.props.label}
                    </div>
                    {/** Current Price */}
                    {!hideValue && <div style={{ width: colWidth, padding, textAlign: 'right', paddingRight, ...styles }}>
                        {this.props.value !== undefined && `${this.props.value} ${this.props.currencyCode}`}
                    </div>
                    }
                </>
            }
            {this.state.inEdit &&
                <>
                    <div style={{ width: firstCol, display: "flex" }} className="netdiscount-container">
                        <div style={{ padding, paddingLeft: 20, ...styles }}>{this.props.label}</div>
                        <ContainerComponent title={Trad("discount_reference")}>
                            <AdwAutocomplete options={baseClasses}
                                value={this.state.base_class}
                                disableClearable
                                getOptionLabel={(option: ref_DiscountClasses) => Trad(option.Name)}
                                onChange={(event, v: any) => this.setState({ base_class: v })}
                            />
                        </ContainerComponent>
                    </div>
                    {/** Current Price */}
                    <div style={{ width: colWidth, textAlign: 'right' }} className="netdiscount-container">
                        <NumericTextBox
                            className={'CellNumberComponent-editing-cell'}
                            spinners={true}
                            step={1}
                            format={GetNumericFormat(eKPIType.Price)}
                            value={this.state.baseValue}
                            onFocus={() => { }}
                            onBlur={() => { }}
                            onChange={(e) => this.setState({ baseValue: e.value })} />
                    </div>
                </>
            }

            {/** Intervals */}
            {!hideIntervalCells && <div style={{ width: colWidth, padding, textAlign: 'right', paddingRight, ...styles }}>
                -
            </div>}

            {/** Taux */}
            <div style={{ width: colWidth, padding, textAlign: 'right', paddingRight, ...styles }}>
                -
            </div>

            {/** Montant */}
            {<div style={{ width: colWidth, padding, textAlign: 'right', paddingRight, ...styles }}>
                -
            </div>}

            {editValue && <div style={{ width: lastCol + scrollSize, textAlign: 'right', display: "flex" }}>
                {this.state.inEdit &&
                    <>
                        <CustomIconButton className="picto custom_btn_secondary_validation no-padding" onClick={async () => {
                            this.setState({ inEdit: false }, () => {
                                onChange?.(this.state.base_class, this.state.baseValue);
                            })
                        }}>
                            {getIcon('done')}
                        </CustomIconButton>
                        <CustomIconButton className="picto custom_btn_secondary_cancel no-padding"
                            onClick={() => this.setState({ inEdit: false })}>
                            {getIcon('undo')}
                        </CustomIconButton>
                    </>}
                {!this.state.inEdit &&
                    <CustomIconButton
                        className='primary_color no-padding'
                        onClick={() => {
                            const net = store.getState().messageEditor.message.KPIs["NetCO"];
                            this.setState({ inEdit: true, baseValue: net })
                        }}>
                        {getIcon('edit_inline')}
                    </CustomIconButton>
                }
            </div>}
            <div className='clearfix'></div>
        </div>)
    }
}