import * as React from "react";
import { Grid } from "@material-ui/core";
import { LEGOCrons } from "hub-lib/models/ref_Crons.bin";
import { Trad } from "trad-lib";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { ref_CustomersExtended } from "hub-lib/models/custom/ref_CustomersExtended.bin";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { TooltipManager } from "../../CustomTooltip";
import { FormatDate } from "tools-lib";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";

const styleRow: React.CSSProperties = {
    height: '100%',
    alignContent: 'center',
    display: 'grid'
}

type LEGOConfiguration = { cron: LEGOCrons, onChange: (cron: LEGOCrons) => void }
export function LEGOConfiguration({ cron, onChange }: LEGOConfiguration) {

    console.log(`[LEGOConfiguration]`, cron);

    const lastUpdate = cron.TaskParams.LastMessagesIndicateursUpdate;
    const indicateurs = cron.TaskParams.MessagesIndicateurs ?? [];
    return <Grid container style={{ marginBottom: 12 }}>
        <Grid item xs={6} style={{ paddingRight: 6 }}>
            <VertexAutocomplete
                disableClearable
                type={ref_CustomersExtended.name}
                params={{ Authorization: "MediaManager", properties: ["*", "Company.Name as Name"] }}
                label={Trad("customer")}
                onResetValue={(options: any) => undefined as any}
                defaultValue={options => options.find(e => e["@rid"] == cron.TaskParams?.Customer)}
                onChange={(client) => {
                    cron.TaskParams.Customer = client?.["@rid"];
                    onChange(cron);
                }}
            />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: 6 }}>

            <CustomIconButton
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("refresh_global_agreement") })}
                style={{ float: "right" }}
                className="custom_btn_secondary popover_button"
                onClick={async () => {
                    // const message = store.getState().messageEditor.getMessage();
                    // await DiscountManager.initGlobalAgreement(message);
                    // dispatch(setMessage(message));
                    if (cron.TaskParams.MessagesIndicateurs) {
                        if (!cron.TaskParams.HistoryMessagesIndicateurs)
                            cron.TaskParams.HistoryMessagesIndicateurs = [];
                        cron.TaskParams.HistoryMessagesIndicateurs.push({
                            indicateurs: cron.TaskParams.MessagesIndicateurs,
                            date: cron.TaskParams.LastMessagesIndicateursUpdate
                        });
                    }

                    cron.TaskParams.MessagesIndicateurs = await IndicateursProvider.GetInstance().ProvideLEGOIndicateurs();
                    cron.TaskParams.LastMessagesIndicateursUpdate = new Date();
                    console.log(`[LEGOConfiguration] NEW`, cron);
                    onChange(cron);
                }}>
                {getIcon("cached")}
            </CustomIconButton>

            <div className="adw-row" style={styleRow}>
                <div>{`Nombre indicateurs: ${indicateurs.length}`}</div>
                <div>{`Dernière mise à jour: ${lastUpdate ? FormatDate(lastUpdate) : 'jamais'}`}</div>
            </div>
        </Grid>
    </Grid>
}