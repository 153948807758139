import { IRid } from "./IRid.bin";
import { rid } from "./orientdb/CommonTypes.bin";

export enum eReportStatus {
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    FAILED = "FAILED"
}

export class ref_Reports implements IRid {
    "@rid"?: rid;
    Active: boolean = true;
    Date: Date = new Date();
    Progress: {
        Status: eReportStatus;
        Total: number;
        Current: number;
    } = {
        Status: eReportStatus.IN_PROGRESS,
        Total: 100,
        Current: 0,
    };
    Errors: {
        Date: Date;
        Error: any;
    }[] = [];
}