import * as React from "react";
import { GridCellProps } from '@progress/kendo-react-grid';
import { DiscountCategoryArray } from "../DiscountCategoryArray.bin";
import { Tooltip } from '@material-ui/core';
import { getIcon } from "adwone-lib/index";
import { Trad } from "trad-lib";
import { GetCellTemplate } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import EventEmitter from "events";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";

export const EventChange = new EventEmitter();

export type CellIntervalsPropsType = GridCellProps & { ptr: DiscountCategoryArray, currency?: string, hideIntervalNumber?: boolean, dataItem: { dataItem: ref_Agreements } };
export class CellIntervalsComponent extends React.Component<CellIntervalsPropsType, any> {

    componentDidMount() {
        EventChange.addListener('changed', this.updateAllComponent);
    }

    componentWillUnmount() {
        EventChange.removeListener('changed', this.updateAllComponent);
    }

    updateAllComponent = () => {
        this.forceUpdate();
    }

    render() {
        let { dataItem } = this.props;
        dataItem = dataItem.dataItem ?? dataItem;

        const { hideIntervalNumber } = this.props;
        let currencyCode = this.props.currency ?? ''
        let className = "";

        const temp = GetCellTemplate(ePropType.Integer)

        return (
            <td className={className} style={{ textAlign: 'right', position: 'relative' }}>
                {dataItem?.Intervals?.length > 0 &&
                    <Tooltip placement="right-end" title={
                        <><div>{`${Trad("intervals")} :`}</div>
                            <ul style={{ padding: 0 }}>
                                {dataItem?.Intervals?.map(e => {
                                    return <li key={`${e.Minimum}_${e.Maximum}_interval`}>{`${Trad("from_number")} ${temp(e.Minimum)}${currencyCode} ${Trad("to_number")} ${temp(e.Maximum)}${currencyCode}`}</li>
                                })}
                            </ul></>
                    }>
                        <div style={{ width: !hideIntervalNumber ? 44 : 35, height: 23, padding: 3, borderRadius: 30, backgroundColor: "#009BCE", color: "white", display: "inline-block" }}>
                            <div style={{ float: "left", marginLeft: 5 }}>{getIcon("intervals")}</div>
                            {!hideIntervalNumber &&
                                <div style={{ float: "right", fontSize: 13, fontWeight: 700, marginRight: 5 }}>{dataItem?.Intervals?.length}</div>}
                        </div>
                    </Tooltip>
                }
            </td>
        );
    }
}
