import * as React from "react";
import { Grid, TextField } from "@material-ui/core";
import { getIcon, VertexAutocomplete } from "adwone-lib";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_GlobalAgreements } from "hub-lib/models/ref_GlobalAgreements.bin";
import { Trad, TradClassName } from "trad-lib";
import { SimpleDatePicker } from "../../ConfigurableComponents/SimpleDatepicker.bin";
import { AgreementDiscounts } from "../Agreements/AgreementDiscounts";
import { CustomTextFieldUpper } from "../Generic/CustomTextField";
import { GetHashCode } from "hub-lib/tools.bin";

type TProps = { item: ref_GlobalAgreements; }
export function GlobalAgreementsDialog({ item }: TProps) {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    return (<>
        <div className="adw-title adw-form-title">
            <div>{getIcon("folder")}</div>
            {Trad("agreement_perimeter")}
        </div>
        <Grid xs={6}>
            <CustomTextFieldUpper
                label={`${Trad("property_Name")} *`}
                value={item.Name}
                onChange={(e) => {
                    item.Name = e;
                    forceUpdate();
                }}
                className="adw-row" />

            <Grid item xs={12} className="message_details_full">
                <VertexAutocomplete
                    nullOnClear
                    label={TradClassName(ref_BroadcastAreas.name)}
                    type={ref_BroadcastAreas.name}
                    multiple
                    defaultValue={options => options.filter(o => item.BroadcastAreas.some(b => b == o["@rid"]))}
                    onChange={(values: ref_BroadcastAreas[]) => item.BroadcastAreas = values?.map(v => v["@rid"]) ?? []} />
            </Grid>

            {/** Devise de l'accord */}
            <Grid item xs={12} className="message_details_full">
                <VertexAutocomplete
                    nullOnClear
                    label={`${TradClassName(ref_Currencies.name)} *`}
                    params={{ properties: ["*"] }}
                    type={ref_Currencies.name}
                    defaultValue={options => options.filter(o => item.Currency == o["@rid"])?.[0]}
                    onChange={(value: ref_Currencies) => item.Currency = value?.["@rid"]} />
            </Grid>

            <Grid item xs={12} className="message_details_full">
                <VertexAutocomplete
                    nullOnClear
                    label={TradClassName(ref_Media.name)}
                    type={ref_Media.name}
                    defaultValue={options => options.filter(o => item.Media == o["@rid"])?.[0]}
                    onChange={(value: ref_Media) => {
                        item.Media = value?.["@rid"];
                        forceUpdate();
                    }} />
            </Grid>
        </Grid>

        <div className="adw-title adw-form-title">
            <div>{getIcon("event")}</div>
            {Trad("application_period")}
        </div>

        <Grid container xs={6} className='block-container'>
            <Grid xs={6} item className="message_details_leftcombo">
                <SimpleDatePicker
                    label={`${Trad("Début")} *`}
                    defaultValue={new Date(item.Start)}
                    onChange={(event) => {
                        const oldStart = item.Start;
                        item.Start = event.value;
                        if (oldStart && item.End && item.Start > item.End) {
                            const diff = Math.abs(item.End.getTime() - oldStart.getTime());
                            item.End = new Date(item.Start.getTime() + diff);
                            forceUpdate();
                        }
                    }}
                />
            </Grid>
            <Grid xs={6} item className="message_right_leftcombo">
                <SimpleDatePicker
                    key={`End_${GetHashCode(item.End)}`}
                    label={Trad("Fin")}
                    min={new Date(item.Start)}
                    defaultValue={item.End ? new Date(item.End) : undefined}
                    onChange={(event) => {
                        if (event.value < new Date(item.Start))
                            item.End = new Date(item.Start);
                        else
                            item.End = event.value;
                        forceUpdate()
                    }}
                    resetDate={() => {
                        item.End = null;
                        forceUpdate()
                    }}
                />
            </Grid>
        </Grid>

        <div className="adw-title adw-form-title">
            <div>{getIcon("assessment")}</div>
            {Trad("financial_agreement")}
        </div>

        <AgreementDiscounts agreement={item} isGlobalAgreement />
    </>);
}