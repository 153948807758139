import { Client } from 'hub-lib/client/client.bin';
import { ref_Periodicity } from 'hub-lib/models/orientdb/ref_Periodicity.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { extractSub, JSONEqualityComparer } from 'hub-lib/tools.bin';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad } from "trad-lib";
import { setMessage } from '../../../../redux/messageEditorSlice';
import { RootState, store } from '../../../../redux/store';
import { SimpleDatePicker } from "../../../ConfigurableComponents/SimpleDatepicker.bin";
import { addDays, addMonths, subDays, addYears } from 'date-fns';
import { Notify } from '../../../../utils/Notify.bin';
import { ConsoleDebug } from '../../../../utils/localstorage.bin';
import { DateNoZone } from 'tools-lib';

const periodicityHandler = {
    others: (date: Date) => date,
    daily: (date: Date) => date,
    weekly: (date: Date) => addDays(date, 6),
    fortnightly: (date: Date) => addDays(date, 13),
    monthly: (date: Date) => subDays(addMonths(date, 1), 1),
    bi_monthly: (date: Date) => subDays(addMonths(date, 2), 1),
    quarterly: (date: Date) => subDays(addMonths(date, 3), 1),
    bi_annual: (date: Date) => subDays(addMonths(date, 6), 1),
    annual: (date: Date) => subDays(addYears(date, 1), 1)
}

export async function changeEndValue(message: ref_Messages) {

    if (!message.Start) {
        message.End = undefined;
        return;
    }

    const start = new Date(message.Start);
    const publications = store.getState().messageEditor.publications?.map(p => ({ ...p, Publication: DateNoZone(p.Publication) }));

    // sort publications by Publication date asc
    publications?.sort((a, b) => a.Publication.getTime() - b.Publication.getTime());

    ConsoleDebug(`[changeEndValue] message.Start`, message.Start);
    ConsoleDebug(`[changeEndValue] publications`, publications);
    if (publications?.length) {
        for (let i = 0; i < publications.length; i++) {
            const publication = publications[i];
            const next = publications[i + 1];
            if (next && publication.Publication.getTime() <= start.getTime() && next.Publication.getTime() > start.getTime()) {
                ConsoleDebug(`[changeEndValue] current publication`, publication);
                ConsoleDebug(`[changeEndValue] next publication`, next);
                const endDate = subDays(next.Publication, 1);
                if (message.End?.getTime() != endDate?.getTime()) {
                    ConsoleDebug(`[changeEndValue] new end`, endDate);
                    //Notify(Trad("new_end_date_from_publications"), "warning");
                    message.End = endDate;
                }
                return;
            }
        }
    }

    const [periodicity] = await Client.searchVertexTyped(ref_Periodicity, { "@rid": message.ModelProperties.Periodicity });
    if (periodicity?.Name) {
        const newEnd = periodicityHandler[periodicity.Name](start);
        if (message.End?.getTime() != newEnd?.getTime()) {
            Notify(Trad("new_end_date_from_periodicity"), "warning");
            message.End = newEnd;
        }
    }
    else message.End = start ?? null
}

export function DiffusionEditor() {

    const { Start } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ["Start"]) ?? {}, JSONEqualityComparer);

    return <SimpleDatePicker
        key={`DiffusionEditor-${Start?.getTime?.()}`}
        label={`${Trad("broadcasting_date")} *`}
        defaultValue={Start ? new Date(Start) : null}
        onChange={async ({ value }) => {
            const message = store.getState().messageEditor.getMessage();
            message.Start = value;
            await changeEndValue(message);
            if (message.ModelProperties?.Periodicity)
                changeEndValue(message);
            store.dispatch(setMessage(message));
        }} />
}