import { Grid, TextField } from "@material-ui/core";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import React from "react";
import { Trad, TradProp } from "trad-lib";
import { CustomNumericTextBox } from "../Generic/Common.bin";
import { Typed } from "hub-lib/tools.bin";

type propertyDescriptorType = { field: keyof ref_Campaigns['Performances'][0], type: "number" | "string", isEditable: boolean };
const propertyDescriptor = (field: keyof ref_Campaigns['Performances'][0], type: "number" | "string", isEditable: boolean = true) => (Typed<propertyDescriptorType>({
    field,
    type,
    isEditable
}));

const campaignPropertiesBase = [
    propertyDescriptor("wave", "string"),
    propertyDescriptor("target", "string"),
    propertyDescriptor("GRP", "number"),
    propertyDescriptor("contacts", "number"),
    propertyDescriptor("coverage", "number"),
    propertyDescriptor("coverage_thousands", "number"),
    propertyDescriptor("repetition", "number")
]

class CampaignPerformanceArgs {
    campaign: ref_Campaigns;
    kpis: ref_Campaigns['KPIs'];
    performances: ref_Campaigns['Performances'][0];
    campaignPropertiesFilter?: (property: propertyDescriptorType) => boolean;
    activeCGrp?: boolean = true;
}

export function CampaignPerformance(props: CampaignPerformanceArgs) {

    const { campaign, performances, kpis, campaignPropertiesFilter, activeCGrp } = { ...new CampaignPerformanceArgs, ...props };
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const campaignProperties = campaignPropertiesBase.filter(campaignPropertiesFilter ?? (() => true));

    const cgrpGross = (kpis?.Gross ?? 0) / (performances?.GRP || 1);
    const cgrpNetCO = (kpis?.NetCO ?? 0) / (performances?.GRP || 1);
    return <>
        <Grid item xs={12} className="adw-title">{Trad("performances")}</Grid>
        <Grid container className='block-container'>
            {campaignProperties.map((property, i) =>
                <Grid key={`${property.field}-${i}`} item xs={6} className={i % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                    {property.type === "number"
                        ? <NumberEditor value={performances[property.field] as any} label={Trad(property.field)} onChange={(newValue: any) => { (performances as any)[property.field] = newValue; forceUpdate?.() }} />
                        : <TextEditor value={performances[property.field] as any} label={Trad(property.field)} onChange={(newValue: any) => { (performances as any)[property.field] = newValue; forceUpdate?.() }} />}
                </Grid>)}

            {activeCGrp
                && <>
                    <Grid item xs={6} className={campaignProperties.length % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                        <NumberEditor value={cgrpGross} label={TradProp('cgrpGross')} disabled />
                    </Grid>

                    <Grid item xs={6} className={(campaignProperties.length + 1) % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                        <NumberEditor value={cgrpNetCO} label={TradProp('cgrpNetCO')} disabled />
                    </Grid>
                </>}
        </Grid>
    </>
}

class NumberEditorArgs { label: string; value: number; onChange?: (value: number) => void; disabled?: boolean = false }
function NumberEditor(props: NumberEditorArgs) {
    const { label, value, onChange, disabled } = { ...new NumberEditorArgs(), ...props };
    return <CustomNumericTextBox
        disabled={disabled}
        min={0}
        title={label}
        value={value}
        onChange={(e) => {
            const value = e.value;
            onChange?.(value);
        }} />
};

type TextEditorArgs = { label: string, value: string, onChange: (value: string) => void }
function TextEditor({ label, value, onChange }: TextEditorArgs) {
    return <TextField
        // key={modelInfo.Name}
        // disabled={_isDisable}
        autoComplete='off'
        style={{ width: '100%' }}
        label={label}
        type={"Text"}
        value={value}
        variant="outlined"
        onChange={(e) => {
            onChange(e.target.value);
        }}
        InputLabelProps={{ shrink: true }}
    />
};
