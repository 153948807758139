import * as React from "react";

import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { AdwRow, ADWColumn } from "adwone-lib/index";
import Loader from "../../layout/Loader";
import { GetCellTemplate, GetOrder } from "format-lib/index.bin";
import { Trad, TradProp } from "trad-lib";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { UserExtended } from "hub-lib/models/UserExtended.bin"
import { propertyOf } from "hub-lib/tools.bin";
import { CustomGroupButton } from "../../ConfigurableComponents/CustomGroupButton.bin";
import { ExportButtonProps } from "../../../styles/theme";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from "../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";

class TState {
    grid?: VertexGrid<UserExtended>;
}

export class SuperUsersGrid extends React.Component<any, TState> {

    constructor(props: any) {
        super(props);
        this.state = new TState();
    }

    componentDidMount() {

        let columns: ADWColumn<UserExtended>[] = [];
        let hiddenProperties: string[] = [];
        let properties: string[] = [];
        hiddenProperties.push(propertyOf<UserExtended>("status"));
        hiddenProperties.push(propertyOf<UserExtended>("password"));
        hiddenProperties.push(propertyOf<UserExtended>("lastConnexion"));
        hiddenProperties.push(propertyOf<UserExtended>("roles"));
        hiddenProperties.push(propertyOf<UserExtended>("registrationDate"));
        hiddenProperties.push(propertyOf<UserExtended>("job"));
        hiddenProperties.push(propertyOf<UserExtended>("phone"));
        hiddenProperties.push(propertyOf<UserExtended>("isDefaultPassword"));
        hiddenProperties.push(propertyOf<UserExtended>("Rights"));

        /** company */
        let company = propertyOf<UserExtended>("company");
        let columnCompany = new ADWColumn<UserExtended>(TradProp(company, UserExtended), company, ePropType.Embedded, false);
        columnCompany.getValue = async (row: UserExtended) => {
            return row.company?.Name;
        }
        columns.push(columnCompany);
        hiddenProperties.push(company);

        /** person */
        let person = propertyOf<UserExtended>("person");
        let columnperson = new ADWColumn<UserExtended>(TradProp(person, UserExtended), person, ePropType.Embedded, false);

        columnperson.getValue = (cellValue: UserExtended) => {
            return Promise.resolve(cellValue ? `${cellValue.person.FirstName} ${cellValue.person.LastName}` : '')
        }

        columnperson.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            dataItem['person_cellValue'] = cellValue;
            return cellValue
        }
        columns.push(columnperson);
        hiddenProperties.push(person);

        /** is online */
        let isOnline = propertyOf<UserExtended>("isOnline");
        let columnisOnline = new ADWColumn<UserExtended>(TradProp(isOnline, UserExtended), isOnline, ePropType.Link, false);
        columnisOnline.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            dataItem['isOnline_cellValue'] = cellValue ? 1 : 0;
            return cellValue ? <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#3AB36E", margin: 'auto' }}></div> : <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#DA3240", margin: 'auto' }}></div>
        }
        columnisOnline.width = 100
        columns.push(columnisOnline);
        hiddenProperties.push(isOnline);

        /** Session time */
        let sessionTime = propertyOf<UserExtended>("sessionsTime");
        let columnTimeSession = new ADWColumn<UserExtended>(TradProp(sessionTime, UserExtended), sessionTime, ePropType.Link, false);
        columnTimeSession.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            dataItem['sessionsTime_cellValue'] = Number(cellValue);

            const elapsed = Math.round(Number(cellValue) / 1000);

            if (elapsed == 0) return ""

            let sec = elapsed % 60;
            let min = Math.trunc(elapsed / 60) % 60;
            let hh = Math.trunc(Math.trunc(elapsed / 60) / 60) % 24;
            let jj = Math.trunc(Math.trunc(Math.trunc(elapsed / 60) / 60) / 24);

            return `${jj}j ${hh}h ${min}min ${sec}sec`;
        }
        columns.push(columnTimeSession);
        hiddenProperties.push(sessionTime);

        /** Last access format */
        let lastAccess = propertyOf<UserExtended>("lastAccess");
        let columnLastAccess = new ADWColumn<UserExtended>(TradProp(lastAccess, UserExtended), lastAccess, ePropType.Date, false);
        columnLastAccess.noTemplate = true;
        columnLastAccess.getValue = async (row: UserExtended) => {

            let cellValue: any = row.lastAccess;
            if (!cellValue || cellValue === "") return "";

            let str = GetCellTemplate(ePropType.Date)(cellValue);

            let d: Date = new Date(cellValue);
            let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
            let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
            let s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
            let time = `${h}:${m}:${s}`

            return `${str} ${time}`;
        }
        columnLastAccess.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            if (!dataItem.dataItem.lastAccess) return 0;
            dataItem[lastAccess + "_cellValue"] = new Date(dataItem.dataItem.lastAccess);
            return new Date(dataItem.dataItem.lastAccess);
        }
        columns.push(columnLastAccess);
        hiddenProperties.push(lastAccess);

        let grid = new VertexGrid<UserExtended>({
            objectPrototype: UserExtended,
            devMode: false,
            width: {
                isOnline: 100
            },
            columns,
            vertexParams: {
                Active: true,
                includeLogs: true,
                excludeRights: true,
                properties: ["*", ...properties]
            },
            hiddenProperties,
            order: GetOrder<UserExtended>(UserExtended),
            columnParams: {
                person: { editable: false },
                company: { editable: false },
                name: { editable: false },
                lastAccess: { editable: false },
                lastConnexion: { editable: false },
                mail: { editable: false }
            },
        });
        this.setState({
            grid
        })
    }



    render() {

        if (!this.state.grid)
            return <Loader />;

        return (
            <div style={{ width: '100%' }}>
                <ToolbarAdw>
                    <ConfigurationPanelContainer>
                        {/* {confComponent} */}
                    </ConfigurationPanelContainer>
                    <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom
                            elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("monitoring_activity") }
                            ]} />
                    </BreadcrumbsCustomContainer>
                </ToolbarAdw>
                <div style={{ width: '100%', display: 'flex' }}>
                    <AdwTelerikGrid
                        grid={this.state.grid}
                        uneditable
                        hideToolbar
                        customButtons={
                            <CustomGroupButton
                                {...ExportButtonProps()}
                                buttons={[
                                    { Label: "CSV", onClick: () => this.state.grid.exportExcel("csv") },
                                    /*{ Label: Trad("formated"), onClick: () => this.state.grid.exportExcel("formated") }*/
                                ]}
                                style={{ float: 'right' }} />
                        } />
                </div>
            </div>
        )
    }
}