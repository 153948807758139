import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_SubAgenciesId = rid;

export class ref_SubAgencies extends V implements Referentials {
	"@rid"?: ref_SubAgenciesId;
	End: Date;
	Name: string;
	Start: Date;
	Active: boolean;

}