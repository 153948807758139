import * as React from "react";
import { Client } from "hub-lib/client/client.bin";
import { AdvertiserHierarchyFilter, ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { clone, compareObjects, firstOrDefault, GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { ProjectState, saveExport, saveFilters, saveTable } from "../../../redux/projectSlice";
import { IconAction } from "../ModeleCreator/ModeleCreatorBase";
import { ref_ConfigurationsBase } from "hub-lib/models/orientdb/ref_ConfigurationsBase.bin";
import { RootState, store } from "../../../redux/store";
import { ref_ProjectConfigurations } from "hub-lib/models/orientdb/ref_ProjectConfigurations.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { ref_SchedulerConfigurations } from "hub-lib/models/ref_SchedulerConfigurations.bin";
import { ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";

type SaveButtonProps = { type: string, getConfiguration?: () => any; saveConfiguration?: (conf: any) => void }
export function SaveButton({ type, getConfiguration, saveConfiguration }: SaveButtonProps) {

    const key = GetProjectKey(type);
    const [baseConfiguration, setBaseConfiguration] = React.useState<ref_ConfigurationsBase>();
    const configuration = getConfiguration ? getConfiguration() : useSelector((root: RootState) => root.project[key], compareObjects);

    React.useEffect(() => {
        if (configuration?.["@rid"] && (baseConfiguration?.["@rid"] != configuration["@rid"]))
            Client.searchVertex(type, { '@rid': configuration["@rid"] })
                .then((res) => setBaseConfiguration(firstOrDefault(res.data.results)));
    });

    const isDisabled = !baseConfiguration
        || !configuration?.["@rid"]
        || Compare(configuration, baseConfiguration)

    return <IconAction
        disabled={isDisabled}
        onClick={() => {
            if (saveConfiguration) {
                saveConfiguration(configuration);
            } else {
                Save(configuration);
            }
            setBaseConfiguration(configuration);
        }}
        tooltip={Trad("save")} icon={"save"} />
}

function Save(configuration: ref_ConfigurationsBase) {
    switch (configuration.DocumentType) {
        case ref_FilterConfigurations.name:
            store.dispatch(saveFilters(configuration as ref_FilterConfigurations));
            break;
        case ref_TableConfigurations.name:
            store.dispatch(saveTable(configuration as ref_TableConfigurations));
            break;
        case ref_ExportConfigurations.name:
            store.dispatch(saveExport(configuration as ref_ExportConfigurations));
            break;
    }
}

function Compare(configuration: ref_ConfigurationsBase, base: ref_ConfigurationsBase) {
    switch (configuration.DocumentType) {
        case ref_FilterConfigurations.name:
            return compareObjects(AdvertiserHierarchyFilter.getFilters((configuration as ref_FilterConfigurations).Filters), AdvertiserHierarchyFilter.getFilters((base as ref_FilterConfigurations).Filters));
        default:
            return compareObjects(configuration, base);
    }
    return false;
}

export function GetProjectKey(type: string): keyof Pick<ProjectState, 'columnsModele' | 'filtersModele' | 'project' | "exportModele"> {
    switch (type) {
        case ref_FilterConfigurations.name:
            return 'filtersModele';
        case ref_TableConfigurations.name:
            return 'columnsModele';
        case ref_ProjectConfigurations.name:
            return 'project';
        case ref_ExportConfigurations.name:
            return "exportModele";
    }
}

export function GetStoreModeleElement<T>(type: string) {
    const modele = store.getState().project[GetProjectKey(type)];
    return clone(modele) as any as T;
}