import { DateNoZone } from "tools-lib";
import { TabManagerEvent, eventTabManagerChanged, TabManager, ITab } from "../components/tabPanel/TabManager.bin";
import { GenericComponentTab } from "../components/tabPanel/GenericComponentTab.bin";
import { clone, duplicateObject, extractSub, hasOwnProperty, propertyOf } from "hub-lib/tools.bin";
import { AdvertiserHierarchyFilter, Filter } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_ProjectConfigurations } from "hub-lib/models/orientdb/ref_ProjectConfigurations.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { UserExtended } from "hub-lib/models/UserExtended.bin";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";
import { ClassProperty } from "hub-lib/models/VertexProperty.bin";
import { Client } from "hub-lib/client/client.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ref_SchedulerConfigurations } from "hub-lib/models/ref_SchedulerConfigurations.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";

TabManagerEvent.addListener(eventTabManagerChanged, SetTabManager);


let keyConfTab = 'configuration-tabs-';
let keyLayout = 'dashboard-layout';
let keyDicoTabMgr = 'dictionary-tabmanager-1';
let keyExport = 'export-config-params-1';

const UserKey = () => "User-" + JSON.parse(localStorage.getItem('user'))?.["@rid"];

export function GetUser(): UserExtended {
    return JSON.parse(localStorage.getItem('user')) ?? {};
}

export function IsMMUser() {
    return GetUser()?.sources?.some(s => s["@class"] == src_MM.name)
}

export function GetSrcMMUser() {
    return GetUser()?.sources?.find(s => s["@class"] == src_MM.name)
}

export function GetSrcTSMUser() {
    return GetUser()?.sources?.find(s => s["@class"] == src_TSM.name)
}

class Storage {
    protected static vertex(): new () => any {
        throw new Error("modeleFilterKey not implemented");
    }

    protected static modeleFilterKey(): string {
        throw new Error("modeleFilterKey not implemented")
    };

    static get(repair: boolean = true) {
        const store = localStorage.getItem(this.modeleFilterKey());
        if (store) {
            const parsed = this.parse(store);
            if (repair) {
                return this.repair(parsed, this.vertex());
            }
            return parsed;
        }
        return null;
    }

    static repair<T>(store: T, Vertex: new () => T): T {
        return { ...(new Vertex()), ...store };
    }

    static set(data: any): void {
        localStorage.setItem(this.modeleFilterKey(), JSON.stringify(data));
    }

    static clear(): void {
        console.log(`clearing ${this.modeleFilterKey()} localstorage`);
        localStorage.removeItem(this.modeleFilterKey());
    }

    static parse<T>(store: string): T {
        try {
            return JSON.parse(store);
        } catch (error) {
            // add error
            console.log(error);
        }
    }

    static getLocalStorageValue(_key: string) {
        const store = localStorage.getItem(UserKey() + "-" + _key);
        return store ? this.parse<any>(store) : undefined;
    }

    static setLocalStorageValue(_key: string, data: any) {
        const store = localStorage.setItem(UserKey() + "-" + _key, JSON.stringify(data));
        return store;
    }

    static isValid<T>(store: T) {
        const vertexName = this.vertex().name;
        const metadata = MetadataStorage.get();
        if (!metadata[vertexName]) {
            return true;
        }
        const metaerror = metadata[vertexName].filter(m => m.mandatory && !hasOwnProperty(store, m.name as any));
        if (metaerror.length) {
            console.log("⚠ localstorage [isValid] properties missing", metaerror);
        }

        return metaerror.length === 0;
    }
}

const timeout = 600000 * 6 * 24;
let intervalTrads = undefined;
type Metadatas = { [key: string]: ClassProperty[] };
type StorageMetadatas = {
    expires: string;
    metadatas: Metadatas;
};
export let defaultFilterConfig: ref_FilterConfigurations;
export let defaultProjectConfig: ref_ProjectConfigurations;
export let defaultExportConfig: ref_ExportConfigurations;
export let defaultTableConfig: ref_TableConfigurations;
export let defaultSchedulerConfig: ref_SchedulerConfigurations;
export class MetadataStorage extends Storage {
    protected static modeleFilterKey = () => "all-metadatas";

    static async initialize() {
        const data = this.get(true);
        if (!Object.keys(data).length) {
            const metadatas = {};
            metadatas[ref_FilterConfigurations.name] = (await Client.getMetadata(ref_FilterConfigurations.name)).data.results;
            metadatas[ref_TableConfigurations.name] = (await Client.getMetadata(ref_TableConfigurations.name)).data.results;
            metadatas[ref_ProjectConfigurations.name] = (await Client.getMetadata(ref_ProjectConfigurations.name)).data.results;
            metadatas[ref_ExportConfigurations.name] = (await Client.getMetadata(ref_ExportConfigurations.name)).data.results;
            metadatas[ref_SchedulerConfigurations.name] = (await Client.getMetadata(ref_SchedulerConfigurations.name)).data.results;
            this.set(metadatas);

            clearInterval(intervalTrads);
            intervalTrads = setInterval(() => this.initialize(), timeout);
        }
        if (!defaultFilterConfig) {
            const [defaultConf] = await Client.searchVertexTyped(ref_FilterConfigurations, {
                [propertyOf<ref_FilterConfigurations>('Default')]: true,
                [propertyOf<ref_FilterConfigurations>('Table')]: ref_Messages.name,
            });
            defaultFilterConfig = defaultConf;
        }
        if (!defaultProjectConfig && RightManager.hasRight(eFunctions.ref_ProjectConfigurations, eRights.read)) {
            const [defaultConfProject] = await Client.searchVertexTyped(ref_ProjectConfigurations, {
                [propertyOf<ref_ProjectConfigurations>('Default')]: true,
                [propertyOf<ref_ProjectConfigurations>('Table')]: ref_Messages.name,
            });
            defaultProjectConfig = defaultConfProject;
        }
        if (!defaultExportConfig) {
            const [defaultConfExport] = await Client.searchVertexTyped(ref_ExportConfigurations, {
                [propertyOf<ref_ExportConfigurations>('Default')]: true,
                [propertyOf<ref_ExportConfigurations>('Table')]: ref_Messages.name,
            });
            defaultExportConfig = defaultConfExport;
        }
        if (!defaultTableConfig) {
            const [defaultConfTable] = await Client.searchVertexTyped(ref_TableConfigurations, {
                [propertyOf<ref_TableConfigurations>('Default')]: true,
                [propertyOf<ref_TableConfigurations>('Table')]: ref_Messages.name,
            });
            defaultTableConfig = defaultConfTable;
        }
        if (!defaultSchedulerConfig) {
            const [defaultConfScheduler] = await Client.searchVertexTyped(ref_SchedulerConfigurations, {
                [propertyOf<ref_SchedulerConfigurations>('Default')]: true,
                [propertyOf<ref_SchedulerConfigurations>('Table')]: ref_Messages.name,
            });
            defaultSchedulerConfig = defaultConfScheduler;
        }
    }

    static get(checkTime: boolean = false): Metadatas {
        const storage = super.get(false) as StorageMetadatas;
        if (storage) {
            if (checkTime) {
                let expiration = new Date(storage.expires);
                if (new Date().getTime() < expiration.getTime())
                    return storage.metadatas;
                else
                    return {};
            } else {
                return storage.metadatas;
            }
        }
        return {};
    }

    static set(data: { [key: string]: ClassProperty[] }): void {
        let expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + timeout);

        let storage: StorageMetadatas = {
            expires: expires?.toString(),
            metadatas: data
        }

        super.set(storage);
    }
}
export class FilterStorage extends Storage {

    protected static vertex = () => ref_FilterConfigurations;
    protected static modeleFilterKey = () => UserKey() + "-" + ref_FilterConfigurations.name;

    static repairFilters(filterConf: ref_FilterConfigurations) {
        if (filterConf.Filters) {
            filterConf.Filters = ClearEmptyValues(filterConf.Filters) ?? {};
            filterConf.Filters = extractSub(filterConf.Filters, Object.keys(new AdvertiserHierarchyFilter()) as (keyof AdvertiserHierarchyFilter)[]);

            // if (filterConf.Filters.Start) filterConf.Filters.Start = DateNoZone(filterConf.Filters.Start);
            // else filterConf.Filters.Start = new Filter().Start;
            // if (filterConf.Filters.End) filterConf.Filters.End = DateNoZone(filterConf.Filters.End);
            // else filterConf.Filters.End = new Filter().End;
            // if (!filterConf.Filters.Source) filterConf.Filters.Source = new Filter().Source;
        }
    }

    static get(): ref_FilterConfigurations {
        const parsed = super.get() as ref_FilterConfigurations;
        if (parsed) {
            FilterStorage.repairFilters(parsed);
            if (this.isValid(parsed)) {
                return parsed;
            }
            this.clear();
        }
        return clone(defaultFilterConfig);
    }

    static getAdvancedFilters(): Partial<Filter> {
        const store = localStorage.getItem(UserKey() + "-" + 'Advanced-filters');
        if (store) {
            const parsed = this.parse<Filter>(store);
            if (parsed.Start) parsed.Start = DateNoZone(parsed.Start);
            else parsed.Start = new Filter().Start;
            if (parsed.End) parsed.End = DateNoZone(parsed.End);
            else parsed.End = new Filter().End;
            if (!parsed.Source) parsed.Source = new Filter().Source;
            return parsed;
        }

        return clone(new Filter());
    }

    static setAdvancedFilters(filters: Partial<Filter>) {
        this.setLocalStorageValue('Advanced-filters', filters ?? {});
    }

    static set(filter: ref_FilterConfigurations) {
        if (filter.Filters)
            filter.Filters = ClearEmptyValues(filter.Filters);
        FilterStorage.setAdvancedFilters(filter?.Filters);
        FilterStorage.repairFilters(filter);
        this.setLocalStorageValue(ref_FilterConfigurations.name, filter);
    }

    static GetLocalStorageFiltersObj(): Partial<Filter> {
        return this.getAdvancedFilters();
    }
}

export class TableStorage extends Storage {

    protected static vertex = () => ref_TableConfigurations;
    protected static modeleFilterKey = () => UserKey() + "-" + ref_TableConfigurations.name;

    static set(table: ref_TableConfigurations) {
        super.set(table ?? {});
    }

    static get(): ref_TableConfigurations {
        const parsed = super.get() as ref_TableConfigurations;
        if (parsed) {
            if (this.isValid(parsed) && !parsed.Default) // on récupère systématiquement le défaut
                return parsed;
            this.clear();
        }

        return clone(defaultTableConfig);
    }

}
export class ProjectStorage extends Storage {

    protected static vertex = () => ref_ProjectConfigurations;
    protected static modeleFilterKey = () => UserKey() + "-" + ref_ProjectConfigurations.name;

    static get(): ref_ProjectConfigurations {
        const parsed = super.get() as ref_ProjectConfigurations;
        if (parsed) {
            if (this.isValid(parsed))
                return parsed;
            this.clear();
        }

        return clone(defaultProjectConfig);
    }

    static set(data: ref_ProjectConfigurations) {
        this.setLocalStorageValue(ref_ProjectConfigurations.name, data);
    }
}

export class ExportStorage extends Storage {

    protected static vertex = () => ref_ExportConfigurations;
    protected static modeleFilterKey = () => UserKey() + "-" + ref_ExportConfigurations.name;

    static get(): ref_ExportConfigurations {
        const parsed = super.get() as ref_ExportConfigurations;
        if (parsed) {
            if (this.isValid(parsed))
                return parsed;
            this.clear();
        }

        return clone(defaultExportConfig);
    }

    static set(exportConfig: ref_ExportConfigurations) {
        this.setLocalStorageValue(ref_ExportConfigurations.name, exportConfig);
    }
}

export class SchedulerStorage extends Storage {

    protected static vertex = () => ref_SchedulerConfigurations;
    protected static modeleFilterKey = () => UserKey() + "-" + ref_SchedulerConfigurations.name;

    static get(): ref_SchedulerConfigurations {
        const parsed = super.get() as ref_SchedulerConfigurations;
        if (parsed) {
            if (this.isValid(parsed))
                return parsed;
            this.clear();
        }

        return clone(defaultSchedulerConfig);
    }

    static set(config: ref_SchedulerConfigurations) {
        this.setLocalStorageValue(ref_SchedulerConfigurations.name, config);
    }
}

// get storage class from string
export function getStorageClass(name: string) {
    switch (name) {
        case ref_FilterConfigurations.name:
            return FilterStorage;
        case ref_TableConfigurations.name:
            return TableStorage;
        case ref_ProjectConfigurations.name:
            return ProjectStorage;
        case ref_ExportConfigurations.name:
            return ExportStorage;
        case ref_SchedulerConfigurations.name:
            return SchedulerStorage;
        default:
            return Storage;
    }
}

function GetLocalStorageChartFilters(chartKey: string) {
    return localStorage.getItem(UserKey() + "-" + chartKey);
}
export function GetLocalStorageFiltersChartObj(chartKey: string) {
    let store = GetLocalStorageChartFilters(chartKey);
    if (store) {
        let res = JSON.parse(store);
        if (res.Start) res.Start = DateNoZone(res.Start);
        if (res.End) res.End = DateNoZone(res.End);
        if (res.Source)
            delete res.Source;
        return res;
    }
    return undefined;
}
export function SetLocalStorageChartFilters(chartKey: string, params: any) {
    localStorage.setItem(UserKey() + "-" + chartKey, JSON.stringify(params));
}
export function RemoveLocalStorageChartFilters(chartKey: string) {
    localStorage.removeItem(chartKey)
}

export function IsPerformanceDebugMode() {
    return localStorage.getItem("perfMode") === "1";
}

let timeMeasureId = 0;
const timeMeasures = {}
export function StartMeasureTime() {
    if (!IsPerformanceDebugMode()) return;
    const id = ++timeMeasureId;
    timeMeasures[id] = new Date().getTime();
    return id;
}

export function PrintMesureTime(idMeasure: number, msg?: string) {
    if (!IsPerformanceDebugMode()) return;
    const elapsed = new Date().getTime() - timeMeasures[idMeasure];
    delete timeMeasures[idMeasure];
    console.log(`[DEBUG] ${msg ?? "Elapsed: "} ${elapsed}ms`);
}

export function IsDebugMode() {
    return localStorage.getItem("devMode") === "1";
}

export function ConsoleDebug(message?: any, ...optionalParams: any[]) {
    if (IsDebugMode())
        console.log(`[DEBUG] ${message}`, ...optionalParams);
}


export function SetLocalStorageExportFormatedConfiguration(params: Partial<ref_ExportConfigurations>) {
    if (IsDebugMode()) {
        console.log(`SetLocalStorageExportFormatedConfiguration`, params);
        console.log(`SetLocalStorageExportFormatedConfiguration2`, duplicateObject(params));
    }

    localStorage.setItem(keyExport, JSON.stringify(duplicateObject(params)));
}

export function GetLocalStorageExportFormatedConfiguration(): Partial<ref_ExportConfigurations> {
    let res: any = localStorage.getItem(keyExport);
    res = res && JSON.parse(res);

    if (IsDebugMode())
        console.log(`GetLocalStorageExportFormatedConfiguration`, res);

    return res;
}

export function GetLocalStorageNotifications(limit: number) {
    const notifStorage = localStorage.getItem('notifications');
    if (!notifStorage)
        return [];
    return JSON.parse(notifStorage).reverse().slice(0, limit)
}

function ClearEmptyValues(state: any) {
    let newParams = { ...state };
    for (let [key, value] of Object.entries(state)) {
        if (Array.isArray(value) && value.length === 0) {
            delete newParams[key];
        }
    }
    return newParams;
}


/**
 * Configuration tableau
 */



/**
 * Configuration tableau
 * @param key
 */
// export function GetConfigurationTab(keyStr: string) {
//     return localStorage.getItem(keyConfTab + keyStr);
// }

/**
 * Configuration tableau
 * @param key
 * @param value
 */
// export function SetConfigurationTab(keyStr: string, value: string) {
//     return localStorage.setItem(keyConfTab + keyStr, value);
// }


/**
 * DashBoard layout
 */

export function GetLayout() {
    let store = localStorage.getItem(keyLayout);
    if (store)
        return JSON.parse(store);
    return [];
}

export function SetLayout(value: any) {
    return localStorage.setItem(keyLayout, JSON.stringify(value));
}


/**
 * TAB manager
 */
export function SetTabManager(tabManager: TabManager) {

    let store = localStorage.getItem(keyDicoTabMgr);
    let dico = store ? JSON.parse(store) : {};
    dico[tabManager.id] = tabManager;
    localStorage.setItem(keyDicoTabMgr, JSON.stringify(dico));

}

export function GetTabManager(id: string) {
    let store = localStorage.getItem(keyDicoTabMgr);
    let dico = store ? JSON.parse(store) : {};
    let res: TabManager = dico[id];
    if (res) {
        let tabMgr = new TabManager(id);
        tabMgr.initMode = true;
        res?.tabs.forEach((tab: ITab) => {
            let newTab = new GenericComponentTab();
            newTab.id = tab.id;
            newTab.name = tab.name;
            tabMgr.addTab(newTab);

            let conf = (tab as GenericComponentTab).configuration;
            if (conf.crossedTable) {
                conf.crossedTable.selectedIndicateurs = [...conf.crossedTable.selectedIndicateurs].filter(s => s); // securité
                conf.crossedTable.Start = new Date(conf.crossedTable.Start);
                conf.crossedTable.End = new Date(conf.crossedTable.End);
            }
            newTab.configuration = conf;
        })

        tabMgr.selectedTab = tabMgr.tabs.find(tab => tab?.id === res.selectedTab?.id);
        tabMgr.initMode = false;
        tabMgr.autoId = res.autoId;
        res = tabMgr;
    }

    return res;
}