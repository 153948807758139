import * as React from "react"
import { ExternalCampaignImport } from "hub-lib/models/custom/ExternalCampaignImport";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { VertexGrid } from "../VertexGrid/Generic/VertexGrid.bin";
import { AdwTelerikGrid } from "../VertexGrid/Generic/AdwTelerikGrid.bin";
import Loader from "../layout/Loader";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { Trad } from "trad-lib";
import { getIcon } from "adwone-lib/index";
import { eDialogMode } from "../ConfigurableComponents/GenericDialog.bin";
import { GetHashCode, JSONEqualityComparer, propertyOf } from "hub-lib/tools.bin";
import { CreateIndicateurInfo } from "adwone-engine/index.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { styleGridContainer } from "../../styles/theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setMessageSync } from "../../redux/messageEditorSlice";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { CreateGrid } from "../VertexGrid/Messages/MessagesGridCreatorOld";
import { MessagesDialog } from "../VertexGrid/Messages/MessagesDialog.bin";

class TProps {
    campaignImport: ExternalCampaignImport;
    onChange?: () => void;
}

class TState {
    grid?: VertexGrid<ref_Messages>;
    messageOpended?: ref_Messages;
    loaded: boolean = false;
    campaignImport: ExternalCampaignImport;
}

let timeout = null;
export function MessageValidation({ campaignImport, onChange }: TProps) {

    const dispatch = useDispatch();
    const messageEdited = useSelector((root: RootState) => root.messageEditor.getMessage(), JSONEqualityComparer);
    const [state, setState] = React.useState<TState>(new TState());

    React.useEffect(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            DrawGrid();
        }, 500);
    }, [GetHashCode(campaignImport.Links), campaignImport.Referential?.["@rid"]])

    const adapts = async (messages: ref_Messages[], properties: { [prop in keyof ref_Messages]?: string }) => {
        for (const [k, v] of Object.entries(properties)) {

            const rids = Array.from(new Set(messages.map(m => m[k])));
            const results: (IRid & { Name: string })[] = (await Client.searchVertex(v, { "@rid": rids }))?.data?.results;

            for (const message of messages) {
                const found = results.find(r => r["@rid"] == message[k]);
                message[`${k}Name`] = found?.Name ?? "NC";
            }
        }
    }

    const DrawGrid = async (callback?: () => void) => {
        const rows = campaignImport.Messages.map(m => m.Referential);
        await adapts(rows, {
            Support: ref_Supports.name,
            Format: ref_Property.name,
            Placement: ref_Property.name,
            BroadcastArea: ref_BroadcastAreas.name,
            Currency: ref_Currencies.name,
            AdvCompany_Com: ref_AdvertisingCompanies.name,
            AdvCompany_Fin: ref_AdvertisingCompanies.name,
            Media: ref_Media.name
        });

        const stateColumn = new ADWColumn<ref_Messages>(Trad("Status"), "State");
        stateColumn.width = 110;
        stateColumn.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Messages>) => {
            const found = campaignImport.Messages.find(m => m.Referential === dataItem.dataItem);
            for (const [k, v] of Object.entries(found.MissingLinks)) {
                if (k == "Placement") continue;
                if (!v) continue;
                if (!dataItem.dataItem[k])
                    return <span className='incomplete_message'>{Trad("incomplete")}</span>
            }
            return <span className='complete_message'>{Trad("complete")}</span>;
        };

        const grid = await CreateGrid({
            vertexGridParams: {
                rows,
                configuration: {
                    Active: true,
                    Default: false,
                    Name: ref_Messages.name,
                    Owner: null,
                    Table: ref_Messages.name,
                    FrozenPosition: 0,
                    Columns: [
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Start"), eKPIType.Date),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Support"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Media"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("BroadcastArea"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Currency"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("AdvCompany_Com"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("AdvCompany_Fin"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Format"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Placement"), eKPIType.Rid)],
                    ViewMode: "Table",
                    CrossedtableConfig: undefined
                },
                forcedColumns: [stateColumn]
            },
            templateCell: (property: keyof ref_Messages, cellValue: any, dataItem: AdwRow<ref_Messages>, baseCell: any) => {
                const found = campaignImport.Messages.find(m => m.Referential === dataItem.dataItem);
                if (found.MissingLinks[property] && !dataItem.dataItem[property])
                    return <div className='import_cell_incomplete' style={{ display: 'flex' }}>{getIcon('error_filled')}<span className='text_import_cell_incomplete'>{found.MissingLinks[property].Imports?.[0]?.Label}</span></div>
                return baseCell;
            }
        });

        setState({ ...state, grid, messageOpended: undefined, loaded: true, campaignImport });
        callback?.();
    }

    const { grid, messageOpended } = state ?? {};
    return <>
        <div style={{ width: '100%', position: 'relative' }}>
            {!grid && <Loader />}
            {grid && <AdwTelerikGrid
                key={`grid-${GetHashCode(grid)}`}
                gridHeight={styleGridContainer.medialandImport.height}
                grid={grid}
                onEdit={(row) => {
                    setState({
                        ...state,
                        messageOpended: row.dataItem
                    });

                    dispatch(setMessageSync(row.dataItem));
                }}
                isCopyDisable />}
        </div>
        {messageOpended && messageEdited &&

            <MessagesDialog
                onValidate={m => {
                    Object.entries(m).forEach(([k, v]) => {
                        if (v)
                            messageOpended[k] = v
                    });
                    DrawGrid(() => onChange?.());
                    dispatch(setMessageSync(undefined));
                }}
                mode={eDialogMode.modify}
                selectedMedia={messageEdited.Media}
                open={messageEdited != undefined}
                message={messageEdited}
                handleClose={() => {
                    DrawGrid();
                    dispatch(setMessageSync(undefined));
                }} />
        }
    </>
}