import * as React from 'react';
import { getIcon } from 'adwone-lib/index';
import { Trad } from 'trad-lib';
import { GridCellProps } from '@progress/kendo-react-grid';
import { GenericDialog } from '../ConfigurableComponents/GenericDialog.bin';
import { CustomIconButton } from './Generic/CustomIconButton';
import { TooltipManager } from '../CustomTooltip';

export type CommandAction<T> = {
    function?: (item: T, event: React.MouseEvent<any, MouseEvent>) => void;
    icon?: JSX.Element | ((item: T) => JSX.Element);
    disabled?: (item: T) => boolean;
};

export type CommandCellArg<T> = {
    edit: (item: T) => void;
    add: (item: T) => void;
    update: (item: T) => void;
    discard: (item: T) => void;
    cancel: (item: T) => void;
    editField: string;
    copy: (item: T) => void;
    removeInline: (item: T) => void;
    removeInlineContent?: (item: T) => JSX.Element;
    isCopyDisable: boolean;
    deleteInline: boolean;
    isEditionDisable?: boolean;
    isEditable?: ((item: T) => boolean) | boolean;
    additionalCommands?: CommandAction<T>[];
};

function Tooltip(text: string) {
    return ({ target }) => TooltipManager.Push({ target, text });
}

export function DefaultCellActions<T>(commandCellProps: CommandCellArg<T>) {
    const { edit, add, update, discard, cancel, editField, copy, removeInline, removeInlineContent, isCopyDisable, deleteInline, isEditable, isEditionDisable, additionalCommands } = commandCellProps;
    return function ({ dataItem, rowType }: GridCellProps) {

        if (rowType == 'groupHeader')
            return <></>;

        const [isValidating, setIsValidating] = React.useState(false);
        const [toggleDialog, setToggleDialog] = React.useState(false);
        const inEdit = dataItem[editField];
        const isNewItem = dataItem.id === undefined;

        return inEdit ? (
            <td className="k-grid-content-sticky k-command-cell add-border-left clear-padding" style={{ right: '-1px', textAlign: isCopyDisable ? 'center' : 'left' }}>
                <CustomIconButton disabled={isValidating} className="picto custom_btn_secondary_validation" onClick={async () => {
                    setIsValidating(true);
                    isNewItem ? await add(dataItem) : await update(dataItem);
                    setIsValidating(false);
                }}>
                    {getIcon('done')}
                </CustomIconButton>

                <CustomIconButton disabled={isValidating} className="picto custom_btn_secondary_cancel" onClick={() => (isNewItem ? discard(dataItem) : cancel(dataItem))}>
                    {getIcon('undo')}
                </CustomIconButton>
            </td >
        ) : (
            <td className="k-grid-content-sticky k-command-cell add-border-left clear-padding padding-right " style={{ display: 'flex', justifyContent: 'space-around', right: '-1px', textAlign: isCopyDisable ? 'center' : 'left' }}>
                {!isEditionDisable && isEditable !== false
                    && <CustomIconButton
                        disabled={typeof isEditable === 'function' && !isEditable(dataItem)}
                        className={dataItem.selected == true ? 'is-selected' : 'primary_color'}
                        onClick={() => edit(dataItem)}
                        onMouseOver={Tooltip(Trad('edit'))}>
                        {getIcon('edit_inline')}
                    </CustomIconButton>}

                {(!isCopyDisable && !deleteInline) && <CustomIconButton
                    className={dataItem.selected == true ? 'is-selected' : 'primary_color'}
                    onClick={() => copy(dataItem)}
                    onMouseOver={Tooltip(Trad('duplicate'))}>
                    {getIcon('copy_inline')}
                </CustomIconButton>}

                {deleteInline && <CustomIconButton
                    className="error_color"
                    onClick={() => setToggleDialog(true)}
                    onMouseOver={Tooltip(Trad('delete'))}>
                    {getIcon('delete')}
                </CustomIconButton>}

                {additionalCommands?.map((command, index) => <CustomIconButton
                    className={dataItem.selected == true ? 'is-selected' : 'primary_color'}
                    disabled={command.disabled?.(dataItem)}
                    onClick={(event) => command.function(dataItem, event)}>
                    {typeof command.icon === 'function' ? command.icon(dataItem) : command.icon}
                </CustomIconButton>)}

                {toggleDialog
                    && <GenericDialog
                        open={toggleDialog}
                        dialogTitle={Trad('confirmation')}
                        handleClose={() => setToggleDialog(false)}
                        submitAction={() => {
                            removeInline(dataItem);
                            setToggleDialog(false);
                        }}
                        submitClass="custom_btn_danger"
                        submitTitle={Trad('yes')}
                        startIcon={getIcon('clear')}
                        actions
                    >
                        {removeInlineContent?.(dataItem)}
                        {Trad('remove_item')}
                    </GenericDialog>}
            </td>
        );
    };
}
