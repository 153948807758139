import { eIndicateurType, Indicateur } from "./index.bin";
import { Trad } from "trad-lib";
import { JoinElements } from "hub-lib/tools.bin";
import { ADWProperty } from "hub-lib/types";
import { IDatedData } from "./EngineTools";

export class CellValue {
    /** Formated value */
    Formated: string;

    /** Base value */
    Value?: any;

    /** kpi/column used */
    Indicateur?: Indicateur;

    Type: "cell" | "header";
}

export class Ventilation<T> {
    /** All data */
    Data: T[];
    Children: Ventilation<T>[];

    Dimension: Indicateur;
    Formated: string = "";
    Value: string | string[];

    constructor() {
        this.Formated = Trad("none");
    }

    getFlat: () => T[] = () => {
        if (!this.Children?.length) return this.Data;
        return this.Children.map(c => c.getFlat()).reduce((a, b) => a.concat(b));
    }
}

export function GetRowValue(row: Row<any>, field: string) {
    return row.ValuesTotal.find(v => v?.Indicateur?.field == field)?.Value;
}

export class Row<T> extends Ventilation<T>{

    /** All data in row */
    Data: T[];

    /** nb of data elements */
    CountData: number;

    Start: Date;
    End: Date;

    /** Children rows (tree) */
    Children: Row<T>[];

    /** Children aggregated by insection dates */
    AggregatedChildren?: IDatedData<T>[];

    /** All Data ventilated by columns */
    DataColumns: T[][];

    /** Total column */
    ValuesTotal: CellValue[];

    /** Values by column indexes */
    Values: CellValue[];

    constructor(dimension?: Indicateur, data?: T[]) {
        super();
        this.Values = [];
        this.DataColumns = [];
        this.Data = data;
        this.Dimension = dimension;
    }


    static getLevel(rows: Row<any>[], cur: number = 0) {
        if (!rows?.length) return cur;

        let max = cur;
        rows.forEach(r => {

            if (r.Dimension.field == '@rid')
                return;

            let calc = Row.getLevel(r.Children, cur + 1);
            if (calc > max) max = calc;
        });
        return max;
    }

    static getLevelData(rows: Row<any>[]): Row<any>[][] {
        if (!rows?.length) return [];

        const children = rows.map(c => c.Children).reduce((a, b) => a.concat(b), []).filter(c => c);
        return [rows, ...this.getLevelData(children)];
    }

    static ToArray(row: Row<any>, first: boolean = true): CellValue[][] {
        let arrays = [];

        const levels = Row.getLevelData(row.Children)

        let afterArray: CellValue[] = [];

        for (let i = 0; i < levels.length; i++) {
            const level = levels[i];
            if (level?.[0]?.Dimension?.field != '@rid')
                afterArray.push({
                    Formated: JoinElements(level.map(l => l.Formated)), Value: JoinElements(level.map(l => l.Formated)),
                    Type: "cell"
                })
        }

        let thisArray = [...afterArray, ...row.ValuesTotal];
        if (!first && row.Dimension.field != "@rid") thisArray.unshift({
            Formated: row.Formated, Value: row.Formated,
            Type: "cell"
        })

        //if (levels.length == 0)
        arrays.push(thisArray);

        row.Children?.forEach(c => {
            Row.ToArray(c, false)?.forEach(cc => {
                arrays.push(!first ? [thisArray[0], ...cc] : [...cc]);
            })
        });

        return arrays;
    }
}

export class Column {
    Id: ADWProperty | Indicateur;
    Label: string;
    IsRow: boolean;
    Children: Column[];
}

export class ArrayTable {
    array: [][];
    table: CellValue[][];
}

export class Table<T> {
    DocumentType: string;
    Columns: Column[];
    Rows: Row<T>[];
    Indicateurs: Indicateur[];
    Info?: {
        Title: string
    };
    Ventilations: (ADWProperty | Indicateur)[];
    TimeVentilations: {
        Granularity: "week" | "month" | "trimester" | "semester";
        Field: string,
        Ranges: {
            Start: Date,
            End: Date,
            Row: Row<T>
        }[]
    };

    static ToArray: (table: Table<any>) => ArrayTable = (table: Table<any>) => {

        let res = [];
        let cells: CellValue[][] = [];

        let at = new ArrayTable();
        at.array = res;
        at.table = cells;

        let addHeaders = () => {
            let headers = [...table.Columns.map(r => r.Label), ...table.Indicateurs.map(i => {
                if (i.type === eIndicateurType.info)
                    return i.name; //  already translated in that case
                return Trad(i.name);
            })];
            res.push(headers);
            cells.push(headers.map(h => { return { Formated: h, Type: "header" } }))
        }

        let addRows = () =>
            table.Rows?.forEach(r => Row.ToArray(r).forEach(c => {
                res.push(c.map(cell => {
                    if (cell.Indicateur?.type === eIndicateurType.info && cell.Formated != "" && cell.Formated)
                        return cell.Formated;
                    return cell.Value
                }));
                cells.push(c);
            }))

        addHeaders();
        addRows();

        return at;
    }
}

