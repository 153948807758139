
// Ne pas supprimer, typescript déploiement
import { Switch } from "@progress/kendo-react-inputs";
import { compareObjects, duplicate } from "hub-lib/tools.bin";
import React from "react";
import { useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../redux/store";
import { IsMMUser } from "../utils/localstorage.bin";
import { StyleFactory } from "../utils/ToolsReact";
import { GenericTooltip } from "./ConfigurableComponents/GenericTooltip.bin";
import { Element } from "./VertexGrid/Generic/Common.bin";

export function Title(props) {
    return <div className="grid_title">{props.children}</div>
}

export function Toolbar(props) {
    return <div className='clearfix custom-toolbar-adwtelerikgrid' style={{ marginTop: 30, marginBottom: 30, position: "relative" }}>{props.children}</div>;
}

type RowProps = { style?: React.CSSProperties, children?: any, className?: string }
export const Row = (props: RowProps) => {
    return <div className={"adw-row " + (props.className ?? "")} style={props?.style ?? {}}>
        {props.children}
    </div>
}

type AnchorRowProps = { children: any, anchor?: string }
export function AnchorRow({ children, anchor }: AnchorRowProps) {
    return <span
        className={`${anchor ? 'anchor-field' : ''}`}
        onClick={(e) => {
            if (anchor) {
                var loc = document.location.toString().split('#')[0];
                document.location = loc + anchor;
            }
        }}>
        {children}
    </span>
}

export const switchStyles = StyleFactory.create({
    switchContainer: { float: 'right', marginLeft: 20, display: 'flex' },
    switchLabel: { marginRight: 12 }
});

export type DataMapSwitchProps = {
    onChange?: (sources: string[]) => void
}

export function DataMapSwitch(props: DataMapSwitchProps) {

    const sources = useSelector((root: RootState) => root.project.filters.Source, compareObjects);
    const _MAPSource = "MAP";

    if (!IsMMUser())
        return <></>;
    return <Element className="adw-row">
        <GenericTooltip tooltipContent="La recherche des données MAP prend du temps. L'équipe Adwanted recommande d'utiliser uniquement les données AdwOne.">
            <div style={switchStyles.switchContainer}>
                <div style={switchStyles.switchLabel}>{Trad("switch_data_map")}</div>
                <Switch
                    size='small'
                    checked={sources?.includes(_MAPSource)}
                    disabled={sources.length == 1 && sources?.[0] == _MAPSource}
                    onChange={(event) => {
                        let newSources = duplicate(sources);
                        if (event.value) newSources.push(_MAPSource);
                        else newSources = newSources.filter(s => s != _MAPSource);
                        props?.onChange(newSources);
                    }}
                />
            </div>
        </GenericTooltip>
    </Element>
}