import * as React from "react";
import { Drawer, IconButton } from "@material-ui/core";
import { useState } from "react";
import { DateRangepickerCloseEvent } from "../../ConfigurableComponents/DateRangepicker";
import { getIcon } from "adwone-lib/index";
import { TooltipManager } from "../../CustomTooltip";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export type IconElement = { type: "icon", icon: string, element: JSX.Element, title: () => string, disabled?: boolean }
export type ComponentElement = { type: "component", component: JSX.Element, element?: never, icon?: never, title?: never }
export type ElementArg = (IconElement | ComponentElement) & { badge?: JSX.Element }

export type ConfigurationPanelArgs = { elements: ElementArg[] }
export function ConfigurationPanel({ elements }: ConfigurationPanelArgs) {

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(undefined);
    const visible = useSelector((root: RootState) => root.background.visible);

    const selectedElement = elements?.[selectedIndex];

    const openChanged = (element: ElementArg) => {

        if (element.type != "icon")
            return;

        if (open && selectedElement?.icon != element.icon) {
            setSelectedIndex(elements.indexOf(element));
            return;
        }

        setOpen(!open);
        if (!open)
            setSelectedIndex(elements.indexOf(element));
    }

    return <>

        {!open && <IconsPanel elements={elements} panelOpened={false} openClicked={openChanged} />}

        <Drawer
            disableEnforceFocus
            className="main-configuration-panel"
            open={open}
            anchor="right"
            onClose={() => {
                DateRangepickerCloseEvent.emit("close");
                setOpen(false);
            }} id="configCrossedTable"
            ModalProps={{
                keepMounted: true,
            }} >

            {open && <IconsPanel elements={elements} panelOpened={true} openClicked={openChanged} selectedElement={selectedElement} />}

            <div>
                <div className="drawer_title margin_bottom_dialog_export">
                    <span className="adw-title">{selectedElement?.title?.()}</span>
                </div>
            </div>
            <div className="confpanel_content">
                {selectedElement?.element}
            </div>
        </Drawer>
    </>
}

type IconsPanelArgs = { elements: ElementArg[], panelOpened?: boolean, selectedElement?: ElementArg, openClicked?: (e: ElementArg) => void }
function IconsPanel({ panelOpened, openClicked, elements, selectedElement }: IconsPanelArgs) {
    return <div className={`icons-panel ${panelOpened ? "icons-integrated" : ""}`}>
        {elements.map((e, i) => {
            if (e.type === "icon") {
                const isSelected = panelOpened && selectedElement?.title?.() === e?.title?.();
                return <IconButton
                    onMouseOver={(ev) => TooltipManager.Push({ target: ev.target, text: e.title?.() })}
                    className={"icon-panel-item navigation no-radius " + (isSelected ? "is-selected" : "")}
                    key={`element-${i}-${e.icon}`}
                    disabled={e.disabled}
                    aria-label="edit"
                    onClick={() => openClicked?.(e)} >
                    <div className="icons-panel-badge-container">
                        {getIcon(e.icon)}
                        {!isSelected && e.badge}
                    </div>
                </IconButton>
            }
            if (e.type === "component")
                return <div key={`element-${i}-static`} className="icon-panel-item">{e.component}</div>;
        })}

    </div >
}