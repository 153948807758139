import * as React from "react";

import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { SortDescriptor } from "@progress/kendo-data-query";
import { ref_ReleaseVersion } from "hub-lib/models/ref_ReleaseVersion.bin";
import { IItemRow, TooltipManager } from "adwone-lib/index";
import { ReleaseVersionDialog } from "./ReleaseVersionDialog.bin";
import Loader from "../../layout/Loader";
import { GetSort } from "format-lib/index.bin";
import { Trad } from "trad-lib";
import { duplicate, compareObjects, propertyOf } from "hub-lib/tools.bin";
import history from "../../../utils/history";
import {
    eFunctions,
    eRights,
    RightManager,
} from "hub-lib/models/types/rights.bin";
import { HistoryStateArg } from "hub-lib/types";
import { RootState } from "../../../redux/store";
import { ConfigurationPanel } from "./ConfigurationPanel";
import {
    BreadcrumbsCustomContainer,
    ConfigurationPanelContainer,
    SelectedItemsContainer,
    ToolbarAdw,
    ToolbarContainer,
} from "../Generic/ToolbarAdw";
import { useSelector } from "react-redux";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import {
    ConfirmDeleteContent,
    eDialogMode,
    GenericDialog,
} from "../../ConfigurableComponents/GenericDialog.bin";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { Client } from "hub-lib/client/client.bin";
import { CustomBadge } from "../Adwone-admin/Referential/BadgesUsage.bin";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { ref_Attachments } from "hub-lib/models/ref_Attachments.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";

type TState = {
    versionEdited?: ref_ReleaseVersion;
    dialogMode?: eDialogMode;
    grid?: VertexGrid<ref_ReleaseVersion>;
    sort?: SortDescriptor[];
    versions: ref_ReleaseVersion[];
    selectedItems: IItemRow<ref_ReleaseVersion>[];
    ridsToSelect?: ref_ReleaseVersion["@rid"][];
    initialized?: boolean;
    rmDialog?: boolean;
};

export function ReleaseVersionGrid() {
    const [refresh, setRefresh] = React.useState(false);
    const projectInitilized = useSelector(
        (root: RootState) => root.project.isInitialized
    );
    const modeleTable = duplicate(
        useSelector((root: RootState) => root.project.columnsModele, compareObjects)
    );

    const [state, _setState] = React.useState<TState>({
        versions: [],
        initialized: false,
        rmDialog: false,
        selectedItems: [],
        sort: []
    });

    const { dialogMode, grid, rmDialog, sort } = state;

    /**
     * Initialize (Component Did Mount)
     */
    React.useEffect(() => {
        if (!state.initialized)
            initializeState().then((newState) => setState(newState));
    });

    /**
     * Trigger 1ere initialization
     */
    React.useEffect(() => {
        if (projectInitilized && state.initialized) redraw();
    }, [state.initialized, projectInitilized]);

    const setState = (newState: Partial<TState>) =>
        _setState({ ...state, ...newState });

    const initializeState = async () => {
        const newState: Partial<TState> = {
            initialized: true,
        };

        return newState;
    };

    const redraw = async () => {
        setState({
            grid: new VertexGrid({
                objectPrototype: ref_ReleaseVersion,
                vertexParams: { Active: true },
                hiddenProperties: [
                    propertyOf<ref_ReleaseVersion>("Image"),
                    propertyOf<ref_ReleaseVersion>("Note"),
                    propertyOf<ref_ReleaseVersion>("Active"),
                ],

            }),
            sort: GetSort<ref_ReleaseVersion>(ref_ReleaseVersion),

        });
    };
    console.log(sort)
    const refreshContent = async (updateColumns: boolean = true) => {
        if (grid?.props?.configuration?.ViewMode === "Table") {
            if (updateColumns) grid.Initialize();
            else await grid.UpdateRows();
        } else setState({ dialogMode: undefined });
    };
    const selectionChange = (rows: IItemRow<ref_ReleaseVersion>[]) => {
        setState({
            selectedItems: rows,
        });
    };
    const removeVersion = async () => {
        try {
            let selectedVersions = state.selectedItems.map(
                (row) => row.dataItem["@rid"]
            );
            let selectedAttachements = state.selectedItems.map(
                (row) => row.dataItem.Image
            );
            let selectedPDF = state.selectedItems.map((row) => row.dataItem.Note);
            await Client.deleteVertex(ref_ReleaseVersion.name, selectedVersions);
            await Client.deleteVertex(ref_Attachments.name, selectedAttachements);
            await Client.deleteVertex(ref_Attachments.name, selectedPDF);
            state.grid.UpdateRows();
        } catch (error) {
            console.error(error);
        }
    };

    const onEdit = (row: ref_ReleaseVersion) =>
        setState({ versionEdited: row, dialogMode: eDialogMode.modify });
    const onDuplicate = (row: ref_ReleaseVersion) => console.log(row);

    if (!state.grid) return <Loader text={Trad("loading_grid")} />;

    let { ViewMode } = modeleTable;
    const confComponent = (
        <ConfigurationPanel
            elements={[
                RightManager.hasRight(
                    eFunctions.ref_ReleaseVersion,
                    eRights.create
                ) && {
                    type: "component",
                    component: (
                        <CustomIconButton
                            onMouseOver={(e) =>
                                TooltipManager.Push({
                                    target: e.target,
                                    text: Trad("create a new version"),
                                })
                            }
                            className="custom_btn_primary no-radius no-shadow"
                            onClick={() =>
                                setState({
                                    dialogMode: eDialogMode.create,
                                    versionEdited: undefined,
                                })
                            }
                        >
                            {getIcon("plus")}
                        </CustomIconButton>
                    ),
                },
            ]}
        />
    );

    return (
        <>
            <div id={"anchor-popup"}></div>
            <div style={{ width: "100%" }}>
                {dialogMode && (
                    <ReleaseVersionDialog
                        open
                        version={state.versionEdited}
                        mode={dialogMode}
                        handleClose={() => {
                            setState({ dialogMode: undefined });
                        }}
                    />
                )}

                <div>
                    <ToolbarAdw
                        includeFilters
                        hasSelectedItems={state.selectedItems?.length > 0}
                    // count={totalItems}
                    >
                        <ToolbarContainer>
                            {state.selectedItems?.length > 0 && (
                                <SelectedItemsContainer>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <GenericDialog
                                            open={rmDialog}
                                            dialogTitle={Trad("confirmation")}
                                            handleClose={() => setState({ rmDialog: false })}
                                            submitAction={() => {
                                                removeVersion();
                                                setState({ rmDialog: false, selectedItems: [] });
                                            }}
                                            cancelAction={() => setState({ rmDialog: false })}
                                            submitClass="custom_btn_danger"
                                            submitTitle={Trad("yes")}
                                            startIcon={getIcon("clear")}
                                            actions={true}
                                        >
                                            <>
                                                <p>{ConfirmDeleteContent(state.selectedItems)}</p>
                                            </>
                                        </GenericDialog>
                                        <div className="margin-right">
                                            <CustomBadge
                                                count={state.selectedItems.length}
                                                icon={"campaigns"}
                                                tradClassKey={ref_ReleaseVersion.name}
                                            />
                                        </div>
                                        {RightManager.hasRight(
                                            eFunctions.ref_ReleaseVersion,
                                            eRights.delete
                                        ) && (
                                                <CustomButton
                                                    Label={Trad("delete")}
                                                    style={{ float: "right" }}
                                                    className="custom_btn_danger"
                                                    startIcon={getIcon("delete")}
                                                    onClick={() =>
                                                        setState({
                                                            rmDialog: true,
                                                        })
                                                    }
                                                />
                                            )}
                                    </div>
                                </SelectedItemsContainer>
                            )}

                            <BreadcrumbsCustomContainer>
                                <BreadcrumbsCustom
                                    hasSelectedItems={state.selectedItems?.length > 0}
                                    elements={[
                                        { text: Trad("home"), href: "/" },
                                        { text: Trad("versions") },
                                    ]}
                                />
                            </BreadcrumbsCustomContainer>
                        </ToolbarContainer>
                        <ConfigurationPanelContainer>
                            {confComponent}
                        </ConfigurationPanelContainer>
                    </ToolbarAdw>
                    {(ViewMode === "Table" || !ViewMode) && (
                        <AdwTelerikGrid
                            footer
                            onEdit={(row) => onEdit(row.dataItem)}
                            onDuplicate={(row) => onDuplicate(row.dataItem)}
                            sort={[{ field: "Date", dir: "desc" }]}
                            grid={state.grid}
                            selectionChange={selectionChange}
                            selectable
                            uneditable={
                                !RightManager.hasRight(
                                    eFunctions.ref_ReleaseVersion,
                                    eRights.update
                                )
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
}