

import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { ModeleColumnsCreator } from "../../ModeleCreator/ModeleColumnsCreator.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { Element } from "../../Generic/Common.bin";
import { useDispatch } from "react-redux";
import { setTable } from "../../../../redux/projectSlice";
import { clone } from "hub-lib/tools.bin";
import { store } from "../../../../redux/store";
import { ModeleDashboard } from "../../ModeleCreator/ModeleDashboard.bin";

export function TableComponent({ grid }: FormConfigurationArgs) {

    const dispatch = useDispatch();
    const configuration = clone(store.getState().project.columnsModele);

    const saveTable = (_modeleTable: ref_TableConfigurations, event?: {
        type: "contentChanged" | "selectionChanged";
    }) => {
        if (event?.type == "selectionChanged") dispatch(setTable(_modeleTable));
        else dispatch(setTable(_modeleTable));
    }
    if (configuration.ViewMode === "Dashboard")
        return <ModeleDashboard
            objectType={ref_TableConfigurations}
            type={ref_Messages.name}
            onChange={saveTable}
            modele={configuration} />
    return <Element>
        <ModeleColumnsCreator
            objectType={ref_TableConfigurations}
            onChange={saveTable}
            modele={grid.props.configuration}
            type={ref_Messages.name} />
    </Element>
}

type FormConfigurationArgs = {
    onColumnsChange?: (modele: Partial<ref_TableConfigurations>) => any,
    grid: VertexGrid<ref_Messages>
}