
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { Conversations, ref_Negotiations } from 'hub-lib/models/ref_Negotiations.bin';
import { duplicate } from 'hub-lib/tools.bin';

export interface NegotiationEditorState {
    conversations: Conversations;
    advCompanyFilter: ref_AdvertisingCompanies;
    negotiation: ref_Negotiations;
}

const initialState: NegotiationEditorState = {
    conversations: undefined,
    advCompanyFilter: undefined,
    negotiation: undefined
}

export const negotiationEditorSlice = createSlice({
    name: 'negotiationEditor',
    initialState,
    reducers: {
        setNegotiation: (state: NegotiationEditorState, action: PayloadAction<ref_Negotiations>) => {
            state.negotiation = duplicate(action.payload);
        },
        setAdvCompanyFilter: (state: NegotiationEditorState, action: PayloadAction<ref_AdvertisingCompanies>) => {
            state.advCompanyFilter = action.payload;
        }, 
        setConversations: (state: NegotiationEditorState, action: PayloadAction<Conversations>) => {
            state.conversations = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setNegotiation, setAdvCompanyFilter, setConversations } = negotiationEditorSlice.actions

export const negotiationEditorReducer = negotiationEditorSlice.reducer;