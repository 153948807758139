import * as React from 'react'
import {UserExtended} from 'hub-lib/models/UserExtended.bin'
import { Client } from 'hub-lib/client/client.bin';
import { Avatar, Typography } from '@material-ui/core';
import ThreeDots from '../three-dots.svg'

export class UserInfos extends React.Component<any, any>{
    constructor(props: any){
        super(props)
        this.state= {
            loading: true,
            user: new UserExtended()
        }
    }

    async componentDidMount(){
        // let storage_user = JSON.parse(localStorage.getItem('user'))
        // let id = storage_user?.["@rid"]
        // Client.searchVertex(UserExtended.name, { "@rid": id}).then(r=>{
            this.setState({
                user: (await Client.getUser()).user,
                loading: false
            })
        // })
    }

    render(){
        if (this.state.loading)
            return <img src={ThreeDots} alt="Loading infos" style={{maxWidth: 50, marginRight:50}}/>
        let { user } = this.state
        return(
            <>
            <Avatar className="user_menu_avatar">
                {`${user?.person?.FirstName[0].toUpperCase()}${user?.person?.LastName[0].toUpperCase()}`}
            </Avatar>
            <Typography variant="body1" style={{ textAlign: 'left', lineHeight: 1.2 }}>
                <b style={{ fontSize: '0.9rem' }}>{`${user?.person?.FirstName} ${user?.person?.LastName.toUpperCase()}`}</b><br /> <span style={{ fontSize: '0.8rem' }}>{user?.mail}</span>
            </Typography>
            </>
        )
    }
}