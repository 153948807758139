import { ref_Sortings } from "./ref_Sortings.bin";
import { ColumnIndicateur, Indicateur } from "adwone-engine/index.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ADWProperty } from "../../types";
import { ref_KPIs } from "./ref_KPIs.bin";
import { ref_Currencies } from "./ref_Currencies.bin";

export const dicoViewModeIcons = {
	Table: "listView",
	CrossedTable: "tableView",
	Scheduler: "schedulerView",
	Dashboard: "dashboardView"
}

export class ref_TableConfigurations extends ref_ConfigurationsBase {
	Columns: ColumnIndicateur[];
	//HidedColumns?: Indicateur[];
	FrozenPosition: number = 0;
	ViewMode: "Table" | "CrossedTable" | "Scheduler" | "Dashboard" = "Table";
	CrossedtableConfig: {
		rowventils?: (ADWProperty | Indicateur)[]
	} = undefined;
	DashboardConfig?: {
		kpis?: ref_KPIs,
		currency?: ref_Currencies,
		filters?: {
			[filter: string]: string | string[]
		}
	} = {};
}

export function RepaireTableConfigurations(toRepaire: ref_TableConfigurations) {
	return { ...new ref_TableConfigurations(), ...toRepaire };
}