import { ref_Supports } from "../models/orientdb/ref_Supports.bin";
import { ref_Brands } from "../models/orientdb/ref_Brands.bin";
import { ref_Products } from "../models/orientdb/ref_Products.bin";
import { ref_AdvertiserGroups } from "../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "../models/orientdb/ref_Advertisers.bin";
import { ref_Property } from "../models/orientdb/ref_Property.bin";
import { ref_KPIs } from "../models/orientdb/ref_KPIs.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { ref_Model } from "../models/orientdb/ref_Model.bin";
import { ref_Sources } from "../models/orientdb/ref_Sources.bin";
import { ref_SourceTypes } from "../models/orientdb/ref_SourceTypes.bin";
import { V } from "../models/orientdb/V.bin";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_Media } from "../models/orientdb/ref_Media.bin";
import { ref_DiscountTypes } from "../models/orientdb/ref_DiscountTypes.bin";
import { SupportExtended } from "../models/SupportExtended.bin";
import { ref_Currencies } from "../models/orientdb/ref_Currencies.bin";
import { ref_PropertyType } from "../models/orientdb/ref_PropertyType.bin";
import { vw_mm_HasDiscountClass } from "../models/orientdb/vw_mm_HasDiscountClass.bin";

class clientCacheClass {

    dicoCache: { [prop: string]: any } = {}
    dicoTimeouts: { [prop: string]: number } = {}

    setTimeOut(vertexType: string, timeout: number) {
        this.dicoTimeouts[vertexType] = timeout;
    }

    getCache(vertexType: string, params: any) {

        if (!this.dicoTimeouts.hasOwnProperty(vertexType))
            return undefined;

        let key = vertexType + ":" + JSON.stringify(params);

        return this.dicoCache[key];
    }

    setCache(vertexType: string, params: any, value: any) {

        if (!this.dicoTimeouts.hasOwnProperty(vertexType))
            return undefined;

        let key = vertexType + ":" + JSON.stringify(params);
        this.dicoCache[key] = value;

        setTimeout(() => {
            delete this.dicoCache[key];
        }, this.dicoTimeouts[vertexType]);

    }

    clearCache(vertexType: string) {
        this.dicoCache = {};
        //Object.keys(this.dicoCache)
            //.filter(k => k.startsWith(`${vertexType}:`))
            //.forEach(k => delete this.dicoCache[k])
    }

}

export let clientCache = new clientCacheClass;

clientCache.setTimeOut(SupportExtended.name, 600000); // 10min
clientCache.setTimeOut(ref_Supports.name, 600000); // 10min
clientCache.setTimeOut(ref_Currencies.name, 600000); // 10min
clientCache.setTimeOut(ref_Brands.name, 600000); // 10min
clientCache.setTimeOut(ref_Products.name, 600000); // 10min
clientCache.setTimeOut(ref_AdvertiserGroups.name, 600000); // 10min
clientCache.setTimeOut(ref_Advertisers.name, 600000); // 10min

clientCache.setTimeOut(ref_Property.name, 600000); // 10min
clientCache.setTimeOut(ref_KPIs.name, 600000); // 10min
clientCache.setTimeOut(lnk_HasKPI.name, 600000); // 10min

clientCache.setTimeOut(ref_Model.name, 600000); // 10min
clientCache.setTimeOut(ref_Sources.name, 600000); // 10min
clientCache.setTimeOut(ref_SourceTypes.name, 600000); // 10min

clientCache.setTimeOut(V.name, 600000); // 10min

clientCache.setTimeOut(ref_DiscountClasses.name, 3600000); // 1h
clientCache.setTimeOut(ref_DiscountTypes.name, 3600000); // 1h
clientCache.setTimeOut(ref_Media.name, 3600000); // 1h
clientCache.setTimeOut(ref_PropertyType.name, 3600000); // 1h
clientCache.setTimeOut(vw_mm_HasDiscountClass.name, 3600000); // 1h