import { ref_BrandsId } from "./orientdb/ref_Brands.bin";
import { ref_AdvertiserGroupsId } from "./orientdb/ref_AdvertiserGroups.bin";
import { ref_CurrenciesId } from "./orientdb/ref_Currencies.bin";
import { ref_AdvertisersId } from "./orientdb/ref_Advertisers.bin";
import { ref_ProductsId } from "./orientdb/ref_Products.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { V } from "./orientdb/V.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { ImportElementStatus, ref_Imports } from "./ref_Imports.bin";
import { User } from "./orientdb/User.bin";
import { ref_Negotiations } from "./ref_Negotiations.bin";
import { ref_Groups } from "./ref_Groups.bin";
import { ref_Attachments, ref_Visuals, ref_VisualsId } from "./ref_Attachments.bin";
import { kpis } from "./ref_Messages.bin";
import { ref_Departments, ref_DepartmentsId } from "./orientdb/ref_Departments.bin";
import { ref_Media } from "./orientdb/ref_Media.bin";

export type ref_CampaignsId = rid;

export type EstimateType = {
	Source: "Sellsy" | "MM",
	ExternalID: string,
	EstimateLib?: string,
	PdfLink?: string,
	CreateParams?: { [key: string]: any }
}

export class ref_Campaigns extends V implements Referentials {
	"@rid"?: ref_CampaignsId;
	Name: string;
	Start: Date;
	End: Date;
	Brand: ref_BrandsId;
	Budget: number;
	//MessagesBudget?: number;
	KPIs?: kpis & {
		MessagesCount?: number;
		MaxStartDate?: Date;
		MinEndDate?: Date
	};
	KPIsMedia?: { [mediaRid: ref_Media['@rid']]: ref_Campaigns['KPIs'] };
	AdvertiserGroup: ref_AdvertiserGroupsId;
	Currency: ref_CurrenciesId;
	Advertiser: ref_AdvertisersId;
	Group?: ref_Groups["@rid"];
	Product: ref_ProductsId;
	Active: boolean;
	Source: "ADWONE" | "MAP";
	Import?: {
		Data: { [key: string]: any };
		Id: ref_Imports["@rid"];
		Status: ImportElementStatus;
	};
	Created_by?: User["@rid"];
	Updated_by?: User["@rid"];
	Negotiation?: ref_Negotiations["@rid"];
	Attachments?: ref_Attachments[];
	Visuals?: ref_Visuals[]; // not in DB !!!
	EstimateNumber?: string; // not in DB !!!
	Departments?: ref_DepartmentsId[]; // not in DB !!!
	Estimates?: EstimateType[];
	Performances?: {
		[ridMedia: string]: {
			wave?: string;
			target?: string;
			GRP?: number;
			contacts?: number;
			coverage?: number;
			coverage_thousands?: number;
			repetition?: number;
		}
	} = {}
}