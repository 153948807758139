import { IRid } from "./IRid.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { ref_AdvertiserGroupsId } from "./orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "./orientdb/ref_Advertisers.bin";
import { ref_CompaniesId } from "./orientdb/ref_Companies.bin";
import { ref_CustomersId } from "./orientdb/ref_Customers.bin";
import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_Sources, ref_SourcesId } from "./orientdb/ref_Sources.bin";
import { UserId } from "./orientdb/User.bin";

export enum ImportsSourceType {
    MESSAGEMAP = "MAP",
    AGREEMENTMAP = "AGREEMENTMAP",
    ADWONE = "ADWONE",
    DSP = "DSP",
    POPCORN = "POPCORN",
    CODIPRESSE = "CODIPRESSE",
    MEDIAVISION = "MEDIAVISION",
    TSM_CONTACTS = "TSM_CONTACTS",
    MEDIAAPI = "MEDIAPILOT"
}

export enum ImportsStatus {
    PREPARING = "PREPARING",
    IN_PROGRESS = "IN_PROGRESS",
    PENDING = "PENDING",
    IMPORTING = "IMPORTING",
    DONE = "DONE",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
    FAILED = "FAILED" // todo if big error
}

export enum ImportElementStatus {
    NEW = "NEW",
    CHANGED = "CHANGED",
    EXIST = "EXIST",
    UPDATED = "UPDATED",
    OBSOLETE = "OBSOLETE",
    REMOVED = "REMOVED",
    IMPORTED = "IMPORTED"
}

export class SourceTarget {
    Source: "dev" | "uat" | "prod";
}

export class FilterMessageMap {
    Start: Date;
    End: Date;
    AdvertiserGroup?: ref_AdvertiserGroupsId[];
    Advertiser?: ref_AdvertisersId[];
    Medias?: ref_MediaId[];
}

export class FilterAgreementMap {
    Start: Date;
    End?: Date;
    AdvertiserGroup?: ref_AdvertiserGroupsId[];
}

export class FiltersAdwone {
    Src: ref_Sources;
    Classes?: string[];
}

export enum eFilterRefType {
    AdvertiserGroup = "ref_AdvertiserGroups",
    Advertiser = "ref_Advertisers",
    Medias = "ref_Media"
}


export class ref_Imports implements IRid, Referentials {
    "@rid"?: rid;
    Active: boolean;
    Date: Date;
    ExpirationDate?: Date;
    Source: ref_SourcesId | string;
    SourceType: ImportsSourceType;
    Target?: ref_SourcesId;
    Customer: ref_CustomersId;
    Company: ref_CompaniesId;
    User: UserId;
    Report: { [key: string]: any } & {
        Status: ImportsStatus,
        Current: number,
        Total: number
    };
    Params?: any;
    Errors: { [key: string]: any };
    Filters?: FilterMessageMap | FilterAgreementMap | FiltersAdwone;
    FiltersName?: string;
    refresh?: boolean;
}

export class ref_ImportsExtended<T> extends ref_Imports {
    Params?: T;
}
