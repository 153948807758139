import * as React from "react";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initCurrencyOptions, setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { WarningMM } from "./WarningMM";
import { vw_mm_HasCurrency } from "hub-lib/models/orientdb/vw_mm_HasCurrency.bin";

export function CurrencyEditor() {

    const dispatch = useDispatch();
    const Currency = useSelector((root: RootState) => root.messageEditor.getMessage().Currency);
    const currencyOptions = useSelector((root: RootState) => root.messageEditor.currencyOptions);
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);

    React.useEffect(() => {
        if (currencyOptions === undefined)
            dispatch(initCurrencyOptions(undefined));
    }, [currencyOptions])

    const getElement = options => options?.find(o => o["@rid"] == Currency);
    const warning = currencyOptions && Currency && (getElement(currencyOptions) as any)?.warning;

    return <WarningMM ids={[{ id: Currency, linkClass: vw_mm_HasCurrency.name }]}>
        <FavoriteVertexAutoComplete
            key={`CurrencyEditor-${GetHashCode({ currencyOptions, Currency })}`}
            disableClearable
            disabled={lockNext}
            loading={lockNext}
            warning={warning}
            // enabledOnlyLoading={isLoading}
            label={TradProp("Currency", ref_Messages) + " *"}
            options={currencyOptions ?? []}
            defaultValue={getElement}
            onChange={async value => {
                if (Currency != value["@rid"]) {
                    const message = store.getState().messageEditor.getMessage();
                    message.Currency = value["@rid"];
                    dispatch(setMessage(message));
                }
                return value;
            }} />
    </WarningMM>
}