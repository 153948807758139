import { ADWColumn, AdwRow } from "adwone-lib";
import { Log } from "hub-lib/models/Log.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { propertyOf } from "hub-lib/tools.bin";
import * as React from "react";
import ReactJson from "react-json-view";
import { GenericPopover } from "../../ConfigurableComponents/GenericPopover.bin";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";

let anchorId = 0;
export function TSMErrorsGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {

            const columns: ADWColumn<Log>[] = [];

            /** Open */
            const openLogPath: any = "openLog";
            const openLog = new ADWColumn<Log>(" ", openLogPath, ePropType.String, false);
            openLog.width = 100;
            openLog.cellValue = (cellValue: any, dataItem?: AdwRow<Log>) => {
                const anchorKey = `anchor-${anchorId++}`;
                return <div id={anchorKey}>
                    <GenericPopover anchorParent={anchorKey} icon="zoom">
                        <ReactJson src={dataItem.dataItem} displayDataTypes={false} sortKeys displayObjectSize={false} />
                    </GenericPopover></div>
            };
            columns.push(openLog);

            const hiddenProperties: string[] = [];
            hiddenProperties.push(propertyOf<Log>("Report"))
            hiddenProperties.push(propertyOf<Log>("CallID"))

            //const properties: string[] = [];
            const grid = new VertexGrid<Log>({
                disableStore: true,
                objectPrototype: Log,
                devMode: false,
                columns,
                vertexParams: {
                    Layer: "TSMError",
                    "Report.Action": "missing_links"
                },
                order: ["@rid", "Date", "Layer", "User", , "CallID"],
                width: {

                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={Log}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const TSMErrorsGrid = Template(TSMErrorsGridComponent);