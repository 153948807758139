import * as React from "react";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { ADWColumn } from "adwone-lib";
import { TradProp } from "trad-lib";
import { ref_Spots } from "hub-lib/models/ref_Couplages.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import DefaultGrid from "../DefaultGrid.bin";
import { RootState } from "../../../redux/store";
import { compareObjects, duplicate } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";

export function SpotsGrid() {
    const [grid, setGrid] = React.useState(null);
    const filters = duplicate(useSelector((root: RootState) => root.supplier.filters, compareObjects));

    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_Spots>[] = [
                new ADWColumn(TradProp("Name"), "Name", ePropType.String),
                new ADWColumn(TradProp("Campaign"), "Campaign", ePropType.Integer),
                new ADWColumn(TradProp("Support"), "SupportName", ePropType.String),
                new ADWColumn(TradProp("Status"), "Type", ePropType.String),
                new ADWColumn("Date Prévue", "DatePrev", ePropType.Date),
                new ADWColumn("Date Diffusée", "DateDiff", ePropType.Date),
                new ADWColumn(TradProp("Format"), "Format", ePropType.Integer),
                new ADWColumn(TradProp("Placement"), "Placement", ePropType.String),
                new ADWColumn("EP", "PosDiff", ePropType.Integer),
                new ADWColumn("Gross", "Gross", ePropType.Decimal),
                new ADWColumn("Net", "Net", ePropType.Decimal)
            ];

            const grid = new VertexGrid<ref_Spots>({
                disableStore: true,
                objectPrototype: ref_Spots,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"],
                    ...filters
                }
            });
            setGrid(grid);
        }
    });

    return <DefaultGrid
        objectPrototype={ref_Spots}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}