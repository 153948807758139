import * as React from 'react'
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { setMessageSync } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { TextField } from '@material-ui/core';
import { TradProp } from 'trad-lib';

type PropertyEditorArgs = { property: keyof ref_Messages & string, label: string }
export function PropertyEditor({ property, label }: PropertyEditorArgs) {

    const propertyValue = useSelector((root: RootState) => root.messageEditor.getMessage()[property], JSONEqualityComparer);

    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={label ?? TradProp(property, ref_Messages)}
        value={propertyValue}
        variant="outlined"
        onChange={(e) => {
            const message = store.getState().messageEditor.getMessage();
            (message as any)[property] = e.target.value;
            store.dispatch(setMessageSync(message));
        }} />
}