
import { TextField } from '@material-ui/core';
import { lnk_HasPropertyTypeExtended, MessageModelManager } from 'hub-lib/models/KPIsManager.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { GetHashCode, propertyOf } from 'hub-lib/tools.bin';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad, TradProp, TradValue } from 'trad-lib';
import { setMessageSync } from '../../../../redux/messageEditorSlice';
import { RootState, store } from '../../../../redux/store';
import { AdwAutocomplete } from "adwone-lib/index";
import { changeEndValue } from './DiffusionEditor';

type ModelPropertiesEditorArgs = { property: string, label?: string }
export function ModelPropertiesChoicesEditor({ property, label }: ModelPropertiesEditorArgs) {

    const propertyValue = useSelector((root: RootState) => root.messageEditor.message.ModelProperties?.[property]);
    const [valueChoices, setValueChoices] = React.useState<lnk_HasPropertyTypeExtended["ValueChoices"]>();

    React.useEffect(() => {
        if (!valueChoices) {
            Promise.resolve().then(async () => {
                const message = store.getState().messageEditor.getMessage();
                const MessageModelMgr = new MessageModelManager(message.Media, "Message");
                const properties: lnk_HasPropertyTypeExtended[] = await MessageModelMgr.GetProperties();
                setValueChoices(properties?.find(p => p.Id == property).ValueChoices);
            })
        }
    })

    const value = valueChoices?.find(v => v.rid == propertyValue);
    return <AdwAutocomplete
        key={`${value}-${GetHashCode(valueChoices)}`}
        loading={!valueChoices}
        disabled={!valueChoices}
        options={valueChoices}
        getOptionLabel={(v => v["@class"] ? TradValue(v["@class"], "Name", v.value) : v.value)}
        value={value}
        onChange={async (event: any, value: any) => {
            const message = store.getState().messageEditor.getMessage();
            if (!message.ModelProperties)
                message.ModelProperties = {};
            message.ModelProperties[property] = value?.rid;
            if (property == "Periodicity")
                await changeEndValue(message);
            store.dispatch(setMessageSync(message));
        }}
        renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${property}` as any, ref_Messages)} />}
    />
}