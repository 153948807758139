import { Chip, Grid, GridSize } from "@material-ui/core";
import { DividerWithText, getIcon } from "adwone-lib";
import { Client } from "hub-lib/client/client.bin";
import { ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { firstOrDefault } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { ChipBox } from "./ChipBox.bin";
import { DateChip } from "./DateChip.bin";

class TPropsExportConfiguration {
    id: string;
    gridSize: GridSize;
}

export function ExportChip({ id, gridSize }: TPropsExportConfiguration) {
    const [config, setConfig] = React.useState<ref_ExportConfigurations>();
    const [open, setOpen] = React.useState<boolean>(false);
    const fetchData = async (): Promise<void> => {
        const defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
        const data = await Client.searchVertexTyped(
            ref_ExportConfigurations,
            id
                ? {
                    "@rid": id,
                    Active: true,
                }
                : defaultParams
        );
        setConfig(firstOrDefault(data));
    };
    React.useEffect(() => {
        if (!config) fetchData();
    });
    return (
        <>
            <DividerWithText
                startIcon={getIcon("download")}
                endIcon={open ? getIcon("less") : getIcon("more")}
                onClick={() => setOpen(!open)}
                hideLines
            >{`${config?.Name}`}</DividerWithText>
            <Grid container item xs={12} className={`widget-expendable ${open ? "open" : ""}`}>
                {config && (
                    <>
                        <Grid item xs={gridSize}>
                            <ChipBox title={Trad("period")}>
                                <DateChip Start={config.ExportArg.Start} End={config.ExportArg.End} />
                            </ChipBox>
                        </Grid>
                        <Grid item xs={gridSize}>
                            <ChipBox title={Trad("export_types")}>
                                <Chip
                                    variant="outlined"
                                    label={Trad(config.ExportArg.ViewModeExport + "_exporttype")}
                                    className="chip-element"
                                />
                            </ChipBox>
                        </Grid>
                        {config.ExportArg.templateName && (
                            <Grid item xs={gridSize}>
                                <ChipBox title={Trad("modele_template")}>
                                    <Chip
                                        variant="outlined"
                                        label={config.ExportArg.templateName}
                                        className="chip-element"
                                    />
                                </ChipBox>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}
