
import * as React from "react";
import { Trad } from "trad-lib";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { getIcon, TooltipManager, VertexAutocomplete } from "adwone-lib/index";
import { InfoAdornment } from "../InfoAdornment";
import { ref_GlobalAgreements } from "hub-lib/models/ref_GlobalAgreements.bin";
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { AgreementTooltip } from "./AgreementTooltip";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";

export function GlobalAgreementComponent() {

    const globalAgreement = useSelector((root: RootState) => root.messageEditor.globalAgreement);
    const dispatch = useDispatch();

    return <div>
        <div style={{
            width: "calc(100% - 40px)",
            float: "left"
        }}>
            <VertexAutocomplete
                key={`GlobalAgreementComponent-${globalAgreement?.["@rid"]}`}
                {...((globalAgreement?.["@rid"] && {
                    startAdornment:
                        <InfoAdornment element={() => <AgreementTooltip agreement={globalAgreement?.["@rid"]} documentType={ref_GlobalAgreements.name} />} />
                }) ?? {})}
                disabled
                options={[globalAgreement]}
                getOptionLabel={(a: ref_GlobalAgreements) => a.Name ?? `Accord_${a["@rid"]}`}
                label={Trad("global_agreement")}
                defaultValue={() => globalAgreement} />
        </div>

        <CustomIconButton
            onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("refresh_global_agreement") })}
            style={{ float: "right" }}
            className="custom_btn_secondary popover_button"
            onClick={async () => {
                const message = store.getState().messageEditor.getMessage();
                await DiscountManager.initGlobalAgreement(message);
                dispatch(setMessage(message));
            }}>
            {getIcon("cached")}
        </CustomIconButton>
    </div>
}