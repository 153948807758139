import { DateInputProps } from "@progress/kendo-react-dateinputs";
import { MaskedTextBox, MaskedTextBoxEvent } from "@progress/kendo-react-inputs";
import moment from "moment";
import * as React from "react";
import { Trad } from "trad-lib";

class TState {
    date: string;
    valid: boolean;
}

type CustomDateInputArgs = { label?: string, prefix?: () => string }
export function CustomDateInput(p?: CustomDateInputArgs) {
    const { label, prefix } = (p ?? {});
    return class CustomDateInputComponent extends React.Component<DateInputProps, TState> {
        timeout: NodeJS.Timeout;
        constructor(props: DateInputProps) {
            super(props);
            let newState = new TState();
            newState.date = props.value ? this.getFormatedDate(props.value) : "";
            newState.valid = true;
            this.state = newState;
        }

        componentWillReceiveProps(nextProps: DateInputProps) {
            const { value } = this.props;
            if ((!value && nextProps.value) || nextProps.value && value && nextProps.value.toISOString() !== value.toISOString()) {
                this.setDate(this.getFormatedDate(nextProps.value));
            }
        }

        setDate = (date: string) => {
            this.setState({
                date,
                valid: true,
            });
        };

        setValid = (valid: boolean) => {
            this.setState({
                valid,
            });
        };

        getMomentFromString = (date: string) => {
            return moment(
                date,
                moment().localeData().longDateFormat("L"),
                true
            )
        }

        getFormatedDate = (date: Date) => {
            return moment(date).format("L");
        }

        handleChange = (syntheticEvent: any) => {
            clearTimeout(this.timeout);
            if (syntheticEvent.target.value === "__/__/____") {
                this.setDate("");
                this.props.onChange({
                    value: null,
                    syntheticEvent,
                    target: this as any,
                });
                return;
            } else {
                this.setDate(syntheticEvent.target.value);
            }
            this.timeout = setTimeout(() => {
                const momentDate = this.getMomentFromString(syntheticEvent.target.value);
                if (momentDate.isValid()) {
                    this.props.onChange({
                        value: momentDate.toDate(),
                        syntheticEvent,
                        target: this as any,
                    });
                    this.setValid(true);
                } else {
                    this.setValid(false);
                }
            }, 500);
        };

        onBlur = (syntheticEvent: any) => {
            const momentDate = this.getMomentFromString(syntheticEvent.target.value);
            if (!momentDate.isValid()) {
                this.setDate(this.props.value ? this.getFormatedDate(this.props.value) : "");
            }
        };

        render() {
            const { date, valid } = this.state;
            return (
                <span className="k-textbox-container">
                    <span className="k-widget k-dateinput">
                        {prefix && <span className="prefix-text">{prefix()}</span>}

                        <MaskedTextBoxRef
                            className="k-dateinput-wrap custom-date-input"
                            valid={valid}
                            mask="00/00/0000"
                            placeholder={Trad("placeholder-date-input")}
                            value={date}
                            onChange={this.handleChange}
                            onBlur={this.onBlur} />

                    </span>

                    {label && <label className="k-label">{label}</label>}
                </span>
            );
        }
    }
}


function MaskedTextBoxRef(p: any) {

    const fooBarRef = React.createRef();

    React.useEffect(() => {
        const input = (fooBarRef as any)?.current?._input;
        if (input)
            input.classList = "k-input";
    })

    return <MaskedTextBox {...p} ref={fooBarRef} />
}
