
import { ADWGrid, ADWColumn, AdwRow, eRowEvent } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { Trad } from "trad-lib";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { PropertyName, eColumnType } from "hub-lib/models/types.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { ToEPropType } from "hub-lib/models/KPIsManager.bin";
import { TableExport } from "hub-lib/models/external.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { IndicateurToString, propertyOption } from "adwone-engine/index.bin";
import { ExportArg } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { store } from "../../../../redux/store";
import { setTable } from "../../../../redux/projectSlice";
import { AddDevModeColumns } from "../AddDevModeColumns";
import { GetHashCode } from "hub-lib/tools.bin";
import { ConsoleDebug } from "../../../../utils/localstorage.bin";

export interface IPropertiesProvider {
    Provide(type: string): Promise<Property[]>;
}

export type props<TVertex> = {
    objectPrototype: new () => TVertex,
    initializePrototype?: (prototype: TVertex) => void;
    validator?: (vertex: TVertex, errors: (keyof TVertex)[], row: AdwRow<TVertex>) => Promise<void>;
    forcedColumns?: ADWColumn<TVertex>[],
    columns?: ADWColumn<TVertex>[],
    hiddenProperties?: string[],
    readOnlyProperties?: string[],
    rows?: any[],
    // notVertex?:boolean,
    vertexParams?: any;
    titles?: { [prop: string]: string },
    order?: ((keyof TVertex) | string)[],
    customCellValue?: { [property in keyof TVertex]?: (value: any, dataItem?: any) => Promise<any>; }
    columnParams?: { [property in keyof TVertex]?: { [p in keyof ADWColumn<TVertex>]?: any } };
    filterRows?: (row: TVertex) => any;
    width?: { [prop in keyof TVertex]?: number | string },
    frozenLeft?: (keyof TVertex)[],
    configuration?: ref_TableConfigurations;
    adapt?: (rows: TVertex[], columns: ADWColumn<TVertex>[]) => any;
    columnDecorator?: { [key in eColumnType]?: (col: ADWColumn<TVertex>) => ADWColumn<TVertex> | Promise<ADWColumn<TVertex>> };
    rowToVertex?: (row: AdwRow<TVertex>, object: any) => any;
    afterSearch?: (verteces: TVertex[]) => TVertex[] | Promise<TVertex[]>;
    onInlineEdited?: (vertex: TVertex, row: AdwRow<TVertex>) => void;
    onInlineDeleted?: (verteces: any[], rows: AdwRow<TVertex>[]) => void;
    onInlineNew?: (vertex: any, row: AdwRow<TVertex>) => void;
    onUpdateStarting?: (vertex: TVertex, row: AdwRow<TVertex>) => void;
    emptyGrid?: boolean;
    computeCellValues?: boolean;
};

export class GridBase<TVertex> extends ADWGrid<TVertex> {

    hiddenProperties: string[] = [
        "Active"
    ];

    objectPrototype: new () => TVertex;
    props: props<TVertex>;

    constructor(props: props<TVertex>) {
        super();
        this.objectPrototype = props.objectPrototype;
        this.props = props;

        if (props.hiddenProperties)
            this.hiddenProperties = this.hiddenProperties.concat(props.hiddenProperties);

        this.Initialize();
    }

    async create(row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
        return true;
    }

    async update(row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
        return true;
    }

    async delete(rows: AdwRow<TVertex>[]) {
        // nothing here, can be overriden if needed
        return true;
    }

    async validator(vertex: TVertex, errors: (keyof TVertex)[], row: AdwRow<TVertex>): Promise<void> {
        if (this.props.validator) {
            await this.props.validator(vertex, errors, row);
        }
    }

    rowToObjectAfter(object: any, row: AdwRow<TVertex>) {
        // nothing here, can be overriden if needed
    }

    async Initialize() {
        await this.InitializeColumns()
        await this.UpdateRows();
    }

    private ApplyWidth(column: ADWColumn<TVertex>) {
        const overrideWidth = (this.props.width as any)?.[column.baseColumn?.field ?? column.bindingPath];
        if (overrideWidth != undefined)
            column.width = overrideWidth;
    }

    InitializeColumns = () => {

        const columns: ADWColumn<TVertex>[] = [];

        if (this.props?.configuration?.Columns?.length > 0) {
            const indicateursConf = this.props?.configuration?.Columns;

            for (const indiConf of indicateursConf) {

                const overridenCol = this.props.columns?.find(c => c.title == indiConf.name);
                if (overridenCol) {
                    columns.push(overridenCol);
                    continue;
                }

                const colKPI = new ADWColumn<TVertex>(
                    indiConf.name,
                    IndicateurToString(indiConf),
                    ToEPropType(indiConf.valueType));

                colKPI.baseColumn = indiConf;
                colKPI.isKPI = true;
                columns.push(colKPI);

                this.ApplyWidth(colKPI);
            }
        }

        AddDevModeColumns(columns);

        // if confuguration is defined, we use it to add frozen columns
        const { configuration } = this.props;
        if (configuration) {
            columns.forEach(c => c.frozen = undefined);
            columns.slice(0, configuration.FrozenPosition ?? 0).forEach(c => c.frozen = "left");
        }

        columns.forEach(c => {
            const id = c.baseColumn ? GetHashCode(IndicateurToString(c.baseColumn))?.toString() : undefined;
            const field = `_computed.${id}`;
            c.field = field;
            c.indicateurHash = id;
        });

        this.Columns = columns;
    }

    UpdateRowsFromServer = async () => {
        const time1379 = new Date().getTime();
        const res = await Client.searchVertex(this.objectPrototype.name, this.props?.vertexParams);
        const rows = res?.data?.results;
        const _time1379 = new Date().getTime();
        console.log(`[PERF] searchVertex Elapsed ${_time1379 - time1379}ms`);
        return rows;
    }

    lastRequestId: number;
    UpdateRows = async () => {

        this.onRowsChanged.emit(eRowEvent.loading);
        if (this.props.rows) {
            this.Rows = this.props.rows
            return
        }
        let requestId = Date.now();
        this.lastRequestId = requestId;
        let rows = [];

        if (!this.props.rows) {
            try {
                rows = await this.UpdateRowsFromServer();
                const time9883 = new Date().getTime();
                if (this.props.afterSearch)
                    rows = await this.props.afterSearch(rows);
                const _time9883 = new Date().getTime();
                ConsoleDebug(`[PERF] afterSearch Elapsed ${_time9883 - time9883}ms`);
            } catch (error) {
                Notify(Trad(`cannot_get_elements`), "error");
                console.error(error);
            }
        }

        if (this.lastRequestId != requestId)
            return;

        const time2512 = new Date().getTime();
        if (this.props.filterRows)
            rows = rows.filter(this.props.filterRows);
        const _time2512 = new Date().getTime();
        console.log(`[PERF] filterRows Elapsed ${_time2512 - time2512}ms`);

        const time9789 = new Date().getTime();
        await Promise.resolve(this.props.adapt?.(rows, this.Columns));
        const _time9789 = new Date().getTime();
        console.log(`[PERF] adapt Elapsed ${_time9789 - time9789}ms`);

        if (!this.props.emptyGrid)
            this.Rows = rows;
        else
            this.Rows = [];
    }

    createData(): TVertex {
        const object = new this.objectPrototype;
        if (this.props.initializePrototype) {
            this.props.initializePrototype(object);
        }
        return object;
    }

    exportExcel = (exportType: "csv" | "formated", args?: ExportArg) => {
        const exportColumns = this.Columns.map(c => c.baseColumn).filter(c => c);
        const arg: TableExport = {
            ...args,
            type: "table",
            document: this.objectPrototype.name,
            filter: {
                ...this.props.vertexParams, ...(args && { Start: args.Start, End: args.End })
            },
            columnsGeneration: exportColumns?.length > 0 ? "fromData" : "fromMetadata",
            columns: exportColumns
        }
        return Client.downloadExport(exportType, arg, Trad(this.objectPrototype.name));
    }

    protected rowToObject(row: AdwRow<TVertex>): TVertex {
        let newObj: any = {};
        this.rowToObjectAfter(newObj, row);
        this.props?.rowToVertex?.(row, newObj);
        return newObj;
    }

    /**
     * Reorder columns depending on UI changes
     * @param columnIds
     */
    async onReorder(columnIds: string[]) {

        const conf = this.props?.configuration;
        if (conf && !conf.Default) {
            conf.Columns = [...conf.Columns].sort((a, b) => {
                let i1 = columnIds.indexOf(IndicateurToString(a));
                let i2 = columnIds.indexOf(IndicateurToString(b));

                if (i1 > -1 && i2 > -1)
                    return i1 - i2;
                return 0;
            })

            store.dispatch(setTable(conf));
        }

        //await this.InitializeColumns();
        //super.onReorder(columnIds);
    }
}

export type Property = {
    name: PropertyName;
    type: ePropType;
    linkedClass?: string;
    options?: propertyOption;
};