import {
    extendDataItem,
    mapTree,
    modifySubItems,
    removeItems,
    TreeListItemChangeEvent,
} from "@progress/kendo-react-treelist";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { clone, propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { RootState } from "./store";

export const subItemsField: string = "Children";
export const editField: string = "inEdit";
export const expendedField: string = "Expanded";

interface TreeListRefNode {
    id: string;
    parent: string;
    Children: DataTree[];
    Expanded: boolean;
    Selected: boolean;
    inEdit?: boolean;
    isNew?: boolean;
}

export type DataTree = IRid & { Name?: string } &TreeListRefNode;

export interface Tree {
    fetchedDataCategory: string;
    fetchedDataType: string;
    fetchedData: IRid[];
    fetchedDataAdvertiserGroups: ref_AdvertiserGroups[],
    fetchedDataAdvertisers: ref_Advertisers[],
    data: DataTree[];
    loaded: boolean;
    inEdit: DataTree[];
}

const initialState: Tree = {
    fetchedDataCategory: undefined,
    fetchedDataType: undefined,
    fetchedData: [],
    fetchedDataAdvertiserGroups: [],
    fetchedDataAdvertisers: [],
    data: [],
    loaded: false,
    inEdit: [],
};

export const createNode = ({
    el,
    parent,
    data,
    childrenKey,
}: {
    el?: IRid;
    parent?: IRid["@rid"];
    data?: IRid[];
    childrenKey?: string;
}): TreeListRefNode => {
    let id = new Date().getTime().toString();
    if (el?.["@rid"]) {
        id = `${el?.["@rid"]}${parent ? "-" + parent : ""}`;
    }
    return {
        ...el,
        Children: el?.[childrenKey]?.length
            ? el[childrenKey].map((e: IRid) =>
                createNode({
                    el: data.find((f) => e === f["@rid"]),
                    parent: id,
                    data,
                    childrenKey: childrenKey
                })
            )
            : [],
        Selected: false,
        Expanded: false,
        id,
        isNew: el === undefined ? true : el["isNew"] ?? false,
        inEdit: false,
        parent: parent,
    };
};

function removeItem(state: Tree, el: DataTree, idsField?: string) {
    state.data = removeItems(idsField
        ? mapTree(state.data, subItemsField, (item) =>
            item.id === el.parent ? { ...item, [idsField]: item[idsField].filter((e: string) => e !== el["@rid"]) } : item
        )
        : state.data, subItemsField, (i) => i.id === el.id);
    state.inEdit = state.inEdit.filter((i) => i.id !== el.id);
}

export const save = createAsyncThunk(
    'treeSlice/save',
    async (action: { item: DataTree, data: DataTree[], inEdit: DataTree[] }, thunkAPI) => {
        let { isNew, inEdit, ...itemToSave } = action.item;
        if (itemToSave[propertyOf<ref_Groups>("Area")]) {
            const area = clone(itemToSave[propertyOf<ref_Groups>("Area")]) as ref_Groups["Area"];
            const advertiser = area.find(e => e.Class === ref_Advertisers.name);
            if (advertiser) {
                const state = thunkAPI.getState() as RootState;
                if (advertiser.IDs !== null && (!advertiser.IDs.length || advertiser.IDs.length === state.tree.fetchedDataAdvertisers.length)) {
                    advertiser.IDs = null;
                    itemToSave = { ...itemToSave, [propertyOf<ref_Groups>("Area")]: area };
                }
            }
        }
        if (isNew) {
            if (itemToSave.parent === undefined) {
                const created = await Client.createVertex(ref_Groups.name, itemToSave);
                itemToSave = { ...itemToSave, ...created?.data?.results };
            } else {
                const el = action.data.find(e => e.id === itemToSave.parent);
                await Client.updateVertex(ref_Groups.name, el);
            }
        } else {
            if (itemToSave.parent === undefined) {
                await Client.updateVertex(ref_Groups.name, itemToSave);
            } else {
                const el = action.data.find(e => e.id === itemToSave.parent);
                await Client.updateVertex(ref_Groups.name, el);
            }
        }
        return {
            data: mapTree(action.data, subItemsField, (item) =>
                item.id === itemToSave.id ? itemToSave : item
            ),
            inEdit: action.inEdit.filter((i) => i.id !== itemToSave.id)
        };
    }
)

export const treeSlice = createSlice({
    name: "tree",
    initialState,
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(save.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.inEdit = action.payload.inEdit;
        }),
        builder.addCase(save.rejected, (state, err) => {
            console.error(err);
        })
    },
    reducers: {
        setState: (state, action: PayloadAction<Partial<Tree>>) => {
            if (state.fetchedDataCategory === action.payload.fetchedDataCategory)
                Object.assign(state, action.payload);
        },
        enterEdit: (state, action: PayloadAction<DataTree>) => {
            state.inEdit = [...state.inEdit, extendDataItem(action.payload, subItemsField)];
        },
        addRecord: (state, action: PayloadAction<IRid>) => {
            const node = createNode({ el: action.payload, data: state.fetchedData });
            state.data = [...state.data, node];
            state.inEdit = [...state.inEdit, { ...node }];
        },
        addDuplicate: (state, action: PayloadAction<DataTree>) => {
            const duplicate = clone(action.payload);
            delete duplicate["@rid"];
            duplicate.isNew = true;
            duplicate.Name = `${duplicate.Name} (${Trad("copy")})`;
            const node = createNode({ el: duplicate, data: state.fetchedData });
            node.Children = duplicate.Children;
            state.data = [...state.data, node];
            state.inEdit = [...state.inEdit, { ...node }];
        },
        addChild: (state, action: PayloadAction<DataTree>) => {
            const node = createNode({ parent: action.payload.id, data: state.fetchedData });
            state.inEdit = [...state.inEdit, node];
            state.data = modifySubItems(
                mapTree(state.data, subItemsField, (item) => item.id === node.parent ? { ...item, [expendedField]: true } : item),
                subItemsField,
                (item: DataTree) => item.id === node.parent,
                (subItems: DataTree[]) => [node, ...subItems]
            );
        },
        cancel: (state, action: PayloadAction<DataTree>) => {
            const { inEdit, data } = state;
            if (action.payload.isNew) {
                removeItem(state, action.payload);
            } else {
                state.data = mapTree(data, subItemsField, (item) =>
                    item.id === action.payload.id ? inEdit.find((i) => i.id === item.id) : item
                );
                state.inEdit = inEdit.filter((i) => i.id !== action.payload.id);
            }
        },
        remove: (state, action: PayloadAction<{ selected: DataTree[], idsField?: string }>) => {
            const toDelete = new Set<string>([]);
            const toUpdate = new Set<string>([]);
            const { selected, idsField } = action.payload;
            for (const el of selected) {
                if (el.parent && !toUpdate.has(el["@rid"])) {
                    toUpdate.add(el.parent);
                } else {
                    toDelete.add(el["@rid"]);
                }
                removeItem(state, el, idsField);
            }
            if (toDelete.size) {
                toDelete.forEach((e) => {
                    toUpdate.delete(e);
                })
                Client.deleteVertex(ref_Groups.name, Array.from(toDelete));
            }
            if (toUpdate.size) {
                Client.updateVertex(ref_Groups.name, state.data.filter(e => toUpdate.has(e.id)));
            }
        },
        onItemChange: (state, action: PayloadAction<{field: string, value: string, id: string}>) => {
            const field = action.payload.field;
            state.data = mapTree(state.data, subItemsField, (item) =>
                item.id === action.payload.id
                    ? extendDataItem(item, subItemsField, { [field]: action.payload.value })
                    : item
            );
        },
        onExpandedChange: (state, action: PayloadAction<string[]>) => {
            state.data = mapTree(state.data, subItemsField, (item) =>
                action.payload.includes(item.id)
                    ? { ...item, [expendedField]: true }
                    : { ...item, [expendedField]: false }
            );
        },
        clear: (state, action: PayloadAction<string>) => {
            Object.assign(state, {
                ...initialState,
                fetchedDataCategory: action.payload,
            });
        },
        updateIDs: (state, action: PayloadAction<{ els: DataTree[], id: string, area?: string }>) => {
            const newItem = {};
            if (action.payload.area) {
                newItem[propertyOf<ref_Groups>("Area")] = [{ Class: action.payload.area, IDs: action.payload.els ? action.payload.els.map(e => e["@rid"]) : action.payload.els }];
            } else {
                newItem[propertyOf<ref_Groups>("IDs")] = action.payload.els.map(e => e["@rid"]);
                newItem[subItemsField] = action.payload.els.map(el => createNode({el, data: state.fetchedData, parent: action.payload.id }));
            }
            state.data = mapTree(state.data, subItemsField, (item) =>
                item.id === action.payload.id
                    ? extendDataItem(item, subItemsField, newItem)
                    : item
            );
        }
    },
});

// Action creators are generated for each case reducer function
export const { setState, enterEdit, cancel, addChild, remove, onItemChange, onExpandedChange, addRecord, addDuplicate, clear, updateIDs } =
    treeSlice.actions;

export const treeReducer = treeSlice.reducer;
