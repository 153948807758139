import { User } from "./orientdb/User.bin";
import { ref_Persons } from "./orientdb/ref_Persons.bin";
import { ref_Companies } from "./orientdb/ref_Companies.bin";
import { ref_Sources } from "./orientdb/ref_Sources.bin";
import { UserRightsId } from "./orientdb/UserRights.bin";
import { ref_ModulesId } from "./orientdb/ref_Modules.bin";
import { ref_CustomersExtended } from "./custom/ref_CustomersExtended.bin";
import { UserPermissions } from "./types/rights.bin";

export class UserExtended extends User {
    company: ref_Companies;
    person:ref_Persons;
    lastConnexion: Date;
    lastAccess: Date;
    sessionsTime: number; // ms
    isOnline: boolean;
    mail: string;
    job: string;
    profileName: string;
    sources: ref_Sources[];
    phone: string;
    Name?: string;
    customerRights?: UserRightsId;
    maskRights: number;
    authentication: "SSO" | "AdwOne";
    userPermissions: UserPermissions;
    customer?: ref_CustomersExtended;
    modules?: ref_ModulesId[];
}