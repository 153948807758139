
import * as React from "react";
import { Grid } from "@material-ui/core";
import { JSONEqualityComparer, extractSub } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { CPMFeesGrid } from "./CPMFeesGrid";
import { performanceKPIs, setMessage, setMessageSync } from "../../../../redux/messageEditorSlice";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";

export function CPMFeesEditor() {
    const discounts = useSelector((root: RootState) => root.messageEditor.getMessage().Discounts, JSONEqualityComparer);
    const [feesType, setFeesType] = React.useState<ref_DiscountTypes>();
    const [feesClasses, setFeesClasses] = React.useState<ref_DiscountClasses[]>();
    const kpis = useSelector((root: RootState) => root.messageEditor.kpis);
    const kpiChoicesCPM = kpis?.filter(kpi => performanceKPIs.includes(kpi.Name));

    React.useEffect(() => {
        if (!feesType)
            IndicateursProvider.GetInstance().getDiscountTypes()
                .then(types => setFeesType(types.find(t => t.Name === "Frais")));
        if (!feesClasses && feesType)
            IndicateursProvider.GetInstance().getDiscountClasses(c => c.DiscountType == feesType["@rid"])
                .then(classes => setFeesClasses(classes))
    })

    if (!feesType || !feesClasses || !kpiChoicesCPM)
        return <></>

    return <Grid container className='block-container'>
        <CPMFeesGrid
            discounts={discounts}
            classes={feesClasses}
            choices={kpiChoicesCPM}
            discountType={feesType}
            onChange={(discounts) => {
                const newMessage = store.getState().messageEditor.getMessage();
                newMessage.Discounts = discounts;
                store.dispatch(setMessage(newMessage))
            }} />
    </Grid>
}