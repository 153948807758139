import { ref_DiscountTypesId } from "../orientdb/ref_DiscountTypes.bin";
import { ref_DiscountClassesId } from "../orientdb/ref_DiscountClasses.bin";
import { rid } from "../orientdb/CommonTypes.bin";
import { ref_Agreements } from "../ref_Agreements.bin";
import { ref_GlobalAgreements } from "../ref_GlobalAgreements.bin";
import { ref_Attachments } from "../ref_Attachments.bin";
import { ref_KPIsId } from "../orientdb/ref_KPIs.bin";

export type vertexName = string;
export type property = { [property: string]: string };
export type vertex = { name: vertexName, params?: property, link?: { edgeType: string, params?: any, to: string } };

export type UpdateSet = {
    Properties?: property,
    FeeRates?: FeeRates
};
export type FeeRates = { [classId: string]: { update: boolean, value: number, label: string, typeId: string } };
/**
 * Mongo
 */

export class ref_DiscountBase {
    "@rid": string;
    IsRate: boolean;
    Rate?: number;
    Value?: number;
    CO?: { Rate: number; Value: number; };
    FO?: { Rate: number; Value: number; };
    FOS?: { Rate: number; Value: number; };
}

/**
 * Mongo
 */
export class ref_Discount extends ref_DiscountBase {
    DiscountType: ref_DiscountTypesId
    DiscountClass: ref_DiscountClassesId;
    Rank: number;
    LastPosition: boolean = false;
    Operator: 1 | -1;
    //Agreement?: boolean;
    Agreement?: ref_Agreements["@rid"] | ref_GlobalAgreements["@rid"];

    // locked by user, cannot be overriden by agreement or global agreement
    Locked?: boolean;
    Intervals: ref_Intervals[];
    Base?: Base[];
    IsCPM?: boolean;
    KpiCPM?: ref_KPIsId;
    ValueCPM?: number;
}

export class ref_Intervals extends ref_DiscountBase {
    Minimum: number;
    Maximum: number;
}
export class Base {
    Operator: 1 | -1 = 1;
    Type: {
        "@rid": rid;
        "@class": string;
        Name: string;
    };
}
