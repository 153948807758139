import { ref_Companies, ref_CompaniesId } from "./ref_Companies.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_DepartmentsId = rid;

export class ref_Departments extends V implements Referentials {
	"@rid"?: ref_DepartmentsId;
	Company: ref_CompaniesId;
	Active: boolean;

}