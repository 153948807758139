
import * as XLSX from 'xlsx';
import { clone } from '../tools.bin';
import { orderBy } from '@progress/kendo-data-query';

export const DayColWidth = 15;

const BorderHeader = {
    style: "thin",
    color: {
        rgb: "000000"
    }
};

export const BorderStyle = {
    border: {
        left: BorderHeader,
        right: BorderHeader,
        top: BorderHeader,
        bottom: BorderHeader
    }
}

export const BorderEventStyle = {
    border: {
        top: {
            style: "medium",
            color: {
                rgb: "FFFFFF"
            }
        },
        bottom: {
            style: "medium",
            color: {
                rgb: "FFFFFF"
            }
        }
    }
}

export const TopBorderTotalStyle = {
    border: {
        top: {
            style: "thin",
            color: {
                rgb: "000000"
            }
        },
    }
}

export const HeaderCellStyle = {
    s: {
        ...BorderStyle,
        alignment: { vertical: "center", horizontal: "center" },
        numFmt: "General",
        fill: {
            patternType: "solid",
            fgColor: {
                theme: 0,
                tint: -0.3499862666707358,
                rgb: "C7E4F1"
            },
            bgColor: {
                indexed: 64
            }
        },
        font: {
            sz: "11",
            name: "Calibri",
            bold: true
        },
    }
}


/**
 * Set column width
 * @param sheet
 * @param colIndex
 * @param colWidth
 */
export function SetColWidth(sheet: XLSX.WorkSheet, colIndex: number, colWidth: number) {

    if (!sheet["!cols"])
        sheet["!cols"] = [];

    let column: XLSX.ColInfo = sheet["!cols"][colIndex];
    if (!column) {
        column = {};
        sheet["!cols"][colIndex] = column;
    }
    column.wpx = colWidth;
}

export function SetRowHeight(sheet: XLSX.WorkSheet, rowIndex: number, rowHeight: number) {

    if (!sheet["!rows"])
        sheet["!rows"] = [];

    let row: XLSX.RowInfo = sheet["!rows"][rowIndex];
    if (!row) {
        row = {};
        sheet["!rows"][rowIndex] = row;
    }
    row.hpx = rowHeight;
}

type MergeCellsOptions =
    { forceClean?: boolean } & ({ firstRowTemplated?: boolean } | { lastRowTemplated?: boolean })

/**
 * Merge 2 cells
 * @param sheet
 * @param start
 * @param end
 */
export function MergeCells(sheet: XLSX.WorkSheet, start: XLSX.CellAddress, end: XLSX.CellAddress, opt?: MergeCellsOptions) {

    let { forceClean, firstRowTemplated, lastRowTemplated } = { lastRowTemplated: false, firstRowTemplated: false, forceClean: false, ...opt };

    if (start.c === end.c && start.r === end.r)
        return;

    if (!sheet["!merges"])
        sheet["!merges"] = [];

    if (forceClean) {
        const codedCells = [XLSX.utils.encode_cell(start), XLSX.utils.encode_cell(end)];
        sheet["!merges"] = sheet["!merges"]
            .filter(m => !codedCells.includes(XLSX.utils.encode_cell(m.s)) && !codedCells.includes(XLSX.utils.encode_cell(m.e)))

        if (start.c == end.c) {
            sheet["!merges"] = sheet["!merges"].filter(m => {
                if (m.s.c != start.c || m.e.c != start.c) return true;
                if (m.s.c == m.e.c && m.s.c == start.c)
                    if (m.s.r > Math.min(start.r, end.r) && m.s.r < Math.max(start.r, end.r)) {
                        return false;
                    }
                return true;
            })
        }
    }

    if (!firstRowTemplated && !lastRowTemplated)
        sheet["!merges"].push({ s: start, e: end });

    // Récupère le style de la 1ere cellule pour l'appliquer sur toutes les cellules du merge
    const cellStart = sheet[XLSX.utils.encode_cell(lastRowTemplated ? end : start)];
    if (cellStart) {
        if (!cellStart.s) cellStart.s = {};
        const style = cellStart.s;

        // Application du style de la 1ere cellule sur toutes les cellules du merge
        // + ajout de wrapText (retour à la ligne)
        let first = true;
        const cpy = { ...start };
        while (cpy.c <= end.c) {
            cpy.r = start.r;
            while (cpy.r <= end.r) {
                const coords = XLSX.utils.encode_cell(cpy);
                const last = (cpy.r == end.r) && (cpy.c == end.c);

                if (firstRowTemplated && !first || lastRowTemplated && !last) {
                    sheet[coords] = { t: "n", v: '' };
                } else {
                    if (!sheet[coords]) sheet[coords] = { v: '', t: 'n', s: style };
                    else sheet[coords].s = style;

                    // if (sheet[coords].s && !sheet[coords].s.alignment)
                    //     sheet[coords].s.alignment = { vertical: 'center', wrapText: '1' };
                    // else {
                    //     sheet[coords].s.alignment.vertical = 'center';
                    //     sheet[coords].s.alignment.wrapText = '1';
                    // }
                }

                first = false;
                cpy.r++;
            }
            cpy.c++;
        }
    }
}

export function CheckCellIntegrity(sheet: XLSX.WorkSheet, cell) {

    switch (sheet[cell].t) {
        case 's':
            if (typeof sheet[cell].v != 'string')
                sheet[cell].v = (sheet[cell].v ?? "")?.toString();
            break;

        case 'n':
            if (typeof sheet[cell].v != 'number' && isNaN(Number(sheet[cell].v)))
                sheet[cell].t = 's';
            break;

        default:
            break;
    }
}

/**
 * Set value in excel
 * @param sheet
 * @param cell
 * @param value
 */
export function SetCellValue(sheet: XLSX.WorkSheet, cell: XLSX.CellAddress, value: any, base?: any) {

    if (base)
        base = clone(base);

    if (cell.c < 0 || cell.r < 0) {
        console.log(`cannot be < 0`)
        console.log(cell)
        console.log(value)
        console.trace();
        return;
    }

    const cellAdd = XLSX.utils.encode_cell(cell);
    sheet[cellAdd] = {
        ...sheet[cellAdd],
        ...((value !== undefined && value !== null) && {
            t: "s",
            v: `${value}`,
            h: `${value}`,
            w: `${value}`,
            r: `<t>${value}</t>`
        }),
        ...(value === null && { t: "n", v: '' })
    };

    if (base)
        Object.entries(base).forEach(([k, v]) => {
            if (typeof sheet[cellAdd][k] == 'object' && typeof v == 'object')
                sheet[cellAdd][k] = { ...sheet[cellAdd][k], ...(<any>v) };
            else
                sheet[cellAdd][k] = sheet[cellAdd][k] ?? v;
        });

    CheckCellIntegrity(sheet, cellAdd);
}

/**
 * Apply style to cell
 * @param sheet
 * @param cell
 * @param value
 */
export function SetCellStyle(sheet: XLSX.WorkSheet, cells: XLSX.CellAddress | XLSX.CellAddress[], value: any) {

    if (!cells) return;

    if (!Array.isArray(cells))
        cells = [cells];

    cells?.forEach(cell => {
        const cellAdd = XLSX.utils.encode_cell(cell);
        if (!sheet[cellAdd])
            sheet[cellAdd] = {};
        value = JSON.parse(JSON.stringify(value))
        sheet[cellAdd].s = { ...sheet[cellAdd].s, ...value };

        if (!sheet[cellAdd]?.v) {
            sheet[cellAdd].v = '';
            sheet[cellAdd].t = 'n';
        }
    });
}

export interface SortDescriptor {
    /**
     * The field that is sorted.
     */
    field: string;
    /**
     * The sort direction. If no direction is set, the descriptor will be skipped during processing.
     *
     * The available values are:
     * - `asc`
     * - `desc`
     */
    dir?: 'asc' | 'desc';
}

const replaceAll = (str: string, search: string, replacement: string): string => {
    const regex = new RegExp(search, 'g');
    return str.replace(regex, replacement);
};

export function MyOrderBy<T>(data: T[], _descriptors: SortDescriptor[]): T[] {
    const descriptors = clone(_descriptors);
    const newProps: string[] = [];

    descriptors.forEach((d) => {
        if (!d.field.includes('.'))
            return;

        const newProp = replaceAll(d.field, '.', '__');

        newProps.push(newProp);
        data?.forEach((e) => { e[newProp] = e[d.field]; });
        d.field = newProp;
    });

    const ordered = orderBy(data, descriptors);
    newProps.forEach((p) => data.forEach((d) => delete d[p]));

    return ordered;
}
