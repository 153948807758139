import { TradMultiLang, ITradType } from "trad-lib";
import { IRid } from "../IRid.bin";
import { rid } from "../orientdb/CommonTypes.bin";

export class Trad implements IRid, ITradType {
    "@rid"?: rid;
    Type: "dynamic" | "static";
    Class?: string;
    Property?: string;
    Value?: string;
    Code?: string;
    Translations: TradMultiLang;
}