import * as React from "react"
import { TextField } from "@material-ui/core";
import { AdwAutocomplete } from "adwone-lib";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { setMessageSync } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";

export const otherRid = "#-1:-1";

type GroupEditorArgs = {
    property: string;
    require?: string;
    modelProperty?: boolean;
    other?: string;
};
export function GroupEditor({ property, require, modelProperty, other }: GroupEditorArgs) {
    const dispatch = useDispatch();
    const group = useSelector((root: RootState) => modelProperty ? root.messageEditor.message.ModelProperties?.[property] : root.messageEditor.message[property]);
    const ids = useSelector((root: RootState) => root.messageEditor.message[require]);
    const valueChoices = useSelector((root: RootState) => root.messageEditor.groups[property]);

    const isDisabled = !valueChoices?.length || (require && !ids?.length);

    if (!valueChoices) return <></>;

    const onChange = (_group: ref_Groups) => {
        const message = store.getState().messageEditor.getMessage();
        const data = modelProperty ? message.ModelProperties : message;

        if (!message.ModelProperties)
            message.ModelProperties = {};

        data[property] = _group?.["@rid"] ?? null;

        if (other) {
            if (data[property] && data[property] !== otherRid) data[other] = null;
            else data[other] = "";
        }

        dispatch(setMessageSync(message));
    }

    let value = valueChoices?.find(o => o["@rid"] == group);
    const message = store.getState().messageEditor.getMessage();
    const data = modelProperty ? message.ModelProperties : message;
    if (other && !group && data[other])
        value = valueChoices?.find(v => v["@rid"] == otherRid);

    return (
        <AdwAutocomplete
            key={`${GetHashCode(valueChoices)}${GetHashCode(value)}`}
            loading={isDisabled}
            disabled={isDisabled}
            options={valueChoices}
            getOptionLabel={(g) => g.Name}
            value={value}
            onChange={(event: any, group: any) => onChange(group)}
            renderInput={(params: any) => (
                <TextField {...params} variant="outlined" label={TradProp(property, ref_Messages)} />
            )}
        />
    );
}
