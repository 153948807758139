import * as React from 'react'
import { Trad, TradProp } from 'trad-lib';
import Loader from '../../../layout/Loader';
import { VertexGrid } from '../../Generic/VertexGrid.bin';
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { AdwTelerikGrid } from '../../Generic/AdwTelerikGrid.bin';
import { ref_TableConfigurations } from 'hub-lib/models/orientdb/ref_TableConfigurations.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/models/ref_SchedulerConfigurations.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { Format } from 'format-lib/index.bin';
import { CreateIndicateurInfo } from 'adwone-engine/index.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { TextColorEditor } from './TextStyleEditor';
import { GetHashCode, GetSubElement } from 'hub-lib/tools.bin';

class TProps {
    propName?: string;
    vertex: any
    params?: any;
    template: ref_SchedulerConfigurations;
    propKey: string;
    onChange?: () => void;
}

export function VertecesStyleComponent({ propName, propKey, template, vertex, params, onChange }: TProps) {

    const [grid, setGrid] = React.useState<VertexGrid<any>>();
    const [selectedItems, setSelectedItems] = React.useState<string[]>();
    const data = useSelector((root: RootState) => root.gridMessages.data);

    React.useEffect(() => {
        if (grid)
            setGrid(undefined);
    }, [GetHashCode(data)]);

    React.useEffect(() => {
        if (grid === undefined) {
            const nameColumn = new ADWColumn<any>(TradProp("Name"), "Name", ePropType.String, true);
            nameColumn.cellValue = (cellValue: any, dataItem?: AdwRow<any>) => {
                dataItem['Name_cellValue'] = Format(dataItem.dataItem);
                return Format(dataItem.dataItem);
            }

            const templateColumn = new ADWColumn<any>(' ', "Badge", ePropType.Boolean);
            templateColumn.width = 60;
            templateColumn.cellValue = (cellValue: any, dataItem?: AdwRow<any>) => {
                const config = template?.Style[dataItem.dataItem[propName ?? "@rid"]];
                dataItem['Badge_cellValue'] = config != undefined;

                const item = dataItem.dataItem[propName ?? "@rid"];
                return <div style={{ display: "flex", alignItems: "center" }}>
                    {(template.Style[item]?.textStyle || dataItem.selected) &&
                        <TextColorEditor
                            badge
                            type={vertex.name}
                            propKey={propKey}
                            selectedItems={[item]}
                            template={template}
                            onChange={() => onChange?.()} />
                    }
                </div>
            }

            const columns: ADWColumn<any>[] = [nameColumn, templateColumn];
            const hiddenProperties: string[] = ["Name"];

            const hashes = new Set();
            data?.forEach(d => {
                const value = GetSubElement(d, propKey);
                if (value)
                    hashes.add(value)
            });
            const filter = { [propName ?? "@rid"]: Array.from(hashes) };

            const grid = new VertexGrid<any>({
                objectPrototype: vertex,
                devMode: false,
                columns,
                configuration: {
                    ...new ref_TableConfigurations(),
                    Columns: [
                        CreateIndicateurInfo("Name", eKPIType.String, Trad("Name")),
                        CreateIndicateurInfo("Badge", eKPIType.String, Trad("Badge"))]
                },
                hiddenProperties,
                afterSearch: (vertices: any[]) => {
                    let tmp: any[] = [];
                    vertices?.forEach(v => {
                        if (!tmp.some(t => t[propName ?? "@rid"] === v[propName ?? "@rid"]))
                            tmp.push(v);
                    });
                    return tmp;
                },
                vertexParams: {
                    properties: ["Name"],
                    ...filter,
                    ...params,
                    Active: true
                }
            });
            setGrid(grid);
        }
    }, [grid])

    if (!template.Style)
        template.Style = {};

    if (!grid)
        return <Loader />

    return (
        <div className='configuration-scheduler'>
            <div className={`configuration-scheduler-selecteditems ${selectedItems?.length > 1 ? "with-selected-items" : ""}`}>
                <TextColorEditor
                    badge
                    template={template}
                    type={vertex.name}
                    propKey={propKey}
                    selectedItems={selectedItems}
                    onChange={() => onChange?.()}
                />
            </div>
            <div className={`configuration-scheduler-grid`}>
                <AdwTelerikGrid
                    sort={[{ field: "Name", dir: "asc" }]}
                    hideToolbar
                    isCopyDisable
                    selectable
                    uneditable
                    selectionChange={(rows) => setSelectedItems(rows.map(e => e.dataItem[propName ?? "@rid"]))}
                    gridHeight={"calc(100vh - 401px)"}
                    grid={grid}
                    autoColumnWidth
                />
            </div>
        </div>
    )
}
