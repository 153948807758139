import { configureStore } from "@reduxjs/toolkit"
import { negotiationEditorReducer } from "./negotiationEditorSlice"

export const storeNegotiation = configureStore({
    reducer: {
        editor: negotiationEditorReducer,
    },
    devTools: {
        name: "Negotiation"
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof storeNegotiation.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof storeNegotiation.dispatch