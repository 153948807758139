import * as React from "react";
import {
  PivotGrid,
  PivotGridContainer,
  PivotGridConfigurator,
  PivotLocalDataServiceArgs,
  usePivotLocalDataService,
  PivotGridProps
} from "@progress/kendo-react-pivotgrid";
import { Trad } from "trad-lib";
import { ConfigurationPanel } from "../ConfigurationPanel";
import { clone, GetHashCode, hasConfig } from "hub-lib/tools.bin";
import { Badge } from "@progress/kendo-react-indicators";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from "../../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { FiltersComponent } from "../../Filters/FiltersComponent";
import { CustomEditor } from "./CustomEditor";
import { Form, FormProps } from "@progress/kendo-react-form";
import { CreateIndicateur, DefaultReturnCurrencyProvider, eDirection, IndicateurToString } from "adwone-engine/index.bin";
import { CustomPivotGridHeader } from "./CustomPivotGridHeader";
import { PivotGridConfiguration } from "./Configuration/PivotGridConfiguration";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setTCDArgs, TcdState } from "../../../../redux/tcdSlice";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";

export function CustomForm(props: FormProps) {
  return <Form
    {...props}
    onSubmit={async (e) => {
      console.log(`[CustomForm] submit`, e)
      await DefaultReturnCurrencyProvider.init();
      props?.onSubmit?.(e);
    }} />
}

type CustomPivotGridProps = { filters: any, serviceArgs: PivotLocalDataServiceArgs }
export function CustomPivotGrid({ filters }: CustomPivotGridProps) {

  const dispatch = useDispatch();
  const serviceArgs = useSelector((root: RootState) => root.tcd.serviceArgs);
  const columns = useSelector((root: RootState) => root.tcd.columns);
  const rows = useSelector((root: RootState) => root.tcd.rows);
  const kpis = useSelector((root: RootState) => root.tcd.kpis);

  const time7810 = new Date().getTime();
  const { pivotProps, configuratorProps } = usePivotLocalDataService(serviceArgs);
  const _time7810 = new Date().getTime();
  console.log(`[TRACK_TCD] usePivotLocalDataService Elapsed ${_time7810 - time7810}ms`);

  console.log(`[TRACK_TCD] CustomPivotGrid`)

  const isColUseEffectMounted = React.useRef(false);
  React.useEffect(() => {
    if (!isColUseEffectMounted.current) {
      isColUseEffectMounted.current = true;
      return;
    }

    configuratorProps.onColumnAxesChange({
      value: columns.map((c, i) => ({ name: [IndicateurToString(c)], expand: i == 0 })),
      target: null,
      syntheticEvent: null
    })
  }, [GetHashCode(columns)])

  const isRowlUseEffectMounted = React.useRef(false);
  React.useEffect(() => {
    if (!isRowlUseEffectMounted.current) {
      isRowlUseEffectMounted.current = true;
      return;
    }

    configuratorProps.onRowAxesChange({
      value: rows.map((c, i) => ({ name: [IndicateurToString(c)], expand: i == 0 })),
      target: null,
      syntheticEvent: null
    })
  }, [GetHashCode(rows)])

  const isKpilUseEffectMounted = React.useRef(false);
  React.useEffect(() => {
    if (!isKpilUseEffectMounted.current && kpis.length == 0) {
      isKpilUseEffectMounted.current = true;
      return;
    }

    configuratorProps.onMeasureAxesChange({
      value: kpis.map((c, i) => ({ name: [c.name], expand: i == 0 })),
      target: null,
      syntheticEvent: null
    })
  }, [GetHashCode(kpis)])

  //dispatch(setTCDArgs({ ...serviceArgs, data: [serviceArgs.data[0]] }));

  React.useEffect(() => {
    if (!isKpilUseEffectMounted.current || !isRowlUseEffectMounted.current || !isColUseEffectMounted.current)
      return;

    Promise.resolve().then(async () => {

      const data = clone(serviceArgs.data);

      const dicoRowCross: TcdState['dicoRowCross'] = {};
      const dicoColCross: TcdState['dicoColCross'] = {};

      const instancesRows = rows.map(r => CreateIndicateur(r));
      const instancesCols = columns.map(r => CreateIndicateur(r));

      await Promise.all(data.map(async msg => {
        const rowKey = await Promise.all(instancesRows.map(i => i.Compute([msg])));
        rowKey.forEach((k, i) => {
          const indicateurKey = IndicateurToString(rows[i]);
          if (!dicoRowCross[indicateurKey]) dicoRowCross[indicateurKey] = {};
          if (!dicoRowCross[indicateurKey][k]) dicoRowCross[indicateurKey][k] = []
          dicoRowCross[indicateurKey][k].push(msg);
        });

        const colKey = await Promise.all(instancesCols.map(i => i.Compute([msg])));
        colKey.forEach((k, i) => {
          const indicateurKey = IndicateurToString(columns[i]);
          if (!dicoColCross[indicateurKey]) dicoColCross[indicateurKey] = {};
          if (!dicoColCross[indicateurKey][k]) dicoColCross[indicateurKey][k] = []
          dicoColCross[indicateurKey][k].push(msg);
        });
      }));

      // for (const msg of data) {
      //   for (const kpisInd of kpis.filter(i => i.optionsBase?.direction && i.optionsBase?.direction != eDirection.U)) {
      //     const key = IndicateurToString(kpisInd);
      //     const instanceIndicateur = CreateIndicateur(kpisInd);

      //     let totalMessageRow: ref_Messages[] = [];
      //     switch (kpisInd.optionsBase?.direction) {
      //       case eDirection["%HorizontalTotal"]:
      //         const rowKey = (await Promise.all(instancesRows.map(i => i.Compute([msg])))).join(";");
      //         totalMessageRow = dicoRowCross.get(rowKey);
      //         break;
      //       case eDirection["%VerticalTotal"]:
      //         const colKey = (await Promise.all(instancesCols.map(i => i.Compute([msg])))).join(";");
      //         totalMessageRow = dicoColCross.get(colKey);
      //         break;

      //       default:
      //         break;
      //     }

      //     const currentMsgResult = await instanceIndicateur.Compute([msg]) as number;
      //     const arrayOfNumbers = await Promise.all(totalMessageRow.map(i => instanceIndicateur.Compute([i])));
      //     const total = arrayOfNumbers.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
      //     const ratio = currentMsgResult / (total || 1);
      //     msg[key] = ratio;
      //   }
      // }
      // dispatch(setTCDArgs({
      //   service: { ...serviceArgs, data },
      //   dicoColCross,
      //   dicoRowCross
      // }));

    });
  }, [GetHashCode(kpis), GetHashCode(rows), GetHashCode(columns)]);

  const confComponent = (
    <ConfigurationPanel
      elements={[
        {
          type: "icon",
          title: () => Trad("filters"),
          icon: "filterAlt",
          badge: hasConfig(filters) ? <Badge cutoutBorder align={{ vertical: "top", horizontal: "start" }} /> : null,
          element: <FiltersComponent />
        },
        // {
        //   type: "icon",
        //   title: () => Trad("settings"),
        //   icon: "settings",
        //   element: <PivotGridConfigurator   {...configuratorProps} editor={CustomEditor} form={CustomForm} />
        // },
        {
          type: "icon",
          title: () => Trad("settings"),
          icon: "settings",
          element: <PivotGridConfiguration />
        }
      ]}
    />);

  return <>
    <ToolbarAdw>
      <BreadcrumbsCustomContainer>
        <BreadcrumbsCustom
          elements={[{ text: Trad("home"), href: "/" }, { text: "TCD" }]} />
      </BreadcrumbsCustomContainer>
      <ConfigurationPanelContainer>{confComponent}</ConfigurationPanelContainer>
    </ToolbarAdw>
    <AdwTCD pivotProps={pivotProps} />
  </>
}

type AdwTCDProps = { pivotProps: PivotGridProps }
function AdwTCD({ pivotProps }: AdwTCDProps) {
  return <PivotGridContainer>
    <PivotGrid  {...pivotProps} columnHeadersCell={CustomPivotGridHeader} />
  </PivotGridContainer>;
}