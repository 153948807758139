import {rid} from "./orientdb/CommonTypes.bin";

export type ref_ReleaseVersionId = rid;

export class ref_ReleaseVersion {
  "@rid"?: ref_ReleaseVersionId;
  Name: string;
  Date: Date;
  Image?: string;
  Note?: string;
  Active: boolean;
}
