import { Referentials } from "./orientdb/Referentials.bin";
import { V } from "./orientdb/V.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_PropertyId } from "./orientdb/ref_Property.bin";
import { ref_SupportsId } from "./orientdb/ref_Supports.bin";
import { ref_CampaignsId } from "./ref_Campaigns.bin";

export type ref_AttachmentsId = rid;
export type ref_VisualsId = rid;

export class ref_Attachments extends V implements Referentials {
    "@rid"?: ref_AttachmentsId;
    Content: any; // the Attachment
    Active: boolean;
    AttachementType: string;
    DocumentOwners: rid[];
    Type: string;
}


export class ref_Comments extends ref_Attachments {
    AttachementType = "comment";
    Type: "External" | "Internal";
    Text: string;
}

export class ref_Files extends ref_Attachments {
    AttachementType = "file";
    Name: string; // nom du fichier
    Size: number; // taille du fichier
    Content: rid;
}

export enum eVisualStatus {
    "Create",
    "Pending",
    "Ended",
    "Sent"
}
export class ref_Visuals extends V implements Referentials {
    "@rid"?: ref_VisualsId;
    Name: string;
    Active: boolean;
    Campaign: ref_CampaignsId;
    File?: ref_AttachmentsId;
    //TechnicalSpecifications?: ref_AttachmentsId;
    //TechDeliveryTimeLimit?: Date;
    Status?: eVisualStatus;
}

export class ref_HyperLinks extends ref_Attachments {
    AttachementType: string = "hyperlink";
    Type = "Tech"
    Content: string;
}