import * as React from "react";
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { TabManager, eventAdded, eventSelected, eventDeleted, ITab } from "./TabManager.bin";
import { GenericComponentTab } from "./GenericComponentTab.bin";
import { TextField } from "@material-ui/core";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { getIcon } from "adwone-lib/index";

export class TProps {
    tabManager: TabManager;
    // clickToAddTab: () => void;
    // doubleClickToDeleteTab: () => void;
}


export default class TabComponent extends React.Component<TProps, any> {

    // constructor(props: TProps) {
    //     super(props);
    // }

    myForceUpdate = () => {
        try {
            this.forceUpdate();
        } catch (e) {

        }
    }

    componentDidMount() {
        this.props.tabManager.onTabChanged.on(eventAdded, this.myForceUpdate);
        this.props.tabManager.onTabChanged.on(eventSelected, this.myForceUpdate);
        this.props.tabManager.onTabChanged.on(eventDeleted, this.myForceUpdate);
    }

    componentWillUnmount() {
        this.props.tabManager.onTabChanged.removeListener(eventAdded, this.myForceUpdate);
        this.props.tabManager.onTabChanged.removeListener(eventSelected, this.myForceUpdate);
        this.props.tabManager.onTabChanged.removeListener(eventDeleted, this.myForceUpdate);
    }

    onChangeHandler = (e: any, value: any) => {
        if (value != -1) {
            let { tabs } = this.props.tabManager;
            let newSelected = tabs[value];
            if (newSelected)
                this.props.tabManager.select(newSelected);
        }
    }

    clickToAddTab = () => {

        let { tabs } = this.props.tabManager;
        let newtab = new GenericComponentTab();
        let baseName = newtab.name;
        let currentName = baseName;
        let idx = 1;
        while (tabs.find(tab => tab.name === currentName)) {
            currentName = baseName + ` (${idx++})`;
        }
        newtab.name = currentName;

        this.props.tabManager.addTab(newtab);
    }

    doubleClickToDeleteTab = (tab: any) => {
        this.props.tabManager.deleteTab(tab);
    }
    render() {

        let { tabs, selectedTab } = this.props.tabManager;
        let idx = selectedTab ? tabs.map(t => t.id).indexOf(selectedTab.id) : tabs.length - 1;
        let idxTab = 0;
        let idxPan = 0;
        return (
            <div>
                <Tabs value={idx} onChange={this.onChangeHandler} id="tabed_generic">
                    {tabs.map(t =>
                        <Tab key={`tab-${t.id}`} value={idxTab++} label={
                            <TabHeader
                                TabManager={this.props.tabManager}
                                Tab={t}
                                clickToDeleteTab={this.doubleClickToDeleteTab} />

                        } />
                    )}
                    {tabs.length <5 && <CustomButton Label={getIcon("plus_rounded")} className={"custom_btn_nostyle_primary"} onClick={this.clickToAddTab}/>}
                </Tabs>
                {tabs.map(t => {
                    let visibility: any = (idxPan === idx) ? 'visible' : 'collapse';
                    let height: any = (idxPan === idx) ? 'auto' : '0px';

                    return <div key={`selectedTab-${idxPan++}`} className="content-tab clearfix" style={{ visibility, height, overflow: 'hidden' }}>
                        {t?.getComponent()}
                    </div>
                })}

            </div>);
    }

}

export class TabHeaderProps {
    clickToDeleteTab: (tab: ITab) => void;
    Tab: ITab;
    TabManager: TabManager;
}

export class TabHeaderStats {
    isEditing: boolean;
}

export class TabHeader extends React.Component<TabHeaderProps, TabHeaderStats> {

    constructor(props: TabHeaderProps) {
        super(props);
        this.state = {
            isEditing: false
        }
    }

    render() {
        let { Tab, clickToDeleteTab } = this.props;
        let { isEditing } = this.state;

        return (
            <>
                {!isEditing && <div onDoubleClick={() => this.setState({ isEditing: true })}>
                    {`${Tab.name}`}
                    <div style={{ height: 20, width: 20, fontSize: '0.8rem', float: 'right', borderRadius: '50%', marginLeft: 10, textTransform: 'none' }} onClick={() => { clickToDeleteTab(Tab) }}>x</div>
                </div>}

                {isEditing && <TextField
                    autoFocus
                    inputRef={(ref) => {
                        if (ref)
                            ref.onblur = () => {
                                this.setState({ isEditing: false })
                                this.props.TabManager.notifyChanged();
                            }
                    }}
                    contentEditable={true}
                    value={Tab.name}
                    onChange={(e) => { Tab.name = e.target.value; this.forceUpdate() }} />}
            </>
        );
    }
}

