
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { eCompare } from "hub-lib/operators.bin";
import { extractSub, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp } from "trad-lib";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { getLabelPlacement, getPlacementOffer, getPlacementPriceExistsComponent } from "./OfferTools";
import { WarningMM } from "./WarningMM";
import { vw_mm_HasPlacement } from "hub-lib/models/orientdb/vw_mm_HasPlacement.bin";
import { vw_tsm_HasEmplacement } from "hub-lib/models/orientdb/vw_tsm_HasEmplacement.bin";
import { CheckElement } from "./CheckElement";
import { EmplacementExtended } from "hub-lib/models/EmplacementExtended.bin";

let placementRidGlobal = undefined;

type PlacementEditorProps = { broadcastPlacement?: boolean }
export function PlacementEditor({ broadcastPlacement }: PlacementEditorProps) {

    const dispatch = useDispatch();
    const { Media, BroadcastArea, Format, ModelProperties, Placement } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ["Media", "BroadcastArea", "Placement", "Format", "ModelProperties"]) ?? {}, JSONEqualityComparer);
    // important de garder ce selector pour refresh le composant quand les labels de prix quand les offres sont Sets
    const offerHash = GetHashCode(useSelector((root: RootState) => root.messageEditor.offers)?.map(o => o["@rid"]));

    const placementValue = broadcastPlacement
        ? ModelProperties.BroadcastPlacement
        : Placement;

    const [placementRid, setPlacementRid] = React.useState(placementRidGlobal);

    React.useEffect(() => {
        if (!placementRid)
            Client.searchVertexTyped<ref_PropertyType>(ref_PropertyType, { Type: "Emplacement" })
                .then(([_placement]) => setPlacementRid(_placement["@rid"]));
    })

    if (!placementRid)
        return <></>

    return <WarningMM ids={[
        { id: Placement, linkClass: vw_mm_HasPlacement.name },
        { id: Placement, linkClass: vw_tsm_HasEmplacement.name }]}>
        <FavoriteVertexAutoComplete
            key={`PlacementEditor-${GetHashCode({ Format, placementValue, offerHash })}`}
            type={EmplacementExtended.name}
            refreshOnPropChanged
            disabled={!Media || !BroadcastArea}
            label={broadcastPlacement ? TradProp('ModelProperties.BroadcastPlacement', ref_Messages) : TradProp("Placement", ref_Messages)}
            params={{
                Active: true,
                _operators: [{
                    property: "PropertyType",
                    value: placementRid,
                    compare: eCompare.Contains
                }],
                Medias: [Media],
                BroadcastAreas: BroadcastArea,
                properties: ["@rid", "Name", "LinkedProperties", "@class"]
            }}
            defaultValue={(options: EmplacementExtended[]) => {
                const key = `${placementValue ?? ""}${ModelProperties["Majoration"] ?? ""}`;
                const found = options?.find(o => o["@rid"] == key);
                return CheckElement(found, placementValue, ref_Property.name);
            }}
            leftColumns={[getPlacementPriceExistsComponent]}
            getCategory={(o: EmplacementExtended) => getPlacementOffer(o?.Emplacement) ? Trad("other_with_price") : Trad("other_with_no_price")}
            SortCategories={[Trad("other_with_price"), Trad("other_with_no_price")]}
            getOptionLabel={getLabelPlacement}
            Sort={(a, b, groupA, groupB) => {
                const tradFav = Trad("favorite");
                if (groupA === tradFav && groupB === tradFav) {
                    const offerA = getPlacementOffer(a.Emplacement);
                    const offerB = getPlacementOffer(b.Emplacement);
                    if (offerA && !offerB) return -1;
                    if (!offerA && offerB) return 1;
                    return 0;
                }
                return undefined;
            }}
            nullOnClear
            onChange={async (placement: EmplacementExtended) => {
                const message = store.getState().messageEditor.getMessage();

                if (broadcastPlacement) {
                    message.ModelProperties.BroadcastPlacement = placement?.Emplacement;
                    //TODO Comment gérer la majoration sur BroadcastPlacement
                }
                else {
                    message.Placement = placement?.Emplacement;
                    if (placement?.Majoration)
                        message.ModelProperties["Majoration"] = placement.Majoration;
                    else
                        delete message.ModelProperties["Majoration"];
                }
                dispatch(setMessage(message));
            }} />
    </WarningMM>
}