import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Publications } from "hub-lib/models/ref_Publications.bin";
import { extractSub, GetDuplicatedMessages, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { setMessageSync, setPublications } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import PublicationsCalendar from "../PublicationsCalendar/PublicationsCalendar.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { DateNoZone } from "tools-lib";

type PublicationsEditorArgs = { onChange?: (value: Date) => void }
export function PublicationsEditor({ onChange }: PublicationsEditorArgs) {

    const [publicationsState, setPublicationsState] = React.useState<{
        support: IRid["@rid"],
        broadcastArea: IRid["@rid"],
        publications: ref_Publications[]
    }>();

    const [duplicatesMessages, setDuplicatesMessages] = React.useState<ref_Messages[]>();

    const { Start, Support, AdvertiserGroup, Advertiser, BroadcastArea, Currency, Brand, Product } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ["Start", "Support", "BroadcastArea", "AdvertiserGroup", "Advertiser", "Currency", "Brand", "Product"]) ?? {}, JSONEqualityComparer);

    React.useEffect(() => {
        if (Support && BroadcastArea && (Support != publicationsState?.support || BroadcastArea != publicationsState?.broadcastArea)) {
            Promise.resolve().then(async () => {
                const publications = await Client.searchVertexTyped(ref_Publications, {
                    Support, BroadcastArea, properties: ['@rid', 'Publication', 'Dated', 'Reference', 'Theme', 'Source']
                });

                // TODO: reprise de code Legacy, à voir pourquoi le DateNoZone
                publications.forEach(p => p.Publication = DateNoZone(p.Publication));
                setPublicationsState({
                    support: Support,
                    broadcastArea: BroadcastArea,
                    publications
                });
                store.dispatch(setPublications(publications));
            })
        }
    }, [Support, BroadcastArea])

    React.useEffect(() => {

        // recherche des messages doublons
        if (Start && Support && AdvertiserGroup && BroadcastArea && Currency) {
            Promise.resolve().then(async () => {
                const duplicates = await GetDuplicatedMessages({ Support, AdvertiserGroup, BroadcastArea, Currency });
                duplicates.forEach(d => d.Start = new Date(d.Start));
                setDuplicatesMessages(duplicates);
            })
        }

    }, [Start, Support, AdvertiserGroup, Advertiser, BroadcastArea, Currency])

    return <PublicationsCalendar
        duplicatesMessages={duplicatesMessages ?? []}
        date={Start}
        publications={publicationsState?.publications ?? []}
        applyPublication={(publication: ref_Publications, date: Date) => {
            const message = store.getState().messageEditor.getMessage();
            message.ModelProperties["Dated"] = publication?.Dated;
            message.ModelProperties["Reference"] = publication?.Reference;
            message.ModelProperties["EditorialTheme"] = publication?.Theme;
            store.dispatch(setMessageSync(message));
            onChange?.(date);
        }} />
}