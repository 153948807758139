import * as React from 'react'
import { Grid } from "@material-ui/core";
import { Client } from "hub-lib/client/client.bin";
import { clientCache } from "hub-lib/client/clientCache.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisingCompanyGroups } from "hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { ref_AgencyGroups } from "hub-lib/models/orientdb/ref_AgencyGroups.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { clearEmptyValues, GetFlatElements } from "hub-lib/tools.bin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Trad, TradClassName } from "trad-lib";
import { TreeProps } from "../../../../redux/referentialTreeSlice";
import { RootState } from "../../../../redux/store";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { ConfirmDeleteContent, eDialogMode, GenericDialog } from "../../../ConfigurableComponents/GenericDialog.bin";
import { getIcon } from "adwone-lib/index";
import { CustomAddButtons } from "../../Generic/CustomAddButtons.bin";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from "../../Generic/ToolbarAdw";
import { ConfigurationPanel, ElementArg } from "../../Messages/ConfigurationPanel";
import { ReferentialForm } from "./ReferentialForm.bin";
import { GetChildClass, GetParentClass, NewElement } from './ReferentialTools'
import { TreeFiltersComponent } from "./TreeFiltersComponent";
import { ReferentialElement } from "../../../layout/SuperAdmin/Referential.bin";
import { BagesUsage } from "./BadgesUsage.bin";
import { TreeListCellProps, TreeListColumnProps, TreeListDateFilter, TreeListTextFilter } from "@progress/kendo-react-treelist";
import { CustomTreeList } from "../../Generic/CustomTreeList";
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { SubTitle } from '../../Generic/Common.bin';
import { ExportComponent } from './ExportComponent';
import Loader from '../../../layout/Loader';
import { editCell } from '../../../crossedTable/CrossedTableTelerikTree.bin';

const calcHeight = "calc(100vh - 198px)";

export class TreeListRefNode {
    id: string;
    Children: TreeListRefNode[] = [];
    Expanded: boolean = false;
    Selected: boolean = false;
}

class SelectedProps {
    Item: TreeListRefNode | IRid;
    ClassName: string;
    Parent?: string;
    Mode?: eDialogMode;
}

export function TDComponent({ colIndex, colSpan, isSelected, dataItem, className, style, children }: TreeListCellProps & { children }) {

    return <td
        className={className}
        style={style}
        role="gridcell"
        colSpan={colSpan}
        aria-colindex={colIndex}
        aria-selected={isSelected}
        data-grid-col-index={colIndex}>
        {children}
    </td>
}

export function ReferentialTreeList(props: TreeProps) {

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [flatData, setFlatData] = useState<IRid[]>([]);
    const [data, setData] = useState<TreeListRefNode[]>([]);
    const [checkedElmnts, setCheckedElmnts] = useState<TreeListRefNode[]>([]);
    const [SelectedProps, setSelectedProps] = useState<SelectedProps>(undefined);
    const [filter, setFilter] = useState<any>();
    const [DeleteElements, setDeleteElements] = useState<any[]>(undefined);

    const configFilter = useSelector((root: RootState) => {
        if (props.hierarchyClassName[0] == ref_AdvertiserGroups.name)
            return root.referentialTree.advertiserFilter;
        if (props.hierarchyClassName[0] == ref_AgencyGroups.name)
            return root.referentialTree.agencyFilter;
        if (props.hierarchyClassName[0] == ref_AdvertisingCompanyGroups.name)
            return root.referentialTree.advertisingCompanyFilter;
        return undefined;
    });

    useEffect(() => {
        if (!loaded || (JSON.stringify(filter ?? {}) != JSON.stringify(configFilter ?? {}))) {
            setLoaded(true);
            if (props.children || props.filterName)
                setFilter(configFilter);
            initializeData();
        }
    })

    const createNode = (d: any, level: number = 0, parent?: TreeListRefNode): TreeListRefNode => {
        let node: TreeListRefNode = new TreeListRefNode();
        node = { id: d["@rid"] ?? "#-1:-1", ...d, Start: d["Start"] ? new Date(d["Start"]) : undefined, End: d["End"] ? new Date(d["End"]) : undefined };
        node["lnkCurrencies"] = node?.["lnkCurrencies"]?.length ? node["lnkCurrencies"] : parent?.["lnkCurrencies"];
        if (parent && level < props.hierarchyClassName.length) {
            node[props.hierarchyClassName[level - 1]] = parent["Name"];
            for (let i = 0; i < level - 1; i++)
                node[props.hierarchyClassName[i]] = parent[props.hierarchyClassName[i]];
        }
        if (d["items"])
            node.Children = d["items"].map(i => createNode(i, level + 1, node));
        return node;
    }

    const initializeData = async () => {
        clientCache.clearCache(props.hierarchyClassName[0])
        const resp = await Client.searchVertex(NewElement(props.hierarchyClassName[0]).constructor.name, {
            Active: true,
            deep: true,
            format: {
                children: "items"
            },
            ...clearEmptyValues(configFilter)
        });
        const nodes: TreeListRefNode[] = resp.data.results.map(el => createNode(el));
        setFlatData(GetFlatElements(resp.data.results, "items"));
        setData(nodes);
        setLoading(false);
    }

    const onItemClick = (item: TreeListRefNode) => {
        if (item.id == "#-1:-1")
            setSelectedProps(undefined);
        else
            setSelectedProps({ Item: item, ClassName: item["@class"], Mode: eDialogMode.modify });
    }

    const onDelete = (todelete: any[]) => {
        // Sort selected element by class
        const todoDeleteByClass = todelete.reduce((rv: any, x: any) => {
            (rv[x["@class"]] = rv[x["@class"]] || []).push(x["@rid"]);
            return rv;
        }, {});
        // Call all necessary vertex service
        Promise.all(
            Object.keys(todoDeleteByClass).map((c) =>
                Client.deleteVertex(c, todoDeleteByClass[c])
            )
        ).then((r) => {
            setSelectedProps(undefined);
            setDeleteElements(undefined);
            initializeData();
        }).catch((e) => console.error(e));
    }

    const hasSelectedItems = checkedElmnts.length > 0;
    const elements: ElementArg[] = [];
    elements.push({
        type: "component",
        component: <CustomAddButtons
            tooltip={Trad("create_element")}
            className="no-shadow no-radius custom_btn_primary no-shadow"
            isIcon
            disabled={!RightManager.hasRight(props.hierarchyClassName[0], eRights.create)}
            disableMoreIcon
            options={props.hierarchyClassName.map(c => { return { Name: c } })}
            onChanged={(selectedClass) => setSelectedProps({ Item: NewElement(selectedClass.Name), ClassName: selectedClass.Name, Mode: eDialogMode.create })} />
    });

    elements.push({
        type: "icon",
        title: () => Trad("export"),
        icon: "download",
        element: <ExportComponent classes={props.hierarchyClassName} filter={{ "@rid": flatData?.map(d => d['@rid']) }} />
    })

    let filterElement = props.children;

    if (props.filterName)
        filterElement = <TreeFiltersComponent {...props} />

    if (filterElement) {
        elements.push({
            type: "icon",
            title: () => Trad("filters"),
            icon: "filterAlt",
            badge: null,
            element: filterElement
        });
    }

    const confComponent = <ConfigurationPanel elements={elements} />

    const columns: TreeListColumnProps[] = [{
        field: "Name",
        width: 200,
        title: Trad("Name"),
        filter: TreeListTextFilter,
        expandable: true,
        resizable: true
    }];

    for (let i = 0; i < props.hierarchyClassName.length - 1; i++) {
        columns.push({
            field: props.hierarchyClassName[i],
            width: 200,
            title: Trad(props.hierarchyClassName[i]),
            filter: TreeListTextFilter,
            resizable: true
        });
    }
    columns.push({
        field: "Start",
        width: 150,
        title: Trad("start_valid"),
        format: "{0:d}",
        filter: TreeListDateFilter,
        resizable: true
    });
    columns.push({
        field: "End",
        width: 150,
        title: Trad("end_valid"),
        format: "{0:d}",
        filter: TreeListDateFilter,
        resizable: true
    });
    if ([ref_BroadcastAreas.name, ref_AdvertiserGroups.name].includes(props.hierarchyClassName[0])) {
        columns.push({
            field: "lnkCurrencies",
            width: 70,
            title: Trad("currency"),
            cell: (cellProps) => {
                const cell = (props: any) => {
                    const currency = props.dataItem?.["lnkCurrencies"]?.[0]?.["outName"]
                    return <td {...props} style={{ display: "contents" }}>{currency}</td>;
                }
                return <TDComponent {...cellProps}>{cell(cellProps)}</TDComponent>
            },
            resizable: true
        })
    }
    columns.push({
        field: "@rid",
        width: 100,
        title: Trad("ID"),
        filter: TreeListTextFilter,
        resizable: true
    });
    columns.push({
        locked: true,
        width: editCell * 2,
        cell: (cellProps) => {
            let selectedVertex = GetChildClass(cellProps.dataItem["@class"], props.hierarchyClassName);
            return <TDComponent {...cellProps} className={cellProps.className + " no-border border-left no-padding"}>
                <span style={{ display: "flex" }}>
                    <CustomIconButton className="custom_btn_secondary" disabled={cellProps.dataItem["id"] == "#-1:-1"} onClick={() => onItemClick(cellProps.dataItem)}>{getIcon("edit")}</CustomIconButton>
                    <CustomIconButton className="custom_btn_secondary" disabled={!selectedVertex} onClick={() => {
                        setSelectedProps({ Item: NewElement(selectedVertex), ClassName: selectedVertex, Mode: eDialogMode.create, Parent: cellProps.dataItem["@rid"] });
                    }}>{getIcon("plus")}</CustomIconButton>
                </span>
            </TDComponent>
        }
    });

    return (<div>
        <ToolbarAdw hasSelectedItems={hasSelectedItems}>
            <ToolbarContainer>
                {hasSelectedItems &&
                    <SelectedItemsContainer>
                        <CustomButton
                            Label={Trad("unselect_all")}
                            className="custom_btn_secondary_white"
                            style={{ marginRight: 10 }}
                            onClick={() => setCheckedElmnts([])} />
                        <CustomButton
                            Label={Trad("delete_selection")}
                            disabled={!RightManager.hasRight(props.hierarchyClassName[0], eRights.delete)}
                            className="custom_btn_danger"
                            style={{ marginRight: 10 }}
                            startIcon={getIcon("delete")}
                            onClick={() => setDeleteElements(checkedElmnts)} />
                    </SelectedItemsContainer>
                }
                {!hasSelectedItems && <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom
                        hasSelectedItems={hasSelectedItems}
                        elements={[
                            { text: Trad("home"), href: "/" },
                            { text: Trad("referential") },
                            { text: Trad(props.hierarchyClassName[0]) },
                        ]} />
                </BreadcrumbsCustomContainer>}
            </ToolbarContainer>
            {<ConfigurationPanelContainer children={confComponent} />}
        </ToolbarAdw>
        <GenericDialog
            style={{ zIndex: 20000 }}
            open={DeleteElements?.length > 0}
            dialogTitle={Trad("confirmation")}
            handleClose={() => setDeleteElements(undefined)}
            submitAction={() => onDelete(DeleteElements)}
            cancelAction={() => setDeleteElements(undefined)}
            submitClass="custom_btn_danger"
            submitTitle={Trad("yes")}
            startIcon={getIcon("clear")}
            actions={true}>
            <p>{ConfirmDeleteContent(DeleteElements)}</p>
        </GenericDialog>
        <Grid className="treeview_container k-grid-table negotiation-grid-container">
            <Grid xs={6} item style={{ height: calcHeight, overflow: 'auto', overflowX: 'hidden' }}>
                {!loading ?
                <CustomTreeList
                    data={data}
                    selectable
                    selectedField="Selected"
                    expandField="Expanded"
                    subItemsField="Children"
                    columns={columns}
                    isSelectable={(r) => true}
                    selectedIds={checkedElmnts.map(c => c.id)}
                    onSelectionChanged={setCheckedElmnts}
                /> : <Loader />}
            </Grid>
            {(SelectedProps) &&
                <Grid xs={6} item className='main-padding' style={{ position: "relative", height: calcHeight, overflow: "hidden", border: '1px solid #DDDDDD' }}>
                    <div className='adw-row'>
                        <SubTitle text={`${Trad(SelectedProps.Mode)} ${TradClassName(SelectedProps.ClassName)}`} />
                    </div>
                    {SelectedProps.Mode === eDialogMode.modify && <div className='main-padding' style={{ position: "absolute", right: 0, top: 0 }}>
                        <BagesUsage vertexName={SelectedProps.ClassName} value={SelectedProps.Item["@rid"]} />
                    </div>}
                    <div className='adw-form-grid-container'>
                        <ReferentialForm key={"ReferentialForm" + SelectedProps.Mode + SelectedProps.Item["@rid"] + SelectedProps.ClassName + SelectedProps.Parent}
                            onSave={() => {
                                setSelectedProps(undefined);
                                initializeData()
                            }}
                            mode={SelectedProps.Mode}
                            onDelete={() => setDeleteElements([SelectedProps.Item])}
                            selectedItem={SelectedProps.Item}
                            onCancel={() => setSelectedProps(undefined)}
                            parentClass={GetParentClass(SelectedProps.ClassName, props.hierarchyClassName)}
                            hierarchyClassName={props.hierarchyClassName}
                            selectedParent={SelectedProps.Parent}
                            selectedVertex={SelectedProps.ClassName} />
                    </div>
                </Grid>
            }
        </Grid>
    </div>);
}