import * as React from "react";
import Loader from "../../../layout/Loader";
import { Trad } from "trad-lib";
import { compareObjects, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CustomPivotGrid } from "./CustomPivotGrid";
import { initTCD } from "../../../../redux/tcdSlice";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";

export function MessagesPivotGrid() {

  const dispatch = useDispatch();
  const filters = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
  const serviceArgs = useSelector((root: RootState) => root.tcd.serviceArgs);
  const loading = useSelector((root: RootState) => root.tcd.loading);

  React.useEffect(() => {
    if (filters?.Start && filters?.End) {
      console.log(`[MessagesPivotGrid] initTCD`);
      Promise.resolve()
        .then(async () => {
          const getIndicateur = (field: string) => indicateurs.find(i => i.indicateur.field == field)?.indicateur;
          const indicateurs = await IndicateursProvider.GetInstance().Provide();
          dispatch(initTCD({
            filters: { ...filters, Source: "ADWONE" },
            rows: [getIndicateur("Advertiser")],
            columns: [getIndicateur("Media")]
          }))
        })
    }
  }, [GetHashCode(filters)])


  console.log(`[TRACK_TCD] MessagesPivotGrid loading: ${loading}`)

  return (
    <div className="grid_container">
      <div className="grid_body">
        {loading &&
          <Loader time />}

        {serviceArgs && !loading &&
          <CustomPivotGrid filters={filters} serviceArgs={serviceArgs} />}
      </div>
    </div>
  )
}




