import moment from "moment";
import { Trad } from "trad-lib";
import { getIcon } from "../ConfigurableComponents/IconManager.bin";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from "../../redux/store";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { useEffect, useState } from "react";
import { BudgetCompanyChart, MixMediasChart, MonthlyBudgetChart, TopSupportsChart } from "./Charts";
import { clearChartConfig, setTable } from "../../redux/projectSlice";
import { IconButton } from "@material-ui/core";
import { BasicAggregateManager } from "hub-lib/business/BasicAggregateManager.bin";
import { Row } from "adwone-engine/types.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import Loader from "../layout/Loader";
import { defaultTableConfig } from "../../utils/localstorage.bin";
import { clone } from "hub-lib/tools.bin";

export const Dashboard = () => {
    const columnsModele = useSelector((root: RootState) => root.project.columnsModele);
    const dispatch = useDispatch();

    if (!columnsModele?.DashboardConfig?.currency || !columnsModele?.DashboardConfig?.kpis) {
        const newConfig = clone(columnsModele);
        newConfig.DashboardConfig.currency = defaultTableConfig.DashboardConfig.currency;
        newConfig.DashboardConfig.kpis = defaultTableConfig.DashboardConfig.kpis;
        dispatch(setTable(newConfig))
        return <Loader />;
    }

    return (
        <div className="dashboard-container">
            <Header />
            <MixMediasChart />
            <TopSupportsChart />
            <MonthlyBudgetChart />
            <BudgetCompanyChart />
        </div>
    );
};

const Header = () => {
    const [budget, setBudget] = useState("0");
    const [count, setCount] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    const columnsModele = useSelector((root: RootState) => root.project.columnsModele);
    const filtersModele = useSelector((root: RootState) => root.project.filtersModele);
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const filters = { ...tabFilters, ...columnsModele?.DashboardConfig?.filters };

    const getData = async () => {
        const mgr = new BasicAggregateManager();
        mgr.customKPI = (a: Row<ref_Messages>) => a?.CountData;
        mgr.dimension = [];
        mgr.KPI = columnsModele?.DashboardConfig?.kpis?.["@rid"];
        mgr.KPIType = eKPIType.Price
        mgr.filters = { ...filters };
        const res = await mgr.getAggregate();
        let { table } = res.data;
        let totalRow = table.Rows[0];

        setCount(totalRow.CountData);

        let budget = totalRow.ValuesTotal[0].Value;
        const isHighBudget = budget > 1000;
        if (isHighBudget)
            budget = budget / 1000;
        let budgetString = budget.toFixed(2);
        if (isHighBudget)
            budgetString = `${budgetString} k`;
        setBudget(budgetString);
    };

    useEffect(() => {
        if(!loaded){
            setLoaded(true);
            getData();
        }
    });

    useEffect(() => {
        getData();
    }, [filters]);

    return <div className="dashboard-header dashboard-block">
        <HeaderBlock
            icon="filterAlt"
            title={Trad("name")}
            subtitle={filtersModele?.Name}
        />
        <HeaderBlock
            icon="dateRange"
            title={Trad("period")}
            subtitle={<DateElem Start={tabFilters?.Start} End={tabFilters?.End} />}
        />
        <HeaderBlock
            icon="messages"
            title={Trad("messages_number")}
            subtitle={`${count}`}
        />
        <HeaderBlock
            icon="wallet"
            title={`${Trad("total_budget")}${columnsModele?.DashboardConfig?.currency?.Code}`}
            subtitle={`${budget}${columnsModele?.DashboardConfig?.currency?.Code}`}
        />
        <HeaderBlock
            icon="reload"
            title=""
            subtitle=""
            onClick={() => dispatch(clearChartConfig())}
            disabled={!columnsModele?.DashboardConfig?.filters || (columnsModele?.DashboardConfig?.filters && Object.keys(columnsModele?.DashboardConfig?.filters).length === 0)}
        />
    </div>
};

const HeaderBlock = ({ icon, title, subtitle, onClick, disabled }: {
    icon: string;
    title: string;
    subtitle: string | JSX.Element;
    onClick?: () => void;
    disabled?: boolean;
}) => (
    <div className="dashboard-header-block" onClick={onClick}>
        <div className={`flex reverse center ${(title || subtitle) && "padding-right"}`}>
            {onClick && <IconButton onClick={onClick} disabled={disabled}>
                {getIcon(icon, {}, `adw-icon ${disabled ? "disabled": ""} lg`)}
            </IconButton>}
            {!onClick && getIcon(icon, {}, "adw-icon lg")}
        </div>
        {(title || subtitle) && <div className="flex center padding-left">
            <div className="flex column">
                <div className="dashboard-header-block-title">{title}</div>
                <div className="dashboard-header-block-value">{subtitle}</div>
            </div>
        </div>}
    </div>
);

const DateElem = (props: { Start: Date, End: Date }): any => {
    return <>
        <span className="prefix-text">{Trad("from")} </span>
        {`${moment(props?.Start).format("DD/MM/YYYY")}`}
        <span className="prefix-text"> {Trad("to")?.toLowerCase?.()} </span>
        {`${moment(props?.End).format("DD/MM/YYYY")}`}
    </>
}
