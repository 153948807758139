

import * as React from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../redux/store";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { CustomMasseActions, PropertySet } from "./CustomMasseActions.bin";
import { eStatusType, ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { Trad } from "trad-lib";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../../../utils/Notify.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { selectItems } from "../../../redux/gridSlice";
import { useDispatch } from 'react-redux'
import { SetSubElement, duplicate } from "hub-lib/tools.bin";
import { BreadcrumbsCustomContainer, SelectedItemsContainer, ToolbarContainer } from "../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { FilterStorage } from "../../../utils/localstorage.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { FeeRates, UpdateSet } from "hub-lib/models/types/vertex.bin";

type TProps = {
    grid: VertexGrid<ref_Messages>,
    onChange?: (rids: string[]) => void;
    onRemove?: (messages: ref_Messages[]) => void;
}

export function ToolbarSelectedItems({ grid, onChange, onRemove }: TProps) {
    const [campaignName, setCampaignName] = React.useState('')
    const selectedItems = duplicate(useSelector((state: RootState) => state.gridMessages?.selectedItems) ?? []);
    const dispatch = useDispatch();

    const filters = FilterStorage.getAdvancedFilters();
    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (!filters) return;
            if (filters.Campaign && filters.Campaign.length === 1) {
                const cmp = (await Client.searchVertex(ref_Campaigns.name, { "@rid": filters.Campaign, properties: ["@rid", "Name"], Source: "ADWONE" }))?.data?.results?.[0];
                setCampaignName(cmp.Name)
            } else {
                setCampaignName('')
            }

        });
    }, [filters?.Campaign])

    const onChangeStatus = async (status: eStatusType) => {
        const elements = selectedItems.filter(e => e.dataItem);
        elements.forEach(e => e.dataItem.Status = status);
        selectedItems.forEach(e => e.Status = Trad(status))
        dispatch(selectItems(selectedItems));
        const messages = elements.map(e => ({ "@rid": e.dataItem["@rid"], Status: status }));
        try {
            await Client.updateVertex(ref_Messages.name, messages, false);
            Notify(Trad("status_modification_success"), "success");
        } catch (error) {
            Notify(Trad("status_modification_failed"), "error");
        }

        onChange?.(messages.map(m => m["@rid"]));
    }

    const onMassAction = async (rids: string[], update: UpdateSet, key: string) => {
        try {
            const params = rids.map(e => ({ "@rid": e, UpdateSet: update }));
            await Client.updateVertex(ref_Messages.name, params, false);
            Notify(Trad(`${key}_modification_success`), "success");
            onChange?.(rids);
        } catch (error) {
            Notify(Trad(`${key}_modification_failed`), "error");
        }
    }

    const onchangeFeeRate = async (rates: FeeRates) => {
        await onMassAction(selectedItems.map(e => e.dataItem["@rid"]), { FeeRates: rates }, "feerate");
    }

    const onchangeProperty = async (property: PropertySet) => {

        const update: UpdateSet = { Properties: {} };
        update.Properties[property.name] = property.value;
        const rids = property.choices.find(c => c.key == property.value).rids;

        await onMassAction(rids, update, "property");
    }

    const elements = [{ text: Trad('home'), href: '/' }, { text: Trad('messages') }]
    if (campaignName) {
        elements.push({ text: campaignName })
    }
    return <ToolbarContainer>
        <SelectedItemsContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
                {selectedItems?.length > 0 &&
                    <CustomMasseActions
                        selectedItems={selectedItems.filter(s => s.dataItem?.["@rid"] || !s.Children?.length)}
                        grid={grid}
                        onRemove={() => onRemove?.([])}
                        onChangeStatus={onChangeStatus}
                        onchangeFeeRate={onchangeFeeRate}
                        onchangeProperty={onchangeProperty}
                        onChange={() => store.dispatch(selectItems([]))} />}
            </div>
        </SelectedItemsContainer>
        <BreadcrumbsCustomContainer>
            <BreadcrumbsCustom
                hasSelectedItems={selectedItems?.length > 0}
                elements={elements}
            />
        </BreadcrumbsCustomContainer>
    </ToolbarContainer>

}

