import { ref_Modules, ref_ModulesId } from "./ref_Modules.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ContractsId = rid;

export class ref_Contracts extends V {
	"@rid"?: ref_ContractsId;
	AgreementAdmin: string;
	CampaignLimit: number;
	ExportTemplates: boolean;
	FormatedExports: number;
	Modules: ref_ModulesId[];
	Name: string;
	Reports: boolean;
	UserAdmin: boolean;
	UserLimit: number;
	UserWalletAdmin: boolean;

}