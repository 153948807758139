import { NumericTextBox, NumericTextBoxProps } from "@progress/kendo-react-inputs";
import { GetNumericFormat, GetNumericStep } from "format-lib/index.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import * as React from "react";
import { StyleFactory } from "../../../utils/ToolsReact";
import { AnchorRow } from "../../Tools";
import { ContainerComponent } from "./ContainerComponent";

export const SubTitle = (props: { text?: string, style?: any, className?: string, children?: any, anchor?: string }) => {
    return <AnchorRow anchor={props?.anchor}>
        <span className={"adw-title " + (props.className ?? "")}>
            {props.text ?? props.children}
        </span>
    </AnchorRow>
}

export const Element = (props: { children: any, className?: string }) => {
    return <div style={{ width: "100%" }} className={props.className ?? ""}>
        {props.children}
    </div>
}

export const ElementContainer = (props: { children: any }) => {
    return <div className="panel-conf-container">
        {props.children}
    </div>
}

const styles = StyleFactory.create({
    PanelDisable: {}
})


type PanelDisableProps = { disabled?: boolean, children?: any, text?: string }
export function PanelDisable({ disabled, children, text }: PanelDisableProps) {
    if (disabled)
        return <div className='panel_disable' style={styles.PanelDisable}>
            <div
                className='panel_disable_locker'
                onClick={e => {
                    e.stopPropagation();
                }}></div>
            <div className='text_description'>{text}</div>
            {children}
        </div>
    return <>{children}</>
}

export function CustomNumericTextBox(props: Partial<NumericTextBoxProps> & { title: string }) {
    return <ContainerComponent
        title={props.title}
        className="configuration-multiselect">
        <NumericTextBox
            spinners={true}
            step={1}
            format={GetNumericFormat(eKPIType.Price)}
            {...props} />
    </ContainerComponent>
}

export function CustomPercentTextBox(props: Partial<NumericTextBoxProps> & { title: string }) {
    return <ContainerComponent
        title={props.title}
        className="configuration-multiselect">
        <NumericTextBox
            spinners={true}
            step={GetNumericStep(eKPIType.Rate)}
            min={0}
            max={100}
            format={GetNumericFormat(eKPIType.Rate)}
            {...props} />
    </ContainerComponent>
}

export function IsTargetInside(container: any, target: any) {
    let found = false;
    const recurse = (e) => {
        if (!e) return;
        if (e.element?.contains?.(target) || e?.contains?.(target)) {
            found = true;
            return;
        }
        Array.from(e.element?.children ?? e.children)?.forEach?.(c => recurse(c));
    }
    recurse(container)

    return found;
}

export function CheckTargetElement(container: any, check: (target: any) => boolean) {
    let found = false;
    const recurse = (e) => {
        if (!e) return;
        if (check(e.element) || check(e)) {
            found = true;
            return;
        }
        const parent = e.element?.parentElement ?? e.parentElement;
        //console.log(`[CheckTargetElement]`, e, e.element)
        if (parent)
            recurse(parent);
    }
    recurse(container)

    return found;
}

type OutsideAlerter = { callback?: (target: any, event: MouseEvent) => void, wrapperRef: React.MutableRefObject<any> }
export function OutsideAlerter({ callback, wrapperRef }: OutsideAlerter) {
    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (!IsTargetInside(wrapperRef.current, event.target))
                callback?.(event.target, event);
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return <></>;
}