import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import React from "react";
import { CustomIconButton } from "../../../../Generic/CustomIconButton";
import { TooltipManager, getIcon } from "adwone-lib";
import { RootState, store } from "../../../../../../redux/store";
import { setMessage } from "../../../../../../redux/messageEditorSlice";
import { Trad } from "trad-lib";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";

type CommandCellComponentProps = { discount: ref_Discount };
export function CommandCellComponent({ discount }: CommandCellComponentProps) {

    // const isEditable = (dtype: ref_DiscountTypes["@rid"]) => {
    //     if (this.currentType() === "CO")
    //         return true;
    //     return DiscountCanBeEdited(dtype)
    // }

    // const messageGlobalAgreement = useSelector((state: RootState) => state.messageEditor.message?.GlobalAgreement);
    // const messageAgreement = useSelector((state: RootState) => state.messageEditor.message?.Agreement);

    // const agreements = store.getState().messageEditor.agreements;
    // const globalAgreement = store.getState().messageEditor.globalAgreement;
    // const canChangeValue = [messageGlobalAgreement, messageAgreement]
    //     .filter(e => e)
    //     .map(e => [...(agreements ?? []), globalAgreement]?.find(a => a?.["@rid"] == e))
    //     .filter(e => e)
    //     .some(a => a.Discounts?.some(d => d.DiscountClass == discount.DiscountClass)) ?? true;

    return <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 3 }}>
        <CellLockValueComponent
            discount={discount}
            onLockChanged={(locked) => {
                const message = store.getState().messageEditor.getMessage();
                const d = message.Discounts.find(d => d.DiscountClass === discount.DiscountClass);
                d.Locked = locked;
                // if (locked) {
                //     const d = message.Discounts.find(d => d.DiscountClass === discount.DiscountClass);
                //     //d.Agreement = null;
                //     d.Locked = true;
                // } else
                //     message.Discounts = message.Discounts.filter(d => d.DiscountClass !== discount.DiscountClass);

                store.dispatch(setMessage(message));
            }}
            canChangeValue={true}
        />
        <div style={{ width: 3 }} />
        <CellRemoveComponent
            discount={discount}
            isRemovable={!Boolean(discount.Agreement)}
            onRemoveClick={() => {
                const message = store.getState().messageEditor.getMessage();
                message.Discounts = message.Discounts.filter(d => d.DiscountClass !== discount.DiscountClass);
                store.dispatch(setMessage(message));
            }} />
    </div>
}

type CellRemoveComponentType = { discount: ref_Discount, isRemovable: boolean, onRemoveClick: (row: ref_Discount) => void };
export function CellRemoveComponent({ discount, isRemovable, onRemoveClick }: CellRemoveComponentType) {
    return <div style={{ textAlign: 'center', position: 'relative' }}>
        {isRemovable && <CustomIconButton className="no-padding" style={{ padding: 0 }} onClick={() => onRemoveClick?.(discount)}>
            {getIcon("clear")}
        </CustomIconButton>}
    </div>
}

type CellLockValueComponentType = { discount: ref_Discount, onLockChanged: (value: boolean) => void, canChangeValue: boolean };
export function CellLockValueComponent({ discount, onLockChanged, canChangeValue }: CellLockValueComponentType) {
    //const isLocked = !Boolean(discount.Agreement);
    const isLocked = Boolean(discount.Locked);
    return <div
        className="celllockvaluecomponent"
        style={{ textAlign: 'center', position: 'relative' }}
        onMouseOver={e => {
            if (!canChangeValue)
                return TooltipManager.Push({ target: e.target, text: Trad("cannot_unlock_user_discount") });
            if (isLocked)
                return TooltipManager.Push({ target: e.target, text: Trad("discount_locked") });
            if (!isLocked)
                return TooltipManager.Push({ target: e.target, text: Trad("discount_unlocked") });
        }}>
        <Checkbox
            value={isLocked}
            //disabled={isLocked}
            onChange={(event) => {
                // if (isLocked)
                //     return;
                onLockChanged(event.value);
            }} />

    </div>
}