import { configureStore } from '@reduxjs/toolkit'
// import adminReducer from '../reducers/adminReducer'
import authReducer from '../reducers/authReducer'
import importsReducer from '../reducers/importsReducer'
import searchReducer from '../reducers/searchReducer'
import supportReducer from '../reducers/supportReducer'
import { gridReducer } from './gridSlice'
import { linkMgrReducer } from './linkMgrSlice'
import { storageReducer } from './storageReducer'
import { tooltipReducer } from './tooltipSlice'
import { referentialTreeReducer } from './referentialTreeSlice'
import { messageEditorReducer } from './messageEditorSlice'
import { treeReducer } from './treeSlice'
import { backgroundReducer } from './backgroundSlice'
import { projectReducer } from './projectSlice'
import { connectedStatusReducer } from './connectedStatusSlice'
import { agreementEditorReducer } from './agreementEditorSlice'
import { percentageSimilarityReducer } from './percentageSimilaritySlice'
import { tcdReducer } from './tcdSlice'
import { supplierReducer } from './supplierSlice'

export const store = configureStore({
  reducer: {
    tcd: tcdReducer,
    tooltip: tooltipReducer,
    auth: authReducer,
    gridMessages: gridReducer,
    verticesStorage: storageReducer,
    linkMgr: linkMgrReducer,
    tree: treeReducer,
    globalSearch: searchReducer,
    support: supportReducer,
    // admin: adminReducer,
    imports: importsReducer,
    referentialTree : referentialTreeReducer,
    messageEditor: messageEditorReducer,
    agreementEditor: agreementEditorReducer,
    background: backgroundReducer,
    project: projectReducer,
    connectedStatus: connectedStatusReducer,
    similarityPercentage: percentageSimilarityReducer,
    supplier: supplierReducer,

  },
  devTools: process.env.NODE_ENV !== 'production' && {
    name: "App",
    trace: true
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredPaths: ['linkMgr.filtersView.minDateViewUpdate', 'payload.0', 'messageEditor.getMessage', 'messageEditor.getOffers'],
      },
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

