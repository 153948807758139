import * as React from "react";
import { VertexAutocomplete } from "adwone-lib/index";
import { Trad } from "trad-lib";
import { eStatusType } from "hub-lib/models/ref_Messages.bin";

export class Store {
    /* Hierarchie état */
    Status: string[];
}

export class TProps {
    store: Partial<Store>;
    configModel?: any;
    onChanged?: (store: Partial<Store>) => void;
}

let dico: any = {
}

export class HierarchyState extends React.Component<TProps, any> {

    constructor(props: TProps) {
        super(props);
        this.state = {};

        dico = {
            [Trad(eStatusType.Cancelled)]: eStatusType.Cancelled,
            [Trad(eStatusType.Confirmed)]: eStatusType.Confirmed,
            [Trad(eStatusType.None)]: eStatusType.None,
            [Trad(eStatusType.Opted)]: eStatusType.Opted,
            [Trad(eStatusType.Simulated)]: eStatusType.Simulated
        }
    }


    Autocomplete = (propStore: keyof Store, params: any = {}) => {
        const { store, onChanged } = this.props;

        return (
            <div className="adw-row">
                <VertexAutocomplete
                    /*type={type}*/
                    multiple={true}
                    disabled={this.props?.configModel?.Status?.length > 0}
                    defaultValue={(sups: any[]) => {
                        return sups?.filter((v) => (store as any)[propStore]?.includes(dico[v]))
                    }}
                    options={Object.keys(dico).sort()}
                    onChange={(value: string[]) => {
                        (store as any)[propStore] = value.map(v => dico[v]);
                        let key = `version${propStore}`;
                        this.setState({ [key]: (this.state as any)[key] + 1 } as any, () => onChanged?.(store));
                    }}
                    label={Trad(propStore as any)}
                    params={{
                        properties: ["@rid", "Name"],
                        ...params
                    }} />
            </div>)
    }

    render() {
        return (
            <>
                {this.Autocomplete("Status")}
            </>
        );
    }
}