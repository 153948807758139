import * as React from 'react'
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { setMessageSync } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';

type DatePropertyEditorArgs = { property: keyof ref_Messages & string, label: string }
export function DatePropertyEditor({ property, label }: DatePropertyEditorArgs) {

    const propertyValue = useSelector((root: RootState) => root.messageEditor.getMessage()[property], JSONEqualityComparer);

    return <SimpleDatePicker
        popupAlign={{
            horizontal: "left",
            vertical: "bottom"
        }}
        label={label}
        defaultValue={propertyValue ? new Date(propertyValue) : null}
        resetDate={() => {
            const message = store.getState().messageEditor.getMessage();
            (message as any)[property] = null;
            store.dispatch(setMessageSync(message));
        }}
        onChange={(event) => {
            const message = store.getState().messageEditor.getMessage();
            (message as any)[property] = new Date(event.value);
            store.dispatch(setMessageSync(message));
        }}
    />
}