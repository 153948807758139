import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { AModeleCreatorBase, ModeleCreatorBaseProps } from "./ModeleCreatorBase";
import { Grid } from "@material-ui/core";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_KPIs } from "hub-lib/models/orientdb/ref_KPIs.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { Trad, TradProp } from "trad-lib";
import { VertexAutocomplete } from "adwone-lib";
import { Element } from "../Generic/Common.bin";

export class TProps extends ModeleCreatorBaseProps<ref_TableConfigurations> {

};

export class ModeleDashboard extends AModeleCreatorBase<TProps, ref_TableConfigurations> {

    constructor(props: TProps) {
        super(props);
    }

    afterRender() {
        const { modele, onChange } = this.props;
        return <Element>
            <Grid container xs={12} className="block-container">
                <Grid xs={6} item className="message_details_leftcombo">
                    <VertexAutocomplete
                        label={Trad("budget")}
                        type={ref_KPIs.name}
                        disableClearable
                        defaultValue={(options: ref_KPIs[]) =>
                            options.find(
                                (e) =>
                                    e["@rid"] ===
                                    modele?.DashboardConfig?.kpis?.["@rid"]
                            )
                        }
                        onChange={(value: ref_KPIs) => {
                            modele.DashboardConfig.kpis = value;
                            onChange(modele);
                        }}
                    />
                </Grid>
                <Grid xs={6} item className="message_details_leftcombo">
                    <VertexAutocomplete
                        type={ref_Currencies.name}
                        onResetValue={(options: any) => undefined as any}
                        params={{
                            properties: ["@rid", "Name", "Code"],
                        }}
                        defaultValue={(options: ref_Currencies[]) =>
                            options.find((e) => e["@rid"] === modele?.DashboardConfig?.currency?.["@rid"])
                        }
                        getOptionLabel={(option: ref_Currencies) => `${option.Name} (${option.Code})`}
                        label={TradProp("Currency", ref_Messages)}
                        onChange={(value: ref_Currencies) => {
                            modele.DashboardConfig.currency = value;
                            onChange(modele);
                        }}
                    />
                </Grid>
            </Grid>
        </Element>
    }
}