import { ADWColumn } from "adwone-lib";
import { ref_Publications } from "hub-lib/models/ref_Publications.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";

export function PublicationsGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_Publications>[] = [
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime)
            ];
            const hiddenProperties: (keyof ref_Publications)[] = ["DataImported"];
            const grid = new VertexGrid<ref_Publications>({
                disableStore: true,
                objectPrototype: ref_Publications,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"]
                },
                width: {
                    Reference: 100,
                    Start: 110,
                    End: 110
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_Publications}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const PublicationsGrid = Template(PublicationsGridComponent);