import { Client } from "../client/client.bin";
import { AggregateExport } from '../models/external.bin'
import { ref_KPIsId } from "../models/orientdb/ref_KPIs.bin";
import { eKPIType, MessageModelManager } from "../models/KPIsManager.bin";
import { ref_Messages } from "../models/ref_Messages.bin";
import { Row } from "adwone-engine/types.bin";
import { eIndicateurType, IndicateurKPI } from "adwone-engine/index.bin";
import { ADWProperty } from "../types";
import { toArray } from "../tools.bin";
import { DateNoZone, DateZone, getMonthName } from "tools-lib";
import moment from "moment";

export type DataValue = {
    Label: string;
    Value: any;
    KPI: any;
    color?: any;
    Origin?: any;
}

export class BasicAggregateManager {

    dimension: ADWProperty | ADWProperty[];
    KPI: ref_KPIsId;
    KPIType: eKPIType;
    customKPI?: (a: Row<ref_Messages>) => any;
    filters: { [prop: string]: any }
    start: Date;
    end: Date;

    async getAggregate() {

        const lnks = await MessageModelManager.GetRefLnkKPIs()
        const lnk = lnks.find(l => l.KPI === this.KPI);

        const indicateur: IndicateurKPI = {
            field: lnk?.Id,
            valueType: lnk?.ValueType,
            name: "",
            type: eIndicateurType.kpi,
            options: {
                rid: this.KPI
            }
        }

        const arg: AggregateExport = {
            hideDetailsRows: true,
            type: "aggregate",
            document: ref_Messages.name,
            dimensions: Array.isArray(this.dimension) ? this.dimension : [this.dimension],
            columns: [indicateur],
            filter: this.filters,
        }

        if (this.start || this.end) {
            arg.Start = this.start;
            arg.End = this.end;
        }

        return Client.aggregate<ref_Messages>(arg);
    }

    async get(): Promise<(DataValue[])[]> {

        const res = await this.getAggregate();
        let { table } = res.data;

        let totalRow = table.Rows[0];
        const isDate = toArray(this.dimension).find(d => d.field === "Start" || d.field === "End");

        const rows: { [key: string]: DataValue[] } = {};
        for (const month of totalRow?.Children) {
            if (month?.Children) {
                for (const year of month?.Children) {
                    let row = {
                        Label: isDate ? getMonthName(month?.Formated, true) : month?.Formated ?? (Array.isArray(month?.Value) ? month.Value.join(",") : month.Value),
                        Value: year?.Value,
                        KPI: this.customKPI?.(year) ?? year.ValuesTotal[0].Value,
                        Origin: isDate ? moment(new Date(parseInt(year.Formated), parseInt(month.Formated) - 1)).utcOffset(0, true).format() : month.Formated + " " + year.Formated
                    };
                    rows[year?.Formated] = [ ...(rows[year?.Formated] || []), row ];
                }
            } else {
                let row = {
                    Label: month?.Formated ?? (Array.isArray(month?.Value) ? month.Value.join(",") : month.Value),
                    Value: month?.Value,
                    KPI: this.customKPI?.(month) ?? month.ValuesTotal[0].Value,
                    Origin: month?.Formated
                };
                rows[totalRow.Formated] = [ ...(rows[totalRow?.Formated] || []), row ];
            }
        }
        const results = Object.values(rows);
        if (!isDate) {
            results.forEach(r => r.sort((a, b) => b.KPI - a.KPI));
        }
        return results;
    }
}