import * as React from "react";
import { ref_Couplages } from "hub-lib/models/ref_Couplages.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { Trad, TradProp } from "trad-lib";
import { ADWColumn, AdwRow } from "adwone-lib";
import DefaultGrid from "../DefaultGrid.bin";
import { removeDiacritics } from "hub-lib/tools.bin";
import { ItemNameButtonCell } from "../Cells/ItemNameButtonCell";
import history from '../../../utils/history';
import { store } from "../../../redux/store";
import { setSpotFilters } from "../../../redux/supplierSlice";

export function CouplagesGrid() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {
            let nameColumn = new ADWColumn<ref_Couplages>(Trad('Name'), 'Name', ePropType.String, true);
            nameColumn.cellValue = (CellValue: any, dataItem: AdwRow<ref_Couplages>) => {
                dataItem['Name_cellValue'] = CellValue ? removeDiacritics(dataItem.dataItem.Name) : '';

                return <ItemNameButtonCell dataItem={dataItem} onClick={() => DisplaySpots(dataItem.dataItem)} />;
            };
            nameColumn.width = 300;

            const columns: ADWColumn<ref_Couplages>[] = [
                nameColumn,
                new ADWColumn(TradProp("Start"), "Start", ePropType.Date),
                new ADWColumn(TradProp("End"), "End", ePropType.Date),
                new ADWColumn(TradProp("Advertiser"), "AdvertiserName", ePropType.String),
                new ADWColumn(TradProp("Support"), "SupportName", ePropType.String),
                new ADWColumn(TradProp("Media"), "MediaName", ePropType.String),
                new ADWColumn(TradProp("Agency"), "AgencyName", ePropType.String),
                new ADWColumn(TradProp("Nature"), "NatureName", ePropType.String),
                new ADWColumn("Nombre de Spots", "SpotsNumber", ePropType.Integer),
                new ADWColumn("Gross", "Gross", ePropType.Decimal),
                new ADWColumn("Net", "Net", ePropType.Decimal)
            ];
            //const properties: string[] = [];
            const grid = new VertexGrid<ref_Couplages>({
                disableStore: true,
                objectPrototype: ref_Couplages,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"]
                }
            });
            setGrid(grid);
        }
    });

    return <DefaultGrid
        objectPrototype={ref_Couplages}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export function DisplaySpots(couplage: ref_Couplages) {
    const campaignIds = couplage.Campaigns.map(c => c["@rid"]);
    store.dispatch(setSpotFilters((
        { Campaign: campaignIds, Start: couplage.Start, End: couplage.End }
    )));
    history.push('/supplier_spots');
}


