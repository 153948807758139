
import * as React from 'react'
import { vw_mm_HasSupport } from "hub-lib/models/orientdb/vw_mm_HasSupport.bin";
import { vw_mm_HasBroadcastArea } from "hub-lib/models/orientdb/vw_mm_HasBroadcastArea.bin";
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { SupportExtended } from 'hub-lib/models/SupportExtended.bin';
import { extractSub, JSONEqualityComparer } from 'hub-lib/tools.bin';
import { useSelector } from 'react-redux';
import { TradProp } from 'trad-lib';
import { setMessage } from '../../../../redux/messageEditorSlice';
import { RootState, store } from '../../../../redux/store';
import { FavoriteVertexAutoComplete } from '../../../AdwAutocomplete/FavoriteVertexAutoComplete';
import { WarningMM } from './WarningMM';
import { CheckElement } from './CheckElement';

type SupportEditorArgs = { onChange?: () => void }
export function SupportEditor({ onChange }: SupportEditorArgs) {

    const { Media, Support, BroadcastArea } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Media', 'Support', 'BroadcastArea']) ?? {}, JSONEqualityComparer);

    return <WarningMM
        cross
        ids={[
            { id: Support, linkClass: vw_mm_HasSupport.name },
            { id: BroadcastArea, linkClass: vw_mm_HasBroadcastArea.name }
        ]}
        hasErrors={(errors) => Boolean(errors?.length)}>
        <FavoriteVertexAutoComplete
            disableClearable
            type={SupportExtended.name}
            refreshOnPropChanged
            label={TradProp("Support", ref_Messages) + " *"}
            params={{
                Medias: [Media],
                properties: ["*"],
                options: {
                    coverUrl: true
                }
            }}
            defaultValue={(options: IRid[]) => CheckElement(options?.find(o => o["@rid"] == Support), Support, ref_Supports.name)}
            onChange={async (irid: IRid) => {
                const support = irid as ref_Supports;
                const message = store.getState().messageEditor.getMessage();

                if (support?.Medias?.length == 1)
                    message.Media = support.Medias[0];

                message.Support = support["@rid"];
                message.AdvCompany_Com = undefined;
                message.AdvCompany_Fin = undefined;
                message.BroadcastArea = undefined;
                message.Currency = undefined;
                // message.ModelProperties["MediaFamily"] = undefined;
                message.ModelProperties["Periodicity"] = (support as any).Periodicity;
                if (message.ModelProperties)
                    delete message.ModelProperties["OjdYear"];

                await store.dispatch(setMessage(message));
                onChange?.();
            }} />
    </WarningMM>
}