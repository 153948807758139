import { eIndicateurType, Indicateur, IndicateurComputed, IndicateurDiscount, IndicateurInfo, IndicateurJoin, IndicateurKPI, IndicateurToString } from "adwone-engine/index.bin";
import { Sort } from "format-lib/index.bin";
import { DiscountOptions, eDiscountOptionType, eDiscountOptionValue } from "hub-lib/models/external.bin";
import { eKPIType, MessageModelManager, ToEKPIType } from "hub-lib/models/KPIsManager.bin";
import { lnk_HasKPI } from "hub-lib/models/orientdb/lnk_HasKPI.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { ref_KPIs } from "hub-lib/models/orientdb/ref_KPIs.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";

import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ClassProperty, ePropType } from "hub-lib/models/VertexProperty.bin";
import { clone, distinct, duplicate, ePropertyOptionFilter, toArray } from "hub-lib/tools.bin";
import { FormatPropertyName, GetCurrentLocale, SetCurrentLocale, Trad, TradClassName, TradProp } from "trad-lib";
import { eColumnType } from "./models/types.bin";
import { DataProvider } from "./provider";
import { ref_AdvertisingCompanyGroups } from "./models/orientdb/ref_AdvertisingCompanyGroups.bin";

const Typed = <T>(d: T) => d;

const getForcedIndicateurs: () => { [field: string]: IndicateurInfo | IndicateurInfo[] } = () => ({
    'ModelProperties.MediaFamily': Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.MediaFamily' as any, ref_Messages),
        field: 'ModelProperties.MediaFamily',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.MediaFamilyOther'
        }
    }),
    'ModelProperties.AdCreation': Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.AdCreation' as any, ref_Messages),
        field: 'ModelProperties.AdCreation',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.AdCreationOther'
        }
    }),
    'ModelProperties.PlacementCategory': Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.PlacementCategory' as any, ref_Messages),
        field: 'ModelProperties.PlacementCategory',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.PlacementCategoryOther'
        }
    }),
    'ModelProperties.Periodicity': Typed<IndicateurInfo>({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.Periodicity' as any, ref_Messages),
        field: 'ModelProperties.Periodicity',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.PeriodicityComment'
        }
    }),
    'Deversement.Estimate': [
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.Estimate' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Code"
            }
        }),
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateStatus' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Status",
                formater: {
                    type: "trad"
                }
            }
        }),
        Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateId' as any, ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'Import.ExternalID',
                subPropertyFallback: "@rid",
            }
        }),
    ]
})

export type IndicateurOption = { indicateur: Indicateur, columnType: eColumnType }
type cumulIndicateurArg = {
    indicateurComputedOptions?: { isPriceReturned?: boolean },
    indicateurOption?: Partial<IndicateurOption>,
    indicateurDiscountOptions?: Partial<DiscountOptions>
}

/**
 * Singleton qui fournit tous les indicateurs possibles à mettre dans un tableau
 */
export class IndicateursProvider {

    private static _instance: IndicateursProvider;
    static GetInstance(): IndicateursProvider {
        if (!IndicateursProvider._instance)
            IndicateursProvider._instance = new IndicateursProvider();
        return IndicateursProvider._instance;
    }

    Provide: () => Promise<IndicateurOption[]> = async () => {

        const metadata = await this.getMetadata();
        const kpis = Sort(ref_KPIs.name, await this.getKPIs());
        const medias = Sort(ref_Media.name, await this.getMedias());
        const lnk_kpis = Sort(lnk_HasKPI.name, await this.getLnkKPIs());
        const discounts = Sort(ref_DiscountClasses.name, await this.getDiscountClasses());
        const dTypes = Sort(ref_DiscountTypes.name, await this.getDiscountTypes());
        const freeDiscountType = dTypes.find(t => t.Name == "Gracieux");

        const [dtBarter] = await this.getDiscountTypes((t) => t.Name == "Barter");
        const discountsBarter = Sort(ref_DiscountClasses.name, await this.getDiscountClasses(c => c.DiscountType == dtBarter["@rid"]));

        const forcedIndicateurs = getForcedIndicateurs();

        /** Propriétés du message */
        const props = metadata.filter(m => m.name !== 'Active' && (m.name !== 'ReturnedCurrency' || RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)))

        // Indicateur: Type de gracieux
        const indFreeType: IndicateurInfo = {
            type: eIndicateurType.info,
            name: Trad(`Type de gracieux`),
            valueType: eKPIType.Rid,
            field: "Discounts.DiscountClass",
            options: {
                match: [{
                    subProperty: "Discounts",
                    value: {
                        DiscountType: freeDiscountType["@rid"]
                    }
                }, {
                    subProperty: "Discounts",
                    filter: ePropertyOptionFilter.DiscountNotEmpty
                }]
            }
        }

        const propOptions: IndicateurOption[] = props.flatMap(p => {

            let indInfo: IndicateurInfo[] = undefined;
            if (forcedIndicateurs[p.name])
                indInfo = toArray(forcedIndicateurs[p.name]);
            else
                indInfo = [{
                    type: eIndicateurType.info,
                    name: TradProp(p.name as any, ref_Messages),
                    field: p.name,
                    valueType: ToEKPIType(p.type)
                }];
            return indInfo.map(i => ({
                indicateur: i,
                columnType: eColumnType.Property
            }));
        });

        /** KPIs en devise restituée */
        let restituedKPI: IndicateurOption[] = [];
        if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read))
            restituedKPI = kpis
                .filter((k: ref_KPIs) => lnk_kpis.filter(lnk => lnk.KPI === k["@rid"] && [eKPIType.Price, eKPIType.PriceReturned].includes(lnk.ValueType)).length)
                .map(k => {

                    /** on cherche le lnkKpi pour avoir la clé et le type */
                    const lnkKpi = lnk_kpis.find(lnk => lnk.KPI === k["@rid"])

                    const indKpi: IndicateurKPI = {
                        type: eIndicateurType.kpi,
                        name: Trad(k.Name) + ` ${Trad("returned")}`,
                        valueType: eKPIType.PriceReturned,
                        field: lnkKpi.Id,
                        options: {
                            rid: k["@rid"],
                            isPriceReturned: true
                        }
                    }
                    return {
                        indicateur: indKpi,
                        columnType: eColumnType.PriceReturned
                    };
                });

        /** KPIs déversés */
        let boundKPI: IndicateurOption[] = [];
        if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read))
            boundKPI = kpis
                .filter((k: ref_KPIs) => lnk_kpis.filter(lnk => lnk.KPI === k["@rid"] && [eKPIType.Price, eKPIType.PriceBound].includes(lnk.ValueType)).length)
                .map(k => {

                    /** on cherche le lnkKpi pour avoir la clé et le type */
                    const lnkKpi = lnk_kpis.find(lnk => lnk.KPI === k["@rid"])

                    const indKpi: IndicateurKPI = {
                        type: eIndicateurType.kpi,
                        name: Trad(k.Name) + ` ${Trad("bound")}`,
                        valueType: eKPIType.PriceBound,
                        field: lnkKpi.Id,
                        options: {
                            rid: k["@rid"],
                            isPriceBound: true
                        }
                    }
                    return {
                        indicateur: indKpi,
                        columnType: eColumnType.PriceBound
                    };
                });

        /** KPIs normaux */
        const propKpis: IndicateurOption[] =
            kpis.filter(k => k.Name != "Nombre total")
                .map((k: ref_KPIs) => {

                    /** on cherche le lnkKpi pour avoir la clé et le type */
                    const lnkKpi = lnk_kpis.find(lnk => lnk.KPI === k["@rid"])

                    if (!lnkKpi) {
                        console.log(`KPI not found`, k);
                        return null;
                    }

                    const indKpi: IndicateurKPI = {
                        type: eIndicateurType.kpi,
                        name: Trad(k.Name),
                        valueType: lnkKpi.ValueType,
                        field: lnkKpi.Id,
                        options: { rid: k["@rid"] }
                    }
                    return {
                        indicateur: indKpi,
                        columnType: eColumnType.KPI
                    };
                })
                .filter(k => k);

        const indNbTotal: IndicateurComputed = {
            type: eIndicateurType.computed,
            name: Trad("Nombre total"),
            valueType: eKPIType.Decimal,
            operator: '+',
            indicateurs: [
                propKpis.find(p => p.indicateur.name == Trad("Nombre gracieux")).indicateur,
                propKpis.find(p => p.indicateur.name == Trad("Nombre payant")).indicateur
            ]
        }

        propKpis.push({
            indicateur: indNbTotal,
            columnType: eColumnType.KPI
        })

        /** Remises */
        const propDiscounts: IndicateurOption[] = discounts
            .map((d: ref_DiscountClasses) => [{
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Percent,
                    name: `${Trad("taux")}  ${Trad(d.Name)}`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
                },
                columnType: eColumnType.Discount
            }, {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Percent,
                    name: `${Trad("taux")} ${Trad(d.Name)} FO`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FO }
                },
                columnType: eColumnType.DiscountFO
            }, {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Percent,
                    name: `${Trad("taux")} ${Trad(d.Name)} FOS`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FOS }
                },
                columnType: eColumnType.DiscountFOS
            }, {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Price,
                    name: `${Trad("montant")} ${Trad(d.Name)}`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.CO }
                },
                columnType: eColumnType.DiscountValue
            }, {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Price,
                    name: `${Trad("montant")} ${Trad(d.Name)} FO`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FO }
                },
                columnType: eColumnType.DiscountFOValue
            }, {
                indicateur: {
                    type: eIndicateurType.discount,
                    valueType: eKPIType.Price,
                    name: `${Trad("montant")} ${Trad(d.Name)} FOS`,
                    options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FOS }
                },
                columnType: eColumnType.DiscountFOSValue
            }])
            .reduce((a, b) => a.concat(b), [])
            .sort((a, b) => a.indicateur.type.localeCompare(b.indicateur.type));


        const propBarter: IndicateurOption[] = discountsBarter.map(d => ([{
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Price,
                name: FormatPropertyName(`${Trad("Barter")} ${Trad("amount")} ${Trad(d.Name)}`),
                options: { rid: d["@rid"], barter: true, value: eDiscountOptionValue.Value, type: eDiscountOptionType.CO }
            },
            columnType: eColumnType.Barter
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Percent,
                name: FormatPropertyName(`${Trad("Barter")} ${Trad("rate")} ${Trad(d.Name)}`),
                options: { rid: d["@rid"], barter: true, value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
            },
            columnType: eColumnType.Barter
        }]))
            .reduce((a, b) => a.concat(b), []);

        /** Remises déversées */
        const propDiscountBound: IndicateurOption[] =
            RightManager.hasRight(eFunctions.ref_Estimates, eRights.read) ?
                propDiscounts
                    .filter(p => {
                        const indicD = (p.indicateur as IndicateurDiscount);
                        // seulement les CO/FO en montant
                        return [eDiscountOptionType.CO, eDiscountOptionType.FO].includes(indicD.options.type)
                            && indicD.options.value === eDiscountOptionValue.Value;
                    })
                    .map((p: IndicateurOption): IndicateurOption => ({
                        indicateur: {
                            ...duplicate(p.indicateur),
                            name: `${Trad(p.indicateur.name)} ${Trad("bound")}`,
                            valueType: eKPIType.PriceBound,
                            options: { ...(p.indicateur as IndicateurDiscount).options, isPriceBound: true }
                        },
                        columnType: (p.columnType + "Bound") as eColumnType
                    }))
                    .sort((a, b) => a.indicateur.name.localeCompare(b.indicateur.name))
                : [];

        /** Remises restituées */
        const propDiscountReturned: IndicateurOption[] =
            !RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)
                ? []
                : propDiscounts
                    .filter(p => {
                        const indicD = (p.indicateur as IndicateurDiscount);
                        // seulement les montants
                        return indicD.options.value === eDiscountOptionValue.Value;
                    })
                    .map((p: IndicateurOption): IndicateurOption => ({
                        indicateur: {
                            ...duplicate(p.indicateur),
                            name: `${Trad(p.indicateur.name)} ${Trad("returned")}`,
                            valueType: eKPIType.PriceReturned,
                            options: { ...(p.indicateur as IndicateurDiscount).options, isPriceReturned: true }
                        },
                        columnType: p.columnType
                    }))
                    .sort((a, b) => a.indicateur.name.localeCompare(b.indicateur.name))

        propKpis.sort((a, b) => a.indicateur.name.localeCompare(b.indicateur.name));
        propOptions.sort((a, b) => a.indicateur.name.localeCompare(b.indicateur.name));

        /** Indicateurs calculés */
        const totalTaxes: IndicateurOption = await this.CreateCumulIndicateur("Taxes", Trad("total_taxes"), discounts);
        const totalHonoCO: IndicateurOption = await this.CreateCumulIndicateur("Honoraires", Trad("total_honoraires"), discounts);
        const totalHonoFO: IndicateurOption = await this.CreateCumulIndicateur("Honoraires", Trad("total_honoraires"), discounts, { indicateurDiscountOptions: { type: eDiscountOptionType.FO } });
        const totalFrais: IndicateurOption = await this.CreateCumulIndicateur("Frais", Trad("total_frais"), discounts);
        const totalFraisFO: IndicateurOption = await this.CreateCumulIndicateur("Frais", Trad("total_frais"), discounts, { indicateurDiscountOptions: { type: eDiscountOptionType.FO } });

        const computedIndicateurReturned: IndicateurOption[] = []
        if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)) {
            const optionsRet: cumulIndicateurArg = {
                indicateurComputedOptions: { isPriceReturned: true },
                indicateurDiscountOptions: { isPriceReturned: true }
            }
            const totalTaxesRet: IndicateurOption = await this.CreateCumulIndicateur("Taxes", `${Trad("total_taxes")} ${Trad("returned")}`, discounts, optionsRet);
            const totalHonoRet: IndicateurOption = await this.CreateCumulIndicateur("Honoraires", `${Trad("total_honoraires")} ${Trad("returned")}`, discounts, optionsRet);
            const totalFraisRet: IndicateurOption = await this.CreateCumulIndicateur("Frais", `${Trad("total_frais")} ${Trad("returned")}`, discounts, optionsRet);

            computedIndicateurReturned.push(totalTaxesRet);
            computedIndicateurReturned.push(totalHonoRet);
            computedIndicateurReturned.push(totalFraisRet);
        }

        // Total CO
        const totalCOKpi = kpis.find(k => k.Name === "Total");
        const totalCOLnkKpi = lnk_kpis.find(lnk => lnk.KPI === totalCOKpi["@rid"]);

        // Total FO
        const totalFOKpi = kpis.find(k => k.Name === "Total FO");
        const totalFOLnkKpi = lnk_kpis.find(lnk => lnk.KPI === totalFOKpi["@rid"]);

        const netKpi = kpis.find(k => k.Name === "Net");
        const netLnkKpi = lnk_kpis.find(lnk => lnk.KPI === netKpi["@rid"]);

        const netKpiFo = kpis.find(k => k.Name === "Net FO");
        const netLnkKpiFO = lnk_kpis.find(lnk => lnk.KPI === netKpiFo["@rid"]);

        //console.log(`propDiscounts`, propDiscounts)



        // Montant honoraires
        const honoRid = discounts.find(t => t.Name === "Honoraires")["@rid"];
        const honoIndicateurCO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == honoRid);
        const honoIndicateurFO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountFOValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == honoRid);

        // Montant honoraires sur Frais technique
        const honoFTRid = discounts.find(t => t.Name === "Honoraires sur frais techniques")["@rid"];
        const honoFTIndicateur = propDiscounts.filter(p => p.columnType == eColumnType.DiscountValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == honoFTRid);
        const honoFTIndicateurFO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountFOValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == honoFTRid);

        // Montant TVA
        const tvaRid = discounts.find(t => t.Name === "TVA")["@rid"];
        const tvaCOIndicateur = propDiscounts.filter(p => p.columnType == eColumnType.DiscountValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == tvaRid);
        const tvaFOIndicateur = propDiscounts.filter(p => p.columnType == eColumnType.DiscountFOValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == tvaRid);

        // Montant Frais de production
        const fpRid = discounts.find(t => t.Name === "Frais de production")["@rid"];
        const fpIndicateurCO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == fpRid);
        const fpIndicateurFO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountFOValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == fpRid);

        // Montant Frais de création
        const fcRid = discounts.find(t => t.Name === "Frais de création")["@rid"];
        const fcIndicateurCO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == fcRid);
        const fcIndicateurFO = propDiscounts.filter(p => p.columnType == eColumnType.DiscountFOValue)
            .map(p => <IndicateurDiscount>p.indicateur)
            .find(p => p.options?.rid == fcRid);

        //console.log(`tvaIndicateur`, tvaIndicateur)

        // Total CO Hors Honos, Hors Frais de Production, Hors Frais de Création
        const totalCOHonoFPFC = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("totalCOHonoFPFC"),
                valueType: eKPIType.Price,
                operator: "-",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "total",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: totalCOLnkKpi.Id,
                        options: { rid: totalCOLnkKpi["@rid"] }
                    }),
                    totalHonoCO.indicateur,
                    fpIndicateurCO,
                    fcIndicateurCO,
                    tvaCOIndicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Total FO Hors Honos, Hors Frais de Production, Hors Frais de Création
        const totalFOHonoFPFC = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("totalFOHonoFPFC"),
                valueType: eKPIType.Price,
                operator: "-",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "totalFo",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: totalFOLnkKpi.Id,
                        options: { rid: totalFOLnkKpi["@rid"] }
                    }),
                    totalHonoFO.indicateur,
                    fpIndicateurFO,
                    fcIndicateurFO,
                    tvaFOIndicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net CO + FT + hono
        const netCoFTHono = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netCoFTHono"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpi.Id,
                        options: { rid: netKpi["@rid"] }
                    }),
                    totalFrais.indicateur,
                    honoIndicateurCO,
                    honoFTIndicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net FO + FT hors hono
        const netFoFT = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netFoFT"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpiFO.Id,
                        options: { rid: netKpiFo["@rid"] }
                    }),
                    totalFraisFO.indicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net CO + FT hors hono
        const netCoFT = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netCoFT"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpi.Id,
                        options: { rid: netKpi["@rid"] }
                    }),
                    totalFrais.indicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net FO + hono
        const netFoHono = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netFoHono"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpiFO.Id,
                        options: { rid: netKpiFo["@rid"] }
                    }),
                    honoIndicateurFO
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net FO + FT Hono inclus
        const netFoFTHono = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netFoFTHono"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpiFO.Id,
                        options: { rid: netKpiFo["@rid"] }
                    }),
                    totalFraisFO.indicateur,
                    honoIndicateurFO,
                    honoFTIndicateurFO
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Net FO TTC hono inclus
        const netFoTTC = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("netFoTTC"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    Typed<IndicateurKPI>({
                        name: "net",
                        valueType: eKPIType.Price,
                        type: eIndicateurType.kpi,
                        field: netLnkKpiFO.Id,
                        options: { rid: netKpiFo["@rid"] }
                    }),
                    totalHonoFO.indicateur,
                    tvaFOIndicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Total Frais techniques Hono inclus
        const ftHono = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurComputed>({
                name: Trad("ftHono"),
                valueType: eKPIType.Price,
                operator: "+",
                type: eIndicateurType.computed,
                indicateurs: [
                    totalFrais.indicateur,
                    honoIndicateurCO,
                    honoFTIndicateur
                ]
            }),
            columnType: eColumnType.KPI
        })

        // Total Frais techniques Hono inclus
        const formatCouleur = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurJoin>({
                name: `${Trad("Format")} ${Trad("Couleur")}`,
                valueType: eKPIType.String,
                type: eIndicateurType.join,
                indicateurs: propOptions.map(p => p.indicateur).filter(i => i.field == "Format" || i.field == "ModelProperties.Couleur")
            }),
            columnType: eColumnType.Property
        })

        const duplicates = Typed<IndicateurOption>({
            indicateur: Typed<IndicateurJoin>({
                field: "Duplicates",
                name: `${Trad("duplicates")}`,
                valueType: eKPIType.String,
                type: eIndicateurType.join,
                indicateurs: [
                    Typed<IndicateurInfo>({
                        name: Trad("start"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Date,
                        field: "Start",
                        options: { value: { type: "moment" } }
                    }),
                    Typed<IndicateurInfo>({
                        name: TradProp("Support"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Rid,
                        field: "Support",
                    }),
                    Typed<IndicateurInfo>({
                        name: TradProp("BroadcastArea"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Rid,
                        field: "BroadcastArea",
                    }),
                    Typed<IndicateurInfo>({
                        name: TradProp("Currency"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Rid,
                        field: "Currency",
                    }),
                    Typed<IndicateurInfo>({
                        name: TradProp("AdvertiserGroup"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Rid,
                        field: "AdvertiserGroup",
                    }),
                ],
                options: { separator: " - " }
            }),
            columnType: eColumnType.Property
        })
        const indicateurAdvCompanyGroupCom = Typed<IndicateurOption>({
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                type: eIndicateurType.info,
                name: `${TradClassName(ref_AdvertisingCompanyGroups.name)} Commercial`,
                field: "AdvCompany_Com.first(out('lnk_Hierarchy'))",
                valueType: eKPIType.Rid,
                options: {
                    subProperty: "Name",
                    MetaData: {
                        linkedClass: ref_AdvertisingCompanyGroups.name,
                        name: "AdvCompanyGroup_Com",
                        type: ePropType.Link
                    }
                }
            })
        });
        const indicateurAdvCompanyGroupFin = Typed<IndicateurOption>({
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                type: eIndicateurType.info,
                name: `${TradClassName(ref_AdvertisingCompanyGroups.name)} Financier`,
                field: "AdvCompany_Fin.first(out('lnk_Hierarchy'))",
                valueType: eKPIType.Rid,
                options: {
                    subProperty: "Name",
                    MetaData: {
                        linkedClass: ref_AdvertisingCompanyGroups.name,
                        name: "AdvCompanyGroup_Fin",
                        type: ePropType.Link
                    }
                }
            })
        });

        const computedIndicateurs: IndicateurOption[] = [
            totalTaxes,
            totalHonoCO,
            totalFrais,
            ...computedIndicateurReturned,
            netCoFTHono,
            totalCOHonoFPFC,
            totalFOHonoFPFC,
            netCoFT,
            netFoFT,
            netFoHono,
            netFoFTHono,
            netFoTTC,
            ftHono,
            formatCouleur,
            duplicates,
            indicateurAdvCompanyGroupCom,
            indicateurAdvCompanyGroupFin];

        /** CPM */
        const computedCPM: IndicateurOption[] = [];

        const mediasPress = medias.find(m => m.Name === "PRESSE");
        /** CPM Total */
        const diffusionKpi = kpis.find(k => k.Name === "Diffusion totale");
        const diffusionLnKpi = lnk_kpis.find(lnk => lnk.KPI === diffusionKpi["@rid"]);
        const nbtotalKpi = kpis.find(k => k.Name === "Nombre total");
        const nbtotalLnkKpi = lnk_kpis.find(lnk => lnk.KPI === nbtotalKpi["@rid"]);

        /** CPM - Payé */
        const diffusionPaidKpi = kpis.find(k => k.Name === "Diffusion payée");
        const diffusionPaidLnKpi = lnk_kpis.find(lnk => lnk.KPI === diffusionPaidKpi["@rid"]);
        const nbPaidKpi = kpis.find(k => k.Name === "Nombre payant");
        const nbPaidLnkKpi = lnk_kpis.find(lnk => lnk.KPI === nbPaidKpi["@rid"]);

        const cpm = this.CreateCPM(Trad("cpm_net"), false, netLnkKpi, netKpi, diffusionLnKpi, diffusionKpi, mediasPress, nbtotalLnkKpi, nbtotalKpi);
        const cpmPaid = this.CreateCPM(Trad("cpm_paye_net"), false, netLnkKpi, netKpi, diffusionPaidLnKpi, diffusionPaidKpi, mediasPress, nbPaidLnkKpi, nbPaidKpi);

        computedCPM.push({
            indicateur: cpm, columnType: eColumnType.KPI
        }, {
            indicateur: cpmPaid, columnType: eColumnType.KPI
        })

        if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)) {
            const cpmReturned = this.CreateCPM(`${Trad("cpm_net")} ${Trad("returned")}`, true, netLnkKpi, netKpi, diffusionLnKpi, diffusionKpi, mediasPress, nbtotalLnkKpi, nbtotalKpi);
            const cpmPaidReturned = this.CreateCPM(`${Trad("cpm_paye_net")} ${Trad("returned")}`, true, netLnkKpi, netKpi, diffusionPaidLnKpi, diffusionPaidKpi, mediasPress, nbPaidLnkKpi, nbPaidKpi);
            computedCPM.push({
                indicateur: cpmReturned, columnType: eColumnType.PriceReturned
            }, {
                indicateur: cpmPaidReturned, columnType: eColumnType.PriceReturned
            })
        }

        const computedDiff: IndicateurOption[] = [
            { indicateur: await this.CreateCompareIndicateur(TradProp("compliance_placement_pige", ref_Messages), "ModelProperties.BroadcastPlacement", "Placement"), columnType: eColumnType.KPI },
        ];

        /** Taux global BBA/BV */
        const ratioCols: IndicateurOption[] = [
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-b"), "Net", "Brut"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_bba-bv"), "Brut Base Achat", "Brut Valorisé"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bv"), "Net", "Brut Valorisé"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bv_fo"), "Net FO", "Brut Valorisé"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bba"), "Net", "Brut Base Achat"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_net-bba_fo"), "Net FO", "Brut Base Achat"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bba"), "Total", "Brut Base Achat"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bba_fo"), "Total FO", "Brut Base Achat"), columnType: eColumnType.KPI },

            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bv"), "Total", "Brut Valorisé"), columnType: eColumnType.KPI },
            { indicateur: await this.CreateRatioIndicateur(Trad("rate_total-bv_fo"), "Total FO", "Brut Valorisé"), columnType: eColumnType.KPI },
        ]

        /** Colonne Start sous format JJ */
        const dayColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("day_dd"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "DD" } }
            })
        };

        /** Colonne End sous format JJ */
        const dayEndColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("day_dd_end"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "End",
                options: { formater: { type: "moment", format: "DD" } }
            })
        };

        /** Colonne Mois sous format MM */
        const monthColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("day_m"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "MM" } }
            })
        };

        /** Colonne Mois sous format MMMM */
        const namedMonthColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("named_month"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "MMMM", trad: Trad } }
            })
        };

        /** Colonne Année sous format YY */
        const yearColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("year"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "YYYY" } }
            })
        };

        /** Colonne Semaine sous format ww */
        const namedWeekColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("named_week"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "WW" } }
            })
        };

        /** Colonne Semaine sous format ww */
        const datedWeekColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("dated_week"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", periodicity: "datedWeek" } }
            })
        };

        /** Colonne sous format trimestre */
        const trimesterColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("trimester"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", format: "[T]Q" } }
            })
        };

        /** Colonne sous format trimestre */
        const semesterColumn: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurInfo>({
                name: Trad("semester"),
                type: eIndicateurType.info,
                valueType: eKPIType.String,
                field: "Start",
                options: { formater: { type: "moment", periodicity: "semester" } }
            })
        };

        /** Colonne Nombre de jours */
        const nbDaysColumnComputed = Typed<IndicateurOption>({
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurComputed>({
                name: Trad("number_of_days"),
                valueType: eKPIType.Number,
                operator: "-",
                type: eIndicateurType.computed,
                options: { rate: 1 / (1000 * 60 * 60 * 24), round: 'ceil' },
                indicateurs: [
                    Typed<IndicateurInfo>({
                        name: Trad("end"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Number,
                        field: "End",
                        options: { value: { type: "moment" } }
                    }),
                    Typed<IndicateurInfo>({
                        name: Trad("start"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Number,
                        field: "Start",
                        options: { value: { type: "moment" } }
                    })
                ]
            }),

        })

        /** Colonne Nombre de semaines */
        const nbWeeksColumnComputed = Typed<IndicateurOption>({
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurComputed>({
                name: Trad("number_of_weeks"),
                valueType: eKPIType.Number,
                operator: "-",
                type: eIndicateurType.computed,
                options: { rate: 1 / (1000 * 60 * 60 * 24 * 7), round: 'ceil' },
                indicateurs: [
                    Typed<IndicateurInfo>({
                        name: Trad("end"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Number,
                        field: "End",
                        options: { value: { type: "moment" } }
                    }),
                    Typed<IndicateurInfo>({
                        name: Trad("start"),
                        type: eIndicateurType.info,
                        valueType: eKPIType.Number,
                        field: "Start",
                        options: { value: { type: "moment" } }
                    })
                ]
            }),

        })

        let contents: IndicateurOption[] = [
            {
                indicateur: indFreeType,
                columnType: eColumnType.Property
            },
            {
                indicateur: {
                    type: eIndicateurType.info,
                    name: TradProp('@rid', ref_Messages),
                    field: '@rid',
                    valueType: eKPIType.String
                },
                columnType: eColumnType.Property
            },
            ...propOptions,
            ...propKpis,
            ...restituedKPI,
            ...boundKPI,
            ...propDiscounts,
            ...propBarter,
            ...propDiscountBound,
            ...propDiscountReturned,
            ...computedIndicateurs,
            ...computedCPM,
            ...computedDiff,
            ...ratioCols,
            dayColumn,
            dayEndColumn,
            yearColumn,
            monthColumn,
            namedMonthColumn,
            namedWeekColumn,
            datedWeekColumn,
            trimesterColumn,
            semesterColumn,
            nbDaysColumnComputed,
            nbWeeksColumnComputed,
        ];

        if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)) {
            const returnedRate: IndicateurOption = {
                columnType: eColumnType.Property,
                indicateur: Typed<IndicateurKPI>({
                    name: Trad("returnedCurrency_rate"),
                    valueType: eKPIType.FullDecimal,
                    type: eIndicateurType.kpi,
                    field: netLnkKpi.Id,
                    options: { rid: undefined, isPriceReturned: true, forceValue: 1 }
                })
            };
            contents.push(returnedRate);
        }

        const numberElements: IndicateurOption = {
            columnType: eColumnType.Property,
            indicateur: Typed<IndicateurKPI>({
                name: Trad("number_elements"),
                valueType: eKPIType.Number,
                type: eIndicateurType.kpi,
                options: { rid: undefined, forceValue: 1 }
            })
        };
        contents.push(numberElements);

        const dicoCat = {
            [eColumnType.Property]: 0,
            [eColumnType.KPI]: 1,
            [eColumnType.PriceReturned]: 2,
            [eColumnType.Discount]: 3,
            [eColumnType.DiscountValue]: 4,
            [eColumnType.DiscountFO]: 5,
            [eColumnType.DiscountFOValue]: 6,
            [eColumnType.DiscountFOS]: 7,
            [eColumnType.DiscountFOSValue]: 8,
            [eColumnType.PriceBound]: 9,
            [eColumnType.DiscountValueBound]: 10,
            [eColumnType.DiscountFOValueBound]: 11,
            [eColumnType.Barter]: 12
        }

        if (!RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
            const toFilter: (keyof ref_Messages | string)[] = [
                "Deversement.InfoCO.LastUpdate" as any,
                "Deversement.InfoFO.LastUpdate" as any,
                "Deversement.InfoCO.Diff" as any,
                "Deversement.InfoFO.Diff" as any,
                "EstimateId",
                "EstimateNumber"];
            contents = contents.filter(c => !toFilter.includes(c.indicateur.field));
        }

        return contents.sort((a, b) => {
            const type = dicoCat[a.columnType] - dicoCat[b.columnType];
            if (type != 0)
                return type;
            return a.indicateur.name.localeCompare(b.indicateur.name);
        });
    }

    ProvideLEGOIndicateurs: () => Promise<Indicateur[]> = async () => {

        const localeSaved = GetCurrentLocale();
        await SetCurrentLocale("engine");

        const filterKpis = [eColumnType.KPI, eColumnType.Barter, eColumnType.PriceReturned, eColumnType.Property];
        const indicateurs = (await IndicateursProvider.GetInstance().Provide()
            .then(inds => inds.filter(i => filterKpis.includes(i.columnType) && !i.indicateur.options?.["formater"])))
            .map(i => i.indicateur);

        // pour les indicateurs de type KPI on prend l'id en name
        indicateurs.forEach(i => {
            if (i.field) {
                const fullField = i.field + (i.options?.['subProperty'] ? ('.' + i.options?.['subProperty']) : '');
                i.name = FormatPropertyName(fullField, fullField.includes("ModelProperties"));
            }
        });

        const indicateurIds = distinct(indicateurs
            .filter(i => i.valueType == eKPIType.Rid)
            .map(i => ({
                ...i,
                valueType: eKPIType.String,
                name: (i.field ? FormatPropertyName(i.field, i.field.includes("ModelProperties")) : i.name) + '_id',
                options: null
            })), i => i.name);

        const indicateurId: Indicateur = {
            type: eIndicateurType.info,
            name: 'ID',
            field: '@rid',
            valueType: eKPIType.String
        }


        await SetCurrentLocale(localeSaved);
        const columns = [indicateurId, ...indicateurIds, ...indicateurs];
        return columns;
    }

    private metadata: ClassProperty[];

    private CreateCPM(title: string, returned: boolean, netLnkKpi: lnk_HasKPI, netKpi: ref_KPIs, diffusionLnKpi: lnk_HasKPI, diffusionKpi: ref_KPIs, mediasPress: ref_Media, nbtotalLnkKpi: lnk_HasKPI, nbtotalKpi: ref_KPIs) {
        return Typed<IndicateurComputed>({
            name: title,
            valueType: eKPIType.Price,
            operator: "/",
            type: eIndicateurType.computed,
            options: { rate: 1000, isPriceReturned: returned },
            indicateurs: [
                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: netLnkKpi.Id,
                    options: { rid: netKpi["@rid"], isPriceReturned: returned }
                }),

                Typed<IndicateurComputed>({
                    name: 'diviseur_total',
                    valueType: eKPIType.Number,
                    type: eIndicateurType.computed,
                    operator: '+',
                    indicateurs: [
                        Typed<IndicateurKPI>({
                            name: "diffusion_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: diffusionLnKpi.Id,
                            options: {
                                rid: diffusionKpi["@rid"],
                                filter: { Media: mediasPress?.["@rid"] }
                            }
                        }),
                        Typed<IndicateurKPI>({
                            name: "nb_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: nbtotalLnkKpi.Id,
                            options: {
                                rid: nbtotalKpi["@rid"],
                                filterIgnore: { Media: mediasPress?.["@rid"] }
                            }
                        })
                    ]
                }),
            ]
        });
    }



    async CreateRatioIndicateur(title: string, kpi1: string, kpi2: string) {

        const kpis = await this.getKPIs();
        const lnk_kpis = await this.getLnkKPIs();

        const Kpi1 = kpis.find(k => k.Name === kpi1);
        const LnkKpi1 = Kpi1 && lnk_kpis.find(lnk => lnk.KPI === Kpi1["@rid"]);

        const Kpi2 = kpis.find(k => k.Name === kpi2);
        const LnkKpi2 = Kpi2 && lnk_kpis.find(lnk => lnk.KPI === Kpi2["@rid"]);

        return Typed<IndicateurComputed>({
            name: title,
            valueType: eKPIType.Percent,
            operator: "%",
            type: eIndicateurType.computed,
            indicateurs: [
                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: LnkKpi1?.Id ?? kpi1,
                    options: { rid: Kpi1?.["@rid"] }
                }),

                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: LnkKpi2?.Id ?? kpi2,
                    options: { rid: Kpi2?.["@rid"] }
                }),
            ]
        });
    }

    async CreateKPIIndicateur(name: string) {
        const kpis = await this.getKPIs();
        const lnk_kpis = await this.getLnkKPIs();

        const kpi = kpis.find(k => k.Name === name);

        /** on cherche le lnkKpi pour avoir la clé et le type */
        const lnkKpi = lnk_kpis.find(lnk => lnk.KPI === kpi["@rid"])

        const indKpi: IndicateurKPI = {
            type: eIndicateurType.kpi,
            name: Trad(kpi.Name),
            valueType: lnkKpi.ValueType,
            field: lnkKpi.Id,
            options: { rid: kpi["@rid"] }
        }

        return indKpi;
    }

    private async CreateCumulIndicateur(
        discountTypeName: string,
        indicateurName: string,
        discounts: ref_DiscountClasses[],
        options?: cumulIndicateurArg) {

        const taxesRid = (await this.getDiscountTypes()).find(t => t.Name === discountTypeName)["@rid"];

        const taxesClasses = discounts.filter(d => d.DiscountType === taxesRid)
            .map((d: ref_DiscountClasses) => (Typed<IndicateurDiscount>({
                type: eIndicateurType.discount,
                valueType: eKPIType.Price,
                name: `montant ${d.Name}`, // Ne pas traduire ce champs car tout l'indicateur est serializé sert de clé
                options: {
                    rid: d["@rid"],
                    value: eDiscountOptionValue.Value,
                    type: eDiscountOptionType.CO,
                    ...options?.indicateurDiscountOptions
                }
            })));

        const cumul: IndicateurOption = {
            indicateur: Typed<IndicateurComputed>({
                name: indicateurName,
                indicateurs: taxesClasses,
                operator: "+",
                type: eIndicateurType.computed,
                valueType: eKPIType.Price,
                ...(options?.indicateurComputedOptions != undefined && { options: options.indicateurComputedOptions })
            }),
            columnType: eColumnType.DiscountValue,
            ...options?.indicateurOption
        };
        return cumul;
    }

    private async CreateCompareIndicateur(title: string, field1: string, field2: string) {
        return Typed<IndicateurComputed>({
            name: title,
            valueType: eKPIType.Number,
            operator: "=",
            type: eIndicateurType.computed,
            indicateurs: [
                Typed<IndicateurInfo>({
                    name: "net",
                    valueType: eKPIType.String,
                    type: eIndicateurType.info,
                    field: field1
                }),

                Typed<IndicateurInfo>({
                    name: "net",
                    valueType: eKPIType.String,
                    type: eIndicateurType.info,
                    field: field2
                }),
            ]
        });
    }

    private async getMetadata(): Promise<ClassProperty[]> {
        if (!this.metadata) {
            this.metadata = (await DataProvider.getMetadata(ref_Messages.name));
        }
        return this.metadata;
    }


    private kpis: ref_KPIs[];
    private async getKPIs(): Promise<ref_KPIs[]> {
        if (!this.kpis) {
            this.kpis = await MessageModelManager.GetRefKPIs();
        }
        return this.kpis;
    }

    private medias: ref_Media[];
    private async getMedias(): Promise<ref_Media[]> {
        if (!this.medias) {
            this.medias = (await DataProvider.search<ref_Media>(ref_Media.name));
        }
        return this.medias;
    }

    private lnk_kpis: lnk_HasKPI[];
    private async getLnkKPIs(): Promise<lnk_HasKPI[]> {
        if (!this.lnk_kpis) {
            this.lnk_kpis = await MessageModelManager.GetRefLnkKPIs();
        }
        return this.lnk_kpis;
    }

    private discounts: ref_DiscountClasses[];
    async getDiscountClasses(filter?: (c: ref_DiscountClasses) => boolean): Promise<ref_DiscountClasses[]> {
        if (!this.discounts) {
            this.discounts = await DataProvider.search<ref_DiscountClasses>(ref_DiscountClasses.name);
        }

        if (!filter) {
            const [barterType] = await this.getDiscountTypes((t) => t.Name == "Barter");
            filter = (c: ref_DiscountClasses) => c.DiscountType != barterType["@rid"];
        }

        return this.discounts.filter(filter);
    }

    private discountTypes: ref_DiscountTypes[];
    async getDiscountTypes(filter?: (t: ref_DiscountTypes) => boolean): Promise<ref_DiscountTypes[]> {
        if (!this.discountTypes) {
            this.discountTypes = await DataProvider.search<ref_DiscountTypes>(ref_DiscountTypes.name);
        }
        return this.discountTypes.filter(filter ?? (d => d.Name != "Barter"));
    }
}

export async function UpdateIndicateursNames(indicateurs: Indicateur[], _allIndicateurs?: Indicateur[]) {
    const allIndicateurs = _allIndicateurs ?? (await IndicateursProvider.GetInstance().Provide()).map(op => op.indicateur);
    const signatures = allIndicateurs.map(ind => ({ signature: IndicateurToString(ind), name: ind.name }))

    if (indicateurs)
        for (const indicateur of indicateurs) {
            const sign = IndicateurToString(indicateur);
            const found = signatures.find(s => s.signature === sign);
            if (found)
                indicateur.name = found.name;
        }
}
