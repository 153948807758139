import { VertexAutocomplete } from 'adwone-lib'
import { Client } from 'hub-lib/client/client.bin'
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin'
import { ref_Sources } from 'hub-lib/models/orientdb/ref_Sources.bin'
import { src_AdwOne } from 'hub-lib/models/orientdb/src_AdwOne.bin'
import { ref_Messages, eStateType } from 'hub-lib/models/ref_Messages.bin'
import React from 'react'
import { Trad } from 'trad-lib'
import { Notify } from '../../../utils/Notify.bin'
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { getIcon } from '../../ConfigurableComponents/IconManager.bin'
import { CustomTextField } from '../../VertexGrid/Generic/CustomTextField'
import { SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from '../../VertexGrid/Generic/ToolbarAdw'
import Template from '../Template'
type TState = {
    customerSelected?: ref_Customers;
    messageId?: string;
    open: boolean
};
function UnlinkMessage() {
    const [state, setState] = React.useState<TState>()
    const [open, setOpen] = React.useState(false)
    const handleUnlink = async () => {
        const sources = (await Client.searchVertex(ref_Sources.name, { "@rid": state.customerSelected.Sources,"@class":src_AdwOne.name })).data.results;
        try {
            await Client.updateVertex(ref_Messages.name,
                {
                    "@rid":state.messageId,
                    "State":eStateType.Cancelled,
                    "collection":sources[0]?.URI
                }
                );
            Notify(Trad("status_modification_success"), "success");
        } catch (error) {
            console.log(error)
            Notify(Trad("status_modification_failed"), "error");
        }
        console.log(sources)
    }
    return (
        <>
            <div className="grid_container">
                <div style={{ width: '100%' }}>
                    <ToolbarAdw>
                        <ToolbarContainer>
                            <SelectedItemsContainer>
                                <div style={{ display: "flex" }} >
                                    <div style={{ width: "300px" }} className="margin-right">
                                        <VertexAutocomplete
                                            key={`1`}
                                            label={Trad("customer")}
                                            params={{ properties: ["@rid", "Sources", "Authorization", "Company", "Company.Name as CompanyName"], Active: true }}
                                            onChange={(value: ref_Customers) => {
                                                setState({ ...state, customerSelected: value })
                                            }}
                                            defaultValue={(options: ref_Customers[]) => options?.find((v) => v["@rid"] === state?.customerSelected?.["@rid"])}
                                            customDisplay="CompanyName"
                                            type={ref_Customers.name}
                                        />
                                    </div>
                                    <div style={{ width: "300px", marginRight: 12 }}>
                                        <CustomTextField
                                            label={Trad("message_id") + " *"}
                                            value={state?.messageId}
                                            onChange={(e) => setState({ ...state, messageId: e })}
                                        />
                                    </div>
                                    <CustomButton
                                        Label={Trad("Delier")}
                                        style={{ float: "right" }}
                                        className="custom_btn_danger"
                                        startIcon={getIcon("delete")}
                                        onClick={() => setOpen(!open)}
                                    />
                                    <GenericDialog
                                        dialogTitle={Trad("confirmation")}
                                        open={open}
                                        submitTitle={Trad("yes")}
                                        dialogContent={<p>{Trad("delete_confirmation")}</p>}
                                        submitClass={"custom_btn_danger"}
                                        handleClose={() => setOpen(!open)}
                                        actions={true}
                                        submitAction={() => {
                                            setOpen(!open);
                                            handleUnlink()
                                        }}
                                    />
                                </div>
                            </SelectedItemsContainer>
                        </ToolbarContainer>
                    </ToolbarAdw>
                </div>
            </div>
        </>
    )
}

export default Template(UnlinkMessage);
