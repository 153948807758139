import { ref_Departments, ref_DepartmentsId } from "./ref_Departments.bin";
import { UserRights, UserRightsId } from "./UserRights.bin";
import { ref_Profiles, ref_ProfilesId } from "./ref_Profiles.bin";
import { ref_Qualifications, ref_QualificationsId } from "./ref_Qualifications.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { OUser, OUserId } from "./OUser.bin";
import { rid } from "./CommonTypes.bin";

export type UserId = rid;

export class User extends OUser implements Referentials {
	"@rid"?: UserId;
	Department: ref_DepartmentsId;
	Rights: UserRightsId;
	isDefaultPassword: boolean;
	profile: ref_ProfilesId;
	qualification: ref_QualificationsId;
	registrationDate: Date;
	Active: boolean;

}