import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Client } from 'hub-lib/client/client.bin'
import { Trad } from 'hub-lib/models/custom/Trad.bin'
import * as React from 'react'
import { TradProvider } from 'trad-lib'
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { getIcon } from "adwone-lib/index"

class TState {
    traduction: Trad = new Trad()
    rid: string
}
export class NewTranslation extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        let newState = new TState()
        newState.traduction.Translations = {} as any
        this.state = newState
    }
    render() {
        const { suffixe } = this.props;
        let { traduction } = this.state
        return (
            <div>
                <h4>Ajouter une traduction</h4>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <FormControl variant="outlined" style={{ width: '20%', padding: '0 10px' }} >
                        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={traduction.Type}
                            onChange={(e: any) => { traduction.Type = e.target.value; this.forceUpdate() }}
                            label="Type"
                        >
                            <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                            <MenuItem value={"static"}>Static</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        variant="outlined"
                        label="Classe"
                        value={traduction.Class}
                        onChange={(e) => {
                            traduction.Class = e.target.value;
                            this.forceUpdate()
                            if (e.target.value === '') {
                                delete traduction.Class
                                this.forceUpdate()
                            }

                        }}
                        style={{ width: "20%", padding: '0 10px' }}
                    />
                    <TextField
                        variant="outlined"
                        label="Property"
                        value={traduction.Property}
                        onChange={(e) => {
                            traduction.Property = e.target.value;
                            this.forceUpdate()
                            if (e.target.value === '') {
                                delete traduction.Property
                                this.forceUpdate()
                            }

                        }}
                        style={{ width: "20%", padding: '0 10px' }}
                    />
                    {traduction.Type === "dynamic" &&
                        <TextField
                            variant="outlined"
                            label="Value"
                            value={traduction.Value}
                            onChange={(e) => {
                                traduction.Value = e.target.value;
                                this.forceUpdate()
                                if (e.target.value === '') {
                                    delete traduction.Value
                                    this.forceUpdate()
                                }
                            }}
                            style={{ width: "20%", padding: '0 10px' }}
                        />}
                    {traduction.Type === "static" &&
                        <TextField
                            variant="outlined"
                            label="Code"
                            value={traduction.Code}
                            onChange={(e) => {
                                traduction.Code = e.target.value;
                                this.forceUpdate()
                                if (e.target.value === '') {
                                    delete traduction.Code
                                    this.forceUpdate()
                                }
                            }}
                            style={{ width: "20%", padding: '0 10px' }}
                        />
                    }
                    <CustomButton
                        style={{ width: "10%", padding: '0 10px', marginLeft: 10, minWidth: 160 }}
                        Label="Ajouter trad"
                        startIcon={getIcon("check")}
                        className="custom_btn_information"
                        onClick={() => {
                            traduction.Translations["fr-FR"] = TradProvider.GetCode(traduction);
                            traduction.Translations["en-US"] = TradProvider.GetCode(traduction);
                            Client.createVertex("Trad" + (suffixe ?? ""), traduction).then(r => {
                                this.props.onChange?.();
                            }).catch(e => console.error(e))
                        }}
                    />
                </div>

                <h4>Supprimer une traduction</h4>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <TextField
                        variant="outlined"
                        label="Rid"
                        value={this.state.rid}
                        onChange={(e) => {
                            this.setState({
                                rid: e.target.value
                            })
                        }}
                        style={{ width: "20%", padding: '0 10px' }}
                    />
                    <CustomButton
                        style={{ width: "15%", marginLeft: 10, padding: '0 10px', minWidth: 160 }}
                        Label="Supprimer trad"
                        startIcon={getIcon("delete")}
                        className="custom_btn_danger"
                        onClick={() => {
                            Client.deleteVertex("Trad" + (suffixe ?? ""), this.state.rid).then(r => {
                                this.props.onChange?.();
                            })
                        }}
                    />
                </div>
            </div>
        )
    }
}