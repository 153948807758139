import { Checkbox } from "@progress/kendo-react-inputs";
import { TreeListBooleanEditor, TreeListColumnProps } from "@progress/kendo-react-treelist";
import { Client } from "hub-lib/client/client.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_Groups, GroupClass, hierarchyAdvertisers, eGroupCategories } from "hub-lib/models/ref_Groups.bin";
import { eCompare } from "hub-lib/operators.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../redux/store";
import { createNode, DataTree, onExpandedChange, setState } from "../../../redux/treeSlice";
import Loader from "../../layout/Loader";
import { CustomTreeList, TooltipCell } from "../Generic/CustomTreeList";
import { CommandCell, EditAdvertisersCell, EditCell } from "./GroupsCells.bin";

export type ref_GroupsTree = ref_Groups & DataTree;

const GroupsTab = ({
    isSelected,
    el,
    selectedItems,
    setSelectedItems
}: {
    isSelected: () => boolean,
    el: GroupClass;
    selectedItems: DataTree[];
    setSelectedItems: (els: DataTree[]) => void
}) => {
    const [loading, setLoading] = React.useState(false);
    const { data, inEdit, loaded, fetchedDataAdvertisers } = useSelector((root: RootState) => root.tree);
    const dispatch = useDispatch();

    const fetchData = async () => {
        setLoading(true);
        let typeId = null;
        let fetchedDataAdvertiserGroups = [];
        if (el.Of.Type) {
            const [type] = await Client.searchVertexTyped<ref_PropertyType>(ref_PropertyType, { Type: el.Of.Type });
            if (type) typeId = type?.["@rid"];
        }
        const fetchedData = (
            await Client.searchVertex(el.Of.Class, {
                Active: true,
                _operators: typeId
                    ? [
                        {
                            property: "PropertyType",
                            value: typeId,
                            compare: eCompare.Contains,
                        },
                    ]
                    : [],
            })
        )?.data?.results;
        if (el.Of.Class !== ref_Advertisers.name) {
            const res = await Client.searchVertex(hierarchyAdvertisers.class, hierarchyAdvertisers.params);
            fetchedDataAdvertiserGroups = res?.data?.results?.filter(hierarchyAdvertisers.filter);
        }
        const groups = await Client.searchVertexTyped(ref_Groups, { Active: true, Category: el.Category });
        const data = groups.map((el) => createNode({ el, data: fetchedData, childrenKey: "IDs" }));
        dispatch(
            setState({
                inEdit: [],
                fetchedDataCategory: el.Category,
                fetchedDataType: el.Of.Class,
                fetchedData,
                fetchedDataAdvertiserGroups,
                fetchedDataAdvertisers: fetchedDataAdvertiserGroups.reduce((prev, curr) => [...prev, ...curr.items], []),
                loaded: true,
                data: data,
            })
        );
        setLoading(false);
    };

    React.useEffect(() => {
        if (!loaded && !loading && isSelected()) fetchData();
    });

    const advertisersName = (dataItem: ref_GroupsTree) => {
        const advertisers = dataItem?.Area?.find((e) => e.Class === ref_Advertisers.name);

        if (!advertisers?.IDs && !dataItem?.parent) {
            return Trad("all");
        }
        if (!advertisers?.IDs) return "";
        return fetchedDataAdvertisers.filter(adv => advertisers.IDs.includes(adv["@rid"])).map(adv => adv.Name).join("|");
    }

    const columns: TreeListColumnProps[] = [{
        field: "Name",
        title: Trad("Name"),
        expandable: true,
        width: "600px",
        editCell: EditCell,
        resizable: true,
    }];

    if (el.Category != eGroupCategories.Magasin) {
        columns.push({
            field: "Default",
            title: Trad("default"),
            width: "100px",
            cell: (e) => <td>{e?.dataItem?.["Default"] ? Trad("yes") : ""}</td>,
            editCell: (e) =>
                !e.dataItem?.parent ? (
                    <td>
                        <Checkbox
                            defaultChecked={e.dataItem?.Default}
                            onChange={(event) => (e.dataItem.Default = !e.dataItem?.Default)}
                        />
                    </td>
                ) : (
                    <td />
                ),
            resizable: true,
        });
        columns.push({
            field: "Advertisers",
            title: Trad("advertisers"),
            width: "200px",
            cell: (props) => {
                return <TooltipCell {...props} customRender={(r, dataItem) => <td>{advertisersName(dataItem)}</td>} generateText={(item, dataItem) => advertisersName(dataItem)} />
            },
            editCell: (props) => <EditAdvertisersCell {...props} />,
            resizable: true,
        });
    }

    columns.push({
        title: Trad("Actions"),
        cell: CommandCell,
        width: "100px",
    });

    return (
        <>
            {loaded ? (
                <CustomTreeList
                    data={data}
                    selectable
                    selectedField="Selected"
                    expandField="Expanded"
                    subItemsField="Children"
                    columns={columns}
                    isSelectable={(r) => true}
                    selectedIds={selectedItems.map(e => e.id)}
                    editField="inEdit"
                    inEdit={inEdit}
                    onSelectionChanged={(e) => setSelectedItems(e)}
                    onExpandedChanged={(e) => dispatch(onExpandedChange(e))}
                />
            ) : (
                <Loader />
            )}
        </>
    );
};

export default GroupsTab;
