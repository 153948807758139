import * as React from "react";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { HierarchyPicker } from "../HierarchyPicker";
import { AdvertisersGroupsCombo } from "./AdvertisersGroupsCombo";
import { AdvertiserGroupExtended } from "hub-lib/models/AdvertiserGroupExtended.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { eGroupCategories, ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { Client } from "hub-lib/client/client.bin";
import { CustomList } from "./CustomList";
import { compareArrays, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { Trad, TradClassName } from "trad-lib";

type AdvertisersGroupsPickerProps = { onChange?: (selectedGroup: ref_Groups["@rid"]) => void, defaultValue?: ref_Groups["@rid"] }
export function AdvertisersGroupsPicker({ onChange, defaultValue }: AdvertisersGroupsPickerProps) {

    const [selectedGroup, setSelectedGRoup] = React.useState<ref_Groups>(undefined);
    const [selectedAdvertisers, setSelectedAdvertisers] = React.useState<ref_Advertisers[]>();

    React.useEffect(() => {
        if (defaultValue && selectedGroup === undefined)
            Client.searchVertexTyped(ref_Groups, { "@rid": defaultValue }).then(([res]) => setSelectedGRoup(res));
    }, [defaultValue])

    React.useEffect(() => {
        onChange?.(selectedGroup?.["@rid"]);
    }, [selectedGroup?.["@rid"]])

    React.useEffect(() => {
        if (selectedGroup) {
            Client.searchVertexTyped(ref_Advertisers, { "@rid": selectedGroup.IDs }).then((res) => {
                setSelectedAdvertisers(res);
            });
            return;
        }
    }, [GetHashCode(selectedGroup)])

    return <>
        <AdvertisersGroupsCombo
            selectedGroup={selectedGroup}
            className="adw-row advertiser-hierarchy-component"
            onChange={group => {
                if (group)
                    Client.searchVertexTyped(ref_Groups, { "@rid": group["@rid"] }).then(([res]) => setSelectedGRoup(res));
                else {
                    setSelectedGRoup(undefined);
                    setSelectedAdvertisers(undefined);
                }
            }} />

        <HierarchyPicker
            title={TradClassName(ref_Advertisers.name)}
            type={AdvertiserGroupExtended}
            value={selectedAdvertisers?.map(adv => ({ key: adv["@rid"] })) ?? []}
            filter={(e) => [ref_AdvertiserGroups.name, ref_Advertisers.name].includes(e["@class"])}
            multiSelectTreeProps={{
                onClose: async () => {

                    if (!selectedAdvertisers?.length)
                        return;

                    const advIds = selectedAdvertisers.map(adv => adv["@rid"]);
                    // On check si le groupe n'existe pas déjà
                    // requete à améliorer, car auj les opérateurs ne sont pas gérés sur les mongoMgr
                    const groups = await Client.searchVertexTyped(ref_Groups, { Category: eGroupCategories.Magasin, Active: true });
                    const existing = groups.find(e => compareArrays(e.IDs, advIds));
                    // s'il existe
                    if (existing) {
                        // on le selectionne
                        setSelectedGRoup(existing);
                        return;
                    }

                    let newGroup: ref_Groups = null;
                    if (selectedGroup)  newGroup =  duplicate(selectedGroup);
                    else {
                        newGroup = new ref_Groups();
                        newGroup.Of = { Class: ref_Advertisers.name }
                        newGroup.Active = true;
                    }

                    delete newGroup["@rid"];
                    newGroup.Category = eGroupCategories.Magasin;
                    newGroup.Of.Class = ref_Advertisers.name;
                    newGroup.IDs = advIds;
                    const newElement = (await Client.createVertex(ref_Groups.name, newGroup)).data.results;
                    setSelectedGRoup(newElement);
                }
            }}
            onSelectedItemsChange={values => {
                const _selectedAdvertisers = values.filter(v => v["@class"] == ref_Advertisers.name).map(v => v["@rid"]);
                Client.searchVertexTyped(ref_Advertisers, { "@rid": _selectedAdvertisers }).then(setSelectedAdvertisers);
            }}
            params={{
                Active: true,
                deep: true,
                format: { children: "items" }
            }} />

        {selectedAdvertisers?.length > 0 &&
            <CustomList
                data={selectedAdvertisers}
                onRemove={item => {
                    const newGroup = duplicate(selectedGroup);
                    newGroup.IDs = newGroup.IDs.filter(rid => rid != item["@rid"]);
                    Client.updateVertex(ref_Groups.name, newGroup).then(() => setSelectedGRoup(newGroup))
                }} />}
    </>
}

