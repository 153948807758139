import * as React from "react";
import "../../../../styles/LinkManager.scss";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { Trad, TradClassName } from "trad-lib";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import {
    ListBox,
    ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { select, setReferentialFilters, setState } from "../../../../redux/linkMgrSlice";
import { useState, useEffect } from "react";
import { Client } from "hub-lib/client/client.bin";
import { ReferentialHasViews } from "hub-lib/models/orientdb/ReferentialHasViews.bin";
import { metaEdge } from "hub-lib/models/types.bin";
import { capitalizeFirstLetter, ClickRefModalityHandler, getData, getDimension, getSource, iconLinked, isLinked, MappingProperties, refClassname } from "./LinkManagerTools";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { TooltipManager } from "../../../CustomTooltip";
import Loader from "../../Loader";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { RowHeader } from "./LinkManager.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_AdvertisingCompanyGroups } from "hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { TableExport } from "hub-lib/models/external.bin";
import { IndicateurInfo } from "adwone-engine/index.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { Referentials } from "hub-lib/models/orientdb/Referentials.bin";
import { rmAccents } from "hub-lib/tools.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { CustomTreeList, TooltipCell } from "../../../VertexGrid/Generic/CustomTreeList";
import { TreeListColumnProps, TreeListTextFilter } from "@progress/kendo-react-treelist";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { Format } from "format-lib/index.bin";
import { lnk_Hierarchy } from "hub-lib/models/orientdb/lnk_Hierarchy.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";

export function ReferentialList() {
    return <>
        <div className="header-list">
            <ReferentialListHeaders />
        </div>

        <ReferentialListBox />
    </>
}

function ReferentialListHeaders() {
    return <>
        <RowHeader>
            <DimensionSelector />
            <CountLinkedReferentials />
        </RowHeader>
        {/* <RowHeader>
            <SearchTextFilters placeholder="ID, Name, Parent ID(s)" />
        </RowHeader> */}
        <RowHeader>
            <ReferentialLinkFilters />
        </RowHeader>
        <div className="buttons-link ">
            <DownloadCSVButton />
        </div>
    </>
}

type DimensionType = {
    value: string;
    trad: string;
    group: string;
    property?: ref_PropertyType;
}

function DimensionSelector() {

    const selectedProptype = useSelector((root: RootState) => root.linkMgr.selectedProptype);
    const selectedDimension = useSelector((root: RootState) => root.linkMgr.selectedDimension);
    const [dimensions, setDimensions] = useState<string[]>(undefined);
    const [proptypes, setProptypes] = useState<ref_PropertyType[]>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!dimensions)
            Client.getDimensionLinkTypes(ReferentialHasViews.name).then(_res => {
                const links: metaEdge[] = _res.data.results || [];
                const dims: Set<string> = new Set<string>();
                links.forEach((l) => {
                    const prop = getDimension(l);
                    if (prop?.linkedClass) dims.add(prop.linkedClass)
                });
                const allDimensions = Array.from(dims).sort((a, b) => Trad(a).localeCompare(Trad(b)));
                setDimensions(allDimensions);
                dispatch(select({ selectedDimension: allDimensions[0] }));
            });

        if (!proptypes) {
            Client.get<ref_PropertyType>(ref_PropertyType).then(_res => {
                const allPropTypes = _res.data.results;
                setProptypes(allPropTypes);
            });
        }
    });

    const dimensionSelectionChanged = async (e: ComboBoxChangeEvent) => {
        const value: DimensionType = e.value;
        if (value) {
            if (value.property) {
                const { links, selectedSource } = store.getState().linkMgr;
                const selectedLink = links
                    .filter((l) => getSource(l)?.linkedClass == selectedSource?.["@class"])
                    .find(l => MappingProperties[value.property.Type].some(v => l.name.includes(v)))
                // .find(l => l.name.includes(MappingProperties[value.property.Type]))

                dispatch(select({
                    selectedDimension: ref_Property.name,
                    selectedProptype: value.property,
                    selectedLink
                }))
            } else
                dispatch(select({
                    selectedDimension: value.value,
                    selectedProptype: undefined
                }));
            getData();
        }
    };

    const getGroup = (v) => {
        const companies = [ref_Advertisers.name, ref_AdvertiserGroups.name, ref_Companies.name, ref_AdvertisingCompanies.name, ref_AdvertisingCompanyGroups.name];
        if (companies.includes(v))
            return "Sociétés";
        return "Autres";
    }

    const dataDimensions = dimensions?.map(v => ({
        value: v,
        trad: capitalizeFirstLetter((v == ref_Companies.name) ? TradClassName(ref_Agencies.name) : TradClassName(v)),
        group: getGroup(v),
        property: undefined
    })) ?? [];

    const dataProperties = proptypes
        ?.filter(p => p.ValueType === "@rid" && Object.keys(MappingProperties).includes(p.Type))
        ?.map(v => ({
            value: v["@rid"],
            trad: capitalizeFirstLetter(Trad(v.Type)),
            group: TradClassName(ref_Property.name),
            property: v
        })) ?? [];

    const data: DimensionType[] = [...dataDimensions, ...dataProperties].sort((a, b) => b.group.localeCompare(a.group));
    const selectedElement =
        (selectedProptype ? data.find(d => d.property?.["@rid"] == selectedProptype["@rid"]) : undefined) ||
        (selectedDimension ? data.find(d => d.value == selectedDimension) : undefined);

    return <div className="selecters">
        {/* Sélection de la dimension à manipuler */}

        <span style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <span className="link-title-header">Référentiel: </span>
            <ComboBox
                clearButton={false}
                className="link-input"
                groupField="group"
                value={selectedElement}
                data={data}
                allowCustom={false}
                textField="trad"
                dataItemKey="value"
                onChange={dimensionSelectionChanged} />
        </span>
    </div>
}

function SearchTextFilters(props: { placeholder?: string }) {
    const searchRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.searchRef);
    const dispatch = useDispatch();

    return <Input
        type="text"
        className="link-input"
        value={searchRef}
        onChange={(t) => dispatch(setReferentialFilters({ searchRef: t.value }))}
        placeholder={"Rechercher: " + props.placeholder} />
}

function ReferentialLinkFilters() {

    const includeLinkedRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeLinkedRef);
    const includeUnlinkedRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeUnlinkedRef);
    const includeActiveRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeActiveRef);
    const includeInactiveRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeInactiveRef);
    const dispatch = useDispatch();

    return <div className='filters'>
        <Checkbox
            label="Liés"
            value={includeLinkedRef}
            onChange={e => dispatch(setReferentialFilters({ includeLinkedRef: e.value }))} />
        <Checkbox
            className="link-checkbox-filter"
            label="Non liés"
            value={includeUnlinkedRef}
            onChange={e => dispatch(setReferentialFilters({ includeUnlinkedRef: e.value }))} />
        <Checkbox
            className="link-checkbox-filter"
            label="Actifs"
            value={includeActiveRef}
            onChange={(event) => dispatch(setReferentialFilters({ includeActiveRef: event.value }))} />
        <Checkbox
            className="link-checkbox-filter"
            label="Inactifs"
            value={includeInactiveRef}
            onChange={(event) => dispatch(setReferentialFilters({ includeInactiveRef: event.value }))} />
    </div>
}

export const refModalityListItem = (props) => {
    const { dataItem, selected, ...others } = props;
    const d = props.dataItem;
    const icon = iconLinked(d);
    return (
        <li {...others} className={`k-item ${refClassname(d)}`}>
            <div
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: d.Name })}
                className={`modality clearfix`}
                // style={{ background: this.refColor(d) }}
                key={"moda" + d["@rid"]}>
                <div className="item-link" style={{ float: "left" }}>
                    <span>
                        {d.Name}
                        <span style={{ fontSize: "x-small", verticalAlign: "middle" }}>
                            {d["Media"] ? " (" + d["Media"] + ")" : ""}
                        </span>
                    </span>
                </div>
                <i className={`material-icons iconlink-unlink ${icon}`}>{iconLinked(d)}</i>
                <div style={{ float: "right" }}>{d["@rid"]}</div>
            </div>
        </li>
    );
};

function getAdditionnalColumnsByType() {
    const additionnalColumnsByType: { [p: string]: TreeListColumnProps[] } = {
        [ref_Supports.name]: [{
            title: Trad("Media"),
            field: "Media",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true
        }, {
            title: TradClassName(ref_AdvertisingCompanies.name),
            field: "Suppliers",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            cell: (_props) => <TooltipCell
                {..._props}
                generateText={async (rid, row) => {
                    return rid ? (await Client.searchVertex(ref_AdvertisingCompanies.name, {
                        '@rid': rid,
                        properties: ["Name"]
                    }))?.data?.results?.map(e => e.Name)?.join(", ") : "";
                }} />,
            locked: true,
            resizable: true
        }]
    };
    return additionnalColumnsByType;
}

function MyLoader() {
    const loading = useSelector((root: RootState) => root.linkMgr.loaders.gridRef);
    return <div className="link-loader-container">{loading && <div className="link-loader-div"><Loader /></div>}</div>
};

function DownloadCSVButton() {

    const getAutoColumns = (views: Referentials[]) => {
        const props = new Set<string>();
        views.forEach(v => Object.keys(v).forEach(k => {
            if (!k.startsWith("out_") && !k.startsWith("in_"))
                props.add(k);
        }));
        return Array.from(props).map(p => new IndicateurInfo({ valueType: eKPIType.String, field: p, name: p }));
    }



    return <CustomButton
        Label="Exporter"
        className="custom_btn_secondary_white" onClick={async () => {
            const { selectedLink, modalities } = store.getState().linkMgr;

            let document = getDimension(selectedLink).linkedClass;
            let filter: any = {
                "@rid": modalities.map(v => v["@rid"])
            };
            let columns = getAutoColumns(modalities);

            if ([ref_Advertisers.name, ref_Brands.name, ref_Products.name].includes(document)) {
                const properties = ['in as ParentID', 'in.Name as ParentName', 'out as ID', 'out.Name as Name'];

                const metadataRes = await Client.getMetadata(document);
                metadataRes.data.results.forEach(p => {
                    if (!properties.some(prop => prop.includes(` as ${p.name}`)))
                        properties.push(`out.${p.name} as ${p.name}`);
                })

                document = lnk_Hierarchy.name;
                filter = { out: filter?.['@rid'], properties };
                columns = properties.map(p => p.split(' as ')[1]).map(p => new IndicateurInfo({ valueType: eKPIType.String, field: p, name: p }));
            }

            const arg: TableExport = {
                type: "table",
                document,
                columns,
                filter
            }
            Client.downloadExport("csv", arg, "export");
        }} />
}

function CountLinkedReferentials() {
    const modalities = useSelector((root: RootState) => root.linkMgr.modalities);

    return <>
        {modalities &&
            <React.Fragment>
                <div className="header-modality-count is-linked">({modalities.filter(m => isLinked(m as any), "Referentials").length}/{modalities.length})</div>
            </React.Fragment>}
    </>
}

function ReferentialListBox() {

    // important de laisser ce selector pour afficher les sélections
    const outIds = useSelector((root: RootState) => root.linkMgr.outIds);

    const modalities = useSelector((root: RootState) => root.linkMgr.modalities)?.map(m => ({ id: m["@rid"], children: null, ...m }));
    const searchRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.searchRef);
    // const searchRid = useSelector((root: RootState) => root.linkMgr.filtersReferential.searchRid);
    const includeLinkedRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeLinkedRef);
    const includeUnlinkedRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeUnlinkedRef);
    const includeActiveRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeActiveRef);
    const includeInactiveRef = useSelector((root: RootState) => root.linkMgr.filtersReferential.includeInactiveRef);
    const dispatch = useDispatch();

    const filterRef = (m: any) => {
        let res = rmAccents(m.Name.toLowerCase()).includes(rmAccents(searchRef.toLowerCase()));
        if (m && searchRef === " ") {
            res = res || m["parentRid"] === null;
        } else if (m) {
            res = res || m["parentRid"]?.includes(searchRef);
            res = res || m["@rid"]?.includes(searchRef);
        }

        if (!includeLinkedRef) res = res && !isLinked(m);
        if (!includeUnlinkedRef) res = res && isLinked(m);
        if (!includeActiveRef) res = res && !m.Active;
        if (!includeInactiveRef) res = res && m.Active;

        return res;
    };

    const additionalColumns: TreeListColumnProps[] = [];
    const className = modalities?.[0]?.["@class"];
    if (className)
        (getAdditionnalColumnsByType()[className] ?? []).forEach(c => additionalColumns.push(c));

    const hasParentRid = modalities?.some(m => m["parentRid"]);
    if (hasParentRid) {
        modalities.forEach(m => {
            m["parentRid_str"] = (Array.isArray(m["parentRid"]) ? m["parentRid"]?.join?.(", ") : m["parentRid"]) ?? "";
        });
    }

    return <>
        {modalities &&
            <div className="sub_list_style referential-grid-container">
                <MyLoader />

                <CustomTreeList
                    subItemsField="children"
                    data={modalities.filter(filterRef) ?? []}
                    rowHeight={30}
                    gridProps={{
                        className: "custom-tree-list  link-mgr",
                        rowRender: (row, props) => {
                            return <tr {...(row.props as any)}
                                className={`item-clickable k-item ${row.props.className} ${refClassname(props.dataItem)}`}
                                onClick={async (_props: MouseEvent) => {
                                    await ClickRefModalityHandler(props);
                                }} />;
                        }
                    }}
                    columns={[{
                        title: Trad("name"),
                        field: "Name",
                        filter: TreeListTextFilter,
                        className: "no-wrap",
                        width: "100%",
                        resizable: true
                    },
                    {
                        title: "id",
                        field: "@rid",
                        filter: TreeListTextFilter,
                        locked: true,
                        resizable: true
                    },
                    ...(hasParentRid ? [{
                        title: Trad("parent"),
                        field: "parentRid",
                        filter: TreeListTextFilter,
                        locked: true,
                        resizable: true,
                        cell: (_props) => <TooltipCell
                            {..._props}
                            generateText={async (rid) =>
                                rid ? (await Client.searchVertex("Referentials", { '@rid': rid }))?.data?.results?.map(e => Format(e))?.join(", ") : ""} />

                    }] : []),
                    ...additionalColumns]}
                    onFilterChange={(filter) => {
                        dispatch(setReferentialFilters({ columnFilters: filter }))
                    }}
                />

            </div>}
    </>
}

