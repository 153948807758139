import { ATab } from "./TabManager.bin";
import * as React from 'react'
import { GenericDocument } from "../crossedTable/GenericDocument.bin";
import { TConfState } from "../crossedTable/CrossedTableConf.bin";

export class GenericComponentTab extends ATab {

    private rid: string;
    private ref: GenericDocument;
    configuration: TConfState;

    constructor(rid?: string, name?: string) {
        super();
        this.rid = rid;
        this.name = "Onglet"
    }

    getComponent = () => {
        return (<GenericDocument
            configuration={this.configuration}
            onConfChanged={this.onTabChanged}
            onConfinitialized={(conf: TConfState) => {
                this.configuration = conf;
                this.onTabChanged?.();
            }} />);
    };

    save = () => {
        // nothing for now
    };

}