
import { TextField } from '@material-ui/core';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad } from 'trad-lib';
import { setMessageSync } from '../../../../redux/messageEditorSlice';
import { RootState, store } from '../../../../redux/store';

type ModelPropertiesEditorArgs = { property: string, label?: string }
export function ModelPropertiesEditor({ property, label }: ModelPropertiesEditorArgs) {

    const propertyValue = useSelector((root: RootState) => root.messageEditor.message.ModelProperties?.[property]);

    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={label ?? Trad(`property_${property}`)}
        value={propertyValue ?? ""}
        variant="outlined"
        onChange={(e) => {
            const message = store.getState().messageEditor.getMessage();
            if (!message.ModelProperties)
                message.ModelProperties = {};
            message.ModelProperties[property] = e.target.value;
            store.dispatch(setMessageSync(message));
        }}
    />
}