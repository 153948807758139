
import * as React from "react";
import { IconButton, MenuItem, MenuList, TextField } from "@material-ui/core";
import { IRid } from 'hub-lib/models/IRid.bin';
import { clone, duplicate, propertyOf } from "hub-lib/tools.bin";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../../../utils/Notify.bin";
import { Trad, TradClassName } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { FilterStorage, getStorageClass } from "../../../utils/localstorage.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { TooltipManager } from "../../CustomTooltip";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { store } from "../../../redux/store";
import { hide, show, showElement } from "../../../redux/backgroundSlice";
import { GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { OutsideAlerter } from "../Generic/Common.bin";
import { GetStoreModeleElement, SaveButton } from "../Filters/SaveButton";
import { Row } from "../../Tools";
import { Popup } from "@progress/kendo-react-popup";
import { eBusinessCode } from 'hub-lib/models/types.bin';
import { eventEmitter } from "../../GenericModal";

export class ModeleCreatorBaseProps<T> {
    objectType: new () => T;
    onChange: (conf: T, event?: { type: "contentChanged" | "selectionChanged" }) => void;
    dialogDelete?: boolean;
    onDelete?: (conf: T) => void;
    modele: T;
    type: string;
    disableLocalStorage?: boolean;
    getConfiguration?: () => T;
    saveConfiguration?: (conf: T) => void;
}

export class TState<T> {
    configuration: T;
    editMode: boolean;
    editedName: string;
    dialogRMOpen: boolean;
    version: number;
    defaultModel: Partial<T>;
    open: boolean;
}

class ConfigurationBase implements IRid {
    "@rid"?: IRid["@rid"];
    Name: string;
    Default?: boolean;
    Table: string;
    Active?: boolean;
}

type IconActionArgs = { onClick, tooltip, icon, className?: string, disabled?: boolean };
export const IconAction = ({ onClick, tooltip, icon, className, disabled }: IconActionArgs) =>
    <div style={{ display: "inline-block" }}
        onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: tooltip })}>
        <CustomIconButton disabled={disabled} onClick={onClick} className={"picto " + (className ?? "custom_btn_secondary")}>
            {getIcon(icon)}
        </CustomIconButton>
    </div>

/**
 * Composant de création d'un modèle de tableau (sélection des indicateurs)
 */

export class AModeleCreatorBase<TProps extends ModeleCreatorBaseProps<T>, T extends ConfigurationBase> extends React.Component<TProps, TState<T>> {
    wrapperRef: any;
    constructor(props: TProps) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            configuration: this.props.modele,
            editMode: false,
            editedName: this.props.modele?.Name,
            dialogRMOpen: false,
            version: 0,
            defaultModel: null,
            open: false,
        }
    }

    async componentDidMount() {
        const { objectType } = this.props;
        const [defaultModel] = await Client.searchVertexTyped(objectType, { Default: true, Table: ref_Messages.name });
        defaultModel.Name = Trad("new_config");
        defaultModel.Default = false;
        this.setState({ defaultModel });
    }

    onChange = async (configuration: T) => {
        if (!configuration?.Default)
            await this.save(configuration);
        else
            this.props?.onChange?.(configuration);
        // this.props?.onChange?.(configuration);
    }

    save = async (configuration: T) => {

        const { objectType, disableLocalStorage } = this.props;
        if (configuration.Default)
            return Notify(Trad("cannot_change_default_conf"), "error");

        // await Client.updateVertex(objectType.name, configuration, false);

        if (!disableLocalStorage) {
            FilterStorage.setLocalStorageValue(objectType.name, configuration);
        }
        this.props?.onChange?.(configuration);
    }

    saveName = async () => {

        const { objectType, disableLocalStorage } = this.props;
        const { configuration, editedName } = this.state;
        console.log(`saveName of`, configuration.Name, configuration)

        if (configuration.Default)
            return Notify(Trad("cannot_change_default_conf"), "error");

        // On modifie uniquement le nom;
        configuration.Name = editedName;
        const [element] = await Client.searchVertexTyped(objectType, { '@rid': configuration["@rid"] });
        try {
            if (element) {
                element.Name = configuration.Name;
                await Client.updateVertex(objectType.name, element, false);
            }
        } catch (error: any) {
            if (error?.response?.data.error.data.type == eBusinessCode.AlreadyExist) {
                const handleCancel = () => {
                    this.setState({ editMode: true })
                    eventEmitter.close();
                };
                const handleConfirm = async () => {
                    eventEmitter.close();

                    const { objectType } = this.props;
                    let { configuration, editedName } = this.state;
                    const configExist = await Client.searchVertexTyped(objectType, { 'Name': editedName, 'Active': true });

                    // Filter out the configuration to be deleted
                    const configToDelete = configExist.filter(config => config["@rid"] !== configuration["@rid"]).map(config => config["@rid"])

                    await Client.deleteVertex(objectType.name, configToDelete, false)

                    await Client.updateVertex(objectType.name, element, false);
                };
                this.modalEvent(handleCancel, handleConfirm)
            }
        }

        if (!disableLocalStorage) {
            FilterStorage.setLocalStorageValue(objectType.name, configuration);
        }

        this.setState({ editMode: false }, async () => {
            store.dispatch(hide());
            // uniquement quand l'édition est finie
            if (!disableLocalStorage)
                FilterStorage.setLocalStorageValue(objectType.name, configuration);
            this.props?.onChange?.(configuration);
        });
    }

    modalEvent = (handleCancel, handleConfirm) => {
        return eventEmitter.push({
            title: Trad("confirmation"),
            content: Trad("the_model") + this.state.editedName + Trad("replacement_warning_message"),
            buttons: [
                {
                    label: Trad("cancel"),
                    handleButton: handleCancel,
                    className: "custom_btn_primary_cancel",
                },
                {
                    label: Trad("yes"),
                    handleButton: handleConfirm,
                    className: "custom_btn_danger",
                }
            ]
        })
    }
    private newConfCreated = async (res: { data: { results: T } }) => {
        const configuration = res.data.results;
        this.setState({ version: this.state.version + 1, configuration, editMode: true, editedName: configuration.Name }, () => store.dispatch(show()));
    }

    addNew = () => {
        const { objectType } = this.props;
        const { defaultModel } = this.state;
        const configuration = duplicate(defaultModel);
        configuration.Default = false;
        // configuration.Table = ref_Messages.name;
        // // configuration.Filters = new Filter();
        // configuration.Active = true;
        // this.configureDefault(configuration);

        Client.createVertex(objectType.name, configuration)
            .then(res => this.newConfCreated(res))
            .catch(e => console.error(e));
    }

    dupplicate = async () => {
        const { objectType } = this.props;
        const current = GetStoreModeleElement<T>(objectType.name);

        const configuration = duplicate(current);
        configuration.Name = Trad('new_config');
        delete configuration.Default;
        delete configuration["@rid"];
        //configuration.Name = current.Name + " - Copie";
        configuration.Table = ref_Messages.name;
        // configuration.Filters = { ...current.Filters };
        configuration.Active = true;

        try {
            const duplicated = (await Client.createVertex(objectType.name, configuration)).data.results;
            this.setState({
                version: this.state.version + 1,
                configuration: duplicated,
                editMode: true,
                editedName: configuration.Name
            },
                () => store.dispatch(show()));
        } catch (error) {
            console.error(error)
        }
    }


    remove = async () => {
        const { objectType } = this.props;
        let { configuration } = this.state;
        if (configuration.Default) {
            Notify(Trad("cannot_change_default_conf"), "error");
            return;
        }

        const defaultConf = clone(configuration);
        defaultConf.Default = true;
        defaultConf.Name = Trad('default');
        delete defaultConf["@rid"];
        //let defaultConf = (await Client.searchVertex(objectType.name, { Default: true, Table: ref_Messages.name }))?.data.results?.[0]

        Client.deleteVertex(objectType.name, configuration["@rid"], false)
            .then(() => {
                Notify(Trad("removed_conf_success"), "success")
                FilterStorage.setLocalStorageValue(objectType.name, defaultConf);
                // TableStorage.SetTable(defaultConf);
                if (this.props.onDelete) {
                    this.props.onDelete(defaultConf);
                } else {
                    this.onChange(defaultConf);
                }
                this.setState({ configuration: defaultConf });
            })
            .catch(() => Notify(Trad("cannot_remove_conf"), "error"))
            .finally(() => this.setState({ version: this.state.version + 1 }));
    }
    beforeRender() {
        return <></>
    }

    afterRender() {
        return <></>
    }

    stopEdition = () => this.setState({ editMode: false }, () => store.dispatch(hide()));
    render() {
        const { objectType, disableLocalStorage, dialogDelete } = this.props;
        const { editedName, editMode, version, configuration, dialogRMOpen, open } = this.state;
        return (
            <>
                {this.beforeRender()}
                <Row style={{ display: 'flex' }} className='modele-selector-root'>
                    <div className="modele-selector-icon-filter">
                        <IconButton className="no-radius no-shadow">
                            {getIcon('filterAlt')}
                        </IconButton>

                    </div>
                    <div className="modele-selector-container">
                        {!editMode && <VertexAutocomplete // Not favorite for now, (bug)
                            disableClearable={true}
                            key={`ref_FilterConfigurations-select-${version}`}
                            label={TradClassName(objectType.name)}
                            type={objectType.name}
                            defaultValue={(all: T[]) => {

                                const found = (configuration?.["@rid"] && all?.find(e => e["@rid"] == configuration["@rid"]))
                                    || (!configuration?.Default && configuration)
                                    || all?.find((v) => v.Default);

                                if (found && (!configuration || configuration?.["@rid"] != found["@rid"])) {
                                    //SetConfigurationTab(ref_Messages.name, found["@rid"]);
                                    // this.onChange(found);
                                    this.props?.onChange?.(found);
                                    this.setState({ configuration: found });
                                }

                                return found;
                            }}
                            params={{ [propertyOf<T>("Table")]: this.props.type, properties: ["*"], Active: true }}
                            onChange={async (selected: T) => {
                                // Gère le cas où la configuration a changé entre temps
                                if (selected?.["@rid"])
                                    selected = (await Client.searchVertex(objectType.name, { "@rid": selected?.["@rid"] }))?.data.results?.[0]

                                if (!disableLocalStorage) {
                                    const Storage = getStorageClass(objectType.name);
                                    selected = Storage.repair(selected, objectType);
                                    Storage.set(selected);
                                }
                                this.setState({ configuration: selected }, () => this.props?.onChange?.(selected, { type: "selectionChanged" }));
                            }} />}

                        {editMode && <div ref={this.wrapperRef}>
                            <div
                                style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                ref={(ref) => store.dispatch(showElement(ref))}>
                                <TextField
                                    onKeyDown={(e) => {
                                        if (e.key == 'Escape') {
                                            e.stopPropagation();
                                            this.stopEdition();
                                        }
                                    }}
                                    label={Trad("table_model")}
                                    autoFocus
                                    onFocus={e => e.target.select()}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    contentEditable={true}
                                    value={editedName}
                                    onChange={(e) => this.setState({
                                        editedName: e.target.value
                                    })} />
                                <IconAction icon="valid" tooltip={Trad("rename")} onClick={() => this.saveName()} />
                                <IconAction icon="undo" tooltip={Trad("back")} onClick={() => this.stopEdition()} />

                                <OutsideAlerter wrapperRef={this.wrapperRef} callback={target => {
                                    this.stopEdition();
                                }} />
                            </div>
                        </div>}
                        {dialogDelete && <GenericDialog
                            open={dialogRMOpen}
                            dialogTitle={Trad("confirmation")}
                            actions
                            submitClass={"custom_btn_danger"}
                            submitTitle={Trad("yes")}
                            startIcon={getIcon("delete")}
                            dialogContent={
                                <p>{Trad("delete_project")}</p>
                            }
                            submitAction={async () => {
                                this.remove();
                                this.setState({ dialogRMOpen: false });
                            }}
                            cancelAction={() => this.setState({ dialogRMOpen: false })}
                        />}

                    </div>

                    <div className="action-modeles-buttons">
                        <IconAction onClick={this.addNew} tooltip={Trad("add")} icon={"plus_custom_base"} />
                        {/* <IconAction onClick={ } tooltip={Trad("rename")} icon={"edit"} disabled={configuration.Default} /> */}
                        <SaveButton type={this.props.objectType.name} getConfiguration={this.props.getConfiguration} saveConfiguration={this.props.saveConfiguration} />
                        {/* <IconAction onClick={this.dupplicate} tooltip={Trad("duplicate")} icon={"copy"} /> */}
                        {/* <IconAction onClick={dialogDelete ? () => this.setState({ dialogRMOpen: true }) : this.remove} tooltip={Trad("remove")} icon={"delete"} className="custom_btn_secondary_remove" disabled={configuration.Default} /> */}

                        <MenuActions actions={[
                            { label: Trad("save_as"), onClick: this.dupplicate },
                            {
                                label: Trad("rename"),
                                onClick: () => {
                                    if (!configuration.Default) this.setState({ editMode: !editMode, editedName: configuration.Name })
                                    else Notify(Trad("cannot_change_default_conf"), "error");
                                    store.dispatch(show());

                                },
                                disabled: configuration.Default
                            },
                            {
                                label: Trad("delete"), onClick: dialogDelete ? () => this.setState({ dialogRMOpen: true }) : this.remove,
                                disabled: configuration.Default,
                                className: 'custom_btn_secondary_remove'
                            }
                        ]} />
                    </div>
                    <div className="clearfix" />
                </Row>
                {this.afterRender()}
            </>
        );
    }
}


export class ModeleCreatorBase<T extends ConfigurationBase> extends AModeleCreatorBase<ModeleCreatorBaseProps<T>, T> {
}

type MenuActionsProps = { actions: { label: string, onClick: () => void, disabled?: boolean, className?: string }[] }
function MenuActions({ actions }: MenuActionsProps) {

    const anchor = React.useRef<HTMLDivElement | null>(null);
    const [show, setShow] = React.useState(false);
    let isLocked = React.useRef(false);

    const onClick = () => {
        if (!isLocked.current)
            setShow(true);
    }

    return <div style={{ display: "inline-block" }}>
        <div ref={anchor}>
            <IconAction onClick={onClick} tooltip={Trad("menu")} icon={"more_vert_icon"} className="" />
            {show && <PopupMenu
                anchor={anchor}
                show={show}
                actions={actions}
                onItemClicked={() => setShow(false)}
                onClickOut={() => {
                    // trick pour éviter de réouvrir la popup sur click de l'IconAction
                    isLocked.current = true;
                    setTimeout(() => {
                        isLocked.current = false;
                    }, 200);
                    setShow(false)
                }} />}
        </div>
    </div>
}

export function PopupMenu({ anchor, show, actions, onItemClicked, onClickOut }) {

    let wrapperRef = React.useRef(null);
    return <Popup ref={wrapperRef} anchor={anchor.current} show={show} popupClass={"popup-content"}>
        <MenuList >
            {actions?.map((a, i) => (
                <MenuItem
                    key={`MenuActions-${i}-${a.label}`}
                    disabled={a.disabled}
                    onClick={() => {
                        a.onClick();
                        onItemClicked(a);
                    }}
                    className={a.className ?? ''}>
                    {a.label}
                </MenuItem>))}
        </MenuList>
        <OutsideAlerter wrapperRef={wrapperRef} callback={(target, event) => {
            onClickOut();
        }} />
    </Popup>
}
