
import * as React from "react";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradClassName } from "trad-lib";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { ref_GlobalAgreements } from "hub-lib/models/ref_GlobalAgreements.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";

type AgreementNames = {
    SupportName: string,
    AdvertisersNames: string[],
    AdvertiserGroupName: string
    MediaName: string
    GroupName: string
    CurrencyName: string
    BroadcastAreasNames: string[],
    PlacementsNames: string[],
    BrandsNames: string[],
    ProductsNames: string[],
    FormatsNames: string[]
}

type AgreementTooltipProps = {
    agreement: IRid['@rid'],
    documentType: string
}

type AgreementTooltipState = {
    value: ref_Agreements & AgreementNames;
    classes: ref_DiscountClasses[];
}

export function AgreementTooltip({ agreement, documentType }: AgreementTooltipProps) {

    if (!agreement)
        return <div></div>;

    const [state, setState] = React.useState<AgreementTooltipState>(null);
    React.useEffect(() => {
        if ((!state && agreement) || (state && state.value["@rid"] != agreement))
            Promise.resolve().then(async () => {
                const classes = await DiscountManager.GetDiscountClasses();
                let value = null;
                switch (documentType) {
                    case ref_Agreements.name:
                        [value] = await Client.searchVertexTyped(ref_Agreements, {
                            "@rid": agreement,
                            Active: [true, false],
                            properties: ["*",
                                "Support.Name as SupportName",
                                "Advertisers.Name as AdvertisersNames",
                                "AdvertiserGroup.Name as AdvertiserGroupName",
                                "Media.Name as MediaName",
                                "Group.Name as GroupName",
                                "Currency.Name as CurrencyName",
                                "BroadcastAreas.Name as BroadcastAreasNames",
                                "Placements.Name as PlacementsNames",
                                "Brands.Name as BrandsNames",
                                "Products.Name as ProductsNames",
                                "FormatsName as FormatsNames",
                                "Discounts"
                            ]
                        });
                        break;
                    case ref_GlobalAgreements.name:
                        [value] = await Client.searchVertexTyped(ref_GlobalAgreements, {
                            "@rid": agreement,
                            Active: [true, false],
                            properties: ["*",
                                "Media.Name as MediaName",
                                "Currency.Name as CurrencyName",
                                "BroadcastAreas.Name as BroadcastAreasNames",
                                "Discounts"
                            ]
                        });
                        break;
                    default:
                        break;
                }
                setState({ value, classes })
            })
    })


    const { value, classes } = state ?? {};
    const data = [
        { type: TradClassName(ref_Supports.name), value: value?.SupportName },
        { type: TradClassName(ref_Groups.name), value: value?.GroupName },
        { type: TradClassName(ref_AdvertiserGroups.name), value: value?.AdvertiserGroupName },
        { type: TradClassName(ref_Advertisers.name), value: value?.AdvertisersNames },
        { type: TradClassName(ref_Brands.name), value: value?.BrandsNames },
        { type: TradClassName(ref_Products.name), value: value?.ProductsNames },
        { type: Trad("format"), value: value?.FormatsNames },
        { type: Trad("placement"), value: value?.PlacementsNames },
        { type: TradClassName(ref_Currencies.name), value: value?.CurrencyName },
        { type: TradClassName(ref_BroadcastAreas.name), value: value?.BroadcastAreasNames },
        { type: TradClassName(ref_Media.name), value: value?.MediaName },
    ].filter(e => e.value)

    return <div>
        {value?.Name}
        <table className="tooltip-agreement">
            <tr>
                <th></th>
                <th></th>
            </tr>

            {data.map((d, i) => <tr key={`${d}:${i}`}>
                <td>{d.type}</td>
                <td className="number-cell">{Array.isArray(d.value) ? d.value.join(", ") : d.value}</td>
            </tr>)}
            <tr><td></td> </tr>
            <tr><td></td> </tr>
            {value?.Discounts.map((d, i) => (<tr key={`${agreement}:${i}`}>
                <td>{classes.find(c => c["@rid"] == d.DiscountClass).Name}</td>
                <td className="number-cell">{(d.IsRate ? `${d.CO?.Rate * 100}%` : d.CO?.Value) ?? 0}</td>
            </tr>))}
        </table>
    </div>
}