import * as React from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../layout/Loader';
import { Client } from 'hub-lib/client/client.bin'
import { SetCurrentLocale } from 'trad-lib';
import history from '../../utils/history'
import { store } from '../../redux/store';
import { initialize } from '../../redux/projectSlice';
import { initializeSchedulerTemplate } from '../../redux/gridSlice';

export default function withAuth(ComponentToProtect: any, permission?: () => boolean) {
    return class extends React.Component<any, any> {
        constructor(props: any) {
            super(props)
            this.state = {
                loading: true,
                redirect: false,
            };
        }

        async componentDidMount() {
            try {
                const res = (await Client.getStatus()).data
                // if (res.status !== 200)
                //     throw new Error(res.error);

                if (permission && !permission())
                    return history.push('/')


                localStorage.setItem('user', JSON.stringify(res.user));
                if (!store.getState().project.isInitialized)
                    store.dispatch(initialize());
                store.dispatch(initializeSchedulerTemplate());
                SetCurrentLocale(res.locale);
                this.setState({ loading: false })
            } catch (error) {
                console.error(error);
                localStorage.removeItem('user');
                this.setState({ loading: false, redirect: true });
            }
        }

        render() {
            const { loading, redirect } = this.state
            if (loading) {
                return <Loader />
            }

            const path = window.location.pathname;
            const encodedRedirection = encodeURIComponent(window.location.pathname + window.location.search);
            const redirectionArgs = (path.length > 1) ? `?redirect=${encodedRedirection}` : '';
            if (redirect) {
                return <Redirect to={`/login${redirectionArgs}`} exact />
            }
            return <ComponentToProtect {...this.props} />
        }
    }
}