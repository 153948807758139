import { Row, Ventilation, Table, Column } from "./types.bin";
import { compareArrays, duplicate, ePropertyOptionFilter, GetSubElement, hasOwnProperty, IsPromise, toArray, toDictionaryList, Typed } from "hub-lib/tools.bin";
import { Trad, TradProp } from "trad-lib";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { GetDateRanges, GetNbDays, IsIntersec, recurseAll } from "tools-lib";
import { DiscountOptions, eDiscountOptionValue } from "hub-lib/models/external.bin";
import { ADWProperty } from "hub-lib/types";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ReturnCurrencyProvider } from "hub-lib/business/ReturnCurrencyProvider.bin";
import { MetaDataProperty, eColumnType } from "hub-lib/models/types.bin";
import moment from "moment";
import { GetCellTemplate } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { EngineOptions } from "../../server/service/engine/engine.bin";

export enum eIndicateurType {
    kpi = "kpi",
    discount = "discount",
    info = "info",
    computed = "computed",
    join = "join"
}

export function ConvertToIndicateur(r: ADWProperty | Indicateur): Indicateur {
    if (!Object.values(eIndicateurType).includes(<any>r.type)) {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            valueType: r.type == "@rid" ? eKPIType.Rid : eKPIType.String,
            field: r.field,
            name: r.field ? TradProp(r.field) : r.field
        })
    }
    return <Indicateur>r;
}

export function CreateIndicateur(data: Indicateur): IndicateurBase {
    let ind: IndicateurBase = undefined;
    switch (data.type) {
        case eIndicateurType.kpi:
            ind = new IndicateurKPI();
            break;

        case eIndicateurType.discount:
            ind = new IndicateurDiscount();
            break;

        case eIndicateurType.info:
            ind = new IndicateurInfo();
            break;

        case eIndicateurType.computed:
            ind = new IndicateurComputed();
            break;
        case eIndicateurType.join:
            ind = new IndicateurJoin();
            break;
        default: {
            console.log(`data indicateur`, data);
            throw new Error("Not implemented CreateIndicateur");
        }
    }
    ind?.Load(data);
    return ind;
}

export type Indicateur = IndicateurInfo | IndicateurKPI | IndicateurDiscount | IndicateurComputed | IndicateurJoin;

type ColumnOptions = {
    name: string,
    isSchedulerHidden?: boolean
}

// ATTENTION, IGNORED PROPERTIES !!!!!!!!!!!!!!!!!!!
const ignoredProperties: (keyof ColumnOptions)[] = ['name', 'isSchedulerHidden']

export type ColumnIndicateur = ColumnOptions & Indicateur;

export function IndicateurToString(ind: Indicateur) {
    let cpy = duplicate(ind);
    if (cpy)
        ignoredProperties.forEach(p => delete cpy[p]);

    if (cpy) {
        let orderedCpy: any = {};
        Object.entries(cpy)
            .sort(([ka, va], [kb, vb]) => ka.localeCompare(kb))
            .forEach(([k, v]) => {
                orderedCpy[k] = v
            });

        recurseAll(orderedCpy, (rec) => {
            if (typeof rec === "object")
                ignoredProperties.forEach(p => delete rec[p]);
        });

        cpy = orderedCpy;
    }

    return JSON.stringify(cpy);
}

/**
 * Boolean, est ce que l'indicateur est en devise restituée
 */
export function IsIndicateurReturned(ind: Indicateur) {
    switch (ind.type) {
        case eIndicateurType.info:
            return false;
        case eIndicateurType.discount:
            return (<IndicateurDiscount>ind).options.isPriceReturned;
        case eIndicateurType.kpi:
            return (<IndicateurKPI>ind).options.isPriceReturned;
        default:
            break;
    }
    return false;
}


export enum eDirection {
    U = "U",
    "%Vertical" = "%Vertical",
    "%VerticalTotal" = "%VerticalTotal",
    "%Horizontal" = "%Horizontal",
    "%HorizontalTotal" = "%HorizontalTotal",
}

export class IndicateurBase {

    optionsBase?: { direction?: eDirection };

    /**
     * Indicateur type kpi|info
     */
    type: eIndicateurType;

    /**
     * Header name
     */
    name: string;

    /**
     * KPI type
     */
    valueType: eKPIType;

    /**
     * Field in the object
     */
    field?: string;

    Load?: (data: any) => void = (data: any) => {
        Object.entries(data).forEach(([k, v]) => {
            if (v && Object.keys(eIndicateurType).includes((<any>v).type)) {
                this[k] = this.Load(v);
            } else {
                this[k] = v;
            }
        });
    }

    GetSubElement?(data: any, prop: string) {
        return GetSubElement(data, prop);
    }

    Compute?(msg: ref_Messages[]): any {

        if (this.field === "Start")
            return new Date([...msg].sort((a, b) => new Date(a.Start).getTime() - new Date(b.Start).getTime())?.[0]?.Start)

        if (this.field === "End")
            return new Date([...msg].sort((a, b) => new Date(b.End).getTime() - new Date(a.End).getTime())?.[0]?.End)

        // if type is date, return the min date
        if (this.valueType == eKPIType.Date) {
            const values = msg.map(m => this.GetSubElement(m, this.field)).filter(v => v);
            const dates = values?.map?.(v => new Date(v));
            if (!dates?.length) return "";
            return new Date(Math.min(...dates.map(d => d.getTime())));
        }

        if (this.field === "NbDays")
            return msg.map((m) => Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24)))
        if (this.field === "NbWeeks")
            return msg.map((m) => Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24 * 7)))

        const map = c => c?.constructor?.name == 'RecordID' ? c.toString() : c

        const flatValues = [];

        const values = msg.map(m => this.GetSubElement(m, this.field));
        values.forEach(v => {
            if (Array.isArray(v)) v.forEach(e => flatValues.push(map(e)));
            else flatValues.push(map(v));
        });

        return Array.from(new Set(flatValues));
    }

}

export class IndicateurJoin extends IndicateurBase {
    type: eIndicateurType = eIndicateurType.join;

    indicateurs: Indicateur[];

    options?: {
        separator: string;
    }

    Compute?: (messages: ref_Messages[]) => any = async (messages: ref_Messages[]) => {
        try {
            const indicateurValues: string[] = [];
            const indicInstance = this.indicateurs.map(i => CreateIndicateur(i));

            const computedValues: any[][] = [];
            for (const msg of messages) {
                const indicateurValues: any[] = [];
                for (const indicateur of indicInstance) {
                    const value = await indicateur.Compute([msg]);
                    indicateurValues.push(value);
                }
                if (!computedValues.some(cv => JSON.stringify(cv) == JSON.stringify(indicateurValues)))
                    computedValues.push(indicateurValues);
            }
            return computedValues;
        } catch (error) {
            console.error(error);
            return "";
        }
    }
}
export class IndicateurComputed extends IndicateurBase {

    type: eIndicateurType = eIndicateurType.computed;

    indicateurs: Indicateur[];
    operator: "+" | "-" | "/" | "*" | "%" | "=" | "|";

    options?: {
        isPriceReturned?: boolean;
        round?: 'ceil'
        // Eventuel multiplicateur
        rate?: number;
    };

    Compute?: (messages: ref_Messages[]) => any = (messages: ref_Messages[]) => {
        try {

            if (this.valueType == eKPIType.Price)
                if (new Set(messages?.map(m => m.Currency) ?? []).size != 1)
                    return '-';

            const indicateurValues: number[] = [];
            for (const indicateur of this.indicateurs) {
                const instanceIndic = CreateIndicateur(indicateur);
                const indicateurValue = instanceIndic.Compute(messages);
                if ((indicateurValue != "" && !isNaN(indicateurValue)) || this.operator === "=")
                    indicateurValues.push(indicateurValue);
            }

            let value = 0;
            switch (this.operator) {

                case "%":
                    if (indicateurValues.length != 2 || indicateurValues[1] === 0) value = 0;
                    else value = ((indicateurValues[0] / indicateurValues[1]) - 1);
                    break;

                case "/":
                    if (indicateurValues.length != 2 || indicateurValues[1] === 0) value = 0;
                    else value = indicateurValues[0] / indicateurValues[1];
                    break;

                case "=":
                    if (indicateurValues.length != 2) value = -1;
                    else if ((indicateurValues?.[0]?.[0] == undefined || indicateurValues?.[1]?.[0] == undefined) && (indicateurValues?.[0]?.[0] || indicateurValues?.[1]?.[0])) {
                        if (indicateurValues?.[0]?.[0]) value = 0;
                        else value = -1;
                    }
                    else if (indicateurValues?.[0]?.[0] == undefined || indicateurValues?.[1]?.[0] == undefined) value = -1;
                    else value = (indicateurValues?.[0]?.[0] === indicateurValues?.[1]?.[0]) ? 1 : 0;
                    break;

                case "|":
                    value = indicateurValues.reduce((a, b) => a | b, 0);
                    break;
                case "-":
                    value = indicateurValues.reduce((a, b) => a - b, (indicateurValues?.[0] ?? 0) * 2);
                    break;

                default:
                    value = indicateurValues.reduce((a, b) => a + b, 0);
                    break;
            }
            switch (this.options?.round) {
                case "ceil":
                    value = Math.ceil(value);
                    break;

                default:
                    break;
            }
            return value * (this.options?.rate || 1);
        } catch (error) {
            console.error(error);
            return 0;
        }
    }
}

export class IndicateurDiscount extends IndicateurBase {
    /**
     * Info type
     */
    type: eIndicateurType = eIndicateurType.discount;

    options: DiscountOptions;

    Compute?: (msg: ref_Messages[]) => any = (msg: ref_Messages[]) => {

        const promises: Promise<any>[] = [];

        /** On ne prend pas les totaux en % pour le moment */
        if (this.valueType === eKPIType.Percent && msg.length !== 1)
            return "";

        // récupère la valeur du discount
        const discounts = [];
        for (const m of msg) {

            let value: number = 0;
            if (this.options.isPriceBound)
                value = m.KPIs[`Bound:${this.options.rid}:${this.options.type == 'CO' ? eColumnType.DiscountValueBound : eColumnType.DiscountFOValueBound}`];
            else {
                if (this.options.barter)
                    value = DiscountManager.GetValue(m.BarterPercents, this.options.rid, this.options.type, this.options.value);
                else {
                    const cascade = m[`cache-cascade`] ?? DiscountManager.UpdateCascadeSync(m);
                    m[`cache-cascade`] = cascade;
                    const foundDiscount = cascade[this.options.type].find(d => d.Discount.DiscountClass == this.options.rid)?.Discount;
                    if (this.options.value == eDiscountOptionValue.Rate) value = foundDiscount?.[this.options.type]?.Rate ?? 0;
                    else value = foundDiscount?.[this.options.type]?.Value ?? 0;
                }
            }

            if (!isNaN(value) && this.options.isPriceReturned) {
                const res = ToReturnedCurrency(m, value);;
                if (IsPromise(res))
                    promises.push(Promise.resolve().then(async () => {
                        discounts.push((await res).value);
                    }));
                else discounts.push((res as ReturnedValue).value)
            } else discounts.push(value)
        }

        const compute = () => discounts.filter(d => d).reduce((a, b) => a + b, 0) ?? 0;
        if (promises.length)
            return Promise.all(promises).then(compute)
        return compute();
    }
}
type formaterMoment = { type: "moment", format?: string, value?: string, periodicity?: string, trad?: typeof Trad }
type formaterTrad = { type: "trad" }
type value = { type: "moment" }

export class propertyOption {
    formater?: formaterMoment | formaterTrad // On peut ajouter des "|" pour ajouter de nouveaux formaters
    value?: value
    priorityToField?: string
    subProperty?: string
    subPropertyFallback?: string
    MetaData?: MetaDataProperty
    rate?: Number
    match?: ({ subProperty: string, value: any } | { subProperty: string, filter: ePropertyOptionFilter })[]
}

export class IndicateurInfo extends IndicateurBase {
    /**
     * Locker on Info type
     */
    type: eIndicateurType = eIndicateurType.info;

    options?: propertyOption;
    constructor(e?: Partial<IndicateurInfo>) {
        super();
        if (e) Object.entries(e).forEach(([k, v]) => this[k] = v);
    }

    GetSubElement?= (data: any, prop: string) => {
        const { priorityToField, subProperty, subPropertyFallback, MetaData } = this.options ?? new propertyOption();
        let result = undefined;
        if (priorityToField) {
            const valuePriority = GetSubElement(data, priorityToField, this.options?.match);
            result = Boolean(valuePriority) ? valuePriority : GetSubElement(data, prop, this.options?.match);
        }
        else {

            let propertyName = subProperty ? `${prop}.${subProperty}`.replace(/\./g, '') : prop;
            if (MetaData?.name)
                propertyName = MetaData.name;

            result = GetSubElement(data, propertyName, this.options?.match);
            if (!result && subPropertyFallback)
                result = GetSubElement(data, `${prop}.${subPropertyFallback}`.replace(/\./g, ''), this.options?.match);
        }

        return result;
    }

    Compute?: (msg: ref_Messages[]) => any = (msg: ref_Messages[]) => {
        let value = super.Compute(msg);

        if (this.options?.value) {
            switch (this.options.value.type) {
                case "moment":
                    const dateVal = moment(new Date(value));
                    if (dateVal.isValid())
                        value = dateVal.valueOf()
                    break;
                default:
                    break;
            }
        }
        if (this.options?.formater) {
            switch (this.options.formater.type) {
                case "moment":
                    const dateVal = moment(new Date(value));

                    if (dateVal.isValid())
                        if (this.options.formater.format) {
                            value = dateVal.format(this.options.formater.format);
                            if (this.options.formater.format === "MMMM") {
                                value = Trad("month_" + dateVal.month())
                            }
                            if (this.options.formater.format === "WW") {
                                value = Trad("week_very_short") + value
                            }
                        } else if (this.options.formater.periodicity === "semester") {
                            value = "S" + (parseInt(dateVal.format('Q')) > 2 ? 2 : 1)
                        } else if (this.options.formater.periodicity === "datedWeek") {
                            value = Trad("week_of") + ' ' + GetCellTemplate(ePropType.Date)(dateVal.startOf("week"))
                        }

                        else value = "";
                    break;
                case "trad":
                    //const text = toArray(value).filter(Boolean).map(v => Trad(v)).join(", ");
                    //console.log(value, text);
                    //value = text;
                    break;
                default:
                    break;
            }
        }




        if (Array.isArray(value) && value.length === 1)
            return value[0];

        return value;
    }
}

export class IndicateurKPI extends IndicateurBase {

    /**
     * Locker on KPI type
     */
    type: eIndicateurType = eIndicateurType.kpi;

    /**
     * More info about the KPI, not use for now
     */
    options?: {
        rid: rid,
        isPriceReturned?: boolean,
        isPriceBound?: boolean,
        filter?: Partial<ref_Messages>
        filterIgnore?: Partial<ref_Messages>,
        forceValue?: number
    }

    Compute?: (msg: ref_Messages[]) => any = (_msg: ref_Messages[]) => {

        const msg = _msg.filter(m => Boolean(m));
        const promises: Promise<any>[] = [];
        const kpis = [];

        if (this.valueType == eKPIType.Price)
            if (new Set(msg.map(m => m?.Currency)).size != 1)
                return '-';

        for (const m of msg) {
            const field = this.options?.isPriceBound ? `Bound${this.field}` : this.field

            let continueLoop: boolean = false;

            /** On ignore les messages qui ne remplissent pas la condition de filter */
            if (this.options?.filter) {
                for (const [k, v] of Object.entries(this.options.filter)) {
                    /** Le KPI est considéré à 0 s'il ne répond pas un filtre */
                    if (m[k] != v) {
                        continueLoop = true;
                        continue;
                    }
                }
            }

            /** On ignore les messges qui remplissent la condition du filterIgnore */
            if (this.options?.filterIgnore) {
                for (const [k, v] of Object.entries(this.options.filterIgnore)) {
                    /** Le KPI est considéré à 0 s'il ne répond au filtre Ignore */
                    if (m[k] === v) {
                        continueLoop = true;
                        continue;
                    }
                }
            }

            if (continueLoop)
                continue;

            let value = this.options?.forceValue ?? m.KPIs[field] ?? 0

            if (!isNaN(value) && value != 0 && this.options?.isPriceReturned) {
                const res = ToReturnedCurrency(m, value);
                if (IsPromise(res))
                    promises.push(Promise.resolve().then(async () => {
                        kpis.push((await res).value);
                    }));
                else kpis.push((res as ReturnedValue).value);
            } else kpis.push(value);
        }

        const compute = () => kpis.length ? kpis.reduce((a, b) => a + b, 0) : 0;
        if (promises.length)
            return Promise.all(promises).then(compute)
        return compute();
    }
}

export const DefaultReturnCurrencyProvider = new ReturnCurrencyProvider(2000);

type ReturnedValue = { value: number, code: string };
export function ToReturnedCurrency(m: ref_Messages, value: number, _mgr?: ReturnCurrencyProvider): ReturnedValue | Promise<ReturnedValue> {
    const apply = (rateRes: { rate: number, currency: rid }) => {
        let code: string = undefined;
        if (rateRes) {
            value *= (rateRes.rate || 1);
            code = rateRes.currency;
        }
        return { value, code };
    }

    const currMgr = _mgr ?? DefaultReturnCurrencyProvider;
    if (currMgr.HasExpired())
        return currMgr.GetCurrency(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End).then(apply);
    return apply(currMgr.GetCurrencySync(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End));
}

export function CreateIndicateurInfo(field: string, valueType: eKPIType, name?: string): IndicateurInfo {
    return {
        name: name ?? TradProp(field, ref_Messages),
        valueType,
        field,
        type: eIndicateurType.info
    };
}

export function CreateIndicateurKPI(field: string, valueType: eKPIType, name?: string): IndicateurKPI {
    return {
        name: name ?? Trad(field),
        valueType,
        field,
        type: eIndicateurType.kpi
    };
}

export type Aggregator<T> = (data: T[], ind: Indicateur) => any;
export type DimensionValueGetter<T> = (data: T, dimension: string) => any;
export type DimensionResolver<T> = (data: T, dimension: string) => any;

export class EngineArgs { propRows: Indicateur[]; propColumns: string[]; ind: Indicateur[] }
export abstract class AEngine<T> {

    public Key: string;

    constructor(prototype?: new () => T) {
        this.Key = prototype?.name ?? "default";
    }

    public abstract split(data: T, ratio: number): Promise<T>;
    public abstract aggregator(data: T[], ind: Indicateur): Promise<any>;
    //protected abstract dimensionValueGetter(data: T, dimension: (ADWProperty | Indicateur)): any;

    protected async dimensionValueGetter(doc: T, dim: (ADWProperty | Indicateur)) {

        let v = undefined;
        try {
            const indicateur = CreateIndicateur(<Indicateur>dim);
            v = await indicateur.Compute([<any>doc]);
        } catch (error) {
            v = GetSubElement(doc, dim.field);
        }

        if (v === undefined) return null;
        return v;
    }

    //private dimensionResolver: DimensionResolver<T>;

    async Aggregate(data: T[], conf: EngineArgs, dates: { start: Date, end: Date }, options?: EngineOptions): Promise<Table<T>> {

        const table: Table<T> = new Table<T>();
        table.DocumentType = this.Key;
        table.Ventilations = conf.propRows;
        let rows: Row<T>[] = [];

        /** table des messages, pas de ventilation */
        const flatMode = false;
        const dims: Indicateur[] = !conf.propRows?.length ?
            [Typed<IndicateurInfo>({
                field: "@rid",
                type: eIndicateurType.info,
                name: "@rid",
                valueType: eKPIType.Rid
            })] : conf.propRows;

        console.log(`[Ventilate] Start ...`);
        const time4129 = new Date().getTime();
        let rowVentils: Ventilation<T>[] = await this.ventilate(data, dims);
        const _time4129 = new Date().getTime();
        console.log(`[Ventilate] Elapsed ${_time4129 - time4129}ms`);

        const total = new Ventilation<T>();
        total.Children = rowVentils;

        if (!hasOwnProperty(options, 'hideDetailsData') || !options.hideDetailsData) total.Data = [...data];
        else total.Data = [];

        total.Dimension = Typed<IndicateurInfo>({
            field: "@class",
            type: eIndicateurType.info,
            name: Trad("Total"),
            valueType: eKPIType.String
        });
        total.Value = "Total";
        total.Formated = "Total";

        for (const r of [total]) {
            let row = new Row<T>();
            Object.assign(row, r);
            // let dataCols = (await this.ventilate(row.Data, /*conf.propColumns*/[]))?.map(v => v.getFlat());
            // row.DataColumns = dataCols?.map(d => {
            //     let n = conf.ind.length;
            //     let array = new Array(n);
            //     for (let i = 0; i < n; i++)
            //         array[i] = d;
            //     return array;
            // })?.reduce((a, b) => a.concat(b));
            rows.push(row);
        }

        let colVentils: Ventilation<T>[] = await this.ventilate(data, /*conf.propColumns*/[]);
        let recurseColumns = (vent: Ventilation<T>[]) => {
            let cols: Column[] = [];
            vent?.forEach(v => {
                let col = new Column();
                col.Id = v.Dimension;
                col.Label = TradProp(v.Dimension.field);
                cols.push(col);
                if (v.Children) col.Children = recurseColumns(v.Children);
                else {
                    col.Children = conf.ind.map(k => {
                        const newcol = new Column();
                        newcol.Id = { field: k.field, type: null };
                        newcol.Label = Trad(k.field);
                        return newcol;
                    })
                }
            })
            return cols;
        };

        let columnsFrozen: Column[] = conf.propRows.map(r => {
            let col = new Column();
            col.Id = r;
            col.Label = TradProp(r.field);
            return col;
        })

        let columns: Column[] = recurseColumns(colVentils);
        table.Columns = [...columnsFrozen, ...columns];

        if (flatMode) {
            rows = rows[0].Children;
        }

        table.Rows = rows;
        table.Indicateurs = conf.ind;

        if (options?.temporalTotal) {
            const ranges = GetDateRanges(dates.start, dates.end, options.temporalTotal);
            table.TimeVentilations = {
                Granularity: options.temporalTotal,
                Field: "Gross",
                Ranges: ranges.map(r => {

                    return {
                        Start: r.start,
                        End: r.end,
                        Row: new Row(
                            Typed<IndicateurInfo>({
                                field: "@rid",
                                type: eIndicateurType.info,
                                name: "@rid",
                                valueType: eKPIType.Rid
                            })
                            , data.filter((d: any) => {
                                const startMsg = new Date(d.Start);
                                return IsIntersec(r.start, r.end, startMsg, startMsg)
                            }))
                    }
                })
            }
        }

        console.log(`[Aggregate] Start...`);
        const time7268 = new Date().getTime();
        await this.aggregate(table);
        const _time7268 = new Date().getTime();
        console.log(`[Aggregate] Elapsed ${_time7268 - time7268}ms`);

        /** remove "@rid" column, in case of includeDetails */
        if (table.Columns.some(c => c.Id.field === "@rid")) {
            table.Columns = table.Columns.filter(c => c.Id.field !== "@rid");
        }

        return table;
    }

    private async ventilate(data: T[], dims: Indicateur[]): Promise<Ventilation<T>[]> {

        let ventils: Ventilation<T>[] = [];
        let clone = [...dims];
        let first = clone.shift();
        if (!first) return undefined;

        //let dico = toDictionaryList(data, (e) => this.dimensionValueGetter(e, first));
        type TIndexer = { key: string | string[], values: T[] };
        const dico: TIndexer[] = [];

        for (const _d of data) {
            const _key = await this.dimensionValueGetter(_d, first);
            const keys = Array.isArray(_key) ? _key : [_key];

            const d = keys.length > 1 ? await this.split(_d, keys.length - 1) : _d;

            for (const key of keys) {

                // 1rt: found exact ventilation
                let found: TIndexer = Array.isArray(key) ?
                    dico.find(e => Array.isArray(e.key) && compareArrays(e.key, key)) :
                    dico.find(e => !Array.isArray(e.key) && e.key == key)
                if (found) found.values.push(d);
                else {
                    found = { key, values: [d] }
                    dico.push(found);
                }

                // 2nd: found where it can fit
                const foundContained = Array.isArray(key) ?
                    dico.filter(e => Array.isArray(e.key) && !compareArrays(e.key, key) && key.every(k => e.key.includes(k))) :
                    dico.filter(e => Array.isArray(e.key) && e.key.includes(key));
                foundContained.forEach(f => f.values.push(d));

                // 3rd: if array, take all previou key that fit in
                if (Array.isArray(key)) {
                    const toInclude = dico.filter(e => (e != found) && ((!Array.isArray(e.key) && found.key.includes(e.key)) || (Array.isArray(e.key) && e.key.every(k => found.key.includes(k)))))
                    toInclude.forEach(e => e.values.forEach(v => {
                        if (!found.values.includes(v))
                            found.values.push(v);
                    }))
                }
            }
        }

        for (const { key, values } of dico) {
            const ventil = new Ventilation<T>();
            ventil.Value = key;
            ventil.Dimension = { ...first, field: first.field.replace("Returned", "") };
            ventil.Data = values;
            ventil.Children = await this.ventilate(values, clone);
            ventils.push(ventil);
        }

        return ventils;
    }

    private async aggregate(table: Table<T>) {
        const columnPromises = new Set<string>();
        const allPromises: Promise<any>[] = [];
        const addValue = (value, ind, row) => {
            row.ValuesTotal.push({
                Formated: '',
                Value: value,
                Indicateur: ind,
                Type: "cell"
            });
        };

        const tableIndicateurs = table?.Indicateurs?.map?.(i => CreateIndicateur(i)) ?? [];
        const recurse = (rows: Row<T>[]) => {
            if (rows)
                for (const row of rows) {
                    row.ValuesTotal = [];
                    for (const ind of tableIndicateurs) {
                        const res = this.aggregator(row.Data, ind);
                        if (IsPromise(res)) {
                            columnPromises.add(ind.name);
                            allPromises.push(res.then(v => addValue(v, ind, row)))
                        }
                        else addValue(res, ind, row)
                    }
                    recurse(row.Children)
                }
        }
        recurse(table.Rows);
        recurse(table.TimeVentilations?.Ranges?.map(r => r.Row));

        const time6442 = new Date().getTime();
        await Promise.all(allPromises);
        const _time6442 = new Date().getTime();
        console.log(`[PERF] [aggregate] Promises: (${allPromises.length}) for: ${Array.from(columnPromises).join(', ')}  ${_time6442 - time6442}ms`);
    }
}

export class EngineManager {

    private dico: { [prop: string]: AEngine<any> } = {};
    private static mgr: EngineManager;

    static GetInstance(): EngineManager {
        if (!EngineManager.mgr)
            EngineManager.mgr = new EngineManager();
        return EngineManager.mgr;
    }

    Register(engine: AEngine<any>) {
        this.dico[engine.Key] = engine;
    }

    Get(type: string): AEngine<any> {
        let engine = this.dico[type];
        if (!engine) {
            engine = this.dico["default"];
        }
        return engine;
    }
}