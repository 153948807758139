
import * as React from 'react'

import Loader from '../layout/Loader'
import Typography from '@material-ui/core/Typography'
import 'date-fns'
import Drawer from '@material-ui/core/Drawer'
import { CrossedTableConf, TConfState } from './CrossedTableConf.bin'
import { Client } from 'hub-lib/client/client.bin'
import { CrossedTableConfigurable } from '../ConfigurableComponents/CrossedTableConfigurable.bin'
import { Trad } from 'trad-lib'
import { CustomGroupButton } from '../ConfigurableComponents/CustomGroupButton.bin'
import { IRid } from 'hub-lib/models/IRid.bin'
import { CustomButton } from '../ConfigurableComponents/CustomButton.bin'
import { getIcon } from "adwone-lib/index"
import { IndicateursProvider } from 'hub-lib/IndicateursProvider'
import { ExportButtonProps } from '../../styles/theme'
import { ConfigurationPanelContainer } from '../VertexGrid/Generic/ToolbarAdw'
import { ConfigurationPanel } from '../VertexGrid/Messages/ConfigurationPanel'

export class TProps {
    onRef?: (ref: GenericDocument) => void;
    hierarchical?: boolean = undefined;
    rid?: string;
    name?: string;

    configuration?: TConfState;
    onConfinitialized?: (conf: TConfState) => void;
    onConfChanged?: () => void;
}

export class CrossedTableState {

    configVisible: boolean = false;

    infos: string[] = [];

    components: JSX.Element[] = [];
    confComponents: JSX.Element[] = [];

    crossedTable: CrossedTableConfigurable;
}

/**
 * Composant graphique du tri croisé
 */
export class GenericDocument extends React.Component<TProps, CrossedTableState> {

    constructor(props: any) {
        super(props);
        if (this.props.onRef)
            this.props.onRef(this);

        let state = new CrossedTableState();
        this.state = state;
    }

    async componentDidMount() {

        this.updateHierarchie();
        let configuration = this.props.configuration || await CrossedTableConf.InitializeNewConfiguration();
        configuration.crossedTable.indicateurs = (await IndicateursProvider.GetInstance().Provide()).map(i => i.indicateur);

        const dims = await Client.getDimensions();
        configuration.comboChoices = [{ field: "*", type: null }, ...dims];
        this.props?.onConfinitialized(configuration);

        let cmp = new CrossedTableConfigurable(configuration, this.props.rid);
        cmp.onConfChanged = this.props.onConfChanged;
        this.state.components.push(cmp.getMainComponent());
        this.state.confComponents.push(cmp.getConfComponent());

        this.setState({ crossedTable: cmp });
    }

    updateHierarchie = () => {

        if (!this.props.rid) return;

        Client.getObjects('Referentials', { "@rid": this.props.rid })
            .then((res) => {
                let element: IRid = res?.data?.results?.[0];
                if (element) {
                    console.log(`getObjects`)
                }
            });
    }

    render() {

        if (!this.state) return <Loader />

        return (
            <>
                <ConfigurationPanelContainer>
                    <ConfigurationPanel
                        elements={[{
                            type: "icon",
                            title: () => Trad("filters"),
                            icon: "settings",
                            element: <>{this.state.confComponents}</>
                        }, {
                            type: "icon",
                            title: () => Trad("export"),
                            icon: "download",
                            element: <CustomGroupButton
                                {...ExportButtonProps()}
                                buttons={[
                                    { Label: "CSV", onClick: () => this.state.crossedTable?.ExportCsv() },
                                    { Label: Trad("formated"), onClick: () => this.state.crossedTable?.ExportFormated() }
                                ]}
                                style={{ float: 'right' }} />
                        }]} />
                </ConfigurationPanelContainer>

                {this.state.components.map((cmp, idx) => <div key={`componentn-${idx}`}>{cmp}</div>)}
            </ >
        );
    }
}