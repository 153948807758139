import * as React from "react";

import { AdwTelerikGrid } from "../../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { SortDescriptor } from "@progress/kendo-data-query";
import { AdwRow, ADWColumn } from "adwone-lib/index";
import Loader from "../../../layout/Loader";
import { Trad } from "trad-lib";
import { Log } from "hub-lib/models/Log.bin"
import { FiltersComponent, TConfig } from "./FiltersComponent.bin"
import { propertyOf } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetCellTemplate } from "format-lib/index.bin";
import { GridDetailRow } from "@progress/kendo-react-grid";
import ReactJson from 'react-json-view'
import { Client } from "hub-lib/client/client.bin";
import { eLayerType, LogBase } from "tools-lib";
import { CreateIndicateurInfo, IndicateurInfo } from "adwone-engine/index.bin";
import { logUserArg } from "hub-lib/models/requests.bin";
import { GenericPopover } from "../../../ConfigurableComponents/GenericPopover.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ConfigurationPanel } from "../../Messages/ConfigurationPanel";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from "../../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";

class TState {
    grid?: VertexGrid<Log>;
    sort?: SortDescriptor[];
}

let anchorId = 0;

let logColumns: IndicateurInfo[] = undefined;
export class LogsGrid extends React.Component<any, TState> {

    constructor(props: any) {
        super(props);
        logColumns = [
            CreateIndicateurInfo(propertyOf<Log>("@rid"), eKPIType.String, Trad("id")),
            CreateIndicateurInfo(propertyOf<Log>("User"), eKPIType.String, Trad("user")),
            CreateIndicateurInfo(propertyOf<Log>("Date"), eKPIType.String, Trad("date")),
            CreateIndicateurInfo(`${propertyOf<Log>("Report")}.${propertyOf<logUserArg>("Category")}`, eKPIType.String, Trad("category")),
            CreateIndicateurInfo(`${propertyOf<Log>("Report")}.${propertyOf<logUserArg>("Action")}`, eKPIType.String, Trad("action")),
            CreateIndicateurInfo(`${propertyOf<Log>("Report")}.${propertyOf<logUserArg>("url")}`, eKPIType.String, Trad("URL"))]
        this.state = new TState();
    }

    componentDidMount() {
        const columns: ADWColumn<Log>[] = [];
        const hiddenProperties: string[] = [];
        hiddenProperties.push(propertyOf<Log>("Report"))
        hiddenProperties.push(propertyOf<Log>("CallID"))
        hiddenProperties.push(propertyOf<Log>("checked"))

        /** Open */
        const openLogPath: any = "openLog";
        const openLog = new ADWColumn<Log>(" ", openLogPath, ePropType.String, false);
        openLog.width = 100;
        openLog.cellValue = (cellValue: any, dataItem?: AdwRow<Log>) => {
            const anchorKey = `anchor-${anchorId++}`;
            return <div id={anchorKey}>
                <GenericPopover anchorParent={anchorKey} icon="zoom">
                    <ReactJson src={dataItem.dataItem} displayDataTypes={false} sortKeys displayObjectSize={false} />
                </GenericPopover></div>
        };
        columns.push(openLog);

        /** Rid du log */
        const ridCol = new ADWColumn<Log>(Trad("id"), propertyOf<Log>("@rid"), ePropType.String, false);
        ridCol.width = 250;
        columns.push(ridCol);

        /** Category */
        const categoryPath: any = "Report.Category";
        const categoryCol = new ADWColumn<Log>(Trad("category"), categoryPath, ePropType.String, false);
        columns.push(categoryCol);

        /** Action */
        const actionPath: any = "Report.Action";
        const actionCol = new ADWColumn<Log>(Trad("action"), actionPath, ePropType.String, false);
        actionCol.width = 250;
        columns.push(actionCol);

        /** Date format */
        const columnDate = new ADWColumn<Log>(Trad(("date") as any), propertyOf<Log>("Date"), ePropType.Date, false);
        columnDate.cellValue = (_cellValue: any, dataItem?: AdwRow<Log>) => {
            let cellValue: any = dataItem.dataItem.Date;
            if (!cellValue || cellValue === "") return "";
            const str = GetCellTemplate(ePropType.Datetime)(cellValue);
            return str;
        }
        columnDate.indexOrder = 1
        columnDate.width = 300
        columns.push(columnDate);

        hiddenProperties.push(propertyOf<Log>("Date"));

        const grid = new VertexGrid<Log>({
            objectPrototype: Log,
            devMode: false,
            columns,
            width: {
                "CallID": 250,
                "Layer": 200,
                "User": 300
            },
            order: ["@rid", "Layer", categoryPath, actionPath, "User", "Date", "CallID"],
            vertexParams: {
                ...this.removeAll(this.props.config)
            },
            hiddenProperties,
        });
        this.setState({
            grid
        })
    }
    removeAll = (config: TConfig) => {
        let newParams: any = { ...config }
        for (let [key, value] of Object.entries(config)) {
            if (value === Trad("all"))
                delete newParams[key]

            if (value && Array.isArray(value) && !value.length)
                delete newParams[key]
        }
        return newParams
    }
    onConfChange = (config: TConfig) => {
        this.props.onConfChange(config)
        let vertexParams = this.state.grid.props.vertexParams
        this.state.grid.props.vertexParams = this.removeAll({ ...vertexParams, ...config });
        this.state.grid.UpdateRows();
    }

    exportUserLogs = () => {
        const { Start, End } = this.state.grid.props.vertexParams;
        Client.downloadExport("formated", {
            type: 'table',
            document: Log.name,
            columns: logColumns,
            filter: {
                [propertyOf<LogBase>("Layer")]: eLayerType.User,
                Start,
                End
            }
        }, Trad("user_logs"))
    }


    exportUserUsages = () => {
        const { Start, End } = this.state.grid.props.vertexParams;
        Client.downloadExport("formated", {
            type: 'table',
            document: Log.name,
            columns: logColumns,
            filter: {
                [propertyOf<LogBase>("Layer")]: eLayerType.User,
                "Report.Category": ['session', 'usage'],
                Start,
                End
            }
        }, Trad("user_logs"))
    }

    render() {

        if (!this.state.grid)
            return <Loader />;
        const confComponent = <ConfigurationPanel
            elements={[{
                type: "icon",
                title: () => Trad("filters"),
                icon: "filterAlt",
                element: <FiltersComponent onConfChange={this.onConfChange} config={this.props.config} />
            }, {
                type: "icon",
                title: () => Trad("export"),
                icon: "download",
                element: <>
                    <div className="adw-row">
                        <CustomButton
                            Label={Trad("user_logs")}
                            style={{ float: "right" }}
                            className="custom_btn_primary"
                            onClick={() => this.exportUserLogs} />
                    </div>
                    <div className="adw-row">
                        <CustomButton
                            Label={Trad("usages_logs")}
                            style={{ float: "right" }}
                            className="custom_btn_primary"
                            onClick={() => this.exportUserUsages} />
                    </div>
                </>
            }
            ]} />
        return (
            <div style={{ width: '100%' }}>
                <ToolbarAdw>
                    <ConfigurationPanelContainer>
                        {confComponent}
                    </ConfigurationPanelContainer>
                    <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom
                            elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("logs_mgt") }
                            ]} />
                    </BreadcrumbsCustomContainer>
                </ToolbarAdw>
                <AdwTelerikGrid
                    grid={this.state.grid}
                    uneditable
                    hideToolbar
                />
            </div>
        )
    }
}

class DetailComponent extends GridDetailRow {
    render() {
        const report = this.props.dataItem.dataItem.Report;
        console.log(report)
        return (
            <>
                <ReactJson src={report} displayDataTypes={false} sortKeys displayObjectSize={false} />
            </>
        );
    }
}