import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_PersonsId = rid;

export class ref_Persons extends V implements Referentials {
	"@rid"?: ref_PersonsId;
	FirstName: string;
	LastName: string;
	Active: boolean;

}