import * as React from "react";
import { getIcon } from "adwone-lib/index";
import { Trad } from "trad-lib";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import Loader from "../../layout/Loader";
import { Client } from "hub-lib/client/client.bin";
import { ContainerComponent } from "../Generic/ContainerComponent";
import { FormatDate } from "tools-lib";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { LinkedEstimate, ref_Estimates } from "hub-lib/models/ref_Estimates.bin";

export const EstimatesLinkedDetail = (props: GridDetailRowProps & { hideMessage?: boolean }) => {
    const [documents, setDocuments] = React.useState<LinkedEstimate[]>(null);

    const findDocuments = async () => {
        const documents = await Client.getLinkedHierchy(ref_Estimates.name, props.dataItem.dataItem);
        setDocuments(documents);
    };

    React.useEffect(() => {
        if (!documents) {
            findDocuments();
        }
    });

    if (!documents) {
        return (
            <div
                style={{
                    height: "40px",
                    position: "relative",
                    width: "40px",
                    overflow: "hidden",
                }}
            >
                <Loader size="small" center />
            </div>
        );
    }

    if (!documents.length) {
        return !props.hideMessage ? <span>{Trad("no_sellsy_linked")}</span> : <></>;
    }

    return (
        <div className="sellsy-box-container">
            {documents.map((d) => (
                <ContainerComponent
                    title={`${Trad(d.Type)}`}
                    className="auto-height no-padding no-margin sellsy-box"
                >
                    {d.Code && <span>{d.Code}</span>}
                    <span style={{margin: "5px 0"}}>
                        <span className={`status_estimate_${d.Status}`}>{Trad(d.Status)}</span>{" "}
                        {d.PdfLink && <CustomIconButton
                            className="primary_color sellsy-pdf"
                            disabled={!d.PdfLink}
                            onClick={() => window.open(d.PdfLink, "_blank")}
                        >
                            {getIcon("pdf")}
                        </CustomIconButton>}
                    </span>
                    {d.Amount && d.Currency && <span>{`${Trad("of", true)} ${d.Amount} ${d.Currency}`}</span>}
                    {d.Created && <span>{`${Trad("created_the")} ${FormatDate(d.Created)}`}</span>}
                </ContainerComponent>
            ))}
        </div>
    );
};
