import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiscountManager, netType, netTypes } from 'hub-lib/business/DiscountManager.bin';
import { eAgreementConfig, ref_Agreements } from 'hub-lib/models/ref_Agreements.bin';
import { ref_GlobalAgreements } from 'hub-lib/models/ref_GlobalAgreements.bin';
import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import { clone, compareObjects } from 'hub-lib/tools.bin';

export interface AgreementEditorState {
    agreementBeforeUpsert: ref_Agreements | ref_GlobalAgreements;
    agreement: ref_Agreements | ref_GlobalAgreements;
    applicationConfig: netType[];
    hasChanged: {
        CO?: boolean;
        FO?: boolean;
        FOS?: boolean;
    }
}

const initialState: AgreementEditorState = {
    agreementBeforeUpsert: null,
    agreement: null,
    applicationConfig: netTypes,
    hasChanged: {
        CO: false,
        FO: false,
        FOS: false,
    }
}

export const agreementEditorSlice = createSlice({
    name: 'agreementEditor',
    initialState: initialState,
    reducers: {
        setAgreement: (state, action: PayloadAction<AgreementEditorState['agreement']>) => {
            const newAgreement = clone(action.payload);
            const discountsBeforeUpdate = state.agreementBeforeUpsert?.Discounts;
            state.hasChanged = {};
            for (const netType of netTypes) {
                const discountSelector = (discounts: ref_Discount[]) => discounts?.map(d => ([d.DiscountClass, DiscountManager.getModulation(d, netType), d.Rank, d.IsRate]))
                    .sort((a, b) => (a[0] as string).localeCompare(b[0] as string));
                const before = discountSelector(discountsBeforeUpdate);
                const after = discountSelector(newAgreement?.Discounts);
                const equals: boolean = compareObjects(before, after);
                state.hasChanged[netType] = !equals;
            }

            if (state.hasChanged['CO']) state.applicationConfig = netTypes;
            else if (state.hasChanged['FO']) state.applicationConfig = netTypes.filter(n => n != 'CO');
            else if (state.hasChanged['FOS']) state.applicationConfig = netTypes.filter(n => n != 'CO' && n != 'FO');

            state.agreement = newAgreement;
        },
        setAgreementBeforeUpsert: (state, action: PayloadAction<AgreementEditorState['agreementBeforeUpsert']>) => {
            state.applicationConfig = netTypes;
            state.agreementBeforeUpsert = clone(action.payload);
        },
        setConfig: (state, action: PayloadAction<AgreementEditorState['applicationConfig']>) => {
            state.applicationConfig = clone(action.payload);
        }
    }
})

// Action creators are generated for each case reducer function
export const { setAgreement, setConfig, setAgreementBeforeUpsert } = agreementEditorSlice.actions

export const agreementEditorReducer = agreementEditorSlice.reducer;


