import * as React from "react";
import { GetToday } from "tools-lib";
import { Client } from "hub-lib/client/client.bin";
import { AdwAutocomplete } from "adwone-lib/index";
import { addDays } from "date-fns";
import { DateRangepicker } from "../../../ConfigurableComponents/DateRangepicker";
import Loader from "../../../layout/Loader";

export class TConfig {
    User: string = '';
    Layer: string | string[] = '';
    Start: Date = GetToday();
    End: Date = addDays(GetToday(), 1)
}

export class TProps {
    config?: TConfig;
    onConfChange?: any;
}

class TState {
    config: TConfig = new TConfig();
    users: string[];
    layers: string[];
}

export class FiltersComponent extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);
        let newState = new TState();
        if (this.props.config)
            newState.config = { ...newState.config, ...this.props.config };
        newState.config.Start = new Date(newState.config.Start)
        newState.config.End = addDays(new Date(newState.config.Start), 1)
        this.state = newState;
    }

    async componentDidMount() {
        let users = (await Client.distinct({ collection: "logs", property: "User", documentType: "logDocument" }))
            .data.results?.filter(u => u);
        users = users.sort((a: string, b: string) => { return a.localeCompare(b) })
        let layers = (await Client.distinct({ collection: "logs", property: "Layer", documentType: "logDocument" }))
            .data.results?.filter(u => u);
        layers = layers.sort((a: string, b: string) => { return a.localeCompare(b) })
        this.setState({
            users,
            layers
        })
    }

    render() {
        let { config, users, layers } = this.state;
        if (!users?.length && !layers?.length)
            return <Loader />
        return (
            <>
                <div className="adw-row">
                    <DateRangepicker
                        defaultStart={config.Start}
                        defaultStop={config.End}
                        handleChangeDate={(v) => {
                            config.Start = v.start
                            config.End = v.end
                            this.forceUpdate();
                            this.props.onConfChange(this.state.config);
                        }} />
                </div>
                <div className="adw-row">
                    <AdwAutocomplete
                        multiple
                        options={this.state.users ?? []}
                        value={Array.isArray(config.User) ? config.User : [config.User]}
                        onChange={(event: any, value: any) => {
                            this.setState({ config: { ...this.state.config, User: value } }, () => { this.props.onConfChange(this.state.config) })
                        }}
                    />
                </div>
                <div className="adw-row">
                    <AdwAutocomplete
                        multiple
                        options={this.state.layers ?? []}
                        value={Array.isArray(config.Layer) ? config.Layer : [config.Layer]}
                        onChange={(event: any, value: any) => {
                            this.setState({ config: { ...this.state.config, Layer: value } }, () => { this.props.onConfChange(this.state.config) })
                        }}
                    />
                </div>
            </>
        )
    }
}
