import React from 'react'
import Template from '../Template'
import { URLServerProvider } from 'hub-lib/client/client.bin';
import io from 'socket.io-client';
import { EventEmitter } from 'events';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';

const socketConsoleEvent = new EventEmitter()

function Console() {

  const [strings, setStrings] = React.useState<string[]>();
  const [socket, setSocket] = React.useState<SocketIOClient.Socket>();

  const receiveData = body => {
    const _strings = [...(strings ?? []), typeof body == 'string' ? body : JSON.stringify(body ?? {})];
    setStrings(_strings);
  }

  React.useEffect(() => {
    if (!socket) {
      const _socket = io(URLServerProvider.provide());

      _socket.on('connect', () => {
        console.log(`CONNECTED`);
        _socket.emit('listen-console');
      });

      _socket.on('console', body => {
        socketConsoleEvent.emit('receiveData', body);
      });

      _socket.on('connect_error', (e: any) => {
        _socket?.disconnect();
        console.log(`socket disconnect`);
        setTimeout(() => {
          setSocket(undefined);
        }, 5000);
      });

      setSocket(_socket);
    }
  }, [socket]);

  React.useEffect(() => {
    socketConsoleEvent.addListener('receiveData', receiveData);
    return () => { socketConsoleEvent.removeListener('receiveData', receiveData) };
  })

  return <Terminal name='Server console' colorMode={ColorMode.Dark} onInput={terminalInput => console.log(`New terminal input received: '${terminalInput}'`)}>
    {strings?.map((str, i) => (<TerminalOutput key={`string-${i}`}>{str}</TerminalOutput>))}
  </Terminal>;
}

export default Template(Console);