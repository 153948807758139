import * as React from 'react'
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin'
import { rid } from 'hub-lib/models/orientdb/CommonTypes.bin';
import { Grid } from '@material-ui/core';
import { Trad, TradProp } from 'trad-lib';
import { Client } from 'hub-lib/client/client.bin';
import Loader from '../../layout/Loader';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { groupBy, propertyOf } from 'hub-lib/tools.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { RootState } from '../../../redux/store';
import { FormatEditor } from './MessageEditor/FormatEditor';
import { PlacementEditor } from './MessageEditor/PlacementEditor';
import { AdditionalFormatEditor } from './MessageEditor/AdditionalFormatEditor';
import { AdditionalPlacementEditor } from './MessageEditor/AdditionalPlacementEditor';
import { SupportEditor } from './MessageEditor/SupportEditor';
import { BroadcastAreaEditor } from './MessageEditor/BroadcastAreaEditor';
import { PublicationsEditor } from './MessageEditor/PublicationsEditor';
import { ModelPropertiesEditor } from './MessageEditor/ModelPropertiesEditor';
import { StartEndEditor } from './MessageEditor/StartEndEditor';
import { DatePropertyEditor } from './MessageEditor/DatePropertyEditor';
import { ModelPropertiesChoicesEditor } from './MessageEditor/ModelPropertiesChoicesEditor';
import { PropertyEditor } from './MessageEditor/PropertyEditor';
import { ValueChoiceChecker } from './MessageEditor/ValueChoiceChecker';
import { CurrencyEditor } from './MessageEditor/CurrencyEditor';
import { LnkAdvPropertyEditor } from './MessageEditor/LnkAdvPropertyEditor';
import { useSelector } from 'react-redux';
import { GroupEditor } from './MessageEditor/GroupEditor';
import { eGroupCategories } from 'hub-lib/models/ref_Groups.bin';

let medias: ref_Media[] = undefined;
export let currencies: ref_Currencies[] = undefined;

export function MessageDetails() {

    const [loading, setLoading] = React.useState(true);
    const media = useSelector((root: RootState) => root.messageEditor.message.Media);
    const MediaFamilyOther = useSelector((root: RootState) => root.messageEditor.message.ModelProperties?.["MediaFamilyOther"]);
    const PlacementCategoryOther = useSelector((root: RootState) => root.messageEditor.message.ModelProperties?.["PlacementCategoryOther"]);

    React.useEffect(() => {
        if (!loading) return;

        Promise.resolve().then(async () => {
            if (!medias) {
                medias = (await Client.get<ref_Media>(ref_Media))?.data?.results;
            }
            if (!currencies) currencies = (await Client.searchVertex(ref_Currencies.name))?.data?.results;
            setLoading(false);
        });
    })

    if (loading) return <Loader />
    const presseRid = medias?.find(media => media.Code == "PR")?.["@rid"];
    const Cinema_Or_HorsMedia = ["CI", "HM"].includes(medias?.find(m => media == m["@rid"])?.Code);
    return <>
        {/* Support details */}
        <Grid item xs={12} className="adw-title">{Trad("support_details")}</Grid>
        <Grid container className='block-container'>

            <Grid item xs={6} className="message_details_leftcombo">
                <SupportEditor />
            </Grid>

            <Grid item xs={6} className="message_details_rightcombo">
                <CurrencyEditor />
            </Grid>

            <Grid item xs={6} className="message_details_leftcombo">
                <BroadcastAreaEditor />
            </Grid>

            <Grid item xs={6} className="message_details_rightcombo">
                <ModelPropertiesEditor property='BroadcastAreaDetail' />
            </Grid>
            <Grid item xs={6} className="message_details_leftcombo">
                <LnkAdvPropertyEditor
                    property="AdvCompany_Com"
                    mandatory={!Cinema_Or_HorsMedia} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <LnkAdvPropertyEditor
                    property="AdvCompany_Fin"
                    mandatory={!Cinema_Or_HorsMedia} />
            </Grid>
            {media == presseRid && <>
                <Grid item xs={6} className="message_details_leftcombo">
                    <ModelPropertiesChoicesEditor property='Periodicity' />
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <ValueChoiceChecker propId='Periodicity' propValue='others'>
                        <ModelPropertiesEditor property='PeriodicityComment' label={Trad("property_Periodicity_comment")} />
                    </ValueChoiceChecker>
                </Grid>
            </>}
            <Grid item xs={6} className="message_details_leftcombo">
                {/* <ModelPropertiesEditor property='MediaFamily' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.MediaFamily` as any, ref_Messages)} /> */}
                <GroupEditor property="MediaFamily" require={propertyOf<ref_Messages>("Support")} other="MediaFamilyOther" modelProperty />
            </Grid>
            {/* if we choice other */}
            {MediaFamilyOther != null && <Grid item xs={6} className="message_details_rightcombo">
                <ModelPropertiesEditor property='MediaFamilyOther' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.MediaFamilyOther` as any, ref_Messages)} />
                {/* <PropertyEditor property='MediaFamilyOther' label={Trad("other")} /> */}
            </Grid>}
        </Grid>
        {/* parution details */}
        <Grid item xs={12} id='parutiondetailspicker' className="adw-title">{Trad("parution_details")}</Grid>
        <Grid container className='block-container'>
            {/* {media == presseRid &&
                <Grid item xs={12} className="message_details_full">
                    <PublicationsEditor />
                </Grid>} */}

            {/* {media != presseRid &&
                <Grid item xs={12} className="message_details_full">

                </Grid>} */}

            <Grid item xs={(media != presseRid) ? 12 : 6} className={(media != presseRid) ? "message_details_full" : "message_details_leftcombo"}>
                <StartEndEditor TSMCalendarEnable={media == presseRid} />
            </Grid>

            {media == presseRid && <>

                <Grid item xs={6} className="message_details_rightcombo">
                    <ModelPropertiesEditor property='Dated' />
                </Grid>

                <Grid item xs={6} className="message_details_leftcombo">
                    <ModelPropertiesEditor property='Reference' />
                </Grid>

                <Grid item xs={6} className="message_details_rightcombo">
                    <ModelPropertiesEditor property='EditorialTheme' />
                </Grid>
            </>}

            <Grid item xs={6} className="message_details_leftcombo">
                <FormatEditor />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <AdditionalFormatEditor />
            </Grid>
            {!Cinema_Or_HorsMedia && <>
                <Grid item xs={6} className="message_details_leftcombo">
                    <PlacementEditor />
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <AdditionalPlacementEditor />
                </Grid>
                <Grid item xs={6} className="message_details_leftcombo">
                    {/* <ModelPropertiesEditor property='PlacementCategory' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.PlacementCategory` as any, ref_Messages)} /> */}
                    <GroupEditor property="PlacementCategory" require={propertyOf<ref_Messages>("Placement")} other="PlacementCategoryOther" modelProperty />
                </Grid>
                {/* if we choice other */}
                {PlacementCategoryOther != null && <Grid item xs={6} className="message_details_rightcombo">
                    <ModelPropertiesEditor property='PlacementCategoryOther' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.PlacementCategoryOther` as any, ref_Messages)} />
                    {/* <PropertyEditor property='MediaFamilyOther' label={Trad("other")} /> */}
                </Grid>}
            </>}
        </Grid>
        {/* time limits */}
        <Grid item xs={12} className="adw-title">{Trad("time_limit")}</Grid>
        <Grid container className='block-container'>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor property='ConfirmationTimeLimit' label={Trad("confirmation_limit")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor property='ConfirmationComments' label={Trad("comment")} />
            </Grid>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor property='TechDeliveryTimeLimit' label={Trad("technical_deadline")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor property='TechDeliveryComments' label={Trad("comment")} />
            </Grid>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor property='CancellationTimeLimit' label={Trad("cancel_limit")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor property='CancellationComments' label={Trad("comment")} />
            </Grid>
        </Grid>
    </>
}