
import * as React from "react";
import TextField from '@material-ui/core/TextField'
import { kpis, ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { GetNumberFormatCustom } from "./NumberFormatCustom.bin";
import { IModelMessageInfo } from "hub-lib/models/KPIsManager.bin";
import { lnk_HasKPI } from "hub-lib/models/orientdb/lnk_HasKPI.bin";
import { lnk_HasPropertyType } from "hub-lib/models/orientdb/lnk_HasPropertyType.bin";
import { Format } from "format-lib/index.bin";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TextEditor } from "../TextEditor.bin";
import { ContainerComponent } from "../Generic/ContainerComponent";

export class TProps {
    message: ref_Messages;
    modelInfo: IModelMessageInfo;
    isDisable: () => boolean;
    getValue: () => any;
    onChange?: (message: ref_Messages) => void;
    isTextEditor?: boolean;
}

export function MetricsTextfield(props: TProps) {
    const { isTextEditor } = props;
    if (isTextEditor)
        return <MetricsComment {...props} />
    return <MetricsTextfieldBase {...props} />;
}

export function MetricsTextfieldBase({ modelInfo, message, isDisable, getValue, onChange }: TProps) {

    const _isDisable = useSelector((root: RootState) => isDisable());
    const value = useSelector((root: RootState) => getValue());

    const _onChange: TProps['onChange'] = (m) =>
        onChange?.(m)

    return (<TextField
        key={modelInfo.Name}
        disabled={_isDisable}
        autoComplete='off'
        style={{ width: '100%' }}
        label={Format(modelInfo)}
        type={modelInfo.ValueType === "String" ? "String" : "Number"}
        value={value}
        variant="outlined"
        onChange={(e) => {
            let value: any;
            if (modelInfo.ValueType === "String")
                value = e.target.value;
            else {
                value = parseFloat(e.target.value);
                if (isNaN(value)) value = 0;
                if (value < 0) value = 0;
            }

            if (!message.KPIs) message.KPIs = new kpis();
            if (!message.ModelProperties) message.ModelProperties = {};

            if (modelInfo["@class"] == lnk_HasKPI.name)
                message.KPIs[modelInfo.Id] = value;
            else if (modelInfo["@class"] == lnk_HasPropertyType.name)
                message.ModelProperties[modelInfo.Id] = value;
            _onChange?.(message);
        }}
        {...(modelInfo.ValueType !== "String" && value !== undefined && value != null && {
            InputProps: { inputComponent: GetNumberFormatCustom(modelInfo.ValueType) as any },
            InputLabelProps: { shrink: true }
        })}
    />);
}

let timeOut = null;
export function MetricsComment({ modelInfo, message, getValue, onChange }: TProps) {

    const [_value, setValue] = React.useState(getValue());

    const _onChange: TProps['onChange'] = (m) => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            onChange?.(m)
        }, 700);
    }

    return <ContainerComponent title={Format(modelInfo)} className="auto-height no-padding no-margin">
        <TextEditor
            key={modelInfo.Name}
            content={_value}
            onChange={value => {
                if (!message.ModelProperties) message.ModelProperties = {};
                message.ModelProperties[modelInfo.Id] = value;
                //MetricsTextfieldEvent.emit('changed');
                setValue(value);
                _onChange?.(message);
            }} />
    </ContainerComponent>
}