const keyNotifications = 'notifications';

export function SetLocalStorageNotifications(params){
    const notifStorage = localStorage.getItem('notifications');
    if(notifStorage){
        const newStorage = JSON.parse(notifStorage)
        newStorage.push(params);
        localStorage.setItem(keyNotifications, JSON.stringify(newStorage));
    }else{
        localStorage.setItem(keyNotifications, JSON.stringify([params]));
    }
}