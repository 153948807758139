import * as React from 'react'
import { ADWColumn } from "adwone-lib/index";
import { Client } from 'hub-lib/client/client.bin';
import { ref_Functions } from 'hub-lib/models/orientdb/ref_Functions.bin';
import { ref_Modules } from 'hub-lib/models/orientdb/ref_Modules.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { useEffect, useState } from 'react';
import { Trad, TradClassName } from 'trad-lib';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { GridBase } from '../../VertexGrid/Generic/GridBase.bin';

const gridHeight = 300;

class OptionRow {
    ModuleName: string;
    OptionName: string;

    constructor(_moduleName?, _optionName?) {
        this.ModuleName = _moduleName;
        this.OptionName = _optionName;
    }
}

export function AvailableOptionsGrid() {

    const [grid, setGrid] = useState<GridBase<OptionRow>>(undefined)

    const initGrid = async () => {
        const moduleCol = new ADWColumn<OptionRow>(TradClassName(ref_Modules.name), "ModuleName", ePropType.String);
        const optCol = new ADWColumn<OptionRow>(TradClassName(ref_Functions.name), "OptionName", ePropType.String);

        moduleCol.width = 350;
        optCol.width = 350;

        const user = (await Client.getUser()).user;
        const rows: OptionRow[] = [];

        if (user.modules?.length) {
            const modules: (ref_Modules & { FuncNames: string[] })[] = await Client.searchVertexTyped(ref_Modules, {
                "@rid": user.modules,
                properties: ["Name", "Functions.Name as FuncNames"]
            }) as any;

            modules.forEach(m => {
                m.FuncNames.forEach(f => {
                    rows.push(new OptionRow(m.Name, Trad(f)));
                })
            })
        }

        const grid = new GridBase({
            objectPrototype: OptionRow,
            rows,
            columns: [moduleCol, optCol]
        });

        setGrid(grid);
    }

    useEffect(() => {
        if (!grid)
            initGrid();
    })

    return <div style={{ height: gridHeight, position: 'relative' }}>
        {grid && <AdwTelerikGrid
            isCopyDisable
            grid={grid}
            uneditable={true}
            gridHeight={gridHeight} />}
    </div>
}