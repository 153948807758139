import { Grid } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { UserExtended } from 'hub-lib/models/UserExtended.bin';
import * as React from 'react'
import { Trad } from 'trad-lib';
import { VertexAutocomplete } from "adwone-lib/index";
import Loader from '../Loader';
import Template from '../Template'
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { eRoles, HasAccess } from 'hub-lib/business/rights/rights.bin';
import { UserRights } from 'hub-lib/models/orientdb/UserRights.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { clone, duplicate } from 'hub-lib/tools.bin';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { ref_Contracts } from 'hub-lib/models/orientdb/ref_Contracts.bin';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { BreadcrumbsCustomContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from '../../VertexGrid/Generic/ToolbarAdw';
import { ListBoxRights, ListItemContext, TreeRights } from '../Tools/ListBoxItemTools.bin';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';
import { ref_Modules } from 'hub-lib/models/orientdb/ref_Modules.bin';
import { BroadcastAreaExtended } from 'hub-lib/models/BroadcastAreaExtended.bin';
import { AdvertiserGroupsData, CustomerRightsExtended, FakeAdvertiserGroup, HandleChangeAdvertiserGroup, TRightsState } from '../SuperAdmin/RightsOfCustomers.bin';

class UserRightsExtended extends UserRights {
    lnk_AdvertiserGroupRight: [] = []
    lnk_AdvertiserRight: [] = []
    lnk_AgencyRight: [] = []
    lnk_MediaRight: [] = []
    lnk_BroadcastAreaRight: [] = []
}

class TState extends TRightsState {
    medias: ref_Media[]
    agencies: ref_Agencies[]
    basicContract: ref_Contracts
    customerSelected: ref_Customers = undefined
    userSelected: UserExtended = undefined
    CustomerRights: CustomerRightsExtended = undefined
    RightCreated: string = undefined;
    currentUser: UserExtended = undefined;
}

class RightsOfUsers extends React.Component<any, TState> {
    constructor(props: any) {
        super(props)
        let newstate = new TState()
        this.state = newstate
    }
    componentDidMount() {
        return Promise.all([
            Client.searchVertex<ref_AdvertiserGroups>(ref_AdvertiserGroups.name),
            Client.searchVertex(BroadcastAreaExtended.name, { deep: true }),
            Client.searchVertex<ref_Media>(ref_Media.name),
            Client.searchVertex<ref_Agencies>(ref_Agencies.name),
            Client.searchVertex<ref_Contracts>(ref_Contracts.name),
            Client.getUser(),
            Client.searchVertex<ref_Modules>(ref_Modules.name, { Name: "International" }),
            Client.searchVertex(ref_Advertisers.name, { "noParent": true }),
        ]).then(res =>
            this.setState({
                advertiserGroups: AdvertiserGroupsData(res[0].data.results, res[7].data.results),
                broadcastareas: res[1].data.results,
                medias: res[2].data.results,
                agencies: res[3].data.results,
                basicContract: res[4].data.results.find(c => c.Name == "Basic"),
                currentUser: res[5].user,
                moduleInternational: res[6].data.results?.[0],
                noParentAdvertisers: res[7].data.results,
                loading: false,
            }));
    }

    initializeData = async () => {
        if (this.state.userSelected?.customerRights) {
            const customer_rights = await Client.searchVertex(UserRights.name, { "@rid": this.state.userSelected?.customerRights });
            this.setState({
                CustomerRights: customer_rights.data.results[0] as CustomerRightsExtended,
            })
        }
        if (this.state.userSelected.Rights) {
            const user_rights = await Client.searchVertex(UserRights.name, { "@rid": this.state.userSelected?.Rights });
            this.setState({
                Rights: user_rights.data.results[0] as UserRightsExtended,
                RightsCopy: duplicate(user_rights.data.results[0])
            })
        } else {
            const userRights = new UserRightsExtended();
            userRights.AllAdvertiserGroups = true;
            userRights.AllBroadcastAreas = true;

            this.setState({ Rights: userRights, RightsCopy: clone(userRights) })
        }
    }

    submitChanges = async () => {
        try {
            const params = { ...this.state.Rights, user: this.state.userSelected['@rid'] };
            if (this.state.Rights["@rid"]) {
                await Client.updateVertex(UserRights.name, params);
                this.initializeData();
            } else {
                this.state.userSelected.Rights = (await Client.createVertex(UserRights.name, params)).data.results["@rid"];
                await this.initializeData();
                this.setState({ RightCreated: this.state.userSelected.Rights });
            }
        }
        catch (e) { console.error(e) }
    }

    handleSelect = (e: TabStripSelectEventArguments) => {
        this.setState({ selected: e.selected });
    }

    render() {
        if (this.state.loading)
            return <Loader />
        let { CustomerRights, Rights, RightsCopy, basicContract, advertiserGroupRights, RightCreated, userSelected, customerSelected, currentUser, moduleInternational } = this.state

        const agencyContext: ListItemContext = {
            label: Trad("all_agencies"),
            Rights: Rights,
            allRights: Rights,
            data: this.state.agencies,
            allProperty: "AllAgencies",
            lnkProperties: ["lnk_AgencyRight"]
        };

        const mediaContext: ListItemContext = {
            label: Trad("all_medias"),
            Rights: Rights,
            allRights: Rights,
            data: this.state.medias,
            allProperty: "AllMedia",
            lnkProperties: ["lnk_MediaRight"]
        };
        const broadcastAreaContext: ListItemContext = {
            label: Trad("all_broadcastareas"),
            Rights: Rights,
            allRights: Rights,
            data: this.state.broadcastareas,
            allProperty: "AllBroadcastAreas",
            lnkProperties: ["lnk_BroadcastAreaRight"],
            enabledProps: {}
        };
        if (!userSelected?.modules?.includes(moduleInternational["@rid"]))
            broadcastAreaContext.enabledProps["Currency"] = userSelected?.customer?.["Currency"];
        if (!CustomerRights?.AllBroadcastAreas)
            broadcastAreaContext.enabledProps["Customer"] = CustomerRights?.lnk_BroadcastAreaRight?.map(l => l["@rid"]);
        //Ajouter le groupe annonceur Fake si le customer a des droits sur des annonceurs sans groupe
        const noParentsAdvRids = new Set(this.state.noParentAdvertisers.map(a => a['@rid']));
        const includeOthers = this.state.noParentAdvertisers.length && CustomerRights?.lnk_AdvertiserRight?.some(l => noParentsAdvRids.has(l["@rid"]));

        const advertiserGroups = this.state.advertiserGroups.filter(e => CustomerRights?.AllAdvertiserGroups
            || CustomerRights?.lnk_AdvertiserGroupRight?.find(l => l["@rid"] == e["@rid"])
            || (e['@rid'] == FakeAdvertiserGroup['@rid'] && includeOthers));
        const advertiserGroupContext: ListItemContext = {
            label: Trad("all_advertiserGroups"),
            Rights: Rights,
            allRights: Rights,
            data: advertiserGroups,
            allProperty: "AllAdvertiserGroups",
            lnkProperties: ["lnk_AdvertiserGroupRight", "lnk_AdvertiserRight"],
            parentRid: advertiserGroupRights?.["@rid"],
            enabledProps: { AllAdvertisers: true }
        };

        const customerAdvertiserGroupRight = CustomerRights?.lnk_AdvertiserGroupRight?.find(g => g["@rid"] == advertiserGroupRights?.["@rid"]);
        //Le client a les droits sur tous les annonceurs du groupe ou le client n'a pas de droit spécifique sur le groupe et a le droit à tous les groupes
        const customerAllAdvertisers = (customerAdvertiserGroupRight?.["AllAdvertisers"] || (!customerAdvertiserGroupRight && CustomerRights?.AllAdvertiserGroups));

        const advertiserContext: ListItemContext = {
            label: Trad("all_advertisers"),
            Rights: Rights,
            allRights: advertiserGroupRights,
            data: this.state.advertisers?.filter(e => customerAllAdvertisers || CustomerRights?.lnk_AdvertiserRight?.find(l => l["@rid"] == e["@rid"])),
            allProperty: "AllAdvertisers",
            lnkProperties: ["lnk_AdvertiserRight"]
        };

        if (userSelected?.company) {
            advertiserGroupContext.readOnlyItemIds = [userSelected.company["@rid"]];
            advertiserContext.readOnlyItemIds = [userSelected.company["@rid"]];
            agencyContext.readOnlyItemIds = [userSelected.company["@rid"]];
        }

        return (
            <>
                <div className="grid_container">
                    <div style={{ width: '100%' }}>
                        <ToolbarAdw>
                            <ToolbarContainer>
                                <SelectedItemsContainer>
                                    <div style={{ display: "flex" }} >
                                        {currentUser?.profileName == "super-administrateur" &&
                                            <div style={{ width: "300px" }} className="margin-right">
                                                <VertexAutocomplete
                                                    key={`company_list_${RightCreated}`}
                                                    label={Trad("customer")}
                                                    params={{ properties: ["@rid", "Contract", "Authorization", "Company", "Company.Name as CompanyName"], Active: true }}
                                                    onChange={(value: ref_Customers) => {
                                                        this.setState({ customerSelected: value, userSelected: value ? undefined : userSelected, Rights: undefined, RightsCopy: undefined, CustomerRights: undefined, advertiserGroupRights: undefined })
                                                    }}
                                                    defaultValue={(options: ref_Customers[]) => options?.find((v) => v["@rid"] === this.state?.customerSelected?.["@rid"])}
                                                    customDisplay="CompanyName"
                                                    type={ref_Customers.name}
                                                    afterLoadFilter={(customers: ref_Customers[]) => customers.filter(c => c.Contract != basicContract?.["@rid"] && c.Authorization == "AdwOne")}
                                                />
                                            </div>}
                                        <div style={{ width: "300px" }}>
                                            <VertexAutocomplete
                                                key={`user_list_${customerSelected?.['@rid']}_${RightCreated}`}
                                                label={Trad("user_to_manage")}
                                                params={{ properties: ["@rid", "Name", "Rights"], Active: true, excludeRights: true }}
                                                onChange={(value: UserExtended) => {
                                                    this.setState({ userSelected: value, Rights: undefined, RightsCopy: undefined, CustomerRights: undefined, advertiserGroupRights: undefined },
                                                        () => { this.initializeData() })
                                                }}
                                                defaultValue={(options: UserExtended[]) => options?.find((v) => v["@rid"] === this.state?.userSelected?.["@rid"])}
                                                type={UserExtended.name}
                                                afterLoadFilter={(users: UserExtended[]) => users.filter(u => u.customer?.Contract != basicContract?.["@rid"]
                                                    && HasAccess(u.maskRights, eRoles.adwoneAuth)
                                                    && (!customerSelected || u.customer?.["@rid"] == customerSelected["@rid"]))}
                                                disableClearable
                                            />
                                        </div>
                                    </div>
                                </SelectedItemsContainer>
                                <BreadcrumbsCustomContainer>
                                    <BreadcrumbsCustom hasSelectedItems={true}
                                        elements={[
                                            { text: Trad("home"), href: "/" },
                                            { text: Trad("wallet_management") }
                                        ]} />
                                </BreadcrumbsCustomContainer>
                            </ToolbarContainer>
                        </ToolbarAdw>
                        {Rights &&
                            <>
                                <TabStrip className="tabpanel_fullwidth" selected={this.state.selected} onSelect={this.handleSelect}>
                                    <TabStripTab title={Trad("agencies")}>
                                        <ListBoxRights context={agencyContext} onSwitchChange={(e) => this.forceUpdate()} />
                                    </TabStripTab>
                                    <TabStripTab title={Trad("medias")}>
                                        <ListBoxRights context={mediaContext} onSwitchChange={(e) => this.forceUpdate()} />
                                    </TabStripTab>
                                    <TabStripTab title={Trad("broadcast_areas")}>
                                        <TreeRights context={broadcastAreaContext} onSwitchChange={(e) => this.forceUpdate()} />
                                        {/*<ListBoxRights context={broadcastAreaContext} onSwitchChange={(e) => this.forceUpdate()} />*/}
                                    </TabStripTab>
                                    <TabStripTab title={Trad("advertisers_and_advertisergroups")}>
                                        <Grid container item xs={12}>
                                            <ListBoxRights context={advertiserGroupContext}
                                                onItemClick={(props) => HandleChangeAdvertiserGroup(props.dataItem, this.state, (p: any) => this.setState(p))}
                                                onSwitchChange={(e, d) => this.setState({ advertiserGroupRights: undefined })} />
                                            <ListBoxRights context={advertiserContext} className="message_details_rightcombo"
                                                onSwitchChange={(event, dataItem) => {
                                                    if (dataItem && !event.target.value)
                                                        Rights.lnk_AdvertiserRight = Rights.lnk_AdvertiserRight.filter(e => e["@rid"] != dataItem["@rid"])

                                                    this.forceUpdate();
                                                }} />
                                        </Grid>
                                    </TabStripTab>
                                </TabStrip>
                                <div className="adw-form-action">
                                    <CustomButton
                                        Label={Trad("save")}
                                        disabled={JSON.stringify(Rights) === JSON.stringify(RightsCopy)}
                                        className="custom_btn_primary_validation"
                                        onClick={() => {
                                            this.submitChanges()
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default Template(RightsOfUsers);