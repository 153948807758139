import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { ref_AgencyRole, ref_AgencyRoleId } from "./ref_AgencyRole.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";
import { ref_CompaniesId } from "./ref_Companies.bin";

export type lnk_MandateId = rid;

export class lnk_Mandate extends E<ref_CompaniesId, ref_CompaniesId> {
	"@rid"?: lnk_MandateId;
	End: Date;
	Media: ref_MediaId[];
	Roles: ref_AgencyRoleId[];
	Start: Date;

}