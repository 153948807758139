import * as React from 'react'
import { extractSub, GetDuplicatedMessages, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { TradProp } from 'trad-lib';
import { Grid } from '@material-ui/core';
import { changeEndValue } from './DiffusionEditor';
import { CalendarProps } from '@progress/kendo-react-dateinputs';
import { PublicationsEditor } from './PublicationsEditor';
import { getIcon } from 'adwone-lib';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { GenericTooltip } from '../../../ConfigurableComponents/GenericTooltip.bin';
import { DuplicatedMessagesTooltip } from '../PublicationsCalendar/PublicationsCalendar.bin';

type StartEndEditorArgs = { TSMCalendarEnable?: boolean }
export function StartEndEditor({ TSMCalendarEnable }: StartEndEditorArgs) {
    return <Grid container style={{ marginBottom: 0 }}>
        <Grid item xs={6} className="left_column" style={{ position: 'relative' }}>
            <StartEditor TSMCalendarEnable={TSMCalendarEnable} />
        </Grid>
        <Grid item xs={6} className="right_column">
            <EndEditor />
        </Grid>
    </Grid>
}

export function StartEditor({ TSMCalendarEnable }: StartEndEditorArgs) {
    const _start = useSelector((root: RootState) => root.messageEditor.message.Start);
    const Start = _start ? new Date(_start) : null;
    return <>
        <SimpleDatePicker
            {...(TSMCalendarEnable ? { calendar: TSMCalendar } : {})}
            key={`start_${Start?.toString()}`}
            label={TradProp("Start")}
            defaultValue={Start ? new Date(Start) : null}
            onChange={async ({ value }) => {
                const message = store.getState().messageEditor.getMessage();
                message.Start = value;
                await changeEndValue(message);
                store.dispatch(setMessage(message));
            }} />
        <WarningDuplicatedMessage />
    </>
}

export function EndEditor() {
    const { Start, End } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ["Start", "End"]) ?? {}, JSONEqualityComparer);
    const endDateValid = !Boolean(End) || (Boolean(Start) && Start.getTime() <= End.getTime());
    return <SimpleDatePicker
        valid={endDateValid}
        key={`end_${End?.toString()}`}
        label={TradProp("End")}
        defaultValue={End ? new Date(End) : null}
        onChange={({ value }) => {
            const message = store.getState().messageEditor.getMessage();
            message.End = value;
            store.dispatch(setMessage(message));
        }} />
}

export function TSMCalendar({ onChange }: CalendarProps) {
    return <div style={{ width: 700 }}>
        <PublicationsEditor
            onChange={date => onChange?.({ value: date, target: { value: date }, syntheticEvent: null })} />
    </div>
}

export function WarningDuplicatedMessage() {

    const [duplicatedMessages, setDuplicatedMessages] = React.useState<ref_Messages[]>();
    const { Support, AdvertiserGroup, BroadcastArea, Currency, Start } = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ["Support", "AdvertiserGroup", "BroadcastArea", "Currency", "Start"]) ?? {}, JSONEqualityComparer);

    React.useEffect(() => {
        if (!Support || !AdvertiserGroup || !BroadcastArea || !Currency || !Start) setDuplicatedMessages(null);
        else GetDuplicatedMessages({ Support, AdvertiserGroup, BroadcastArea, Currency, Start }).then(setDuplicatedMessages);
    }, [Support, AdvertiserGroup, BroadcastArea, Currency, Start?.getTime?.()]);

    return <>
        {duplicatedMessages?.length > 0
            && <GenericTooltip tooltipContent={() => <DuplicatedMessagesTooltip messages={duplicatedMessages} />}>
                <div className='warning-duplicate-icon'>{getIcon("warning")}</div>
            </GenericTooltip>}
    </>
}