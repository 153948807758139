
import { Client } from "hub-lib/client/client.bin";
import { ConfigureInOut, Locale, TradProvider } from "trad-lib";

let getterLocale = () =>
    (localStorage.getItem('currentLocal') ?? "fr-FR") as Locale;

let setterLocale = (locale: Locale) => {
    localStorage.setItem('currentLocal', locale);
    document.getElementsByTagName("html")[0].setAttribute("lang", locale);
    return Client.SetLocale(locale).catch(err => console.error(err));
}

export function RunInitLocale() {
    TradProvider.IsActivated = () => {
        return localStorage.getItem("devTrad") != "1";
    }
    return ConfigureInOut(getterLocale, setterLocale);
}

RunInitLocale();