import { Grid, Button, Popover, TextField } from "@material-ui/core";
import FilterList from "@material-ui/icons/FilterList";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import React from "react";
import { Trad, TradClassName } from "trad-lib";
import { FilterStorage } from "../../../utils/localstorage.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { CampaignFilter, CampaignSearch, SearchProps } from "../Messages/CampaignSearch.bin";
import { CampaignInfos } from "./CampaignInfos";
import { IAdvertiserHierarchy } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { Client } from "hub-lib/client/client.bin";
import { Format } from "format-lib/index.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { clone, GetHashCode, toArray, ToArrayObject } from "hub-lib/tools.bin";
import { AdvertiserHierarchyComponent } from "../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent";
import { StoreCreationCampaigns } from "../AdvertiserHierarchyComponent/StoreFilters";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";

export type CampaignPickerArg = {

    // Pilotable componant
    options?: ref_Campaigns[],
    campaignFilter?: CampaignFilter,
    onFilterChanged?: SearchProps['onFilterChanged'],

    hideSearchButton?: boolean,
    store: IAdvertiserHierarchy & { Campaign?: IRid["@rid"] },
    onChange: (cmp: ref_Campaigns) => void,
    onStoreChanged?: (store: IAdvertiserHierarchy) => void,
    defaultValue: ref_Campaigns,
    params?: any;
    readonlyHiearchy?: boolean;
    validationMode?: boolean;
    lockedProperties?: (keyof IAdvertiserHierarchy)[]

    isLocked?: boolean;
};

export function CampaignPicker({ isLocked, campaignFilter: _campaignFilter, options, onFilterChanged, hideSearchButton, store, onChange, defaultValue, params, readonlyHiearchy, validationMode, onStoreChanged, lockedProperties }: CampaignPickerArg) {

    const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement>();
    const [campaignFilter, setCampaignFilter] = React.useState<CampaignFilter>(_campaignFilter ?? { ...FilterStorage.GetLocalStorageFiltersObj() });

    const openCampaignFilter = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const getCampaignParams = () => {
        const searchParams: any = {
            ...(!params.CacheInfos && {
                AdvertiserGroup: campaignFilter?.AdvertiserGroup,
                Advertiser: campaignFilter?.Advertiser,
                Brand: campaignFilter?.Brand,
                Product: campaignFilter?.Product,
                Start: campaignFilter?.Start,
                End: campaignFilter?.End
            }),
            Source: "ADWONE",
            Active: true,
            ...params
        };

        Object.keys(searchParams).forEach((key: string) => {
            if (searchParams[key] === undefined)
                delete searchParams[key];
        });

        return searchParams;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'campaign_search_popover' : undefined;

    const multiStore = ToArrayObject(store);
    return <>
        <Grid item xs={12} className="adw-title">{Trad("campaign_association")}</Grid>
        <Grid container className='block-container'>
            <Grid container item xs={12} className="message_details_full" >
                <Grid item xs={12} className="selection-campaign-row">
                    <div className={"campaign-autocomplete-container " + ((validationMode && !defaultValue) ? "k-invalid" : "")}>
                        <VertexAutocomplete
                            key={`campaign-selector-${defaultValue?.["@rid"]}`}
                            disabled={isLocked}
                            {...(options && {
                                options,
                                key: `campaign-selector_${GetHashCode(options?.map(o => o['@rid']))}_${defaultValue?.["@rid"]}`
                            })}
                            refreshOnPropChanged
                            type={ref_Campaigns.name}
                            label={Trad("campaign")}
                            defaultValue={_options => _options.find(e => e["@rid"] == defaultValue?.["@rid"]) ?? defaultValue}
                            params={getCampaignParams()}
                            onChange={onChange} />
                    </div>
                    {!hideSearchButton && <>
                        <CustomButton
                            Label={getIcon("filter_list")}
                            className={`custom_btn_primary`}
                            onClick={openCampaignFilter} />

                        {/* <CustomIconButton
                            className="custom_btn_primary"
                            onClick={openCampaignFilter}>
                            {getIcon("filter_list")}
                        </CustomIconButton> */}

                        {/* <Button aria-describedby={id} variant="contained" className="custom_btn_primary" onClick={openCampaignFilter}>
                            <FilterList />
                        </Button> */}
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(undefined)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <div style={{ margin: 20 }}>
                                <CampaignSearch
                                    // saveOnChange
                                    filter={campaignFilter as any}
                                    onFilterChanged={(filter: CampaignFilter) => {
                                        setCampaignFilter(filter);
                                        onFilterChanged?.(filter);
                                    }} />
                            </div>
                        </Popover>
                    </>}
                </Grid>
            </Grid>

            <CampaignInfos campaignRid={defaultValue?.["@rid"]} params={params} />

            {!defaultValue?.Group &&
                <AdvertiserHierarchyComponent
                    hiddenKeys={['Campaign']}
                    multiSelection={false}
                    reduxStore={StoreCreationCampaigns}
                    store={multiStore}
                    isLocked={Boolean(!multiStore.Campaign)}
                    lockedKeys={lockedProperties as any}
                    onChange={(conf) => {
                        const copy: IAdvertiserHierarchy = clone(conf);
                        copy.AdvertiserGroup = toArray(conf.AdvertiserGroup)?.[0];
                        copy.Advertiser = toArray(conf.Advertiser)?.[0];
                        copy.Brand = toArray(conf.Brand)?.[0];
                        copy.Product = toArray(conf.Product)?.[0];
                        onStoreChanged?.(copy)
                    }} />}

            {/* {!defaultValue?.Group &&
                <HierarchyComboManager
                    key={`HierarchyComboManager-${store?.Campaign}-${validationMode}`}
                    nullDefault
                    lockMode={lockedProperties}
                    readonly={readonlyHiearchy}
                    prototypeStore={ref_Messages}
                    store={store}
                    onConfChanged={(conf) => onStoreChanged?.(conf)}
                    {...(!readonlyHiearchy && validationMode && { validationMode: IsObjectValid<ref_Messages>(ref_Messages, store as any)?.length > 0 })} />} */}

            {defaultValue?.Group && <Grid item xs={12} className="selection-campaign-row">
                <RidTextfield type={ref_Groups.name} rid={defaultValue.Group} />
            </Grid>}
        </Grid>
    </>
}

type RidTextfieldProps = { type: string, rid: IRid["@rid"] }
function RidTextfield({ type, rid }: RidTextfieldProps) {

    const [text, setText] = React.useState<string>("");
    React.useEffect(() => {
        Client.searchVertex(type, { "@rid": rid }).then(res => {
            setText(Format(res?.data?.results?.[0]) ?? "");
        })
    }, [rid])

    return <TextField label={TradClassName(type)} style={{ width: "100%" }} variant="outlined" value={text} disabled />
}