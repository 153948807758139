import { Indicateur } from "adwone-engine/index.bin";
import { ADWProperty } from "../../types";
import { AggregateExport, ExportBase } from "../external.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";

export type ViewModeType = "Table" | "CrossedTable" | "Scheduler" | "SchedulerLight";
export class ExportArgChoices {
    dimensionsChoices: (ADWProperty | Indicateur)[] = [];
    templatesChoices: string[] = [];
    subTotalChoices: ["week", "month", "trimester", "semester"] = ["week", "month", "trimester", "semester"];
    rowTotalChoices: ["top", "bottom"] = ["top", "bottom"];
}

export type ExportArg =
    Pick<AggregateExport, 'Start' | 'End' | 'timeView' | 'stretchTotal' | 'dimensions' | 'totalLevel' | 'totalDetails' | 'hideGroupingColumns' | 'hideDetailsRows' | 'groupingRows' | 'groupingTotalTable' | 'applySchedulerUserTemplate' | 'schedulerUserTemplate' | 'temporalTotal'>
    & Pick<ExportBase, 'ViewModeExport' | 'rowTotal' | 'rowTotalActivated' | 'templateName' | 'splitTab' | 'addSplitDimensionToTotal'>

export function GetNewDefaultExport(): ExportArg {
    return {
        ...new ExportBase(),
        ...new AggregateExport()
    }
}


export class ref_ExportConfigurations extends ref_ConfigurationsBase {
    ExportArg: ExportArgChoices & ExportArg
}