import * as React from 'react'
import Logo from '../../utils/adwone.png'
import { Trad } from 'trad-lib'
import { GenericDialog } from '../ConfigurableComponents/GenericDialog.bin'


const loginErrors = {
    "user_not_found": "user_not_found",
    "user_desactivated": "user_desactivated",
    "no_contract": "no_contract",
    "company_desactivated": "company_desactivated",
    "company_expired": "ContractExpired",
    "internal_error": "id_incorrect"
}

type ErrorLoginProps = { opened: boolean, code: string, onClose: () => void };
export function ErrorLogin({ opened, code, onClose }: ErrorLoginProps) {
    return <GenericDialog
        open={opened}
        dialogTitle={Trad(code)}
        dialogContent={<div style={{ textAlign: "center" }}>
            <img className="loginCard_content_img"
                alt="Adwanted"
                src={Logo} />
            <p>
                {Trad(loginErrors[code])}
            </p>
            <p><a href="mailto:supportfr@adwone.com">supportfr@adwone.com</a></p>
        </div>}
        disableCancel
        actions
        submitAction={onClose}
        submitTitle={Trad("Ok")}
    />
}

