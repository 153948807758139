import * as React from "react";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { GetTemplate, ref_SchedulerConfigurations, TemplatedRid } from "hub-lib/models/ref_SchedulerConfigurations.bin";

export function TemplateToCSS(template: TemplatedRid): React.CSSProperties {
    if (!template)
        return {};
    //let background = template.color?.code;
    let fontWeight: any = template.textStyle?.bold ? "bold" : "normal";
    let fontStyle = template.textStyle?.italic ? "italic" : null;
    let textDecoration = template.textStyle?.underline ? "underline" : null;
    let color = template.textStyle?.color?.code;

    return {
        background: color,
        fontWeight,
        fontStyle,
        textDecoration,
        color
    }
}


export function MessagesToCSS(messages: ref_Messages[], template: ref_SchedulerConfigurations): React.CSSProperties {

    if (!template)
        return {};

    const templ = GetTemplate(messages, template?.Style);
    if (templ)
        return TemplateToCSS(templ);

    return {}
}