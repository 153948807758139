import * as React from 'react'
import { Button } from '@progress/kendo-react-buttons';
import { InputPrefix, InputSuffix, TextBox } from '@progress/kendo-react-inputs';
import { Trad } from "trad-lib";
import { ContainerComponent } from '../../Generic/ContainerComponent';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';

type WebsiteTextBoxArgs = { value: string, onChange: (v: string) => void, title?: string }
export function WebsiteTextBox({ value, onChange, title }: WebsiteTextBoxArgs) {

    const prefix = () => (
        <InputPrefix>https://www.</InputPrefix>
    );
    const suffix = () => (
        <InputSuffix>
            <Button className='custom_btn_secondary picto'
                fillMode={"flat"}
                rounded={null}
                disabled={!value}
                onClick={() => window.open("https://www." + value, '_blank')} >
                {Trad("Go")}
            </Button>
        </InputSuffix>
    );

    return <ContainerComponent title={title ?? Trad("website")}>
        <TextBox
            className='website-textbox'
            defaultValue={value}
            onChange={(e) => {
                onChange?.(e.target.value as string);
            }}
            style={{ width: '100%' }}
            fillMode={"flat"}
            rounded={null}
            prefix={prefix}
            suffix={suffix}
        />
    </ContainerComponent>

}