import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { GetCurrentLocale, SetCurrentLocale, Trad } from 'trad-lib'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText'
import { AboutDialog } from './AboutDialog.bin'
import { ContactSupportDialog } from './ContactSupportDialog.bin'
import history from '../../../utils/history'
import { getIcon } from "adwone-lib/index"
import { UserInfos } from './UserInfos.bin';
import { Client } from 'hub-lib/client/client.bin'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import moment from "moment";
import {
  ListView,
  ListViewItemProps,
} from "@progress/kendo-react-listview";
import { GetLocalStorageNotifications } from '../../../utils/localstorage.bin';
import { StatusAPIDialog } from './StatusAPI';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: 'auto 8px',
    },
    position: 'absolute',
    right: 0
  },
  leaveIcon: {
    width: 40,
    height: 40,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    color: '#3f51b5'
  },
  inputRoot: {
    cursor: 'text',
    color: 'inherit',
  },
  inputInput: {
    cursor: 'text',
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  list: {
    width: 600,
    padding: 20
  }
}))

type ItemProfileProps = {
  onClick?: React.MouseEventHandler<HTMLLIElement>,
  icon?: string,
  text: string,
  textClassName?: string,
  iconClassName?: string
}

const ItemProfile = ({ onClick, icon, text, textClassName, iconClassName }: ItemProfileProps) =>
  <MenuItem
    onClick={onClick}>
    <ListItemIcon className={iconClassName ?? "user_menu_icon_primary"}>
      {getIcon(icon)}
    </ListItemIcon>
    <ListItemText primary={text} className={textClassName ?? "user_menu_text_primary"} />
  </MenuItem>

const ProfilMenu = () => {

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [statusApiDialogOpen, setStatusApiDialogOpen] = React.useState(false)
  const [contactSupportDialogOpen, setContactSupportDialogOpen] = React.useState(false)
  const open = Boolean(anchorEl)
  const [anchorElLang, setAnchorElLang] = React.useState(null)
  const [anchorElNotification, setAnchorElNotification] = React.useState(null)
  const [notifications, setNotifications] = React.useState([]);
  const openNotification = Boolean(anchorElNotification)
  const openLang = Boolean(anchorElLang)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseLang = () => {
    setAnchorElLang(null)
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClickNotification = (event: any) => {
    setAnchorElNotification(event.currentTarget)
    setNotifications(GetLocalStorageNotifications(10))
  }
  const handleCloseNotification = () => {
    setAnchorElNotification(null)
  }
  const handleClickLang = (event: any) => {
    setAnchorElLang(event.currentTarget)
  }
  const capitalize = (s: any) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const MenuLanguage = () =>
    <Menu
      id="menuLanguage"
      anchorEl={anchorElLang}
      keepMounted
      open={openLang}
      onClose={handleCloseLang}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{}} >
      <MenuItem key={0} onClick={async () => { await SetCurrentLocale('fr-FR'); window.location.reload(); }}>
        {Trad("french")}
      </MenuItem>
      <MenuItem key={1} onClick={async () => { await SetCurrentLocale('en-US'); window.location.reload(); }}>
        {Trad("english")}
      </MenuItem>
      <MenuItem key={2} onClick={async () => { await SetCurrentLocale('en-GB'); window.location.reload(); }}>
        {Trad("english_british")}
      </MenuItem>
    </Menu>

  const MenuUser = () =>
    <Menu
      id="menuUser"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: 194,
          marginTop: '2.5%'
        },
      }} >

      <ItemProfile
        text={Trad("my_profile")}
        icon="person"
        onClick={() => { history.push('/profile'); handleClose(); }} />

      <ItemProfile
        text={Trad("ContactSupport")}
        icon="contactSupport"
        onClick={() => { setContactSupportDialogOpen(true); handleClose(); }} />

      <ItemProfile
        text={Trad("about")}
        icon="info"
        onClick={() => { setDialogOpen(true); handleClose(); }} />

      <ItemProfile
        text={Trad("status_api")}
        icon="info"
        onClick={() => { setStatusApiDialogOpen(true); handleClose(); }} />

      {dialogOpen && <AboutDialog open={dialogOpen} onClose={() => { setDialogOpen(false) }} />}
      {statusApiDialogOpen && <StatusAPIDialog onClose={() => setStatusApiDialogOpen(false)} />}
      <ContactSupportDialog title={Trad("ContactSupport")} open={contactSupportDialogOpen} handleClose={() => { setContactSupportDialogOpen(false) }} />
      <Divider className="user_menu_divider" />

      <ItemProfile
        text={Trad("logout")}
        textClassName="user_menu_text_error"
        iconClassName="user_menu_icon_error"
        icon="power"
        onClick={() => Client.logout().finally(() => window.location.reload())} />

    </Menu>

  const MenuNotifications = () =>
    <Menu
      id="notification"
      anchorEl={anchorElNotification}
      keepMounted
      open={openNotification}
      onClose={handleCloseNotification}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          marginTop: '2.5%',
          width: 300,
        },
      }}
    >
      <div>
        <ListView
          data={notifications}
          item={MyItemRender}
        />
      </div>
    </Menu>

  const MyItemRender = (props: ListViewItemProps) => {
    let item = props.dataItem;
    return (
      <div style={{ margin: 12 }} >
        <div style={{ overflow: 'hidden', justifyContent: 'left', borderBottom: "solid #F0F0F0" }} className={" k-listview-item row p-2 border-bottom align-middle "}>

          <div style={{ fontSize: 12, marginRight: 12 }} >
            {item.type === "error" && <CloseOutlinedIcon className={"img-notification img-back-notif-" + item.type}></CloseOutlinedIcon>}
            {item.type === "warning" && <WarningOutlinedIcon className={"img-notification img-back-notif-" + item.type}></WarningOutlinedIcon>}
            {item.type === "info" && <InfoOutlinedIcon className={"img-notification img-back-notif-" + item.type}></InfoOutlinedIcon>}
            {item.type === "success" && <CheckOutlinedIcon className={"img-notification img-back-notif-" + item.type}></CheckOutlinedIcon>}
          </div>

          <div>
            <p style={{ margin: 0 }}>{item.msg}</p>
            <span style={{ fontSize: 12 }}>{moment.utc(item.date).local().startOf('seconds').fromNow()}
            </span>
          </div>

        </div>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <UserInfos />
      <IconButton
        aria-label="more"
        aria-controls="menuUser"
        aria-haspopup="true"
        onClick={handleClick}
        id="settings_expand"
      >
        {getIcon("more_menu")}
      </IconButton>
      <IconButton
        aria-label="more"
        aria-controls="notification"
        aria-haspopup="true"
        onClick={handleClickNotification}
        id="notif_expand"
      >
        {getIcon("notifications")}
      </IconButton>
      <Typography variant="body1" style={{ marginRight: 0 }}>
        {Trad(capitalize(GetCurrentLocale()))}
      </Typography>
      <IconButton
        aria-label="more"
        aria-controls="menuLanguage"
        aria-haspopup="true"
        onClick={handleClickLang}
        id="language_expand"
      >
        {getIcon("more")}
      </IconButton>

      <MenuLanguage />
      <MenuUser />
      <MenuNotifications />

    </div>
  )
}
export default ProfilMenu