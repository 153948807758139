import { Referentials } from "./orientdb/Referentials.bin";
import { ref_BroadcastAreasId } from "./orientdb/ref_BroadcastAreas.bin";
import { ref_SupportsId } from "./orientdb/ref_Supports.bin";
import { V } from "./orientdb/V.bin";

export class ref_Publications extends V implements Referentials {
    Active: boolean;
    Key: string;
    Start: Date;
    End: Date;
    Publication: Date;
    Dated: string;
    TechnicalClosure: Date;
    CommercialClosure: Date;
    Created_at?: string;
    Reference: string;
    Theme: string;
    BroadcastArea: ref_BroadcastAreasId;
    Support: ref_SupportsId;
    DataImported: { [key: string]: any }[];

    Source?: "src_ADWANTED" | "src_CODIPRESSE" | "src_TSM";
}