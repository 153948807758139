import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_DiscountTypesId = rid;

export class ref_DiscountTypes extends V implements Referentials {
	"@rid"?: ref_DiscountTypesId;
	Name: string;
	Active: boolean;

}