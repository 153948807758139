import * as React from "react";
import { Trad } from "trad-lib";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { VertexAutocomplete } from "adwone-lib/index";
import { InfoAdornment } from "../../InfoAdornment";
import { setMessage } from "../../../../../redux/messageEditorSlice";
import { AgreementTooltip } from "../AgreementTooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../../redux/store";
import { compareObjects, GetHashCode } from "hub-lib/tools.bin";

export function AgreementSelector() {

    const dispatch = useDispatch();
    const currentAgreement = useSelector((root: RootState) => root.messageEditor.message.Agreement);
    const agreements = useSelector((root: RootState) => root.messageEditor.agreements, compareObjects);
    const defaultValue = agreements?.find(a => a["@rid"] == currentAgreement);

    return <VertexAutocomplete
        key={`AgreementSelector-${GetHashCode({ currentAgreement, opts: agreements?.map(a => a["@rid"]) })}`}
        {...((currentAgreement && {
            startAdornment:
                <InfoAdornment element={() => <AgreementTooltip agreement={currentAgreement} documentType={ref_Agreements.name} />} />
        }) ?? {})}
        warning={defaultValue?.["warning"] || (!defaultValue && currentAgreement)}
        onResetValue={() => null}
        options={agreements ?? []}
        getOptionLabel={(a: ref_Agreements) => a.Name ?? `Accord_${a["@rid"]}`}
        label={Trad("agreement")}
        defaultValue={() => defaultValue}
        onChange={(value: ref_Agreements) => {
            const message = store.getState().messageEditor.getMessage();
            if (value) message.Agreement = value["@rid"];
            else message.Agreement = null;
            dispatch(setMessage(message));
        }} />
}