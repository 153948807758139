import { Grid } from '@material-ui/core';
import * as React from 'react'
import Template from '../Template';
import { ref_CustomersExtended } from 'hub-lib/models/custom/ref_CustomersExtended.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { Client } from 'hub-lib/client/client.bin';
import { Trad } from 'trad-lib';
import Loader from '../Loader';
import { VertexAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { UserRights } from 'hub-lib/models/orientdb/UserRights.bin';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { BreadcrumbsCustomContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from '../../VertexGrid/Generic/ToolbarAdw';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { ListBoxRights, ListItemContext, TreeRights } from '../Tools/ListBoxItemTools.bin';
import { BroadcastAreaExtended } from 'hub-lib/models/BroadcastAreaExtended.bin';
import { ref_Modules } from 'hub-lib/models/orientdb/ref_Modules.bin';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';

export class CustomerRightsExtended {
    AllAdvertiserGroups: boolean = false;
    AllBroadcastAreas: boolean = false;
    lnk_AdvertiserGroupRight: [] = []
    lnk_AdvertiserRight: [] = []
    lnk_BroadcastAreaRight: [] = []
}

export class TRightsState {
    selected: number = 0
    advertiserGroups: ref_AdvertiserGroups[]
    advertisers: ref_Advertisers[]
    noParentAdvertisers: ref_Advertisers[]
    broadcastareas: BroadcastAreaExtended[]
    moduleInternational: ref_Modules
    advertiserGroupRights: any = undefined
    Rights: any = undefined
    RightsCopy: any = undefined
    loading: boolean = true
}

class TState extends TRightsState {
    customerSelected: ref_CustomersExtended = undefined
    dialogOpen: boolean = false
    disableSaveButton: boolean = false
}

export const FakeAdvertiserGroup = {
    "@rid": "#-1:-1",
    Name: Trad("Autres"),
    Active: true
}

export function AdvertiserGroupsData(advertiserGroups: any[], noParentAdvertisers: any[]): any[] {
    return [...(noParentAdvertisers.length ? [FakeAdvertiserGroup] : []), ...advertiserGroups]
}

export async function HandleChangeAdvertiserGroup(dataItem: any, state: TRightsState, refreshState: (any) => void) {

    if (dataItem["@rid"] == FakeAdvertiserGroup['@rid']) {
        refreshState({
            advertiserGroupRights: { "@rid": dataItem["@rid"], AllAdvertisers: state.Rights?.AllAdvertiserGroups && !(state.Rights?.lnk_AdvertiserRight?.length > 0) },
            advertisers: state.noParentAdvertisers
        });
        return;
    }
    let advertiserGroupRights = state.Rights?.lnk_AdvertiserGroupRight?.find((element: any) => element["@rid"] == dataItem["@rid"]);
    if (!advertiserGroupRights && state.Rights?.AllAdvertiserGroups)
        advertiserGroupRights = { "@rid": dataItem["@rid"], AllAdvertisers: true };
    if (advertiserGroupRights) {
        const advertisers = (await Client.searchVertex(ref_Advertisers.name, { parents: dataItem["@rid"] })).data.results;

        if (state.Rights?.AllAdvertiserGroups && state.Rights?.lnk_AdvertiserRight) {
            //Si "tout les groupes annonceurs" est coché alors pour le groupe annonceur courant,
            //Alors "tous les annonceurs" est coché s'il n'existe aucun lien sur les annonceurs ce groupe
            const advRids = state.Rights?.lnk_AdvertiserRight.map(l => l["@rid"]);
            advertiserGroupRights["AllAdvertisers"] = !advertisers.some(o => advRids.includes(o["@rid"]));
        }
        refreshState({
            advertiserGroupRights: advertiserGroupRights,
            advertisers: advertisers
        })
    }
    else
        refreshState({ advertiserGroupRights: undefined });
}
class RightsOfCustomers extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        let newstate = new TState()
        this.state = newstate
    }

    componentDidMount() {
        return Promise.all([
            Client.searchVertex(ref_AdvertiserGroups.name),
            Client.searchVertex(BroadcastAreaExtended.name, { deep: true }),
            Client.searchVertex<ref_Modules>(ref_Modules.name, { Name: "International" }),
            Client.searchVertex(ref_Advertisers.name, { "noParent": true }),
        ]).then(res => this.setState({
            advertiserGroups: AdvertiserGroupsData(res[0].data.results, res[3].data.results),
            broadcastareas: res[1].data.results,
            moduleInternational: res[2].data.results?.[0],
            noParentAdvertisers: res[3].data.results,
            loading: false
        }));
    }

    initializeData = () => {
        if (this.state.customerSelected.Rights) {
            Client.searchVertex(UserRights.name, { "@rid": this.state.customerSelected?.Rights })
                .then(r => {
                    this.setState({
                        Rights: r.data.results[0],
                        RightsCopy: JSON.parse(JSON.stringify(r.data.results[0]))
                    }
                    )
                })
        }
        else {
            this.setState({ Rights: new CustomerRightsExtended(), RightsCopy: new CustomerRightsExtended() })
        }
    }

    submitChanges = () =>
        this.setState({ disableSaveButton: true }, async () => {
            try {
                await Client.updateVertex(UserRights.name, { ...this.state.Rights, customer: this.state.customerSelected['@rid'] })
            } catch (error) {
                console.error(error);
            }
            this.setState({
                dialogOpen: false,
                disableSaveButton: false
            }, () => this.initializeData());
        })

    handleSelect = (e: TabStripSelectEventArguments) => {
        this.setState({ selected: e.selected });
    };

    render() {
        if (this.state.loading)
            return <Loader />
        let { Rights, RightsCopy, disableSaveButton, advertiserGroupRights, customerSelected, moduleInternational } = this.state
        const broadcastAreaContext: ListItemContext = {
            label: Trad("all_broadcastareas"),
            Rights: Rights,
            data: this.state.broadcastareas,
            allRights: Rights,
            allProperty: "AllBroadcastAreas",
            lnkProperties: ["lnk_BroadcastAreaRight"],
            enabledProps: customerSelected?.lnkHasModule?.some(m => m.out == moduleInternational["@rid"]) ? {} : { Currency: customerSelected?.["Currency"] }
        };
        const advertiserGroupContext: ListItemContext = {
            label: Trad("all_advertiserGroups"),
            Rights: Rights,
            data: this.state.advertiserGroups,
            allRights: Rights,
            allProperty: "AllAdvertiserGroups",
            lnkProperties: ["lnk_AdvertiserGroupRight", "lnk_AdvertiserRight"],
            parentRid: advertiserGroupRights?.["@rid"],
            enabledProps: { AllAdvertisers: true }
        };
        const advertiserContext: ListItemContext = {
            label: Trad("all_advertisers"),
            Rights: Rights,
            data: this.state.advertisers,
            allRights: advertiserGroupRights,
            allProperty: "AllAdvertisers",
            lnkProperties: ["lnk_AdvertiserRight"]
        };
        return (
            <>
                <div className="grid_container">
                    <div style={{ width: '100%' }}>
                        <ToolbarAdw>
                            <ToolbarContainer>
                                <SelectedItemsContainer>
                                    <div style={{ width: "300px" }}>
                                        <VertexAutocomplete
                                            label={Trad("customer_to_manage")}
                                            params={{ properties: ["*", "Company.Name as Name", "Rights"] }}
                                            onChange={(value: ref_CustomersExtended) => {
                                                this.setState({ customerSelected: value, Rights: undefined, RightsCopy: undefined, advertiserGroupRights: undefined },
                                                    () => { this.initializeData() })
                                            }}
                                            type={ref_CustomersExtended.name}
                                            afterLoadFilter={(customers: any[]) => customers.filter(c => !c.SSO).sort((a, b) => a.Name.localeCompare(b.Name))}
                                            disableClearable
                                        />
                                    </div>
                                </SelectedItemsContainer>
                                <BreadcrumbsCustomContainer>
                                    <BreadcrumbsCustom hasSelectedItems={true}
                                        elements={[
                                            { text: Trad("home"), href: "/" },
                                            { text: Trad("wallet_customer") }
                                        ]} />
                                </BreadcrumbsCustomContainer>
                            </ToolbarContainer>
                        </ToolbarAdw>
                        {Rights &&
                            <>
                                <TabStrip className="tabpanel_fullwidth" selected={this.state.selected} onSelect={this.handleSelect}>
                                    <TabStripTab title={Trad("broadcast_areas")}>
                                        <TreeRights context={broadcastAreaContext} onSwitchChange={(e) => this.forceUpdate()} />
                                        {/*<ListBoxRights context={broadcastAreaContext} onSwitchChange={(e) => this.forceUpdate()} />*/}
                                    </TabStripTab>
                                    <TabStripTab title={Trad("advertisers_and_advertisergroups")}>
                                        <Grid container item xs={12}>
                                            <ListBoxRights context={advertiserGroupContext}
                                                onItemClick={(props) => HandleChangeAdvertiserGroup(props.dataItem, this.state, (p: any) => this.setState(p))}
                                                onSwitchChange={(e) => this.setState({ advertiserGroupRights: undefined })} />
                                            <ListBoxRights context={advertiserContext} className="message_details_rightcombo"
                                                onSwitchChange={(event, dataItem) => {
                                                    if (dataItem && !event.target.value)
                                                        Rights.lnk_AdvertiserRight = Rights.lnk_AdvertiserRight.filter(e => e["@rid"] != dataItem["@rid"])
                                                    this.forceUpdate();
                                                }} />
                                        </Grid>
                                    </TabStripTab>
                                </TabStrip>
                                <GenericDialog
                                    dialogTitle={Trad('confirmation')}
                                    open={this.state.dialogOpen}
                                    actions
                                    disablePrimaryButton={disableSaveButton}
                                    dialogContent={<p>{Trad("alert_modification_rights_customers")}</p>}
                                    submitAction={() => this.submitChanges()}
                                    submitTitle={disableSaveButton ? Trad("modification_progress") : Trad("change_rights")}
                                    cancelAction={() => this.setState({ dialogOpen: false })}
                                />
                                <div className="adw-form-action">
                                    <CustomButton
                                        Label={Trad("save")}
                                        disabled={JSON.stringify(Rights) === JSON.stringify(RightsCopy)}
                                        className="custom_btn_primary_validation"
                                        onClick={() => this.setState({ dialogOpen: true })}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Template(RightsOfCustomers);