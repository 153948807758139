import { classType, rid } from "./orientdb/CommonTypes.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { ref_AdvertiserGroups } from "./orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "./orientdb/ref_Advertisers.bin";
import { ref_Property } from "./orientdb/ref_Property.bin";
import { ref_Supports } from "./orientdb/ref_Supports.bin";
import { V } from "./orientdb/V.bin";

export class ref_GroupsOld extends V implements Referentials {
    '@rid'?: rid;
    Active: boolean;
    Name: string;
    Class: classType;
    Type?: string;
    Default?: boolean;
    Advertisers?: ref_Advertisers['@rid'][];
    IDs: rid[];
}

export enum eGroupCategories {
    MediaFamily = "MediaFamily",
    SupportCategory = "SupportCategory",
    PlacementCategory = "PlacementCategory",
    Magasin = "Magasin",
}

export type GroupOf = {
    Class: classType; // ref_Supports
    Type?: string; // Emplacement
};

export type GroupArea = {
    IDs: rid[];
} & GroupOf;

export class ref_Groups extends V implements Referentials {
    "@rid"?: rid;
    Active: boolean;
    Name: string;
    Default?: boolean;
    Category: eGroupCategories;
    Of: GroupOf;
    IDs: rid[];
    Area?: GroupArea[];
}
export class MediaFamilyGroup extends ref_Groups {
    Category = eGroupCategories.MediaFamily;
    Of = {
        Class: ref_Supports.name,
    };
}
export class SupportCategoryGroup extends ref_Groups {
    Category = eGroupCategories.SupportCategory;
    Of = {
        Class: ref_Supports.name,
    };
}
export class PlacementCategoryGroup extends ref_Groups {
    Category = eGroupCategories.PlacementCategory;
    Of = {
        Class: ref_Property.name,
        Type: "Emplacement",
    };
}
export class MagasinGroup extends ref_Groups {
    Category = eGroupCategories.Magasin;
    Of = {
        Class: ref_Advertisers.name,
    };
}

export type GroupClass = {
    Hierarchy?: {
        params?: any;
        class?: string;
        filter?: (e: any) => any;
    };
} & ref_Groups;

export const hierarchyAdvertisers: GroupClass["Hierarchy"] = {
    class: ref_AdvertiserGroups.name,
    params: {
        Active: true,
        deep: true,
        format: { children: "items" },
    },
    filter: (e) => [ref_AdvertiserGroups.name, ref_Advertisers.name].includes(e["@class"]),
};

export const GroupClassList: GroupClass[] = [
    new MediaFamilyGroup(),
    new SupportCategoryGroup(),
    new PlacementCategoryGroup(),
    {
        ...new MagasinGroup(),
        Hierarchy: hierarchyAdvertisers,
    },
];
