import * as React from 'react'
import { Grid } from '@material-ui/core';
import { subMonths, addMonths } from 'date-fns';
import { GetToday } from 'tools-lib';
import { Trad } from 'trad-lib';
import { FilterStorage } from '../../../utils/localstorage.bin';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import { ref_AdvertiserGroupsId } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_AdvertisersId } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_BrandsId } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_ProductsId } from 'hub-lib/models/orientdb/ref_Products.bin';
import { AdvertiserHierarchyComponent, AdvertiserHierarchyComponentProps } from '../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent';
import { StoreFiltersCampaignsSearch } from '../AdvertiserHierarchyComponent/StoreFilters';
import { clearEmptyValues } from 'hub-lib/tools.bin';

export class CampaignFilterRids {
    Brand?: ref_BrandsId | ref_BrandsId[] = undefined;
    Advertiser?: ref_AdvertisersId | ref_AdvertisersId[] = undefined;
    AdvertiserGroup?: ref_AdvertiserGroupsId | ref_AdvertiserGroupsId[] = undefined;
    Product?: ref_ProductsId | ref_ProductsId[] = undefined;
}

export class CampaignFilter extends CampaignFilterRids {
    Start?: Date;
    End?: Date;
}

export class SearchProps {
    onFilterChanged?: (filter: CampaignFilter) => void;
    onComponentDidMount?: (filter: CampaignFilter) => void;
    styles?: React.CSSProperties;
    filter?: AdvertiserHierarchyComponentProps["store"];
}

export function CampaignSearch({ styles, filter: _filter, onFilterChanged }: SearchProps) {

    const filter: AdvertiserHierarchyComponentProps["store"] = _filter ?? { ...FilterStorage.GetLocalStorageFiltersObj() };

    let date = GetToday();
    let year = date.getFullYear();
    let month = date.getMonth();

    return <Grid style={styles ?? { width: 700 }} container item xs={12}>
        <div className='adw-title'>{Trad('campaign_advanced_filters')}</div>
        <Grid item xs={12} className='adw-row'>
            <DateRangepicker
                defaultStart={filter.Start ?? subMonths(new Date(year, month, 1), 5)}
                defaultStop={filter.End ?? addMonths(new Date(year, month, 1), 10)}
                handleChangeDate={(e: { start: Date, end: Date }) => {
                    filter.Start = new Date(e.start);
                    filter.End = new Date(e.end);
                    onFilterChanged?.(filter);
                }} />
        </Grid >

        <AdvertiserHierarchyComponent
            hiddenKeys={['Campaign']}
            reduxStore={StoreFiltersCampaignsSearch}
            store={filter}
            onChange={store => {
                const newStore: any = clearEmptyValues(store);
                newStore.Start = filter.Start;
                newStore.End = filter.End;
                onFilterChanged?.(newStore);
            }} />
    </Grid >
}