import React from 'react'
import Template from '../Template'
import { SuperUsersGrid } from '../../VertexGrid/Adwone-admin/SuperUsersGrid.bin';

const SuperUsers = () => {
    return (
        <>
            <div className="grid_container">
                <SuperUsersGrid />
            </div>
        </>
    );
}

export default Template(SuperUsers);