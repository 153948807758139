import * as React from 'react'
import Template from '../Template'
import { DashBoardGridLayout } from '../../GridLayout/DashBoardGridLayout.bin'

import Typography from '@material-ui/core/Typography'
import { Trad } from 'trad-lib';
import Calendar from '../Calendar/Calendar.bin'
import { SearchWidgets } from '../../GridLayout/SearchWidgets.bin'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { UserExtended } from 'hub-lib/models/UserExtended.bin';
import { Client } from 'hub-lib/client/client.bin';
import Loader from '../Loader';
import { PassForm } from './PassForm.bin';

class TState {
    user: UserExtended = new UserExtended()
    loading: boolean = true
}

class Home extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        let newState = new TState()
        this.state = newState
    }
    async componentDidMount() {

        // let user = localStorage.getItem('user')
        // Client.searchVertex(UserExtended.name, {"@rid": JSON.parse(user)["@rid"]}).then(r=>{
        const user = (await Client.getUser()).user;
        this.setState({
            user,
            loading: false
        })
        // })
    }
    render() {

        if (this.state.loading)
            return <Loader />;
        let { user } = this.state
        return (
            <div className="home_container">
                <div className="widgets_container">
                    <div className="title">
                        <span>{Trad("my_dashboard")}</span>
                        <span className="SearchWidgets">
                            <SearchWidgets />
                        </span>
                    </div>
                    <DashBoardGridLayout />
                </div>
                <div className="calendar_container">
                    <div className='title'>
                        <span>{Trad("calendar")}</span>
                    </div>
                    <Calendar />
                </div>
                <GenericDialog
                    open={(user.isDefaultPassword && user.authentication === "AdwOne") ?? false}
                    dialogTitle={Trad("title_change_password")}
                    disableCancel
                    id="change_pass_dialog"
                    dialogContent={
                        <PassForm user={user}>
                        </PassForm>
                    }
                />
            </div>
        )
    }
}

export default Template(Home);
