import * as React from "react";
import { GenericPopover } from "./GenericPopover.bin";
import ReactJson from "react-json-view";

let anchorId = 0;

type JSONViewerType = { data: any }
export function JSONViewer({ data }: JSONViewerType) {
    const [anchorKey] = React.useState(`JSONViewer-${anchorId++}`);
    return <div id={anchorKey} style={{ textAlign: "center" }}>
        <GenericPopover anchorParent={anchorKey} icon="zoom">
            <ReactJson src={data} displayDataTypes={false} sortKeys displayObjectSize={false} />
        </GenericPopover>
    </div>
}