import EventEmitter from "events";
import { IRid } from "hub-lib/models/IRid.bin";
import { IAdvertiserHierarchy } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import React, { useEffect } from "react";
import { Trad } from "trad-lib";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { eDialogMode, GenericDialog } from "../ConfigurableComponents/GenericDialog.bin";
import { CampaignForm, DicoCreateTrad, DicoTitleCampaignTrad } from "../VertexGrid/Campaigns/CampaignForm";
import { EstimateMode } from "../VertexGrid/Campaigns/EstimateMode";
import { MultiAdvertisersMode } from "../VertexGrid/Campaigns/MultiAdvertisersMode";

const submitEvent = new EventEmitter();
type NewCampaignButtonProps = { store: IAdvertiserHierarchy & { Campaign?: IRid["@rid"] }, onChange: (campaign: ref_Campaigns) => void }
export function NewCampaignButton({ store, onChange }: NewCampaignButtonProps) {

    const [open, setOpen] = React.useState(false);
    const [multiAdvertisers, setMultiAdvertisers] = React.useState(false);
    const [estimateMode, setEstimateMode] = React.useState(false);

    return <div>
        <CustomButton
            className="custom_btn_secondary_white"
            style={{ marginLeft: "auto", width: 220 }}
            Label={Trad("create_campaign")}
            onClick={() => setOpen(true)} />

        <GenericDialog
            dialogTitle=""
            titleClass='display-none'
            id="messages_dialog_createupdate"
            dialogClassname="messages"
            submitClass={'custom_btn_primary_validation'}
            open={open}
            actions
            submitTitle={DicoCreateTrad()[eDialogMode.create]}
            submitAction={() => submitEvent.emit("submit")}
            handleClose={() => setOpen(false)}
        // dialogTitle={<div>{DicoTitleCampaignTrad()[eDialogMode.create]}</div>}
        >

            <div className="multiAdvertisersMode-component" style={{ zIndex: 9000, padding: 8, top: 0 }}>
                <MultiAdvertisersMode
                    multiAdvertisers={multiAdvertisers}
                    onChange={value => setMultiAdvertisers(value)} />
                <EstimateMode
                    estimateMode={estimateMode}
                    onChange={value => setEstimateMode(value)} />
            </div>

            <div style={{ zIndex: 8000, position: "relative", height: "100%" }}>
                <CampaignForm
                    estimatedMode={estimateMode}
                    multiAdvertisers={multiAdvertisers}
                    submitEvent={submitEvent}
                    mode={eDialogMode.create}
                    onSave={(campaign) => {
                        store.Campaign = campaign["@rid"];
                        store.AdvertiserGroup = campaign.AdvertiserGroup;
                        store.Advertiser = campaign.Advertiser;
                        store.Brand = campaign.Brand;
                        store.Product = campaign.Product;
                        setOpen(false);
                        onChange?.(campaign);
                    }}
                    onCancel={() => setOpen(false)} />
            </div>
        </GenericDialog>
    </div>
}