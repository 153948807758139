import React, { useEffect, useState } from "react";
import { ADWGrid, AdwRow, eComputeEvent } from "adwone-lib";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GetSubElement } from "hub-lib/tools.bin";
import { TdCell } from "../CustomCell.bin";
import { Indicateur } from "adwone-engine/index.bin";

export class VertexRidCellArgs<TRow> {

    indicateur: Indicateur;
    /**
     * ComboBox choices
     */
    dataProvider: (row: any) => Promise<any[]> | any[];

    dataProviderFormater: (data: any) => string;

    bindingField?: string;

    isValid?: (value: any) => boolean;

    cellValue?: (cellValue: any, dataItem?: AdwRow<TRow>) => Promise<any>;

    cellContent?: (cell: { Formated: string, Value: any }, row: AdwRow<TRow>) => Promise<any>;

    editable?: boolean;

    onChange?: (row: any, field: string) => any;

    frozen?: "left" | "right";
    frozenLeftPx?: number;
    frozenRightPx?: number;

    className?: string;

    classNameProvider?: (row: AdwRow<TRow>) => string;

    grid?: { selectRow: (row: any) => any }

    vGrid?: ADWGrid<TRow>;
}

export function BaseCell<TRow>(args: Partial<VertexRidCellArgs<TRow>>) {
    return function (props: GridCellProps) {

        const { dataItem, rowType } = props;
        if (!dataItem || rowType == 'groupHeader')
            return <></>;


        return <LightCell {...props} args={args} />;
    }
}

function LightCell<TRow>(props: GridCellProps & { args: Partial<VertexRidCellArgs<TRow>> }) {

    const { args, dataItem, field } = props;
    if (!dataItem)
        return <td></td>;

    const getCellValue = () => {
        const dataValueBase = GetSubElement(dataItem, field) ?? GetSubElement(dataItem?.dataItem, field);
        const dataValue = dataValueBase ?? {};
        return dataValue;
    }

    const getCellContent = () => {
        const newContent = args?.cellContent ?
            args?.cellContent?.(data, dataItem) :
            data?.Formated ?? "";
        return newContent;
    }

    const [data] = useState<{ Formated: string, Value: any }>(getCellValue());
    const [content, setContent] = useState<any>(getCellContent());

    useEffect(() => {
        const row: AdwRow<any> = dataItem;
        row.event?.addListener(eComputeEvent.forceUpdate, updateContent);
        return () => { row.event?.removeListener(eComputeEvent.forceUpdate, updateContent) };
    })

    const updateContent = () => {
        setContent(getCellContent());
    }

    const getClassName = () => {
        let className = `${args.className ?? ""} `;
        className += args?.classNameProvider ? ` ${args.classNameProvider(dataItem)} ` : '';
        if (args.frozen)
            className += ` k-grid-content-sticky `;
        return className;
    }

    const getStyle = () => {
        let style: React.CSSProperties = undefined;
        if (args.frozen)
            style = { left: args?.frozenLeftPx, right: args?.frozenRightPx };
        return style;
    }

    return <TdCell
        className={getClassName()}
        style={getStyle()}
        grid={args.grid}
        row={props.dataItem}
        tooltipText={data.Formated}>
        <span className={`${args.className ?? ""} ValueCell_${removeSpaces(data?.Value)} `}>
            {content}
        </span>
    </TdCell>
}

// fonction that remove all spaces and tabs from a string
export function removeSpaces(str: string) {
    return str?.replace?.(/\s/g, '') ?? str;
}

