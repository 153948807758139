import { Client } from "hub-lib/client/client.bin";
import { ref_CustomersExtended } from "hub-lib/models/custom/ref_CustomersExtended.bin";
import { eFileType, FileDescriptor } from "hub-lib/models/FileDescriptor.bin";
import React from "react";
import { Notify } from "../../utils/Notify.bin";
import { AdwAutocomplete } from "adwone-lib/index";
import Template from "../layout/Template";

class TState {
    customers: ref_CustomersExtended[];
    selectedCustomer: ref_CustomersExtended;
    selectedCompany: { "@rid": string, Name: string };
    selectedDocument: eFileType;
    companies: {
        "@rid": string;
        Name: any;
    }[];
    selectedFile: any;
    files: FileDescriptor[];
}
export class FilesManagerComponent extends React.Component<any, TState> {

    constructor(props: any) {
        super(props);
        this.state = new TState();
    }

    async componentDidMount() {
        const customers = (await Client.searchVertex(ref_CustomersExtended.name, { properties: ["*", "Company.Name as CompanyName"] })).data.results as ref_CustomersExtended[];
        this.setState({ customers });
    }

    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    };

    onFileUpload = async () => {
        const { selectedCompany, selectedCustomer, selectedDocument } = this.state;

        // Create an object of formData 
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        try {
            await Client.uploadFile(this.state.selectedFile, {
                docType: selectedDocument,
                company: selectedCompany["@rid"],
                Customer: selectedCustomer["@rid"]
            });

            Notify("Ajout avec succes", "success");

        } catch (error) {
            Notify("Ajout impossible, voir détails dans la console.", "error");
            console.error(error);
        }

        this.updateFiles();
    };

    updateFiles = async () => {
        const { selectedCompany, selectedCustomer, selectedDocument } = this.state;

        if (!selectedCompany || !selectedCustomer || !selectedDocument)
            return;

        const files = (await Client.searchFiles({ Customer: selectedCustomer["@rid"], company: selectedCompany["@rid"], docType: selectedDocument }, true)).data;
        this.setState({ files });
    }

    removeFile = (fileId) => async () => {
        const { selectedCompany, selectedCustomer, selectedDocument } = this.state;

        if (!selectedCompany || !selectedCustomer || !selectedDocument)
            return;

        await Client.deleteFiles({ Customer: selectedCustomer["@rid"], company: selectedCompany["@rid"], docType: selectedDocument, _id: fileId })
        this.updateFiles();
    }

    downloadFile = (file: FileDescriptor, params: any) => async () => {
        await Client.dowloadFile({ _id: file._id, filename: file.filename, ...params })
    }

    render() {

        const {
            customers,
            selectedCustomer,
            selectedCompany,
            companies,
            selectedDocument,
            files } = this.state;

        return <div key={`selectedCompany-${selectedCustomer?.["@rid"]}`}>
            {<AdwAutocomplete
                value={selectedCustomer}
                options={customers}
                getOptionLabel={(option: any) => option.CompanyName}
                style={{ width: 300 }}
                onChange={(event: any, value: any) => {
                    const allCompanies = value ? [{ "@rid": value.Company, Name: value.CompanyName }, ...(value?.Children ?? [])] : []
                    this.setState({
                        selectedCustomer: value,
                        selectedCompany: allCompanies[0],
                        companies: allCompanies
                    }, () => this.updateFiles())
                }} />}

            {<AdwAutocomplete
                value={selectedCompany}
                options={companies ?? []}
                getOptionLabel={(option) => option.Name}
                style={{ width: 300 }}
                onChange={(event: any, value: any) => this.setState({ selectedCompany: value }, () => this.updateFiles())} />}


            {<AdwAutocomplete
                value={selectedDocument}
                options={Object.keys(eFileType)}
                style={{ width: 300 }}
                onChange={(event: any, value: any) => this.setState({ selectedDocument: value }, () => this.updateFiles())} />}


            <div>
                <input type="file" onChange={this.onFileChange} />
                <button onClick={this.onFileUpload}>
                    Upload!
                </button>
            </div>

            {<div style={{ marginTop: 50 }}>

                <div style={{ display: "inline" }}>
                    <button disabled>supprimer</button>
                    <button onClick={async () => { await Client.dowloadFile(); }} style={{ marginLeft: 10 }}>télécharger</button>
                    <span style={{ marginLeft: 10 }}>default.xlsx</span>
                </div>


                {files?.map(f => <div key={f._id}>
                    <div style={{ display: "inline" }}>
                        <button onClick={this.removeFile(f._id)}>supprimer</button>
                        <button onClick={this.downloadFile(f, { Customer: selectedCustomer["@rid"] })} style={{ marginLeft: 10 }}>télécharger</button>
                        <span style={{ marginLeft: 10 }}>{f.filename}</span>
                    </div>

                </div>)}
            </div>}
        </div>
    }
}

const FilesManager = () => {
    return (
        <>
            <div className="grid_container">
                <FilesManagerComponent />
            </div>
        </>
    );
}

export default Template(FilesManager);