import * as React from "react";
import { RootState } from "../../../redux/store";
import { ToolbarAdw } from "../Generic/ToolbarAdw";
import { useSelector } from "react-redux";

type MessagesToolbarArgs = { children: any }
export function MessagesToolbar({ children }: MessagesToolbarArgs) {

    const selectedItems = useSelector((state: RootState) => state.gridMessages?.selectedItems);
    const length = useSelector((state: RootState) => state.gridMessages?.data?.length);

    return <ToolbarAdw includeFilters hasSelectedItems={selectedItems?.length > 0} count={length}>
        {children}
    </ToolbarAdw>
}