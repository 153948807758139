import * as React from "react";
import { getIcon, VertexAutocomplete, DividerWithText } from "adwone-lib";
import { propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { Divider, Grid, GridSize } from "@material-ui/core";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import history from "../../../utils/history";
import { ref_ProjectConfigurations } from "hub-lib/models/orientdb/ref_ProjectConfigurations.bin";
import { FiltersChip } from "./Chips/FiltersChip.bin";
import { TableChip } from "./Chips/TableChip.bin";
import { ExportChip } from "./Chips/ExportChip.bin";
import { ProjectStorage } from "../../../utils/localstorage.bin";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

let timeout: any = null;

export class TProps {
    table?: string = ref_Messages.name;
    urlToRedirect?: string = "/messages";
}

export function ProjectConfigurationWidget({
    table = ref_Messages.name,
    urlToRedirect = "/messages",
}: TProps) {
    const [config, setConfig] = React.useState<ref_ProjectConfigurations>();
    const project = useSelector((root: RootState) => root.project.project);

    React.useEffect(() => {
        setConfig(project);
    }, [project]);

    const [gridSize, setGridSize] = React.useState<GridSize>(4);
    const ref = React.useRef(null);
    const resize = () => {
        if (ref.current) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const size = ref.current.clientWidth;
                if (size > 600) {
                    setGridSize(3);
                } else if (size < 300) {
                    setGridSize(6);
                } else {
                    setGridSize(4);
                }
            }, 100);
        }
    }
    window.removeEventListener('resize', resize);
    window.addEventListener('resize', resize);

    React.useEffect(() => {
        resize();
    });

    return (
        <div className="container-widget">
            <div style={{display: "flex"}}>
                <div style={{ marginRight: 12, width: "-webkit-fill-available"}}>
                    <VertexAutocomplete
                        disableClearable
                        key={`ref_FilterConfigurations-select`}
                        label={Trad("project_model")}
                        type={ref_ProjectConfigurations.name}
                        params={{
                            [propertyOf<ref_ProjectConfigurations>("Table")]: table,
                            Active: true,
                            properties: ["*"]
                        }}
                        onChange={(conf: ref_ProjectConfigurations) => {
                            setConfig(conf);
                        }}
                        defaultValue={() => config}
                    />
                </div>
                <div style={{minWidth: "fit-content"}}>
                    <CustomButton
                        startIcon={getIcon("next")}
                        Label={Trad(`go_to_${table}`)}
                        className="custom_btn_primary"
                        onClick={() => {
                            history.push(`${urlToRedirect}?project=${config["@rid"] ?? "default"}`);
                        }}
                    />
                </div>
            </div>
            {config && (
                <>
                    <div className="widget-chips-scroll" ref={ref} key={config.ConfTable + config.ConfFilter + config.ConfExport}>
                        {config.ConfTableActive && <TableChip id={config.ConfTable} gridSize={gridSize} />}
                        {config.ConfFilterActive && <FiltersChip id={config.ConfFilter} table={table} gridSize={gridSize} />}
                        {config.ConfExportActive && <ExportChip id={config.ConfExport} gridSize={gridSize} />}
                    </div>
                </>
            )}
        </div>
    );
}
