
import { vertexName } from "./types/vertex.bin";
import { referentialLink } from "./link.bin";
import { ReferentialHasViews } from "./orientdb/ReferentialHasViews.bin";
import { Views } from "./orientdb/Views.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { IRid } from "./IRid.bin";
import { ClassProperty, ePropType } from "./VertexProperty.bin";

export type PropertyName = string;

export enum eCode {
  Unknown = 'Unknown',
  System = 'System',
  Validation = 'Validation',
  Restricted = 'Restricted',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Persist = "Persist"
}

export enum eBusinessCode {
  UnknownError = 'UnknownError',
  MissingProperty = 'MissingProperty',
  AlreadyExist = 'AlreadyExist',
  RecordNotFound = 'RecordNotFound',
  Unautorized = 'Unautorized',
  BadProperty = 'BadProperty'
}

export class HubResponse {
  success?: "OK" | "KO";
  error?: {
    code: eCode,
    data?: any
  }
}

export function GetSuffixe(type: eColumnType) {
  return {
    [eColumnType.DiscountFO]: " FO",
    [eColumnType.DiscountFOS]: " FOS",
    [eColumnType.DiscountFOValue]: " FO",
    [eColumnType.DiscountFOSValue]: " FOS",
    [eColumnType.DiscountFOValueBound]: " FO"
  }[type] ?? "";
}

export function IsBound(type: eColumnType) {
  return [eColumnType.DiscountValueBound, eColumnType.DiscountFOValueBound].includes(type);
}

export function IsDiscount(type: eColumnType) {
  return ([
    eColumnType.Discount,
    eColumnType.DiscountFO,
    eColumnType.DiscountFOS,
    eColumnType.DiscountValue,
    eColumnType.DiscountFOValue,
    eColumnType.DiscountFOSValue,
    eColumnType.DiscountValueBound,
    eColumnType.DiscountFOValueBound].includes(type));
}

export function IsDiscountRateColumn(type: eColumnType) {
  return ([
    eColumnType.Discount,
    eColumnType.DiscountFO,
    eColumnType.DiscountFOS].includes(type));
}

export function IsDiscountCO(type: eColumnType) {
  return ([
    eColumnType.Discount,
    eColumnType.DiscountValue,
    eColumnType.DiscountValueBound].includes(type));
}

export function IsDiscountFO(type: eColumnType) {
  return ([
    eColumnType.DiscountFO,
    eColumnType.DiscountFOValue,
    eColumnType.DiscountFOValueBound].includes(type));
}

export function IsDiscountFOS(type: eColumnType) {
  return ([
    eColumnType.DiscountFOS,
    eColumnType.DiscountFOSValue].includes(type));
}

export enum eColumnType {
  KPI = "KPI",
  Barter = "Barter",
  Property = "Property",
  PriceReturned = "PriceReturned",
  PriceBound = "PriceBound",
  Discount = "Discount",
  DiscountFO = "DiscountFO",
  DiscountFOS = "DiscountFOS",
  DiscountValue = "DiscountValue",
  DiscountFOValue = "DiscountFOValue",
  DiscountFOSValue = "DiscountFOSValue",
  DiscountValueBound = "DiscountValueBound",
  DiscountFOValueBound = "DiscountFOValueBound",
}

export type DuplicationCampaignArgs = {
  messages?: boolean,
  messagesWithStatus?: boolean,
  messagesCanceled?: boolean,
  broadcastPlacement?: boolean

}

export type UpdateBody<T extends IRid> = T | Partial<T>;

export type CreateBody<T> = T | Partial<T>;

//export type CreateBody = Object | Object[];
export type DeleteBodyContent = rid | rid[];
export type DeleteBody = { "@rid": DeleteBodyContent };

export type CopyBody = {
  "@rid": rid;
  [key: string]: any;
} | CopyBody[];

export type PersistBody = {
  "@rid": rid;
  [key: string]: any;
} | PersistBody[];

export type CancelBody = {
  "@rid": rid;
  [key: string]: any;
} | CancelBody[];

export type linkType = keyof referentialLink;
export type edgeName = string;
export type metaEdgeproperty = { linkedClass: vertexName, name: linkType, "@rid": string };
export type metaEdge = { name: edgeName, properties: metaEdgeproperty[] };

export type searchArg = { texts: string[] };

export class res<T> extends HubResponse {
  results?: T[];
}
export class ValidationResponse extends HubResponse {
  results?: ValidationRules;
}

export type orientResp = res<IRid>;
export type anyResp = res<any>;
export type referentialHasViewsResp = res<ReferentialHasViews<rid, rid, rid>>
export type SearchResponse = res<any>;
export type MetaResp = res<metaEdge>;
export type viewsResp = res<Views>;

export class OrientClassMeta {
  customFields: any;
  defaultClusterId: number;
  strictMode: any;
  description: string;
  abstract: any;
  clusterIds: number[];
  superClass: string;
  name: string;
  clusterSelection: string;
  shortName: string;
  overSize: number;
  properties: any[];
  superClasses: string[];
}

export type ValidationRules = { mandatory: string[], optional: string[] }

export class MetaDataProperty extends ClassProperty {
  name: string;
  type: ePropType;

  /**
   *
   */
  constructor(base?: Partial<MetaDataProperty>) {
    super();
    if (base) {
      Object.entries(base).forEach(([key, value]) => {
        this[key] = value;
      })
    }

  }
}