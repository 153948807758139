import * as React from 'react'
import { RowWrapper } from "../CrossedTableTelerikTree.bin"
import { CreateIndicateur } from 'adwone-engine/index.bin';
import { FilterStorage } from '../../../utils/localstorage.bin';
import { ref_TableConfigurations } from 'hub-lib/models/orientdb/ref_TableConfigurations.bin';
import { IndicateurBase } from 'adwone-engine/index.bin';
import { GetKPITemplate } from 'format-lib/index.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { Trad } from 'trad-lib';

let tooltipid = 0;

export const toolTipGroup = (row: RowWrapper) => {
    return <div key={`tooltip-id-group-${row.id}`}>
        <div className="clearfix">
            <div style={{ float: "left", width: 100, fontSize: '15px', fontWeight: 'bold' }}>{Trad("number_messages") + ':'}</div>
            <div style={{ float: "left", fontSize: '15px', fontWeight: 'bold' }}>{`${row?.Data?.length}`}</div>
        </div>
        {toolTipMessage(row)}
    </div>
}

export const toolTipMessage = (row: RowWrapper) => {
    const configuration: ref_TableConfigurations = FilterStorage.getLocalStorageValue(ref_TableConfigurations.name);
    const indics = (configuration?.Columns ?? []).filter(i => !i.isSchedulerHidden);
    return <div>
        {indics.map((i, idx) => {
            const ind = CreateIndicateur(i);
            if (ind.valueType === eKPIType.Rid)
                return <></>
            return <div key={`tooltip-id-message-${tooltipid++}`} className="clearfix">
                <div style={{ float: "left", width: 100 }}>{`${ind.name}:`}</div>
                <div style={{ float: "left" }}>
                    <IndicateurValue row={row} indicateur={ind} />
                </div>
            </div>
        })}
    </div>
}

type IndicateurValueProps = { row: RowWrapper, indicateur: IndicateurBase };
const IndicateurValue = ({ row, indicateur }: IndicateurValueProps) => {
    const [value, setValue] = React.useState<any>(null);
    React.useEffect(() => {
        if (!value)
            Promise.resolve()
                .then(async () => {
                    const val = await indicateur.Compute(row.Data);
                    const template = GetKPITemplate(indicateur.valueType);
                    setValue(template(val));
                })
    });
    return <div>{value}</div>
}