import { ref_Property, ref_PropertyId } from "./orientdb/ref_Property.bin";

export class FormatExtended {
    "@rid": string;
    Format: ref_PropertyId;
    Couleur?: ref_PropertyId;
    Implantation?: ref_PropertyId;
    Name: string;
    Start: Date;
    End: Date;
}