import React from 'react';
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Favorites } from 'hub-lib/models/orientdb/ref_Favorites.bin';
import { VertexAutocomplete, TPropsRids, VertexAutocompleteDecorator, getIcon, TPropsVertexAuto } from 'adwone-lib'
import { Client } from 'hub-lib/client/client.bin';
import { GetHashCode, propertyOf } from 'hub-lib/tools.bin';
import { Trad } from 'trad-lib';
import { Format } from 'format-lib/index.bin';
import { TooltipManager } from '../CustomTooltip';

export type FavTPropsBase<V> = {
    SetFavorite?: (option: V, isfavorite: boolean) => boolean;
    leftColumns?: ((option: V) => React.ReactNode)[];
    getCategory?: (option: V) => string;
    SortCategories?: string[];
    Sort?: (a: V, b: V, groupA: string, groupB: string) => (number | undefined);
    bindingPath?: string;
};
export type FavTProps<V> = FavTPropsBase<V> & TPropsRids<V> & Partial<TPropsVertexAuto<V>>;

class TState {
    favorites: ref_Favorites = null;
}

export class FavoriteVertexAutoCompleteComponent<V extends IRid> extends React.Component<FavTProps<V>, TState> {
    static defaultProps = { SetFavorite: undefined };

    constructor(props: FavTProps<V>) {
        super(props);
        this.state = new TState();
    }

    async componentDidMount() {
        const { type } = this.props;
        const favorites: ref_Favorites = (await Client.searchVertex<ref_Favorites>(ref_Favorites.name, { [propertyOf<ref_Favorites>('Table')]: type }))?.data?.results[0] ?? this.newFavorite();
        this.setState({ favorites });
    }

    groupBy = (option: V) => {
        const { getCategory } = this.props;
        if (this.IsFavorite(option))
            return Trad('favorite');
        if (getCategory)
            return getCategory(option);
        return Trad('other');
    };

    getOptionLabelCallBack = () => {
        const { customDisplay, needTrad, getOptionLabel } = this.props;
        const getTradOption = (option: IRid | string) => Trad(Format(option));
        const getValueOption = (option: IRid | string) => option[customDisplay];
        const formatOption = (option: IRid | string) => Format(option);
        const noTrad = customDisplay ? getValueOption : formatOption;
        const getTrad = needTrad ? getTradOption : noTrad;
        return getOptionLabel ?? getTrad;
    };

    sortCategory = (a, b) => {
        const { SortCategories, Sort } = this.props;

        const groupA = this.groupBy(a);
        const groupB = this.groupBy(b);

        const customSort = Sort?.(a, b, groupA, groupB);
        if (customSort !== undefined)
            return customSort;

        if (groupA === Trad('favorite')) {
            if (groupB === Trad('favorite'))
                return 0;
            return -1;
        }

        if (groupB === Trad('favorite'))
            return 1;

        if (SortCategories) {
            const idxA = SortCategories?.indexOf(groupA);
            const idxB = SortCategories?.indexOf(groupB);

            if (idxA >= 0) {
                if (idxB >= 0)
                    return idxA - idxB;
                return -1;
            } if (idxB >= 0)
                return 1;
        }

        return groupA.localeCompare(groupB);
    };

    newFavorite() {
        const { type } = this.props;
        const favorite = new ref_Favorites();
        favorite.Table = type;
        favorite.TableIds = [];
        return favorite;
    }

    IsFavorite(option: V) {
        const { bindingPath } = this.props;
        const { favorites } = this.state;
        return favorites?.TableIds?.includes(option[bindingPath ?? '@rid']);
    }

    async updateFavorites() {
        const { favorites } = this.state;
        if (favorites['@rid'])
            await Client.updateVertex(ref_Favorites.name, favorites, false);
        else {
            const newFavorites = (await Client.createVertex(ref_Favorites.name, favorites, false))?.data?.results;
            this.setState({ favorites: newFavorites });
        }
    }

    render() {
        const { bindingPath } = this.props;
        const { favorites } = this.state;
        const { SetFavorite, leftColumns } = this.props;

        return <VertexAutocomplete
            groupBy={this.groupBy as any}
            loading={!favorites}
            disabled={!favorites}
            sort={((options: V[]) => [...options].sort(this.sortCategory)) as any}
            renderOption={((option: V) => {
                const decorator = VertexAutocompleteDecorator.Decorate(option);
                const text = this.getOptionLabelCallBack()(option);
                return <>
                    {decorator}
                    <div
                        className="vertexauto-option vertexauto-option-favorite"
                        style={{ width: '100%', height: '100%' }}
                        onMouseOver={(e) => TooltipManager.Push({ target: e.target, text })}
                    >

                        {leftColumns?.map((col) => col(option))}
                        <span>
                            {text}
                        </span>
                        <span
                            style={{ position: 'absolute', right: 10 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const isFavorite = this.IsFavorite(option);
                                if (!isFavorite)
                                    favorites.TableIds.push(option[bindingPath ?? '@rid']);
                                else
                                    favorites.TableIds = favorites.TableIds.filter((id) => id != option[bindingPath ?? '@rid']);
                                SetFavorite?.(option, !isFavorite);
                                this.forceUpdate();
                                this.updateFavorites();
                            }}
                            className={this.IsFavorite(option) ? 'is_favorite' : 'is_not_favorite'}
                        >
                            {getIcon('star')}
                        </span>
                    </div>
                </>;
            }) as any}
            {...this.props as any}
            refreshOnPropChanged={false}
        />;
    }
}

export class FavoriteVertexAutoComplete<V> extends React.Component<FavTProps<V>, any> {
    constructor(props: FavTProps<V>) {
        super(props);
        this.state = {

        };
    }

    componentDidUpdate(prevProps: FavTProps<V>) {
        const { refreshOnPropChanged, params } = this.props;
        if (refreshOnPropChanged) {
            const paramsSerialized = JSON.stringify(params ?? {});
            if (JSON.stringify(prevProps.params ?? {}) !== paramsSerialized)
                this.setState({ key: GetHashCode(paramsSerialized) });
        }
    }

    render() {
        const { key: keyProp } = this.props;
        const { key } = this.state;
        return <FavoriteVertexAutoCompleteComponent {...(key && { key: `${keyProp ?? ''}-${key}` })} {...this.props as any} />;
    }
}
