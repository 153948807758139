import * as React from "react";
import { PivotGridHeaderCell, PivotGridHeaderCellProps } from "@progress/kendo-react-pivotgrid";
import { TooltipManager } from "adwone-lib";

export function CustomPivotGridHeader(props: PivotGridHeaderCellProps) {
    const { dataItem } = props;
    const chunks = dataItem.caption.split(".");
    const headerName = chunks[chunks.length - 1];
    return <PivotGridHeaderCell {...props} >
        <span onMouseOver={e => TooltipManager.Push({ target: e.target, text: headerName })}>{headerName}</span>
    </PivotGridHeaderCell>
}