import * as React from "react";
import { Filter } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { HierarchyState } from "../HierarchyComponent/HierarchyState.bin"
import { SupportHierarchyComponent } from "../SupportHierarchyComponent/SupportHierarchyComponent";
import { StoreFiltersMessages } from "../AdvertiserHierarchyComponent/StoreFilters";
import { CampaignFilter } from "../AdvertiserHierarchyComponent/CampaignFilter";
import { clearEmptyValues } from "hub-lib/tools.bin";
import { Row } from "../../Tools";
import { VisualFilters } from "./VisualFilters";

export class TProps {
    config: Partial<Filter>;
    applyConfig: (config: Partial<Filter>) => any;
}

export function AdvancedFilters({
    config,
    applyConfig }: TProps) {

    const onChange = (filters) => applyConfig?.(clearEmptyValues({ ...config, ...filters }));

    return <>
        <Row>
            <CampaignFilter
                store={config}
                onChange={onChange} />
        </Row>
        <SupportHierarchyComponent
            store={config}
            reduxStore={StoreFiltersMessages}
            onChange={onChange} />
        <HierarchyState
            store={config}
            onChanged={onChange} />
        <VisualFilters
            store={config}
            onChanged={onChange} />
    </>;
}