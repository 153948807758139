import { IRid } from "./IRid.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { ref_AdvertisingCompanies } from "./orientdb/ref_AdvertisingCompanies.bin";
import { User } from "./orientdb/User.bin";
import { ref_Campaigns } from "./ref_Campaigns.bin";
import { ref_Messages } from "./ref_Messages.bin";

export type NegotiatedMessage = {
    Status: "accepted" | "proposed" | "canceled",
    Net: number
}

export type NegotiatedMessages = {
    [rid: string]: NegotiatedMessage
}

export enum eSender {
    owner = "owner",
    seller = "seller"
}

export class ConversationExchange {
    Created_at: Date = new Date();
    Sender: eSender = eSender.owner;
    Text?: string;
    Files?: rid[];
    Messages?: NegotiatedMessages;
}
export class Conversation {
    Label?: string;
    Exchanges: ConversationExchange[] = [];
}

export type Conversations = {
    [AdvertisingCompany: string]: Conversation
}

export class ref_Negotiations implements IRid {
    "@rid"?: rid;
    Owner: string;
    Created_by: User["@rid"];
    Updated_by: User["@rid"];
    Created_at: Date;
    Updated_at: Date;
    Conversations: Conversations;
}

export class ref_NegotiationsExtended extends ref_Negotiations {
    Campaign: ref_Campaigns["@rid"];
}

export type Reflection = {
    Adwone_Message: string;
    Adwone_AdvertisingCompany: string;
    Exchange_Conversation: string;
    Exchange_Message: string;
};

export class ref_NegotiationsDB extends ref_Negotiations {
    Reflections: Reflection[];
}

export class NegotiationTools {
    static GetMessages(negotiation: ref_Negotiations): { rid: ref_Messages["@rid"], value: NegotiatedMessage }[] {
        return Array.from(new Set(Object.values(negotiation?.Conversations ?? {})
            .map(v => v.Exchanges?.map(e => {
                const entries = Object.entries(e.Messages ?? {});
                return entries.map(([k, v]) => ({
                    rid: k,
                    value: v
                }))

            }))
            .reduce((a, b) => [...a, ...b], [])
            .reduce((a, b) => [...a, ...b], [])
        ));
    }

    static SetMessageNet(negotiation: ref_Negotiations, message: ref_Messages, net: number, exchangeIndex?: number) {
        let firstProposition = negotiation.Conversations[message.AdvCompany_Com];
        if (!firstProposition) {
            firstProposition = new Conversation();
            firstProposition.Exchanges.push(new ConversationExchange());
            negotiation.Conversations[message.AdvCompany_Com] = firstProposition;
        }

        if (exchangeIndex != null) {
            const prop = firstProposition.Exchanges[exchangeIndex];
            prop.Messages[message["@rid"]] = {
                Status: "proposed",
                Net: net
            }
        } else {
            const newExchange = new ConversationExchange();
            newExchange.Messages = {
                [message["@rid"]]: {
                    Status: "proposed",
                    Net: net
                }
            }
        }
    }

    static GetMessageNet(negotiation: ref_Negotiations, message: ref_Messages) {
        let firstProposition = negotiation.Conversations?.[message.AdvCompany_Com];
        const allNets = firstProposition?.Exchanges
            ?.map(ex => ex?.Messages?.[message["@rid"]]?.Net)
            ?.filter(net => net != null);
        return !allNets?.length ? null : allNets[allNets.length - 1];
    }

    static GetAdvertisingCompanies(negotiation: ref_Negotiations): ref_AdvertisingCompanies["@rid"][] {
        return Object.keys(negotiation?.Conversations ?? {});
    }

    static RemoveMessages(negotiation: ref_Negotiations, messages: ref_Messages[]) {
        messages.forEach(m => {
            const conversation = negotiation.Conversations?.[m.AdvCompany_Com];
            if (conversation) {
                const exchange = conversation.Exchanges?.[0];
                if (exchange?.Messages) {
                    delete exchange.Messages[m["@rid"]];
                    if (Object.keys(exchange.Messages).length == 0)
                        delete negotiation.Conversations[m.AdvCompany_Com];
                }
            }


        })
    }

    static ClearMessages(negotiation: ref_Negotiations) {
        const conversations = Object.values(negotiation.Conversations ?? {});
        for (const conversation of conversations) {
            const exchange = conversation.Exchanges?.[0];
            if (exchange) {
                exchange.Messages = {};
            }
        }
    }

    static ClearEmptyAdvCompanies(negotiation: ref_Negotiations) {
        const entries = Object.entries(negotiation.Conversations ?? {});
        for (const [advCompany, conversation] of entries) {
            const exchange = conversation.Exchanges?.[0];
            if (Object.keys(exchange.Messages).length == 0)
                delete negotiation.Conversations[advCompany];
        }
    }
}
