import * as React from "react";
import { Grid, TextField } from "@material-ui/core";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { clone, firstOrDefault, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp } from "trad-lib";
import { RootState, store } from "../../../../redux/store";
import { GetNumberFormatCustom } from "../NumberFormatCustom.bin";
import { setMessage, lockNext, unlockNext } from '../../../../redux/messageEditorSlice';
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { VertexAutocomplete } from "adwone-lib";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { BarterGrid } from "./BarterGrid";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ErrorMessage } from "../../../ConfigurableComponents/ErrorMessage.bin";
import { CustomNumericTextBox } from "../../Generic/Common.bin";
import { Checkbox } from "@progress/kendo-react-inputs";

class BarterState {
    Companies: ref_AdvertisingCompanies[] = null;
    Discounts: ref_DiscountClasses[] = null;
}

export function BarterEditor() {
    const [state, setState] = React.useState<BarterState>(undefined);
    const barter = useSelector((root: RootState) => root.messageEditor.message.KPIs.Barter);
    const smartBarter = useSelector((root: RootState) => root.messageEditor.message.SmartBarter);
    const applyComBarter = useSelector((root: RootState) => root.messageEditor.message.ApplyComBarter);
    const barterPercents = useSelector((root: RootState) => root.messageEditor.message.BarterPercents);
    const totalCO = useSelector((root: RootState) => root.messageEditor.message.KPIs?.TotalCO);
    // const [barterError, setBarterError] = React.useState<boolean>(false);
    const barterError = totalCO >= 0 && (barter ?? 0) > totalCO;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(barterError ? lockNext() : unlockNext());
    }, [barterError])

    const dispatchValue = (messageProvided: ref_Messages) => {
        dispatch(setMessage(messageProvided));
    }

    React.useEffect(() => {
        if (state === undefined) {
            Promise.resolve().then(async () => setState({
                Companies: await Client.searchVertexTyped(ref_AdvertisingCompanies, { "IsBarter": true, properties: ["Name"] }),
                Discounts: await Client.searchVertexTyped(ref_DiscountClasses, { "DiscountType.Name": "Barter", Active: true })
            }));
        }
    }, [state])

    const initializeBarter = (barterValue: number, smartBarter: boolean) => {
        console.log("initializeBarter");
        const message = store.getState().messageEditor.getMessage();
        const oldValue = message.KPIs.Barter;
        if (smartBarter != message.SmartBarter) {
            message.SmartBarter = smartBarter;
            message.KPIs.Barter = null;
        }
        else
            message.KPIs.Barter = (Number.isNaN(barterValue) || barterValue <= 0) ? undefined : barterValue;
        if (message.KPIs.Barter) {
            message.SmartBarter = false;
            if (!oldValue) {
                message.BarterPercents = state.Discounts.map(d =>
                ({
                    "@rid": null,
                    DiscountClass: d["@rid"],
                    DiscountType: d.DiscountType,
                    LastPosition: d.LastPosition,
                    Rank: d.Rank,
                    Operator: 1 /*d.Operator*/,
                    Intervals: [],
                    IsRate: true,
                    Rate: 0.5
                }));
                message.AdvCompany_Fin = firstOrDefault(state.Companies)?.["@rid"];
            }
            else if (!message.BarterPercents[0].IsRate)
                for (const d of message.BarterPercents)
                    d.IsRate = true;
        }
        else if (!message.SmartBarter) {
            message.BarterPercents = null;
            message.AdvCompany_Fin = null;
            if (message.ApplyComBarter) {
                message.AdvCompany_Com = null;
                message.ApplyComBarter = false;
            }
        }
        dispatchValue(message);
    }

    // const checkBarterError = () => {

    //     if (error != barterError) {
    //         dispatch(error ? lockNext() : unlockNext());
    //         setBarterError(error);
    //     }
    // }

    // checkBarterError();
    return <Grid container className='block-container'>
        <Grid item xs={12} className="message_details_full">
            <Checkbox label={TradProp("SmartBarter", ref_Messages)}
                checked={smartBarter}
                onChange={(e) => initializeBarter(barter, e.value)} />
        </Grid>
        <Grid item xs={6} className="message_details_leftcombo">
            <CustomNumericTextBox
                min={0}
                title={TradProp("KPIs.Barter", ref_Messages)}
                value={barter}
                onChange={(e) => initializeBarter(e.value, smartBarter)} />
            {barterError &&
                <ErrorMessage message={Trad("barter_error")} margin={'12px 0 0 0'} padding={'6px'} />
            }
        </Grid>


        {(barter || smartBarter) &&
            <>
                <Grid item xs={6} className="message_details_rightcombo">
                    <VertexAutocomplete
                        loading={!state?.Companies}
                        enabledOnlyLoading
                        options={state?.Companies}
                        key={GetHashCode(state?.Companies)}
                        disableClearable
                        label={TradProp("AdvCompany_Barter") + " *"}
                        defaultValue={(options: IRid[]) => options?.find(c => c["@rid"] == store.getState().messageEditor.getMessage()?.AdvCompany_Fin)}
                        onChange={async (company: IRid) => {
                            const message = store.getState().messageEditor.getMessage();
                            message.AdvCompany_Fin = company["@rid"];
                            if (message.ApplyComBarter)
                                message.AdvCompany_Com = message.AdvCompany_Fin;
                            dispatchValue(message);
                        }} />
                </Grid>
                <Grid item xs={6} className="message_details_leftcombo" />
                <Grid item xs={6} className="message_details_rightcombo">
                    <Checkbox label={TradProp("ApplyComBarter", ref_Messages)}
                        checked={applyComBarter}
                        onChange={(e) => {
                            const message = store.getState().messageEditor.getMessage();
                            message.ApplyComBarter = e.value;
                            if (message.ApplyComBarter)
                                message.AdvCompany_Com = message.AdvCompany_Fin;
                            dispatchValue(message);
                        }
                        } />
                </Grid>
            </>
        }

        {state?.Discounts &&
            <Grid item xs={12} className="message_details_full">
                <BarterGrid
                    discounts={clone(barterPercents)}
                    classes={state.Discounts}
                    onChange={(percents) => {
                        const message = store.getState().messageEditor.getMessage();
                        message.BarterPercents = percents;
                        dispatchValue(message);
                    }} />
            </Grid>
        }
    </Grid>
}