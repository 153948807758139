
import { Grid, GridCell, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Indicateur } from "adwone-engine/index.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib";
import { propertyOf, toArray } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { RowSize } from "../../../../../styles/theme";
import { CustomButton } from "../../../../ConfigurableComponents/CustomButton.bin";
import { ContainerComponent } from "../../../Generic/ContainerComponent";
import { CustomIconButton } from "../../../Generic/CustomIconButton";
import "./theme.scss";

type DimensionsPickerProps<T> = {
    title?: string;
    data: T[],
    values: DimensionsPickerProps<T>['data'],
    onChange: (values: DimensionsPickerProps<T>['data']) => void
};

type IndicateurPickerProps = DimensionsPickerProps<Indicateur>;
export function DimensionsPicker({ data, title, values, onChange }: IndicateurPickerProps) {

    const [addingNew, setAddingNew] = React.useState(false);

    return <ContainerComponent title={title ?? ''} className="tcd-dimensions-picker-container">
        <div className="tcd-dimensions-picker">
            {/** Affichage des indicateurs déjà dans la configuration */}
            <Grid className="indicateurs-grid" data={addingNew ? [...values, null] : values}>

                <GridNoRecords>
                    {Trad('no-dimensions-selected')}
                </GridNoRecords>

                <GridColumn width={25} title="" cell={DragCell(() => { }, () => { })} />
                <GridColumn
                    field={propertyOf<IndicateurPickerProps['data'][0]>('name')}
                    cell={p => {
                        if (!p.dataItem)
                            return <td>
                                <VertexAutocomplete
                                    label=""
                                    options={data}
                                    getOptionLabel={(i: Indicateur) => i.name}
                                    onChange={v => {
                                        setAddingNew(false);
                                        onChange([...values, ...toArray(v)]);
                                    }} />
                            </td>;
                        return <GridCell {...p} />;
                    }} />

                <GridColumn
                    cell={(c) => (
                        <td>
                            <CustomIconButton
                                disabled={(values?.length ?? 0) <= 1}
                                onClick={() => {
                                    if (!c.dataItem) setAddingNew(false);
                                    else onChange(values.filter(v => v != c.dataItem));
                                    // this.onRemove(c.dataItem)
                                }}>
                                {getIcon("close")}
                            </CustomIconButton>
                        </td>
                    )}
                    width={28}
                />
            </Grid>
            <div style={{ height: RowSize }}>
                <CustomButton
                    className="custom_btn_secondary add-indicateur-button"
                    startIcon={getIcon("plus_rounded")}
                    Label={Trad('add')}
                    onClick={() => setAddingNew(true)} />
            </div>
        </div>
    </ContainerComponent>
}

const DragCell = (reorder: any, dragStart: any): any => {
    return class DragCell extends React.Component<any, any> {
        render() {
            const item = this.props.dataItem;
            const cursor: React.CSSProperties["cursor"] = "move";
            return (
                <td style={{ textAlign: 'center', cursor }} className={`${!item ? 'cell-disable' : ''}`}
                    draggable="true"
                    onDragStart={(e) => {
                        if (!item) return;
                        e.dataTransfer.setData("dragging", "");
                        dragStart(this.props.dataItem);
                    }}
                    onDragOver={(e) => {
                        if (!item) return;
                        reorder(this.props.dataItem);
                        e.preventDefault();
                        e.dataTransfer.dropEffect = "copy";
                    }}>
                    <span className="k-icon k-i-reorder" style={{ cursor, textAlign: 'center' }} />
                </td>
            );
        }
    }
}