import { Trad } from "trad-lib";
import React, { useEffect } from "react";
import { BreadcrumbsCustom } from "../BreadcrumbsCustom";
import { CampaignSelection } from "./CampaignSelection";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { getIcon } from "adwone-lib/index";
import { LinksSelection } from "./LinksSelection";
import { MessageValidation } from "./MessageValidation";
import { ExternalCampaignImport } from "hub-lib/models/custom/ExternalCampaignImport"
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../../utils/Notify.bin";
import history from '../../utils/history';
import { BreadcrumbsCustomContainer, ToolbarAdw } from "../VertexGrid/Generic/ToolbarAdw";
import { ShowMessages } from "../VertexGrid/Campaigns/CampaignsGrid.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";

export function MedialandImportComponent() {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [campaignImport, setCampaignImport] = React.useState<ExternalCampaignImport>();

    useEffect(() => {
        if (campaignImport) return;

        /* Get plan && stock in state */
        Promise.resolve().then(async () => {
            const token = new URLSearchParams(window.location.search).get("token");
            const _campaignImport = (await Client.getExternalImport(token)).import;
            if (!_campaignImport)
                Notify(Trad("no_import_pending"), "warning");

            for (const m of _campaignImport.Messages) {
                const msg = m.Referential;
                if (msg.BroadcastArea && msg.Currency && msg.Media)
                    await DiscountManager.initGlobalAgreement(msg);
            }

            setCampaignImport(_campaignImport);
        })
    });

    const getCompleteMessages = () =>
        campaignImport?.Messages
            .filter(m => {
                for (const [k, v] of Object.entries(m.MissingLinks)) {
                    // Emplacement non obligatoire
                    if (k == "Placement") continue;
                    if (!v) continue;
                    if (m.Referential[k] === undefined) return false;
                }
                return true;
            }).map(m => m);

    const importMessages = () =>
        Client.pushExternalImport({ ...campaignImport, Messages: getCompleteMessages() })
            .then(i => {
                Notify(Trad('import_messages_success'), "success");
                Notify(Trad('you_will_be_redirected'), "info");
                setTimeout(() => {
                    // history.push('/messages_by_campaign', {
                    //     campaignName: campaignImport.Referential.Name,
                    //     campaignRid: campaignImport.Referential["@rid"],
                    // });
                    ShowMessages([campaignImport.Referential])
                }, 5000);
            })
            .catch(e => Notify(Trad('import_messages_failed'), "error"));

    if (!campaignImport)
        return <div>{Trad("no_import_pending")}</div>

    return <div className="grid_container no_panel">
        <div style={{ width: '100%' }}>
            <ToolbarAdw>
                <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom elements={[
                        { text: Trad("home"), href: "/" },
                        { text: Trad("import_medialand"), href: "/medialand" }
                    ]} />
                </BreadcrumbsCustomContainer>
            </ToolbarAdw>

            <div style={{ position: 'relative', height: 'calc(100% - 40px)' }}>
                <div style={{ width: '100%', overflow: 'auto', height: 'calc(100% - 80px)' }} className='clearfix'>
                    <div style={{ overflow: 'hidden' }}>
                        <CampaignSelection campaignImport={campaignImport} onChange={forceUpdate} />
                    </div>
                    <LinksSelection campaignImport={campaignImport} onChange={forceUpdate} />
                    <MessageValidation campaignImport={campaignImport} onChange={forceUpdate} />
                </div>
                <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
                    <div className="adw-form-action">
                        <CustomButton
                            disabled={!campaignImport.Referential || !getCompleteMessages()?.length}
                            Label={`${Trad("import")} ${getCompleteMessages().length} message(s)`}
                            className="custom_btn_information"
                            endIcon={getIcon("next")}
                            onClick={() => importMessages()} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}