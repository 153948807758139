import * as React from "react";
import { Chip, Grid, GridSize } from "@material-ui/core";
import { dicoViewModeIcons, ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { firstOrDefault } from "hub-lib/tools.bin";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradProp } from "trad-lib";
import { DividerWithText, getIcon } from "adwone-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ChipBox } from "./ChipBox.bin";

class TPropsTableConfiguration {
    id: string;
    gridSize: GridSize;
}

export function TableChip({ id, gridSize }: TPropsTableConfiguration) {
    const [config, setConfig] = React.useState<ref_TableConfigurations>();
    const [open, setOpen] = React.useState<boolean>(true);
    const fetchData = async (): Promise<void> => {
        const defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
        const data = await Client.searchVertexTyped(
            ref_TableConfigurations,
            id
                ? {
                    "@rid": id,
                    Active: true,
                }
                : defaultParams
        );
        setConfig(firstOrDefault(data));
    };
    React.useEffect(() => {
        if (!config) fetchData();
    });
    return (
        <>
            <DividerWithText
                startIcon={getIcon(config ? dicoViewModeIcons[config.ViewMode] : "setting")}
                endIcon={open ? getIcon("less") : getIcon("more")}
                onClick={() => setOpen(!open)}
                hideLines
            >{`${config?.Name}`}</DividerWithText>
            <Grid container item xs={12} className={`widget-expendable ${open ? "open" : ""}`}>
                {config && (
                    <>
                        <Grid item xs={config.CrossedtableConfig ? 9 : 12}>
                            <ChipBox title={Trad("columns")}>
                                {config.Columns.map((s, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={s.name}
                                        className={`chip-element ${index < config.FrozenPosition && "chip-element-frozen"}`}
                                    />
                                ))}
                            </ChipBox>
                        </Grid>
                        {config.CrossedtableConfig && (
                            <Grid item xs={3}>
                                <ChipBox title={Trad("grouping")}>
                                    {config.CrossedtableConfig.rowventils.map((r) => (
                                        <Chip
                                            variant="outlined"
                                            label={r['name'] ?? TradProp(r.field, ref_Messages)}
                                            className="chip-element"
                                        />
                                    ))}
                                </ChipBox>
                            </Grid>
                        )}
                        {/* <Grid item xs={gridSize}>
                <span className="subtitle-CustomLayout">{Trad("viewmode")}</span>
                <OverflowExpendable>
                    <Chip variant="outlined" label={Trad(config.ViewMode.toLocaleLowerCase() + "_view")} className="chip-element" />
                </OverflowExpendable>
            </Grid> */}
                    </>
                )}
            </Grid>
        </>
    );
}
