import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ComputatedCascade, DiscountManager, getAvailableAgreements, netType } from 'hub-lib/business/DiscountManager.bin';
import { Client } from 'hub-lib/client/client.bin';
import { AdvertisingCompanyExtended } from 'hub-lib/models/AdvertisingCompanyExtended.bin';
import { BroadcastAreaExtended } from 'hub-lib/models/BroadcastAreaExtended.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { eKPI, lnk_HasKPIExtended, MessageModelManager } from 'hub-lib/models/KPIsManager.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { lnk_HasBroadcastArea } from 'hub-lib/models/orientdb/lnk_HasBroadcastArea.bin';
import { lnk_HasCurrency } from 'hub-lib/models/orientdb/lnk_HasCurrency.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_SubAgencies } from 'hub-lib/models/orientdb/ref_SubAgencies.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Agreements } from 'hub-lib/models/ref_Agreements.bin';
import { ref_Campaigns } from 'hub-lib/models/ref_Campaigns.bin';
import { ref_GlobalAgreements } from 'hub-lib/models/ref_GlobalAgreements.bin';
import { eGroupCategories, ref_Groups } from 'hub-lib/models/ref_Groups.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { ref_Offers } from 'hub-lib/models/ref_Offers.bin';
import { ref_Publications } from 'hub-lib/models/ref_Publications.bin';
import { UserExtended } from 'hub-lib/models/UserExtended.bin';
import { clone, compareObjects, distinct, duplicate, extractSub, hasOwnProperty, SerializeError, Typed } from 'hub-lib/tools.bin';
import { Trad } from 'trad-lib';
import { getDefaultFormatOffer, getFormatOffer } from '../components/VertexGrid/Messages/MessageEditor/OfferTools';
import { FilterStorage } from '../utils/localstorage.bin';
import { Notify } from '../utils/Notify.bin';
import { GetMessageOffers, SetBroadcastPriceOffer, SetPriceOffer } from '../utils/Tools';
import { RootState } from './store';
import { DateZone } from 'tools-lib';
import { ref_DiscountClasses } from 'hub-lib/models/orientdb/ref_DiscountClasses.bin';
import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import { eDiscountOptionType } from 'hub-lib/models/external.bin';
import { DataProvider } from 'hub-lib/provider';
import { MAPInsertionRemise } from 'hub-lib/import/MAPTypes';

export type CursorPosition = {
    top: number,
    left: number
}

interface ref_MessagesSerializable extends Omit<ref_Messages, "Start" | "End"> {
    Start: number;
    End: number
}

export interface ref_OffersSerializable extends Omit<ref_Offers, "Start" | "End"> {
    Start: number;
    End: number
}

export interface MessageEditorState {
    currentNetType: netType;
    cascade: ComputatedCascade;
    currentAgreement: ref_Agreements;
    lockNext: boolean;
    repeat: boolean;
    currencyCode: string;
    currency: ref_Currencies;
    message: ref_MessagesSerializable;
    offers: ref_OffersSerializable[];
    currencyOptions: TCurrency[];
    broadcastOptions: ref_BroadcastAreas[];
    groups: {
        [key: string]: ref_Groups[],
        MediaFamily: ref_Groups[],
        PlacementCategory: ref_Groups[]
    }
    getMessage: () => ref_Messages;
    getOffers: () => ref_Offers[];
    publications: ref_Publications[];
    AdvCompany_ComOptions: (LnkAdv | ref_SubAgencies)[];
    AdvCompany_FinOptions: (LnkAdv | ref_SubAgencies)[];
    agreements: ref_Agreements[];
    globalAgreement: ref_GlobalAgreements;
    kpis: lnk_HasKPIExtended[];
}

function ToRefMessage(message: ref_MessagesSerializable): ref_Messages {
    return {
        ...duplicate(message),
        Start: message.Start ? new Date(message.Start) : undefined,
        End: message.End ? new Date(message.End) : undefined,
        CancellationTimeLimit: message.CancellationTimeLimit ? new Date(message.CancellationTimeLimit) : undefined,
        ConfirmationTimeLimit: message.ConfirmationTimeLimit ? new Date(message.ConfirmationTimeLimit) : undefined,
        TechDeliveryTimeLimit: message.TechDeliveryTimeLimit ? new Date(message.TechDeliveryTimeLimit) : undefined
    }
}

function ToRefMessageSerializable(message: ref_Messages): ref_MessagesSerializable {
    return {
        ...duplicate(message),
        Start: message.Start ? new Date(message.Start).getTime() : undefined,
        End: message.End ? new Date(message.End).getTime() : undefined,
    }
}

const initialState: MessageEditorState = {
    currentNetType: "CO",
    cascade: null,
    currentAgreement: undefined,
    globalAgreement: null,
    agreements: null,
    lockNext: false,
    repeat: false,
    currencyCode: "",
    currency: undefined,
    message: undefined,
    offers: undefined,
    publications: undefined,
    currencyOptions: undefined,
    broadcastOptions: undefined,
    AdvCompany_ComOptions: undefined,
    AdvCompany_FinOptions: undefined,
    kpis: undefined,
    groups: {
        MediaFamily: [],
        PlacementCategory: []
    },
    getMessage() {
        return this.message && ToRefMessage(this.message)
    },

    getOffers() {
        return this.offers?.map(o => ({
            ...duplicate(o),
            Start: o.Start ? new Date(o.Start) : undefined,
            End: o.End ? new Date(o.End) : undefined
        })) ?? []
    }
}

type TCurrency = Partial<ref_Currencies> & {
    Default: boolean,
    link: ref_Supports["@rid"] | ref_BroadcastAreas["@rid"]
}

export const initAdvComOptions = createAsyncThunk(
    'messageEditor/initAdvComOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const AdvCompany_ComOptions = await UpdateAdvertisingCompany("AdvCompany_Com", state.messageEditor, state.messageEditor.getMessage());
            return { AdvCompany_ComOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initAdvFinOptions = createAsyncThunk(
    'messageEditor/initAdvFinOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const AdvCompany_FinOptions = await UpdateAdvertisingCompany("AdvCompany_Fin", state.messageEditor, state.messageEditor.getMessage());
            return { AdvCompany_FinOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initCurrencyOptions = createAsyncThunk(
    'messageEditor/initCurrencyOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const currencyOptions = await UpdateCurrencyState(state.messageEditor, state.messageEditor.getMessage());
            return { currencyOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initBroadcastOptions = createAsyncThunk(
    'messageEditor/initBroadcastOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const broadcastOptions = await UpdateBroadcastState(state.messageEditor, state.messageEditor.getMessage());
            return { broadcastOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export function UpdateSPQ(m: ref_Messages) {
    const rateGrossDefaultFormat = Math.round(100 * m.KPIs.Gross / m.KPIs.PriceDefaultFormat) / 100;
    const rateGrossValoDefaultFormat = Math.round(100 * m.KPIs.GrossVal / m.KPIs.PriceDefaultFormat) / 100;
    m.KPIs.RateGrossDefaultFormat = Number.isNaN(rateGrossDefaultFormat) ? 0 : rateGrossDefaultFormat;
    m.KPIs.RateGrossValoDefaultFormat = Number.isNaN(rateGrossValoDefaultFormat) ? 0 : rateGrossValoDefaultFormat;
}

const ConsoleDebug = (message?: any, ...optionalParams: any[]) => {
    if (localStorage.getItem('debugSetMessage'))
        console.log(`[debugSetMessage] ${message}`, ...optionalParams);
}


let media: ref_Media[] = undefined;

export const performanceKPIs = [eKPI.PaidCirculation, eKPI.TotalCirculation, eKPI.Subscriptions, eKPI.ToDeliver, eKPI.Release];

export const setNet = createAsyncThunk(
    'messageEditor/setNet',
    async (props: { base: ref_DiscountClasses, mode: eDiscountOptionType, value: number }, thunkAPI) => {

        console.log(`[setNet] BEGIN`, props);
        const state = thunkAPI.getState() as RootState;
        const message = state.messageEditor.getMessage();

        const bbaDiscounts = (await DiscountManager.getCategoryTypes())[1]; // category bba
        const discounts = message.Discounts.filter(d => bbaDiscounts.includes(d.DiscountType));
        let messageCascade: ComputatedCascade = null;

        try {
            const { base, value, mode } = props;
            let row: ref_Discount = discounts.find(r => r.DiscountClass == base["@rid"]);
            console.log("[setNet] Row exists", row);
            if (!row) {
                const classes = await DiscountManager.GetDiscountClasses();
                const discountClass = classes.find(c => c["@rid"] == base["@rid"]);
                row = {
                    "@rid": null,
                    DiscountClass: base["@rid"],
                    DiscountType: base.DiscountType,
                    IsRate: true,
                    Rank: 0,
                    Operator: discountClass.Operator as any,
                    LastPosition: discountClass.LastPosition,
                    Intervals: null
                };
                message.Discounts.push(row);
            }

            //const initValue = DiscountManager.getModulation(row, mode);
            if (!row[mode]) {
                row.IsRate = true;
                const cofoData = DiscountManager.getModulation(row, mode);
                row[mode] = { Value: cofoData.Value ?? 0, Rate: cofoData.Rate ?? 0 };
            }

            console.log(`[setNet] row[mode]?.Value`, row[mode]?.Value, row[mode]?.Value != 0);

            const cascade = (await DiscountManager.UpdateCascade(message))[mode];
            const getRowBase = (discountClass: string) => cascade.find(r => r.Discount.DiscountClass == discountClass);

            console.log(`[setNet] cascade`, cascade);

            if (getRowBase(row.DiscountClass).Base != 0) {
                let calcBase = value;
                if (message.DiscountMode == "cascade") {
                    const position = discounts.indexOf(row);
                    let length = discounts.length;
                    for (let i = length - 1; i > position; i--) {
                        let r = discounts[i];
                        const cofoData = DiscountManager.getModulation(r, mode);
                        if (!r.IsRate)
                            calcBase += cofoData?.Value ?? 0;
                        else if (r[mode]?.Rate != 1)
                            calcBase = calcBase / (1 - cofoData?.Rate ?? 0);
                    }
                }
                else {
                    const rank = Number(row.Rank);
                    const rows = discounts.filter(r => Number(r.Rank) >= rank && r != row);
                    const maxRank = Math.max(...rows.map(r => Number(r.Rank)));

                    console.log("[setNet] Rows", rows);
                    for (let i = maxRank; i > rank; i--) {
                        const rankRows = rows.filter(r => r.Rank == i);
                        let rates = 0;
                        for (const r of rankRows) {
                            const cofoData = DiscountManager.getModulation(r, mode);
                            if (!r.IsRate)
                                calcBase += cofoData?.Value ?? 0;
                            else
                                rates += cofoData?.Rate ?? 0;
                        }
                        if (rates != 1)
                            calcBase = calcBase / (1 - rates);
                    }

                    for (const r of rows.filter(r => r.Rank == rank)) {
                        const cofoData = DiscountManager.getModulation(r, mode);
                        if (!r.IsRate)
                            calcBase += cofoData?.Value ?? 0;
                        else
                            calcBase += getRowBase(row.DiscountClass).Base * (cofoData?.Rate ?? 0);
                    }
                }

                console.log(`[setNet] calcBase`, calcBase, 'getRowBase(row.DiscountClass)', getRowBase(row.DiscountClass), 'rate', 1 - (calcBase / getRowBase(row.DiscountClass).Base));

                let rate = 1 - (calcBase / getRowBase(row.DiscountClass).Base);
                if (rate < 0)
                    rate = 0;
                row[mode].Rate = rate;
            }

            messageCascade = await DiscountManager.UpdateCascade(message);
            console.log(`[setNet] END`, { message, base, mode, value });

        } catch (error) {
            console.log(`[setNet] ERROR`, error);
        }

        return { message: ToRefMessageSerializable(message), cascade: messageCascade };
    }
)

export const setMessage = createAsyncThunk(
    'messageEditor/message',
    async (message: ref_Messages, thunkAPI) => {
        try {

            let cascade: ComputatedCascade = undefined;
            const time7642 = new Date().getTime();
            ConsoleDebug('message param: ', message);

            const state = thunkAPI.getState() as RootState;
            ConsoleDebug('previous msg', state.messageEditor.message);
            const isInitializing = Boolean(!state.messageEditor.message);
            const { ModelProperties, Campaign, Agreement, GlobalAgreement, BroadcastArea, Media, Start, End, Support, Advertiser, AdvertiserGroup, Brand, Placement, Format, Currency, Product, KPIs, Discounts, ["@rid"]: rid, IsGrossCPM, KpiCPM } = (state.messageEditor.message ?? new ref_Messages);

            if (!media)
                media = await Client.searchVertexTyped(ref_Media);

            const newMsg = clone(message);
            const isCreatingMessage = newMsg && !newMsg['@rid'];

            if (isInitializing)
                await DiscountManager.lockDiscountsLockedFromMAP(newMsg);

            // LEGACY, en cas de message enregistré sans DiscountMode
            if (!isInitializing && newMsg && !newMsg.DiscountMode) {
                newMsg.DiscountMode = new ref_Messages().DiscountMode;
            }

            if (newMsg.Start && ['string', 'number'].includes(typeof newMsg.Start)) newMsg.Start = new Date(newMsg.Start);
            if (newMsg.End && ['string', 'number'].includes(typeof newMsg.End)) newMsg.End = new Date(newMsg.End);

            if (state.messageEditor.message && state.messageEditor.kpis) {
                if (newMsg.IsGrossCPM != IsGrossCPM) {
                    if (newMsg.IsGrossCPM) {

                        const defaultKPICPM = state.messageEditor.kpis.find(kpi => kpi.Name == eKPI.PaidCirculation);
                        newMsg.KpiCPM = defaultKPICPM?.KPI;
                        const base = newMsg.KPIs?.[defaultKPICPM.Id] ?? 0;
                        if (base != 0)
                            newMsg.KPIs["GrossCPM"] = newMsg.KPIs.Gross * 1000 / base;
                        else
                            newMsg.KPIs["GrossCPM"] = 0;
                    }
                    else {
                        newMsg.KpiCPM = null;
                        delete newMsg.KPIs["GrossCPM"];
                    }
                }
                if (newMsg.IsGrossCPM) {
                    const kpiCPM = state.messageEditor.kpis.find(kpi => kpi.KPI == newMsg.KpiCPM);
                    if (newMsg.KpiCPM != KpiCPM ||
                        newMsg.KPIs["GrossCPM"] != KPIs["GrossCPM"] ||
                        newMsg.KPIs[kpiCPM.Id] != KPIs[kpiCPM.Id]) {
                        const base = newMsg.KPIs?.[kpiCPM.Id] ?? 0;
                        newMsg.KPIs.Gross = base * newMsg.KPIs["GrossCPM"] / 1000;
                        console.log("Compute Gross by CPM", newMsg.KPIs.Gross);
                    }
                }
            }
            if (newMsg.KPIs?.Gross != KPIs?.Gross || newMsg.KPIs?.GrossVal != KPIs?.GrossVal) {
                ConsoleDebug('UpdateSPQ');
                UpdateSPQ(newMsg);
            }

            // si on est en train d'initialiser le message en création, on récupère les valeurs par défaut de la campagne sélectionnée dans les filtres
            if (isInitializing && !newMsg.Campaign && !newMsg['@rid']) {
                const filters = FilterStorage.GetLocalStorageFiltersObj();
                if (filters.Campaign?.length == 1 && filters.Campaign[0]) {
                    const campRid = filters.Campaign[0];
                    const params = { "@rid": campRid, Source: "ADWONE" };
                    // if (importRid) {
                    //     params["CacheInfos"] = { Key: importRid, Type: "Import" }
                    // }
                    const [campaign] = await Client.searchVertexTyped(ref_Campaigns, params);
                    ConsoleDebug('set default campaign: ', params, campaign);
                    if (campaign) {
                        newMsg.Campaign = campaign?.["@rid"];
                        newMsg.AdvertiserGroup = campaign?.AdvertiserGroup;
                        newMsg.Advertiser = campaign?.Advertiser;
                        newMsg.Brand = campaign?.Brand;
                        newMsg.Product = campaign?.Product;
                        newMsg.Group = campaign?.Group;
                    }
                }
            }

            if (!newMsg.ModelProperties) newMsg.ModelProperties = {};

            let offers = state.messageEditor.offers;
            const isInitializingOffer = offers !== undefined;

            // S'il y a une valeur et que le support change on réinitialize les valeurs par défaut
            if (state.messageEditor.message && Support != newMsg.Support) {
                newMsg.BroadcastArea = null;
                newMsg.AdvCompany_Com = null;
                newMsg.AdvCompany_Fin = null;
                newMsg.Currency = null;
                //https://www.wrike.com/open.htm?id=1058127033
                newMsg.Start = null;
                newMsg.End = null;
                if (newMsg.GlobalAgreement || newMsg.Agreement) {
                    newMsg.GlobalAgreement = null;
                    newMsg.Agreement = null;
                    newMsg.Discounts = newMsg.Discounts?.filter(d => !d.Agreement);
                    //await DiscountManager.applyDiscounts(newMsg);
                }
                newMsg.ModelProperties["Dated"] = undefined;
                newMsg.ModelProperties["Reference"] = undefined;
                newMsg.ModelProperties["EditorialTheme"] = undefined;
            }

            let broadcastOptions: ref_BroadcastAreas[] = undefined;
            let AdvCompany_ComOptions: (LnkAdv | ref_SubAgencies)[] = undefined;
            let AdvCompany_FinOptions: (LnkAdv | ref_SubAgencies)[] = undefined;
            let currencyOptions: TCurrency[] = undefined;

            const cloneMessageEditor = clone(state.messageEditor);
            if (new Date(Start).getTime() != new Date(newMsg.Start).getTime()) {
                cloneMessageEditor["AdvCompany_ComOptions"] = null;
                cloneMessageEditor["AdvCompany_FinOptions"] = null;
            }
            if (newMsg.Campaign) {
                broadcastOptions = await UpdateBroadcastState(cloneMessageEditor, newMsg);
                AdvCompany_ComOptions = await UpdateAdvertisingCompany("AdvCompany_Com", cloneMessageEditor, newMsg);
                AdvCompany_FinOptions = await UpdateAdvertisingCompany("AdvCompany_Fin", cloneMessageEditor, newMsg);
                currencyOptions = await UpdateCurrencyState(cloneMessageEditor, newMsg);
            }

            if (!newMsg.BroadcastArea) {
                newMsg.Format = null;
                newMsg.Placement = null;
            }

            let agreements: ref_Agreements[] = null;
            if (!isInitializing) {

                // TODO : à revoir dans le cadre de la réactivation des intervalles
                // il faudra sans doute applyDiscount si les discounts, ou le Gross ont changé
                if (newMsg.Agreement != Agreement)
                    await DiscountManager.initAgreement(newMsg, { refreshCurrent: true });
                else if (newMsg.GlobalAgreement != GlobalAgreement)
                    await DiscountManager.initGlobalAgreement(newMsg);

                if (newMsg.Currency &&
                    newMsg.Start) {
                    if (BroadcastArea !== newMsg.BroadcastArea ||
                        Currency !== newMsg.Currency ||
                        Media != newMsg.Media ||
                        new Date(Start).getTime() != new Date(newMsg.Start).getTime()) {
                        await DiscountManager.initGlobalAgreement(newMsg);
                    }
                }

                if ((newMsg.AdvertiserGroup || newMsg.Group) &&
                    newMsg.Currency &&
                    newMsg.Support &&
                    newMsg.Start) {
                    if (BroadcastArea !== newMsg.BroadcastArea ||
                        Currency !== newMsg.Currency ||
                        Support != newMsg.Support ||
                        Media != newMsg.Media ||
                        Advertiser != newMsg.Advertiser ||
                        AdvertiserGroup != newMsg.AdvertiserGroup ||
                        Brand != newMsg.Brand ||
                        Product != newMsg.Product ||
                        Format != newMsg.Format ||
                        Placement != newMsg.Placement ||
                        new Date(Start).getTime() != new Date(newMsg.Start).getTime()) {
                        const ag = newMsg.Agreement;
                        const agGlobal = newMsg.GlobalAgreement;
                        await DiscountManager.initAgreement(newMsg);
                        //await DiscountManager.applyDiscounts(<ref_Messages><unknown>newMsg, { initAgreement: true });
                        agreements = await getAvailableAgreements(newMsg);
                        if (ag != newMsg.Agreement || agGlobal != newMsg.GlobalAgreement) {
                            Notify(Trad("change_agreement_auto"), "warning");
                        }
                    }
                } else {
                    agreements = [];
                }
            }

            if (isInitializing && !agreements) {
                if ((newMsg.AdvertiserGroup || newMsg.Group) &&
                    newMsg.Currency &&
                    newMsg.Support &&
                    newMsg.Start) {
                    agreements = await getAvailableAgreements(newMsg);
                } else {
                    agreements = [];
                }
            }

            let globalAgreement: ref_GlobalAgreements = null;
            if (newMsg.GlobalAgreement != state.messageEditor.globalAgreement?.['@rid'])
                globalAgreement = newMsg.GlobalAgreement ? (await Client.searchVertexTyped(ref_GlobalAgreements, { "@rid": newMsg.GlobalAgreement }))?.[0] : null;

            if (!newMsg.Support) {
                newMsg.Currency = null;
                newMsg.BroadcastArea = null;
            }

            if (!isInitializingOffer ||
                BroadcastArea !== newMsg.BroadcastArea ||
                Media !== newMsg.Media ||
                Support != newMsg.Support ||
                Start != newMsg.Start?.getTime?.() ||
                End != newMsg.End?.getTime?.()) {

                offers = (await GetMessageOffers(newMsg)).map(o => ({
                    ...o,
                    Start: o.Start ? new Date(o.Start).getTime() : undefined,
                    End: o.End ? new Date(o.End).getTime() : undefined,
                }));

                ConsoleDebug('load offers', offers, extractSub(clone(newMsg), ["BroadcastArea", "Media", "Start", "Support"]))

                if (isInitializingOffer && !isInitializing) {
                    if (newMsg.Format)
                        await SetPriceOffer(newMsg, getDefaultFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], offers), "Format");
                    if (newMsg.Placement)
                        await SetPriceOffer(newMsg, getFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], newMsg.Placement, offers), "Placement", false);
                }
            }

            if (!isInitializing) {
                if (Format != newMsg.Format || ModelProperties["Couleur"] != newMsg.ModelProperties["Couleur"]) {
                    await SetPriceOffer(newMsg, getDefaultFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], offers), "Format");
                    if (newMsg.Placement)
                        await SetPriceOffer(newMsg, getFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], newMsg.Placement, offers), "Placement", false);
                    if (newMsg.ModelProperties?.BroadcastPlacement)
                        SetBroadcastPriceOffer(newMsg, getFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], newMsg.ModelProperties?.BroadcastPlacement, offers));
                    const [preCreatedMsg] = await Client.preCreateVertex(ref_Messages, [newMsg]);
                    newMsg.KPIs = { ...newMsg.KPIs, ...preCreatedMsg?.KPIs };
                }

                if (Placement != newMsg.Placement) {
                    await SetPriceOffer(newMsg, getFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], newMsg.Placement, offers), "Placement");
                    //SetPriceOffer(newMsg, getPlacementOffer({ "@rid": message.Placement }, offers), "Placement");
                }

                if (ModelProperties?.BroadcastPlacement != newMsg.ModelProperties?.BroadcastPlacement) {
                    if (!newMsg.ModelProperties?.BroadcastPlacement) newMsg.KPIs.BroadcastGross = 0;
                    else await SetBroadcastPriceOffer(newMsg, getFormatOffer(newMsg.Format, newMsg?.ModelProperties["Couleur"], newMsg.ModelProperties?.BroadcastPlacement, offers));
                }
            }

            const mediaPresse = media?.find(m => m.Code == "PR")?.['@rid'];
            // Init/Update OJD
            if ((!newMsg['@rid'] && !isInitializing)
                && mediaPresse && newMsg.Media == mediaPresse
                && (!hasOwnProperty(newMsg.ModelProperties, "OjdYear") || Support != newMsg.Support || BroadcastArea != newMsg.BroadcastArea)
                && newMsg.Support
                && newMsg.BroadcastArea) {
                ConsoleDebug('Init/Update OJD');
                let waves: string[];
                try {
                    waves = await DataProvider.getOJDWaves({ support: newMsg.Support, broadcastArea: newMsg.BroadcastArea });
                } catch (error) {
                    console.error(error);
                    Notify(Trad('cannot_retrieve_ojd_data'), 'warning')
                    waves = [];
                }

                newMsg.ModelProperties["OjdYear"] = waves?.[0] ?? null;
                const ojd = waves?.length && (await DataProvider.getOJD({ support: newMsg.Support, broadcastArea: newMsg.BroadcastArea, year: newMsg.ModelProperties?.["OjdYear"] }))?.[0]
                newMsg.KPIs["Release"] = ojd?.MiseEnDistribution ?? 0;
                newMsg.KPIs["PaidCirculation"] = ojd?.DiffusionPayeeFrance ?? 0;
                newMsg.KPIs["TotalCirculation"] = ojd?.DiffusionTotaleFrance ?? 0;
                newMsg.KPIs["Subscriptions"] = ojd?.Abonnements ?? 0;
                if (newMsg.ModelProperties["OjdYear"] != newMsg.ModelProperties?.["OjdYear"]
                    || (!compareObjects(extractSub((KPIs ?? {}) as any, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"]), extractSub(newMsg.KPIs as any, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"]))))
                    Notify(Trad("update_ojd"), "info");
            }

            const setGroup = async (ids: string, category: string, property: string, other: string): Promise<ref_Groups[]> => {
                const otherRid = "#-1:-1";
                const messageProperty = newMsg.ModelProperties[property];
                const messagePropertyOther = newMsg.ModelProperties[other];
                const options = await Client.searchVertexTyped(ref_Groups, {
                    Active: true,
                    Category: category,
                    IDs: require ? ids ?? [] : undefined,
                    _strictParams: { Area: { "$elemMatch": { Class: ref_Advertisers.name, IDs: { $in: [newMsg.Advertiser, null] } } } }
                });
                options.push({ Name: Trad("other"), "@rid": otherRid } as any);
                if (isCreatingMessage && isInitializing)
                    if ((!messageProperty && !messagePropertyOther) || (messageProperty && !options.some(c => c["@rid"] == messageProperty))) {
                        const _group = options.find(v => v.Default) ?? options.find(v => v["@rid"] != otherRid);
                        newMsg.ModelProperties[property] = _group?.["@rid"] ?? null;
                        if (newMsg.ModelProperties[property] && newMsg.ModelProperties[property] !== otherRid) newMsg.ModelProperties["MediaFamilyOther"] = null;
                        else newMsg.ModelProperties[other] = "";
                    }
                return options;
            };

            let mediaFamilyOptions = null;
            if (isInitializing || Support !== newMsg.Support)
                mediaFamilyOptions = await setGroup(newMsg.Support, eGroupCategories.MediaFamily, "MediaFamily", "MediaFamilyOther")

            let placementCategoryOptions = null;
            if (isInitializing || Placement !== newMsg.Placement)
                placementCategoryOptions = await setGroup(newMsg.Placement, eGroupCategories.PlacementCategory, "PlacementCategory", "PlacementCategoryOther")

            if (!newMsg.Discounts) newMsg.Discounts = [];

            let currency = state.messageEditor.currency;
            let currencyCode = state.messageEditor.currencyCode;

            if (newMsg.Currency != currency?.['@rid']) {
                currency = undefined;
                currencyCode = "";
                if (newMsg.Currency) {
                    currency = (await Client.searchVertexTyped(ref_Currencies, { "@rid": newMsg.Currency }))[0];
                    currencyCode = currency.Code;
                }
            }


            let kpis: lnk_HasKPIExtended[] = null;
            if (newMsg?.Media && newMsg.Media != Media) {
                const _messageModelMgr = new MessageModelManager(newMsg.Media, "Message");
                kpis = await _messageModelMgr.GetKPIs();
            }


            // Si on est en train d'initialiser le message, on sauvargarde la nouvelle version de l'agreement
            let currentAgreement = undefined;
            if (isInitializing) {
                if (newMsg.Agreement) [currentAgreement] = await Client.searchVertexTyped(ref_Agreements, { "@rid": newMsg.Agreement });
                else currentAgreement = null;
            }
            const kpiCPMHasChanged = () => {
                const oldKpis = Discounts?.filter(d => d.IsCPM).map(d => KPIs[d.KpiCPM]);
                const newKpis = newMsg?.Discounts?.filter(d => d.IsCPM).map(d => newMsg.KPIs[d.KpiCPM]);
                return !compareObjects(oldKpis, newKpis);
            }
            // si les discount ont changé
            // TODO à optimiser
            if (newMsg.KPIs?.Gross != KPIs?.Gross
                || (newMsg.Discounts?.length && !compareObjects(newMsg.Discounts, Discounts))
                || kpiCPMHasChanged())
                cascade = await DiscountManager.UpdateCascade(newMsg);

            const _time7642 = new Date().getTime();
            ConsoleDebug(`SetMessage Elapsed ${_time7642 - time7642}ms`);

            return { cascade, message: ToRefMessageSerializable(newMsg), offers, currency, currencyCode, currencyOptions, broadcastOptions, mediaFamilyOptions, placementCategoryOptions, AdvCompany_ComOptions, AdvCompany_FinOptions, agreements, globalAgreement, kpis, currentAgreement }
        } catch (error) {
            console.error(error);
            Notify(Trad('error'), 'error');
            Client.log({ Category: 'ERROR', Action: 'setMessage', Informations: SerializeError(error) });


        }
    }
)

export const messageEditorSlice = createSlice({
    name: 'messageEditor',
    initialState: clone(initialState),
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        builder.addCase(setMessage.pending, (state, action) => {
            state.lockNext = true;
        })

        const fulfilledCallback = (state: MessageEditorState, action) => {
            if (action.payload?.message)
                state.message = action.payload.message;
            if (action.payload?.currencyCode)
                state.currencyCode = action.payload.currencyCode;
            if (action.payload?.offers)
                state.offers = action.payload.offers;
            if (action?.payload?.currencyOptions)
                state.currencyOptions = action.payload.currencyOptions;
            if (action?.payload?.broadcastOptions)
                state.broadcastOptions = action.payload.broadcastOptions;
            if (action?.payload?.mediaFamilyOptions)
                state.groups.MediaFamily = action.payload.mediaFamilyOptions;
            if (action?.payload?.placementCategoryOptions)
                state.groups.PlacementCategory = action.payload.placementCategoryOptions;
            if (action?.payload?.AdvCompany_ComOptions)
                state.AdvCompany_ComOptions = action.payload.AdvCompany_ComOptions;
            if (action?.payload?.AdvCompany_FinOptions)
                state.AdvCompany_FinOptions = action.payload.AdvCompany_FinOptions;
            if (action?.payload?.agreements)
                state.agreements = action.payload.agreements;
            if (action?.payload?.globalAgreement)
                state.globalAgreement = action.payload.globalAgreement;
            if (action?.payload?.kpis)
                state.kpis = action.payload.kpis;
            if (action?.payload?.currentAgreement !== undefined)
                state.currentAgreement = action.payload.currentAgreement;
            if (action?.payload?.cascade !== undefined)
                state.cascade = action.payload.cascade;
        }

        builder.addCase(setMessage.fulfilled, (state, action) => {
            fulfilledCallback(state, action);
            state.lockNext = false;
        });

        builder.addCase(setNet.fulfilled, fulfilledCallback);
        builder.addCase(initCurrencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initBroadcastOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvComOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvFinOptions.fulfilled, fulfilledCallback);
    },
    reducers: {
        lockNext: (state, action: PayloadAction<void>) => {
            state.lockNext = true;
        },
        unlockNext: (state, action: PayloadAction<void>) => {
            state.lockNext = false;
        },
        toggleRepeat: (state, action: PayloadAction<void>) => {
            state.repeat = !state.repeat;
        },
        disableRepeat: (state, action: PayloadAction<void>) => {
            state.repeat = false;
        },
        setStart: (state, action: PayloadAction<{ start: Date, force?: boolean }>) => {
            const start = action.payload.start;
            state.message.Start = start?.toString() as any;

            if (action.payload.force) {

            }
            //state.message.

        },
        setPublications: (state, action: PayloadAction<ref_Publications[]>) => {
            state.publications = action.payload ? duplicate(action.payload) : undefined;
        },
        setCurrentNetType: (state, action: PayloadAction<netType>) => {
            state.currentNetType = action.payload;
        },
        setMessageSync: (state, action: PayloadAction<ref_Messages>) => {

            if (!action.payload) {
                const cloneValues = clone(initialState);
                Object.entries(cloneValues).forEach(([key, value]) => {
                    state[key] = value;
                });
                return;
            }

            const newMsg: ref_MessagesSerializable = {
                ...duplicate(action.payload),
                Start: action.payload.Start ? new Date(action.payload.Start).getTime() : undefined,
                End: action.payload.End ? new Date(action.payload.End).getTime() : undefined,
            }

            if (!newMsg.Discounts)
                newMsg.Discounts = [];

            if (!newMsg.ModelProperties) newMsg.ModelProperties = {};
            state.message = newMsg;
        },
        clearMessageEditor: (state, action: PayloadAction<void>) => {
            state.currencyOptions = undefined;
            state.broadcastOptions = undefined;
            state.AdvCompany_ComOptions = undefined;
            state.AdvCompany_FinOptions = undefined;
        },


        // setOffers: (state, action: PayloadAction<ref_Offers[]>) => {
        //     state.offers = action?.payload.map(o => ({
        //         ...duplicate(o),
        //         Start: o.Start ? new Date(o.Start).getTime() : undefined,
        //         End: o.End ? new Date(o.End).getTime() : undefined,
        //     }))
        // }
    },
})

type GetDefaultBroadcastAreasRes = {
    broadcastAreaOptions: ref_BroadcastAreas[],
    broadcastAreaDefault: ref_BroadcastAreas['@rid']
}

export async function GetDefaultAdvertisingCompany(support: ref_Supports['@rid'], broadcastArea?: ref_BroadcastAreas['@rid']) {
    try {

        //let defaultAdvCompany: ref_AdvertisingCompanies['@rid'] = null;
        let options: (LnkAdv | ref_SubAgencies)[] = undefined;
        ConsoleDebug('UpdateAdvertisingCompany');
        const tempParams = duplicate({
            Active: true,
            out: [support],
            properties: ["in", "@class", "in.Name as Name", "in.Active as Active", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"],
            // ...params
        });

        const user = JSON.parse(localStorage.getItem("user")) as UserExtended;
        const newOptions = (await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams) as (lnk_AdvertisingCompanySupport & { Name: string })[])
            .filter(e => e.Active);
        const subAgencies = user?.customer?.Authorization == "MediaManager" ? await Client.searchVertexTyped(ref_SubAgencies) : [];
        options = [...newOptions, ...subAgencies].sort((a, b) => a.Name.localeCompare(b.Name));

        options = distinct(options, _value => _value?.['outRid'] ?? _value?.['@rid']);

        let defaultValue: IRid['@rid'] = null;
        const setValue = (value: LnkAdv | ref_SubAgencies) => {
            if (!value) {
                defaultValue = null;
                return;
            }

            let newValue: IRid["@rid"];
            if (value["@class"] == lnk_AdvertisingCompanySupport.name) newValue = (value as LnkAdv).outRid;
            else newValue = value["@rid"];
            defaultValue = newValue;
        }
        const setDefaultValue = async () => {

            if (options?.length === 1)
                return setValue(options[0]);

            const _options = options?.filter(o => o["@class"] == lnk_AdvertisingCompanySupport.name) as LnkAdv[]

            let defaultVal: LnkAdv | ref_SubAgencies;

            if (!defaultVal)
                defaultVal = _options?.find(v => v.Default);

            if (!defaultVal && broadcastArea) {
                const found = _options?.find(e => e.DefaultBroadcastArea == broadcastArea);
                if (found) {
                    defaultValue = found.in
                    return;
                }
            }

            setValue(defaultVal);
        }
        await setDefaultValue();

        return { options: options.map(o => ({ '@rid': o['outRid'] ?? o['@rid'], Name: o['Name'] })), defaultValue };
    } catch (error) {
        console.log(`error UpdateAdvertisingCompany`, error)
    }
}


export async function GetDefaultBroadcastAreas(support: ref_Supports['@rid']): Promise<GetDefaultBroadcastAreasRes> {
    let broadcastAreaOptions: ref_BroadcastAreas[] = undefined;

    ConsoleDebug('UpdateBroadcastState');

    const dispatchChanged = (broadcastArea: IRid) => {
        return {
            broadcastAreaOptions,
            broadcastAreaDefault: broadcastArea?.["@rid"]
        }
    }

    const lnkAdv = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": support })
    const adv = distinct(lnkAdv.map(l => l.in), e => e);
    const broadcastAreas = await Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": [support, ...adv] });

    const setDefault = async () => {
        broadcastAreaOptions = await Client.searchVertexTyped(ref_BroadcastAreas, {
            Active: true,
            "@rid": distinct(broadcastAreas.map(l => l.out), e => e),
            properties: ["@rid", "Name"]
        })

        if (broadcastAreaOptions.length == 1) {
            return dispatchChanged(broadcastAreaOptions[0]);
        }

        // const selectedElement = broadcastAreaOptions?.find(o => o["@rid"] == message.BroadcastArea);
        // if (selectedElement)
        //     return dispatchChanged(selectedElement);

        // recherche les régies associées au support
        let advDefault = distinct(lnkAdv.map(lnk => lnk.in), e => e);
        // si une seule régie alors elle est par défaut. Sinon recherche les régies dont le lien est par défaut
        if (advDefault.length > 1)
            advDefault = distinct(lnkAdv.filter(lnk => lnk.Default).map(lnk => lnk.in), e => e);

        if (advDefault.length) {
            // recherche de la zone de diff par défaut entre dans le lient support <=> régie
            const found = lnkAdv
                .filter(lnk => advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default))
                .map(lnk => lnk.DefaultBroadcastArea);

            if (found.length)
                return dispatchChanged(broadcastAreaOptions.find(o => found.includes(o["@rid"])))

            // recherche de la zone de diff par défaut de la première régie trouvée
            const broadcastAdvDefault = broadcastAreas.find(lnkB => advDefault.includes(lnkB.in) && lnkB.Default)?.out;
            if (broadcastAdvDefault)
                return dispatchChanged(broadcastAreaOptions.find(o => o["@rid"] == broadcastAdvDefault));
        }

        // recherche des zones de diff par défaut du support
        const defaultElement = broadcastAreaOptions.find(o => broadcastAreas.some(lnk => lnk.out == o["@rid"] && lnk.Default));
        return dispatchChanged(defaultElement);
    }

    return setDefault();
}

async function UpdateBroadcastState(messageEditor: MessageEditorState, message: ref_MessagesSerializable | ref_Messages) {
    let broadcastOptions: ref_BroadcastAreas[] = undefined;
    const { Support } = messageEditor.message ?? {};
    if (messageEditor.broadcastOptions == undefined || Support !== message.Support) {
        ConsoleDebug('UpdateBroadcastState');

        const dispatchChanged = (broadcastArea: IRid) => {
            if (broadcastArea) {
                message.BroadcastArea = broadcastArea?.["@rid"];
            }
        }

        const lnkAdv = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": message.Support })
        const adv = distinct(lnkAdv.map(l => l.in), e => e);
        const broadcastAreas = await Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": [message.Support, ...adv] });

        const setDefault = async () => {
            broadcastOptions = await Client.searchVertexTyped(ref_BroadcastAreas, {
                Active: true,
                "@rid": distinct(broadcastAreas.map(l => l.out), e => e),
                properties: ["@rid", "Name"]
            })

            if (broadcastOptions.length == 1) {
                return dispatchChanged(broadcastOptions[0]);
            }

            const selectedElement = broadcastOptions?.find(o => o["@rid"] == message.BroadcastArea);
            if (selectedElement)
                return dispatchChanged(selectedElement);

            // recherche les régies associées au support
            let advDefault = distinct(lnkAdv.map(lnk => lnk.in), e => e);
            // si une seule régie alors elle est par défaut. Sinon recherche les régies dont le lien est par défaut
            if (advDefault.length > 1)
                advDefault = distinct(lnkAdv.filter(lnk => lnk.Default).map(lnk => lnk.in), e => e);

            if (advDefault.length) {
                // recherche de la zone de diff par défaut entre dans le lient support <=> régie
                const found = lnkAdv
                    .filter(lnk => advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default))
                    .map(lnk => lnk.DefaultBroadcastArea);

                if (found.length)
                    return dispatchChanged(broadcastOptions.find(o => found.includes(o["@rid"])))

                // recherche de la zone de diff par défaut de la première régie trouvée
                const broadcastAdvDefault = broadcastAreas.find(lnkB => advDefault.includes(lnkB.in) && lnkB.Default)?.out;
                if (broadcastAdvDefault)
                    return dispatchChanged(broadcastOptions.find(o => o["@rid"] == broadcastAdvDefault));
            }

            // recherche des zones de diff par défaut du support
            const defaultElement = broadcastOptions.find(o => broadcastAreas.some(lnk => lnk.out == o["@rid"] && lnk.Default));
            dispatchChanged(defaultElement);
        }

        await setDefault();
    }
    return broadcastOptions;
}

async function UpdateCurrencyState(messageEditor: MessageEditorState, message: ref_MessagesSerializable | ref_Messages) {

    const { BroadcastArea, Support, AdvCompany_Com } = messageEditor.message ?? {};
    let currencyOptions: TCurrency[] = undefined;

    if (messageEditor.currencyOptions == undefined ||
        (BroadcastArea !== message.BroadcastArea || Support !== message.Support || AdvCompany_Com != message.AdvCompany_Com)) {
        ConsoleDebug('UpdateCurrencyState');
        // if (BroadcastArea !== message.BroadcastArea)
        //     console.log(`BroadcastArea changed:  ${BroadcastArea} => ${message.BroadcastArea}`)

        // if (Support !== message.Support)
        //     console.log(`Support changed: ${Support} => ${message.Support}`)

        // if (AdvCompany_Com !== message.AdvCompany_Com)
        //     console.log(`AdvCompany_Com changed: ${AdvCompany_Com} => ${message.AdvCompany_Com}`)


        const setCurrency = async () => {
            const setValue = (value: TCurrency) => {
                message.Currency = value?.["@rid"];
                return value;
            }

            let lastBroadcastArea: ref_BroadcastAreas["@rid"] = message.BroadcastArea;
            if (message.BroadcastArea) {
                const parents = new Set<IRid["@rid"]>();
                while (true) {
                    const [broadcastArea] = await Client.searchVertexTyped(BroadcastAreaExtended, { "@rid": lastBroadcastArea, parent: true });

                    if (!broadcastArea)
                        break;

                    lastBroadcastArea = broadcastArea["@rid"];
                    if (broadcastArea.lnkCurrencies?.length)
                        break;

                    const parent = (broadcastArea as any)?.parent;
                    if (!parent || parents.has(parent))
                        break;

                    lastBroadcastArea = parent;
                    parents.add(parent);
                }
            }

            let advertisingCompany: AdvertisingCompanyExtended = null;
            if (message.AdvCompany_Com)
                advertisingCompany = (await Client.searchVertexTyped(AdvertisingCompanyExtended, { "@rid": message.AdvCompany_Com }))?.[0];

            const res: (lnk_HasCurrency & { outRid: IRid["@rid"], Code: string, Name: string, Default: boolean })[] = await Client.searchVertexTyped(lnk_HasCurrency, {
                Active: true,
                in: [message.Support, lastBroadcastArea].filter(e => e),
                properties: ["*", "out.Name as  Name", "out.Code as Code", "out.@rid as outRid"]
            }) as any;

            currencyOptions = res.map(r => Typed<TCurrency>({
                "@rid": r.outRid,
                Name: r.Name,
                Code: r.Code,
                Default: r.Default,
                link: r.in
            }))

            const links = advertisingCompany?.lnkBroadcastAreas?.filter(m => m.DefaultCurrency && m.out == message.BroadcastArea);
            if (links?.length) {
                const currencies = await Client.searchVertexTyped(ref_Currencies, { "@rid": links.map(l => l.DefaultCurrency) });
                currencyOptions = [
                    ...currencyOptions,
                    ...links.map(r => {
                        const currency = currencies.find(c => c["@rid"] == r.DefaultCurrency);
                        return Typed<TCurrency>({
                            "@rid": r.DefaultCurrency,
                            Name: currency.Name,
                            Code: currency.Code,
                            Default: r.Default,
                            link: r.in
                        })
                    })]
            }

            try {
                if (messageEditor.currencyOptions === undefined && message.Currency) {
                    const defaultValue = currencyOptions?.find(o => o["@rid"] == message.Currency || (o as any).outRid == message.Currency);
                    if (!defaultValue) {
                        console.log(`add inexising currency`)
                        const [missingValue] = await Client.searchVertexTyped(ref_Currencies, { "@rid": message.Currency });
                        if (missingValue) {
                            (missingValue as any).warning = Trad('data_not_found');
                            currencyOptions = [missingValue as any, ...currencyOptions];
                        }
                    }
                }
            } catch (error) {
                console.log(`error`, error)
            }


            const broadcastAreaWithCurrency = lastBroadcastArea;

            if (message.Currency) {
                const found = currencyOptions?.find(v => v["@rid"] == message.Currency);
                if (found) return setValue(found);
                //else Notify(Trad("message_currency_not_found"), "warning");
            }

            if (currencyOptions?.length === 1)
                return setValue(currencyOptions[0]);

            if (advertisingCompany) {
                const links = advertisingCompany?.lnkBroadcastAreas?.filter(l => l.out == message.BroadcastArea &&
                    l.DefaultCurrency);
                if (links.length) {
                    const link = links.find(l => l.Default) ?? links[0];
                    const opt = currencyOptions?.find(o => o["@rid"] == link.DefaultCurrency);
                    if (opt)
                        return setValue(opt);
                }
            }

            if (broadcastAreaWithCurrency) {
                const currencies = currencyOptions?.filter(e => e.link == broadcastAreaWithCurrency);
                if (currencies.length == 1)
                    return setValue(currencies[0]);

                const defaultCurrency = currencies.find(e => e.Default);
                if (defaultCurrency)
                    return setValue(defaultCurrency);
            }

            const defaultVal = currencyOptions?.find(v => {
                if (message.Currency)
                    return v["@rid"] == message.Currency;
                if (v.Default)
                    return Boolean(setValue(v));
                return false;
            });

            setValue(defaultVal);
        }

        await setCurrency();
    }

    return currencyOptions && distinct(currencyOptions, (e) => e['@rid']);
}

export type LnkAdv = (lnk_AdvertisingCompanySupport & {
    outRid: IRid["@rid"];
})

let financialRid: IRid["@rid"] = undefined;
let commercialRid: IRid["@rid"] = undefined;

async function UpdateAdvertisingCompany(property: "AdvCompany_Com" | "AdvCompany_Fin", messageEditor: MessageEditorState, message: ref_MessagesSerializable | ref_Messages) {
    try {
        const currentOptions = messageEditor[`${property}Options`];
        let options: (LnkAdv | ref_SubAgencies)[] = undefined;
        const { Support, ApplyComBarter, KPIs } = messageEditor.message ?? {};
        if (currentOptions == undefined || Support !== message.Support || ApplyComBarter != message.ApplyComBarter ||
            ((!KPIs?.Barter && message.KPIs.Barter) || (KPIs?.Barter && !message.KPIs.Barter))) {
            ConsoleDebug('UpdateAdvertisingCompany');
            const setValue = (value: LnkAdv | ref_SubAgencies) => {
                if (!value) {
                    message[property] = null;
                    return;
                }

                let newValue: IRid["@rid"];
                if (value["@class"] == lnk_AdvertisingCompanySupport.name) newValue = (value as LnkAdv).outRid;
                else newValue = value["@rid"];
                message[property] = newValue;
            }

            let params = undefined;
            let barterCompanies: ref_AdvertisingCompanies[] = [];

            switch (property) {
                case "AdvCompany_Com":
                    if (!commercialRid) commercialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Commercial" }))?.data?.results?.[0]?.["@rid"];
                    params = { Roles: commercialRid }
                    break;
                case "AdvCompany_Fin":
                    if (!financialRid) financialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Financial" }))?.data?.results?.[0]?.["@rid"];
                    params = { Roles: financialRid }
                    break;
                default:
                    break;
            }
            if ((message.KPIs.Barter || message.SmartBarter) &&
                (property == "AdvCompany_Fin" || message.ApplyComBarter)) {
                barterCompanies = await Client.searchVertexTyped(ref_AdvertisingCompanies, { "IsBarter": true });
            }
            const tempParams = duplicate({
                Active: true,
                out: [message.Support],
                properties: ["in", "Start", "End", "@class", "in.Name as Name", "in.Start as ValidStart", "in.End as ValidEnd", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"],
                ...params
            });

            const user = JSON.parse(localStorage.getItem("user")) as UserExtended;
            let newOptions = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams) as (lnk_AdvertisingCompanySupport & { Name: string })[];
            const subAgencies = user?.customer?.Authorization == "MediaManager" ? await Client.searchVertexTyped(ref_SubAgencies) : [];

            if (message.Start) {
                newOptions = newOptions.filter(lnk =>
                    DateZone(lnk.Start) <= message.Start && (!lnk.End || message.Start <= DateZone(lnk.End))
                    && DateZone(lnk["ValidStart"]) <= message.Start && (!lnk["ValidEnd"] || message.Start <= DateZone(lnk["ValidEnd"])));
            }

            options = [...newOptions, ...subAgencies, ...barterCompanies].sort((a, b) => a.Name.localeCompare(b.Name));

            const setDefaultValue = async () => {

                if (options?.length === 1)
                    return setValue(options[0]);

                const _options = options?.filter(o => o["@class"] == lnk_AdvertisingCompanySupport.name) as LnkAdv[]

                let defaultVal: LnkAdv | ref_SubAgencies;

                if (message[property]) {
                    defaultVal = _options?.find(v => v.outRid == message[property]);
                    if (!defaultVal)
                        defaultVal = options?.find(v => v["@rid"] == message[property]);
                }

                if (!defaultVal)
                    defaultVal = _options?.find(v => v.Default);

                if (!defaultVal && message.BroadcastArea) {
                    const found = _options?.find(e => e.DefaultBroadcastArea == message.BroadcastArea);
                    if (found) {
                        message[property] = found.in
                        return;
                    }
                }

                setValue(defaultVal);
            }

            if (currentOptions === undefined) {
                if (message[property]) {
                    const defaultValue = options?.find(o => o["@rid"] == message[property] || (o as any).outRid == message[property]);
                    if (!defaultValue) {
                        console.log(`add inexising`)
                        const [missingValue] = await Client.searchVertexTyped(ref_AdvertisingCompanies, { "@rid": message[property] });
                        if (missingValue) {
                            (missingValue as any).warning = Trad('data_not_found');
                            options = [missingValue, ...options];
                        }
                    }
                }
            }
            await setDefaultValue();
        }

        return options;
    } catch (error) {
        console.log(`error UpdateAdvertisingCompany`, error)
    }
}

// Action creators are generated for each case reducer function
export const { setCurrentNetType, setMessageSync, setPublications, setStart, lockNext, unlockNext, toggleRepeat, disableRepeat, clearMessageEditor } = messageEditorSlice.actions

export const messageEditorReducer = messageEditorSlice.reducer;


