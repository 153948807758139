import { Grid, TextField } from '@material-ui/core'
import { Client } from 'hub-lib/client/client.bin'
import { IRid } from 'hub-lib/models/IRid.bin'
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin'
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin'
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin'
import { ref_AdvertisingCompanyGroups } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin'
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin'
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin'
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin'
import { ref_Countries } from 'hub-lib/models/orientdb/ref_Countries.bin'
import * as React from 'react'
import { Notify } from '../../../utils/Notify.bin'
import { VertexAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { eDialogMode, GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import Loader from '../Loader'
import { ErrorMessage } from '../../ConfigurableComponents/ErrorMessage.bin'
import { Trad, TradClassName } from 'trad-lib'
import { E } from 'hub-lib/models/orientdb/E.bin'
import { ReferentialForm } from '../../VertexGrid/Adwone-admin/Referential/ReferentialForm.bin'

const TvaRegex = new RegExp("^([a-zA-Z]{2}[0-9]{11})$")
class TProps {
    company: string
    changeCountry: (ridCountry: any) => void
}
class TState {
    company: ref_Companies
    loading: boolean = true
    country: string
    childrens: ref_Companies[]
    open: boolean = false
}

export let groups = [ref_AdvertiserGroups.name, ref_AdvertisingCompanyGroups.name, ref_AgencyGroups.name]

export let groupToChild = {
    [ref_AdvertiserGroups.name]: ref_Advertisers.name,
    [ref_AdvertisingCompanyGroups.name]: ref_AdvertisingCompanies.name,
    [ref_AgencyGroups.name]: ref_Agencies.name
}
export class CustomerInfos extends React.Component<TProps, TState>{
    constructor(props: TProps) {
        super(props)
        let newState = new TState()
        this.state = newState
    }

    initialiseInfos = async () => {
        let parent = (await Client.searchVertexTyped(ref_Companies, { "@rid": [this.props.company] }))?.[0];

        if (!parent)
            return Notify(Trad('cannot_retrieve_company'), 'error');

        let country = parent.Country != undefined ? (await this.getName(parent?.Country)) : ''
        if (!groups.includes(parent["@class"])) {
            this.setState({
                company: parent,
                childrens: [],
                country,
                loading: false
            })
        } else {
            let childs = (await Client.searchVertex<ref_Companies>(groupToChild[parent["@class"]], { "parents": [this.props.company] }))?.data.results
            this.setState({
                company: parent,
                childrens: childs,
                country,
                loading: false
            })
        }
    }
    componentDidMount() {
        this.initialiseInfos()
    }
    getName = async (rid: any) => {
        let name: any
        await Client.searchVertex(ref_Countries.name, { "@rid": rid })
            .then(r => {
                name = r.data.results[0].Name ?? ''
            })
        return name
    }
    CompanyUpdated = () => {
        this.setState({ open: false }, () => {
            this.initialiseInfos().then(res => this.props.changeCountry(this.state.company.Country))
        })
    }
    render() {
        if (this.state.loading)
            return <Loader />
        let { company, childrens, country } = this.state

        return (<>
            <Grid container item xs={12} className="adw-row" style={{ backgroundColor: "rgb(237, 237, 236", paddingTop: "12px", position: "relative" }}>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>{Trad("property_Name")}</label>
                    <div>{company.Name ?? ''}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>Siret</label>
                    <div>{company.Siret ?? ''}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>TVA UE</label>
                    <div>{company.Tva ?? ''}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>{Trad("adress")}</label>
                    <div>{`${company.Adress ?? ''} ${company.ZipCode ?? ''} ${company.City ?? ''}`}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>{Trad("property_Country")}</label>
                    <div>{country ?? ''}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>Siren</label>
                    <div>{company.Siren ?? ''}</div>
                </Grid>
                <Grid item xs={8} className="adw-row box-right legend">
                    <label>{Trad("companies_of_group")}</label>
                    <div>{childrens.length > 0 && childrens.map((e, i) => { return <span key={i}>{e.Name}{i < childrens.length - 1 && ' / '}</span> })}</div>
                </Grid>
                <Grid item xs={4} className="adw-row box-right legend">
                    <label>GLN</label>
                    <div>{company.Gln ?? ''}</div>
                </Grid>

            </Grid>
            <Grid item xs={12} className="adw-form-action">
                <CustomButton
                    Label="Modifier les informations"
                    className="custom_btn_primary"
                    onClick={() => this.setState({ open: true })}
                />
            </Grid>
            <GenericDialog
                id="edit_customer_company_dialog"
                open={this.state.open}
                dialogTitle={`${Trad(eDialogMode.modify)} ${TradClassName(company['@class'])}`}>
                <div className='adw-form-dialog-container'>
                    <ReferentialForm
                        onSave={() => this.CompanyUpdated()}
                        mode={eDialogMode.modify}
                        onCancel={() => this.setState({ open: false })}
                        selectedVertex={company['@class']}
                        selectedItem={company} /></div>
            </GenericDialog>
        </>
        )
    }
}