
import { Client } from 'hub-lib/client/client.bin';
import { User } from 'hub-lib/models/orientdb/User.bin';
import * as React from 'react';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { CustomTextField } from '../../VertexGrid/Generic/CustomTextField';
import history from '../../../utils/history'
import { Log } from 'hub-lib/models/Log.bin';
import { propertyOf, removeDiacritics, sleep } from 'hub-lib/tools.bin';
import { Notify } from '../../../utils/Notify.bin';
import { LoadFakeStorage } from '../../../LoadLocalStorage';
import { VertexGrid } from '../../VertexGrid/Generic/VertexGrid.bin';
import { ADWColumn, AdwRow } from 'adwone-lib';
import { Trad, TradProp } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../redux/store';
import { setConnectedUsers } from '../../../redux/connectedStatusSlice';
import { UserExtended } from 'hub-lib/models/UserExtended.bin';

let interval = null;
const hiddenProperties = ["lastAccess","lastConnexion","Rights","profile","isOnline","isDefaultPassword","registrationDate","userPermissions","phone","job","qualification","sessionsTime","person","company"]
hiddenProperties.push(propertyOf<User>("Department"))

export const BroadcastMessage = () => {
    const dispatch = useDispatch();

    const [message, setMessage] = React.useState();
    const [grid, setGrid] = React.useState<VertexGrid<UserExtended>>(undefined)
    const gridHeight = 700

    const initGrid = async () => {
        const columns: ADWColumn<UserExtended>[] = [];
        let company = propertyOf<UserExtended>("company");
        let columnCompany = new ADWColumn<UserExtended>(TradProp(company, UserExtended), company, ePropType.String, false);

        columnCompany.getValue = (cellValue: UserExtended) => {
            return `${cellValue?.company?.Name ?? ""}`
        }
        columns.push(columnCompany);

        let person = propertyOf<UserExtended>("person");
        let columnperson = new ADWColumn<UserExtended>(TradProp(person, UserExtended), person, ePropType.Embedded, false);

        columnperson.getValue = (cellValue: UserExtended) => {
            return `${cellValue.person?.FirstName ?? ""} ${cellValue.person?.LastName ?? ""}`
        }
        columns.push(columnperson);

        const statusColumn = new ADWColumn<UserExtended>(TradProp("isOnline", UserExtended), "isOnline", ePropType.Boolean);
        statusColumn.cellValue = (CellValue, dataItem?:AdwRow<UserExtended>) => {
            return <Status dataItem={dataItem.dataItem} />
        }

        columns.push(statusColumn);
        
        const connectColumn = new ADWColumn<UserExtended>(Trad("Connect"), "Connect", ePropType.String, false);
        connectColumn.cellValue = (CellValue, dataItem?:AdwRow<UserExtended>) => {
            const { name } = dataItem.dataItem;
            const mail = name?.split?.(" ")?.[0];
            return <CustomButton
            Label={'Se connecter'}
            onClick={async () => {
                Notify('please_wait', 'info');
                await Client.broadcast({}, "localstorage");
                await sleep(2000);
                const [logElement] = await Client.searchVertexTyped(Log, { "Report.Category": "localstorage", User: mail });
                if (logElement?.Report?.Informations) {
                    LoadFakeStorage(logElement.Report.Informations);
                }
                await Client.bypassUser(mail);
                history.push('/');
                window.location.reload();
            }} />

        }
        columns.push(connectColumn);
        const grid = new VertexGrid({
            objectPrototype: UserExtended,
            columns,
            hiddenProperties
        });
        setGrid(grid);
    }
    console.log(grid)

    React.useEffect(() => {
        if (!grid)
            initGrid();
    })

    React.useEffect(() => {
        const connectedUsers = async () => {
            const { connected } = await Client.getActiveSockets();
            const connectedUsers = Object.keys(connected).map(k => ({ '@rid': k.match(/\(([^)]+)\)/)[1], name: k, connected: true }));
            dispatch(setConnectedUsers({users:connectedUsers}))
        }

        clearInterval(interval);
        interval = setInterval(connectedUsers, 2000);
        return () => clearInterval(interval);
    })

    return <>
        <CustomTextField style={{ width: 500 }} value={message} onChange={(v) => setMessage(v)} />
        <CustomButton
            Label={"Envoyer le message"}
            onClick={async () => {
                await Client.broadcast({ message }, "message");
            }} />

        <CustomButton
            Label={"Logger les localstorages"}
            onClick={async () => {
                await Client.broadcast({}, "localstorage");
            }} />

        <div className='grid_container'>
            {grid && <AdwTelerikGrid
            grid={grid}
            uneditable={true}
            gridHeight={gridHeight}
            />}
        </div>
    </>
}


function Status(dataItem) {
    const onlineUsers = useSelector((state: RootState) => state.connectedStatus);
    const isConnected = onlineUsers.users?.some(user => dataItem.dataItem['@rid'] === user['@rid']);

  return (
    <div>
        {isConnected ? <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#3AB36E", margin: 'auto' }}></div> : <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#DA3240", margin: 'auto' }}></div>}
    </div>
  )
}