import React, { useState } from "react";
import { BaseTextFieldProps, InputLabelProps, StandardTextFieldProps, TextField, TextFieldProps } from "@material-ui/core";
import { GetNumberFormatCustom } from "../Messages/NumberFormatCustom.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";

type CustomTextFieldArgs = {
    label?: string,
    value?: any,
    type?: "number",
    disabled?: boolean,
    onChange?: (value: any) => void;
    style?: React.CSSProperties;
    placeholder?: string;
    InputLabelProps?: { shrink: boolean }
    textFieldProps?: Partial<TextFieldProps>
}

let key = 0;
const createKey = () => `CustomTextField-key-${key++}-${Date.now()}`;

export function CustomTextField({ label, value, type, disabled, onChange, style, placeholder, InputLabelProps, textFieldProps }: CustomTextFieldArgs) {

    const [key, setKey] = React.useState(createKey());
    const [_value, setValue] = React.useState(value);

    React.useEffect(() => {
        if (value != _value) {
            setKey(createKey());
            setValue(value);
        }
    }, [value])

    return <TextField
        key={key}
        disabled={disabled}
        {...(type && { type })}
        autoComplete='off'
        label={label}
        value={_value}
        {...(placeholder && { placeholder })}
        style={{ width: '100%', ...(style ?? {}) }}
        variant="outlined"
        onChange={(e) => {
            setValue(e.target.value);
            onChange?.(e.target.value);
        }}
        {...(InputLabelProps ? InputLabelProps : {
            InputLabelProps: { shrink: true }
        })}
        {...(type === "number" && { InputProps: { inputComponent: GetNumberFormatCustom(eKPIType.Decimal) as any } })}
        {...textFieldProps} />
}

type CustomTextFieldUpperArgs = { value: string, onChange: (val: string) => void } & BaseTextFieldProps;
export function CustomTextFieldUpper(props: CustomTextFieldUpperArgs) {

    const { value, onChange } = props;
    const [valueTxt, setValueTxt] = useState(value)

    return <TextField
        {...props}
        style={{ width: '100%' }}
        inputProps={{ style: { textTransform: 'uppercase' } }}
        variant="outlined"
        value={valueTxt}
        onChange={(e) => {
            if (e.target) {
                const newVal = e.target.value;
                setValueTxt(newVal);
                onChange?.(newVal?.toUpperCase());
            }
        }}
    />
}