import { Format } from 'format-lib/index.bin';
import { Client } from 'hub-lib/client/client.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { GetTemplates, TemplatedRid } from 'hub-lib/models/ref_SchedulerConfigurations.bin';
import { compareObjects, distinct, GetHashCode, GetSubElement } from 'hub-lib/tools.bin';
import * as React from 'react'
import { Provider, useSelector } from 'react-redux';
import { TradClassName } from 'trad-lib';
import { RootState, store } from '../../../redux/store';
import { GenericTooltip } from '../../ConfigurableComponents/GenericTooltip.bin';
import { CustomBadge } from '../../VertexGrid/Filters/MenuItemFilters';
import { TemplateToCSS } from '../../VertexGrid/Messages/MessagesToCSS';
import { RowWrapper } from '../CrossedTableTelerikTree.bin';
import { TextComponent } from './TextComponent';
import { toolTipGroup } from './TooltipGroup';
import { EngineTools } from 'adwone-engine/EngineTools';
import { GetNbDays } from 'tools-lib';

class TProps {
    row: RowWrapper;
}

type TextGeneratorProps = { row: RowWrapper, labelField: 'LabelBuild' | 'LabelBuildTop' | 'LabelBuildBottom' | 'StartLabel' | 'EndLabel' }
function TextGenerator({ row, labelField }: TextGeneratorProps) {
    const element = useSelector((root: RootState) => root.gridMessages.schedulerTemplate?.[labelField], compareObjects)
    return <TextComponent
        key={GetHashCode(element)}
        messages={row.Data}
        template={store.getState().gridMessages.schedulerTemplate}
        labelField={labelField} />
}

export const BadgeTemplate = (props?: { style?: React.CSSProperties }) => {
    return <span className='badge-template'>
        <CustomBadge cutoutBorder={false} style={props?.style} />
    </span>
}

export const LabelSchedulerSeparator = (props?: { style?: React.CSSProperties }) => {
    return <span className='text-separator'>
        <CustomBadge cutoutBorder={false} style={props?.style} />
    </span>
}

type BadgeTooltipProps = { badge: TemplatedRid }
function BadgeTooltip({ badge }: BadgeTooltipProps) {
    const [value, setValue] = React.useState<IRid>();
    React.useState(() => {
        if (!value)
            Promise.resolve()
                .then(async () => {
                    const [res] = (await Client.searchVertex(badge.type, { '@rid': badge['rid'] }))?.data?.results;
                    setValue(res);
                })
    })
    if (!value) return <div></div>
    return <div>{TradClassName(badge.type)}:{` ${Format(value)}`}</div>
}

const BadgeTooltipMemo = React.memo(BadgeTooltip, (prev, next) => GetHashCode(prev.badge) == GetHashCode(next.badge))

type GroupComponentBadgesProps = { row: RowWrapper }
function GroupComponentBadges({ row }: GroupComponentBadgesProps) {
    const badges = useSelector((root: RootState) => {
        const schedulerTemplate = root.gridMessages.schedulerTemplate;
        let templates = GetTemplates(row.Data, schedulerTemplate?.Style);
        if (schedulerTemplate?.BarProperty)
            templates = templates.filter(t => t.key != schedulerTemplate.BarProperty)
        return templates;
    }, compareObjects)
    return <>
        {badges?.map((b, i) => {
            return <GenericTooltip key={`row-id-badge-${row.id}-${i}`} tooltipContent={() => <BadgeTooltipMemo badge={b} />}>
                <BadgeTemplate style={{ color: b.textStyle?.color?.code, background: b.textStyle?.color?.code }} />
            </GenericTooltip>
        })}
    </>
}

export function GroupComponentBase({ row }: TProps) {

    const styleDefault = useSelector((root: RootState) => root.gridMessages.schedulerTemplate?.StyleDefault, compareObjects)
    const visible = useSelector((root: RootState) => {
        const badges = GetTemplates(row.Data, root.gridMessages.schedulerTemplate?.Style);
        const visible = (!badges?.length || badges.some(b => !b.textStyle.hidden));
        return visible
    });

    if (!row) return (<></>);

    const difference = new Date(row.End).getTime() - new Date(row.Start).getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    const styleFromTemplate = TemplateToCSS(styleDefault);
    const style: React.CSSProperties = {
        width: '100%',
        height: 'unset',
        ...styleFromTemplate,
        background: 'unset'
    };

    const isGroup = row.Data?.length > 1;

    return <div key={`row-id-${row.id}`} style={style}>
        <div className={`scheduler-text-container ${visible ? '' : 'hidden-scheduler-text-container'}`}>
            <div className='scheduler-text-cell'>
                <span className="content-task-group">
                    <TextGenerator row={row} labelField={'StartLabel'} />
                </span>
                <GroupComponentBadges row={row} />
                {totalDays > 0 && <span className="content-task-group" style={{ marginLeft: 'auto' }}>
                    <TextGenerator row={row} labelField={'EndLabel'} />
                </span>}
            </div>

            <GenericTooltip tooltipContent={toolTipGroup(row)}>
                <>
                    {/** SEPARATEUR */}
                    <BarComponent row={row} style={styleFromTemplate} />

                    {!isGroup && <>
                        <div className='scheduler-text-cell'><span className="content-task-group">
                            <TextGenerator row={row} labelField={'LabelBuildTop'} />
                        </span></div>
                        <div className='scheduler-text-cell'><span className="content-task-group">
                            <TextGenerator row={row} labelField={'LabelBuild'} />
                        </span></div>
                        <div className='scheduler-text-cell'><span className="content-task-group">
                            <TextGenerator row={row} labelField={'LabelBuildBottom'} />
                        </span></div>
                    </>}

                    {isGroup &&
                        <div className='scheduler-text-cell'><span className="content-task-group">
                            {`${row.Data?.length} Messages`}
                        </span></div>}
                </>
            </GenericTooltip>
        </div>
    </div>
}

class BarComponentProps extends TProps {
    style: React.CSSProperties;
}

export function BarComponent({ row, style }: BarComponentProps) {

    const templateStyles = useSelector((root: RootState) => root.gridMessages.schedulerTemplate?.Style, compareObjects)
    const barProp = useSelector((root: RootState) => root.gridMessages.schedulerTemplate?.BarProperty)

    let barColor: React.CSSProperties['color'] = undefined;
    if (barProp) {
        const barColors = distinct(row.Data.map(d => templateStyles?.[GetSubElement(d, barProp)]?.textStyle?.color?.code).filter(Boolean));
        if (barColors?.length == 1)
            barColor = barColors[0];
    }

    const barStyle: React.CSSProperties = { height: 3, ...style };
    if (barColor)
        barStyle.background = barColor;

    return <div className='task-element-override bar-dates-element'
        style={barStyle} />;
}

export function GroupComponent(props: TProps) {

    const { row } = props;
    const totalDays = GetNbDays(row.Start, row.End) + 1;
    const mergedElements = EngineTools.groupElementsByDate(row.Data);

    const getGroupWidth = (start: Date, end: Date) => {
        if (!start || !end) return 0;
        return ((GetNbDays(start, end) + 1) * 100) / totalDays
    }

    const getGapWidth = (start: Date, end: Date) => {
        if (!start || !end) return 0;
        return ((GetNbDays(start, end) - 1) * 100) / totalDays
    }

    return <Provider store={store}>
        <div style={{ display: 'flex' }}>
            {mergedElements.map((group, i) => {

                const rowWrapper: Partial<RowWrapper> = {
                    id: `${row.id}-${i}`,
                    Start: group.Start,
                    End: group.End,
                    Data: group.Data,
                    Formated: row.Formated,
                    Children: null,
                    Parent: row,
                    Level: row.Level + 1
                }

                const isLast = mergedElements[i + 1] == null;
                const gapWidth = getGapWidth(group.End, mergedElements[i + 1]?.Start);
                const groupWidth = getGroupWidth(group.Start, group.End);

                const totalWidth = gapWidth + groupWidth;
                return <>
                    <div style={{ overflow: isLast ? 'visible' : 'hidden', width: `${totalWidth}%` }}>
                        <div className='GroupComponent-element' style={{ width: `${(groupWidth / totalWidth) * 100}%` }}>
                            <GroupComponentBase {...props} row={rowWrapper as RowWrapper} />
                        </div>
                        {!isLast && <div className='GroupComponent-gap' style={{ width: `${(gapWidth / totalWidth) * 100}%` }}></div>}
                    </div>
                </>
            })}

        </div>

    </Provider>
}