import { GetCellTemplate } from "format-lib/index.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { Trad, TradComposed, TradProp } from "trad-lib";
import { AnchorRow, Row } from "../../Tools";
import { PanelDisable, SubTitle } from "../Generic/Common.bin";

type CampaignOverview = {
    children?: any;
    campaign: ref_Campaigns;
    estimateSwitch?: JSX.Element;
    showPerformances?: boolean;
};
export function CampaignOverview({
    children,
    campaign,
    estimateSwitch,
    showPerformances,
}: CampaignOverview) {
    return (
        <div className="MessageEditorOverview">
            {children}
            <InfoOverview campaign={campaign} />
            <EstimateOverview campaign={campaign} estimateSwitch={estimateSwitch} />
            <AdvertisingOverview campaign={campaign} />
            {showPerformances && <PerformancesOverview campaign={campaign} />}
        </div>
    );
}

function InfoOverview({ campaign }: { campaign: ref_Campaigns }) {
    const [advertisergroup, setAdvertisergroup] = React.useState<ref_AdvertiserGroups["Name"]>("");
    const [advertiser, setAdvertiser] = React.useState<ref_Advertisers["Name"]>("");
    const [brand, setBrand] = React.useState<ref_Brands["Name"]>("");
    const [product, setProduct] = React.useState<ref_Products["Name"]>("");
    const [group, setGroup] = React.useState<ref_Groups["Name"]>("");

    const propCheck = [
        { type: ref_AdvertiserGroups, setter: setAdvertisergroup, value: campaign.AdvertiserGroup },
        { type: ref_Advertisers, setter: setAdvertiser, value: campaign.Advertiser },
        { type: ref_Brands, setter: setBrand, value: campaign.Brand },
        { type: ref_Products, setter: setProduct, value: campaign.Product },
        { type: ref_Groups, setter: setGroup, value: campaign.Group },
    ];

    propCheck.forEach((p) => {
        React.useEffect(() => {
            if (!p.value) return p.setter("");
            Client.searchVertex(p.type.name, { "@rid": p.value, properties: ["Name"] }).then((res) =>
                p.setter(res?.data?.results?.[0]?.Name)
            );
        }, [p.value]);
    });

    return (
        <Row>
            <SubTitle anchor="#campaignpicker">{Trad("Campaign")}</SubTitle>
            <Field field="Name" value={campaign.Name} anchor="#campaignpicker" />
            <Field
                field="Start"
                value={GetCellTemplate(ePropType.Date)(campaign.Start)}
                anchor="#campaignpicker"
            />
            <Field
                field="End"
                value={GetCellTemplate(ePropType.Date)(campaign.End)}
                anchor="#campaignpicker"
            />
            <Field field="Budget" value={priceFormater(campaign.Budget)} anchor="#campaignpicker" />
            {!Boolean(campaign?.Group) ? (
                <>
                    <Field field="AdvertiserGroup" value={advertisergroup} anchor="#campaignpicker" />
                    <Field field="Advertiser" value={advertiser} anchor="#campaignpicker" />
                    <Field field="Brand" value={brand} anchor="#campaignpicker" />
                    <Field field="Product" value={product} anchor="#campaignpicker" />
                </>
            ) : (
                <>
                    <Field field="Group" value={group} anchor="#campaignpicker" />
                </>
            )}
        </Row>
    );
}

function EstimateOverview({
    campaign,
    estimateSwitch,
}: {
    campaign: ref_Campaigns;
    estimateSwitch?: JSX.Element;
}) {
    return (
        <InfosValidatior campaign={campaign}>
            <Row>
                <SubTitle anchor="#estimatepicker">
                    {Trad("estimate")}
                    {estimateSwitch}
                </SubTitle>
                {campaign.Estimates?.map((estimate, i) => {
                    return (
                        <Field
                            label={TradComposed("estimate_number", [(i + 1).toString()])}
                            value={estimate.EstimateLib}
                            anchor="#estimatepicker"
                        />
                    );
                })}
            </Row>
        </InfosValidatior>
    );
}

export function InfosValidatior({
    children,
    campaign,
    text,
}: {
    children: any;
    campaign: ref_Campaigns;
    text?: string;
}) {
    return (
        <PanelDisable disabled={!Boolean(campaign.AdvertiserGroup)} text={text}>
            {children}
        </PanelDisable>
    );
}

const priceFormater = GetCellTemplate(ePropType.Double);

function AdvertisingOverview({ campaign }: { campaign: ref_Campaigns }) {
    return (
        <InfosValidatior campaign={campaign} text={Trad("complete_campaign_infos")}>
            <Row>
                <SubTitle anchor="#advertisingpicker">{Trad("advertising_creation")}</SubTitle>
            </Row>
        </InfosValidatior>
    );
}

function PerformancesOverview({ campaign }: { campaign: ref_Campaigns }) {
    return (
        <InfosValidatior campaign={campaign}>
            <Row>
                <SubTitle anchor="#performancespicker">{Trad("performances")}</SubTitle>
            </Row>
        </InfosValidatior>
    );
}

type FieldProps = {
    field?: keyof ref_Campaigns;
    value: string;
    anchor?: string;
    label?: string;
};

function Field({ field, value, anchor, label }: FieldProps) {
    return <Element label={label ?? TradProp(field, ref_Campaigns)} value={value} anchor={anchor} />;
}

type ElementProps = {
    label: string;
    value: any;
    anchor?: string;
};

function Element({ label, value, anchor }: ElementProps) {
    return (
        <div className="overview-container">
            <AnchorRow anchor={anchor}>
                <span className={`overview-field`}>{label + ": "}</span>
                {value}
            </AnchorRow>
        </div>
    );
}
