import { Referentials, ReferentialsId } from './Referentials.bin';
import { V, VId } from './V.bin';
import { rid } from './CommonTypes.bin';

export type ref_ProductsId = rid;

export class ref_Products extends V implements Referentials {
    '@rid'?: ref_ProductsId;
    End: Date;
    Name: string;
    Start: Date;
    Active: boolean;
    parent?: any;
    import_upsertDate?: Date;
}
