import { ref_Currencies, ref_CurrenciesId } from "./ref_Currencies.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_CountriesId = rid;

export class ref_Countries extends V implements Referentials {
	"@rid"?: ref_CountriesId;
	Currency: ref_CurrenciesId;
	Name: string;
	Active: boolean;

}