import React from 'react'
import { Icon } from '@material-ui/core'

type iconOptions = {
    [key: string]: any
}

const iconList: iconOptions = {
    username: "drafts",
    lock: "lock_outline",
    send: "send",
    schedule_send: "schedule_send",
    delete: "delete",
    settings: "settings",
    clear: "clear",
    configure: "build",
    search: "search",
    info: "info",
    close: "close",
    plus: "add",
    wallet: "account_balance_wallet",
    purchases: "library_add",
    campaigns: "dvr",
    messages: "all_inbox",
    estimates: "description",
    purchases_settings: "settings_applications",
    currencies: "monetization_on",
    folder: "folder_shared",
    assessment: "assessment",
    superusers: "people",
    logs: "access_time",
    home: "dashboard",
    charts: "pie_chart",
    more: "expand_more",
    less: "expand_less",
    plus_rounded: "add_circle",
    supersettings: "admin_panel_settings",
    users: "supervised_user_circle_icon",
    edit: "edit_outlined",
    edit_inline: "edit",
    copy: "file_copy_outlined",
    copy_inline: "file_copy",
    save: "save_outlined",
    delete_rounded: "delete_outlined_outlined",
    plus_custom: "add_circle_outlined_outlined",
    plus_custom_base: "add_circle",
    up: "arrow_upward",
    down: "arrow_downward",
    more_menu: "more_vert_icon",
    power: "power_settings_new_icon",
    person: "person",
    customer: "contact_page",
    refresh: "autorenew",
    check: "check",
    group: "group",
    translate: "translate",
    storage: "storage",
    link: "link",
    source: "source",
    event: "event",
    location: "location_on",
    trending: "trending_down",
    compare: "compare_arrows",
    euro: "euro",
    done: "done",
    agreements: "connect_without_contact",
    reload: "refresh",
    go: "navigate_next",
    download: "get_app",
    listView: "list",
    tableView: "border_all",
    schedulerView: "event",
    intervals: "trending_down",
    zoom: "zoom_in",
    warning: "warning",
    tv: "tv",
    error: "error_outline",
    chat: "chat_bubble",
    chat_alert: "feedback",
    arrow_down: "arrow_downward",
    star: "star",
    back: "arrow_back",
    next: "arrow_forward",
    valid: "check_circle",
    error_filled: "error",
    palette: "palette",
    reset_style: "format_color_reset",
    bold: "format_bold",
    italic: "format_italic",
    underlined: "format_underlined",
    reset_text_color: "rotate_left",
    diffusion: "trending_up",
    view: "visibility",
    view_off: "visibility_off",
    swap: "swap_calls_icon",
    cellTower: "cell_tower",
    closeCircleOutline: "close-circle-outline",
    filterList: "filter_list",
    filterAlt: "filter_alt",
    undo: "undo",
    negociations: "forum",
    filterAltOff: "filter_alt_off",
    dateRange: "date_range",
    expandLess: "expand_less",
    accountCircle: "account_circle",
    category: "category",
    contactSupport : "contact_support",
    schedule: "schedule",
    bookmarks: "collections_bookmark",
    attachFile: "attach_file",
    api: "api",
    podcasts: "podcasts",
    task: "task_alt",
    terminal: "smart_screen",
    history: "history",
    debug: "bug_report",
    logout: "logout",
    sell: "sell",
    pdf: "file_present",
    arrow_drop_up: "arrow_drop_up",
    arrow_drop_down: "arrow_drop_down",
    find_in_page: "find_in_page",
    brush: "brush",
}

export const getIcon = (iconName: string, style?: React.CSSProperties, className?: string, id?: string) =>
    <Icon style={style ?? {}} className={className ?? ""}>
        {iconList[iconName] ?? iconName}
    </Icon>
