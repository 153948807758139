import { ref_AdvertisingCompaniesId } from "./orientdb/ref_AdvertisingCompanies.bin";
import { ref_BrandsId } from "./orientdb/ref_Brands.bin";
import { ref_BroadcastAreasId } from "./orientdb/ref_BroadcastAreas.bin";
import { ref_AdvertisersId } from "./orientdb/ref_Advertisers.bin";
import { ref_ProductsId } from "./orientdb/ref_Products.bin";
import { ref_SupportsId } from "./orientdb/ref_Supports.bin";
import { ref_CampaignsId } from "./ref_Campaigns.bin";
import { ref_PropertyId } from "./orientdb/ref_Property.bin";
import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_AdvertiserGroupsId } from "./orientdb/ref_AdvertiserGroups.bin";
import { ref_CurrenciesId } from "./orientdb/ref_Currencies.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { V } from "./orientdb/V.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { ref_Discount } from "./types/vertex.bin";
import { ImportElementStatus, ref_Imports } from "./ref_Imports.bin";
import { User } from "./orientdb/User.bin";
import { ref_Groups } from "./ref_Groups.bin";
import { ref_AgreementsId } from "./ref_GlobalAgreements.bin";
import { ref_Agreements } from "./ref_Agreements.bin";
import { ref_KPIsId } from "./orientdb/ref_KPIs.bin";
import { ref_DepartmentsId } from "./orientdb/ref_Departments.bin";

export type ref_MessagesId = rid;

export class kpis {

	Ratio?: number = 0;
	RatioDiviser?: number = 0;

	Gross?: number = 0;
	GrossVal?: number = 0;
	GrossBa?: number = 0;
	BroadcastGross?: number = 0;

	NetCO?: number = 0;
	NetFO?: number = 0;
	NetFOS?: number = 0;
	NetNego?: number = 0;

	TotalCO?: number = 0;
	TotalFO?: number = 0;
	TotalFOS?: number = 0;

	PriceDefaultFormat?: number;
	RateGrossDefaultFormat?: number;
	RateGrossValoDefaultFormat?: number;

	Barter?: number;
}
export enum eStatusType {
	None = "None",
	Simulated = "Simulated",
	Opted = "Opted",
	Confirmed = "Confirmed",
	Cancelled = "Cancelled"
}

export enum eStateType {
	None = "None",
	Bound = "Bound",
	Confirmed = "Confirmed",
	Billed = "Billed",
	Cancelled = "Cancelled"
}

export class NegotiatedKPIs {
	Net: number;
	Rate: number;
}

export class FactInfo {
	Diff?: boolean;
	LastUpdate: Date;
}
export class Deversement {
	Id?: string;
	FromMAP?: boolean;
	Estimate: string;
	InfoFO: FactInfo = new FactInfo();
	InfoCO: FactInfo = new FactInfo();
	Fees?: { [key: string]: Deversement };
}
export class ref_Messages extends V implements Referentials {
	"@rid"?: ref_MessagesId;
	AdvCompany_Com: rid;
	AdditionalPlacement: string;
	Brand: ref_BrandsId;
	BroadcastArea: ref_BroadcastAreasId;
	Advertiser: ref_AdvertisersId;
	Product: ref_ProductsId;
	TechDeliveryTimeLimit: Date;
	Support: ref_SupportsId;
	Campaign: ref_CampaignsId;
	Format: ref_PropertyId;
	Media: ref_MediaId;
	Status: eStatusType = eStatusType.None;
	State: eStateType = eStateType.None;
	ConfirmationTimeLimit: Date;
	ConfirmationComments: string;
	TechDeliveryComments: string;
	CancellationTimeLimit: Date;
	AdditionalFormat: string;
	AdvertiserGroup: ref_AdvertiserGroupsId;
	Group?: ref_Groups["@rid"];
	Currency: ref_CurrenciesId;
	ReturnedCurrency: ref_CurrenciesId;
	ReturnedCurrencyRate: number;
	AdvCompany_Fin: rid;
	CancellationComments: string;
	Placement: ref_PropertyId;
	Start: Date;
	End: Date;
	Active: boolean;
	KPIs: kpis = new kpis();
	ModelProperties: { [key: string]: any } = {};
	Discounts: ref_Discount[];
	BarterPercents: ref_Discount[];
	SmartBarter?: boolean;
	ApplyComBarter?: boolean;
	ParentId?: any;
	Created_by: User["@rid"];
	Updated_by: User["@rid"];
	Created_at: string;
	Updated_at: string;
	Version: number;
	Source: "ADWONE" | "MAP";
	GlobalAgreement?: ref_AgreementsId;
	GlobalAgreementLastUpdate?: Date;
	Agreement?: ref_AgreementsId;
	AgreementLastUpdate?: Date;
	DiscountMode?: "cascade" | "cumul" | "mixed" = "cascade";
	Deversement?: Deversement = new Deversement();
	PaidPlacement: boolean;
	IsGrossCPM: boolean;
	KpiCPM: ref_KPIsId;
	Department?: ref_DepartmentsId;
	Import?: {
		Data: { [key: string]: any };
		Id: ref_Imports["@rid"];
		Status: ImportElementStatus;
	}

	//DiffFO?: boolean; // old estimate
	//DiffCO?: boolean; // old estimate
	//EstimateId?: string; // old estimate
	//EstimateNumber?: string; // old estimate
	//BuySystem?: string; // old estimate
	//BuySystemFT?: string; // old estimate
	//LastUpdateCO?: Date; // old estimate
	//LastUpdateFO?: Date; // old estimate

	Invalide?: boolean;
	// MediaFamily?: ref_Groups["@rid"];
	// MediaFamilyOther?: string;
	// PlacementCategory?: ref_Groups["@rid"];
	/*CommunicationObjective?: string;
	PurchaseOrder?: string;
	ExternalID?: string;
	TechnicalContact?: string;
	TechnicalSpecifications?: string;
	OIDate?: Date;*/


	/**
	 * @description called in volumeUpdate / used in MessageManager to prevent further update
	 */
	upsertedAgreement?: ref_Agreements;

	attachments?: string[];
}

export class ref_MessagesNegotiated extends ref_Messages {
	KPIs: kpis & { NetNego: number }
}