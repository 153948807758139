import { removeDiacritics } from "hub-lib/tools.bin";

let intervalTrads = undefined;

const tradNotFound = new Set();

export type TradMultiLang = {
    "fr-FR": string;
    "en-US": string;
    "en-GB": string;
}

export interface ITradType {
    "@rid"?: string; // ID de la DB
    Type: "dynamic" | "static";
    Class?: string;
    Property?: string;
    Value?: string;
    Code?: string;
    Translations: TradMultiLang;
}

export type Locale = "fr-FR" | "en-US" | "en-GB" | "engine";

/**
 * Configure les accès à la locale
 * @param _getter
 * @param _setter
 */
export let ConfigureInOut = (_getter: () => Locale, _setter: (locale: Locale) => any) => {
    setter = _setter;
    getter = _getter;
}

let setter: (locale: Locale) => any = undefined;
let getter: () => Locale = undefined;

/**
 * Accès à la locale courante
 */
export let GetCurrentLocale = () => {
    let locale = getter?.();

    if (!locale)
        console.log(`!!! No local found !!!`)

    const locales: Locale[] = ["fr-FR", "en-GB", "en-US", "engine"];
    if (!locales.includes(locale))
        locale = "fr-FR";

    return locale;
}

/**
 * Pour changer la locale courante
 * @param locale
 */
export let SetCurrentLocale = (locale: Locale) => {
    return setter?.(locale);
}

/**
 * A utiliser dans adwone uniquement, fonction de transition de systeme
 * @param name
 */
export let TradClassName = (name: string): string => {
    return Trad(name);
}

/**
 * Traduit un code statique
 * @param key
 */
export let Trad = (key: string, capitalize: boolean = false): string => {

    if (GetCurrentLocale() == 'engine')
        return key;

    if (!key || typeof key != "string")
        return 'undefined';

    const toCapitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";

    // Hack adwone, transition old key system
    if (key.startsWith("property_")) {
        let k = key.replace("property_", "");
        let trad = TradProvider.GetInstance().Trad({ Type: "static", Property: k });
        if (!trad.startsWith("static/"))
            return capitalize ? toCapitalize(trad) : trad;
    }

    // Hack adwone, transition old key system
    if (key.startsWith("ref_") || key.startsWith("src_")) {
        let trad = TradProvider.GetInstance().Trad({ Type: "static", Class: key });
        if (!trad.startsWith("static/"))
            return capitalize ? toCapitalize(trad) : trad;
    }

    const trad = TradProvider.GetInstance().Trad({ Type: "static", Code: key })
    return capitalize ? toCapitalize(trad) : trad;
}

export function TradComposed(key: string, values: (string | number)[], capitalize: boolean = false) {
    let trad = Trad(key, capitalize);
    for (let index = 0; index < values.length; index++) {
        const element = values[index];
        trad = trad.replace("$" + index, element?.toString());
    }
    return trad;
}

export function FormatPropertyName(propertyName : string, shift : boolean = false) {

    if (GetCurrentLocale() != 'engine')
        return propertyName;

    let props = propertyName.split('.');

    if (shift)
        props.shift();
    return removeDiacritics(props.join("_").trim().split(" ").join("_"));
}

/**
 * Traduit le nom d'une propriété
 * @param prop
 * @param classType
 */
export function TradProp<T>(prop: (keyof T & string) | string, classType?: new () => T) {

    if (GetCurrentLocale() == 'engine')
        return FormatPropertyName(prop);

    if (prop === "*") return "*";
    return TradProvider.GetInstance().Trad({ Type: "static", Class: classType?.name, Property: prop });
}

export function TradPropVName<T>(prop: (keyof T & string) | string, classType: string) {

    if (GetCurrentLocale() == 'engine')
        return FormatPropertyName(prop);

    if (prop === "*") return "*";
    return TradProvider.GetInstance().Trad({ Type: "static", Class: classType, Property: prop });
}

/**
 * Traduit une valeur, dynamic
 * @param classType
 * @param prop
 * @param value
 */
export function TradValue<T>(classType: (new () => T) | string, prop: keyof T & string, value: string) {

    if (GetCurrentLocale() == 'engine')
        return value;

    let Class = typeof classType === "string" ? classType : classType?.name;
    return TradProvider.GetInstance().Trad({ Type: "dynamic", Class, Property: prop, Value: value }) ?? value;
}

export let GetTimeFormat = () => {
    switch (GetCurrentLocale()) {
        case "fr-FR":
            return "HH:mm"
        case "en-US":
            return "hh:mm a"
        case "en-GB":
            return "HH:mm"
        default:
            return "HH:mm"
    }
}

export let GetMonthFormat = () => {
    switch (GetCurrentLocale()) {
        case "fr-FR":
            return "L"
        case "en-US":
            return "l"
        case "en-GB":
            return "L"
        default:
            return "L"
    }
}

export class TradProvider {

    static IsActivated = () => true;

    /** timeout in ms, after that timeout trads are refreshed */
    static Timeout: number;

    static TradGetter: () => Promise<ITradType[]>;

    private dicoTrads: { [prop: string]: ITradType } = {};
    private allTrads: ITradType[];
    private static _provider: TradProvider;

    GetCount() {
        return this?.allTrads?.length;
    }

    static GetInstance(): TradProvider {
        if (!TradProvider._provider)
            TradProvider._provider = new TradProvider();
        return TradProvider._provider;
    }

    static GetCode(trad: Partial<ITradType>) {
        if (!trad)
            return null;

        if (!trad.Class && !trad.Property && !trad.Value)
            return trad.Code;

        trad.Code = `${trad.Type}/${trad.Class}/${trad.Property}`;
        if (trad.Type === "dynamic")
            trad.Code += `/${trad.Value}`;

        return trad.Code;
    }

    TradCode(code: string, nullIfNotFount: boolean = false) {

        if (!code) {
            console.error(`code undefined`);
            return "undefined";
        }

        const locale = GetCurrentLocale();

        if (!locale) {
            console.error(`mapping locale not found for locale: ${locale}`);
            return code;
        }

        let trad = this.dicoTrads[code]?.Translations?.[locale];
        if (!trad && !nullIfNotFount) {
            trad = code;
        }

        return trad;
    }

    Trad(trad: Partial<ITradType>) {

        let code = TradProvider.GetCode(trad);
        let value: string = this.TradCode(code, true);

        if (!trad) {
            console.error(`trad undefined`);
            return "undefined";
        }

        switch (trad.Type) {
            case "dynamic":
                if (!value) value = trad.Value;
                break;
            case "static":
                if (value) break;
                if (trad.Property) {
                    let codeProp = TradProvider.GetCode({ Type: "static", Property: trad.Property });
                    let tradProp = this.TradCode(codeProp, true);
                    if (tradProp) {
                        value = tradProp;
                        break;
                    }
                }
                break;

            default:
                console.error(`trad with no type`);
                console.error(trad);
                return "*";
        }

        if (!value && trad.Type == 'static' && !code?.startsWith('['))
            tradNotFound.add(code);

        if (!TradProvider.IsActivated()) {
            if (!value)
                console.log(Array.from(tradNotFound));
            return `[${value == undefined ? '?' : ''}${code}]`;
        }

        if (!value && trad.Type == 'dynamic')
            return '';

        return value ?? code;
    }

    async Init() {
        try {
            if (this.allTrads)
                return;

            const getTrads = async () => {
                this.allTrads = await TradProvider.TradGetter() ?? [];
                this.allTrads?.forEach(t => {
                    if (!t?.Code)
                        return;

                    this.dicoTrads[t.Code] = t;
                });
                this.lastTradsUpdate = new Date();
            }

            if (TradProvider.Timeout) {
                clearInterval(intervalTrads);
                intervalTrads = setInterval(getTrads, TradProvider.Timeout);
            }

            await getTrads();

            console.log(`Translation init OK`);
        } catch (error) {
            console.error(`Cannot Init trads`, error);
            this.errorInit = error?.message;
        }
    }

    errorInit: any = undefined;
    lastTradsUpdate: Date = undefined;
}

