import { GenericPopover } from "../../ConfigurableComponents/GenericPopover.bin";
import {
    ExternalDropZone,
    Upload,
    UploadFileInfo,
    UploadFileStatus,
} from "@progress/kendo-react-upload";
import React from "react";
import { Client, URLServerProvider } from "hub-lib/client/client.bin";
import { fileRoute } from "hub-lib/index.bin";
import { VertexAutocomplete } from "adwone-lib";
import { ref_Attachments, ref_Files } from "hub-lib/models/ref_Attachments.bin";
import { Trad } from "trad-lib";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import ReactDOM from "react-dom";
import { IconButton } from "@material-ui/core";

let timeout: NodeJS.Timeout;

type UploadFileInfoCustom = {
    attachment?: ref_Attachments;
    "@rid"?: string;
} & UploadFileInfo;

type TProps = {
    title?: string;
    attachmentsIds: ref_Files["@rid"][];
    type?: string;
    onChange: (attachments: string, deleted?: boolean) => void;
    popover?: boolean;
    autoComplete?: boolean;
    rid: string;
    extensions?: string[];
};
const onClick = (e) => {
    console.log("onClick", e);
    e.stopPropagation();
}

export function FilesPicker({ title, attachmentsIds, type, onChange, popover, autoComplete, rid, extensions }: TProps) {
    const [files, setFiles] = React.useState<UploadFileInfoCustom[]>(null);
    const [toAdd, setToAdd] = React.useState([]);

    const [divRef, setDivRef] = React.useState<HTMLDivElement>();

    const getAttachments = async () => {
        const attachments = (
            await Client.searchVertex(ref_Attachments.name, { Active: true, "@rid": attachmentsIds, Type: type })
        ).data.results as ref_Files[];
        setFiles(attachments.map(transform));
    };
    const transform = (a: ref_Files): UploadFileInfoCustom => ({
        name: a.Name,
        progress: 100,
        size: a.Size,
        extension: `.${/(?:\.([^.]+))?$/.exec(a.Name)[1]}`,
        status: UploadFileStatus.Initial,
        uid: a.Content,
        attachment: a,
        "@rid": a.Content,
    });

    React.useEffect(() => {
        if (!files && attachmentsIds && attachmentsIds.length) getAttachments();
    });

    React.useEffect(() => {
        if (!divRef) return;

        const uploadButton = divRef.getElementsByClassName("k-upload-button")[0];
        const dropZone = uploadButton.parentElement;
        const hint = dropZone.getElementsByClassName("k-dropzone-hint")[0];
        if (hint && files?.length > 0)
            hint["style"]["display"] = "none";

        const allWithClass = Array.from(
            (divRef?.getElementsByClassName('k-file-success') || [])
        );
        for (const el of allWithClass) {
            if (!el.getElementsByClassName("adw-download-button").length) {
                const uid = el.getAttribute("data-uid");
                const button = el.getElementsByClassName('k-upload-status')[0];
                const span = document.createElement('span');

                ReactDOM.render(
                    <button className="adw-download-button k-button k-button-icon k-flat k-upload-action" onClick={async (e) => await dowloadFile(uid)}>
                        <span className="k-icon k-i-download"></span>
                    </button>
                    , span)

                button.prepend(span);
            }
        }
    }, [files]);

    const renderPicker = () => {
        return (
            <div className="adw-upload" style={{ width: "100%", padding: 0 }} ref={ref => {
                if (!ref)
                    return;

                const uploadButton = ref.getElementsByClassName("k-upload-button")[0];

                uploadButton.removeEventListener("click", onClick)
                uploadButton.addEventListener("click", onClick)

                if (uploadButton?.children.length >= 2) {
                    uploadButton.removeChild(uploadButton?.children[1]);
                    const span = document.createElement('span');
                    span.className = "material-icons MuiIcon-root";
                    span.innerText = "attach_file";
                    uploadButton.appendChild(span);
                }

                setDivRef(ref)
            }}>
                {title ? <div className="adw-title">{title}</div> : null}
                {autoComplete && <div style={{ display: "flex", padding: 8, paddingBottom: 4 }}>
                    <div style={{ marginRight: 12, width: "-webkit-fill-available" }}>
                        <VertexAutocomplete
                            key={`key-${files ? files.map(e => e["@rid"]).join() : ""}`}
                            label={Trad("add_existing_files")}
                            type={ref_Attachments.name}
                            params={{ Active: true, AttachementType: "file", properties: ["*"] }}
                            onChange={(file: ref_Files) => {
                                setToAdd([file]);
                            }}
                            afterLoadFilter={(els: ref_Files[]) => {
                                if (!files)
                                    return els;
                                const set = new Set(files.filter(e => e.attachment).map(e => e?.attachment["@rid"]));
                                return els.filter((el) => !set.has(el["@rid"]));
                            }}
                        />
                    </div>
                    <div style={{ minWidth: "fit-content" }}>
                        <CustomButton
                            startIcon={getIcon("plus")}
                            Label={Trad(`add`)}
                            className="custom_btn_primary"
                            onClick={() => {
                                // todo
                                const filesToAdd = toAdd.map(transform) as any;
                                for (const a of toAdd) {
                                    onChange(a["@rid"]);
                                }
                                setFiles(filesToAdd);
                            }}
                        />
                    </div>
                </div>}
                <div>
                    <Upload
                        batch={false}
                        onAdd={(event) => {
                            setFiles(event.newState);
                        }}
                        onRemove={async (event) => {
                            const [file] = event.affectedFiles;
                            onChange(file["@rid"], true);
                            setFiles(event.newState);
                        }}
                        onProgress={(event) => {
                            setFiles(event.newState);
                        }}
                        onStatusChange={async (event) => {
                            const [file] = event.affectedFiles;
                            const el = event.newState.find((e) => e.uid === file.uid);
                            if (el && event.response) {
                                const resp = event.response.response;
                                el["@rid"] = resp.id;
                                el["attachment"] = resp.attachment;
                                onChange(resp.attachment["@rid"]);
                            }
                            setFiles(event.newState);
                        }}
                        onBeforeUpload={(event) => {
                            event.additionalData = { type };
                        }}
                        onBeforeRemove={(event) => {
                            const [file] = event.files;
                            event.additionalData = { _id: file["@rid"], attachment: file["attachment"]["@rid"], targetRid: rid };
                        }}
                        restrictions={{
                            maxFileSize: 10000000, // 10MO
                            allowedExtensions: extensions,
                        }}
                        saveField={"attachment"}
                        withCredentials
                        saveUrl={URLServerProvider.provide(fileRoute) + fileRoute + "/attachment"}
                        removeUrl={URLServerProvider.provide(fileRoute) + fileRoute + "/attachment"}
                        removeMethod="Delete"
                        multiple={false}
                        files={files}
                    />
                </div>
            </div>
        );
    };

    const dowloadFile = async (id: string) => {
        const file = files.find(e => e["@rid"] === id);
        await Client.dowloadAttachmentFile({ _id: id, filename: file.name });
    }

    return (
        <>
            {popover ? (
                <GenericPopover count={files ? files.length : 0} icon="attachFile" style={{ width: 600 }}>
                    {renderPicker()}
                </GenericPopover>
            ) : (
                renderPicker()
            )}
        </>
    );
}
