
import { AdwRow } from "adwone-lib";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ImportsSourceType, ref_Imports } from "hub-lib/models/ref_Imports.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../../redux/store";

type DynamicReportCellProps = { cellValue: any, dataItem?: AdwRow<ref_Imports> }
export function DynamicReportCell({ cellValue, dataItem }: DynamicReportCellProps) {
    const items = useSelector((root: RootState) => root.imports.list);
    const row: ref_Imports = items.find(e => e["@rid"] === dataItem?.dataItem?.["@rid"]) ?? dataItem.dataItem;

    let str = "";
    switch (row?.SourceType) {
        case ImportsSourceType.MESSAGEMAP:
            str = `${Trad(ref_Campaigns.name)}: ${row.Report.ref_Campaigns} ${row.Report.virtual_Campaigns ? "(+" + row.Report.virtual_Campaigns + ")" : ""} / ${Trad(ref_Messages.name)}: ${row.Report.ref_Messages}`;
            break;
        case ImportsSourceType.ADWONE:
            str = `${Trad("referentials")}: ${Object.keys(row.Report).filter(e => !["Status", "Total", "Current"].includes(e)).reduce((p, c) => p + row.Report[c], 0)}`;
            break;
        case ImportsSourceType.AGREEMENTMAP:
            str = `${Trad(ref_Agreements.name)}: ${Object.keys(row.Report).filter(e => !["Status", "Total", "Current"].includes(e)).reduce((p, c) => p + row.Report[c], 0)}`;
            break;
        case ImportsSourceType.MEDIAVISION:
        case ImportsSourceType.TSM_CONTACTS:
            str = `${Trad("Created")}: ${row.Report["Created"]} / ${Trad("Updated")}: ${row.Report["Updated"]}`;
            break;
        default:
            str = "";
            break;
    }
    dataItem["Filters_cellValue"] = str;
    return <span>{str}</span>
}