import * as React from 'react'
import { Grid, TextField } from '@material-ui/core';
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Countries } from 'hub-lib/models/orientdb/ref_Countries.bin';
import { Trad } from 'trad-lib';
import { VertexAutocomplete } from "adwone-lib/index";
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';

export function AdressForm(props: { element: ref_Companies}) {

    const [refresh, setRefresh] = React.useState<any>();

    const update = () => {
        setRefresh(Date.now());
    }

    return (
        <>
            <Grid item xs={6} className="message_details_leftcombo">
                <TextField
                    variant="outlined"
                    label={`${Trad("adress")} *`}
                    value={props.element.Adress}
                    onChange={(e) => { props.element.Adress = e.target.value; update();}}
                    style={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <TextField
                    variant="outlined"
                    label={`${Trad("property_City")} *`}
                    value={props.element.City}
                    onChange={(e) => { props.element.City = e.target.value; update();}}
                    style={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={6} className="message_details_leftcombo">
                <TextField
                    variant="outlined"
                    label={`${Trad("zipcode")} *`}
                    value={props.element.ZipCode}
                    onChange={(e) => { props.element.ZipCode = e.target.value; update();}}
                    style={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <VertexAutocomplete
                    label={`${Trad("property_Country")} *`}
                    disableClearable
                    defaultValue={(options: any[]) => options?.find((v) => v["@rid"] === props.element.Country)}
                    onChange={(value: IRid) => props.element.Country = value["@rid"]}
                    type={ref_Countries.name}
                />
            </Grid>
        </>
    )
}