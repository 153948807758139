import { VertexAutocompleteDecorator } from "adwone-lib/index"

import * as React from 'react'
import './App.scss'
import withAuth from './components/auth/withAuth.bin'
import { Provider, useSelector } from 'react-redux'
import { Route, Switch, withRouter } from "react-router-dom"

import Login from "./components/auth/Login.bin"
import Home from './components/layout/Home/Home.bin'
import Campagnes from './components/layout/Puchases/Campagnes.bin'
import SuperUsers from './components/layout/SuperAdmin/SuperUsers.bin'
import { MultiDocument, SingleDocument } from './components/layout/GenericComponent'
import NotFound from './components/layout/NotFound.bin'
import Currencies from './components/layout/PurchaseSettings/Currencies.bin'
import Messages from './components/layout/Puchases/Messages.bin'
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl'
import EstimatesDetails from './components/layout/Puchases/EstimatesDetails.bin'
import Logs from './components/layout/SuperAdmin/Logs.bin'
import Users from './components/layout/Admin/Users.bin'
import Groups from './components/layout/Admin/Groups.bin'
import Configurations from './components/layout/Admin/GlobalAgreements'
import Profile from './components/layout/Admin/Profile.bin'
import Unauthorized from './components/layout/Unauthorized.bin'
import Customers from './components/layout/SuperAdmin/Customers.bin'
import NewCustomerSetting from './components/layout/SuperAdmin/NewCustomerSetting.bin'
import EditCustomerSetting from './components/layout/SuperAdmin/EditCustomerSetting.bin'
import Translate from './components/layout/SuperAdmin/Translate.bin'
import Referential from './components/layout/SuperAdmin/Referential.bin'
import DataLink from './components/layout/SuperAdmin/DataLink.bin'
import { GetCurrentLocale } from 'trad-lib'
import Loader from './components/layout/Loader'
import RightsOfUsers from './components/layout/Admin/RightsOfUsers.bin'
import RightsOfCustomers from './components/layout/SuperAdmin/RightsOfCustomers.bin'
import MedialandImport from './components/MedialandImport/MedialandImport'

import { Reset } from './components/auth/Reset.bin'
import Agreements from './components/layout/PurchaseSettings/Agreements.bin'
import { URLLogger } from 'log-url'
import { Client } from 'hub-lib/client/client.bin'
import FilesManager from './components/FilesManager/FilesManager.bin'
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin'
import Imports from './components/layout/SuperAdmin/Imports'
import { RootState, store } from './redux/store'
import { CustomTooltip } from './components/CustomTooltip'
import Crons from './components/layout/SuperAdmin/Crons'
import Template from './components/layout/Template'
import { BroadcastMessage } from './components/layout/SuperAdmin/BroadcastMessage'
import { SupportExtended } from "hub-lib/models/SupportExtended.bin"
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin"
import History from "./components/layout/SuperAdmin/History"
import { OffersGrid } from "./components/layout/Admin/OffersGrid"
import { PublicationsGrid } from "./components/layout/Admin/PublicationsGrid"
import { TSMImportsGrid } from "./components/layout/Admin/TSMImportsGrid"
import { initialize } from "./configuration.bin"
import { TSMErrorsGrid } from "./components/layout/Admin/TSMErrorsGrid"
import { UserSessionsGrid } from "./components/layout/Admin/UserSessionsGrid"
import Console from "./components/layout/SuperAdmin/Console"
import ReleaseVersion from "./components/layout/Puchases/ReleaseVersion.bin"
import MissingProperties from "./components/layout/SuperAdmin/MissingProperties"
import UnlinkMessage from "./components/layout/SuperAdmin/UnlinkMessage"
import NewEstimates from "./components/layout/Puchases/Estimates.bin"
import { TokenComponent } from "./components/auth/TokenComponent"
import { GenericModal } from "./components/GenericModal"
import { ExternalClientsGrid } from "./components/layout/SuperAdmin/ExternalClientsGrid"
import { GenericPopper } from "./components/ConfigurableComponents/GenericPopper.bin"
import { InvalidEstimatesGrid } from "./components/layout/SuperAdmin/InvalidEstimatesGrid"
import { MessagesPivotGrid } from "./components/VertexGrid/Messages/PivotGrid/MessagesPivotGrid"
import Couplages from "./components/layout/Suppliers/Couplages"
import Spots from "./components/layout/Suppliers/Spots"
import { eRoles } from "hub-lib/business/rights/rights.bin"

//import { LoadFakeStorage } from "./LoadLocalStorage"

//LoadFakeStorage();

let frMessages = require('./components/ConfigurableComponents/fr.json');
loadMessages(frMessages, 'fr-FR');
loadMessages(frMessages, 'fr');

/** Legacy */
localStorage.removeItem('configMessagesGrid')
localStorage.removeItem('configCampaignsGrid')
localStorage.removeItem('common-filter-storage')
localStorage.removeItem('dictionary-tabmanager')

VertexAutocompleteDecorator.RegisterDecorator(o => {
  const sup = o as SupportExtended;
  if ((sup?.["@class"] === SupportExtended.name || sup?.["@class"] === ref_Supports.name) && sup.coverUrl) {
    return <img
      className="cover-vertexauto"
      src={sup.coverUrl} />;
  }
  return undefined;

})

URLLogger.SendToServer = (storeUsage) => {
  return Promise.all(Object.entries(storeUsage.usages).map(e => {
    return e[1].elapsed > 0 ? Client.log({ Category: 'usage', Action: e[0], Elapsed: e[1].elapsed }) : Promise.resolve();
  }));
}
URLLogger.Start();


class TState {
  loaded: boolean;
}

class App extends React.Component<any, TState>{
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  async componentDidMount() {
    await initialize();
    this.setState({ loaded: true });
  }

  render() {

    if (!this.state.loaded)
      return <Loader></Loader>;

    return (
      <LocalizationProvider language={GetCurrentLocale()}>
        <IntlProvider locale={GetCurrentLocale()}>
          <Provider store={store}>
            <div className="App">
              {/* <ConfigurationRouter /> */}
              <CustomTooltip>
                <Switch>
                  <Route exact path="/medialand" component={withAuth(MedialandImport)} />
                  <Route exact path="/reset" component={Reset} />
                  <Route exact path="/broadcastmessage" component={withAuth(Template(BroadcastMessage), () => RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/authfailed" component={Unauthorized} />
                  <Route exact path="/" component={withAuth(Home)} />
                  <Route exact path="/campaigns" component={withAuth(Campagnes, () => RightManager.hasRight(eFunctions.ref_Campaigns, eRights.read))} />
                  <Route exact path="/messages" component={withAuth(Messages, () => RightManager.hasRight(eFunctions.ref_Messages, eRights.read))} />
                  <Route exact path="/versions" component={withAuth(ReleaseVersion, () => RightManager.hasRight(eFunctions.ref_ReleaseVersion, eRights.create))} />
                  <Route exact path="/supplier_campaigns" component={withAuth(Couplages, () => RightManager.hasRight(eFunctions.ref_Campaigns, eRights.read))} />
                  <Route exact path="/supplier_spots" component={withAuth(Spots, () => RightManager.hasRight(eFunctions.ref_Campaigns, eRights.read))} />
                  <Route exact path="/messages_by_campaign" component={withAuth(Messages, () => RightManager.hasRight(eFunctions.ref_Messages, eRights.read))} />
                  <Route exact path="/messages_by_import" component={withAuth(Messages, () => RightManager.hasRight(eFunctions.cache, eRights.read))} />
                  <Route exact path="/reporting" component={withAuth(Template(MessagesPivotGrid), () => RightManager.hasRight(eFunctions.ref_Messages, eRights.read))} />
                  <Route exact path="/reports" component={withAuth(MultiDocument, () => RightManager.hasRight(eFunctions.ref_Messages, eRights.read))} />
                  <Route exact path="/estimates" component={withAuth(NewEstimates, () => RightManager.hasRight(eFunctions.ref_Estimates, eRights.read))} />
                  <Route exact path="/estimates/:id" component={withAuth(EstimatesDetails, () => RightManager.hasRight(eFunctions.ref_Estimates, eRights.read))} />
                  <Route exact path="/token/:url/:api" component={withRouter(TokenComponent)} />
                  {/* <Route exact path="/sellsy" component={withAuth(SellsyAuth(Sellsy), () => RightManager.hasRight(eFunctions.Sellsy, [eRights.read]))} /> */}
                  {/* <Route exact path="/diffusion_control" component={withAuth(Diffusion, () => RightManager.hasRight(eFunctions.ref_Messages, eRights.read))} /> */}
                  {/* <Route exact path="/negotiations" component={withAuth(Negotiations, () => true)} /> */}
                  <Route exact path="/document/:id" component={withAuth(SingleDocument)} />
                  <Route exact path="/document/" component={withAuth(MultiDocument)} />
                  {/*<Route exact path="/support/:supportId" component={withAuth(Support)} />*/}
                  <Route exact path="/monitoring" component={withAuth(SuperUsers, () => (RightManager.hasRight(eFunctions.User, eRights.read) && RightManager.hasRight(eFunctions.Logs, eRights.read)))} />
                  <Route exact path="/files" component={withAuth(FilesManager, () => (RightManager.hasRight(eFunctions.ExcelTemplate, [eRights.create, eRights.read, eRights.update, eRights.delete])))} />
                  <Route exact path="/logs" component={withAuth(Logs, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/console" component={withAuth(Console, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/history" component={withAuth(History, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/missingproperties" component={withAuth(MissingProperties, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/tsmimports" component={withAuth(TSMImportsGrid, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/offers" component={withAuth(OffersGrid, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/usersessions" component={withAuth(UserSessionsGrid, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/tsmerrors" component={withAuth(TSMErrorsGrid, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/publications" component={withAuth(PublicationsGrid, () => RightManager.hasRight(eFunctions.Logs, eRights.read))} />
                  <Route exact path="/new_customer" component={withAuth(NewCustomerSetting, () => RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/edit_customer/:customerSettingId" component={withAuth(EditCustomerSetting, () => RightManager.hasRight(eFunctions.cache, eRights.read) && RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/customers" component={withAuth(Customers, () => RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/externalclients" component={withAuth(ExternalClientsGrid, () => RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/invalidestimates" component={withAuth(InvalidEstimatesGrid, () => RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/translation" component={withAuth(Translate, () => RightManager.hasRight(eFunctions.Trad, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/referential" component={withAuth(Referential, () => (RightManager.hasRight(eFunctions.ref_Supports, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    && RightManager.hasRight(eFunctions.ref_AdvertisingCompanies, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    && RightManager.hasRight(eFunctions.ref_BroadcastAreas, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    && RightManager.hasRight(eFunctions.ref_Media, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    && RightManager.hasRight(eFunctions.ref_Property, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    && RightManager.hasRight(eFunctions.ref_Advertisers, [eRights.create, eRights.read, eRights.update, eRights.delete])))} />
                  <Route exact path="/users" component={withAuth(Users, () => RightManager.hasRight(eFunctions.User, [eRights.create, eRights.read, eRights.update, eRights.delete], [eRoles.administrateur, eRoles.superAdministrateur]))} />
                  <Route exact path="/userrights" component={withAuth(RightsOfUsers, () => RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/customerrights" component={withAuth(RightsOfCustomers, () => RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete]) && RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/currency_and_exchange_rates" component={withAuth(Currencies, () => RightManager.hasRight(eFunctions.ref_Currencies, eRights.read))} />
                  <Route exact path="/agreements" component={withAuth(Agreements, () => RightManager.hasRight(eFunctions.ref_Agreements, [eRights.read, eRights.create, eRights.update, eRights.delete]))} />
                  <Route exact path="/agreements/import/:importId" component={withAuth(Agreements, () => RightManager.hasRight(eFunctions.ref_Agreements, [eRights.read, eRights.create, eRights.update, eRights.delete]))} />
                  <Route exact path="/configurations" component={withAuth(Configurations, () => RightManager.hasRight(eFunctions.ref_Configurations, [eRights.read, eRights.create, eRights.update, eRights.delete]))} />
                  <Route exact path="/profile" component={withAuth(Profile)} />
                  <Route exact path="/link" component={withAuth(DataLink, () => RightManager.hasRight(eFunctions.ReferentialHasViews, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/imports" component={withAuth(Imports, () => RightManager.hasRight(eFunctions.ref_Imports, [eRights.create, eRights.read]))} />
                  <Route exact path="/crons" component={withAuth(Crons, () => RightManager.hasRight(eFunctions.ref_Crons, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/groups" component={withAuth(Groups, () => RightManager.hasRight(eFunctions.ref_Groups, [eRights.create, eRights.read, eRights.update, eRights.delete]))} />
                  <Route exact path="/unlinkmessage" component={withAuth(UnlinkMessage, () => RightManager.hasRight(eFunctions.ref_Customers, eRights.update))} />
                  <Route path="*" component={withAuth(NotFound)} />

                </Switch>
                <Background />
              </CustomTooltip>
              <GenericModal />
              <GenericPopper />
            </div>
          </Provider>
        </IntlProvider >
      </LocalizationProvider>
    );
  }
}

export function Background() {
  const visible = useSelector((root: RootState) => root.background.visible);
  const element = useSelector((root: RootState) => root.background.element);

  if (!visible || !element)
    return <></>;

  const className = `focus-background-element ${visible ? 'm-fadeIn' : 'm-fadeOut'}`;
  return <>
    <div className={className} style={{ left: element?.left, top: 0, width: element?.width ?? 0, height: element?.top }} />
    <div className={className} style={{ left: 0, top: 0, width: element?.left ?? 0, height: "100%" }} />
    <div className={className} style={{ left: element?.left + element?.width, top: 0, width: "100%", height: "100%" }} />
    <div className={className} style={{ left: element?.left, top: element.top + element.height, width: element?.width ?? 0, height: "100%" }} />
  </>
}

export default App;
