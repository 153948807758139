import * as React from "react";
import { getIcon, VertexAutocomplete } from "adwone-lib";
import { Trad } from "trad-lib";
import { eGroupCategories, ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { GetHashCode, propertyOf } from "hub-lib/tools.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { CustomIconButton } from "../VertexGrid/Generic/CustomIconButton";
import { store } from "../../redux/store";
import { hide, show, showElement } from "../../redux/backgroundSlice";
import { TextField } from "@material-ui/core";
import { IconAction } from "../VertexGrid/ModeleCreator/ModeleCreatorBase";
import { Client } from "hub-lib/client/client.bin";
import { FilterStorage } from "../../utils/localstorage.bin";

type AdvertisersGroupsComboProps = { className?: string, selectedGroup: ref_Groups, onChange?: (selectedItem: ref_Groups) => void }
export function AdvertisersGroupsCombo({ className, onChange, selectedGroup }: AdvertisersGroupsComboProps) {

    const [groups, setGroups] = React.useState<ref_Groups[]>();
    const [selectedOption, setSelectedOption] = React.useState<ref_Groups>();
    const [editNameMode, setEditNameMode] = React.useState(false);
    const [editedName, setEditedName] = React.useState("");

    const filters = FilterStorage.getAdvancedFilters();

    const updateGroups = () => Client.searchVertexTyped(ref_Groups, { [propertyOf<ref_Groups>("Category")]: eGroupCategories.Magasin, properties: ["*"], Active: true }).then((res) => {
        if (filters?.Group) {
            const filteredGroups = res.filter((grp) => filters.Group.some((filter) => filter === grp["@rid"]))
            setGroups(filteredGroups)
            if (filteredGroups.length === 1)
                setSelectedOption(filteredGroups[0])
        } else {
            setGroups(res);
            const options = groups?.find(g => g["@rid"] == selectedGroup?.["@rid"]);
            setSelectedOption(options)
        }
    });

    React.useEffect(() => {
        updateGroups()
    }, [GetHashCode(selectedGroup)])

    return <div className={className}>
        {!editNameMode && <div>
            <div style={{
                width: "calc(100% - 40px)",
                float: "left"
            }}>
                <VertexAutocomplete
                    key={`AdvertisersGroupsCombo-${groups?.length}-${GetHashCode(selectedOption)}`}
                    loading={!groups}
                    options={groups ?? []}
                    autoCompleteProps={{
                        value: selectedOption
                    }}
                    label={Trad(`groups`)}
                    type={ref_Groups.name}
                    nullOnClear
                    onChange={async (selected: ref_Groups) => onChange?.(selected)} />
            </div>
            <CustomIconButton
                style={{ float: "right" }}
                disabled={!Boolean(selectedGroup)}
                className="custom_btn_secondary popover_button"
                onClick={async () => {
                    setEditedName(selectedGroup?.Name ?? "");
                    setEditNameMode(true);
                    store.dispatch(show());
                }}>
                {getIcon("edit")}
            </CustomIconButton>
        </div>}


        {editNameMode && <div style={{ width: '100%', display: "flex" }}
            ref={(ref) => store.dispatch(showElement(ref))}>
            <TextField
                label={Trad("table_model")}
                autoFocus
                variant="outlined"
                style={{ width: '100%' }}
                contentEditable={true}
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)} />
            <IconAction
                icon="valid"
                tooltip={Trad("rename")}
                onClick={() => {
                    const previsousName = selectedGroup.Name;
                    selectedGroup.Name = editedName;
                    Client.updateVertex(ref_Groups.name, selectedGroup)
                        .then(() => {
                            setEditNameMode(false);
                            onChange?.(selectedGroup);
                            store.dispatch(hide());
                        })
                        .catch(() => {
                            selectedGroup.Name = previsousName;
                        });
                }} />
            <IconAction icon="undo" tooltip={Trad("back")} onClick={() => { setEditNameMode(false); store.dispatch(hide()) }} />
        </div>}
    </div>
}