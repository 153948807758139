import axios, { AxiosRequestConfig } from "axios";
import { EventEmitter } from "events";

export type Resp<T> = {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    config: any;
    request?: any;
};

export type Config = AxiosRequestConfig;

export class ClientAXIOS {
    private urlBase: string | (() => string);

    private _defaultAxiosConfig: AxiosRequestConfig;
    get defaultAxiosConfig(): AxiosRequestConfig {
        return this._defaultAxiosConfig;
    }

    get headers(): AxiosRequestConfig["headers"] {
        return {
        }
    }

    get url() {
        return typeof this.urlBase === "function" ? this.urlBase() : this.urlBase;
    }

    onRequestDone: EventEmitter = new EventEmitter();

    constructor(urlBase: string | (() => string), config: AxiosRequestConfig = {}) {
        this.urlBase = urlBase;
        this._defaultAxiosConfig = config;
    }

    protected mergeConfigs(config?: AxiosRequestConfig, config2?: AxiosRequestConfig): AxiosRequestConfig {
        return {
            ...config,
            ...config2,
            headers: {
                ...(config?.headers ?? {}),
                ...(config2?.headers ?? {}),
                ...this.headers
            }
        };
    };

    protected async execPost<T = any>(
        url: string,
        body: any,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {
        const resp = await axios.post(url, body, this.mergeConfigs(this.defaultAxiosConfig, config));
        this.onRequestDone.emit("POST", url);
        return resp;
    }

    protected async execPut<T = any>(
        url: string,
        body: any,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {
        const resp = await axios.put(url, body, this.mergeConfigs(this.defaultAxiosConfig, config));
        this.onRequestDone.emit("PUT", url);
        return resp;
    }

    protected async execGet<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {
        const resp = await axios.get(url, this.mergeConfigs(this.defaultAxiosConfig, config));
        this.onRequestDone.emit("GET", url);
        return resp;
    }

    protected async execDelete<T = any>(
        url: string,
        config?: AxiosRequestConfig,
        data = undefined
    ): Promise<Resp<T>> {
        const resp = await axios.delete(url, { ...this.mergeConfigs(this.defaultAxiosConfig, config), data });
        this.onRequestDone.emit("DELETE", url);
        return resp;
    }
}
