
import * as React from "react";

import { VertexGrid } from "../Generic/VertexGrid.bin";
import { eStateType, ref_Messages } from "hub-lib/models/ref_Messages.bin"
import { AdwRow, ADWColumn } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { GetKPITemplate, HTMLToString } from "format-lib/index.bin";
import { Trad, TradProp, TradValue } from "trad-lib";
import { GetSubElement, propertyOf, removeDiacritics } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { IsDebugMode, IsMMUser } from "../../../utils/localstorage.bin";
import { MessageModelManager, lnk_HasKPIExtended, eKPIType, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { eColumnType, IsDiscountRateColumn } from "hub-lib/models/types.bin";
import { ReturnCurrencyProvider } from 'hub-lib/business/ReturnCurrencyProvider.bin';
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { CreateIndicateur, eIndicateurType, IndicateurDiscount, IndicateurKPI, IndicateurToString, ToReturnedCurrency } from "adwone-engine/index.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { props as GridBaseProps } from "../Generic/GridBase.bin";
import { store } from "../../../redux/store";
import { setData } from "../../../redux/gridSlice";
import { CreateColumnLink } from "../Cells/CellLink";
import { storeElements } from "../../../redux/storageReducer";

export async function CreateGrid(params?: {
    vertexParams?: any,
    vertexGridParams?: Partial<GridBaseProps<ref_Messages>>,
    columnFilter?: (col: ADWColumn<ref_Messages>) => boolean,
    templateCell?: (property: keyof ref_Messages, cellValue: any, dataItem: AdwRow<ref_Messages>, baseCell: any) => any
}) {

    const currMgr = new ReturnCurrencyProvider();
    await currMgr.init();
    let columns: ADWColumn<ref_Messages>[] = [];
    const forcedColumns: ADWColumn<ref_Messages>[] = [];
    const hiddenProperties: (keyof ref_Messages)[] = [];
    const properties: string[] = [];

    type colsMessArg = { tradType?: new () => any, pName?: string };
    const colsMess = (property: keyof ref_Messages, { tradType, pName }: colsMessArg = {}) => {
        let bindingPath = propertyOf<ref_Messages>(property);
        let columnCustom = new ADWColumn<ref_Messages>(TradProp(property, ref_Messages), bindingPath, ePropType.Link, true);
        columnCustom.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Messages>) => {
            let val = (dataItem.dataItem as any)?.[`${property}Name`];
            let res: any;
            if (tradType) res = TradValue(tradType, "Name", val);
            else res = val;
            if (params?.templateCell)
                res = params.templateCell(property, cellValue, dataItem, res);

            return res;
        }
        columns.push(columnCustom);
        hiddenProperties.push(property);
        properties.push(`${property}.${pName ?? "Name"} as ${property}Name`);
    }

    const colsLink = (property: keyof ref_Messages) => {
        const columnCustom = CreateColumnLink(ref_Messages, property);
        columns.push(columnCustom);
        hiddenProperties.push(property);
    }

    // colsMess("Support");
    // colsMess("AdvCompany_Com");
    // colsMess("AdvCompany_Fin");
    // colsMess("Advertiser");
    // colsMess("AdvertiserGroup");
    // colsMess("Product");
    // colsMess("Campaign");
    // colsMess("Media");
    // colsMess("Brand");
    colsMess("Format", { tradType: ref_Property });
    colsMess("Placement", { tradType: ref_Property });
    // colsMess("BroadcastArea");
    // colsMess("Currency");
    // colsMess("ReturnedCurrency");
    // colsMess("PlacementCategory");

    colsLink("Created_by");
    colsLink("Updated_by");

    const statusColumn = new ADWColumn<ref_Messages>(Trad("status"), "Status", ePropType.String, true)
    statusColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
        const status = dataItem?.dataItem?.Status;
        const tradStatus = Trad(status);
        dataItem['Status_cellValue'] = removeDiacritics(tradStatus);
        return <span className={`status_message_${status}`}>
            {tradStatus}
        </span>
    };

    let importstatusColumn = new ADWColumn<ref_Messages>(TradProp(ref_Messages.name, ("Import.Status" as any)), "Import.Status", ePropType.Any, true)
    importstatusColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
        return <span className={`status_message_${(dataItem.dataItem as any)?.Import?.Status}`}>
            {(dataItem.dataItem as any)?.Import?.Status ? Trad((dataItem.dataItem as any)?.Import?.Status) : ''}
        </span>
    };
    importstatusColumn.width = 150
    const discountTypeColumn = new ADWColumn<ref_Messages>(TradProp("DiscountMode"), "DiscountMode", ePropType.String, true)
    discountTypeColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
        return <span className={`discounttype_message_${dataItem.dataItem.DiscountMode}`}>
            {Trad(dataItem.dataItem.DiscountMode)}
        </span>
    };

    let stateColumn = new ADWColumn<ref_Messages>(TradProp("State", ref_Messages), "State", ePropType.String, true)
    stateColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
        return <span className={`state_message_${dataItem.dataItem.State}`}>
            {Trad(dataItem.dataItem.State)}
        </span>
    };

    // properties.push(`ModelProperties.MediaFamily.Name as MediaFamilyName`);
    // const familyMediaColumn = new ADWColumn<ref_Messages>(TradProp("ModelProperties.MediaFamily", ref_Messages), "ModelProperties.MediaFamily", ePropType.String, true)
    // familyMediaColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
    //     return dataItem.dataItem?.ModelProperties?.["MediaFamilyOther"] ? dataItem.dataItem?.ModelProperties?.["MediaFamilyOther"] : dataItem.dataItem?.["MediaFamilyName"];
    // };

    // properties.push(`ModelProperties.PlacementCategory.Name as PlacementCategoryName`);
    // const placementCategoryColumn = new ADWColumn<ref_Messages>(TradProp("ModelProperties.PlacementCategory", ref_Messages), "ModelProperties.PlacementCategory", ePropType.String, true)
    // placementCategoryColumn.cellValue = (cellValue, dataItem) => {
    //     return dataItem.dataItem?.ModelProperties?.["PlacementCategoryOther"] ? dataItem.dataItem?.ModelProperties?.["PlacementCategoryOther"] : dataItem.dataItem?.["PlacementCategoryName"];
    // }
    /*
        properties.push(`Deversement.Estimate.Code as DeversementEstimateCode`);
        const DeversementEstimateColumn = new ADWColumn<ref_Messages>(TradProp("Deversement.Estimate", ref_Messages), "Deversement.Estimate", ePropType.String, true)
        DeversementEstimateColumn.cellValue = (cellValue, dataItem) => {
            return dataItem.dataItem?.["DeversementEstimateCode"];
        }
    */
    // properties.push(`ModelProperties.Periodicity.Name as PeriodicityName`);
    // const periodicityColumn = new ADWColumn<ref_Messages>(TradProp("ModelProperties.Periodicity", ref_Messages), "ModelProperties.Periodicity", ePropType.String, true)
    // periodicityColumn.cellValue = (cellValue, dataItem) => {
    //     const periodicityComment = dataItem.dataItem?.ModelProperties?.["PeriodicityComment"];
    //     const periodicityName = dataItem.dataItem?.["PeriodicityName"];
    //     return periodicityComment ?? (periodicityName ? Trad(periodicityName) : "");
    // }

    const getDiffCol = (prop: keyof ref_Messages, isDeversement: boolean = false) => {
        const diffColumn = new ADWColumn<ref_Messages>(TradProp(prop, ref_Messages), prop, ePropType.Boolean, true)
        diffColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
            if (cellValue === "-1" || (isDeversement && dataItem.dataItem.State !== eStateType.Bound))
                return <div style={{ textAlign: "center" }}> - </div>

            const diff = isDeversement ? GetSubElement(dataItem.dataItem, prop) : cellValue === "0";
            dataItem[`${diffColumn.bindingPath}_cellValue`] = !diff;
            return <div className={diff ? "red_indicator" : "green_indicator"} />
        };
        hiddenProperties.push(prop);
        return diffColumn;
    }

    const diffC0Column = getDiffCol("Deversement.InfoCO.Diff" as any, true);
    const diffF0Column = getDiffCol("Deversement.InfoFO.Diff" as any, true);
    const diffColumn = getDiffCol("compliance_placement_pige" as keyof ref_Messages);

    columns.push(stateColumn)
    columns.push(statusColumn)
    columns.push(importstatusColumn)
    columns.push(discountTypeColumn)
    // columns.push(familyMediaColumn)
    // columns.push(placementCategoryColumn)
    //columns.push(DeversementEstimateColumn)
    //columns.push(periodicityColumn)
    if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
        columns.push(diffC0Column);
        columns.push(diffF0Column);
    }
    columns.push(diffColumn);

    if (IsMMUser())
        hiddenProperties.push('Group');

    hiddenProperties.push("Status")
    hiddenProperties.push(("Import.Status" as any))
    hiddenProperties.push("DiscountMode")
    hiddenProperties.push("State")
    // hiddenProperties.push("ModelProperties.MediaFamily" as any)
    // hiddenProperties.push("ModelProperties.PlacementCategory" as any)
    //hiddenProperties.push("Deversement.Estimate" as any)
    //hiddenProperties.push("ModelProperties.Periodicity" as any)

    const currencies = (await Client.get<ref_Currencies>(ref_Currencies)).data.results;

    const discountColDecorator = (type: eColumnType) => (col: ADWColumn<ref_Messages>) => {
        const ind = col.baseColumn as IndicateurDiscount;
        const instanceIndic = CreateIndicateur(ind);

        /** Temporaire, pour creer une clé unique par colonne, car les résultats sont indexés dans les rows */
        col.bindingPath = IndicateurToString(col.baseColumn);

        col.dataType = ePropType.Double;
        col.getValue = async (rowData: ref_Messages) => {

            const res = await instanceIndic.Compute([rowData]);
            if (res == undefined)
                return "";

            if (ind.valueType === eKPIType.Percent && (IsDiscountRateColumn(type) || ind.type === eIndicateurType.computed))
                return GetKPITemplate(eKPIType.Percent)(res * 100)

            let msgCurrCode = rowData.Currency;
            if (ind?.options?.isPriceReturned) {
                const ret = await ToReturnedCurrency(rowData, res, currMgr);
                if (ret.code)
                    msgCurrCode = ret.code;
            }

            const currencyCode = currencies.find(c => c["@rid"] === msgCurrCode).Code;
            return `${GetKPITemplate(eKPIType.Price)(res)} ${currencyCode}`;
        }

        return col;
    }

    if (params?.columnFilter) {
        columns = columns.filter(params?.columnFilter);
    }

    return new VertexGrid<ref_Messages>({
        objectPrototype: ref_Messages,
        columns,
        forcedColumns,
        filterRows: (row: ref_Messages) => row.Active,
        hiddenProperties,
        customConfCol: true,
        vertexParams: {
            Active: true,
            properties: ["*", ...properties],
            ...params?.vertexParams
        },
        frozenLeft: [],
        width: {
            Status: 120,
        },
        adapt: async (rows: ref_Messages[]) => {
            // création du store des Users pour les cellules Created_by, Updated_by
            storeElements(rows);
            store.dispatch(setData(rows));

            let mgr = KPIsManagerCache.GetInstance("Message");
            for (const row of rows) {

                let all = await mgr.GetKPIs(row.Media);
                all.forEach(a => {
                    let cast = (row as any);
                    cast.KPIs[a.KPI] = cast.KPIs[a.Id];

                });
            }
        },
        columnDecorator: {
            [eColumnType.Discount]: discountColDecorator(eColumnType.Discount),
            [eColumnType.DiscountFO]: discountColDecorator(eColumnType.DiscountFO),
            [eColumnType.DiscountFOS]: discountColDecorator(eColumnType.DiscountFOS),
            [eColumnType.DiscountValue]: discountColDecorator(eColumnType.DiscountValue),
            [eColumnType.DiscountFOValue]: discountColDecorator(eColumnType.DiscountFOValue),
            [eColumnType.DiscountFOSValue]: discountColDecorator(eColumnType.DiscountFOSValue),
            [eColumnType.PriceReturned]: async (col: ADWColumn<ref_Messages>) => {

                const ind = col.baseColumn;
                const instance = CreateIndicateur(ind);

                col.getValue = async (rowData: ref_Messages) => {

                    const res = await instance.Compute([rowData]);
                    if (res == undefined)
                        return "";

                    let msgCurrCode = rowData.Currency;
                    if ((ind?.options as any)?.isPriceReturned) {
                        const ret = await ToReturnedCurrency(rowData, res, currMgr);
                        if (ret.code)
                            msgCurrCode = ret.code;
                    }

                    const currencyCode = currencies.find(c => c["@rid"] === msgCurrCode).Code;
                    return `${GetKPITemplate(eKPIType.Price)(res)} ${currencyCode}`;
                }

                return col;
            },
            [eColumnType.DiscountValueBound]: discountColDecorator(eColumnType.DiscountValueBound),
            [eColumnType.DiscountFOValueBound]: discountColDecorator(eColumnType.DiscountFOValueBound),
            [eColumnType.PriceBound]: async (col: ADWColumn<ref_Messages>) => {

                const lnkKpis = await MessageModelManager.GetRefLnkKPIs();
                const instance = CreateIndicateur(col.baseColumn) as IndicateurKPI;

                const lnkKpi = lnkKpis.find(l => l.KPI === instance?.options?.rid);
                if (!col.options)
                    col.options = col.bindingPath;
                col.bindingPath = ("Bound" + lnkKpi?.Id) as any;

                const baseFunc = col.getValue;
                col.getValue = async (msg: ref_Messages) => {
                    const value = await col.getKPIValue(msg);
                    if (value === undefined)
                        return "";
                    return baseFunc(msg);
                };

                return col;
            },
            [eColumnType.Property]: (col: ADWColumn<ref_Messages>) => {
                if (col.baseColumn.name === TradProp('Deversement.EstimateStatus' as any, ref_Messages)) {
                    col.cellValue = (cellValue: any, dataItem: AdwRow<ref_Messages>) => {
                        const value = dataItem.dataItem["DeversementEstimateStatus"]
                        if (value && value !== "NC") {
                            return <span className={`status_estimate_${value}`}>{cellValue}</span>;
                        }
                        return value;
                    };
                }
                return col;
            }
        },
        ...params?.vertexGridParams
    });
}