import { ref_Messages, kpis } from "../models/ref_Messages.bin";
import { ref_Discount } from "../models/types/vertex.bin";
import { ref_DiscountTypes, ref_DiscountTypesId } from "../models/orientdb/ref_DiscountTypes.bin";
import { arrayUnique, clone, extractSub, toArray, toDictionaryList } from "../tools.bin";
import { ref_DiscountClasses, ref_DiscountClassesId } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_Agreements } from "../models/ref_Agreements.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { MessageModelManager } from "../models/KPIsManager.bin";
import { ref_GlobalAgreements } from "../models/ref_GlobalAgreements.bin";
import { DataProvider } from "../provider";
import BigNumber from "bignumber.js";
import { MAPInsertionRemise } from "../import/MAPTypes";
import { vw_mm_HasDiscountClass } from "../models/orientdb/vw_mm_HasDiscountClass.bin";
import { src_MM } from "../models/orientdb/src_MM.bin";

export type netType = "CO" | "FO" | "FOS";
export const netTypes: netType[] = ["CO", "FO", "FOS"]

export const categoryTypesNames = [
    ["Modulations", "Gracieux"],
    ["Remises"],
    ["Taxes", "Frais", "Honoraires"]
];

export type Computation = {
    Base: number,
    Discount: ref_Discount
}

export type ComputatedCascade = {
    CO: Computation[],
    FO: Computation[],
    FOS: Computation[]
}

const getBase = (msg: { KPIs: kpis }, idx: number, cofo: netType): number => {
    switch (idx) {
        case 0:
            return msg.KPIs.Gross
        case 1:
            return msg.KPIs.GrossBa
        case 2:
            return DiscountManager.getNet("Net", msg, cofo)
        case 3:
            return DiscountManager.getNet("Total", msg, cofo)
        default:
            return msg.KPIs.Gross
    }
}

const setBase = (msg: { KPIs: kpis }, idx: number, value: number, cofo: netType) => {
    DiscountManager.init(msg);
    switch (idx) {
        case 0:
            return msg.KPIs.GrossBa = value
        case 1:
            return msg.KPIs[`Net${cofo}`] = value
        case 2:
            return msg.KPIs[`Total${cofo}`] = value
        default:
            return msg.KPIs.Gross;
    }
}

export const IsValidKPIVolumeBase = async (lnk: lnk_HasKPI, ag: ref_Agreements) => {

    const categoryTypes = await DiscountManager.getCategoryTypes();
    const groups = categoryTypes?.map(cat => ag.Discounts?.filter(r => cat.includes(r.DiscountType)));

    if (!groups?.length)
        return true;

    for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        if (!group?.length)
            continue;

        let indexLnk = 0;
        if (lnk.Id.startsWith('Net')) indexLnk = 1;
        if (lnk.Id.startsWith('Total')) indexLnk = 2;

        return i >= indexLnk;
    }
}

let discountClasses: ref_DiscountClasses[] = undefined;
let discountTypes: ref_DiscountTypes[] = undefined;

const addDiscounts = (target: ref_Discount[], source: ref_Discount[]) => {
    return [...target.filter(d => !source.some(dm => dm.DiscountClass == d.DiscountClass)), ...source];
}


type initAgreementArgs = { updateCascade?: boolean } & ({ refreshCurrent?: true } | { agreement?: ref_GlobalAgreements });
export class DiscountManager {

    static SortDiscounts(discounts: ref_Discount[]): ref_Discount[] {
        const cloneArray = [...(discounts ?? [])];
        let results: ref_Discount[] = [];

        const categories = DiscountManager.getCategoryTypesLoaded();
        for (const cat of categories) {
            const categoryDiscount = cloneArray.filter(d => cat.includes(d.DiscountClass));
            results = [...results, ...categoryDiscount.sort(DiscountManager.Sort)];
        }

        return results;
    }

    static Sort(d1: ref_Discount, d2: ref_Discount): number {
        if (d1.Rank === d2.Rank) {
            if (d1.LastPosition == d2.LastPosition)
                return 0;
            if (d1.LastPosition)
                return 1;
            if (d2.LastPosition)
                return -1;
        }
        return d1.Rank - d2.Rank;
    }

    static async lockDiscountsLockedFromMAP(message: ref_Messages) {
        if (message?.Import && !message?.Import?.['autoLock']) {
            message.Import['autoLock'] = true;
            const saveAllDiscounts = message.Discounts ?? [];

            // get all discounts that are not locked and not from an agreement
            // we must identify which discounts are forced by the user in MAP import, even if if locked is false
            const discountsUnlockedFromUser = saveAllDiscounts.filter(s => !s.Agreement && !s.Locked);

            //console.log(`[INIT AGR] check`, intersection, message.Import)

            if (discountsUnlockedFromUser.length) {
                const remisesMAP: MAPInsertionRemise[] = message.Import?.Data?.['REMISES'];
                if (remisesMAP?.length) {
                    // Get MM View
                    const views = await DataProvider.getVwMMDiscountClasses();
                    //console.log(`[INIT AGR] views`, views)
                    discountsUnlockedFromUser
                        .forEach(d => {
                            const viewsFound = views.filter(v => v.Referential == d.DiscountClass);
                            //console.log(`[INIT AGR] viewsFound`, viewsFound)
                            if (viewsFound?.length) {
                                const remiseMap = remisesMAP.find(r => viewsFound.some(v => v.ExternalID == r.MM_DEGRESSIF_ID?.toString()));
                                d.Locked = remiseMap?.['TYPEAPP'] == 2;
                            }
                        });
                }
            }
        }
    }

    static async initGlobalAgreement(message: ref_Messages, opt?: initAgreementArgs) {
        let { refreshCurrent, agreement: globalAgreement, updateCascade } = { updateCascade: true, refreshCurrent: false, agreement: undefined, ...opt };

        if (refreshCurrent && !message.GlobalAgreement)
            return;

        const saveAllDiscounts = [...(message.Discounts ?? [])];

        if (!globalAgreement) {
            [globalAgreement] = refreshCurrent
                ? await DataProvider.search<ref_GlobalAgreements>(ref_GlobalAgreements.name, {
                    "@rid": message.GlobalAgreement,
                    Active: true,
                })
                : await getAvailableGlobalAgreements(message);
        }

        message.GlobalAgreement = globalAgreement?.["@rid"];
        message.GlobalAgreementLastUpdate = new Date();
        //message.DiscountMode = 'cascade';

        if (globalAgreement) { // prevent old @rid => call will be null
            globalAgreement.Discounts?.forEach(d => d.Agreement = globalAgreement['@rid']);
            message.DiscountMode = globalAgreement.DiscountMode;
        }

        message.Discounts = saveAllDiscounts.filter(s => !Boolean(s.Agreement) && !s.Locked);
        message.Discounts = addDiscounts(message.Discounts, globalAgreement?.Discounts ?? []);
        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(s => s.Agreement == message.Agreement));
        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(s => s.Locked));

        message.Discounts
            .filter(d => d.Locked)
            .forEach(d => {
                if (d.Agreement != message.Agreement) {
                    if (globalAgreement?.Discounts?.some?.(dm => dm.DiscountClass == d.DiscountClass))
                        d.Agreement = globalAgreement['@rid'];
                    else d.Agreement = null
                }
            });

        if (!message.DiscountMode)
            message.DiscountMode = "cascade";

        if (updateCascade)
            await DiscountManager.UpdateCascade(message);
    }

    static async initAgreement(item: ref_Messages | ref_Messages[], opt?: initAgreementArgs) {
        const messages = toArray(item);
        let { refreshCurrent, agreement, updateCascade } = { updateCascade: true, refreshCurrent: false, agreement: undefined, ...opt };

        let globalAgreement: ref_GlobalAgreements;

        if (agreement) {
            const globalAgreementID = arrayUnique(messages.map(m => m.GlobalAgreement));
            if (globalAgreementID) {
                [globalAgreement] = await DataProvider.search<ref_GlobalAgreements>(ref_GlobalAgreements.name, {
                    "@rid": globalAgreementID,
                    Active: true,
                })
            }
        }

        for (const message of messages) {
            const getMsg = () => extractSub(message, [
                "@rid",
                "AdvertiserGroup",
                "Advertiser",
                "Group",
                "Brand",
                "Product",
                "BroadcastArea",
                "Format",
                "Placement",
                "Support",
                "Currency",
                "Media",
                "Start",
                "End",
                "Discounts",
                "KPIs"]);
            // 2 cas possibles :
            // Rechercher le meilleur accord
            // Rafraichir l'accord existant pour le message
            if ((!agreement && !refreshCurrent) || (refreshCurrent && message.Agreement))
                [agreement] = await DataProvider.search<ref_Agreements>(ref_Agreements.name, {
                    ...(refreshCurrent && { "@rid": message.Agreement }),
                    Active: true,
                    prepared: true,
                    message: getMsg()
                });

            /*
            Steps:
            - save all discounts that were in message
            - clear discounts of the message
            - add discounts of the global agreement
            -- add discounts that are not locked and not from an agreement
            - add discounts of the agreement
            - add locked discounts
            -- final step: set agreement of locked discounts if they are in the agreement else set agreement to null
            */


            // save all discounts that were in message
            const saveAllDiscounts = [...(message.Discounts ?? [])];

            // clear discounts of the message
            message.Discounts = [];
            //message.Discounts = [];

            // add discounts of the global agreement
            await DiscountManager.initGlobalAgreement(message, { refreshCurrent: true, updateCascade: false, agreement: message.GlobalAgreement ? globalAgreement : undefined });

            // add discounts that are not locked and not from an agreement
            message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(s => !Boolean(s.Agreement) && !s.Locked));

            // const config = agreement?.["applyAgreementsConfig"] ?? ["CO", "FO", "FOS"];
            const config = ["CO", "FO", "FOS"];
            agreement?.Discounts?.forEach(d => {
                d.Agreement = agreement['@rid'];
                if (!config.includes("CO")) {
                    d.FO = DiscountManager.getModulation(d, "FO");
                    d.CO = saveAllDiscounts.find(dm => dm.DiscountClass == d.DiscountClass)?.CO;
                }
                if (!config.includes("FO")) {
                    d.FOS = DiscountManager.getModulation(d, "FOS");
                    d.FO = saveAllDiscounts.find(dm => dm.DiscountClass == d.DiscountClass)?.FO;
                }
            });

            message.Agreement = agreement ? agreement["@rid"] : null;
            message.AgreementLastUpdate = new Date();

            // if there is an agreement => add discounts of the agreement
            if (agreement) {
                message.DiscountMode = agreement.DiscountMode;
                message.Discounts = addDiscounts(message.Discounts, agreement.Discounts);
            }

            // if messages from MAP
            await DiscountManager.lockDiscountsLockedFromMAP(message);

            message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(s => s.Locked));

            // final step
            message.Discounts
                .filter(d => d.Locked)
                .forEach(d => {
                    if (agreement?.Discounts?.some?.(dm => dm.DiscountClass == d.DiscountClass))
                        d.Agreement = agreement['@rid'];
                    else if (d.Agreement != message.GlobalAgreement)
                        d.Agreement = null
                });

            if (!message.DiscountMode)
                message.DiscountMode = "cascade";

            if (updateCascade) {
                await DiscountManager.UpdateCascade(message);
            }

        }
    }

    static GetValue(discounts: ref_Discount[], discountClass: ref_DiscountClassesId, netType: netType, type: "Rate" | "Value") {
        DiscountManager.cleanDiscounts(discounts);
        const discount = discounts?.find(d => d.DiscountClass === discountClass);
        if (discount) {
            const res = DiscountManager.valid(discount[netType]) ?? (netType === "FOS" ? DiscountManager.valid(discount.FO) : undefined) ?? DiscountManager.valid(discount.CO) ?? discount;
            return type === "Rate" ? res.Rate : res.Value;
        }
        return undefined;
    }

    static async Load() {
        await DiscountManager.GetDiscountClasses();
        await DiscountManager.GetDiscountTypes();
    }

    static async GetDiscountClasses() {
        if (!discountClasses)
            discountClasses = await DataProvider.search<ref_DiscountClasses>((ref_DiscountClasses.name));

        return discountClasses
    }

    static async GetDiscountTypes() {
        if (!discountTypes) {
            discountTypes = await DataProvider.search<ref_DiscountTypes>((ref_DiscountTypes.name));
        }

        return discountTypes
    }

    static GetDiscountClassesLoaded() {
        return discountClasses
    }

    static GetDiscountTypesLoaded() {
        return discountTypes
    }

    static initKPI(msg: { KPIs: kpis }, kpi: string) {
        if (!msg.KPIs)
            msg.KPIs = new kpis();
        if (!msg.KPIs.hasOwnProperty(kpi))
            msg.KPIs[kpi] = 0;
    }

    static init(msg: { KPIs: kpis }) {
        DiscountManager.initKPI(msg, "NetCO");
        DiscountManager.initKPI(msg, "NetFO");
        DiscountManager.initKPI(msg, "NetFOS");
        DiscountManager.initKPI(msg, "TotalCO");
        DiscountManager.initKPI(msg, "TotalFO");
        DiscountManager.initKPI(msg, "TotalFOS");
    }

    static getNet(str: "Net" | "Total", msg: { KPIs: kpis }, cofo: netType) {
        return msg.KPIs[str + cofo];
    }


    static async SetFees(msg: ref_Messages, fee: number, discountClass: string, discountType: string) {
        let fees = msg?.Discounts?.find(d => d.DiscountClass == discountClass);
        if (!fees) {
            fees = new ref_Discount();
            msg.Discounts.push(fees);
        }
        const rate = fee / 100;
        fees.DiscountClass = discountClass;
        fees.DiscountType = discountType;
        fees.Rank = 1;
        fees.IsRate = true;
        fees.Rate = rate;
        fees.CO = { Rate: rate, Value: 0 };
        fees.Agreement = null;

        const [dClass] = await DataProvider.search<ref_DiscountClasses>(ref_DiscountClasses.name, { '@rid': discountClass });
        fees.Operator = <any>dClass.Operator;

        return fees;
    }

    static GetRefDiscountTypes = async (names: string[]): Promise<ref_DiscountTypes[]> => {
        const types = await DiscountManager.GetDiscountTypes();
        let res = null;
        res = types.filter(t => names.includes(t.Name));
        return res;
    }

    static getCategoryTypes = async (filterNames?: (name: string) => boolean) => {
        let categoryTypes: ref_DiscountTypesId[][] = [];

        for (let idx = 0; idx < categoryTypesNames.length; idx++) {
            const names = categoryTypesNames[idx]?.filter(n => (filterNames ? filterNames(n) : true));
            let res: ref_DiscountTypes[] = await DiscountManager.GetRefDiscountTypes(names);
            categoryTypes[idx] = res.map(r => r["@rid"]);
        }
        return categoryTypes;
    }

    static getCategoryTypesLoaded = (filterNames?: (name: string) => boolean) => {
        let categoryTypes: ref_DiscountTypesId[][] = [];

        for (let idx = 0; idx < categoryTypesNames.length; idx++) {
            const names = categoryTypesNames[idx]?.filter(n => (filterNames ? filterNames(n) : true));
            let res: ref_DiscountTypes[] = DiscountManager.GetDiscountTypesLoaded().filter(t => names.includes(t.Name));
            categoryTypes[idx] = res.map(r => r["@rid"]);
        }
        return categoryTypes;
    }

    static valid = (v: {
        Rate: number;
        Value: number;
    }) => {
        return v ?? undefined;
    }

    static getModulation = (discount: ref_Discount, cofo: string) => {
        return DiscountManager.valid(discount[cofo]) ?? (cofo === "FOS" ? DiscountManager.valid(discount.FO) : undefined) ?? DiscountManager.valid(discount.CO) ?? { Rate: 0, Value: 0 };
    }

    static getRemise = (discount: ref_Discount, rowBase: number, cofo: string, KPIs: kpis) => {
        const realMod = DiscountManager.valid(discount[cofo]);
        const mod = DiscountManager.getModulation(discount, cofo);
        let remise = 0;
        let rate = 0;

        if (discount.IsRate) {
            remise = new BigNumber(mod.Rate).multipliedBy(rowBase).abs().multipliedBy(100).toNumber();
            remise = discount.Operator * Math.round(remise) / 100;
            rate = mod.Rate;
            if (realMod) realMod.Value = remise;
        }
        else {
            if (discount.IsCPM) {
                remise = new BigNumber(discount.ValueCPM).multipliedBy(KPIs[discount.KpiCPM]).dividedBy(10).toNumber();
                remise = Math.round(remise) / 100;
                if (realMod) realMod.Value = remise;
            }
            else
                remise = Math.abs(mod.Value) * discount.Operator;
            rate = rowBase ? (remise / rowBase) : 0
            if (realMod) realMod.Rate = rate;
        }
        return { remise, rate };
    }

    static setRowType = (t: netType, row: ref_Discount, prop: "Rate" | "Value", value: any) => {
        if (t) {
            if (!row[t]) row[t] = { Rate: 0, Value: 0 };
            row[t][prop] = value;
        }
    }


    static cleanDiscounts = (discounts: ref_Discount[]) => {

        const valid = (v: {
            Rate: number;
            Value: number;
        }) => {
            if ((v?.Value === undefined || v?.Value === null) && (v?.Rate === undefined || v?.Rate === null))
                return false;
            return true;
        }

        discounts?.forEach(d => {
            if (!valid(d.CO)) delete d.CO;
            if (!valid(d.FO)) delete d.FO;
            if (!valid(d.FOS)) delete d.FOS;
        })
    }


    static async UpdateCascade(msg: { DiscountMode?: "cascade" | "cumul" | "mixed", KPIs: kpis, Discounts: ref_Discount[] }) {
        await DiscountManager.Load();
        return DiscountManager.UpdateCascadeSync(msg);
    }

    static UpdateCascadeSync(msg: { DiscountMode?: "cascade" | "cumul" | "mixed", KPIs: kpis, Discounts: ref_Discount[] }) {

        //if (msg.DiscountMode != "cascade" && msg.Discounts?.length)
        //msg.Discounts = [...msg.Discounts].sort((a, b) => a.Rank - b.Rank);

        if (!msg.Discounts)
            msg.Discounts = [];

        const computations: ComputatedCascade = { CO: [], FO: [], FOS: [] };

        DiscountManager.cleanDiscounts(msg.Discounts);
        msg.Discounts.sort(DiscountManager.Sort);

        const list = toDictionaryList(msg.Discounts, d => d.DiscountClass);
        // if (Object.values(list).length == 0)
        //     return computations;

        Object.values(list).forEach(val => {
            if (val.length > 1) {
                console.log(msg)
                throw new Error(`Too many discounts type:${val[0].DiscountType} class:${val[0].DiscountClass}`);
            }
        });

        const categoryTypes = DiscountManager.getCategoryTypesLoaded();

        const groups = categoryTypes?.map(cat => msg.Discounts?.filter(r => cat.includes(r.DiscountType)) ?? []);

        const cofos: netType[] = ["CO", "FO", "FOS"];

        const setRow = (row: ref_Discount) => {
            if (row.Value == -0) row.Value = 0;
            if (row.Rate == -0) row.Rate = 0;

            if (isNaN(row.Rate)) row.Rate = 0;
            if (isNaN(row.Value)) row.Value = 0;
        }

        const computeRows = (rows: ref_Discount[], currentBase: number, idx: number, cofo: netType, cascade: boolean = true) => {
            rows.sort(sortPosition);
            let totalRemise = 0;
            rows.forEach((row) => {
                let rowBase = currentBase + (cascade ? totalRemise : 0);

                const discountClass = DiscountManager.GetDiscountClassesLoaded().find(dc => dc["@rid"] === row.DiscountClass);
                if (discountClass?.Base) {
                    const base = discountClass.Base;
                    switch (base.Type) {
                        case "DiscountType":
                            const allFrais = msg.Discounts?.filter(r => r?.DiscountType === base.Data?.toString() && r !== row);
                            const res = allFrais.map(frais => DiscountManager.getModulation(frais, cofo)?.Value ?? 0).reduce((a, b) => a + b, 0);
                            rowBase = res;
                            break;

                        case "KPI":
                            rowBase = msg.KPIs[base.Field + cofo];
                            break;

                        default:
                            console.error(`base.Type not found:`, base);
                            break;
                    }
                }

                const { remise, rate } = DiscountManager.getRemise(row, rowBase, cofo, msg.KPIs);
                setRow(row);

                if (!isNaN(remise)) {
                    totalRemise += remise;
                    if (row.IsRate) {

                        const base = DiscountManager.valid(row[cofo]);
                        if (base)
                            base.Value = remise;
                    }
                }

                const cloneBase = clone(DiscountManager.getModulation(row, cofo));
                const cloneRow = clone(row);
                cloneRow[cofo] = cloneBase;
                if (row.IsRate) cloneBase.Value = remise;
                else cloneBase.Rate = rate;

                computations[cofo].push({
                    Base: rowBase,
                    Discount: cloneRow
                });

            });
            return totalRemise;
        }

        cofos.forEach(cofo => {

            groups?.forEach((grows, idx) => {

                let currentBase = getBase(msg, idx, cofo) ?? 0; // base

                if (msg.DiscountMode === "cumul") {
                    const ranks = Array.from(new Set(grows.map(r => r.Rank)));
                    ranks.sort((a, b) => (a - b));
                    ranks.forEach(rank => {
                        const currentRows = grows.filter(row => row.Rank === rank);
                        const totalRemise = computeRows(currentRows, currentBase, idx, cofo, false);
                        currentBase += totalRemise;
                    });
                } else {
                    const totalRemise = computeRows(grows, currentBase, idx, cofo, true);
                    currentBase += totalRemise;
                }

                setBase(msg, idx, currentBase, cofo);
            })
        })

        return computations;
    }
}



let sortPosition = (a: ref_Discount, b: ref_Discount) => {
    if (a.LastPosition && !b.LastPosition) return 1;
    if (!a.LastPosition && b.LastPosition) return -1;
    return 0;
}

export const agreementPerimeter: (keyof ref_Messages)[] = [
    "@rid",
    "AdvertiserGroup",
    "Advertiser",
    "Group",
    "Brand",
    "Product",
    "BroadcastArea",
    "Format",
    "Placement",
    "Support",
    "Currency",
    "Media",
    "Start",
    "End",
    "KPIs"]

export const getAvailableAgreements = async (message: ref_Messages) => {
    const agreements = await DataProvider.search<ref_Agreements>(ref_Agreements.name, {
        Active: true,
        prepared: true,
        message: extractSub(message, agreementPerimeter)
    });

    if (message.Agreement && !agreements.some(a => a["@rid"] == message.Agreement)) {
        const [messageAgreement] = await DataProvider.search<ref_Agreements>(ref_Agreements.name, { "@rid": message.Agreement, Active: [true, false] });
        if (messageAgreement) {
            messageAgreement["warning"] = true;
            agreements.unshift(messageAgreement);
        }
    }

    if (!agreements.length) {
        console.log("applyAgreement no agreement");
        return undefined;
    }

    return agreements;
}

export const getAvailableGlobalAgreements = async (message: ref_Messages) => {
    const globalAgreements = await DataProvider.search<ref_GlobalAgreements>(ref_GlobalAgreements.name, {
        Active: true,
        message: extractSub(message, [
            "@rid",
            "BroadcastArea",
            "Currency",
            "Media",
            "Start",
            "End"])
    });

    // LEGACY
    globalAgreements?.forEach(g => g?.Discounts?.forEach(d => d.Agreement = g['@rid']));
    return globalAgreements;
}

let discountTypesNonEditables: any[] = undefined;
export const DiscountCanBeEdited = (type: ref_DiscountTypes["@rid"]) =>
    !discountTypesNonEditables?.includes(type);

export async function InitEditableDiscountsList() {
    await DiscountManager.Load();
    if (discountTypesNonEditables === undefined) {
        const fees = (await DiscountManager.GetDiscountTypes()).find(t => t.Name == 'Frais');
        discountTypesNonEditables = [fees?.["@rid"]];
    }
}

export const getAvailableDiscountClasses = (discounts: ref_Discount[], cofo: netType, media: string) => {
    const availableTypes = DiscountManager.GetDiscountTypesLoaded().filter(t => (cofo == "CO" || DiscountCanBeEdited?.(t['@rid']))
        && t.Name != "Barter");
    return DiscountManager.GetDiscountClassesLoaded()
        .filter(c => !media || !c.Medias || c.Medias?.includes(media))
        .filter(c => !discounts?.some(d => d.DiscountClass == c["@rid"]))
        .filter(c => availableTypes.some(t => t["@rid"] == c.DiscountType));
}
