import { Grid } from '@material-ui/core';
import { ref_Campaigns } from 'hub-lib/models/ref_Campaigns.bin';
import * as React from 'react'
import { useEffect, useState } from 'react';
import { eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import Loader from '../../layout/Loader';
import { Trad, TradClassName, TradComposed, TradProp } from 'trad-lib';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import { Checkbox, Switch } from '@progress/kendo-react-inputs';
import { IsObjectValid, IsValid } from 'validation-lib/index.bin';
import { AdvertisersGroupsPicker } from '../../AdvertisersGroupsPicker/AdvertisersGroupsPicker';
import { distinct, duplicate, propertyOf, toArray, ToArrayObject } from 'hub-lib/tools.bin';
import { ContainerComponent } from '../Generic/ContainerComponent';
import { TextEditor } from '../TextEditor.bin';
import { DateNoZone, GetToday } from 'tools-lib';
import { Notify, NotifyError } from '../../../utils/Notify.bin';
import { Client } from 'hub-lib/client/client.bin';
import { CopyBody, DuplicationCampaignArgs } from 'hub-lib/models/types.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { CustomTextField } from '../Generic/CustomTextField';
import { CustomNumericTextBox } from '../Generic/Common.bin';
import { AdvertiserHierarchyComponent } from '../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent';
import { StoreCreationCampaigns } from '../AdvertiserHierarchyComponent/StoreFilters';
import { ref_Comments, ref_Visuals } from 'hub-lib/models/ref_Attachments.bin';
import { CampaignPerformance } from './CampaignPerformance';
import { AdwTelerikGrid } from '../Generic/AdwTelerikGrid.bin';
import { styleGridContainer } from '../../../styles/theme';
import { VertexGrid } from '../Generic/VertexGrid.bin';
import { ADWColumn, ADWGrid, AdwRow, VertexAutocomplete } from 'adwone-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { FilesPicker } from '../Generic/FilesPicker.bin';
import { ref_Estimates } from 'hub-lib/models/ref_Estimates.bin';
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import { QuestionsForm } from 'hub-lib/dto/QuestionsForm';
import { GenericForm } from '../../layout/GenericForm';
import { Row, Title } from '../../Tools';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { getIcon } from '../../ConfigurableComponents/IconManager.bin';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { EstimatesLinkedDetail } from '../Estimates/EstimateLinkedDetails.bin';
import { CustomMultiSelect } from '../Generic/CustomMultiSelect';
import { IsMMUser } from '../../../utils/localstorage.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { StyleFactory } from '../../../utils/ToolsReact';
import { CampaignOverview } from './CampaignOverview';
import EventEmitter from 'events';

export function DicoTitleCampaignTrad() {
    return {
        [eDialogMode.create]: Trad("new_campaign"),
        [eDialogMode.modify]: Trad("edit_campaign"),
        [eDialogMode.duplicate]: Trad("dupli_campaign")
    }
}

export function DicoCreateTrad() {
    return {
        [eDialogMode.create]: Trad("create"),
        [eDialogMode.modify]: Trad("modify"),
        [eDialogMode.duplicate]: Trad("duplicate")
    }
}
class TProps {
    submitEvent?: EventEmitter;
    mode: eDialogMode;
    campaign?: ref_Campaigns;
    multiAdvertisers?: boolean;
    estimatedMode?: boolean;
    onCancel?: () => void;
    onSave: (e: ref_Campaigns, mode: eDialogMode) => void;
}

const overviewWidth = '350px';

const StylesBase = StyleFactory.create({
    column: {
        flex: overviewWidth,
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
});

const Styles = StyleFactory.create({
    layout: { display: 'flex', height: '100%' },
    columnOverview: {
        ...StylesBase.column,
        flex: overviewWidth,
        background: '#DBF1F8'
    },
    columnContent: {
        ...StylesBase.column,
        flex: `calc(100% - ${overviewWidth})`,
    }
});

export function DuplicateMessages(props: { params: DuplicationCampaignArgs }) {
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const CheckBoxParams = (propertyName: (keyof DuplicationCampaignArgs), clearAll: boolean = false) => {
        return <Grid item xs={3}>
            <Checkbox
                checked={props.params[propertyName]}
                disabled={!clearAll && !props.params.messages}
                label={Trad(`duplicate_${propertyName}`)}
                onChange={(e) => {
                    props.params[propertyName] = e.value;
                    if (clearAll && !props.params[propertyName])
                        Object.keys(props.params).forEach(k => {
                            if (k != propertyName)
                                props.params[k] = e.value;
                        })
                    forceUpdate();
                }}
            />
        </Grid>
    }
    return <Grid container xs={12} className="message_details_full">
        {CheckBoxParams("messages", true)}
        {CheckBoxParams("messagesWithStatus")}
        {CheckBoxParams("messagesCanceled")}
        {CheckBoxParams("broadcastPlacement")}
    </Grid>
}

export function CampaignForm(props: TProps) {
    const { mode } = props;
    const [state, setState] = useState<{
        Loading: boolean,
        Campaign: ref_Campaigns,
        originCampaign: ref_Campaigns,
        validationMode: boolean,
        displayWarning: boolean,
        Attachments: { [type: string]: string },
        DisableActions: boolean,
        DuplicateParams: DuplicationCampaignArgs,
        Focused: boolean,
        VisualGrid: VertexGrid<ref_Visuals>,
        CreateEstimate: boolean,
        UpdateEstimate: boolean,
        selected: number
    }>({ Loading: true, CreateEstimate: false, UpdateEstimate: false, selected: 0 } as any);

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [form, setForm] = React.useState<QuestionsForm>(null);

    const {
        Loading,
        Campaign,
        originCampaign,
        validationMode,
        displayWarning,
        Attachments,
        DisableActions,
        DuplicateParams,
        Focused,
        VisualGrid,
        selected,
        CreateEstimate,
        UpdateEstimate
    } = state;

    const PropertyValidationError = (property: keyof ref_Campaigns) => {
        if (!validationMode) return null;
        let valid = IsValid<ref_Campaigns>(ref_Campaigns, Campaign, property);
        if (!valid || valid.isValid) return null;
        return valid.errorText;
    }

    const campaignHasChanged = () => {
        return originCampaign["@rid"] &&
            (Campaign.AdvertiserGroup !== originCampaign.AdvertiserGroup || Campaign.Advertiser !== originCampaign.Advertiser);
    }

    const setCampaignComments = (key: 'External' | "Internal") => {
        let comment = Campaign?.["Attachments"]?.find((a) => a.AttachementType === "comment" && a.Type === key) as ref_Comments;
        if (!comment) {
            comment = new ref_Comments();
            comment.Type = key;
            if (!Campaign["Attachments"])
                Campaign["Attachments"] = [];
            Campaign["Attachments"].push(comment);
        }
        comment.Text = Attachments[key];
    }

    const generateComments = (label: string, type: string, className: string) => {
        return (<div className={className}>
            <ContainerComponent title={label} className="auto-height no-padding no-margin">
                <TextEditor
                    content={Attachments[type]}
                    onChange={(value: string) => Attachments[type] = value} />
            </ContainerComponent>
        </div>)
    }

    const Save = async (_c: ref_Campaigns) => {
        if (IsObjectValid<ref_Campaigns>(ref_Campaigns, _c).length) {
            Notify("Certains champs ne sont pas valides.", "warning");
            setState({ ...state, validationMode: true });
            return;
        }
        setCampaignComments("Internal");
        setCampaignComments("External");
        Notify(Trad(`${props.mode}_in_progress`), "info");
        setState({ ...state, DisableActions: true });
        try {
            let campaign = { ..._c, Name: _c.Name.trim() };
            if (props.mode == eDialogMode.duplicate) {
                await Client.copyVertex(ref_Campaigns.name, { ...(campaign as CopyBody), ...DuplicateParams }, false);
                Notify(`${Trad('campaign')} : ${campaign.Name} ${Trad('creation_succes_feminine')}`, 'success');
            }
            else if (campaign['@rid']) {
                await Client.updateVertex(ref_Campaigns.name, campaign, false);
                Notify(`${Trad('campaign')} : ${campaign.Name} ${Trad('modify_success_feminine')}`, 'success');
            } else {
                campaign = (await Client.createVertex(ref_Campaigns.name, campaign, false)).data.results;
                Notify(`${Trad('campaign')} : ${campaign.Name} ${Trad('creation_succes_feminine')}`, 'success');
            }

            setState({ ...state, DisableActions: false });
            props?.onSave(campaign, props.mode);
        }
        catch (error: any) {
            setState({ ...state, DisableActions: false });
            NotifyError(error?.response?.data)
        }
    }

    const ConfigureElement = (campaign: ref_Campaigns) => {
        let defaultEnd = GetToday();
        defaultEnd.setMonth(11);
        defaultEnd.setDate(31);
        campaign.Start = campaign.Start ? DateNoZone(campaign.Start) : GetToday();
        campaign.End = campaign.End ? DateNoZone(campaign.End) : defaultEnd;
        if (!campaign.hasOwnProperty("Budget"))
            campaign.Budget = 0;
        if (props.mode == eDialogMode.duplicate) {
            campaign.Name += `(${Trad('copy')})`;
            campaign.Estimates = [];
        }

        return campaign;
    }

    const [medias, setMedias] = useState<ref_Media[]>();
    const [existingMedias, setExistingMedias] = React.useState<ref_Media['@rid'][]>();
    React.useEffect(() => {
        props.submitEvent?.removeAllListeners("submit");
        props.submitEvent?.addListener("submit", () => Save(Campaign));
        if (!medias)
            Client.searchVertexTyped(ref_Media)
                .then(setMedias);

        if (Campaign?.["@rid"] && !existingMedias)
            Client.searchVertexTyped(ref_Messages, {
                Campaign: Campaign['@rid'],
                properties: ["Media"],
                Source: "ADWONE"
            })
                .then(messages => {
                    const medias = distinct(messages.map(m => m.Media));
                    setExistingMedias(medias);
                });
    });

    useEffect(() => {
        if (!Campaign && props.mode == eDialogMode.create)
            setState({ ...state, Campaign: ConfigureElement(new ref_Campaigns()) });

        if (!Campaign && props.campaign && props.mode !== eDialogMode.create)
            Client.searchVertexTyped(ref_Campaigns, { "@rid": props.campaign["@rid"], getEstimates: true, Attachments: [{ AttachementType: 'comment' }] })
                .then(res => setState({ ...state, Campaign: { ...props.campaign, ...ConfigureElement(res[0]) } }));

        if (Loading && Campaign) {
            const _state = duplicate(state);
            _state.originCampaign = { ...Campaign };
            if (props.mode === eDialogMode.modify)
                _state.CreateEstimate = Campaign?.Estimates?.length !== 0;
            _state.Attachments = {
                "External": (Campaign?.["Attachments"] as ref_Comments[])?.find((a) => a.AttachementType === "comment" && a.Type === "External")?.Text ?? "",
                "Internal": (Campaign?.["Attachments"] as ref_Comments[])?.find((a) => a.AttachementType === "comment" && a.Type === "Internal")?.Text ?? ""
            };
            _state.DuplicateParams = { messages: false, messagesCanceled: false, messagesWithStatus: false, broadcastPlacement: false };
            _state.Loading = false;
            const columns = [];
            const nameColumn = new ADWColumn<ref_Visuals>(TradProp("Name", ref_Visuals), "Name", ePropType.String, true);
            nameColumn.width = 300;

            const fileColumn = new ADWColumn<ref_Visuals>(TradProp("File", ref_Visuals), "File", ePropType.String, false);
            fileColumn.width = 500;
            fileColumn.cellValue = (cellValue: any, row?: AdwRow<ref_Visuals>) => {
                const visual = _state.Campaign.Visuals.find(v => v["@rid"] == row.id || v["@rid"] == row.dataItem["@rid"]);
                if (row.inEdit)
                    return <></>;
                return <FilesPicker rid={visual?.["@rid"]} attachmentsIds={[visual?.File]} onChange={(attachmentId, deleted) => {
                    visual.File = attachmentId;
                }} />
            };
            columns.push(nameColumn);
            columns.push(fileColumn);
            const hiddenProperties = ["Name", "File", "Campaign", "Status", "Created_by", "Updated_by", "Created_at", "Updated_at"];
            _state.VisualGrid = new VertexGrid<ref_Visuals>({
                objectPrototype: ref_Visuals,
                devMode: false,
                hiddenProperties,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"],
                    "Campaign": _state.Campaign['@rid'] ?? null
                },
                afterSearch: (visuals) => {
                    _state.Campaign.Visuals = visuals;
                    return visuals;
                },
                onInlineNew: (visual, row) => {
                    visual["@rid"] = row.id;
                    visual["Active"] = true;
                    _state.Campaign.Visuals.push(visual);
                },
                onInlineEdited: (visual, row) => {
                    let oldVisual = _state.Campaign.Visuals.find(v => v["@rid"] == row.id || v["@rid"] == row.dataItem["@rid"]);
                    let position = _state.Campaign.Visuals.indexOf(oldVisual);
                    if (!_state.Campaign.Visuals[position]["@rid"])
                        _state.Campaign.Visuals[position]["@rid"] = row.id;
                    _state.Campaign.Visuals[position].Name = visual.Name;
                },
                onInlineDeleted: (visuals, rows) => {
                    const deletedIds = rows.map(r => r.dataItem["@rid"] ?? r.id);
                    _state.Campaign.Visuals = _state.Campaign.Visuals.filter(v => !deletedIds.includes(v["@rid"]));
                },
                validator: async (visual, errors, row) => {
                    const doublon = _state.Campaign.Visuals.some(v => v.Name == visual.Name && v["@rid"] != row.id && v["@rid"] != visual["@rid"]);
                    if (doublon)
                        errors.push("Name");
                }
            });
            setState(_state);
        }

        if (Campaign) {
            if (props.multiAdvertisers) {
                Campaign.AdvertiserGroup = null;
                Campaign.Advertiser = null;
                Campaign.Brand = null;
                Campaign.Product = null;
            } else {
                Campaign.Group = null;
            }
        }
    })

    const AddNewEstimate = (params: { nb?: number | string, source?: string, clear?: boolean, createParams?: any }) => {
        const { nb, source, clear, createParams } = params;
        const isMMUser = IsMMUser();
        if (clear) {
            Campaign.Estimates = [];
            setState({ ...state, selected: 0, CreateEstimate: true });
        }
        Campaign.Estimates.push({ EstimateLib: TradComposed("estimate_number", [nb.toString()]), Source: source ?? isMMUser ? "MM" : "Sellsy", ExternalID: null, CreateParams: createParams ?? {} });
        forceUpdate();
    }

    React.useEffect(() => {
        if (CreateEstimate && RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
            Promise.resolve().then(async () => {
                const form = await Client.getForm(ref_Estimates.name, { [propertyOf<ref_Estimates>('Campaign')]: Campaign, mode: props.mode })
                setForm(form);
                if (Campaign && !Campaign?.Estimates?.length && form && Object.keys(form)?.length)
                    AddNewEstimate({ nb: "1", clear: true });
            })
        } else {
            setForm(null);
        }
    }, [Campaign?.AdvertiserGroup, Campaign?.Advertiser, Campaign?.Brand, Campaign?.Product, CreateEstimate])

    React.useEffect(() => {
        if (Campaign?.Estimates) {
            Campaign.Estimates = [];
            setState({ ...state, selected: 0, CreateEstimate: false });
            forceUpdate();
        }
    }, [props?.estimatedMode])

    if (Loading)
        return <Loader />

    const estimateSwitch = <Switch
        checked={CreateEstimate}
        onChange={(event) => {
            event.nativeEvent.preventDefault();
            event.nativeEvent.stopPropagation();
            if (CreateEstimate) {
                Campaign.Estimates = [];
                setState({ ...state, selected: 0, CreateEstimate: false });
            } else {
                setState({ ...state, CreateEstimate: true, UpdateEstimate: true });
            }
        }}
        name={Trad("create_estimate")}
    />;

    return <div className='message_dialog' style={Styles.layout}>
        <div style={Styles.columnOverview}>
            <CampaignOverview campaign={Campaign} estimateSwitch={props.mode == eDialogMode.create || UpdateEstimate === true || Campaign?.Estimates?.length === 0 ? estimateSwitch : <></>} showPerformances={Boolean(mode == eDialogMode.modify
                && medias && existingMedias && medias
                    .filter(m => existingMedias.includes(m['@rid'])).length > 0)} >
                <Title>
                    <span>{DicoTitleCampaignTrad()[mode]}</span>
                </Title>
            </CampaignOverview>
        </div>

        <div className='message_dialog_content' style={Styles.columnContent}>
            <div id='campaignpicker'>
                <Title>{Trad('Campaign')}</Title>
                <Row>
                    <Grid container className='block-container'>
                        {props.mode == eDialogMode.duplicate &&
                            <DuplicateMessages params={DuplicateParams} />
                        }
                        <Grid item xs={6} className="message_details_leftcombo">
                            <CustomTextField
                                label={Trad("campaign_name") + " *"}
                                value={Campaign.Name}
                                textFieldProps={{
                                    inputRef: input => {
                                        if (Focused) return
                                        setState({ ...state, Focused: true });
                                        input?.focus();
                                    },
                                    helperText: PropertyValidationError("Name"),
                                    error: PropertyValidationError("Name") != null
                                }}
                                onChange={(e) => {
                                    Campaign.Name = e
                                    forceUpdate();
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} className="message_details_rightcombo">
                            <DateRangepicker
                                disabled={props.estimatedMode}
                                defaultStart={Campaign.Start}
                                defaultStop={Campaign.End}
                                handleChangeDate={(e: { start: Date, end: Date }) => {
                                    Campaign.Start = e.start;
                                    Campaign.End = e.end;
                                    forceUpdate();
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} className="message_details_leftcombo">
                            <CustomNumericTextBox
                                title={Trad("campaign_budget")}
                                value={Campaign.Budget}
                                min={0}
                                onChange={(e) => {
                                    /*let budget = parseFloat(e.value);
                                    if (budget < 0) budget = 0;*/
                                    Campaign.Budget = e.value;
                                    if (isNaN(Campaign.Budget))
                                        Campaign.Budget = 0;
                                    forceUpdate();
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} className="message_details_rightcombo">
                            {RightManager.hasRight(eFunctions.ref_Estimates, eRights.read) && props.estimatedMode && props.mode === eDialogMode.create && !Campaign?.Estimates?.length &&
                                <CustomMultiSelect minimumFilterLength={3} vertex={ref_Estimates} searchKey={"Code"} title={Trad("estimate")} onChange={(e) => {
                                    const [estimate] = e.value;
                                    Campaign.Name = estimate.Lib;
                                    Campaign.AdvertiserGroup = estimate.AdvertiserGroup;
                                    Campaign.Advertiser = estimate.Advertiser;
                                    Campaign.Brand = estimate.Brand;
                                    Campaign.Product = estimate.Product;
                                    Campaign.Start = estimate.Start;
                                    Campaign.End = estimate.End;
                                    AddNewEstimate({ nb: 1, source: "MM", clear: true, createParams: { estimate: estimate["@rid"] } });
                                    forceUpdate();
                                }} />}
                        </Grid>

                        {!props.multiAdvertisers &&
                            <AdvertiserHierarchyComponent
                                hiddenKeys={['Campaign']}
                                multiSelection={false}
                                lockedKeys={props.estimatedMode ? ["AdvertiserGroup", "Advertiser", "Brand", "Product"] : []}
                                applyFilters
                                reduxStore={StoreCreationCampaigns}
                                store={ToArrayObject(Campaign)}
                                onChange={newCampaign => {
                                    const hasEstimates = (Campaign?.Estimates || []).filter(e => e.ExternalID !== null)?.length > 0;
                                    if (hasEstimates && (Campaign.AdvertiserGroup !== toArray(newCampaign.AdvertiserGroup)?.[0] || Campaign.Advertiser !== toArray(newCampaign.Advertiser)?.[0] || Campaign.Brand !== toArray(newCampaign.Brand)?.[0] || Campaign.Product !== toArray(newCampaign.Product)?.[0])) {
                                        state.selected = 0;
                                        state.CreateEstimate = false;
                                        state.UpdateEstimate = true;
                                        Notify(Trad("warning_delete_estimates"), "warning");
                                        Campaign.Estimates = [];
                                    }
                                    Campaign.AdvertiserGroup = toArray(newCampaign.AdvertiserGroup)?.[0];
                                    Campaign.Advertiser = toArray(newCampaign.Advertiser)?.[0];
                                    Campaign.Brand = toArray(newCampaign.Brand)?.[0];
                                    Campaign.Product = toArray(newCampaign.Product)?.[0];
                                    setState({ ...state, displayWarning: campaignHasChanged() })
                                }} />}

                        {props.multiAdvertisers && <Grid item xs={6} style={{ marginBottom: 20 }}>
                            <AdvertisersGroupsPicker onChange={group => Campaign.Group = group} defaultValue={Campaign?.Group} />
                        </Grid>}

                        <Grid item xs={12} className="message_details_full" style={{ display: "flex" }}>
                            {generateComments(Trad("internal_comment"), "Internal", "message_details_leftcombo half-width")}
                            {generateComments(Trad("external_comment"), "External", "message_details_rightcombo half-width")}
                        </Grid>
                    </Grid>

                </Row>
            </div>
            {Campaign?.Estimates?.length > 0 &&
                <div id='estimatepicker'>
                    <Title>{Trad('estimate')}</Title>
                    <Row>
                        <Grid container className='block-container'>
                            <div style={{ width: "100%" }}
                                key={`EstimatesTab-${selected}-${Campaign?.Estimates?.length}`}>
                                <TabStrip
                                    className="tabpanel_fullwidth tabpanel_rightmenu"
                                    selected={selected}
                                    onSelect={(e) => {
                                        setState({ ...state, selected: e.selected });
                                    }}
                                >
                                    {Campaign?.Estimates?.map((tab, index) => (
                                        <TabStripTab title={
                                            <div>
                                                {tab.EstimateLib}
                                                {index !== selected && tab.ExternalID === null && <CustomIconButton onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    const newSelected = selected === Campaign.Estimates.length - 1 ? selected - 1 : selected;
                                                    setState({ ...state, selected: newSelected });
                                                    Campaign.Estimates.splice(index, 1);
                                                }} >{getIcon("close")}</CustomIconButton>}
                                            </div>
                                        }>{form &&
                                            <Row>
                                                <GenericForm disabled={props.mode === eDialogMode.modify && tab.ExternalID !== null} form={form} store={tab.CreateParams ?? {}} order={{
                                                    modeles: "message_details_leftcombo",
                                                    priceCategory: "message_details_rightcombo",
                                                    tvaMode: "message_details_leftcombo",
                                                    documentAppearance: "message_details_rightcombo"
                                                }} />
                                                {props.mode == eDialogMode.modify && tab.ExternalID !== null && <EstimatesLinkedDetail dataItem={{ dataItem: { ["@rid"]: tab.ExternalID } }} dataIndex={0} hideMessage />}
                                            </Row>
                                            }
                                        </TabStripTab>
                                    ))}
                                    <TabStripTab title={
                                        <div onClick={(event) => {
                                            let number = Campaign.Estimates.length;
                                            const estimates = Campaign.Estimates.filter(e => e.ExternalID === null);
                                            const lastEstimate = estimates[estimates.length - 1];
                                            if (lastEstimate) {
                                                const [numberLast] = lastEstimate.EstimateLib.match(/\d+/g);
                                                number = parseInt(numberLast);
                                            }
                                            AddNewEstimate({ nb: number + 1 });
                                        }}>
                                            <CustomIconButton
                                                className="custom_btn_secondary">
                                                {getIcon("plus_rounded")}
                                            </CustomIconButton>
                                        </div>
                                    } />
                                </TabStrip>
                            </div>
                        </Grid>
                    </Row>
                </div>
            }
            <div id='advertisingpicker'>
                <Title>{Trad('advertising_creation')}</Title>
                <Row>
                    <Grid container className='block-container'>

                        {VisualGrid && <Grid item xs={12} className="message_details_full">
                            <AdwTelerikGrid
                                grid={VisualGrid}
                                deleteInline
                                selectable
                                customAddText={Trad("add_visual")}
                                gridHeight={styleGridContainer.supportForm.height}
                                addButton />
                        </Grid>}
                    </Grid>
                </Row>
            </div>

            {mode == eDialogMode.modify
                && medias && existingMedias && medias
                    .filter(m => existingMedias.includes(m['@rid'])).length > 0 &&
                <div id='performancespicker'>
                    <Title>{Trad('performances')}</Title>
                    <Row>
                        <Grid container className='block-container'>
                            <CampaignPerformances campaign={Campaign} medias={medias} existingMedias={existingMedias} />
                        </Grid>
                    </Row>
                </div>}

            {displayWarning && <span>{Trad("warning_campaign_changed")}</span>}
        </div>
    </div>
}

type CampaignPerformances = { campaign: ref_Campaigns, medias: ref_Media[], existingMedias: ref_Media["@rid"][] }
function CampaignPerformances({ campaign, medias, existingMedias }: CampaignPerformances) {

    const [selected, setSelected] = React.useState<number>(0);

    return <TabStrip className="tabpanel_fullwidth" selected={selected} onSelect={e => setSelected(e.selected)}>
        {existingMedias && medias
            && medias
                .filter(m => existingMedias.includes(m['@rid']))
                .map(m => {
                    if (!campaign.Performances) campaign.Performances = {};
                    if (!campaign.Performances[m["@rid"]]) campaign.Performances[m["@rid"]] = {};
                    return <TabStripTab key={`performances_${m['@rid']}`} title={Trad(m.Name)}>
                        <CampaignPerformance
                            campaign={campaign}
                            kpis={campaign.KPIsMedia[m["@rid"]]}
                            performances={campaign.Performances[m["@rid"]]} />
                    </TabStripTab>
                })}
    </TabStrip>
}