import { ref_CurrenciesId } from "./orientdb/ref_Currencies.bin";
import { ref_CompaniesId } from "./orientdb/ref_Companies.bin";
import { E } from "./orientdb/E.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { User } from "./orientdb/User.bin";

export type lnk_ChangeRateId = rid;

export class lnk_ChangeRate extends E<ref_CurrenciesId, ref_CurrenciesId> {
	"@rid"?: lnk_ChangeRateId;
	Rate: number;
	Start: Date;
	End: Date;
	Company: ref_CompaniesId;
	Active: boolean;
	Created_at?: Date;
	Created_by?: User["@rid"];
	Updated_at?: Date;
	Updated_by?: User["@rid"];
}