import { ref_CurrenciesId } from "./orientdb/ref_Currencies.bin";
import { ref_BroadcastAreasId } from "./orientdb/ref_BroadcastAreas.bin";
import { ref_CustomersId } from "./orientdb/ref_Customers.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { V } from "./orientdb/V.bin";
import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_Discount } from "./types/vertex.bin";

export type ref_AgreementsId = rid;

export class ref_GlobalAgreements extends V implements Referentials {
    "@rid"?: ref_AgreementsId;
    Active: boolean;
    Name: string;
    BroadcastAreas: ref_BroadcastAreasId[];
    Currency: ref_CurrenciesId;
    Media : ref_MediaId;
    Start: Date;
    End?: Date;

    DiscountMode?: "cascade" | "cumul" | "mixed";
    Discounts?: ref_Discount[];
}