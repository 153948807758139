import * as React from "react";
import { Grid } from "@material-ui/core";
import { VertexAutocomplete } from "adwone-lib";
import { TradClassName } from "trad-lib";
import { ref_Estimates } from "hub-lib/models/ref_Estimates.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { Client } from "hub-lib/client/client.bin";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { GenericForm } from "../GenericForm";
import { QuestionsForm } from "hub-lib/dto/QuestionsForm";
import { propertyOf } from "hub-lib/tools.bin";

type TProps = { item: ref_Estimates; mode: eDialogMode }
export function EstimateForm({ item, mode }: TProps) {

    if (!item.ModelProperties)
        item.ModelProperties = {}

    const [form, setForm] = React.useState<QuestionsForm>(null);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            setForm(await Client.getForm(ref_Estimates.name, { [propertyOf<ref_Estimates>('Campaign')]: item.Campaign }));
        })
    }, [item.Campaign])

    return <Grid container xs={12}>
        <Grid item xs={6} className="message_details_leftcombo">
            <VertexAutocomplete
                label={TradClassName(ref_Campaigns.name)}
                type={ref_Campaigns.name}
                disableClearable
                defaultValue={(options: ref_Campaigns[]) => options.find(e => e["@rid"] === (Array.isArray(item?.Campaign) ? item?.Campaign?.[0] : item?.Campaign))}
                onChange={(value: ref_Campaigns) => {
                    item.Campaign = value?.["@rid"];
                    forceUpdate();
                }} />
        </Grid>
        {form && <GenericForm form={form} store={item.ModelProperties} />}
    </Grid>;
}