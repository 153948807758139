import { Grid } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import { AdwAutocomplete } from 'adwone-lib'
import { Client } from 'hub-lib/client/client.bin'
import { ref_Contacts } from 'hub-lib/models/orientdb/ref_Contacts.bin'
import { ref_PersonExtended } from 'hub-lib/models/ref_PersonExtended'
import { GetHashCode, Typed } from 'hub-lib/tools.bin'
import { eContactType } from 'hub-lib/models/eContactType'
import * as React from 'react'
import { Trad, TradProp } from 'trad-lib'
import { ConsoleDebug } from '../../../../utils/localstorage.bin'
import { CustomTextField } from '../../Generic/CustomTextField'

type ContactOption = {
    Contact: Partial<ref_Contacts>,
    Temp?: boolean
}

const filter = createFilterOptions<ContactOption>();

const mapContact = (contact: ref_Contacts) => Typed<ContactOption>({ Contact: contact })

type ContactFormArgs = { element: ref_PersonExtended }
export function ContactForm({ element }: ContactFormArgs) {

    ConsoleDebug(`ContactForm, element: `, element);

    if (!element.Contact)
        element.Contact = new ref_Contacts();

    const [contact, setContact] = React.useState<Partial<ref_Contacts>>(element.Contact);
    const [contacts, setContacts] = React.useState<ref_Contacts[]>();

    React.useEffect(() => {
        if (!contacts) Client.searchVertexTyped(ref_Contacts).then(setContacts);
    })

    const contactsOptions = (contacts ?? []).map(mapContact);
    const selectedOption = contact ? contactsOptions.find(c => c.Contact?.Email == contact.Email) : undefined;
    const loadingContacts = !contacts;

    ConsoleDebug(`loadingContacts: `, loadingContacts);
    ConsoleDebug(`ContactForm, selectedOption: `, selectedOption);

    return <>
        <Grid item xs={12} className="message_details_full">
            <AdwAutocomplete
                key={`contacts-autocomplete-${loadingContacts}`}
                loading={loadingContacts}
                {...(!loadingContacts && {
                    value: selectedOption,
                    options: contactsOptions
                })}
                getOptionLabel={o => o?.Contact?.Email ?? o?.toString()}
                onChange={(e, v) => {
                    const _contact: Partial<ref_Contacts> = (typeof v === "string") ?
                        { ...contact, Email: v, "@rid": undefined } :
                        (v as ContactOption)?.Contact;
                    element.Contact = _contact ?? {};
                    setContact(_contact);
                    ConsoleDebug(`onChange`, _contact);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.Contact.Email);
                    if (inputValue !== '' && !isExisting) {
                        filtered.unshift({
                            Contact: { Email: inputValue },
                            Temp: true
                        });
                    }

                    return filtered;
                }}
                handleHomeEndKeys
                clearOnBlur
                selectOnFocus
                freeSolo />
        </Grid>
        <Grid item xs={6} className="message_details_leftcombo">
            <CustomTextField
                value={contact.Job}
                label={`${TradProp("Job", ref_Contacts)} *`}
                onChange={value => contact.Job = value}
                InputLabelProps={{ shrink: false }}
            />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <CustomTextField
                value={contact.Phone}
                label={`${TradProp("Phone", ref_Contacts)} *`}
                onChange={value => contact.Phone = value}
                InputLabelProps={{ shrink: false }}
            />
        </Grid>
        <Grid item xs={6} className="message_details_leftcombo">
            <AdwAutocomplete
                value={contact.Type}
                placeholder={`${TradProp("Type", ref_Contacts)} *`}
                options={Object.keys(eContactType)}
                getOptionLabel={(o) => Trad(o)}
                onChange={(e, v: any) => {
                    contact.Type = v;
                    setContact(contact);
                    ConsoleDebug(`onChange`, contact);
                }}
            />
        </Grid>
    </>
}