import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { TooltipManager } from "../../CustomTooltip";
import { CustomIconButton } from "../Generic/CustomIconButton";
import {
    createNode,
    addChild,
    cancel,
    enterEdit,
    save,
    editField,
    setState,
    subItemsField,
    onItemChange,
    updateIDs,
    addDuplicate,
} from "../../../redux/treeSlice";
import {
    mapTree,
    TreeListTextEditorProps,
} from "@progress/kendo-react-treelist";
import { useDispatch, useSelector } from "react-redux";
import { ref_GroupsTree } from "./GroupsTab.bin";
import { Trad } from "trad-lib";
import { RootState, store } from "../../../redux/store";
import { duplicate } from "hub-lib/tools.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import * as React from "react";
import { VertexAutocomplete } from "adwone-lib";
import { Grid, TextField } from "@material-ui/core";
import { HierarchyPicker } from "../../HierarchyPicker";
import { GroupClassList, hierarchyAdvertisers } from "hub-lib/models/ref_Groups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";

export const CommandCell = ({ dataItem }: { dataItem: ref_GroupsTree }) => {
    const dispatch = useDispatch();
    return dataItem[editField] ? (
        <td>
            <CustomIconButton
                disabled={!dataItem.Name || dataItem?.Name?.length === 0 || (dataItem.parent && !dataItem["@rid"])}
                className={"custom_btn_secondary_validation"}
                onClick={() => dispatch(save({ item: dataItem, data: store.getState().tree.data, inEdit: store.getState().tree.inEdit }))}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("save") })}
            >
                {getIcon("done")}
            </CustomIconButton>
            <CustomIconButton
                disabled={false}
                className={"custom_btn_secondary_cancel"}
                onClick={() => dispatch(cancel(dataItem))}
                onMouseOver={(e) =>
                    TooltipManager.Push({
                        target: e.target,
                        text: Trad("cancel"),
                    })
                }
            >
                {getIcon("undo")}
            </CustomIconButton>
        </td>
    ) : (
        <td>
            <CustomIconButton
                disabled={dataItem.IDs === undefined}
                className={"primary_color"}
                onClick={() => dispatch(addChild(dataItem))}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("add") })}
            >
                {getIcon("plus")}
            </CustomIconButton>
            <CustomIconButton
                disabled={false}
                className={"primary_color"}
                onClick={() => dispatch(enterEdit(dataItem))}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("edit") })}
            >
                {getIcon("edit_inline")}
            </CustomIconButton>
            <CustomIconButton
                disabled={false}
                className={"primary_color"}
                onClick={() => dispatch(addDuplicate(dataItem))}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("copy") })}
            >
                {getIcon("copy_inline")}
            </CustomIconButton>
        </td>
    );
};

export const EditAdvertisersCell = (props: TreeListTextEditorProps) => {
    if (props?.dataItem?.parent) return <td />;
    const item: ref_GroupsTree = props.dataItem;
    const advertisers = item?.Area?.find((e) => e.Class === ref_Advertisers.name);

    const dispatch = useDispatch();
    const { fetchedDataAdvertiserGroups, fetchedDataAdvertisers } = useSelector(
        (root: RootState) => root.tree
    );
    return (
        <td className="no-padding no-border">
            <Grid container>
                <Grid item xs={12}>
                    <HierarchyPicker<IRid>
                        type={hierarchyAdvertisers.class}
                        filter={hierarchyAdvertisers.filter}
                        data={fetchedDataAdvertiserGroups ?? []}
                        value={(
                            advertisers?.IDs ?? fetchedDataAdvertisers.map((e) => e["@rid"].toString())
                        ).map((e: string) => ({ key: e }))}
                        onSelectedItemsChange={(values) => {
                            const selected = values.filter((v) => v["@class"] == ref_Advertisers.name);
                            dispatch(
                                updateIDs({
                                    els: selected as any,
                                    id: props.dataItem.id,
                                    area: ref_Advertisers.name,
                                })
                            );
                        }}
                        params={hierarchyAdvertisers.params}
                    />
                </Grid>
            </Grid>
        </td>
    );
};

export const EditCell = (cell) => {
    const dataItem = cell.dataItem as ref_GroupsTree;
    const { fetchedData, data, inEdit } = useSelector(
        (root: RootState) => root.tree
    );
    const parent = data.find((d) => d.id === dataItem.parent) as ref_GroupsTree;
    const dispatch = useDispatch();
    if (cell.dataItem.parent) {
        return (
            <td className="edit-cell-VertexAutocomplete">
                <VertexAutocomplete
                    disableClearable
                    label=""
                    options={fetchedData.filter((d) => !parent.IDs.includes(d["@rid"]))}
                    defaultValue={(e) => fetchedData.find((e) => e["@rid"] === dataItem["@rid"])}
                    getOptionLabel={(e) => e.Name}
                    onChange={(newElement: IRid) => {
                        const toChange = duplicate(parent);
                        const newEl = createNode({
                            el: fetchedData.find((f) => newElement["@rid"] === f["@rid"]),
                            parent: toChange.id,
                            data: fetchedData,
                            childrenKey: "IDs",
                        });
                        const oldEl = {
                            ...inEdit.find((i) => i.id === dataItem.id),
                            id: newEl.id,
                        };
                        newEl.isNew = oldEl.isNew;
                        newEl.inEdit = oldEl.inEdit;
                        toChange.IDs = [
                            ...toChange.IDs.filter((id) => id !== dataItem["@rid"]),
                            newElement["@rid"],
                        ];
                        toChange.Children = toChange.Children.map((c) => {
                            if (c.id === dataItem.id) {
                                return newEl;
                            }
                            return c;
                        });
                        dispatch(
                            setState({
                                data: mapTree(data, subItemsField, (item) =>
                                    item.id === toChange.id ? toChange : item
                                ),
                                inEdit: [
                                    ...inEdit.filter((i) => i.id !== dataItem.id),
                                    oldEl,
                                ],
                            })
                        );
                    }}
                />
            </td>
        );
    }
    return <CustomTreeListTextEditor {...cell} />;
};

let timeout = null;
const CustomTreeListTextEditor = (props: TreeListTextEditorProps) => {
    const [value, setValue] = React.useState(props.dataItem[props.field]);
    const { fetchedDataType, fetchedData } = useSelector(
        (root: RootState) => root.tree
    );
    const group = GroupClassList.find(e => e.Of.Class === fetchedDataType);
    const dispatch = useDispatch();
    return (
        <td className="no-padding no-border">
            <Grid container>
                <Grid item xs={6}>
                    <TextField
                        autoComplete='off'
                        style={{ width: '100%' }}
                        value={value}
                        variant="outlined"
                        onChange={(event) => {
                            setValue(event.target.value);
                            clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                dispatch(onItemChange({
                                    field: props.field,
                                    value: event.target.value,
                                    id: props.dataItem.id
                                }))
                            }, 500);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HierarchyPicker<IRid>
                        type={group.Hierarchy?.class ?? group.Of.Class}
                        filter={group.Hierarchy?.filter}
                        data={group.Hierarchy?.class ? undefined : fetchedData}
                        value={props.dataItem["IDs"].map((e: string) => ({"key": e}))}
                        onSelectedItemsChange={ (values) => {
                            const selected = values.filter(v => v["@class"] == fetchedDataType)
                            dispatch(updateIDs({
                                els: selected as any,
                                id: props.dataItem.id
                            }));
                        }}
                        placeholder={Trad("select-items-group")}
                        params={group.Hierarchy?.params}
                    />
                </Grid>
            </Grid>
        </td>
    );
};
