import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import * as React from 'react'
import { Trad, TradClassName, TradProp } from 'trad-lib'
import { IsDebugMode, SchedulerStorage } from '../../../utils/localstorage.bin';
import { AdwAutocomplete, VertexAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import MultipleCombo from '../../crossedTable/MultipleCombo.bin';
import { SubTitle, Element, ElementContainer } from '../Generic/Common.bin';
import { Filter } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ExportArg, ExportArgChoices, GetNewDefaultExport, ref_ExportConfigurations, ViewModeType } from 'hub-lib/models/orientdb/ref_ExportConfigurations.bin';
import { ref_TableConfigurations } from 'hub-lib/models/orientdb/ref_TableConfigurations.bin';
import { GetHashCode } from 'hub-lib/tools.bin';
import { IndicateursProvider } from 'hub-lib/IndicateursProvider';
import { eColumnType } from 'hub-lib/models/types.bin';
import { AggregateExport } from 'hub-lib/models/external.bin';

class TProps {
    filters: Partial<Filter>;
    table: ref_TableConfigurations;
    open: boolean;
    config: ref_ExportConfigurations;
    onChange: (config: ref_ExportConfigurations) => void;
    cancelAction: () => void;
    onValidate: (arg: ExportArg) => void;
}

type TState = ref_ExportConfigurations["ExportArg"];

export class ExportDialog extends React.Component<TProps, TState>{

    constructor(props: TProps) {
        super(props);

        const state: TState = { ...GetNewDefaultExport(), ...props.config.ExportArg, ...new ExportArgChoices() };
        //if (!props.config?.ExportArg?.Start) {
        state.Start = props.filters.Start;
        //}
        //if (!props.config?.ExportArg?.End) {
        state.End = props.filters.End;
        //}
        if (!state.dimensions)
            state.dimensions = props.table.CrossedtableConfig?.rowventils ?? [];

        this.state = state;
    }

    async componentDidMount() {

        let { templateName } = this.props.config?.ExportArg ?? {};
        const dimensionsChoices = (await IndicateursProvider.GetInstance().Provide())
            .filter(i => i.columnType == eColumnType.Property)
            .map(i => i.indicateur);
        const templatesChoices = (await Client.searchFiles())?.data?.map(f => f.filename) ?? [];
        if (IsDebugMode())
            console.log('templatesChoices', templatesChoices);

        if (templateName && !templatesChoices.includes(templateName))
            templateName = null;

        this.saveParams({
            ...this.props.config.ExportArg,
            dimensionsChoices,
            templatesChoices,
            templateName,
            Start: this.props?.filters?.Start ?? this.state?.Start,
            End: this.props?.filters?.End ?? this.state?.End
        });
    }

    saveParams = (state: Partial<TState>) => {
        this.setState(state as Pick<TState, any>, () => this.props.onChange({ ...this.props.config, ExportArg: this.state }));
    }

    checkBoxActivation = (label: string, checked: boolean, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
        return <FormControlLabel
            key={`${GetHashCode(label)}-${checked}`}
            style={{ marginTop: 0 }}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    name="subTotal"
                    color="primary" />
            }
            label={label} />
    }

    radioGroup = () => {
        const { ViewModeExport } = this.state;

        return <FormControl component="fieldset" style={{ width: "100%", left: "16px" }}>

            <RadioGroup value={ViewModeExport} onChange={(e) => this.saveParams({ ViewModeExport: e.target.value as ViewModeType })}>
                <FormControlLabel className="margin_top_dialog_export" value="Table" control={<Radio />} label={Trad("Table_exporttype")} />
                <FormControlLabel className="margin_top_dialog_export" value="CrossedTable" control={<Radio />} label={Trad("CrossedTable_exporttype")} />
                {/* <FormControlLabel value="SchedulerLight" control={<Radio />} label={Trad("SchedulerLight_exporttype")} /> */}
                <FormControlLabel className="margin_top_dialog_export" value="Scheduler" control={<Radio />} label={Trad("Scheduler_exporttype")} />
            </RadioGroup>
        </FormControl>
    }

    /**
     * Composant sous total (only calendrier)
     * @returns
     */
    temporalTotalComponent = () => {

        const { temporalTotal, subTotalChoices, ViewModeExport } = this.state;
        const isSubTotalChecked = (temporalTotal !== undefined);

        return <>
            {(ViewModeExport === "Scheduler" || ViewModeExport === "SchedulerLight") &&
                <ElementContainer>
                    {/**Checkbox */}
                    {this.checkBoxActivation(Trad("add_subTotal"), isSubTotalChecked,
                        () => this.saveParams({
                            temporalTotal: !isSubTotalChecked ? subTotalChoices[0] : undefined,
                            rowTotalActivated: false,
                            groupingTotalTable: false,
                        }))}

                    {temporalTotal != undefined && <VertexAutocomplete
                        key={`temporalTotal-${temporalTotal}`}
                        label={Trad("subTotalChoices")}
                        options={subTotalChoices}
                        disabled={!isSubTotalChecked}
                        disableClearable
                        needTrad
                        defaultValue={(options: any) => options.find(e => e == temporalTotal)}
                        onChange={subT => this.saveParams({ temporalTotal: subT })} />}
                </ElementContainer>}
        </>
    }

    totalPositionComponent = () => {

        const { rowTotal, rowTotalChoices, ViewModeExport } = this.state;

        return <>
            {ViewModeExport !== "Table" &&
                <MultipleCombo
                    key={`rowTotal_${rowTotal}`}
                    max={1}
                    label={Trad("rowTotalChoices")}
                    choices={rowTotalChoices}
                    transform={(str) => Trad(str)}
                    initValue={(rowTotal ? [rowTotal] : ["top"])}
                    onChange={(rowTT: ("top" | "bottom")[]) => this.saveParams({ rowTotal: rowTT[0] })} />}
        </>
    }

    rowTotalActivatedComponent = () => {

        const { ViewModeExport, rowTotalActivated } = this.state;

        return <>
            {ViewModeExport !== "Table" &&
                <>
                    {/**Checkbox */}
                    {this.checkBoxActivation(Trad("add_total_row"), rowTotalActivated,
                        () => this.saveParams({
                            rowTotalActivated: !rowTotalActivated,
                            groupingTotalTable: false,
                            temporalTotal: undefined
                        }))}
                </>}
        </>
    }

    /**
     * Activer le tableau de total des regroupements
     * @returns
     */
    groupingTotalTableComponent = () => {

        const { ViewModeExport, groupingTotalTable, hideDetailsRows } = this.state;
        return <>
            {ViewModeExport !== "Table" && !hideDetailsRows &&
                <div className="margin_top_dialog_export">
                    {/**Checkbox */}
                    {this.checkBoxActivation(Trad("add_grouping_total_table"), groupingTotalTable,
                        () => this.saveParams({
                            groupingTotalTable: !groupingTotalTable,
                            temporalTotal: undefined,
                            rowTotalActivated: false
                        }))}
                </div>}
        </>
    }

    /**
     * Checkbox pour regrouper les doublons
     * @returns
     */
    groupingRowsComponent = () => {
        const { ViewModeExport, groupingRows } = this.state;
        return <>
            {ViewModeExport == "Scheduler" &&
                <div className="margin_top_dialog_export">
                    {/**Checkbox */}
                    {this.checkBoxActivation(Trad("grouping_duplicates"), groupingRows,
                        () => this.saveParams({
                            groupingRows: !groupingRows
                        }))}
                </div>}
        </>
    }

    /**
     * Composant pour découper par onglet
     * @returns
     */
    splitTabComponent = () => {

        const { splitTab, dimensionsChoices, addSplitDimensionToTotal, ViewModeExport } = this.state;
        const isSplitTabChecked = splitTab !== undefined;

        return <>

            {/**Checkbox */}
            <div className={splitTab ? "margin_bottom_dialog_export" : ""}>
                {this.checkBoxActivation(Trad("add_splitTab"), isSplitTabChecked,
                    () => this.saveParams({ splitTab: !isSplitTabChecked ? dimensionsChoices[0] : undefined }))}
            </div>

            {splitTab && <>
                <VertexAutocomplete
                    key={`splitTab_${dimensionsChoices?.length}_${splitTab?.field}`}
                    label={Trad("splitTabChoices")}
                    options={dimensionsChoices}
                    getOptionLabel={(o) => o['name'] ?? TradProp(o.field as (keyof ref_Messages), ref_Messages)}
                    disabled={!isSplitTabChecked}
                    disableClearable
                    defaultValue={(options: any[]) => options.find(e => e?.field == splitTab?.field)}
                    onChange={splt => this.saveParams({ splitTab: splt })} />
                <div className="margin_top_dialog_export">
                    {ViewModeExport != "Table"
                        && this.checkBoxActivation(Trad("add_split_dimension_to_total"), addSplitDimensionToTotal,
                            () => this.saveParams({ addSplitDimensionToTotal: !addSplitDimensionToTotal }))}
                </div>

            </>}
        </>
    }

    /**
     * Composant sélection periode export
     * @returns
     */
    datePicker = () => {
        //const { Start, End } = this.props.filters;
        const { Start, End } = this.state;
        return <DateRangepicker
            defaultStart={Start}
            defaultStop={End}
            handleChangeDate={(e: { start: Date, end: Date }) => {
                if (e.end)
                    this.saveParams({ Start: e.start, End: e.end })
            }} />
    }

    /**
     * Choix des ventilation dans le cas d'un tableau et ou calendrier
     * @returns
     */
    linesVentilationsComponent = () => {
        const { ViewModeExport, dimensionsChoices, dimensions, hideGroupingColumns, hideDetailsRows, groupingRows } = this.state;

        return <>
            {ViewModeExport !== "Table" && <>
                {/* <Title text={Trad("lines")} /> */}
                <MultipleCombo
                    key={`dimensionsChoices_${dimensionsChoices?.join('-')}`}
                    max={2}
                    label={Trad("grouping")}

                    choices={dimensionsChoices}
                    defaultValue={{ field: "*", type: null }}
                    initValue={dimensions ?? []}
                    onChange={newDimensions => this.saveParams({
                        dimensions: newDimensions,
                        hideDetailsRows: newDimensions?.length > 0 ? hideDetailsRows : false
                    })}
                    transform={(str) => str['name'] ?? TradProp(str.field, ref_Messages)} />
                <div className="margin_top_dialog_export">
                    {this.checkBoxActivation(Trad("hide_grouping_columns"), hideGroupingColumns,
                        () => this.saveParams({ hideGroupingColumns: !hideGroupingColumns }))}
                </div>
                <div className="margin_top_dialog_export">
                    {dimensions?.length > 0 &&
                        this.checkBoxActivation(Trad("hide_details_rows"), hideDetailsRows,
                            () => this.saveParams({
                                hideDetailsRows: !hideDetailsRows,
                                groupingRows: !hideDetailsRows ? false : groupingRows
                            }))}
                </div>
            </>}
        </>
    }

    timeViewComponent = () => {
        const { ViewModeExport, timeView } = this.state;

        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <>
                <MultipleCombo
                    max={1}
                    label={Trad("time_view")}
                    choices={["day", "week"]}
                    initValue={timeView ? [timeView] : ["day"]}
                    onChange={views => {
                        const val = views[0] as ("day" | "week");
                        const st: Partial<TState> = {
                            timeView: val
                        };

                        if (val === "week" && this.state.temporalTotal === "week")
                            st.temporalTotal = "month";

                        this.saveParams(st);
                    }}
                    transform={(str) => TradClassName(str)} />
            </>}
        </>
    }

    /**
     * Limite de niveau de sous total
     * @returns
     */
    subTotalLimitComponent = () => {
        const { ViewModeExport, dimensions, totalLevel } = this.state;
        const defaultValue = "none";
        const choices = dimensions?.map(d => d.field);
        return <>
            {ViewModeExport !== "Table" && <>
                {/*<Title text={Trad("subTotal_limit")} />*/}
                <MultipleCombo
                    key={`totalLevels_${dimensions?.map(d => d.field).join(".")}`}
                    label={Trad(`subTotal_limit`)}
                    max={1}
                    choices={choices}
                    defaultValue={defaultValue}
                    initValue={[totalLevel ?? defaultValue]}
                    onChange={totalLevels =>
                        this.saveParams({ totalLevel: totalLevels[0]?.['field'] ?? totalLevels[0] ?? defaultValue })}
                    transform={(str) => str == defaultValue ? Trad(defaultValue) : TradProp(str, ref_Messages)} />
            </>}
        </>
    }

    /**
     * détails du total
     * @returns
     */
    totalDetailsComponent = () => {
        const { ViewModeExport, totalDetails } = this.state;
        const defaultValue = (new AggregateExport()).totalDetails;
        return <>
            {ViewModeExport !== "Table" && <>
                {/*<Title text={Trad("total_details")} />*/}
                <div className='adw-row'>
                    <AdwAutocomplete
                        disableClearable
                        options={["none", "numeric", "all"]}
                        value={totalDetails ?? defaultValue}
                        onChange={(event: any, value: any) => {
                            this.saveParams({ totalDetails: value ?? defaultValue })
                        }}
                        getOptionLabel={l => Trad(l)}
                        renderInput={(params) => <TextField {...params} label={Trad("total_details")} variant="outlined" />}
                    />
                </div>

                {/* <MultipleCombo
                    max={1}
                    label={Trad("total_details")}
                    choices={["none", "numeric", "all"]}
                    defaultValue={defaultValue}
                    initValue={[totalDetails ?? defaultValue]}
                    onChange={(totalDetailsSelect: any) =>
                        this.saveParams({ totalDetails: totalDetailsSelect[0] ?? defaultValue })}
                    transform={(str) => Trad(str)} /> */}
            </>}
        </>
    }

    /**
     * Appliquer le template du calendrier
     * @returns
     */
    applySchedulerUserTemplateComponent = () => {
        const { ViewModeExport, applySchedulerUserTemplate } = this.state;
        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <div className="margin_top_dialog_export">
                {this.checkBoxActivation(
                    Trad("apply_scheduler_user_template"),
                    applySchedulerUserTemplate,
                    (e) => this.saveParams({ applySchedulerUserTemplate: e.target.checked }))}
            </div>}
        </>
    }

    stretchTotalComponent = () => {
        const { ViewModeExport, stretchTotal } = this.state;
        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <div className="margin_top_dialog_export">
                {this.checkBoxActivation(
                    Trad("stretch_total_lines"),
                    stretchTotal,
                    (e) => this.saveParams({ stretchTotal: e.target.checked }))}
            </div>}
        </>
    }

    /**
     * Selection du template d'export
     * @returns
     */
    templateSelectorComponent = () => {
        const { templatesChoices, templateName } = this.state;

        return <>
            {templatesChoices?.length > 0 && <>
                <MultipleCombo
                    label={Trad("modele_template")}
                    max={1}
                    choices={templatesChoices}
                    defaultValue={Trad('default')}
                    initValue={templatesChoices.includes(templateName) ? [templateName] : null}
                    onChange={templateNames => this.saveParams({ templateName: templateNames[0] })}
                />
            </>}
        </>
    }

    /**
     * Contenu de la dialog
     * @returns
     */
    dialogContent = () => {
        const { ViewModeExport } = this.state;
        return <Grid container style={{ width: '100%' }} xs={12}>

            <Grid item xs={4}>
                <SubTitle text={Trad("export_types")} />
                {this.radioGroup()}
            </Grid>

            <Grid item xs={8}>
                {/** Paramètres de base */}
                <div className='adw-row'>
                    <SubTitle text={Trad("base_parameters_title")} />
                </div>
                <div className='adw-row'>
                    {this.datePicker()}
                </div>
                {RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && this.templateSelectorComponent()}
                {this.splitTabComponent()}
            </Grid>

            <Grid item xs={12}>

                {/** Regroupements */}
                <ElementContainer>
                    {this.linesVentilationsComponent()}
                </ElementContainer>

                <ElementContainer>
                    {/* {ViewModeExport != "Table" && <Title text={Trad("subtotal_category")} />} */}
                    {this.totalDetailsComponent()}
                    {this.subTotalLimitComponent()}
                </ElementContainer>

                {/** Paramétrage du total */}
                {/* {ViewModeExport != "Table" && <Title text={Trad("Total")} />} */}
                <Element>{this.totalPositionComponent()}</Element>
                <Element>{this.rowTotalActivatedComponent()}</Element>
                <Element>{this.groupingTotalTableComponent()}</Element>
                {this.temporalTotalComponent()}

                {/** Scheduler */}
                {/* {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <Title text={Trad("scheduler")} />} */}
                {RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && this.applySchedulerUserTemplateComponent()}
                {this.groupingRowsComponent()}
                {this.timeViewComponent()}
                {this.stretchTotalComponent()}

            </Grid>
        </Grid>
    }

    render() {
        const { onValidate } = this.props;
        const { temporalTotal: subTotal,
            splitTab,
            Start,
            End,
            ViewModeExport,
            dimensions,
            templateName,
            applySchedulerUserTemplate,
            totalLevel,
            totalDetails,
            rowTotal,
            groupingTotalTable,
            rowTotalActivated,
            timeView,
            addSplitDimensionToTotal,
            hideGroupingColumns,
            hideDetailsRows,
            groupingRows,
            stretchTotal } = this.state;

        const _addSplitDimensionToTotal = ViewModeExport == "Table" ? false : addSplitDimensionToTotal;

        return <>
            {this.dialogContent()}
            <div className="adw-row">
                <CustomButton
                    Label={Trad("formated")}
                    style={{ float: "right" }}
                    disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.formatedExport)}
                    className="custom_btn_primary"
                    onClick={() => onValidate?.({
                        temporalTotal: subTotal,
                        splitTab,
                        Start,
                        End,
                        ViewModeExport,
                        dimensions,
                        templateName: templateName === Trad('default') ? undefined : templateName,
                        applySchedulerUserTemplate,
                        schedulerUserTemplate: SchedulerStorage.get(),
                        totalLevel,
                        totalDetails,
                        rowTotal,
                        groupingTotalTable,
                        rowTotalActivated,
                        timeView,
                        addSplitDimensionToTotal: _addSplitDimensionToTotal,
                        hideGroupingColumns,
                        hideDetailsRows,
                        groupingRows,
                        stretchTotal
                    })} />
            </div>
        </>
    }
}