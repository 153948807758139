import React from 'react'
import Template from '../Template'
import { MessagesGrid } from '../../VertexGrid/Messages/MessagesGrid.bin';
import { connect } from 'react-redux';
import { selectProject } from '../../../redux/projectSlice';
import history from "../../../utils/history";

class Messages extends React.Component<any,any> {
    componentDidMount() {
        const projectRid = new URLSearchParams(window.location.search).get("project");
        if (projectRid) {
            this.props.selectProject(projectRid);
            history.replace("/messages")
        }
    }
    render() {
        return (
            <>
                <div className="grid_container">
                    <MessagesGrid/>
                </div>
            </>
        )
    }
}

export default connect(null, { selectProject })(Template(Messages));