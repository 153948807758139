import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { ListBox, ListBoxDragEvent, processListBoxDragAndDrop } from '@progress/kendo-react-listbox';
import { Indicateur, IndicateurToString } from 'adwone-engine/index.bin';
import { getIcon } from 'adwone-lib';
import { Client } from 'hub-lib/client/client.bin';
import { IndicateursProvider } from 'hub-lib/IndicateursProvider';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { indicateurBuilder, propertyBuilder, ref_SchedulerConfigurations } from 'hub-lib/models/ref_SchedulerConfigurations.bin';
import { eColumnType } from 'hub-lib/models/types.bin';
import { clone, distinctValues, duplicate, toArray, Typed } from 'hub-lib/tools.bin';
import * as React from 'react'
import { Trad, TradProp } from 'trad-lib';
import { ContainerComponent } from '../../Generic/ContainerComponent';
import { CustomIconButton } from '../../Generic/CustomIconButton';
import { TextColorEditor } from './TextStyleEditor';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Popup, Offset } from "@progress/kendo-react-popup";
import { Button } from '@progress/kendo-react-buttons';
import { CheckTargetElement, IsTargetInside, OutsideAlerter } from '../../Generic/Common.bin';
import { VertecesStyleComponent } from './VertecesStyleComponent';

export type LabelBuilderOption = {
    /** ID unique */
    Id: string,
    Name: string
} & (propertyBuilder | indicateurBuilder)

export class PropertyConfig {
    vertex: new () => any;
    params?: any;
    label?: keyof ref_Messages;
    propName?: string;
    key: keyof ref_Messages | string;
}

type TProps = {
    lines: string[];
    template: ref_SchedulerConfigurations;
    propertyConfigs: PropertyConfig[];
    validate: (template: ref_SchedulerConfigurations) => any,
    onChange: (values: { [key: string]: LabelBuilderOption[] }, startProperty: LabelBuilderOption, endProperty: LabelBuilderOption, barProperty: string) => void;
}

class LabelLinesCreatorState {
    options: LabelBuilderOption[] = null;
    availableOptions: LabelBuilderOption[] = null;
    values: { [key: string]: LabelBuilderOption[] } = null;
    draggedLine?: string;
    draggedItem = null;
    lineEditing: string = null;
    startValue: LabelBuilderOption = null;
    endValue: LabelBuilderOption = null;
    startOptions: LabelBuilderOption[] = null;
    endOptions: LabelBuilderOption[] = null;
    barProperty: string = null;
    barColorsOpen: boolean = false;
}

const LabelOptionItem = (props) => {
    let { dataItem, selected, onRemove, style, index, count, ...others } = props;
    let separatorStyle = clone(style);
    separatorStyle.fontSize = "18px";
    const liStyle: CSSProperties = {};
    if (index != count - 1)
        liStyle.marginRight = "-10px";
    return (<>
        {index != 0 &&
            <span className="label-separator" style={separatorStyle}>&#8226;</span>
        }
        <li {...others} style={liStyle}>
            <div>
                <span style={style}>{dataItem.Name}</span>
                <CustomIconButton
                    className='remove-icon'
                    onClick={() => onRemove(dataItem)}>{getIcon("close")}</CustomIconButton>
            </div>
        </li>
    </>);
};

export function indicateurToOptions(indicateur: Indicateur) {
    return Typed<LabelBuilderOption>({
        Id: IndicateurToString(indicateur),
        Type: "indicateur",
        Value: indicateur,
        Name: indicateur.name
    });
}

export class LabelLinesCreator extends React.Component<TProps, LabelLinesCreatorState> {

    constructor(props: TProps) {
        super(props);
        this.state = new LabelLinesCreatorState();
    }

    async componentDidMount() {
        // const properties: LabelBuilderOption[] = (await Client.getMetadata(ref_Messages.name))?.data?.results
        //     .filter(elm => elm.name !== "Active")
        //     .map(p => Typed<LabelBuilderOption>({
        //         Id: p.name,
        //         Type: "property",
        //         Value: p.name,
        //         Name: TradProp(p.name, ref_Messages)
        //     }));
        const indicateurs = (await IndicateursProvider.GetInstance().Provide())
            .filter(i => i.columnType === eColumnType.Property)
            .map(i => i.indicateur);

        const startIndicateurs = indicateurs.filter(i => i.field == "Start");
        const endIndicateurs = indicateurs.filter(i => i.field == "End");

        const lineIndicateurs = indicateurs.filter(i => !startIndicateurs.includes(i) && !endIndicateurs.includes(i));
        const startProperty = indicateurToOptions(startIndicateurs.find(i => !i.options));
        // const endProperty = properties.find(p => p.Id == "End");

        // const lineProperties = properties.filter(p => p != startProperty && p != endProperty && !lineIndicateurs.find(i => i?.field === p.Id));
        const lineBuilders = {};

        // const options = lineProperties.concat(lineIndicateurs.map(i => this.indicateurToOptions(i))).sort((a, b) => a.Name.localeCompare(b.Name));
        const options = lineIndicateurs.map(i => indicateurToOptions(i)).sort((a, b) => a.Name.localeCompare(b.Name));
        const startOptions = startIndicateurs.map(i => indicateurToOptions(i));
        const endOptions = endIndicateurs.map(i => indicateurToOptions(i));

        let availableOptions = duplicate(options);
        for (let line of this.props.lines) {
            lineBuilders[line] = [];
            for (const l of this.props.template[line]) {
                if ((l.Type == "property" || l.Type == "indicateur") && l.Id != "Start" && l.Id != "End") {
                    const option = options.find(o => o.Id == l.Id);
                    if (option) {
                        lineBuilders[line].push(option);
                        availableOptions = availableOptions.filter(o => o.Id != option.Id);
                    }
                }
            }
        }

        const startValue = this.props.template.StartLabel ? startOptions.find(o => o.Id == this.props.template.StartLabel["Id"]) : startProperty;
        const endValue = this.props.template.EndLabel ? endOptions.find(o => o.Id == this.props.template.EndLabel["Id"]) : null;

        this.setState({
            options: options,
            availableOptions: availableOptions,
            values: lineBuilders,
            draggedItem: {},
            startOptions,
            startValue,
            endOptions,
            endValue,
            barProperty: this.props.template.BarProperty
        })
    }

    handleDragStart = (e: ListBoxDragEvent, dragLine: string) => {
        this.setState({
            draggedLine: dragLine,
            draggedItem: e.dataItem,
        });
    };

    handleDrop = (e: ListBoxDragEvent, dropLine: string) => {
        const { state } = this;
        let result: any = processListBoxDragAndDrop(
            state.values[state.draggedLine],
            state.values[dropLine],
            state.draggedItem,
            e.dataItem,
            "Id"
        );
        state.values[state.draggedLine] = result.listBoxOneData;
        state.values[dropLine] = result.listBoxTwoData;
        this.setState({
            values: duplicate(state.values)
        });
        this.props.onChange?.(state.values, state.startValue, state.endValue, state.barProperty);
    };

    LabelOptionItemBase = (props) => {
        const { dataItem } = props;
        const item = this.state.values[dataItem.line].find(d => d.Id == dataItem.Id);
        const index = this.state.values[dataItem.line].indexOf(item);
        const style = this.GenerateStyle();

        return <LabelOptionItem
            {...props}
            index={index}
            count={this.state.values[dataItem.line].length}
            style={style}
            onRemove={(dataItem) => {
                const { availableOptions, values, startValue, endValue, barProperty } = this.state;
                const { line } = dataItem;
                values[line] = values[line].filter(o => o.Id != dataItem.Id);
                availableOptions.push(dataItem);
                this.setState({ availableOptions: duplicate(availableOptions).sort((a, b) => a.Name.localeCompare(b.Name)), values: duplicate(values) });
                this.props.onChange?.(values, startValue, endValue, barProperty);
            }} />
    }

    CreateLine = (keyLine: string) => {

        const { lineEditing, availableOptions, values, startValue, endValue, barProperty } = this.state;
        return <div className="line">
            <ListBox
                data={this.state.values[keyLine].map(v => ({ ...v, line: keyLine }))}
                textField="Name"
                item={this.LabelOptionItemBase}
                onDragStart={(e) => this.handleDragStart(e, keyLine)}
                onDrop={(e) => this.handleDrop(e, keyLine)} />
            {lineEditing == keyLine &&
                <>
                    <ComboBoxFilerable data={availableOptions}
                        textField="Name"
                        placeholder={Trad("add_label")}
                        onChange={(event) => {
                            values[keyLine].push(event.value);
                            this.setState({
                                lineEditing: null,
                                availableOptions: availableOptions.filter(o => o.Id != event.value.Id),
                                values: duplicate(values)
                            });
                            this.props.onChange?.(values, startValue, endValue, barProperty);
                        }}
                    />

                    <CustomIconButton
                        className='picto small'
                        onClick={() => {
                            this.setState({ lineEditing: null });
                        }}>{getIcon("close")}</CustomIconButton>
                </>}
            {lineEditing == null &&
                <>
                    <CustomIconButton
                        className='primary_color picto'
                        onClick={() => {
                            this.setState({ lineEditing: keyLine });
                        }}>{getIcon("plus")}
                        {!this.state.values[keyLine]?.length &&
                            <span style={{ fontSize: "1rem" }}>{Trad("add_label")}</span>
                        }
                    </CustomIconButton>
                </>
            }
        </div>;
    }

    GenerateStyle(position?: 'left' | 'right' | "bottom") {
        const { template } = this.props;
        const style: React.CSSProperties = { fontSize: "1rem" };
        let color = "rgba(0, 0, 0, 0.54)";
        if (template.StyleDefault.textStyle?.color?.code)
            color = template.StyleDefault.textStyle.color.code;

        style.color = color;
        if (position == 'bottom') {
            style.height = "1.5rem";
            style.borderBottom = `2px solid ${color}`;
        }

        return style;
    }

    render() {
        const { values, startOptions, endOptions, startValue, endValue, barProperty } = this.state;
        const builders = [];
        const barProperties = this.props.propertyConfigs.map(e => ({ key: e.key, label: (e.label && TradProp(e.label)) ?? Trad(e.vertex.name) }));
        console.log("template", this.props.template);
        if (values)
            for (let line of this.props.lines)
                if (values[line])
                    builders.push(this.CreateLine(line));
        return <ContainerComponent title="Libellés" className="configuration-sheduler-labels" >
            <div>
                {startOptions && endOptions &&
                    <>
                        <TextColorEditor
                            template={this.props.template}
                            onChange={() => this.props.onChange?.(values, startValue, endValue, barProperty)} />
                        <div className="bar-label" style={this.GenerateStyle('bottom')}>
                            <div style={{ float: "left", width: '30%', display: "table" }}>
                                <ComboBox data={startOptions}
                                    defaultValue={startValue}
                                    textField="Name"
                                    className='inputLeft'
                                    style={this.GenerateStyle()}
                                    clearButton={false}
                                    onChange={(event) => {
                                        this.setState({ startValue: event.value });
                                        this.props.onChange?.(values, event.value, endValue, barProperty);
                                    }}
                                />
                            </div>
                            <div style={{ float: "right", display: "flex", width: '40%', textAlign: 'right', justifyContent: 'flex-end' }}>
                                <ComboBox data={barProperties}
                                    placeholder={Trad("property")}
                                    className='inputRight barProperty'
                                    defaultValue={barProperty ? barProperties.find(p => p.key == barProperty) : null}
                                    dataItemKey='key'
                                    textField='label'
                                    onChange={(event) => {
                                        this.setState({ barProperty: event.value?.key });
                                        this.props.onChange?.(values, startValue, endValue, event.value?.key);
                                    }}
                                />
                                {barProperty && <ColorBarSelector
                                    config={this.props.propertyConfigs.find(p => p.key == barProperty)}
                                    template={this.props.template}
                                    validate={this.props.validate} />}
                            </div>
                            <div style={{ float: "right", display: "table", width: '30%', textAlign: 'right' }}>
                                <ComboBox data={endOptions}
                                    defaultValue={endValue}
                                    placeholder={TradProp("End")}
                                    className='inputRight'
                                    style={this.GenerateStyle()}
                                    textField="Name"
                                    onChange={(event) => {
                                        this.setState({ endValue: event.value });
                                        this.props.onChange?.(values, startValue, event.value, barProperty);
                                    }}
                                />
                            </div>

                        </div>
                    </>
                }
                {builders}
            </div>
        </ContainerComponent>
    };
}

type ColorBarSelectorProps = {
    config: PropertyConfig;
    template: ref_SchedulerConfigurations;
    validate: (template: ref_SchedulerConfigurations) => any,
}

const colorBarPopupClassName = `colorBarPopup`;

function ColorBarSelector(props: ColorBarSelectorProps) {
    const [colorBarOpen, setColorBarOpen] = React.useState<boolean>(false);
    const [popupOffset, setPopupOffset] = React.useState<Offset>(null);

    const wrapperRef = React.useRef(null);

    const handleOnClick = (e: any) => {
        setColorBarOpen(!colorBarOpen);
        const button = document.getElementById(`colorBarButton`);
        const position = button.getBoundingClientRect();
        console.log("button", position);
        setPopupOffset({ top: position.top + position.height, left: position.left + position.width - 600 })
        e.stopPropagation();
    }

    return <>
        <Button onClick={handleOnClick} id='colorBarButton'
            className="inputRight no-radius picto">
            {getIcon('edit_inline')}
        </Button>
        <Popup offset={popupOffset} show={colorBarOpen} ref={wrapperRef} style={{ width: 600 }} className={colorBarPopupClassName}>
            {props.config && <VertecesStyleComponent
                vertex={props.config.vertex}
                propName={props.config.propName}
                params={props.config.params ?? {}}
                propKey={props.config.key}
                template={props.template}
                onChange={() => props.validate(props.template)} />}
        </Popup>
        {colorBarOpen && <OutsideAlerter wrapperRef={wrapperRef} callback={target => {
            const element = document.querySelector(`.${colorBarPopupClassName}`);
            const found = CheckTargetElement(target, (e) => {
                const classes = Array.from(e?.classList ?? []) as string[];
                return classes?.some(c => c.includes('colorpicker'));
            })

            if (!IsTargetInside(element, target) && !found)
                setColorBarOpen(false);
        }} />}
    </>
}

function ComboBoxFilerable(props: ComboBox['props']) {

    const [filter, setFilter] = React.useState<FilterDescriptor>();

    const filterData = () => {
        if (!filter)
            return props.data;
        const data = (props.data ?? []).slice();
        return filterBy(data, filter);
    };

    return <ComboBox
        {...props}
        data={filterData()}
        filterable
        onFilterChange={(event: ComboBoxFilterChangeEvent) => {
            setFilter(event.filter);
        }}
    />
}