import { ref_Supports } from "./orientdb/ref_Supports.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { lnk_HasBroadcastArea } from "hub-lib/models/orientdb/lnk_HasBroadcastArea.bin";

export class SupportExtended extends ref_Supports {
    lnkCurrencies: (lnk_HasCurrency & { outName?: string })[];
    lnkBroadcastAreas: (lnk_HasBroadcastArea & { outName?: string })[];
    lnkAdvertisingCompanies: (lnk_AdvertisingCompanySupport & { inName?: string })[];

    coverUrl?: string;
}