import * as React from "react";

import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";

import { VertexGrid } from "../Generic/VertexGrid.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ADWColumn, AdwRow, eComputeEvent, IItemRow } from "adwone-lib/index";
import { duplicate, hasConfig, propertyOf } from "hub-lib/tools.bin";
import { Trad, TradProp } from "trad-lib";
import Loader from "../../layout/Loader";
import { SortDescriptor } from "@progress/kendo-data-query";
import { CurrenciesFilters } from "./CurrenciesFilters";
import { GetCellTemplate, GetOrder, GetSort } from 'format-lib/index.bin';
import { PopupCurrencies } from "../../layout/PurchaseSettings/CurrenciesDialog.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { lnk_HasCurrency } from 'hub-lib/models/orientdb/lnk_HasCurrency.bin'
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { lnk_ChangeRate } from "hub-lib/models/lnk_ChangeRate.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { Notify } from "../../../utils/Notify.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { StyleFactory } from "../../../utils/ToolsReact";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, SelectedItemsContainer, ToolbarAdw, ToolbarContainer } from "../Generic/ToolbarAdw";
import { ConfigurationPanel } from "../Messages/ConfigurationPanel";
import { IconButton } from "@material-ui/core";
import { getIcon } from "adwone-lib/index";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { CustomBadge } from "../Adwone-admin/Referential/BadgesUsage.bin";
import { TooltipManager } from "../../CustomTooltip";
import { Badge } from "@progress/kendo-react-indicators";
import { TableExport } from "hub-lib/models/external.bin";
import { lnk_ChangeRateExtended } from "hub-lib/models/custom/lnk_ChangeRateExtended";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { eIndicateurType } from "adwone-engine/index.bin";
import { FiltersChangeTrigger } from "../Filters/FiltersChangeTrigger";
import { UserInfos } from "../../layout/Navigation/UserInfos.bin";

const styles = StyleFactory.create({
  mainContainer: { width: '100%' },
  toolbar: { float: 'left', marginTop: 20 },
  exportGroupButtons: { float: 'right' }
});

export type ChangeRateConfig = {
  /** inverse le mode de saisie d'un taux de change */
  reverse?: boolean,
  Company: rid,
  date?: {
    start: Date,
    end: Date
  }
  Start?: Date;
  End?: Date;
}

type TProps = {
  config: ChangeRateConfig,
  onConfChange: () => any
}

class TState {
  grid?: VertexGrid<lnk_ChangeRate>;
  adwGrid?: AdwTelerikGrid<lnk_ChangeRate>;
  sort?: SortDescriptor[];
  currencies?: ref_Currencies[];
  selectedItems: IItemRow<lnk_ChangeRate>[];
  totalItems: number;
}

export class CurrenciesGrid extends React.Component<TProps, TState> {

  constructor(props: TProps) {
    super(props);
    Client.log({ Category: "navigation", Action: lnk_ChangeRate.name });
    this.state = { selectedItems: [], totalItems: 0 };
  }

  async componentDidMount() {
    let hiddenProperties: string[] = [];

    const currencies = (await Client.searchVertex(ref_Currencies.name))?.data?.results;

    /**  COLONNE ANNONCEUR  */
    const bindingPath = propertyOf<lnk_ChangeRate>("Company");
    const columnAdv = new ADWColumn<lnk_ChangeRate>(Trad("advertiser"), bindingPath, ePropType.Link, true);

    // columnUpdatedBy.cellValue = (cellvalue, dataItem)=> {
    //   console.log(dataItem.dataItem)
    // }
    columnAdv.cellValue = (cellValue: any, dataItem?: AdwRow<lnk_ChangeRate>) => {
      if (cellValue === this.props.config.Company) return Trad("all");
      return (dataItem.dataItem as any).CompanyName;
    }

    /** COLONNE TAUX */
    const columnRate = new ADWColumn<lnk_ChangeRate>(TradProp("Rate", lnk_ChangeRate), propertyOf<lnk_ChangeRate>("Rate"), ePropType.Decimal, true);
    columnRate.cellValue = (cellValue: any, dataItem?: AdwRow<lnk_ChangeRate>) => {

      const from = currencies?.find(c => c["@rid"] == dataItem?.dataItem?.in)?.Code;
      const to = currencies?.find(c => c["@rid"] == dataItem?.dataItem?.out)?.Code;

      const templ = GetCellTemplate(columnRate.dataType);
      const res = templ(cellValue);

      return <div>
        <div style={{ float: 'left' }}>{`1${!this.props.config.reverse ? from : to} =`}</div>
        <div style={{ float: 'right' }}>{!this.props.config.reverse ? to : from}</div>
        <div style={{ float: 'right' }}>{res}</div>
      </div>
    }

    hiddenProperties.push(propertyOf<lnk_ChangeRate>("Company"))
    hiddenProperties.push(propertyOf<lnk_ChangeRate>("Rate"))

    columnRate.getValue = (row: lnk_ChangeRate) =>
      !this.props.config.reverse ? row.Rate : (1 / row.Rate);

    let grid = new VertexGrid<lnk_ChangeRate>({
      onUpdateStarting: () => {
        Notify(Trad("change_rate_in_progress"), "info");
      },
      objectPrototype: lnk_ChangeRate,
      devMode: false,
      columns: [columnAdv, columnRate],
      hiddenProperties,
      readOnlyProperties : ["Updated_at", "Updated_by", "Created_at", "Created_by"],
      vertexParams: {
        Active: true,
        properties: ["*", "Company.Name as CompanyName"],
        ...this.props.config
      },
      titles: {
        in: Trad("property_in_Currency"),
      },
      order: GetOrder<lnk_ChangeRate>(lnk_ChangeRate),
      columnParams: {
        out: { editable: false }
      },
      width: {
        Company: 400,
        in: 150,
        out: 150,
        Rate: 150,
        Start: 150,
        End: 150
      },
      rowToVertex: (row, res: lnk_ChangeRate) => {
        if (this.props.config.reverse) {
          res.Rate = 1 / row.Rate;
        }
      }
    });

    let sort: SortDescriptor[] = GetSort<lnk_ChangeRate>(lnk_ChangeRate);
    // Override pour filtrer
    const storeFunc = grid.GetStoredData.bind(grid);
    grid.GetStoredData = async (row: any, bdp: string) => {
      let data = await storeFunc(row, bdp);

      if (bdp === "in") {
        data = data?.filter(d => d["@rid"] !== row["out"]);
      }

      return data;
    }

    grid.onChange = async (row: AdwRow<lnk_ChangeRate>, field: string) => {
      if (field !== propertyOf<lnk_ChangeRate>("Company")) return;
      try {
        const companyRids = [row["Company"]];
        if (this.props.config.Company != row["Company"])
          companyRids.push(this.props.config.Company);
        //Recherche de la devise restituée sur la company (groupe ou annonceur)
        const links = await Client.searchVertexTyped(lnk_HasCurrency, { in: companyRids });
        row["out"] = links.find(l => l.in == row["Company"])?.out;
        if (!row["out"])
          row["out"] = links.find(l => l.in == this.props.config.Company)?.out;

        row.event.emit(eComputeEvent.forceUpdate, { field: "out" });
      } catch (error) {
        console.error(error)
      }
    }
    this.setState({ currencies, grid, sort }, () => this.updateComponent(false));
  }

  updateComponent = async (updateRows: boolean = true) => {
    this.props.onConfChange();
    const newParams = duplicate(this.props.config);
    const dates = newParams.date;
    if (dates) {
      if (!newParams.Start && dates.start) (newParams as any).Start = dates.start;
      if (!newParams.End && dates.end) (newParams as any).End = dates.end;
      delete newParams.date;
    }

    const advertisers = (await Client.searchVertex(ref_Advertisers.name, { parents: [this.props.config.Company] }))?.data?.results;
    const bindingPath = propertyOf<lnk_ChangeRate>("Company");
    this.state.grid.store[bindingPath] = [{ Name: Trad("all"), "@rid": this.props.config.Company }, ...advertisers];
    let vertexParams = this.state.grid.props.vertexParams;
    this.state.grid.props.vertexParams = { ...vertexParams, ...newParams };
    if (updateRows)
      this.state.grid.UpdateRows();
  }

  render() {

    const { config } = this.props;
    const { selectedItems, totalItems, grid, sort } = this.state;

    if (!grid) return <Loader />;

    const gridComponent = <AdwTelerikGrid
      onRef={ref => this.setState({ adwGrid: ref })}
      sort={sort}
      grid={grid}
      isCopyDisable={!RightManager.hasRight(eFunctions.lnk_ChangeRate, [eRights.create, eRights.update])}
      commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.update) }}
      selectable
      selectionChange={rows => this.setState({ selectedItems: rows })}
      onRowInitialized={(rows) => {
        const selectedRows = rows?.filter(r => r.selected) ?? [];
        this.setState({ selectedItems: selectedRows, totalItems: rows?.length })
      }}
      hideToolbar
    >
    </AdwTelerikGrid>

    const confComponent = <ConfigurationPanel
      elements={[{
        type: "component",
        component: <IconButton
          onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("create_changerate") })}
          disabled={!RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.create)}
          className="custom_btn_primary no-radius no-shadow icon-panel-item"
          onClick={() => { this.state.adwGrid.addNew() }}>
          {getIcon("plus")}
        </IconButton>
      }, {
        type: "icon",
        title: () => Trad("filters"),
        icon: "filterAlt",
        badge: hasConfig(config, ["date", "filtre"]) ? <Badge cutoutBorder align={{ vertical: "top", horizontal: "start" }} /> : null,
        element: <CurrenciesFilters config={config} onConfChange={() => this.updateComponent()} />
      }, {
        type: "component",
        component: <PopupCurrencies />
      }, {
        type: "icon",
        title: () => Trad("export"),
        icon: "download",
        element: <>
          <div className="adw-row">
            <CustomButton
              Label={Trad("CSV")}
              style={{ float: "right" }}
              disabled={!RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.export)}
              className="custom_btn_primary"
              onClick={() => {
                const arg: TableExport = {
                  type: "table",
                  document: lnk_ChangeRateExtended.name,
                  filter: grid.props.vertexParams,
                  columns: [
                    { type: eIndicateurType.info, field: propertyOf<lnk_ChangeRateExtended>("AdvertiserGroup"), name: TradProp("AdvertiserGroup", lnk_ChangeRateExtended), valueType: eKPIType.Rid },
                    { type: eIndicateurType.info, field: propertyOf<lnk_ChangeRateExtended>("Advertiser"), name: TradProp("Advertiser", lnk_ChangeRateExtended), valueType: eKPIType.Rid },
                    ...grid.Columns.map(c => c.baseColumn).filter(c => c && c.field != propertyOf<lnk_ChangeRate>("Company"))]
                }
                Client.downloadExport("csv", arg, Trad(lnk_ChangeRate.name));
              }} />
          </div></>
      }
      ]} />

    const deleteButton = <CustomButton
      Label={Trad("delete")}
      disabled={!RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.delete)}
      style={{ float: 'right' }}
      className="custom_btn_danger"
      startIcon={getIcon("delete")}
      onClick={() => { this.state.adwGrid.baseToggleRemoveDialog(); this.setState({ selectedItems: [] }); }} />

    return (
      <div style={styles.mainContainer}>
        <FiltersChangeTrigger onChange={(data) => {
          this.props.config.Start = new Date(data.Start);
          this.props.config.End = new Date(data.End);
          this.updateComponent();
        }} />
        <ToolbarAdw
          className='currencies-toolbar'
          includeFilters
          hasSelectedItems={selectedItems?.length > 0}
          count={totalItems}>

          <ToolbarContainer>
            {selectedItems?.length > 0 && <SelectedItemsContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="margin-right">
                  <CustomBadge count={this.state.selectedItems.length} icon={"currencies"} tradClassKey={lnk_ChangeRate.name} />
                </div>
                {deleteButton}
              </div>
            </SelectedItemsContainer>
            }

            <BreadcrumbsCustomContainer>
              <BreadcrumbsCustom
                hasSelectedItems={selectedItems?.length > 0}
                elements={[
                  { text: Trad("home"), href: "/" },
                  { text: Trad("currency_and_change_rate") }
                ]} />
            </BreadcrumbsCustomContainer>
          </ToolbarContainer>

          <ConfigurationPanelContainer>
            {confComponent}
          </ConfigurationPanelContainer>

        </ToolbarAdw>
        {gridComponent}
      </div>
    )
  }
}