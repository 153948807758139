import React from 'react'
import Template from '../Template'

import { CampaignsGrid } from '../../VertexGrid/Campaigns/CampaignsGrid.bin';

const Campagnes = () => {
    return (
        <>
            <div className="grid_container">
                <CampaignsGrid />
            </div>
        </>

    );
}

export default Template(Campagnes);
