import { Referentials, ReferentialsId } from './Referentials.bin';
import { V, VId } from './V.bin';
import { rid } from './CommonTypes.bin';

export type ref_BrandsId = rid;

export class ref_Brands extends V implements Referentials {
    '@rid'?: ref_BrandsId;
    End: Date;
    Name: string;
    Start: Date;
    Active: boolean;
    parent?: any;
    import_upsertDate?: Date;
}
