import { PivotGridProps, PivotLocalDataServiceArgs } from '@progress/kendo-react-pivotgrid';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Indicateur } from 'adwone-engine/index.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { IndexCells, InitPivotGridArgs } from '../components/VertexGrid/Messages/PivotGrid/InitPivotGridArgs';
import { RootState } from './store';


type DicoCross = {
    [indicateurStr: string]: {
        [value: string]: ref_Messages[]
    }
};
export interface TcdState {
    loading: boolean;
    dicoRowCross: DicoCross;
    dicoColCross: DicoCross;
    filters: any;
    serviceArgs: PivotLocalDataServiceArgs;
    rows: Indicateur[];
    columns: Indicateur[];
    kpis: Indicateur[];
    //props: PivotGridProps;
}

const initialState: TcdState = {
    loading: false,
    dicoRowCross: {},
    dicoColCross: {},
    filters: null,
    serviceArgs: null,
    rows: [],
    columns: [],
    kpis: localStorage.getItem('kpis-tcd') ? JSON.parse(localStorage.getItem('kpis-tcd')) : []
}

type setRowsColsArgs = { filters: any, rows: Indicateur[], columns: Indicateur[] }
export const initTCD = createAsyncThunk(
    'tcd/setRowsCols',
    async ({ filters, rows, columns }: setRowsColsArgs, thunkAPI) => {
        const { service, dicoColCross, dicoRowCross } = await InitPivotGridArgs(filters, { rows, columns });
        return { service, rows, columns, filters, dicoColCross, dicoRowCross };
    }
)

export const setColumns = createAsyncThunk(
    'tcd/setColumns',
    async (columns: Indicateur[], thunkAPI) => {
        const { rows, serviceArgs } = (thunkAPI.getState() as RootState).tcd;
        return { columns, ...await IndexCells(columns, rows, serviceArgs.data) };
    }
)

export const setRows = createAsyncThunk(
    'tcd/setRows',
    async (rows: Indicateur[], thunkAPI) => {
        const { columns, serviceArgs } = (thunkAPI.getState() as RootState).tcd;
        return { rows, ...await IndexCells(columns, rows, serviceArgs.data) };
    }
)

export const setKPIs = createAsyncThunk(
    'tcd/setKPIs',
    async (kpis: Indicateur[], thunkAPI) => {
        const { columns, rows, serviceArgs } = (thunkAPI.getState() as RootState).tcd;
        localStorage.setItem('kpis-tcd', JSON.stringify(kpis ?? {}));
        return { kpis, ...await IndexCells(columns, rows, serviceArgs.data) };
    }
)

export const tcdSlice = createSlice({
    name: 'tcd',
    initialState,
    reducers: {
        setTCDArgs: (state, action: PayloadAction<{
            service: PivotLocalDataServiceArgs,
            dicoRowCross: TcdState['dicoRowCross'],
            dicoColCross: TcdState['dicoColCross']
        }>) => {
            state.serviceArgs = action.payload.service;
            state.dicoRowCross = action.payload.dicoRowCross;
            state.dicoColCross = action.payload.dicoColCross;
        },
        // setColumns: (state, action: PayloadAction<Indicateur[]>) => {
        //     state.columns = action.payload;
        // },
        // setRows: (state, action: PayloadAction<Indicateur[]>) => {
        //     state.rows = action.payload;
        // },
        // setKPIs: (state, action: PayloadAction<Indicateur[]>) => {
        //     state.kpis = action.payload;
        //     localStorage.setItem('kpis-tcd', JSON.stringify(action.payload ?? {}));
        // }
    },
    extraReducers: (builder) => {

        const setLoading = (value: boolean) => ((state, action) => { state.loading = value });
        const setPayloadProperties = (state, action) => {
            Object.keys(action.payload).forEach(key => {
                state[key] = action.payload[key];
            })
            state.loading = false;
        };

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(initTCD.fulfilled, (state, action) => {
            state.serviceArgs = action.payload.service;
            state.rows = action.payload.rows;
            state.columns = action.payload.columns;
            state.dicoColCross = action.payload.dicoColCross;
            state.dicoRowCross = action.payload.dicoRowCross;
            state.loading = false;
        })
        builder.addCase(initTCD.pending, setLoading(true));
        builder.addCase(initTCD.rejected, setLoading(false));

        /** Columns */
        builder.addCase(setColumns.fulfilled, setPayloadProperties);
        builder.addCase(setColumns.pending, setLoading(true));
        builder.addCase(setColumns.rejected, setLoading(false));

        /** Rows */
        builder.addCase(setRows.fulfilled, setPayloadProperties);
        builder.addCase(setRows.pending, setLoading(true));
        builder.addCase(setRows.rejected, setLoading(false));

        /** KPIs */
        builder.addCase(setKPIs.fulfilled, setPayloadProperties);
        builder.addCase(setKPIs.pending, setLoading(true));
        builder.addCase(setKPIs.rejected, setLoading(false));
    },
})

export const { setTCDArgs } = tcdSlice.actions
export const tcdReducer = tcdSlice.reducer;