
import { NumericTextBox, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import * as React from 'react';
import { SelectorDiscountComputation } from './SelectorDiscountComputation';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { GetCellTemplate, GetNumericFormat, GetNumericStep } from 'format-lib/index.bin';
import { AgreementValueComparer } from '../../../discounts/AgreementValueComparer';
import { store } from '../../../../../../redux/store';
import { setMessage } from '../../../../../../redux/messageEditorSlice';

type AmountCellComponentProps = { discount: ref_Discount };
export function AmountCellComponent({ discount }: AmountCellComponentProps) {

    const onChange = (newVal: number) => {
        const message = store.getState().messageEditor.getMessage();
        const netType = store.getState().messageEditor.currentNetType;

        const d = message.Discounts.find(d => d.DiscountClass == discount.DiscountClass);
        d.IsRate = false;
        if (!d[netType])
            d[netType] = { Rate: 0, Value: 0 };
        d[netType].Value = newVal;
        if (d.IsCPM) {
            d.IsCPM = false;
            delete d.ValueCPM;
            delete d.KpiCPM;
        }
        store.dispatch(setMessage(message))
    }

    const opt = { type: ePropType.Double, minmax: true, color: true, applyOperator: true }
    const editable = true;

    const { computation, value } = SelectorDiscountComputation(discount.DiscountClass);
    const amount = value?.Value ?? 0;

    const [isEditing, setIsEditing] = React.useState(false);
    const ref = React.createRef<NumericTextBoxHandle>();

    React.useEffect(() => {
        if (isEditing) {
            ref.current.focus();
            ref.current.element.select();
        }
    }, [isEditing])

    const minMax: { min?: number, max?: number } = {};

    // always > 0 when edit
    if (opt.minmax)
        minMax.min = 0;
    // if (opt.min)
    //     minMax.min = opt.min;
    // if (opt.max)
    //     minMax.max = opt.max;

    if (editable && isEditing) {
        let id = `amount_${discount.DiscountClass}`;
        return (<div
            className="k-grid-edit-cell gray-font"
            onKeyDown={e => {
                if (e.code == "Enter" || e.code == "NumpadEnter")
                    setIsEditing(false);
            }}>
            <NumericTextBox
                ref={ref}
                className={'CellNumberComponent-editing-cell'}
                spinners={true}
                id={id}
                step={GetNumericStep(opt.type)}
                {...minMax}
                format={GetNumericFormat(opt.type)}
                value={amount}
                onBlur={() => setIsEditing(false)}
                onChange={(e) => onChange(Math.abs(e.value))} />
        </div>);
    }

    let valueNbr = Math.abs(amount ?? 0);
    if (valueNbr !== 0 && opt.applyOperator)
        valueNbr *= discount.Operator;

    const template = GetCellTemplate(opt.type);
    let str = `${template(valueNbr)}`;
    // if (currency)
    //     str += ` ${currency}`

    const star = opt?.color && computation?.Discount?.IsRate;
    const className = star ? "gray-font" : "";

    return <div className={className + " k-grid-edit-cell " + ` ${editable ? 'editable-cell-discount' : ''} `} style={{ textAlign: 'right', position: 'relative' }}
        onClick={() => {
            if (!editable)
                return;
            //setStateValue(Math.abs(value));
            setIsEditing(true);
        }}>

        {computation
            && <AgreementValueComparer
                type="amount"
                discount={computation.Discount}
                formater={template} />}

        {star && <div className="star_cell" style={{ marginRight: -12 }}>*</div>}
        {str}
    </div>
}