import * as React from 'react';


import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { getIcon } from "adwone-lib/index";
import { CustomIconButton } from '../VertexGrid/Generic/CustomIconButton';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';

class TState {
    anchorEl: Element | null;
}
export class TProps {
    anchorParent?: string;
    children?: React.ReactElement;
    icon?: string;
    count?: number;
    style?: React.CSSProperties;
}

export class GenericPopover extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);
        const newState = new TState()
        newState.anchorEl = null
        this.state = newState
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.anchorParent) {
            this.setState({
                anchorEl: document.getElementById(this.props.anchorParent)
            })
        } else {
            this.setState({
                anchorEl: event.currentTarget
            })
        }
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    render() {
        const { icon, count, style } = this.props;
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'GenericPopover' : undefined;
        return (
            <div onClick={e => e.stopPropagation()}>
                <CustomIconButton aria-describedby={id} className="custom_btn_secondary popover_button" onClick={this.handleClick}>
                    <BadgeContainer style={{display: "flex"}}>
                        { getIcon(icon ?? "settings")}
                        {count > 0 && <Badge>{count > 99 ? "99+" : count}</Badge>}
                    </BadgeContainer>
                </CustomIconButton>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: { maxHeight: '85%', ...style },
                    }}
                >
                    <div style={{ padding: 10 }}>
                        {this.props.children}
                    </div>

                </Popover>
            </div>
        );
    }
}