import * as React from "react";
import { TradClassName } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { Client } from "hub-lib/client/client.bin";
import { Filter } from 'hub-lib/models/orientdb/ref_FilterConfigurations.bin';
import { clone, compareObjects } from "hub-lib/tools.bin";
import { ModeleCreatorBase } from "../../ModeleCreator/ModeleCreatorBase";
import { SchedulerStorage } from "../../../../utils/localstorage.bin";
import { ExportArg, ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { ExportDialog } from "../ExportDialog.bin";
import { AggregateExport } from "hub-lib/models/external.bin";
import { CrossedTableConfigurable } from "../../../ConfigurableComponents/CrossedTableConfigurable.bin";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { setExport } from "../../../../redux/projectSlice";

type ExportConfigurationArgs = {
    filters: Partial<Filter>,
    open: boolean;
    close: () => void;
    generateConfigCrossedTable: () => Promise<any>;
    grid: VertexGrid<ref_Messages>;
}

export function ExportConfiguration({ filters, open, close, generateConfigCrossedTable, grid }: ExportConfigurationArgs) {

    const config = clone(useSelector((root: RootState) => root.project.exportModele, compareObjects));

    return (
        <>
            <ModeleCreatorBase
                objectType={ref_ExportConfigurations}
                onChange={(config) => {
                    store.dispatch(setExport(config));
                }}
                modele={config}
                type={ref_Messages.name}
            />
            <ExportDialog
                key={config["@rid"]}
                config={config}
                onChange={(newConfig) => {
                    store.dispatch(setExport(newConfig));
                }}
                filters={filters}
                table={grid.props.configuration}
                open={open}
                cancelAction={() => close()}
                onValidate={async (args: ExportArg) => {
                    const { ViewModeExport } = args;

                    if (ViewModeExport === "Table") {
                        args['headerFilters'] = store.getState().gridMessages.gridFilter;
                        args['sort'] = store.getState().gridMessages.sort;
                        grid.exportExcel("formated", args);
                    }
                    else {
                        const confCT = await generateConfigCrossedTable();
                        const arg: AggregateExport = { ...CrossedTableConfigurable.getAggregatedArgsForExports(confCT), ...args };
                        arg.ViewModeExport = ViewModeExport === "SchedulerLight" ? "Scheduler" : ViewModeExport;
                        arg.headerFilters = store.getState().gridMessages.gridFilter;
                        arg.sort = store.getState().gridMessages.sort;

                        if (ViewModeExport === "SchedulerLight")
                            arg.columns = [];
                        Client.downloadExport("formated", arg, TradClassName(ref_Messages.name));
                    }
                    close();
                }} />
        </>
    )
}