import { IRid } from "hub-lib/models/IRid.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { vw_mm_HasBroadcastArea } from "hub-lib/models/orientdb/vw_mm_HasBroadcastArea.bin";
import { vw_mm_HasSupport } from "hub-lib/models/orientdb/vw_mm_HasSupport.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import * as React from "react"
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initBroadcastOptions, setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { CheckElement } from "./CheckElement";
import { WarningMM } from "./WarningMM";

type BroadcastAreaEditorArgs = { onChange?: () => void }

export function BroadcastAreaEditor({ onChange }: BroadcastAreaEditorArgs) {

    const dispatch = useDispatch();
    const BroadcastArea = useSelector((root: RootState) => root.messageEditor.getMessage().BroadcastArea);
    const Support = useSelector((root: RootState) => root.messageEditor.getMessage().Support);
    const broadcastOptions = useSelector((root: RootState) => root.messageEditor.broadcastOptions);
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);

    React.useEffect(() => {
        if (broadcastOptions === undefined)
            dispatch(initBroadcastOptions(undefined));
    }, [broadcastOptions])

    const dispatchChanged = (broadcastArea: IRid) => {
        const message = store.getState().messageEditor.getMessage();
        if (message.BroadcastArea != broadcastArea?.["@rid"]) {
            message.BroadcastArea = broadcastArea?.["@rid"];
            store.dispatch(setMessage(message)).then(() => onChange?.());
        }
        return broadcastArea;
    }

    return <WarningMM
        cross
        ids={[
            { id: Support, linkClass: vw_mm_HasSupport.name },
            { id: BroadcastArea, linkClass: vw_mm_HasBroadcastArea.name }
        ]}>
        <FavoriteVertexAutoComplete
            key={`BroadcastAreaEditor-${GetHashCode({ broadcastOptions, BroadcastArea })}`}
            disableClearable
            disabled={lockNext}
            loading={lockNext}
            type={ref_BroadcastAreas.name}
            refreshOnPropChanged
            label={TradProp("BroadcastArea", ref_Messages) + " *"}
            options={broadcastOptions ?? []}
            defaultValue={(options: IRid[]) => CheckElement(options?.find?.(o => o["@rid"] == BroadcastArea), BroadcastArea, ref_BroadcastAreas.name)}
            onChange={async (broadcastArea: IRid) => {
                dispatchChanged(broadcastArea);
            }} />
    </WarningMM>
}