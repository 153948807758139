import * as React from "react";
import { compareObjects, hasConfig } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { Badge, BadgeProps } from "@progress/kendo-react-indicators";
import { FiltersComponent, FiltersComponentProps } from "./FiltersComponent";
import { ElementArg } from "../Messages/ConfigurationPanel";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export function MenuItemFilters(options?: FiltersComponentProps): ElementArg {
    return {
        type: "icon",
        title: () => Trad("filters"),
        icon: "filterAlt",
        badge: <FilterBadge />,
        element: <FiltersComponent {...options} />
    }
}

export function CustomBadge(props?: Partial<BadgeProps>) {
    return <Badge cutoutBorder {...props} align={{ vertical: "top", horizontal: "start" }} />;
}

export function FilterBadge() {
    const modeleFilters = useSelector((root: RootState) => root.project.filtersModele, compareObjects);
    const filters = modeleFilters?.Filters;
    return hasConfig(filters) ? <CustomBadge /> : null;
}