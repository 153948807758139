import * as React from "react"
import Button from '@material-ui/core/Button'
import { Trad, TradProp } from "trad-lib"
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import { ConfirmDeleteContent, GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin"
import { eStatusType, ref_Messages } from "hub-lib/models/ref_Messages.bin"
import { CustomGroupButton } from "../../ConfigurableComponents/CustomGroupButton.bin"
import { AdwAutocomplete, getIcon } from "adwone-lib/index"
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin"
import { CustomBadge } from "../Adwone-admin/Referential/BadgesUsage.bin"
import { VertexGrid } from "../Generic/VertexGrid.bin"
import { Checkbox } from "@progress/kendo-react-inputs"
import { Grid } from "@material-ui/core"
import { CustomPercentTextBox } from "../Generic/Common.bin"
import { DiscountManager } from "hub-lib/business/DiscountManager.bin"
import { FeeRates } from "hub-lib/models/types/vertex.bin"
import { distinct, propertyOf } from "hub-lib/tools.bin"
import { eGroupCategories, ref_Groups } from "hub-lib/models/ref_Groups.bin"
import { Client } from "hub-lib/client/client.bin"
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin"
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin"

export class TProps {
    grid?: VertexGrid<ref_Messages>;
    selectedItems?: any[];
    onChangeStatus: (status: eStatusType) => Promise<void>;
    onchangeFeeRate: (fees: FeeRates) => Promise<void>;
    onchangeProperty?: (values: any) => Promise<void>;
    onChange?: () => void;
    onRemove?: () => void;
}
class TState {
    removeVisible: boolean;
    statusVisible: boolean;
    feeRateVisible: boolean;
    propertiesVisible: boolean;
    selectedIndex: number;
    anchorEl: HTMLElement | null;
    fees: FeeRates;
    property: PropertySet;
    popoverMode: "feeRateVisible" | "propertiesVisible";
}
export type PropertySet = {
    name: string,
    value: string,
    choices: { key: string, Name: string, rids: string[] }[]
};

const status = [eStatusType.None, eStatusType.Simulated, eStatusType.Opted, eStatusType.Confirmed, eStatusType.Cancelled]
export class CustomMasseActions extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);
        let newState = new TState();
        newState.removeVisible = false;
        newState.statusVisible = false;
        newState.feeRateVisible = false;
        newState.propertiesVisible = false;
        newState.selectedIndex = 0;
        newState.anchorEl = null;
        newState.fees = {};
        newState.property = null;
        this.state = newState;
    }
    feeRatesClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.currentTarget;
        const typeId = (await DiscountManager.GetDiscountTypes()).find(c => c.Name === "Honoraires")?.["@rid"];
        const classes = (await DiscountManager.GetDiscountClasses()).filter(c => c.DiscountType == typeId);
        const initfees = {};
        classes.forEach(discountClass => {
            initfees[discountClass["@rid"]] = { update: false, value: 0, typeId: typeId, label: `${Trad("taux")} ${Trad(discountClass.Name)}` };
        });
        this.setState({
            popoverMode: "feeRateVisible",
            fees: initfees,
            anchorEl: target
        });
    }

    propertyClick = async (button: HTMLElement, property: string) => {

        const dataItems = this.props.selectedItems.map(i => i["dataItem"] as ref_Messages);
        const supportIds = distinct(dataItems.map(i => i.Support));

        const mediaFamilies = await Client.searchVertexTyped(ref_Groups, {
            Active: true,
            Category: eGroupCategories.MediaFamily,
            IDs: supportIds,
        });

        const isMessageElligible = (mediaFamily: ref_Groups, message: ref_Messages) => {
            if (!mediaFamily.IDs.includes(message.Support))
                return false;
            return mediaFamily.Area.find(area => {
                if (area.Class == ref_Advertisers.name) {
                    if (!area.IDs)
                        return true;
                    if (area.IDs.includes(message.Advertiser))
                        return true;
                }
                return false;
            })
        }
        const propertyChoices = mediaFamilies.map(f =>
        ({
            key: f["@rid"],
            Name: f.Name,
            rids: dataItems.filter(i => isMessageElligible(f, i)).map(m => m["@rid"])
        })).filter(p => p.rids.length > 0);

        this.setState({
            popoverMode: "propertiesVisible",
            property: {
                name: property,
                value: null,
                choices: propertyChoices
            },
            anchorEl: button
        });
    }

    toggleDialog = (dialogType: "removeVisible" | "statusVisible" | "feeRateVisible" | "propertiesVisible") => {
        const stateChange = {};
        stateChange[dialogType] = !this.state[dialogType];
        this.setState(stateChange);
    }

    removeItems = async () => {
        await this.props.grid.delete(this.props.selectedItems);
        this.props.grid.UpdateRows();
        this.props?.onChange?.();
    }

    render() {
        const { popoverMode, property } = this.state;
        const open = Boolean(this.state.anchorEl);
        const id = open ? popoverMode : undefined;
        let suppressionMsg = ConfirmDeleteContent(this.props.selectedItems);
        const properties = ["ModelProperties.MediaFamily"];
        let statusMsg = `Voulez vous vraiment changer le statut de ${this.props.selectedItems.length} élément(s) en ${Trad(status[this.state.selectedIndex])} ?`;
        const count = this.props.selectedItems.length;
        return (
            <>
                {/* <span className="margin-right">{`${this.props.selectedItems.length} ${Trad("selected_items")}`}</span> */}
                <div className="margin-right">
                    <CustomBadge count={count} icon={"messages"} tradClassKey={ref_Messages.name} />
                </div>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ anchorEl: null })}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} >

                    <div className="DialogContent-container">
                        <div style={{ width: "300px" }}>
                            {popoverMode == "feeRateVisible" && Object.keys(this.state.fees).map(key => {
                                return <div className="message_details_full" style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <Checkbox checked={this.state.fees[key].update}
                                        onChange={e => {
                                            this.state.fees[key].update = e.value;
                                            this.setState({ fees: this.state.fees })
                                        }}
                                        style={{ marginRight: "6px" }} />
                                    <CustomPercentTextBox
                                        title={this.state.fees[key].label}
                                        disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.update) || !this.state.fees[key].update}
                                        value={this.state.fees[key].value}
                                        onChange={(e) => { this.state.fees[key].value = e.value; this.setState({ fees: this.state.fees }) }}
                                    />
                                </div>
                            })}
                            {popoverMode == "propertiesVisible" &&
                                <div className="message_details_full">
                                    <AdwAutocomplete
                                        options={property.choices}
                                        getOptionLabel={(v => v.Name)}
                                        disableClearable
                                        value={this.state.property.value && property.choices.find(p => p.key == this.state.property.value)}
                                        onChange={async (event: any, value: any) => {
                                            console.log("value", value);
                                            this.state.property.value = value?.key;
                                            this.setState({ property: this.state.property })
                                        }}
                                        renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(property.name, ref_Messages)} />}
                                    />
                                    {this.props.selectedItems?.map(item => {
                                        return <div>{item["Support"]}</div>
                                    })}
                                </div>}
                        </div>
                        <div>
                            <div className="adw-form-action">
                                <Button aria-describedby={id}
                                    variant="contained"
                                    disabled={!property?.value && !Object.keys(this.state.fees).some(k => this.state.fees[k].update)}
                                    className="custom_btn_primary_validation" onClick={() => {
                                        this.toggleDialog(popoverMode)
                                    }}>
                                    {Trad("change")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Popover>

                {RightManager.hasRight(eFunctions.ref_Messages, eRights.update) && <>
                    <CustomGroupButton
                        Label={Trad("status")}
                        buttonClassName={"custom_btn_secondary_white margin-right"}
                        buttons={status.map((s, index) => (
                            {
                                Label: <><span className={`rounded_status ${s.slice(0, 3)}`}></span>{Trad(s)}</>,
                                onClick: () => {
                                    this.setState({ selectedIndex: index }, () => this.toggleDialog("statusVisible"))
                                }
                            }
                        ))} />
                    <Button aria-describedby={id} startIcon={getIcon("wallet")} className="custom_btn_secondary_white margin-right" onClick={this.feeRatesClick}>
                        {Trad("fee_rate")}
                    </Button>
                    {/*<CustomGroupButton
                        Label={Trad(ref_Property.name)}
                        buttonClassName={"custom_btn_secondary_white margin-right"}
                        buttons={properties.map((p, index) => (
                            {
                                Label: <>{TradProp(p, ref_Messages)}</>,
                                onClick: (event, button) => { this.propertyClick(button, p) }
                            }
                        ))} />*/}
                </>}

                {RightManager.hasRight(eFunctions.ref_Messages, eRights.delete) &&
                    <Button variant="contained"
                        className="custom_btn_danger"
                        startIcon={getIcon("delete")}
                        onClick={() => this.toggleDialog("removeVisible")}>
                        {Trad("delete")}
                    </Button>}

                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={this.state.statusVisible}
                    submitTitle={Trad("yes")}
                    dialogContent={<p>{statusMsg}</p>}
                    // submitClass={"custom_btn_primary"}
                    handleClose={() => this.toggleDialog("statusVisible")}
                    actions={true}
                    submitAction={async () => {
                        await this.props.onChangeStatus(status[this.state.selectedIndex]);
                        this.props?.onChange?.();
                        this.toggleDialog("statusVisible")
                    }}
                />
                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={this.state.removeVisible}
                    submitTitle={Trad("yes")}
                    dialogContent={<p>{suppressionMsg}</p>}
                    submitClass={"custom_btn_danger"}
                    handleClose={() => this.toggleDialog("removeVisible")}
                    actions={true}
                    submitAction={async () => {
                        await this.removeItems();
                        this.props?.onRemove?.();
                        this.toggleDialog("removeVisible");
                    }}
                />
                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={this.state.feeRateVisible}
                    submitTitle={Trad("yes")}
                    dialogContent={<p>
                        {`Voulez vous vraiment changer les taux d'honoraires de ${count} élément(s) ?`}
                        <ul>
                            {Object.keys(this.state.fees).map(k => {
                                if (!this.state.fees[k].update) return;
                                return <li>{`${this.state.fees[k].label} : ${this.state.fees[k].value}%`}</li>
                            })
                            }
                        </ul>
                    </p>}
                    // submitClass={"custom_btn_primary"}
                    handleClose={() => this.toggleDialog("feeRateVisible")}
                    actions={true}
                    submitAction={async () => {
                        this.toggleDialog("feeRateVisible");
                        await this.props.onchangeFeeRate(this.state.fees);
                        this.props?.onChange?.();

                    }}
                />
                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={this.state.propertiesVisible}
                    submitTitle={Trad("yes")}
                    dialogContent={<p>
                        {`Voulez vous vraiment changer ${TradProp(property?.name, ref_Messages)} de ${property?.choices.find(c => c.key == property.value)?.rids?.length} élément(s) ?`}
                    </p>}
                    // submitClass={"custom_btn_primary"}
                    handleClose={() => this.toggleDialog("propertiesVisible")}
                    actions={true}
                    submitAction={async () => {
                        this.toggleDialog("propertiesVisible");
                        await this.props.onchangeProperty(this.state.property);
                        this.props?.onChange?.();

                    }}
                />
            </>
        )
    }
}