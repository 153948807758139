

import { Client } from 'hub-lib/client/client.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { CrossedTableConfig } from './CrossedTableConf.bin';
import { eAction, logUserArg } from 'hub-lib/models/requests.bin';
import { IsDebugMode } from '../../utils/localstorage.bin';
import { store } from '../../redux/store';
import { setData } from '../../redux/gridSlice';

export async function UpdateFromServer(config: CrossedTableConfig) {

    if (!config)
        return;

    const logArg: Partial<logUserArg> = { Category: 'crossed_table', Action: eAction.Read, Informations: {} };
    try {

        if (IsDebugMode())
            console.log('config aggregate', config);

        const document = CrossedTableConfig.ToExternalConfig(config);
        logArg.Informations.document = document;

        if (!config.colventils || !config.colventils.length)
            config.colventils = ["*"];

        if (IsDebugMode())
            console.log('document aggregate', document);

        const resp = await Client.aggregate<ref_Messages>(document);
        console.log(`[UpdateFromServer]`, resp)
        const { table } = resp.data;

        const data = table.Rows.flatMap(r => r.Data);
        store.dispatch(setData(data));
        return table;

    } catch (error) {
        let errorTitle = 'Error crossed table';
        Client.log({ ...logArg, Status: "fail", Informations: { ...logArg.Informations, error } });
        console.log(errorTitle);
        console.log(error);
    }
}

