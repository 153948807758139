import * as React from "react";

import { AdwTelerikGrid } from "../../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { AdwAutocomplete, ADWColumn, AdwRow, TooltipManager } from "adwone-lib/index";
import Loader from "../../../layout/Loader";
import { Trad, TradProp, TradValue } from "trad-lib";
import { ImportsSourceType, ImportsStatus, ref_Imports } from 'hub-lib/models/ref_Imports.bin';
import { eDialogMode, GenericDialog } from "../../../ConfigurableComponents/GenericDialog.bin";
import { Grid, IconButton } from "@material-ui/core";
import { VertexAutocomplete } from "adwone-lib/index";
import { ref_AdvertiserGroups, ref_AdvertiserGroupsId } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_CustomersId } from "hub-lib/models/orientdb/ref_Customers.bin";
import { ref_Media, ref_MediaId } from "hub-lib/models/orientdb/ref_Media.bin";
import { Client } from "hub-lib/client/client.bin";
import { propertyOf, Typed } from "hub-lib/tools.bin";
import { GetSort } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { DateNoZone } from "tools-lib";
import { getIcon } from "adwone-lib/index";
import ImportsDialog from "../ImportsDialog";
import { GenericTooltip } from "../../../ConfigurableComponents/GenericTooltip.bin";
import history from '../../../../utils/history'
import { ImportsCommandCell } from "./ImportsCommandCell";
import { connect } from "react-redux";
import { ref_CustomersExtended } from "hub-lib/models/custom/ref_CustomersExtended.bin";
import { DateRangepicker } from "../../../ConfigurableComponents/DateRangepicker";
import { HierarchyComboManager } from "../../HierarchyComponent/HierarchyComponent.bin";

import { refreshedImports } from "../../../../actions/importsActions";
import { eRoles } from "hub-lib/business/rights/rights.bin";
import { HistoryStateArg } from "hub-lib/types";
import { store } from "../../../../redux/store";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from "../../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { ConfigurationPanel } from "../../Messages/ConfigurationPanel";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { createFilterOptions } from "@material-ui/lab";
import { DynamicReportCell } from "./DynamicReportCell";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { editCell } from "../../../crossedTable/CrossedTableTelerikTree.bin";

class TState {
    grid?: VertexGrid<ref_Imports>;
    open?: boolean
    selectedImport?: dataToImport
    key: number = 0;
    sort: any;
    openProgressImports: boolean = false;
    profil: string
    openErrors: boolean = false
    MappingErrors: [] = []
    ReferentialErrors: [] = []
    Errors: {} = null;
    BroadcastErrors: [] = []
    AlreadyExistErrors: [] = []
}

export class dataToImport {
    SourceType?: ImportsSourceType;
    DSP?: string;
    Customer?: ref_CustomersId;
    Start?: Date;
    End?: Date;
    AdvertiserGroup?: ref_AdvertiserGroupsId[] | ref_AdvertiserGroupsId;
    Advertiser?: ref_AdvertisersId[];
    Medias?: ref_MediaId[];
    Params?: any;
}

class ImportsGrid extends React.Component<any, TState> {

    constructor(props: any) {
        super(props);
        let newstate = new TState()
        newstate.open = false;
        this.state = newstate;
    }

    componentDidMount() {
        const profil = JSON.parse(localStorage.getItem("user")).profileName
        this.setState({ profil })
        const columns: ADWColumn<ref_Imports>[] = [];
        const hiddenProperties: string[] = [];
        let properties: string[] = [];

        hiddenProperties.push(propertyOf<ref_Imports>("Report"));
        hiddenProperties.push(propertyOf<ref_Imports>("Errors"));
        hiddenProperties.push(propertyOf<ref_Imports>("Filters"));
        hiddenProperties.push(propertyOf<ref_Imports>("Target"));
        hiddenProperties.push(propertyOf<ref_Imports>("Source"));
        hiddenProperties.push(propertyOf<ref_Imports>("Customer"));
        hiddenProperties.push(propertyOf<ref_Imports>("Date"));
        const colsMess = (property: keyof ref_Imports, tradType?: new () => any) => {
            let bindingPath = propertyOf<ref_Imports>(property);
            let columnCustom = new ADWColumn<ref_Imports>(TradProp(property, ref_Imports), bindingPath, ePropType.Any, true);
            columnCustom.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Imports>) => {
                let val = (dataItem.dataItem as any)?.[`${property}name`];
                let res: any;
                if (tradType) res = TradValue(tradType, "name", val);
                else res = val;
                return res;
            }
            columns.push(columnCustom);
            hiddenProperties.push(property);
            properties.push(`${property}.name as ${property}name`);
        }
        colsMess("User")
        let columnStatus = new ADWColumn<ref_Imports>(Trad("Status"), "Status", ePropType.Any, true)
        columnStatus.cellValue = statusCellValue;
        columnStatus.width = 150
        columns.push(columnStatus)
        /** Column Date */
        let columnDate = new ADWColumn<ref_Imports>(Trad("Date"), propertyOf<ref_Imports>("Date"), ePropType.Datetime, false);
        columnDate.getValue = (row: ref_Imports) => row.Date ? DateNoZone(row.Date) : '';
        columnDate.width = 180
        columns.push(columnDate)
        /** Column Report */
        let columnReport = new ADWColumn<ref_Imports>(Trad("Report"), propertyOf<ref_Imports>("Filters"), ePropType.Any, false);
        columnReport.cellValue = (cellValue, dataItem) => <DynamicReportCell cellValue={cellValue} dataItem={dataItem} />
        columnReport.width = 280
        columns.push(columnReport)
        /** Column Errors */
        let columnError = new ADWColumn<ref_Imports>(Trad("Errors"), propertyOf<ref_Imports>("Errors"), ePropType.Any, false);
        const renderError = (row: ref_Imports) => {
            switch (row.SourceType) {
                case ImportsSourceType.MESSAGEMAP:
                    return `${Trad(ref_Campaigns.name)}: ${row.Errors.ref_Campaigns} / ${Trad(ref_Messages.name)}: ${row.Errors.ref_Messages}`;
                case ImportsSourceType.AGREEMENTMAP:
                    return `${Trad(ref_Agreements.name)}: ${row.Errors.Total}`;
                case ImportsSourceType.ADWONE:
                    return `${Trad("referentials")}: ${row.Errors.Total}`;
                default:
                    return "";
            }
        }
        columnError.cellValue = (CellValue: any, dataItem: AdwRow<ref_Imports>) => {
            const errors = renderError(dataItem.dataItem);
            dataItem["Errors_cellValue"] = errors;
            return dataItem.dataItem.Errors?.Total ?
                <>
                    <div style={{ width: "80%", float: "left" }}>
                        {errors}
                    </div>
                    {(dataItem.dataItem.SourceType === ImportsSourceType.MESSAGEMAP ||
                        dataItem.dataItem.SourceType === ImportsSourceType.AGREEMENTMAP) ?
                        <GenericTooltip
                            tooltipContent={Trad("see_errors")}
                            style={{ width: "20%", float: "right", cursor: "pointer" }}
                            children={<div style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({
                                    openErrors: true,
                                    Errors: dataItem.dataItem.Errors,
                                    ReferentialErrors: dataItem.dataItem.Errors.referential_not_found,
                                    MappingErrors: dataItem.dataItem.Errors.mapping_not_found,
                                    BroadcastErrors: dataItem.dataItem.Errors.broadcast_not_found,
                                    AlreadyExistErrors: dataItem.dataItem.Errors.alreadyExist
                                })
                            }}>{getIcon("search")}</div>}
                        />
                        : ''}
                </>
                : '';
        }
        columnError.width = 280
        columns.push(columnError)
        const sort = GetSort<ref_Imports>(ref_Imports);
        const grid = new VertexGrid<ref_Imports>({
            objectPrototype: ref_Imports,
            devMode: false,
            columns,
            order: ["Date", "ExpirationDate", "Status", "User", "SourceType", "Company", "FiltersName", "Report", "Filters", "Errors"],
            width: { "Date": 180, "SourceType": 150, "ExpirationDate": 200 },
            vertexParams: {
                properties: ["*", ...properties],
            },
            hiddenProperties,
        });
        this.setState({
            grid,
            sort,
        })
    }

    render() {
        const { selectedImport, sort, profil, openErrors, MappingErrors, ReferentialErrors, BroadcastErrors, AlreadyExistErrors, Errors } = this.state
        const { statusImport } = this.props

        if (!this.state.grid)
            return <Loader />;

        if (statusImport) {
            this.state.grid.UpdateRows()
            store.dispatch(refreshedImports())
        }

        /** Check current import creation is valid */
        const isNotValid = () => {
            if (profil == eRoles.superAdministrateur && [ImportsSourceType.MEDIAAPI, ImportsSourceType.CODIPRESSE, ImportsSourceType.MEDIAVISION, ImportsSourceType.TSM_CONTACTS].includes(selectedImport?.SourceType))
                return false;
            return (!selectedImport?.Customer && profil == eRoles.superAdministrateur) || !selectedImport?.Start || !selectedImport?.End
        };

        return (
            <div style={{ width: '100%' }}>
                <ImportToolbar onNew={() => this.setState({ open: true, selectedImport: {} })} />

                <AdwTelerikGrid
                    hideToolbar
                    grid={this.state.grid}
                    pluriCustom
                    customCommandCellFunction={() => this.state.grid.UpdateRows()}
                    customCommandCell={ImportsCommandCell}
                    sort={sort} />

                <ImportsDialog open={this.state.openProgressImports} closeDialog={() => this.setState({ openProgressImports: false })} />
                <DialogErrors open={openErrors} errors={Errors} broadcastErrors={BroadcastErrors} mappingErrors={MappingErrors} referentialErrors={ReferentialErrors} AlreadyExistErrors={AlreadyExistErrors} onClose={() => this.setState({ openErrors: false, MappingErrors: [], ReferentialErrors: [], AlreadyExistErrors: [] })} />

                <GenericDialog
                    open={this.state.open}
                    id="custom_import_messages_dialog"
                    dialogTitle={Trad('add_import')}
                    actions
                    dialogContent={<Grid container>
                        <Grid item xs={6} className="adw-row">
                            <VertexAutocomplete
                                label={Trad("import_type")}
                                options={getAvailableOptions()}
                                defaultValue={(types) => types.find(e => e == selectedImport.SourceType)}
                                onResetValue={() => undefined}
                                onChange={(type) => {
                                    selectedImport.SourceType = type;
                                    this.forceUpdate()
                                }} />
                        </Grid>

                        {(selectedImport?.SourceType == ImportsSourceType.TSM_CONTACTS && profil) &&
                            <Grid item xs={12} className="adw-row">
                                <Grid item xs={6}>
                                    <TargetSelector
                                        value={selectedImport.Params?.Source}
                                        onChange={(v) => {
                                            if (!selectedImport.Params) selectedImport.Params = {};
                                            selectedImport.Params.Source = v;
                                        }} />
                                </Grid>
                            </Grid>}

                        {(selectedImport?.SourceType == ImportsSourceType.MESSAGEMAP ||
                            selectedImport?.SourceType == ImportsSourceType.AGREEMENTMAP) && profil && <>
                                <Grid container item xs={12} className="adw-row">
                                    {profil == eRoles.superAdministrateur && <Grid item xs={6}>
                                        <VertexAutocomplete
                                            type={ref_CustomersExtended.name}
                                            params={{ Authorization: "MediaManager", properties: ["*", "Company.Name as Name"] }}
                                            label={Trad("customer")}
                                            onResetValue={(options: any) => undefined as any}
                                            defaultValue={options => options.find(e => e["@rid"] == selectedImport?.Customer)}
                                            onChange={(client) => {
                                                selectedImport.Customer = client?.["@rid"];
                                                if (client == undefined) {
                                                    selectedImport.AdvertiserGroup = []
                                                    selectedImport.Advertiser = []
                                                    this.forceUpdate()
                                                }
                                                this.forceUpdate()
                                            }}
                                        />
                                    </Grid>}
                                </Grid>
                                <Grid item xs={12} className="adw-row">
                                    <DateRangepicker
                                        defaultStart={selectedImport.Start ? new Date(selectedImport.Start) : null}
                                        defaultStop={selectedImport.End ? new Date(selectedImport.End) : null}
                                        handleChangeDate={(date) => {
                                            if (date.end) {
                                                selectedImport.Start = date.start;
                                                selectedImport.End = date.end;
                                                this.forceUpdate()
                                            }
                                        }} />
                                </Grid>
                            </>
                        }
                        {(selectedImport?.SourceType == ImportsSourceType.AGREEMENTMAP && profil) && <>
                            <Grid item xs={12} className="adw-row">
                                <VertexAutocomplete
                                    label={`${Trad(ref_AdvertiserGroups.name)} *`}
                                    disableClearable
                                    disabled={(!selectedImport.Customer && profil == eRoles.superAdministrateur)}
                                    defaultValue={(options: any[]) => options?.find((v) => v["@rid"] === selectedImport?.AdvertiserGroup)}
                                    onChange={(value: ref_AdvertiserGroups) => {
                                        selectedImport.AdvertiserGroup = value?.["@rid"];
                                        this.forceUpdate();
                                    }}
                                    type={ref_AdvertiserGroups.name}
                                />
                            </Grid>
                        </>}
                        {(selectedImport?.SourceType == ImportsSourceType.MESSAGEMAP && profil) && <>
                            <HierarchyComboManager
                                store={selectedImport}
                                multi
                                noPadding
                                disableProperty={{ AdvertiserGroup: (!selectedImport.Customer && profil == eRoles.superAdministrateur), Advertiser: (!selectedImport.Customer && profil == eRoles.superAdministrateur) }}
                                hideProperty={{ Brand: true, Product: true }}
                            />
                            <Grid item xs={6}>
                                <VertexAutocomplete
                                    multiple
                                    label={Trad("medias")}
                                    type={ref_Media.name}
                                    defaultValue={(medias: any[]) =>
                                        medias.filter((v) => selectedImport?.Medias?.includes(v["@rid"]))
                                    }
                                    afterLoadFilter={(medias: ref_Media[]) => medias.filter(m => m.Name == "PRESSE" || m.Name == "DIGITAL")}
                                    onChange={(value) => {
                                        selectedImport.Medias = value.map(v => v["@rid"])
                                        this.forceUpdate()
                                    }}
                                />
                            </Grid>
                        </>
                            // <MapImport selectedImport={this.state.selectedImport} refreshDialog={()=>this.setState({key: this.state.key+1})}/>
                        }
                    </Grid>}
                    disablePrimaryButton={isNotValid()}
                    submitAction={() => {
                        let copy = selectedImport
                        for (const [key, value] of Object.entries(copy)) {
                            if (Array.isArray(value) && value.length == 0)
                                delete copy[key]
                        }
                        this.setState({ open: false }, () => {
                            Promise.resolve().then(res => {
                                return Client.createVertex(ref_Imports.name, copy).then(response => {
                                    return response;
                                }).catch(e => console.error(e));
                            }).then(fin => this.state.grid.UpdateRows())
                        });

                    }}
                    submitTitle={Trad("import")}
                    // submitClass="custom_btn_primary"
                    cancelAction={() => this.setState({ open: false, selectedImport: undefined })}
                />
            </div >
        )
    }
}


const statusCellValue = (CellValue: any, dataItem: AdwRow<ref_Imports>) => {
    const status = dataItem.dataItem.Report?.Status;
    const item = dataItem.dataItem;
    const expired = status == ImportsStatus.PENDING && item.ExpirationDate && new Date(item.ExpirationDate).getTime() < new Date().getTime();
    let value = Trad(status);
    if (expired)
        value = Trad(ImportsStatus.EXPIRED);

    dataItem["Status_cellValue"] = value ?? "";

    if (expired)
        return <span>{value}</span>

    const seeMessages = () => history.push(
        "/messages_by_import",
        Typed<HistoryStateArg>({
            type: "import",
            importRid: dataItem.dataItem["@rid"],
        })
    );
    const seeAgreements = () => history.push(`/agreements/import/${item["@rid"]}`);

    let showDetail = { delegateFunction: undefined, toolTip: undefined };
    if (item.Report?.ref_Messages > 0) {
        showDetail.toolTip = Trad("see_messages");
        showDetail.delegateFunction = seeMessages;
    }

    if (item.Report?.[ref_Agreements.name] > 0) {
        showDetail.toolTip = Trad("see_agreements");
        showDetail.delegateFunction = seeAgreements;
    }

    if (showDetail.delegateFunction) {
        return (
            <div className="flex center between">
                <div>{value}</div>
                <GenericTooltip
                    tooltipContent={showDetail.toolTip}
                    style={{ cursor: "pointer" }}
                    children={
                        <div
                            className="flex center"
                            style={{ cursor: "pointer" }}
                            onClick={() => showDetail.delegateFunction()}
                        >
                            {getIcon("go")}
                        </div>
                    }
                />
            </div>
        );
    }
    return <span>{value}</span>;
};

type DialogErrorsType = {
    open: boolean,
    onClose: () => void,
    errors: { [errors: string]: [] },
    referentialErrors: [], mappingErrors: [], broadcastErrors: [], AlreadyExistErrors: []
}

function DialogErrors({ open, onClose, referentialErrors, mappingErrors, broadcastErrors, AlreadyExistErrors, errors }: DialogErrorsType) {

    if (open) {
        console.log("[DialogErrors] errors", errors)
    }

    return <GenericDialog
        actions
        open={open}
        dialogTitle={Trad("Errors")}
        disableCancel
        submitTitle={Trad("close")}
        submitAction={() => onClose()}
        dialogContent={<div>
            {referentialErrors && referentialErrors.length !== 0 &&
                <>
                    <h4>{Trad("referential_not_found")}</h4>
                    {referentialErrors && referentialErrors.sort((a: any, b: any) => a["class"].localeCompare(b["class"])).map((e: any) => {
                        let str = `${e.class}: ${e.externalID} / ${e.externalLabel}`;
                        if (e.text) str += ` / ${e.text}`;
                        return <div>{str}</div>
                    })}
                </>
            }
            {errors?.mapping_not_found?.length > 0 &&
                <>
                    <br />
                    <h4>{Trad("mapping_not_found")}</h4>
                    {[...errors?.mapping_not_found].sort((a: any, b: any) => a["class"].localeCompare(b["class"])).map((e: any) => {
                        let str = `${e.class}: ${e.externalID}`;
                        if (e.text) str += ` / ${e.text}`;
                        return <div>{str}</div>
                    })}
                </>
            }
            {broadcastErrors && broadcastErrors.length !== 0 &&
                <>
                    <br />
                    <h4>{Trad("broadcast_not_found")}</h4>
                    {broadcastErrors.map((e: any) => {
                        return <div>{`${e}`}</div>
                    })}
                </>
            }
            {AlreadyExistErrors && AlreadyExistErrors.length !== 0 &&
                <>
                    <br />
                    <h4>{Trad("agreement_import_already_exist")}</h4>
                    {AlreadyExistErrors.map((e: {
                        message: string;
                        messageStatic?: string;
                        context: any;
                        type: string;
                        properties: string[];
                    }) => {
                        return <div>{`(${e?.context?.["@rid"]}) ${e?.context?.Name} => (${e?.properties?.[0]}) ${e?.properties?.[1]}`}</div>
                    })}
                </>
            }
        </div>} />
}

const baseOptions = ["MAP", "DSP", "POPCORN"];
function getAvailableOptions() {
    let options = [...baseOptions];
    if (RightManager.hasRight(eFunctions.ref_Crons, [eRights.create, eRights.read, eRights.update, eRights.delete])) {
        options = Object.values(ImportsSourceType);
    }
    return options;
}

type ImportToolbarArgs = { onNew: () => void }
function ImportToolbar({ onNew }: ImportToolbarArgs) {
    const confComponent = <ConfigurationPanel
        elements={[{
            type: "component",
            component: <IconButton
                style={{ width: "100%", height: "100%"} }
                onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad(eDialogMode.create) })}
                className="custom_btn_primary no-radius no-shadow"
                onClick={() => onNew()} >
                {getIcon("plus")}
            </IconButton>
        }]} />

    return <ToolbarAdw>
        <BreadcrumbsCustomContainer>
            <BreadcrumbsCustom
                elements={[
                    { text: Trad("home"), href: "/" },
                    { text: Trad("imports") }
                ]} />
        </BreadcrumbsCustomContainer>
        <ConfigurationPanelContainer>
            {confComponent}
        </ConfigurationPanelContainer>
    </ToolbarAdw>
}

type TargetSelectorArg = { value: string, onChange: (t: string) => void }
function TargetSelector({ value, onChange }: TargetSelectorArg) {

    const [_value, setValue] = React.useState(value);
    const filter = createFilterOptions<string>();
    return <AdwAutocomplete
        multiple={false}
        value={_value}
        options={["dev", "uat", "prod"]}
        onChange={(e, v) => {
            setValue(v);
            onChange(v);
        }}
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.includes(inputValue);
            if (inputValue !== '' && !isExisting)
                filtered.unshift(inputValue);
            return filtered;
        }}
        handleHomeEndKeys
        clearOnBlur
        selectOnFocus
    />
}

const mapStateToProps = (state, ownProps) => ({
    statusImport: state.imports.refreshList,
})

export default connect(mapStateToProps)(ImportsGrid)