import * as React from "react";
import { Trad } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_TableConfigurations } from "hub-lib/models/orientdb/ref_TableConfigurations.bin";
import { Client } from "hub-lib/client/client.bin";
import { Element } from "../../Generic/Common.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { compareObjects, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { ModeleCreatorBase } from "../../ModeleCreator/ModeleCreatorBase";
import { ref_FilterConfigurations } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_ProjectConfigurations } from "hub-lib/models/orientdb/ref_ProjectConfigurations.bin";
import { ExportStorage } from "../../../../utils/localstorage.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { selectProject } from "../../../../redux/projectSlice";
import { ref_ExportConfigurations } from "hub-lib/models/orientdb/ref_ExportConfigurations.bin";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ref_ConfigurationsBase } from "hub-lib/models/orientdb/ref_ConfigurationsBase.bin";
import { IRid } from "hub-lib/models/IRid.bin";

type FormSuperConfigurationArg = {
    onConfChange?: (conf: any) => any,
}

export function FormSuperConfiguration({ onConfChange }: FormSuperConfigurationArg) {
    const dispatch = useDispatch();

    const modeleFilters = duplicate(useSelector((root: RootState) => root.project.filtersModele, compareObjects));
    const modeleTable = duplicate(useSelector((root: RootState) => root.project.columnsModele, compareObjects));
    const project = duplicate(useSelector((root: RootState) => root.project.project, compareObjects));

    const [config, setConfig] = React.useState<ref_ProjectConfigurations>(project);

    const modelCreator = <Element>
        <ModeleCreatorBase
            key={GetHashCode(config)}
            objectType={ref_ProjectConfigurations}
            onChange={(conf) => {
                // ProjectStorage.SetProject(conf);
                setConfig(conf as ref_ProjectConfigurations);
                // onConfChange(conf);
            }}
            onDelete={(conf) => {
                apply(conf);
            }}
            dialogDelete
            modele={config}
            type={ref_Messages.name}
            disableLocalStorage
        />
    </Element>

    const onChange = async (conf: ref_ProjectConfigurations) => {
        await Client.updateVertex(ref_ProjectConfigurations.name, conf);
        setConfig(conf);
    }

    const apply = (conf: ref_ProjectConfigurations) => {
        onConfChange(conf);
        dispatch(selectProject(conf["@rid"]));
        setConfig(conf);
    }

    return (
        <>
            {modelCreator}
            <div className="adw-row" key={GetHashCode(config)}>
                <CheckBoxActivation label={"table"} objectName={ref_TableConfigurations.name} config={config} keyName="ConfTable" onChange={onChange} currentConf={modeleTable} />
                <CheckBoxActivation label={"filter"} objectName={ref_FilterConfigurations.name} config={config} keyName="ConfFilter" onChange={onChange} currentConf={modeleFilters} />
                <CheckBoxActivation label={"export"} objectName={ref_ExportConfigurations.name} config={config} keyName="ConfExport" onChange={onChange} currentConf={ExportStorage.get()} />
            </div>
            <div className="adw-row" style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                    startIcon={getIcon("down")}
                    Label={Trad("apply")}
                    className="custom_btn_primary"
                    onClick={() => {
                        apply(config);
                    }}
                />
                {!config?.Default && <CustomButton
                    style={{ marginLeft: 10 }}
                    startIcon={getIcon("save")}
                    Label={Trad("save")}
                    className="custom_btn_primary_validation"
                    onClick={() => {
                        // can't be generic for now
                        const currentExport = ExportStorage.get();
                        const newConfig = duplicate(config);
                        if (newConfig.ConfTableActive) {
                            newConfig.ConfTable = modeleTable["@rid"] || null;
                        }
                        if (newConfig.ConfFilterActive) {
                            newConfig.ConfFilter = modeleFilters["@rid"] || null;
                        }
                        if (newConfig.ConfExportActive) {
                            newConfig.ConfExport = currentExport["@rid"] || null;;
                        }
                        onChange(newConfig);
                    }}
                />}
            </div>
        </>
    )
}

type CheckBoxActivationArgs = { label: string, objectName: string, config: ref_ProjectConfigurations, keyName: string, onChange: (config: ref_ProjectConfigurations) => void, currentConf?: Partial<ref_ConfigurationsBase> };
function CheckBoxActivation({ label, objectName, config, keyName, onChange, currentConf }: CheckBoxActivationArgs) {
    const active = `${keyName}Active`;
    const [checked, setChecked] = React.useState<boolean>(config[active]);
    const [isNotDiff, setIsNotDiff] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsNotDiff(!config[active] || currentConf["@rid"] === config[keyName] || (currentConf.Default && config[keyName] === null));
    })

    return <div className="checkbox-activation">
        <FormControlLabel
            disabled={config?.Default}
            style={{ marginBottom: 5 }}
            control={
                <Checkbox
                    checked={checked}
                    onChange={(e) => {
                        onChange({
                            ...config,
                            [active]: !checked
                        })
                        setChecked(!checked);
                    }}
                    name="subTotal"
                    color="primary" />
            }
            label={Trad(label)} />
        <VertexAutocomplete
            warning={!isNotDiff && !config?.Default ? Trad("config-diff") : undefined}
            disabled={!checked || config?.Default}
            disableClearable={true}
            label={Trad(`${label.toLowerCase()}_model`)}
            type={objectName}
            defaultValue={(all: Partial<ref_ConfigurationsBase>[]) => {
                const find = all.find(e => e["@rid"] === config[keyName]);
                return find ?? all.find(e => e.Default);
            }}
            params={{ "Table": ref_Messages.name, properties: ["*"], Active: true }}
            onChange={(conf: IRid) => {
                onChange({ ...config, [keyName]: conf["@rid"] ?? null });
            }} />
    </div>
}