import { IRid } from "./IRid.bin";
import { rid } from "./orientdb/CommonTypes.bin";
import { ref_Sources } from "./orientdb/ref_Sources.bin";

export class ref_History implements IRid {
    "@rid"?: rid;
    source: ref_Sources["@rid"];
    date: Date;
    rid: IRid["@rid"];
    previousObject: any;
    newObject: any;
    changes: any;
    checked?: boolean;
}