import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_QualificationsId = rid;

export class ref_Qualifications extends V implements Referentials {
	"@rid"?: ref_QualificationsId;
	Name: string;
	Active: boolean;

}