import * as React from "react";
import { DiscountMode } from "../DIscountMode.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { setMessage } from "../../../../redux/messageEditorSlice";

export function DiscountModeContainer() {

    const dispatch = useDispatch();
    const globalAgreement = useSelector((root: RootState) => root.messageEditor.message.GlobalAgreement);
    const agreement = useSelector((root: RootState) => root.messageEditor.message.Agreement);
    const discountMode = useSelector((root: RootState) => root.messageEditor.message.DiscountMode);
    const disableDiscountMode = Boolean(globalAgreement) || Boolean(agreement);

    return <>
        {!disableDiscountMode
            && <DiscountMode
                discountMode={discountMode}
                disableDiscountMode={disableDiscountMode}
                onChange={mode => {
                    const message = store.getState().messageEditor.getMessage();
                    message.DiscountMode = mode;
                    dispatch(setMessage(message));
                }} />}
    </>
}