import { Grid } from "@material-ui/core";
import { Indicateur } from "adwone-engine/index.bin";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";
import { eColumnType } from "hub-lib/models/types.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../../../redux/store";
import { setColumns, setKPIs, setRows } from "../../../../../redux/tcdSlice";
import { DimensionsPicker } from "./DimensionsPicker";
import { KPIsPicker } from "./KPIsPicker";

export function PivotGridConfiguration() {

    const dispatch = useDispatch();
    const rows = useSelector((root: RootState) => root.tcd.rows);
    const columns = useSelector((root: RootState) => root.tcd.columns);
    //const kpis = useSelector((root: RootState) => root.tcd.kpis);
    const [dimensions, setDimensions] = React.useState<Indicateur[]>(null);
    //const [measures, setMeasures] = React.useState<Indicateur[]>(null);

    React.useEffect(() => {
        IndicateursProvider.GetInstance().Provide()
            .then(indicateurs => {
                setDimensions(indicateurs.filter(i => i.columnType == eColumnType.Property).map(i => i.indicateur));
                //setMeasures(indicateurs.filter(i => i.columnType != eColumnType.Property).map(i => i.indicateur));
            });

    }, [Boolean(dimensions)]);

    return <Grid container item xs={12}>
        <Grid item xs={6} style={{ paddingRight: 3 }}>
            <DimensionsPicker
                title={Trad('rows')}
                data={dimensions}
                values={rows ?? []}
                onChange={values => dispatch(setRows(values))} />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 3 }}>
            <DimensionsPicker
                title={Trad('columns')}
                data={dimensions}
                values={columns ?? []}
                onChange={values => dispatch(setColumns(values))} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 6 }}>
            {/* <DimensionsPicker
                title={Trad('kpis')}
                data={measures}
                values={kpis ?? []}
                onChange={values => dispatch(setKPIs(values))} /> */}
            <KPIsPicker onChange={configuration => dispatch(setKPIs(configuration.Columns))} />
        </Grid>
    </Grid>
}