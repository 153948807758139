
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { ref_SubAgencies } from "hub-lib/models/orientdb/ref_SubAgencies.bin";
import { vw_mm_HasAdvertisingCompanies } from "hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanies.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { UserExtended } from "hub-lib/models/UserExtended.bin";
import { duplicate, extractSub, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp } from "trad-lib";
import { initAdvComOptions, initAdvFinOptions, setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { WarningMM } from "./WarningMM";
import { InfoAdornment } from "../InfoAdornment";

type LnkPropertyEditorArgs = { property: "AdvCompany_Com" | "AdvCompany_Fin", mandatory: boolean, params?: any }
type LnkAdv = (lnk_AdvertisingCompanySupport & {
    outRid: IRid["@rid"];
})

export function LnkAdvPropertyEditor({ property, mandatory }: LnkPropertyEditorArgs) {

    const dispatch = useDispatch();

    const options = useSelector((root: RootState) => root.messageEditor[`${property}Options`]);
    const propertyValue = useSelector((root: RootState) => root.messageEditor.getMessage()[property]);
    const Support = useSelector((root: RootState) => root.messageEditor.getMessage().Support);
    const Barter = useSelector((root: RootState) => root.messageEditor.getMessage().KPIs?.Barter);
    const SmartBarter = useSelector((root: RootState) => root.messageEditor.getMessage().SmartBarter);
    const ApplyComBarter = useSelector((root: RootState) => root.messageEditor.getMessage().ApplyComBarter);
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);

    React.useEffect(() => {
        if (options === undefined) {
            switch (property) {
                case "AdvCompany_Com":
                    dispatch(initAdvComOptions(undefined));
                    break;
                case "AdvCompany_Fin":
                    dispatch(initAdvFinOptions(undefined));
                    break;
                default:
                    break;
            }
        }
    }, [options])

    const setValue = (value: LnkAdv | ref_SubAgencies) => {
        const message = store.getState().messageEditor.getMessage();
        let newValue: IRid["@rid"];
        if (value["@class"] == lnk_AdvertisingCompanySupport.name) newValue = (value as LnkAdv).outRid;
        else newValue = value["@rid"];
        if (propertyValue != newValue) {
            (message as any)[property] = newValue;
            dispatch(setMessage(message));
        }
        return value;
    }

    const getElement = (allOptions: (LnkAdv | ref_SubAgencies)[]) => propertyValue && allOptions?.find(o => o["@rid"] == propertyValue || (o as any).outRid == propertyValue)
    const warning = options && propertyValue && (getElement(options) as any)?.warning;

    return <WarningMM ids={[{ id: propertyValue, linkClass: vw_mm_HasAdvertisingCompanies.name }]}>
        <FavoriteVertexAutoComplete
            loading={!options || lockNext}
            enabledOnlyLoading
            options={options}
            key={GetHashCode({ propertyValue, options })}
            {...((property == "AdvCompany_Com" && ApplyComBarter && {
                startAdornment:
                    <InfoAdornment element={() => <div>{Trad("tooltip_ApplyComBarter")}</div>} />
            }) ?? {})}
            disableClearable
            disabled={!Support || lockNext || ((property == "AdvCompany_Fin" || (property == "AdvCompany_Com" && ApplyComBarter)) && (Barter > 0 || SmartBarter))}
            label={TradProp(property, ref_Messages) + (mandatory ? " *" : "")}
            warning={warning}
            defaultValue={getElement}
            onChange={async (lnk: LnkAdv | ref_SubAgencies) => {
                setValue(lnk)
            }} />
    </WarningMM>
}