import { Grid } from "@material-ui/core";
import { DiscountManager, IsValidKPIVolumeBase } from "hub-lib/business/DiscountManager.bin";
import { Client } from "hub-lib/client/client.bin";
import { eKPIType, MessageModelManager } from "hub-lib/models/KPIsManager.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_KPIs } from "hub-lib/models/orientdb/ref_KPIs.bin";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { Base } from "hub-lib/models/types/vertex.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { StyleFactory } from "../../../utils/ToolsReact";
import { VertexAutocomplete } from "adwone-lib/index";
import { getIcon } from "adwone-lib/index";
import Loader from "../../layout/Loader";

class TState {
  assietteKey: number = 0;
  assietteOptions: (ref_DiscountClasses | ref_KPIs)[] = [];
}

class TProps {
  agreement: ref_Agreements;
  bases: Base[];
  disableButtons?: () => any;
  title: string;
  onNewBase: (base: Base) => any;
  OnDeleteBase: (base: Base) => any;
  onChangeElement: (base: Base, index: number) => any;
}

const styles = StyleFactory.create({
  title: { paddingLeft: 20 },
  titleIcon: { color: "#009BCE", width: 30, float: "left" },
  resumeContainer: { padding: "0px 0px 10px 15px" },
  resumeBlock: { float: "left" },
  textResume: {
    padding: 10,
    backgroundColor: "rgb(97,97,97)",
    color: "white",
  },
  operatorResume: { marginLeft: 15, marginRight: 10 },

  editionGrid: { padding: "0px 0px 10px 15px" },
  editionOperator: { width: 120, float: "left", marginRight: 10 },
  editionElement: { width: 250, float: "left" },
  editionBlock: { float: "left", marginRight: 20 },
  iconNewContainer: { float: "left", marginTop: 16 }
});

export class CalculBase extends React.Component<TProps, TState> {

  constructor(props: TProps) {
    super(props);
    let newState = new TState();
    this.state = newState;
  }

  async componentDidMount() {
    const { agreement } = this.props;
    const lnkkpis = (await MessageModelManager.GetRefLnkKPIs())
      .filter(l => [eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(l.ValueType));

    const kpis = (await MessageModelManager.GetRefKPIs())
      .filter(k => ["Brut", "Brut Base Achat", "Brut Valorisé"].includes(k.Name));

    const filteredKpis: ref_KPIs[] = [];
    for (const kpi of kpis) {
      const found = lnkkpis.find(l => l.KPI === kpi["@rid"])
      if (found && await IsValidKPIVolumeBase(found, agreement))
        filteredKpis.push(kpi)
    }

    const supportedTypes = (await DiscountManager.GetRefDiscountTypes(["Modulations", "Gracieux"]))?.map(t => t["@rid"]);
    const discountClasses = (await Client.searchVertex(ref_DiscountClasses.name, { DiscountType: supportedTypes }))
      ?.data?.results;
    this.setState({
      assietteOptions: [...filteredKpis, ...discountClasses],
    });
  }

  render() {
    const { assietteOptions } = this.state;
    const { bases, title, onNewBase } = this.props;

    if (!assietteOptions.length)
      return <Loader />

    return (
      <>
        {/** Title Compute Base */}
        <TitleCompute title={title} />

        {/** grille édition */}
        <Grid
          item
          xs={12}
          key={this.state.assietteKey}
          style={styles.editionGrid}>

          {this.props.bases.map((element, i) =>
            <div key={`${element.Type.Name}_${i}`}>
              <div style={styles.editionBlock}>
                <div style={styles.editionOperator}>
                  <VertexAutocomplete
                    label={Trad("assiette_operator")}
                    options={[{ Name: "+", Value: 1 }, { Name: "-", Value: -1 }]}
                    onResetValue={(options: any) => { element.Operator = undefined }}
                    defaultValue={(options: any[]) =>
                      options.find((v) => v.Value === element.Operator)
                    }
                    onChange={(value: any) => {
                      this.props.onChangeElement({
                        Type: { Name: element?.Type?.Name, "@rid": element?.Type?.["@rid"], "@class": element?.Type?.["@class"] },
                        Operator: value?.Value
                      }, i)
                      this.forceUpdate();
                    }}
                  />
                </div>
                <div style={styles.editionElement} className="remove_range_container">
                  <VertexAutocomplete
                    label={Trad("assiette_key")}
                    options={assietteOptions?.filter(opt => !bases.some(b => b.Type?.Name === opt.Name && element.Type.Name !== opt.Name))}
                    defaultValue={(options: any[]) =>
                      options.find((v) => v.Name === element.Type.Name)
                    }
                    onChange={(value: any) => {
                      this.props.onChangeElement({
                        Type: { Name: value.Name, "@rid": value["@rid"], "@class": value["@class"] },
                        Operator: element?.Operator
                      }, i)
                      this.forceUpdate();
                    }}
                  />
                  <div className="remove_range"><span onClick={() => {
                    this.props.OnDeleteBase(element)
                    this.setState({
                      assietteKey: this.state.assietteKey + 1,
                    })
                  }}>{Trad("delete")}</span></div>
                </div>
              </div>
            </div>)}

          <IconAddNewElement onClick={onNewBase} />

        </Grid>

        {/** Resumé  */}
        <Grid item xs={12} style={styles.resumeContainer}>
          {this.props.bases?.map((e, i) =>
            <div key={`${e.Type.Name}_${i}`} style={styles.resumeBlock}>
              {i > 0 && <span style={styles.operatorResume}>
                {e.Operator === -1 ? "  -  " : "  +  "}
              </span>}
              <span style={styles.textResume}>
                {e.Type?.Name}
              </span>
            </div>)}
        </Grid>
      </>
    );
  }
}

function TitleCompute({ title }) {
  return <Grid item xs={12} style={styles.title}>
    <h3 style={{ display: "inline-block" }}>
      <div style={styles.titleIcon}>
        {getIcon("compare")}
      </div>
      {title}
    </h3>
  </Grid>
}

function IconAddNewElement({ onClick }) {
  return <div style={styles.iconNewContainer}>
    <span
      style={{ cursor: "pointer", color: "#009BCE" }}
      onClick={() => {
        onClick({
          Type: {
            "@class": undefined,
            "@rid": undefined,
            Name: undefined,
          },
          Operator: 1,
        })
      }}>
      {getIcon("plus_custom")}
    </span>
  </div>
}
