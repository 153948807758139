import * as React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh'
import { ImportsStatus, ref_Imports } from 'hub-lib/models/ref_Imports.bin';
import { Client } from 'hub-lib/client/client.bin';
import { Trad } from 'trad-lib';
import { GenericDialog } from '../../../ConfigurableComponents/GenericDialog.bin';
import { GenericTooltip } from '../../../ConfigurableComponents/GenericTooltip.bin';
import { useSelector } from 'react-redux';
import { CustomProgressBar } from '../../../ConfigurableComponents/CustomProgressBar';
import { GridCellProps } from '@progress/kendo-react-grid';
import { RootState } from '../../../../redux/store';
import { CommandIconButton } from './CommandIconButton';

export function ImportsCommandCell({ editField }, customCommandCellFunction) {
    return function ({ dataItem }: GridCellProps) {

        const items = useSelector((root: RootState) => root.imports.list);
        const [state, _setState] = React.useState({
            openValid: false,
            openCancel: false,
            refreshImport: false
        });

        const setState = (e) => _setState({ ...state, ...e });

        const { openValid, openCancel, refreshImport } = state
        const item: ref_Imports = items.find(e => e["@rid"] === dataItem?.dataItem?.["@rid"]);
        return (
            <td className="k-grid-content-sticky k-command-cell add-border-left" style={{ right: '-1px' }} >
                {item ?
                    <div style={{ margin: 15 }} key={`progress_${item['@rid']}`}>
                        <GenericTooltip tooltipContent={`${item.Report.Current}/${item.Report.Total}`}><CustomProgressBar maximum={item.Report.Total} current={item.Report.Current} /></GenericTooltip>
                    </div> :
                    <>
                        <CommandIconButton
                            icon={<ClearIcon />}
                            tooltipContent={Trad("cancel_import")}
                            disabled={(dataItem?.dataItem?.Report?.Status !== ImportsStatus.PENDING) || (new Date(dataItem?.dataItem?.ExpirationDate) < new Date())}
                            color="#DA3240"
                            onClick={() => setState({ openCancel: true })} />

                        <CommandIconButton
                            icon={<RefreshIcon />}
                            tooltipContent={Trad("refresh_import")}
                            disabled={dataItem?.dataItem?.Report?.Status == ImportsStatus.IN_PROGRESS}
                            color="#009BCE"
                            onClick={() => setState({ refreshImport: true })} />

                        <CommandIconButton
                            icon={<CheckBoxIcon />}
                            tooltipContent={Trad("valid_import")}
                            disabled={(dataItem?.dataItem?.Report?.Status !== ImportsStatus.PENDING) || (new Date(dataItem?.dataItem?.ExpirationDate) < new Date())}
                            color="#3AB36E"
                            onClick={() => setState({ openValid: true })} />

                        <GenericDialog
                            dialogTitle={Trad("confirm")}
                            open={openValid}
                            actions
                            dialogContent={<p>{`${Trad("valid_import")} ?`}</p>}
                            cancelAction={() => setState({ openValid: false })}
                            submitAction={() => {
                                Client.persistVertex(ref_Imports.name, { "@rid": dataItem.dataItem["_id"], "SourceType": dataItem.dataItem["SourceType"] }, true).then(res => {
                                    setState({ openValid: false })
                                    customCommandCellFunction()
                                }).catch(e => console.error(e))
                            }}
                            submitTitle={Trad("valid_import")}
                        />
                        <GenericDialog
                            dialogTitle={Trad("confirm")}
                            open={refreshImport}
                            actions
                            dialogContent={<p>{`${Trad("refresh_import")} ?`}</p>}
                            cancelAction={() => setState({ refreshImport: false })}
                            submitAction={() => {
                                Client.updateVertex(ref_Imports.name, { "@rid": dataItem.dataItem["_id"], refresh: true, "SourceType": dataItem.dataItem["SourceType"] }, true).then(res => {
                                    setState({ refreshImport: false })
                                    customCommandCellFunction()
                                }).catch(e => console.error(e))
                            }}
                            submitTitle={Trad("refresh_import")}
                        />
                        <GenericDialog
                            dialogTitle={Trad("confirm")}
                            open={openCancel}
                            actions
                            submitClass={"custom_btn_danger"}
                            dialogContent={<p>{`${Trad("cancel_import")} ?`}</p>}
                            cancelAction={() => setState({ openCancel: false })}
                            submitAction={() => {
                                Client.cancelVertex(ref_Imports.name, { "@rid": dataItem.dataItem["_id"], "SourceType": dataItem.dataItem["SourceType"] }, true).then(res => {
                                    setState({ openCancel: false })
                                    customCommandCellFunction()
                                }).catch(e => console.error(e))
                            }}
                            submitTitle={Trad("cancel_import")}
                        />
                    </>
                }
            </td>
        )
    }
}

