import * as React from 'react'
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin'
import { eKPI, eKPIType, IModelMessageInfo, IsNumber, MessageModelManager, lnk_HasKPIExtended, lnk_HasPropertyTypeExtended } from 'hub-lib/models/KPIsManager.bin';
import { Grid, TextField } from '@material-ui/core';
import { MetricsTextfield } from './MetricsTextfield.bin';
import { AdwAutocomplete } from "adwone-lib/index";
import { Trad, TradProp, TradValue } from 'trad-lib';
import { SimpleDatePicker } from '../../ConfigurableComponents/SimpleDatepicker.bin';
import { clone, groupByTag, JSONEqualityComparer, propertyOf } from 'hub-lib/tools.bin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setMessage, setMessageSync } from '../../../redux/messageEditorSlice';
import { FilesPicker } from '../Generic/FilesPicker.bin';
import { ContainerComponent } from '../Generic/ContainerComponent';
import { WebsiteTextBox } from '../Adwone-admin/Referential/WebsiteTextBox';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Visuals } from 'hub-lib/models/ref_Attachments.bin';
import { UpdateOJDData } from 'hub-lib/business/ojd'
import { otherRid } from './MessageEditor/GroupEditor';
import { ModelPropertiesEditor } from './MessageEditor/ModelPropertiesEditor';
import { ref_Campaigns } from 'hub-lib/models/ref_Campaigns.bin';

class Category {
    name: string;
    metrics?: eKPI[];
    upload?: string;
    order?: string[];
}

const categories: Category[] = [
    { name: "Pricing", metrics: [eKPI.GrossFormat, eKPI.GrossFormatRate, eKPI.GrossFormatValRate] },
    { name: "volumes", metrics: [eKPI.Total, eKPI.Paid, eKPI.Free] },
    { name: "Performance", metrics: [eKPI.ViewRate, eKPI.SOV, eKPI.Release, eKPI.PaidCirculation, eKPI.TotalCirculation, eKPI.Subscriptions, eKPI.ToDeliver] },
    { name: "PlanPurchased" },
    { name: "Additional" },
    { name: "TechnicalSpecifications" },
    { name: "ExternalReferences" }];

export function MessageMetrics() {

    const dispatch = useDispatch();
    const [loaded, setLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [messageModelMgr, setMessageModelMgr] = React.useState<MessageModelManager>();
    const [properties, setProperties] = React.useState<lnk_HasPropertyTypeExtended[]>();
    const [kpis, setKpis] = React.useState<lnk_HasKPIExtended[]>();
    const message = clone(useSelector((root: RootState) => root.messageEditor.getMessage(), JSONEqualityComparer));

    React.useEffect(() => {

        if (loading)
            return;

        setLoading(true);

        Promise.resolve().then(async () => {
            const _messageModelMgr = new MessageModelManager(message.Media, "Message");
            const _kpis: lnk_HasKPIExtended[] = await _messageModelMgr.GetKPIs();
            const _properties: lnk_HasPropertyTypeExtended[] = await _messageModelMgr.GetProperties();

            if (_properties.find(p => p.Id == "OjdYear")) {
                const waves = (message.Support && message.BroadcastArea)
                    ? await Client.getWaves({ support: message.Support, broadcastArea: message.BroadcastArea }).catch(e => ([]))
                    : []
                if (waves) {
                    const ojdProps = _properties.find(p => p.Id == "OjdYear");
                    ojdProps.ValueChoices = waves.map(w => ({ rid: w as string, value: w as string }));
                    ojdProps.Sort = ((a, b) => b.value.localeCompare(a.value));
                }
                onChangeWave(false);
            }
            const adCreationProp = _properties.find(p => p.Id == "AdCreation");
            if (adCreationProp) {
                let visuals: ref_Visuals[] = [];
                if (message.Campaign)
                    visuals = await Client.searchVertexTyped(ref_Visuals, { Campaign: message.Campaign, Active: true });
                adCreationProp.ValueChoices = visuals.map(v => ({ rid: v['@rid'], value: v.Name }));

                if (message.ModelProperties["AdCreationOther"])
                    adCreationProp.ValueChoices.push({ value: message?.ModelProperties["AdCreationOther"], rid: otherRid } as any);
                if (visuals.length == 1 && !message?.ModelProperties["AdCreation"]) {
                    message.ModelProperties["AdCreation"] = visuals[0]['@rid'];
                    dispatch(setMessageSync(message));
                }
            }

            setMessageModelMgr(_messageModelMgr);
            setProperties(_properties);
            setKpis(_kpis);
            setLoaded(true);
            setLoading(false);
        })
    }, [message.Support, message.BroadcastArea])


    const isInfoDisable = (info: IModelMessageInfo) => {
        return [eKPI.Total, eKPI.GrossFormat, eKPI.GrossFormatRate, eKPI.GrossFormatValRate].includes(info.Name as any);// || disabledByWave.includes(info.Id);
    }

    const applyOrder = (properties: lnk_HasPropertyTypeExtended[]): lnk_HasPropertyTypeExtended[] => {
        return [...properties.filter(p => p.Rank).sort((a, b) => a.Rank - b.Rank), ...properties.filter(p => !p.Rank)];
    }

    const onChangeWave = async (force: boolean) => {
        // disabledByWave = [];
        await UpdateOJDData(message, force);
        dispatch(setMessageSync(message));
    }

    const getSortedValueChoices = (p: lnk_HasPropertyTypeExtended) => {
        return [...(p.ValueChoices ?? [])].sort(p.Sort ?? ((a, b) => a.value.localeCompare(b.value)));
    }

    const generateInfos = (name: string, infos: IModelMessageInfo[]) => {

        const { FullWidth, TextEditor, Upload, Website } = groupByTag(infos);

        const getXs = (propKey) => FullWidth.includes(name) || FullWidth.includes(propKey)
            ? 12
            : 6;

        const getClassName = (propKey, pos: "left" | "right") => FullWidth.includes(name) || FullWidth.includes(propKey)
            ? "message_details_full"
            : (pos == 'right' ? "message_details_rightcombo" : "message_details_leftcombo")

        let currentPos: 'left' | 'right' = 'right';
        return (<>
            {infos.length > 0 && <Grid item xs={12} className="adw-title">{Trad(name)}</Grid>}
            <Grid container className='block-container'>
                {infos?.map((p, idx) => {
                    if (IsNumber(p.ValueType) && !message.KPIs.hasOwnProperty(p.Id))
                        (message.KPIs as any)[p.Id] = 0;

                    const xs = getXs(p.Id)
                    if (xs == 12) currentPos = 'right';
                    else if (currentPos == 'left' && xs == 6) currentPos = 'right';
                    else currentPos = 'left';

                    return (<Grid key={p.Id} item xs={xs} className={getClassName(p.Id, currentPos)}>
                        <div style={{ width: "100%" }}>
                            {Upload.includes(p.Name) && <Grid key={p.Id} item xs={12} className={"message_details_full"}>
                                <ContainerComponent title={`${Trad(p.Name)} | ${Trad("files")}`} className="auto-height no-padding no-margin">
                                    <FilesPicker rid={message['@rid']} autoComplete attachmentsIds={message.attachments} type={p.Name} onChange={(attachmentId, deleted) => {
                                        if (deleted) {
                                            const index = message.attachments.indexOf(attachmentId);
                                            message.attachments.splice(index, 1);
                                        } else {
                                            message.attachments = [...(message.attachments || []), attachmentId];
                                        }
                                        dispatch(setMessageSync(message));
                                    }} />
                                </ContainerComponent>
                            </Grid>}
                            {!(p as any).ValueChoices &&
                                (p.ValueType != eKPIType.Date && p.ValueType != eKPIType.DateTime &&
                                    (Website.includes(p.Name) ?
                                        <WebsiteTextBox title={`${Trad(p.Name)} | ${Trad("link")}`}
                                            value={message.ModelProperties[p.Id]}
                                            onChange={(v) => {
                                                message.ModelProperties[p.Id] = v;
                                                dispatch(setMessageSync(message))
                                            }}
                                        />
                                        :
                                        <MetricsTextfield
                                            message={message}
                                            modelInfo={p}
                                            isTextEditor={TextEditor.includes(p.Name)}
                                            isDisable={() => isInfoDisable(p)}
                                            getValue={() => messageModelMgr.GetInfoValue(message, p)}
                                            onChange={(_message) => dispatch(setMessage(_message))} />
                                    )
                                ) ||
                                ((p.ValueType == eKPIType.Date || p.ValueType == eKPIType.DateTime) &&
                                    <div style={{ overflow: 'visible', width: "100%" }}>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <SimpleDatePicker
                                                label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${p.Name}`, ref_Messages)}
                                                defaultValue={messageModelMgr.GetInfoValue(message, p)}
                                                resetDate={() => {
                                                    message.ModelProperties[p.Id] = null;
                                                    dispatch(setMessageSync(message));
                                                }}
                                                onChange={(event) => {
                                                    message.ModelProperties[p.Id] = event.value;
                                                    dispatch(setMessageSync(message));
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {(p as lnk_HasPropertyTypeExtended).ValueChoices &&
                                <AdwAutocomplete
                                    key={p.Name}
                                    options={getSortedValueChoices(p as lnk_HasPropertyTypeExtended)}
                                    value={messageModelMgr.GetInfoValue(message, p)}
                                    onChange={(event: any, value: any) => {
                                        if (!message.ModelProperties)
                                            message.ModelProperties = {};
                                        message.ModelProperties[p.Id] = value?.rid;
                                        if (p.Id == "AdCreation") {
                                            if (message.ModelProperties[p.Id] != "#-1:-1")
                                                message.ModelProperties["AdCreationOther"] = null;
                                            else
                                                message.ModelProperties["AdCreationOther"] = value?.value;
                                        }
                                        if (p.Id == "OjdYear") onChangeWave(true);
                                        else dispatch(setMessageSync(message));
                                    }}
                                    getOptionLabel={(v => v["@class"] ? TradValue(v["@class"], "Name", v.value) : v.value)}
                                    renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${p.Name}` as any, ref_Messages)} />} />
                            }
                        </div>
                    </Grid>
                    )
                })}
            </Grid>
        </>)
    }

    return (<>
        {loaded && categories.map(category => generateInfos(category.name, [...applyOrder(properties?.filter(p => p.Tags?.includes(category.name))),
        ...kpis?.filter(e => category.metrics?.includes(e.Name)).sort((e1, e2) => category.metrics.indexOf(e1.Name) - category.metrics.indexOf(e2.Name))]
        )
        )}</>)

}
