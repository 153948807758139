import * as React from 'react'
import { Tooltip } from '@material-ui/core';
import { TooltipManager } from 'adwone-lib';

class TState {
    position: {
        x: number,
        y: number
    }

}
export class GenericTooltipProps {
    tooltipContent: string | JSX.Element | (() => JSX.Element)
    children?: React.ReactElement<any, any>
    style?: any
    contentStyle?: any
    contentClassName?: string;
}

export function GenericTooltip(props: GenericTooltipProps) {
    const { tooltipContent, children, contentStyle, contentClassName } = props;
    if (typeof tooltipContent === "string")
        return <div onMouseOver={e => TooltipManager.Push({ target: e.target, text: tooltipContent })}>
            <div className={`tootlip-content ${contentClassName ?? ""}`} style={contentStyle}>
                {children}
            </div>
        </div>

    return <GenericTooltipComponent {...props} contentClassName={contentClassName} />;
}

export class GenericTooltipComponent extends React.Component<GenericTooltipProps, TState>{
    constructor(props: GenericTooltipProps) {
        super(props)
        let newState = new TState()
        newState.position = { x: undefined, y: undefined }
        this.state = newState
    }

    render() {

        const { tooltipContent, contentClassName } = this.props;
        const _content = typeof tooltipContent == "function" ? tooltipContent() : tooltipContent;

        return (
            <Tooltip
                title={_content}
                style={this.props.style}
                onMouseMove={e => {
                    this.state.position.x = e.pageX
                    this.state.position.y = e.pageY
                    this.forceUpdate()
                }}
                PopperProps={{
                    anchorEl: {
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: () => ({
                            x: this.state.position.x,
                            y: this.state.position.y,
                            top: this.state.position.y,
                            left: this.state.position.x,
                            right: this.state.position.x,
                            bottom: this.state.position.y,
                            width: 0,
                            height: 0,
                            toJSON: undefined
                        })
                    }
                }}
            >
                <div className={`tootlip-content ${contentClassName ?? ""}`} style={this.props.contentStyle}>
                    {this.props.children}
                </div>
            </Tooltip>
        )
    }
}