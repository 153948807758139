import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { ADWGridComponent, TStateBase } from 'adwone-lib/index';
import { ADWGrid, AdwRow, ADWColumn } from 'adwone-lib/index';
import { clone, firstOrDefaultWhere, GetHashCode, removeDiacritics } from 'hub-lib/tools.bin';
import { GroupDescriptor } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridFilterCell,
  GridDetailRowProps, GridCellProps, GridPageChangeEvent, GridHeaderCellProps, GridHeaderCell, GridExpandChangeEvent, GridProps
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';

import { GetCurrentLocale, Trad } from 'trad-lib';
import { ePropType, IsLink } from 'hub-lib/models/VertexProperty.bin';
import LockIcon from '@material-ui/icons/Lock';
import { GetCellClassName, GetCellTemplate } from 'format-lib/index.bin';
import { getIcon } from 'adwone-lib/index';
import { ScrollMode } from '@progress/kendo-react-treelist/dist/npm/ScrollMode';
import { GridFooterCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridFooterCellProps';
import { useState } from 'react';
import { CommandCellArg, DefaultCellActions } from '../DefaultCellActions';
import { GetColumnType } from './ColumnType.bin';
import {
  CustomCell
} from './CustomCell.bin';
import Loader from '../../layout/Loader';
import { CustomCheckbox } from './CustomCheckbox.bin';
import { ConfirmDeleteContent, GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { IsDebugMode } from '../../../utils/localstorage.bin';
import { Title } from '../../Tools';
import { FooterRowSize, RowSize, styleGridContainer } from '../../../styles/theme';
import { editCell } from '../../crossedTable/CrossedTableTelerikTree.bin';
import { TooltipManager } from '../../CustomTooltip';
import { eKPIType, IsPrice } from 'hub-lib/models/KPIsManager.bin';
import { eStatusType, ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { SortDescriptor, MyOrderBy } from 'hub-lib/export/Tools.bin';
import { IndicateurToString } from 'adwone-engine/index.bin';
import { VertexRidCell } from './VertexRidCell.bin';

const colSelectionSize = 38;
const colSize = 200;

require('moment/locale/fr.js');
require('moment/locale/en-gb.js');
moment.locale(GetCurrentLocale());

export class TProps<TRow> {
  headerFiltersChanged?: (filter: CompositeFilterDescriptor) => void;
  onSortChanged?: (sort: SortDescriptor[]) => void;
  commandCellWidth?: number;
  onExport?: (type: 'formated' | 'csv') => void;
  onRef?: (ref?: AdwTelerikGrid<TRow>) => void;
  grid: ADWGrid<TRow>;
  sort?: SortDescriptor[];
  selectable?: boolean;
  selectionMode?: 'basic' | 'multiple';
  uneditable?: boolean;
  addButton?: boolean;
  DetailsComponent?: React.ComponentType<GridDetailRowProps>;
  groupable?: boolean;
  isCopyDisable?: boolean;
  isDeleteDisable?: boolean;
  title?: string | JSX.Element;
  selectionActive?: any;
  customAddButtons?: JSX.Element;
  customButtons?: JSX.Element;
  customMasseActions?: JSX.Element;
  customCommandCell?: any;
  customCommandCellFunction?: any;
  customRemoveInlineContent?: (item: AdwRow<TRow>) => JSX.Element;
  gridHeight?: number | string;
  deleteInline?: boolean;
  customAddText?: string;
  customAddClass?: string;
  customAddButtonIcon?: JSX.Element;
  commandCellArgs?: Partial<CommandCellArg<AdwRow<TRow>>>;
  isSelectable?: (row: AdwRow<TRow>) => boolean;
  pluriCustom?: boolean;
  MySelectionCell?: any;
  onEdit?: (row: AdwRow<TRow>) => void;
  onDelete?: (rows: AdwRow<TRow>[]) => void;
  onDuplicate?: (row: AdwRow<TRow>) => void;
  onAddNew?: () => void;
  selectionChange?: (rows: AdwRow<TRow>[]) => void;
  classNameProvider?: (col: ADWColumn<any>, row: AdwRow<any>) => string;
  scrollable?: ScrollMode;
  hideToolbar?: boolean;
  footer?: boolean;
  textLoader?: string;
  loaderHeight?: number | string;
  onRowInitialized?: (rows: AdwRow<TRow>[]) => void;
  loaderClassname?: string;
  autoColumnWidth?: boolean;
  // viewMode?: "Table" | "CrossedTable" | "Scheduler";
}

class TState<TRow> extends TStateBase<TRow> {
  originalRows: AdwRow<TRow>[];
  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
  actionsColumn: number = 0;
  /**
   * visibility of base remove dialog component
   */
  rmDialogVisible: boolean = false;

  // viewMode: "Table" | "CrossedTable" | "Scheduler" = "Table";
}
export class AdwTelerikGrid<TRow> extends ADWGridComponent<
  TRow,
  TProps<TRow>,
  TState<TRow>
> {
  editField = 'inEdit';
  CommandCell: React.ComponentType<GridCellProps>;
  lastSelectedIndex: number = 0;

  static defaultProps = {
    classNameProvider: (col: ADWColumn<any>, row: AdwRow<any>) => row?.dataItem?.Active === false ? ' inactive_cell ' : ''
  };

  constructor(props: TProps<TRow>) {
    super(props);

    if (props.onAddNew || props.customAddButtons) this.addNew = props.onAddNew;
    if (props.onDelete || props.customMasseActions)
      this.removeGroup = props.onDelete;

    const paramsCommandCell = {
      edit: props.onEdit ?? this.enterEdit,
      add: this.add,
      discard: this.discard,
      update: this.update,
      cancel: this.cancel,
      copy: props.onDuplicate ?? this.copy,
      removeInline: this.removeInline,
      removeInlineContent: this.props.customRemoveInlineContent,
      deleteInline: this.props.deleteInline ?? false,
      editField: this.editField,
      isEditionDisable: this.props.uneditable,
      isCopyDisable: this.props.isCopyDisable ?? false,
      ...props.commandCellArgs
    }
    this.CommandCell = this.props.customCommandCell ? this.props.customCommandCell(paramsCommandCell, this.props.customCommandCellFunction) : DefaultCellActions(paramsCommandCell);
    props.onRef?.(this);
  }

  // ChangeView(viewMode: "Table" | "CrossedTable" | "Scheduler" = "Table") {
  //   this.setState({ viewMode });
  // }

  shouldComponentUpdate(np: TProps<TRow>, ns: TState<TRow>) {
    if (this.state?.originalRows !== ns.originalRows) return false;
    return true;
  }

  initializeState(): TState<TRow> {
    const state = new TState<TRow>();
    state.sort = this.props.sort ? [...this.props.sort] : [];
    return state;
  }

  async afertComputeRows(rows: AdwRow<TRow>[]): Promise<void> {
    if (this.state?.sort?.length || this.state?.filter?.filters?.length)
      await this.props.grid?.ComputeCellValues(rows);
  }

  /**
   * toggle the base remove dialog component
   */
  baseToggleRemoveDialog = () => {
    this.setState({ rmDialogVisible: !this.state.rmDialogVisible })
  }

  rowInitialized = async () => {
    this.setState({ originalRows: [...this.state.rows] }, () => this.props.onRowInitialized?.(this.state.originalRows));
  };

  enterEdit = async (dataItem: AdwRow<TRow>) => {
    const hasEditingItem = this.state.rows.some((p) => p.inEdit);
    if (!hasEditingItem) {
      this.setState({
        rows: await Promise.all(this.state.rows.map(async (item) => {
          const copyRow = (item.id === dataItem.id ? { ...item, inEdit: true } : item);
          await this.props.grid.ComputeCellValues([copyRow]);
          return copyRow;
        })),
      });
    }
  };

  removeGroup = (rows: AdwRow<TRow>[]) => {

    this.setState(
      { rows: this.state.rows.filter(r => !rows.some(d => d.id == r.id)) },
      () => this.props.grid.delete(rows));
  };

  add = async (dataItem: AdwRow<TRow>) => {
    dataItem.id = uuidv4();
    let result = await this.props.grid.create(dataItem);
    if (result) {
      dataItem.inEdit = undefined;
      this.state.originalRows.push(dataItem)
      this.forceUpdate()
    } else {
      dataItem.id = undefined;
    }
  };
  copy = (item: AdwRow<TRow>) => {
    let newRow = new AdwRow<TRow>();
    newRow.dataItem = this.props.grid.createData();
    let key: any;
    let columns = this.props.grid.Columns;
    for (key in item) {
      let findCoresp = firstOrDefaultWhere(columns, (e) => e['title'] === key)
        ?.IsEditable();
      if (
        findCoresp === true ||
        (findCoresp === undefined && key !== 'dataItem' && key !== 'id')
      ) {
        newRow[key] = item[key];
      }
    }
    let newDataItem = { ...newRow, inEdit: true, Discontinued: false };
    this.setState({
      rows: [newDataItem, ...this.state.rows],
    });
  };
  discard = (dataItem: AdwRow<TRow>) => {
    const rows = [...this.state.rows];
    this.removeItem(rows, dataItem);

    this.setState({ rows });
  };

  update = async (dataItem: AdwRow<TRow>) => {
    if (dataItem.id) {
      const rows = [...this.state.rows];
      const updatedItem: any = { ...dataItem, inEdit: undefined };
      if (await this.props.grid.update(updatedItem)) {
        this.updateItem(rows, updatedItem);
        this.setState({ rows });
      }
    } else {
      this.add(dataItem)
    }
  };

  cancel = (dataItem: AdwRow<TRow>) => {
    const originalItem = this.state.originalRows.find(
      (p: AdwRow<TRow>) => p?.id === dataItem.id
    );
    const rows = this.state.rows.map((item) =>
      item.id === originalItem?.id ? originalItem : item
    );

    this.setState({ rows });
  };

  updateItem = (data: AdwRow<TRow>[], item: AdwRow<TRow>) => {
    let index = data.findIndex(
      (p) => p === item || (item.id !== undefined && p.id === item.id)
    );
    if (index >= 0) {
      data[index] = { ...item };
    }
  };

  itemChange = (event: any) => {
    const rows = this.state.rows.map((item) =>
      item.id === event.dataItem?.id
        ? { ...item, [event.field]: event.value }
        : item
    );

    this.setState({ rows });
  };

  addNew = () => {
    let newRow = new AdwRow<TRow>();
    newRow.dataItem = this.props.grid.createData();
    newRow = { ...newRow, ...newRow.dataItem };

    let newDataItem = { ...newRow, inEdit: true, Discontinued: false };

    this.setState({
      rows: [newDataItem, ...this.state.rows],
    });
  };

  cancelCurrentChanges = () => {
    this.setState({ rows: [...this.state.originalRows] });
  };

  removeItem(data: AdwRow<TRow>[], item: AdwRow<TRow>) {
    let index = data.findIndex(
      (p) => p === item || (item.id && p.id === item.id)
    );
    if (index >= 0) {
      data.splice(index, 1);
    }
  }
  removeInline = (item: AdwRow<TRow>) => {
    let newRows = this.state.rows.filter(e => e != item)
    this.setState({
      rows: newRows
    }, () => this.props.grid.delete([item]))
  }

  selectionChange = (event: any) => {

    if (IsDebugMode()) {
      console.log('selectionChange this.state.rows', this.state.rows);
      console.log('selectionChange event', event);
    }

    this.state.rows.forEach(e => {
      if (e.id && e.id === event?.dataItem?.id) {
        e.selected = !event.dataItem.selected;
      }
    });

    this.forceUpdate()
    this.selectionChanged();
  };

  rowClick = (event: any) => {
    let last = this.lastSelectedIndex;
    const rows = [...this.state.rows];
    const current = rows.findIndex((dataItem) => dataItem === event.dataItem);

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      rows.forEach((item) => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      rows[i].selected = select;
    }
    this.setState({ rows });
  };

  expandChange = (event: any) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };
  _export: ExcelExport;
  _grid: any;

  /**
   * excel base export
   */
  export = () => {
    let promises: any = [];
    let exportData: any[] = [];

    let data = MyOrderBy(filterBy(this.state.rows, this.getFilter()), this.getSort());

    data.forEach((r) => {
      let exportRowData: any = {};
      exportData.push(exportRowData);
      this.props.grid.Columns.forEach((c) => {
        promises.push(
          c.getValue(r.dataItem).then((res: any) => {
            if (c.cellValue)
              return Promise.resolve(c.cellValue(res, r)).then((res2: any) => {
                exportRowData[c.bindingPath] = res2;
              });
            else {
              exportRowData[c.bindingPath] = res;
            }
          })
        );
      });
    });

    Promise.all(promises).then(() => {
      this._export.save(exportData, this._grid.columns.filter((c: any) => c.field !== 'selected'));
    });
  };

  private ComputeLeft = (idxCol: number) => {
    let { grid } = this.props;
    let calc = colSelectionSize;

    for (let idx = 0; idx < grid.Columns.length; idx++) {
      const col = grid.Columns[idx];
      if (idx === idxCol)
        return calc;
      if (col.frozen) {
        calc += Number.isNaN(Number(col.width)) ? colSize : Number(col.width);
      }
    }

    return calc;
  }

  private ComputeRight = (idxCol: number) => {
    let { grid } = this.props;
    let calc = 100;

    for (let idx = grid.Columns.length - 1; idx >= 0; idx--) {
      const col = grid.Columns[idx];

      if (idx === idxCol)
        return calc;

      if (col.frozen)
        calc += (Number.isNaN(col.width) ? colSize : Number(col.width))
    }

    return calc;
  }

  filterCell = (props: any) => {
    return <div className={'container-header-sticky'}><GridFilterCell {...props} /></div>
  }

  /**
   * selection cell (combobox)
   * @param props
   */
  MySelectionCell = (props: GridCellProps) => {

    if (this.props.isSelectable)
      return <CustomCheckbox props={{
        ...props,
        selectionChange: this.selectionChange,
        grid: this.props.grid,
        disabled: !this.props.isSelectable(props.dataItem)
      }} />

    if (!this.props.selectionActive || this.props.selectionActive(props)) {
      return <CustomCheckbox props={{ ...props, selectionChange: this.selectionChange, grid: this.props.grid }} />;

    } else {
      props.dataItem.Selectable = false;
      return <LockIcon style={{ color: '#DDDDDD' }} />;
    }
  };



  filterCellRight = (props: any) => {
    return <div className={'add-border-right container-header-sticky'}><GridFilterCell {...props} /></div>
  }

  filterCellLeft = (props: any) => {
    return <div className={'add-border-left container-header-sticky'}><GridFilterCell {...props} /></div>
  }

  filterCellLeftEmpty = () => {
    return <div className={'add-border-left container-header-sticky'}></div>
  }

  filterCellBoth = (props: any) => {
    return <div className={'add-border-right add-border-left container-header-sticky'}><GridFilterCell {...props} /></div>
  }

  createColumnComponent = (c: ADWColumn<any>, idx: number) => {
    let className = '';

    const id = c.baseColumn ? GetHashCode(IndicateurToString(c.baseColumn))?.toString() : undefined;

    const { grid, autoColumnWidth } = this.props;

    let filterCell = this.filterCell;

    const FooterCell = (props: GridFooterCellProps & { column: ADWColumn<any> }) => {
      let rows = filterBy(MyOrderBy(this.state.rows, this.getSort()), this.getFilter())
      //Les messages annulés sont décomptés du total
      if (this.props?.grid?.["objectPrototype"]?.name == ref_Messages.name)
        rows = rows.filter(r => r.dataItem["Status"] != eStatusType.Cancelled);
      return <FooterCellComponent {...props} rows={rows} />
    }

    const MyFooterCell = (props: GridFooterCellProps) => <FooterCell {...props} column={c} />
    if (c.frozen) {
      if (idx > 0 && !grid.Columns?.[idx - 1]?.frozen) {
        className += ' add-border-left ';
        filterCell = this.filterCellLeft;
      }

      if (!grid.Columns?.[idx + 1]?.frozen) {
        className += ' add-border-right ';
        if (filterCell === this.filterCellLeft) filterCell = this.filterCellBoth;
        else filterCell = this.filterCellRight;
      }
    }

    const isNumber = [ePropType.Integer, ePropType.Double].includes(c.dataType) || IsPrice(c.baseColumn?.valueType);
    const currentFrozLeft = this.ComputeLeft(idx);
    const currentFrozRight = this.ComputeRight(idx);
    const key = `col:${c.title}:${c.bindingPath?.toString()}`;
    const locked: any = c.frozen !== undefined;

    if (IsLink(c.dataType)) {
      return (
        <Column
          {...(id && { id })}
          key={key}
          resizable={true}
          locked={locked}
          headerCell={(props) => <HeaderColumn title={c.title} headerProps={props} />}
          headerClassName={className}
          filterCell={filterCell}
          field={c.bindingPath?.toString()}
          title={c.title}
          width={c.width ?? (autoColumnWidth ? "" : colSize)}
          cell={VertexRidCell<TRow>({
            vGrid: this.props.grid,
            dataProvider: (row: any) => this.props.grid.GetStoredData(row, c.bindingPath?.toString()),
            dataProviderFormater: this.props.grid.FormaterStoredData,
            bindingField: '@rid',
            cellValue: c.cellValue,
            editable: c.IsEditable(),
            onChange: this.props.grid.onChange,
            frozen: c.frozen,
            frozenLeftPx: currentFrozLeft,
            frozenRightPx: currentFrozRight,
            className,
            classNameProvider: (row) => this.props?.classNameProvider(c, row),
            grid: this
          })}
        />
      );
    }

    return (
      <Column
        {...(id && { id })}
        key={key}
        resizable={true}
        headerCell={(props) => <HeaderColumn title={c.title} headerProps={props} />}
        headerClassName={className}
        filterCell={filterCell}
        locked={locked}
        field={c.bindingPath?.toString()}
        title={Trad(c.title)}
        width={c.width ?? (autoColumnWidth ? "" : colSize)}
        footerCell={this.props.footer && isNumber && (this.state.rows.length > 0 ? MyFooterCell : null)}
        cell={CustomCell({
          vGrid: this.props.grid,
          dataType: c.dataType,
          classname: `${GetCellClassName(c.dataType)} column_${c.bindingPath?.toString()} ${className} `,
          textCell: GetCellTemplate(c.dataType),
          noTemplate: c.noTemplate,
          editable: c.IsEditable(),
          onEdit: c.onEdit,
          frozen: c.frozen,
          frozenLeftPx: currentFrozLeft,
          frozenRightPx: currentFrozRight,
          cellValue: c.cellValue,
          /*onChange: this.props.grid.onChange,*/
          classNameProvider: (row) => this.props?.classNameProvider(c, row),
          cell: c.cell,
          grid: this
        })}

        filter={GetColumnType(c.dataType)}
        editor={GetColumnType(c.dataType)}
        editable={c.IsEditable()}
      />
    );
  };

  /**
   * Click on header selection all
   * @param event
   */
  headerSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;

    // filerBy to only get displayed rows
    let filteredrows = filterBy(this.state.rows, this.getFilter());

    /** eventually get selectable rows */
    filteredrows = this.getSelectable(filteredrows);

    filteredrows.forEach(r => r.selected = checked);

    this.forceUpdate();
    this.selectionChanged();
  };

  /**
   * eventually get selectable rows
   * @param rows
   */
  private getSelectable = (rows: AdwRow<TRow>[]) => {
    let { isSelectable } = this.props;
    if (isSelectable)
      rows = rows.filter(r => isSelectable(r));
    return rows
  }

  selectRow = (row: AdwRow<TRow>) => {

    const { selectable, selectionMode } = this.props;

    if (!selectable)
      return;

    switch (selectionMode) {
      case 'multiple':
        row.selected = !row.selected;
        break;
      case 'basic':
      default:
        this.state.rows.forEach(r => r.selected = false);
        row.selected = true;
        break;
    }

    /** Doit appeler forceUpdate car selectionChanged ne refresh pas systématiquement le render */
    this.forceUpdate();
    this.selectionChanged();
  }

  /**
   * must be called when selection changed
   */
  selectionChanged = () => {
    // check if the behaviour is overriden
    let { selectionChange } = this.props;
    if (selectionChange) {
      const filteredrows = filterBy(this.state.rows, this.getFilter());
      selectionChange(filteredrows.filter((r) => r.selected));
    }
  }

  getCommandCellWidth = () => {
    const { customCommandCell, pluriCustom, commandCellArgs, deleteInline, commandCellWidth } = this.props;
    if (customCommandCell && !pluriCustom)
      return 50;
    if (commandCellWidth)
      return commandCellWidth;

    const additionalCommands = commandCellArgs?.additionalCommands;
    const isEditable = !this.props.uneditable && (commandCellArgs?.isEditable !== false);
    const isCopyable = !this.props.isCopyDisable && !commandCellArgs?.isCopyDisable;
    const isRemoveable = (commandCellArgs?.deleteInline);

    let width = (additionalCommands?.length ?? 0) * editCell;
    if (isEditable) width += editCell;
    if (isCopyable) width += editCell;
    if (isRemoveable) width += editCell;

    return width;
  }

  DrawComponent: () => JSX.Element = () => {

    let { grid, textLoader, title, loaderClassname } = this.props;
    let { rows, loading } = this.state;

    const containerHeight = this.props.gridHeight ?? styleGridContainer.messages.height;
    const loader = <div className={`loader-container ${loaderClassname ?? ''}`} style={{ height: this.props.loaderHeight ?? containerHeight }}>
      <Loader text={textLoader}></Loader>
    </div>

    if (!grid.Columns?.length || !rows) return loader;

    /** filter and order data to display  */
    let data: AdwRow<TRow>[] = MyOrderBy(filterBy(rows, this.getFilter()), this.getSort());

    /** selectable rows */
    let selectable = this.getSelectable(data);

    /** any row selected in screen, /!\ may have row selected that are filtered */
    const hasSelectedItem = data.some((p) => p.selected);

    const hasEditingItem = data.some((p) => p.inEdit);
    let currentLocale = GetCurrentLocale();
    return (
      <>
        {loading && loader}
        {<ExcelExport ref={(exporter) => (this._export = exporter)}>
          {/* toolbar */}
          {!this.props.hideToolbar &&
            <div className='clearfix custom-toolbar-adwtelerikgrid'>

              <div className="clearfix">
                {/** Title */}
                {title && <div style={{ float: 'left', display: 'block' }} className="clearfix">
                  <Title>{title}</Title>
                </div>}

                {/** Options: delete, exports etc ... */}
                <div style={{ float: 'right', minWidth: 250 }}>
                  <div style={{ display: 'inline' }} >

                    {/** OPTIONAL CUSTOM ADD BUTTONS */}
                    {!hasSelectedItem &&
                      this.props.customAddButtons}

                    {/** DEFAULT ADD BUTTON */}
                    {!hasSelectedItem && !this.props.customAddButtons && this.props.addButton && (
                      <CustomButton
                        style={{ float: 'right' }}
                        Label={this.props.customAddText ?? Trad('add')}
                        className={this.props.customAddClass ?? 'custom_btn_primary'}
                        startIcon={this.props.customAddButtonIcon ?? getIcon('plus')}
                        disabled={hasEditingItem}
                        onClick={this.addNew} />
                    )}

                    {/** OPTIONAL CUSTOM BUTTONS */}
                    {!hasSelectedItem &&
                      this.props.customButtons}

                    {/** DEFAULT REMOVE */}
                    {hasSelectedItem && !this.props.customMasseActions && this.props.addButton && (
                      <>
                        {/** default remove button */}
                        <CustomButton
                          style={{ float: 'right', marginRight: 10 }}
                          Label={Trad('remove')}
                          disabled={this.props.isDeleteDisable ?? false}
                          className="custom_btn_danger"
                          startIcon={getIcon('delete')}
                          onClick={this.baseToggleRemoveDialog} />
                      </>
                    )}

                    {/** OPTIONAL CUSTOM MASS ACTIONS */}
                    {hasSelectedItem &&
                      this.props.customMasseActions}

                  </div>
                </div>
              </div>

              {/** Potential toolbar */}
              <div>{this.props.children}</div>

            </div>
          }
          {/** default remove dialog */}
          {this.state.rmDialogVisible && this.DefaultRemoveDialog(data)}
          <div className="with-borders">
            {!loading && <PagedGrid
              grid={this}
              selectableRows={selectable}
              data={data} />}
          </div>
        </ExcelExport>}
      </>
    );
  };

  /**
   * default remove dialog component
   * @param data grid data
   */
  private DefaultRemoveDialog(data: AdwRow<TRow>[]) {

    let selectedRows = data.filter(d => d.selected);

    return <GenericDialog
      open={this.state.rmDialogVisible}
      dialogTitle={Trad('confirmation')}
      handleClose={this.baseToggleRemoveDialog}
      submitAction={() => {
        this.removeGroup(selectedRows);
        this.baseToggleRemoveDialog();
      }}
      submitClass="custom_btn_danger"
      submitTitle={Trad('yes')}
      startIcon={getIcon('delete')}
      actions={true}>
      <p>{ConfirmDeleteContent(selectedRows)}</p>
    </GenericDialog>
  }

  private correctSort(sort: SortDescriptor[]) {
    sort?.forEach((s) => {
      let col = firstOrDefaultWhere(
        this.props.grid.Columns,
        (c) => c.bindingPath === s.field
      );
      if (IsLink(col?.dataType) || col?.cellValue) {
        s.field += '_cellValue';
        if (col?.valueIsArray)
          s.field += '_array';
      }
    });

    return sort;
  }

  private correctFilter(filters: Array<FilterDescriptor | CompositeFilterDescriptor>) {
    const { grid } = this.props;
    filters?.forEach((f: any) => {
      let col: ADWColumn<TRow> = null;
      if (f.field) {
        col = grid.Columns.find(c => c.bindingPath === f.field);

        if (IsLink(col?.dataType) || col?.cellValue || col?.dataType == ePropType.String) {
          f.field += '_cellValue';
          if (col.valueIsArray)
            f.field += '_array';
        }
      }
      if (f.value && col?.dataType != ePropType.Boolean)
        f.value = removeDiacritics(f.value);
    });
    const adapted = filters?.map((s: any) => ({ field: (e) => e[s.field], operator: s.operator, value: s.value }))
    return adapted;
  }

  private getSort() {
    let copy = (this.state.sort ?? []).map((s) => {
      return { ...s };
    });
    return this.correctSort(copy);
  }

  private getFilter() {
    const { filter } = this.state;
    if (!filter) return undefined;

    const res = this.correctFilter(clone(filter?.filters));
    const copyTotal = { ...this.state.filter, filters: res };

    if (IsDebugMode()) {
      console.log('Grid filters:')
      console.log(copyTotal)
    }

    return copyTotal;
  }
}

let timeout = null;
type PagedGridProps = {
  grid: AdwTelerikGrid<any>,
  selectableRows: any[],
  data: any[]
}

function OptimizedGrid(props: GridProps & { ref: any }) {
  const { scrollable } = props;
  const [skip, setSkip] = useState(0);

  let dataSlice = props.data;
  if (scrollable == 'virtual')
    dataSlice = (props.data as any[]).slice(skip, skip + 50);

  return <Grid
    {...props}
    {...(scrollable == 'virtual' ? {
      onPageChange: (event: GridPageChangeEvent) => {
        if (skip != event.page.skip)
          setSkip(event.page.skip);
      },
      skip: skip,
      pageSize: 50
    } : {})}
    data={dataSlice}>
    {props.children}
  </Grid>
}


function PagedGrid({ grid, selectableRows, data }: PagedGridProps) {

  const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [height, setHeight] = useState(0);
  const [groups, setGroups] = useState<GroupDescriptor[]>([]);

  let scrollable: ScrollMode = grid.props.scrollable ?? 'virtual';
  if (grid.props.groupable)
    scrollable = 'scrollable';

  // let dataSlice = data;
  // if (scrollable == 'virtual')
  //   dataSlice = data.slice(skip, skip + 50);

  const container: any = React.useRef();

  // React.useEffect(() => {
  //   const checkHeight = () => {
  //     if (container?.current) {
  //       const offsetHeight = container.current.offsetHeight;
  //       if (offsetHeight != height)
  //         setHeight(offsetHeight);
  //     }
  //   }
  //   window.addEventListener('resize', checkHeight);
  //   checkHeight();
  //   return () => window.removeEventListener('resize', checkHeight)
  // });

  React.useEffect(() => {
    if (grid._grid) {
      const rowIndex = data.findIndex((e) => e.selected);
      if (rowIndex >= 0) {
        setTimeout(() => {
          if (grid._grid?.vs?.tableBody?.children?.length) {
            const elHeight = grid._grid.vs.tableBody.children[0].offsetHeight;
            const containerHeight = grid._grid.vs.containerRef.current.offsetHeight;
            const size = Math.floor(containerHeight / elHeight);
            if (data.length - rowIndex < size) {
              grid._grid.scrollIntoView({ rowIndex: data.length - size });
            } else {
              grid._grid.scrollIntoView({ rowIndex });
            }
          }
        }, 200);
      }
    }
  }, [grid]);

  const baseHeight = grid.props.gridHeight ?? styleGridContainer.messages.height;
  const computedHeight = typeof baseHeight == 'number'
    ? `calc(${baseHeight}px - ${FooterRowSize}px)`
    : baseHeight;

  // if (grid.props.groupable && groups?.length > 0) {
  //   const newDataState = groupBy(dataSlice, groups.map(g => ({ field: GetHashCode(g.field)?.toString() + '_formated' }))) as GroupResult[];

  //   newDataState.forEach(d => {
  //     d.value = `(${d.items.length}) ${d.value}`;
  //   });

  //   setGroupIds({ data: newDataState, group: groups });
  //   dataSlice = setExpandedState({
  //     data: newDataState,
  //     collapsedIds: collapsedState,
  //   });
  // }

  const onExpandChange = React.useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  return <div style={{ height: baseHeight }} ref={container}>
    <div>
      <OptimizedGrid
        style={{ width: '100%', height: computedHeight }}
        resizable={true}
        onColumnResize={e => {
          e.columns?.forEach((c, i) => {
            const col = grid.props.grid.Columns[i - 1];
            if (!col) return;
            col.width = c.width;
          });
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            forceUpdate();
          }, 5);
        }}

        rowHeight={RowSize}
        scrollable={scrollable}
        data={data}
        total={data.length}

        // {...(scrollable == 'virtual' ? {
        //   onPageChange: (event: GridPageChangeEvent) => {
        //     if (skip != event.page.skip)
        //       setSkip(event.page.skip);
        //   },
        //   skip: skip,
        //   pageSize: 50
        // } : {})}

        ref={(gridRef) => {
          grid._grid = gridRef;
        }}

        onItemChange={grid.itemChange}
        editField={grid.editField}
        selectedField="selected"
        onSelectionChange={grid.selectionChange}
        expandField="expanded"
        // onRowClick={this.rowClick}
        onHeaderSelectionChange={grid.headerSelectionChange}
        className="grid_table"

        /** Row details */
        {...(grid.props.DetailsComponent ? {
          detail: grid.props.DetailsComponent,
          onExpandChange: grid.expandChange
        } : {})}

        /** Grouping */
        {...(grid.props.groupable ? {
          groupable: true,
          onGroupChange: e => {
            setGroups(e.group);
            // console.log('onGroupChange', e)
          },
          group: groups,
          onExpandChange: onExpandChange
        } : {})}

        sort={[...(grid.state.sort ?? [])]}
        sortable={{
          mode: 'multiple',
        }}

        onSortChange={async (e) => {
          if (e?.sort?.length > 0)
            await grid.props.grid.ComputeCellValues(grid.state.rows);
          grid.setState({ sort: [...e.sort] }, () => {
            grid.props.onSortChanged?.(e.sort);
            grid.selectionChanged();
          })
        }}
        reorderable
        filterable
        filter={grid.state.filter}
        onFilterChange={async (e) => {
          if (e?.filter?.filters)
            await grid.props.grid.ComputeCellValues(grid.state.rows);

          grid.setState({ filter: e.filter },
            () => {
              grid.props.headerFiltersChanged?.(e.filter);
              grid.selectionChanged();
            });
        }}

        onColumnReorder={e => {

          // e.columns[0].id

          grid.props.grid.onReorder(e.columns.sort((a, b) => a.orderIndex - b.orderIndex).map(c => c.id));
        }}
      >
        <Column
          locked
          headerClassName='k-grid-myselection-cell'
          orderIndex={0}
          field={grid.props.selectable ? 'selected' : ''}
          width={colSelectionSize}
          filterable={false}
          reorderable={false}
          resizable={false}
          cell={(props) => props.rowType == 'groupHeader' ? <></> : <td className="k-grid-content-sticky k-grid-myselection-cell">{grid.props.selectable ? grid.MySelectionCell(props) : <></>}</td>}
          headerSelectionValue={selectableRows.length > 0 && !selectableRows.some(d => !d.selected)}
        />

        {grid.props.grid.Columns.map(grid.createColumnComponent)}
        {!grid.props.uneditable && <Column
          locked
          resizable={false}
          key={grid.state.actionsColumn}
          filterCell={grid.filterCellLeftEmpty}
          headerClassName={'add-border-left'}
          cell={grid.CommandCell}
          width={grid.getCommandCellWidth()}
          filterable={true}
          editable={false}
          reorderable={false}
        />}
      </OptimizedGrid>
    </div>
    <FooterCellTotalElement count={data.length} />
  </div >
}

type HeaderColumn = { title: string, headerProps: GridHeaderCellProps }
function HeaderColumn({ title, headerProps }) {
  return <span onMouseOver={e => TooltipManager.Push({ target: e.target, text: title })}><GridHeaderCell {...headerProps} /></span>
}

type FooterCellComponentState = {
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits: number,
  currency?: string

}

export function FooterCellComponent<TRow>(props: GridFooterCellProps & { column: ADWColumn<any>, rows: AdwRow<TRow>[] }) {

  const isMounted = React.useRef(false);
  const { column, rows } = props;
  const [state, setState] = React.useState<FooterCellComponentState>(undefined);

  React.useEffect(() => {
    if (state) return;
    isMounted.current = true;
    const isPrice = IsPrice(column.baseColumn.valueType);
    const isNumber = [eKPIType.Number, eKPIType.Decimal].includes(column.baseColumn.valueType);

    if (isPrice || isNumber) {
      Promise.all(rows.map(async r => await column.getKPIValue(r.dataItem)))
        .then(async values => {
          const aggregatedValue: number = values.reduce((a, b) => ((a || 0) + (b || 0)), 0);

          if (typeof aggregatedValue != "number")
            return;

          if (isPrice) {
            // TODO: refacto d'ancien code, mieux, mais à faire différemment
            const currencies = new Set<string>();
            for (const row of rows) {
              const cellValue = await column.getValue(row.dataItem);
              if (typeof cellValue == "string") {
                const chunks = cellValue.split(" ");
                if (!chunks.length) return; // 1 ligne sans currency, on ne fait pas de total
                currencies.add(chunks[chunks.length - 1]);
                if (currencies.size > 1) return; // plusieurs currencies, on ne fait pas de total
              }
            }

            if (isMounted.current)
              setState({
                value: aggregatedValue,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                currency: Array.from(currencies)?.[0]
              });
          }

          if (isNumber) {
            if (isMounted.current)
              setState({
                value: aggregatedValue,
                minimumFractionDigits: column.baseColumn.valueType == eKPIType.Decimal ? 2 : 0,
                maximumFractionDigits: column.baseColumn.valueType == eKPIType.Decimal ? 2 : 0
              });
          }
        });
    }
    return () => {
      isMounted.current = false;
    }
  })

  if (!state) return <TDFooter></TDFooter>

  const { value, minimumFractionDigits, maximumFractionDigits, currency } = state;
  return <TDFooter>
    {column.footerCellValue ? column.footerCellValue(rows.map(r => r.dataItem), value, currency) :
      value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits, maximumFractionDigits }) + (currency ?? "")}
  </TDFooter>
}

function TDFooter(props) {
  return <td className="number-cell-data" style={{ fontSize: 14, fontFamily: 'Roboto' }}>
    {props.children}
  </td>
}

type FooterCellTotalElementProps = { count: number }
export function FooterCellTotalElement({ count }: FooterCellTotalElementProps) {
  return <div className='FooterCellTotalElement' style={{ height: FooterRowSize }}>
    <span>{`${count} ${count <= 1 ? Trad('element') : Trad('elements')}`}</span>
  </div>
}