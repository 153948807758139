import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import React from "react";
import { DiscountGridRow } from "../DiscountGridRow";
import { ComputationBaseCellComponent } from "./ComputationBaseCellComponent";
import { RateCellComponent } from "./RateCellComponent";
import { AmountCellComponent } from "./AmountCellComponent";
import { CommandCellComponent } from "./CommandCellComponent";
import { CascadeRankCellComponent } from "./CascadeRankCellComponent";
import { Trad } from "trad-lib";

const rowStyle: React.CSSProperties = {
    height: 28,
    alignItems: 'center',
}

type DiscountClassGridRowProps = { discount: ref_Discount };
export function DiscountClassGridRow({ discount }: DiscountClassGridRowProps) {
    const dClass = DiscountManager.GetDiscountClassesLoaded().find(cl => cl["@rid"] == discount.DiscountClass);
    return <DiscountGridRow
        rowStyle={rowStyle}
        firstCell={<CascadeRankCellComponent discount={discount} />}
        labelCell={<span>{Trad(dClass.Name)}</span>}
        intervalCell={<ComputationBaseCellComponent discount={discount} />}
        rateCell={<RateCellComponent discount={discount} />}
        amountCell={<AmountCellComponent discount={discount} />}
        commandCell={<CommandCellComponent discount={discount} />}
    />
}