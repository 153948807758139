import * as React from 'react'

import { CrossedTableConfig } from './CrossedTableConf.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { Column, Row, Table } from 'adwone-engine/types.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import Loader from '../layout/Loader';
import { propertyOf } from 'hub-lib/tools.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { Indicateur, IsIndicateurReturned } from 'adwone-engine/index.bin';
import { ADWProperty } from 'hub-lib/types';

export class TProps<TCol> {
    onRef?: (ct: ACrossedTable<any, TCol>) => void;
}

export class CrossedTableState<TCol> {

    rows: Row<ref_Messages>[] = [];
    cols: Column[] = [];

    crossedTable: CrossedTableConfig;
    columns: TCol[] = [];
    version: number = 0;

    isSearchPanelVisible: boolean = false;

    loading: boolean = false;
}

export abstract class ACrossedTable<TP extends TProps<TCol>, TCol> extends React.Component<TP, CrossedTableState<TCol>> {

    abstract createColumnParent: (id: any, header: string, children: JSX.Element[]) => any;
    abstract createColumnIndicateur: (nbVentils: number, index: number, id: string, header: string, width: number, calculateCellValue: (row: Row<ref_Messages>) => { Formated: string, Value: any }, type?: eKPIType) => TCol;
    abstract createTreeList: (rows: Row<ref_Messages>[], columns: TCol[], rowventils: (ADWProperty | Indicateur)[]) => JSX.Element;

    constructor(props: TP) {
        super(props);
        let newState = new CrossedTableState<TCol>();
        this.state = newState;
    }

    componentDidMount() {
        this.props?.onRef?.(this);
    }

    shouldComponentUpdate(prev: TProps<TCol>, next: CrossedTableState<TCol>) {
        if (next?.loading != this.state?.loading) return true;
        if (next?.isSearchPanelVisible != this.state?.isSearchPanelVisible) return true;
        if (next?.version != this.state?.version) return true;
        return false;
    }

    beforeLoading = (data: Table<ref_Messages>) => {
        // nothing
    }

    Loading = () => {
        this.setState({ loading: true })
    }

    Load = (table: Table<ref_Messages>, crossedTable: CrossedTableConfig) =>
        new Promise((res, rej) => {
            try {
                if (crossedTable.End.getTime() <= crossedTable.Start.getTime())
                    crossedTable.End = new Date(crossedTable.Start);

                this.beforeLoading(table);
                this.setState({
                    crossedTable,
                },
                    () => this.setState({
                        loading: false,
                        rows: table?.Rows ?? [],
                        cols: table?.Columns ?? [],
                        columns: this.GenerateKPIColumns(),
                        version: this.state.version + 1
                    }, () => res(null)));
            } catch (error) {
                rej(error);
            }
        })


    GenerateKPIColumns = (): TCol[] => {
        let res: TCol[] = [];
        const nbVentils = this.state.crossedTable?.rowventils?.length;
        this.state.crossedTable?.selectedIndicateurs?.forEach((indc, i) => {
            const calculateCellValue = (data: Row<ref_Messages>) => {
                const cell = data.ValuesTotal?.[i];
                return { Formated: cell?.Formated ?? "", Value: cell?.Value };
            };
            const col = this.createColumnIndicateur(nbVentils, i, `col_${indc.field}_${i}_$field`, indc.name, 151, calculateCellValue, indc.valueType);
            res.push(col);
        });

        return res;
    }

    render() {

        let { rows, crossedTable: config, columns, loading } = this.state;
        let rowventils = config?.rowventils;

        if (config?.selectedIndicateurs?.find(ind => IsIndicateurReturned(ind))) {
            /** Then force ventil to returned currency */
            if (rowventils && !rowventils.some(r => r.field === propertyOf<ref_Messages>("ReturnedCurrency")))
                rowventils = [{
                    field: propertyOf<ref_Messages>("ReturnedCurrency"),
                    type: "@rid",
                    linkedClass: ref_Currencies.name
                }, ...rowventils]
        }

        if (!rowventils?.length) {
            rows = rows?.[0]?.Children ?? [];
        }

        return (<>
            {loading && <Loader />}
            {this.createTreeList(rows, columns, rowventils)}
        </>);
    }
}




