
import * as React from 'react'
import moment from 'moment';
import Events from './Events.bin'
import { ref_Publications } from 'hub-lib/models/ref_Publications.bin';
import { Calendar, CalendarCell } from '@progress/kendo-react-dateinputs';
import { propertyOf } from 'hub-lib/tools.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { GenericTooltip } from '../../../ConfigurableComponents/GenericTooltip.bin';
import { TradProp } from 'trad-lib';
import { store } from '../../../../redux/store';

export type TPublication = {
    date: moment.Moment,
    end: moment.Moment,
    dated: string,
    number: string,
    theme: string,
    category: string,
    source: string
}

class TState {
    selectedDay: moment.Moment;
    showEvents: boolean = false;
}
class TProps {
    duplicatesMessages: ref_Messages[];
    date: Date;
    publications: ref_Publications[]
    applyPublication: (publication: ref_Publications, date: Date) => void
}
export default class PublicationsCalendar extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);
        const newState = new TState()
        newState.selectedDay = this.props.date ? moment(this.props.date).startOf("day") : moment().startOf("day")
        this.state = newState
    }

    select = (day: moment.Moment) =>
        this.setState({
            selectedDay: day,
            showEvents: true
        });

    render() {
        const currentSelectedDay = this.state.selectedDay;
        return (
            <div className="calendar_publications_container">
                <div className="calendar-rectangle publications" style={{ width: "50%", float: "left", boxShadow: "none" }}>
                    <div id="calendar-content" className="calendar-content">
                        <Calendar
                            value={currentSelectedDay.toDate()}
                            onChange={e => this.select(moment(e.value))}
                            cell={cellProps => {
                                // check s'il y a des doublons
                                const duplicates = this.props.duplicatesMessages.filter(d => moment(d.Start).isSame(cellProps.value, 'day'));
                                const events = this.props.publications;

                                console.log('events', cellProps.value, events)

                                // Si une date est dans les événements, on ajoute un className
                                const className = events.some(e => e.Publication?.getTime?.() == cellProps.value?.getTime?.()) ? "has-publication-event" : "";
                                return <td style={{ position: 'relative' }}>
                                    {duplicates.length > 0
                                        ? <GenericTooltip tooltipContent={() => <DuplicatedMessagesTooltip messages={duplicates} />}>
                                            <>
                                                <CalendarCell {...cellProps} className={className} />
                                                <div className='duplicated-message' />
                                            </>
                                        </GenericTooltip>
                                        : <CalendarCell {...cellProps} className={className} />}
                                </td>
                            }}
                        />
                    </div>
                </div>
                <div className="events-calendar publications" style={{ width: "50%", float: "right", minHeight: "100%", boxShadow: "none", marginBottom: 0 }}>
                    <Events
                        day={currentSelectedDay.toDate()}
                        applyPublication={this.props.applyPublication}
                        events={this.props.publications} />
                </div>
            </div>
        )
    }
}

const RowTooltip = ({ label, value, hasDuplication }) => <div className="row-tooltip clearfix">
    <div style={{ float: "left" }} className={`tooltip-duplicated-message-row ${hasDuplication ? 'has-duplicated' : ''}`} />
    <div style={{ float: "left", width: 100 }}>{`${label}:`}</div>
    <div style={{ float: "left" }}>{value}</div>
</div>

const hierarchyProperties: (keyof ref_Messages)[] = ['AdvertiserGroup', 'Advertiser', 'Brand', 'Product', 'Campaign'];

const RowTooltipProp = ({ property, message }) => {
    const messageEdited = store.getState().messageEditor.getMessage();

    // iterate over hierarchy properties and check which one is the last common
    let lastCommonProperty: keyof ref_Messages = null;
    for (let i = 0; i < hierarchyProperties.length; i++) {
        const testedProperty = hierarchyProperties[i];
        if (!message[testedProperty] || !messageEdited[testedProperty] || message[testedProperty] != messageEdited[testedProperty])
            break;
        lastCommonProperty = testedProperty;
    }

    return <RowTooltip label={TradProp(propertyOf<ref_Messages>(property), ref_Messages)} value={message[property + 'Name']} hasDuplication={lastCommonProperty == property} />
}
type DuplicatedMessagesTooltipProps = { messages: ref_Messages[] }
export function DuplicatedMessagesTooltip({ messages }: DuplicatedMessagesTooltipProps) {
    return <div>
        {messages.map((m, i) => <div key={`DuplicatedMessagesTooltip-${i}-${m['@rid']}`}>
            <RowTooltipProp property={'AdvertiserGroup'} message={m} />
            <RowTooltipProp property={'Advertiser'} message={m} />
            <RowTooltipProp property={'Brand'} message={m} />
            <RowTooltipProp property={'Product'} message={m} />
            <RowTooltipProp property={'Campaign'} message={m} />
            {i < messages.length - 1 && <hr />}
        </div>)}
    </div>
}