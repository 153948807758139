import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import React from "react";
import { RootState, store } from "../../../../../../redux/store";
import { setMessage } from "../../../../../../redux/messageEditorSlice";
import { useSelector } from "react-redux";
import { NumericTextBox, NumericTextBoxHandle } from "@progress/kendo-react-inputs";
import { GetCellTemplate, GetNumericFormat } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";

let discountDragged: ref_Discount;

type CascadeRankCellComponentProps = { discount: ref_Discount };
export function CascadeRankCellComponent({ discount }: CascadeRankCellComponentProps) {
    const discountMode = useSelector((root: RootState) => root.messageEditor.message.DiscountMode);
    if (discountMode == "cumul")
        return <RankCellComponent discount={discount} />
    return <CascadeCellComponent discount={discount} />
}

type CascadeCellComponentProps = { discount: ref_Discount };
function CascadeCellComponent({ discount }: CascadeCellComponentProps) {
    const cursor: React.CSSProperties["cursor"] = discount.LastPosition ? "not-allowed" : "move";
    return <div style={{ textAlign: 'center', cursor }}
        draggable="true"
        onDragStart={(e) => {
            if (discount.LastPosition) return;
            e.dataTransfer.clearData();
            e.dataTransfer.setData("text/plain", "");
            discountDragged = discount;
            //dragStart(discount);
        }}
        onDrop={(e) => {
            if (discount.LastPosition) return;
            e.preventDefault();
            discountDragged = null;

        }}
        onDragEnd={(e) => {
            if (discount.LastPosition) return;
            e.preventDefault();
            discountDragged = null;
        }}
        onDragOver={(e) => {
            if (discount.LastPosition) return;
            e.preventDefault();

            if (discountDragged.DiscountClass == discount.DiscountClass) return;

            const message = store.getState().messageEditor.getMessage();
            const reorderedData = [...message.Discounts];
            const prevIndex = reorderedData.findIndex(p => p.DiscountClass == discountDragged.DiscountClass);
            const nextIndex = reorderedData.findIndex(p => p.DiscountClass == discount.DiscountClass);
            reorderedData.splice(prevIndex, 1);
            reorderedData.splice(nextIndex, 0, discountDragged);

            message.Discounts = reorderedData;
            store.dispatch(setMessage(message));
        }}>
        <span className="k-icon k-i-reorder" style={{ cursor, textAlign: 'center' }} />
    </div>
}

type RankCellComponentProps = { discount: ref_Discount };
function RankCellComponent({ discount }: RankCellComponentProps) {

    const value = discount.Rank;

    const [isEditing, setIsEditing] = React.useState(false);
    const ref = React.createRef<NumericTextBoxHandle>();

    const [stateValue, setStateValue] = React.useState(Math.abs(value));

    React.useEffect(() => {
        if (isEditing) {
            ref.current.focus();
            ref.current.element.select();
        }
    }, [isEditing])

    const minMax: { min?: number, max?: number } = {};

    const upddateValue = (value: number) => {
        const message = store.getState().messageEditor.getMessage();
        const d = message.Discounts.find(p => p.DiscountClass == discount.DiscountClass);
        d.Rank = Math.abs(value);
        store.dispatch(setMessage(message));
    }

    if (isEditing) {
        return (<div
            className="k-grid-edit-cell"
            onKeyDown={e => {
                if (e.code == "Enter" || e.code == "NumpadEnter")
                    setIsEditing(false)
            }}>
            <NumericTextBox
                ref={ref}
                className={'CellNumberComponent-editing-cell'}
                spinners={true}
                step={1}
                {...minMax}
                format={GetNumericFormat(ePropType.Integer)}
                value={stateValue}
                onFocus={() => setStateValue(Math.abs(value))}
                onBlur={() => setIsEditing(false)}
                onChange={(e) => upddateValue(Math.abs(e.value))} />
        </div>);
    }

    const template = GetCellTemplate(ePropType.Integer);
    const str = `${template(Math.abs(value ?? 0))}`;
    return <div className="k-grid-edit-cell editable-cell-discount" style={{ textAlign: 'right', position: 'relative' }}
        onClick={() => {
            setStateValue(Math.abs(value));
            setIsEditing(true);
        }}>
        {str}
    </div>

}