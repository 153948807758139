import { Client } from "hub-lib/client/client.bin";
import { IAdvertiserHierarchy } from "hub-lib/models/orientdb/ref_FilterConfigurations.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { extractSub, GetHashCode, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { FilterStorage } from "../../../../utils/localstorage.bin";
import { CampaignPicker } from "../../Campaigns/CampaignPicker";

type CampaignPickerEditorArgs = { importRid?: any, validationMode?: boolean }
export function CampaignPickerEditor({ importRid, validationMode }: CampaignPickerEditorArgs) {

    const dispatch = useDispatch();
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);
    const _message = useSelector((root: RootState) => extractSub(root.messageEditor.getMessage(), ['Campaign', 'AdvertiserGroup', 'Advertiser', 'Brand', 'Product']), JSONEqualityComparer);
    const [selectedCampaign, setSelectedCampaign] = React.useState<ref_Campaigns>(undefined);
    const [loadingCamp, setLoadingCamp] = React.useState<ref_Campaigns["@rid"]>();

    React.useEffect(() => {
        const message = store.getState().messageEditor.getMessage();
        if (message && selectedCampaign !== undefined && message.Campaign != selectedCampaign["@rid"]) {
            const hash = GetHashCode(message);
            message.Campaign = selectedCampaign?.["@rid"];
            message.AdvertiserGroup = selectedCampaign?.AdvertiserGroup;
            message.Advertiser = selectedCampaign?.Advertiser;
            message.Brand = selectedCampaign?.Brand;
            message.Product = selectedCampaign?.Product;
            message.Group = selectedCampaign?.Group;
            if (hash != GetHashCode(message))
                dispatch(setMessage(message));
        }
    }, [GetHashCode(selectedCampaign)])

    React.useEffect(() => {
        const message = store.getState().messageEditor.getMessage();

        if (message.Campaign && !selectedCampaign) {
            if (loadingCamp == message.Campaign) return; // already loading
            setLoadingCamp(message.Campaign);
            const params = { "@rid": message.Campaign, Source: "ADWONE" };
            if (importRid) {
                params["CacheInfos"] = { Key: importRid, Type: "Import" }
            }
            Promise.resolve().then(async () => {
                const [campaign] = await Client.searchVertexTyped(ref_Campaigns, params);
                setSelectedCampaign(campaign);
            })
        }
    })

    const propsLocked: any = ["AdvertiserGroup", "Advertiser", "Brand", "Product"];
    const lockedProperties: (keyof IAdvertiserHierarchy)[] = selectedCampaign ? Object.entries(extractSub(selectedCampaign, propsLocked) ?? {}).filter(([k, v]) => v).map(([k, v]) => k) : propsLocked;

    return <CampaignPicker
        isLocked={lockNext}
        store={_message}
        hideSearchButton={Boolean(importRid)}
        validationMode={validationMode}
        defaultValue={selectedCampaign}
        params={{ ...(importRid && { CacheInfos: { Key: importRid, Type: "Import" }, properties: ["*"] }) }}
        lockedProperties={lockedProperties}
        onStoreChanged={(_store: any) => {
            const message = store.getState().messageEditor.getMessage();
            message.AdvertiserGroup = _store.AdvertiserGroup;
            message.Advertiser = _store.Advertiser;
            message.Brand = _store.Brand;
            message.Product = _store.Product;
            dispatch(setMessage(message));
        }}
        onChange={async value => {
            if (!value?.["@rid"]) return setSelectedCampaign(null);
            const params = { "@rid": value["@rid"], Source: "ADWONE" };
            if (importRid) {
                params["CacheInfos"] = { Key: importRid, Type: "Import" }
            }
            const [campaign] = await Client.searchVertexTyped(ref_Campaigns, params)
            setSelectedCampaign(campaign);
        }} />
}
