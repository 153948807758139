import * as React from "react";
import { Trad } from 'trad-lib';
import { ref_Messages, kpis } from "hub-lib/models/ref_Messages.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { DateNoZone } from 'tools-lib'
import { eDialogMode, GenericDialog, GenericDialogProps } from "../../ConfigurableComponents/GenericDialog.bin";
import { MessageDetails } from './MessageDetails'
import { MessageMetrics } from "./MessageMetrics";
import { Provider, useSelector } from "react-redux";
import { RootState, store } from "../../../redux/store";
import { clearMessageEditor, setMessage, setMessageSync, toggleRepeat } from "../../../redux/messageEditorSlice";
import { CampaignPickerEditor } from "./MessageEditor/CampaignPickerEditor";
import { DiscountEditorStep } from "./DiscountEditorStep";
import { Row, Title } from "../../Tools";
import { StyleFactory } from "../../../utils/ToolsReact";
import { MessageEditorOverview } from "./MessageEditorOverview";
import { DiffusionValidator, MetricsValidatior, OfferValidatior, PricingValidatior } from "./MessageEditorValidators";
import { DiffusionPicker } from "./DiffusionPicker";
import { Client } from "hub-lib/client/client.bin";
import Loader from "../../layout/Loader";
import { Switch } from "@progress/kendo-react-inputs";

const overviewWidth = '350px';

const StylesBase = StyleFactory.create({
    column: {
        flex: overviewWidth,
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
});

const Styles = StyleFactory.create({
    layout: { display: 'flex', height: '100%' },
    columnOverview: {
        ...StylesBase.column,
        flex: overviewWidth,
        background: '#DBF1F8'
    },
    columnContent: {
        ...StylesBase.column,
        flex: `calc(100% - ${overviewWidth})`,
    }
});

export class TProps {
    open: boolean;
    mode: eDialogMode;
    selectedMedia: ref_Media['@rid'];
    handleClose?: () => void;
    canRepeat?: boolean;
    onValidate: (m: ref_Messages) => any;
    message?: ref_Messages;
    importRid?: string;
}

class TState {
    message: ref_Messages;
    disableCreateButton: boolean = false
    validationMode: boolean = false;
    medias: ref_Media[] = [];
    key?: number;
    needReload?: boolean;
}

let dicoTitleTrad: any = {}
let dicoCreateTrad: any = {}
let dicoButtonTrad: any = {}

type DialogRender = {
    title?: string | JSX.Element,
    children?: any,
    dialogProps?: Partial<GenericDialogProps>
}
function DialogRender({ title, children, dialogProps }: DialogRender) {
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);
    return <GenericDialog
        open={dialogProps?.open}
        {...dialogProps}
        disablePrimaryButton={dialogProps?.disablePrimaryButton || lockNext}
        disableCancelButton={dialogProps?.disableCancelButton || lockNext}
        dialogTitle={title}
        actions={true}
        submitClass={'custom_btn_primary_validation'}
        titleClass='display-none'
        id="messages_dialog_createupdate"
        dialogClassname="messages"
        dialogContent={<Provider store={store}>
            {children}
        </Provider>
        }
    >
    </GenericDialog>
}

export function MessagesDialog({ message, selectedMedia, onValidate, mode, importRid, open, handleClose, canRepeat }: TProps) {

    const [state, setState] = React.useState<TState>();
    const { medias, validationMode, disableCreateButton, key, needReload } = state ?? {};
    const messageState = useSelector((root: RootState) => Boolean(root.messageEditor.message));
    const repeat = useSelector((root: RootState) => root.messageEditor.repeat);

    React.useEffect(() => {
        if (state) return;
        dicoTitleTrad = {
            [eDialogMode.create]: Trad("new_message"),
            [eDialogMode.modify]: Trad("edit_message"),
            [eDialogMode.duplicate]: Trad("dupli_message")
        }

        dicoCreateTrad = {
            [eDialogMode.create]: Trad("create"),
            [eDialogMode.modify]: Trad("modify"),
            [eDialogMode.duplicate]: Trad("create")
        }
        dicoButtonTrad = {
            [eDialogMode.create]: Trad("creation_progress"),
            [eDialogMode.modify]: Trad("modification_progress"),
            [eDialogMode.duplicate]: Trad("creation_progress")
        }
        const newState = new TState()
        newState.message = message ? { ...message, KPIs: { ...message.KPIs } } : new ref_Messages()
        newState.message.Start = newState.message.Start ? DateNoZone(newState.message.Start) : undefined;
        newState.message.End = newState.message.End ? DateNoZone(newState.message.End) : undefined;
        newState.message.CancellationTimeLimit = newState.message.CancellationTimeLimit ? DateNoZone(newState.message.CancellationTimeLimit) : undefined;
        newState.message.TechDeliveryTimeLimit = newState.message.TechDeliveryTimeLimit ? DateNoZone(newState.message.TechDeliveryTimeLimit) : undefined;
        newState.message.ConfirmationTimeLimit = newState.message.ConfirmationTimeLimit ? DateNoZone(newState.message.ConfirmationTimeLimit) : undefined;
        newState.message.Media = newState.message.Media ? newState.message.Media : selectedMedia;
        //newState.message.AgreementPreventUpdate = newState.message.AgreementPreventUpdate ? newState.message.AgreementPreventUpdate : false;
        if (!newState.message.KPIs)
            newState.message.KPIs = new kpis();

        store.dispatch(clearMessageEditor());
        store.dispatch(setMessage(newState.message));

        Promise.resolve().then(async () => {
            newState.medias = await Client.searchVertexTyped(ref_Media);
            setState(newState);
        })
    }, [state])

    React.useEffect(() => {
        if (repeat && needReload) {
            setState({ ...state, key: (key || 0) + 1, needReload: false })
        }
    });

    if (!state)
        return <></>

    const handleNext = async () => {
        const message = store.getState().messageEditor.getMessage();
        if (!validationMode)
            setState({ ...state, validationMode: true });
        setState({ ...state, disableCreateButton: true });
        if (await onValidate(message)) {
            if (repeat) {
                store.dispatch(setMessage(message));
                setState({ ...state, message, needReload: true });
            } else {
                setState({ ...state, disableCreateButton: false })
            }
        }
    };

    const media = medias.filter(m => m["@rid"] == state.message.Media)[0].Name
    const title = <Title>
        <span>{dicoTitleTrad[mode]}
            <span className="message_pill_title">{Trad(media)}</span>
        </span>
    </Title>

    return <DialogRender
        dialogProps={{
            open: open,
            handleClose: () => { handleClose(); store.dispatch(setMessageSync(null)); },
            submitAction: handleNext,
            disablePrimaryButton: disableCreateButton,
            disableCancelButton: disableCreateButton,
            submitTitle: disableCreateButton ? dicoButtonTrad[mode] : dicoCreateTrad[mode],
            beforeButton: canRepeat && mode === eDialogMode.create && <RepeatChecker repeat={repeat} handleRepeat={() => {
                store.dispatch(toggleRepeat());
            }} />
        }}>
        {!messageState && <Loader text={Trad('loading')} />}
        {messageState
            && <div className='message_dialog' style={Styles.layout}>
                <div style={Styles.columnOverview}>
                    <MessageEditorOverview >
                        {title}
                    </MessageEditorOverview>
                </div>

                <div className='message_dialog_content' style={Styles.columnContent} key={key}>

                    <div id='campaignpicker'>
                        <Title>{Trad('Campaign')}</Title>
                        <Row><CampaignPickerEditor importRid={importRid} validationMode={validationMode} /></Row>
                    </div>

                    <div id='offerpicker'>
                        <OfferValidatior>
                            <Title>{Trad('Offer')}</Title>
                            <Row><MessageDetails /></Row>
                        </OfferValidatior>
                    </div>

                    <div id='pricingpicker'>
                        <PricingValidatior>
                            <Title>{Trad('Pricing')}</Title>
                            <Row><DiscountEditorStep /></Row>
                        </PricingValidatior>
                    </div>

                    <div id='metricspicker'>
                        <MetricsValidatior>
                            <Title>{Trad('Other_metrics')}</Title>
                            <Row><MessageMetrics /></Row>
                        </MetricsValidatior>
                    </div>

                    <div id='diffusionpicker'>
                        <DiffusionValidator>
                            <Title>{Trad('Diffusion')}</Title>
                            <Row><DiffusionPicker /></Row>
                        </DiffusionValidator>
                    </div>
                </div>
            </div>}

    </DialogRender>
}

function RepeatChecker({ repeat, handleRepeat }: { repeat: boolean, handleRepeat: () => void }) {
    return <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleRepeat}>
        <Switch
            size='small'
            checked={repeat}
            name={Trad("reapet_checker")} />
        <div>{Trad("reapet_checker")}</div>
    </div>;
}