import * as React from "react";
import { VertexAutocomplete } from "adwone-lib/index";
import { Trad, TradProp } from "trad-lib";
import { eStatusType, ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_Visuals } from "hub-lib/models/ref_Attachments.bin";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";

export class Store {
    /* Hierarchie état */
    "ModelProperties.AdCreation": rid[];
}

export class TProps {
    store: Partial<Store>;
    configModel?: any;
    onChanged?: (store: Partial<Store>) => void;
}

export class VisualFilters extends React.Component<TProps, any> {

    constructor(props: TProps) {
        super(props);
        this.state = {};
    }


    Autocomplete = (propStore : keyof Store) => {
        const { store, onChanged } = this.props;

        return (
            <div className="adw-row">
                <VertexAutocomplete
                    type={ref_Visuals.name}
                    multiple={true}
                    label={`${TradProp(propStore, ref_Messages)}`}
                    defaultValue={(v: any[]) => {
                        return v?.filter((v) => (store as any)[propStore]?.includes(v["@rid"]))
                    }}
                    onChange={(value: string[]) => {
                        (store as any)[propStore] = value.map(v => v["@rid"]);
                        let key = `version${propStore}`;
                        this.setState({ [key]: (this.state as any)[key] + 1 } as any, () => onChanged?.(store));
                    }}
                    params={{
                        Active : true,
                        properties: ["@rid", "Name"]
                    }} />
            </div>)
    }

    render() {
        return (
            <>
                {this.Autocomplete("ModelProperties.AdCreation")}
            </>
        );
    }
}