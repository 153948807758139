import { ref_Messages } from "../models/ref_Messages.bin";
import { DataProvider } from "../provider";

export async function InitOJD(message: ref_Messages) {
    if (!message) return;
    if (!message.ModelProperties) message.ModelProperties = {};
    message.ModelProperties["OjdYear"] = null;
    if (message.KPIs) {
        message.KPIs['Release'] = null;
        message.KPIs['PaidCirculation'] = null;
        message.KPIs['TotalCirculation'] = null;
        message.KPIs['Subscriptions'] = null;
    }

    if (message.Support && message.BroadcastArea) {
        await UpdateOJDWave(message);
        await UpdateOJDData(message, true);
    }
}

export async function UpdateOJDWave(message: ref_Messages) {
    let waves: string[];
    try {
        waves = await DataProvider.getOJDWaves({ support: message.Support, broadcastArea: message.BroadcastArea });
    } catch (error) {
        console.error(error);
        //Notify(Trad('cannot_retrieve_ojd_data'), 'warning')
        waves = [];
    }
    message.ModelProperties["OjdYear"] = waves?.[0] ?? null;
}

export async function UpdateOJDData(message: ref_Messages, force: boolean = false) {
    // disabledByWave = [];
    const kpis = message.KPIs as any;

    if (message.ModelProperties?.["OjdYear"]) {
        const ojd = (await DataProvider.getOJD({ support: message.Support, broadcastArea: message.BroadcastArea, year: message.ModelProperties?.["OjdYear"] }))?.[0]

        if (ojd && ojd.MiseEnDistribution != undefined && ojd.MiseEnDistribution != 0)
            if (kpis.Release == null || force)
                kpis.Release = ojd.MiseEnDistribution;

        if (ojd && ojd.DiffusionPayeeFrance != undefined && ojd.DiffusionPayeeFrance != 0)
            if (kpis.PaidCirculation == null || force)
                kpis.PaidCirculation = ojd.DiffusionPayeeFrance

        if (ojd && ojd.DiffusionTotaleFrance != undefined && ojd.DiffusionTotaleFrance != 0)
            if (kpis.TotalCirculation == null || force)
                kpis.TotalCirculation = ojd.DiffusionTotaleFrance;

        if (ojd && ojd.Abonnements != undefined && ojd.Abonnements != 0)
            if (kpis.Subscriptions == null || force)
                kpis.Subscriptions = ojd.Abonnements
    }
    else if (force) {
        kpis.PaidCirculation = null;
        kpis.TotalCirculation = null;
        kpis.Subscriptions = null;
        kpis.Tirage = null;
    }
}