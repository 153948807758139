import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { Indicateur, IndicateurJoin, eIndicateurType, propertyOption, IndicateurInfo } from "./index.bin";
import { CellValue } from "./types.bin";
import { MetaDataProperty } from "hub-lib/models/types.bin";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";
import { IsIDDB, IsOrientDB, JoinElements, memoizeAsync, propertyOf } from "hub-lib/tools.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Groups } from "hub-lib/models/ref_Groups.bin";
import { Format, GetKPITemplate, GetPropTemplate, GetPropertyTypeTemplate, Sort, propsNeededForFormat } from "format-lib/index.bin";
import { MessageModelManager, eKPIType } from "hub-lib/models/KPIsManager.bin";
import { DataProvider } from "hub-lib/provider";
import { Trad } from "trad-lib";
import { getUniqueWeekNumbers, getUniqueWeekYears } from "tools-lib";
import { ref_Periodicity } from "hub-lib/models/orientdb/ref_Periodicity.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";

export interface IDated {
    Start: Date;
    End: Date;
}

export interface IDatedData<T> extends IDated {
    Data: T[];
}

export function isDate(obj) {
    return obj instanceof Date;
}

export function getMinMaxDate<T>(data: T[], dateField: keyof T, mode: 'min' | 'max'): Date {
    let dateTime: number = mode === 'min' ? Infinity : -Infinity;
    const dataLength = data.length;
    for (let i = 0; i < dataLength; i++) {
        const valToCompare = data[i][dateField] ?? 0;
        const currentDate = (isDate(valToCompare) ? <Date><unknown>valToCompare : new Date(<number><unknown>valToCompare)).getTime();

        if ((mode === 'min' && currentDate < dateTime) || (mode === 'max' && currentDate > dateTime)) {
            dateTime = currentDate;
        }
    }
    return new Date(dateTime);
}

export function getMinDate<T>(data: T[], dateField: keyof T): Date {
    return getMinMaxDate(data, dateField, 'min');
}

export function getMaxDate<T>(data: T[], dateField: keyof T): Date {
    return getMinMaxDate(data, dateField, 'max');
}

export const FormatCellsMemoized = memoizeAsync((documentType: string, indicateur: Indicateur, cells: CellValue[]) => {
    const ress = EngineTools.FormatCells(documentType, indicateur, cells);
    return ress;
})

type IDatedDataExtended<T> = (IDatedData<T> & { Weeks: string[] })

export class EngineTools {
    static groupElementsByDate<T>(elements: T[]): IDatedData<T>[] {

        const groups: IDatedData<T>[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        let currentGroup: IDatedData<T> = null;
        for (const element of elements) {
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                };
                groups.push(currentGroup);
            } else {
                const currentGroupEnd = currentGroup.End as Date;
                const elementStart = element['Start'] as Date;
                if (currentGroupEnd && elementStart && elementStart?.getTime?.() <= currentGroupEnd?.getTime?.()) {
                    currentGroup.Data.push(element);
                    currentGroup.End = getMaxDate(currentGroup.Data, <any>"End");
                } else {
                    currentGroup = {
                        Start: element['Start'],
                        End: element['End'],
                        Data: [element]
                    };
                    groups.push(currentGroup);
                }
            }
        }
        return groups;
    }

    static groupElementsByWeek<T>(elements: T[]): IDatedDataExtended<T>[] {


        const groups: (IDatedDataExtended<T> & { Weeks: string[] })[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        let currentGroup: IDatedDataExtended<T> = null;
        for (const element of elements) {
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Weeks: getUniqueWeekYears(element['Start'] as Date, element['End'] as Date),
                    Data: [element]
                };
                groups.push(currentGroup);
            } else {
                const currentGroupEnd = currentGroup.End as Date;
                const elementStart = element['Start'] as Date;
                if (currentGroupEnd && elementStart && getUniqueWeekYears(elementStart, elementStart).some(w => currentGroup.Weeks.includes(w))) {
                    currentGroup.Data.push(element);
                    currentGroup.End = getMaxDate(currentGroup.Data, <any>"End");
                    currentGroup.Weeks = getUniqueWeekYears(currentGroup.Start, currentGroup.End);
                } else {
                    currentGroup = {
                        Start: element['Start'],
                        End: element['End'],
                        Weeks: getUniqueWeekYears(element['Start'] as Date, element['End'] as Date),
                        Data: [element]
                    };
                    groups.push(currentGroup);
                }
            }
        }
        return groups;
    }



    static groupElementsByExactDate<T>(elements: T[]): IDatedData<T>[] {

        const groups: IDatedData<T>[] = [];

        // convert string dates to Date
        elements.forEach(e => {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });

        // order elements by start date
        elements = [...elements].sort((a, b) => {
            const aStart = a['Start'] as Date;
            const bStart = b['Start'] as Date;
            return (aStart?.getTime?.() ?? 0) - (bStart?.getTime?.() ?? 0);
        });

        for (const element of elements) {
            // on cherche s'il y a un groupe avec la meme date de début et de fin
            const group = groups.find(g => {
                const gStart = g.Start as Date;
                const gEnd = g.End as Date;
                const elementStart = element['Start'] as Date;
                const elementEnd = element['End'] as Date;
                return gStart?.getTime?.() === elementStart?.getTime?.() && gEnd?.getTime?.() === elementEnd?.getTime?.();
            });

            // si on en a un alors on ajoute l'élément aux data sinon on ajoute un nouveau groupe
            if (group) {
                group.Data.push(element);
            } else {
                groups.push({
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                });
            }
        }
        return groups;
    }

    static async FormatCells(documentType: string, indicateur: Indicateur, cells: CellValue[], src?: src_AdwOne) {

        // const mgr = ManagerFactory.GetInstance().getManager(ref_PropertyType.name);
        // const propertyTypes: ref_PropertyType[] = await mgr.find();

        switch (indicateur.type) {
            case eIndicateurType.info:
                const indicateurInfo = <IndicateurInfo>indicateur;
                let prop: ref_PropertyType;
                if (indicateurInfo?.field?.startsWith?.(`${propertyOf<ref_Messages>('ModelProperties')}.`)) {
                    const propertyTypes: ref_PropertyType[] = await DataProvider.search(ref_PropertyType.name);
                    prop = propertyTypes?.find(p => p.Type === indicateurInfo.field.replace(`${propertyOf<ref_Messages>('ModelProperties')}.`, ""));
                }

                const metadata = await DataProvider.getMetadata(documentType);
                await EngineTools.FormatCellInfo(cells, metadata, indicateurInfo, src, prop);
                break;
            case eIndicateurType.join:
                await EngineTools.FormatJoinCell(documentType, indicateur as IndicateurJoin, cells, src);
                break;
            case eIndicateurType.kpi:
            case eIndicateurType.discount:
            case eIndicateurType.computed:
                EngineTools.FormatCellKPIDiscount(cells, indicateur);
                break;

            default:
                throw new Error("Not implemented");
        }
        return cells;
    }

    static async FormatLinkedClassCell(cells: CellValue[], prop: MetaDataProperty, src: src_AdwOne, cellTemplate: (data: any) => string) {

        if (!cellTemplate)
            cellTemplate = (data) => data;

        /* Si on est sur un link alors on récupère les valeurs via le manager pour les formater */
        //const mgr = ManagerFactory.GetInstance().getManager(prop.linkedClass, { collection: src?.URI });
        //Formatage des cellules pour gérer les valeurs qui ne sont pas des rids
        cells.forEach(c => c.Formated = c.Value);
        /** get non empty values */
        const rids = Array.from(new Set(cells
            .filter(c => c?.Value && c?.Value != "" && (typeof c.Value == "string" || c.Value?.length))
            .map(c => c.Value)
            .reduce((a, b) => (Array.isArray(a) ? a : [a]).concat(Array.isArray(b) ? b : [b]), [])
            .filter(v => v)
            .filter(v => IsIDDB(v))));

        let values: IRid[] = [];

        if (rids?.length) {

            const dicoMapping = {
                [ref_Periodicity.name]: ref_Property.name
            }

            const properties = propsNeededForFormat[prop.linkedClass] ?? ["Name"];
            const time0126 = new Date().getTime();
            let elements = await DataProvider.search(dicoMapping[prop.linkedClass] ?? prop.linkedClass,
                {
                    "@rid": rids,
                    properties,
                    Active: [true, false],
                    // ...(src ? { collection: src?.URI } : {})
                }).catch(e => { console.error(e); return [] });
            if (prop.name == "AdvertiserGroup" && prop.linkedClass == ref_AdvertiserGroups.name) {
                //elements = [...elements, ...await ManagerFactory.GetInstance().getManager(ref_Groups.name, { collection: src?.URI }).find({ "@rid": rids })];
                elements = [...elements, ...await DataProvider.search(ref_Groups.name, {
                    ...(src ? { collection: src?.URI } : {}),
                    properties: ["Name"],
                    "@rid": rids
                })];
            }
            values = Sort(prop.linkedClass, elements);
            const _time0126 = new Date().getTime();
            console.log(`[PERF] [FORMAT_TABLE] Get ${prop.linkedClass} (${elements.length}) ${_time0126 - time0126}ms`);
        }

        for (const cell of cells) {

            if (cell.Value === "#-1:-1") {
                cell.Formated = "NC";
                continue;
            }

            if (Array.isArray(cell.Value)) {
                cell.Formated = JoinElements(cell.Value
                    .map(v => values.find(e => e['@rid'] == v) ?? v)
                    .map(i => typeof i == 'string' ? i : Format(i, null, prop.linkedClass))
                    .map(cellTemplate));
            }
            else {
                const element = values.find(v => v["@rid"] === cell.Value);
                cell.Formated = cellTemplate((element ? Format(element, null, prop.linkedClass) : cell.Value) ?? "");
            }
        }
    }

    static GetCellInfoTemplate(indicateur: IndicateurInfo, propertyType: ref_PropertyType): (data: any) => string {
        const propTemplate = GetPropTemplate(indicateur.field);
        const propTypeTemplate = GetPropertyTypeTemplate(propertyType);
        const kpitemplate = GetKPITemplate(indicateur.valueType);
        let cellTemplate = propTemplate ?? propTypeTemplate ?? kpitemplate ?? GetKPITemplate(eKPIType.String)
        if (indicateur.options?.formater?.type == "trad") {
            const cellBase = cellTemplate;
            cellTemplate = (str) => {
                const translated = str ? Trad(str) : str;
                return cellBase(translated);
            }
        }
        return cellTemplate;
    }

    static async FormatCellInfo(cells: CellValue[], props: MetaDataProperty[], indicateur: IndicateurInfo, src?: src_AdwOne, propertyType?: ref_PropertyType) {
        let prop = indicateur?.options?.["MetaData"] ?? props.find(p => p.name === indicateur.field);

        if (!prop)
            console.warn(`FormatCellInfo: prop not found in metadata: ${indicateur.field}`);

        const cellTemplate = EngineTools.GetCellInfoTemplate(indicateur, propertyType);

        if (prop?.linkedClass && indicateur.valueType == eKPIType.Rid) {
            await EngineTools.FormatLinkedClassCell(cells, prop, src, cellTemplate);
        } else {
            /** si ce n'est pas un link on prend le même template que les tableaux */
            for (const cell of cells) {
                cell.Formated = Array.isArray(cell.Value) ? JoinElements(cell.Value.map(cellTemplate)) : cellTemplate(cell.Value);
            }
        }
    }

    static async FormatJoinCell(documentType: string, indicateur: IndicateurJoin, cells: CellValue[], src: src_AdwOne) {
        const allSubCells: CellValue[][] = [];
        for (let i = 0; i < indicateur.indicateurs.length; i++) {
            const subIndicateur = indicateur.indicateurs[i];
            const clonedCells = cells
                .flatMap((c, key) => c.Value.map(v => ({
                    ...c,
                    Value: v[i],
                    flag: key,
                })));

            await EngineTools.FormatCells(documentType, subIndicateur, clonedCells, src);
            allSubCells.push(clonedCells);
        }

        for (let iCell = 0; iCell < cells.length; iCell++) {
            const cell = cells[iCell];
            const colCells = indicateur.indicateurs.map((_, i) => {
                const indicateurCells = allSubCells[i].filter(c => c['flag'] === iCell).map(c => c.Formated);
                return indicateurCells;
            })

            if (colCells?.length) {
                const allValues: string[] = [];
                for (let i = 0; i < colCells[0].length; i++) {
                    const element = colCells.map(c => c[i]).filter(c => Boolean(c) && c != "").join(indicateur.options?.separator ?? " ");
                    allValues.push(element);
                }
                cell.Formated = allValues.join(", ");
            }
        }
    }

    static FormatCellKPIDiscount(cells: CellValue[], indicateur: Indicateur) {
        const templateDiscount = GetKPITemplate(indicateur.valueType);
        for (const cell of cells) {
            if (indicateur.valueType === eKPIType.Percent && (indicateur.type === eIndicateurType.discount
                || indicateur.type === eIndicateurType.computed)) {
                const num = Number(cell.Value);
                let val = cell.Value;
                if (val !== "" && !isNaN(num))
                    val *= 100;

                cell.Formated = templateDiscount?.(val);
            } else {
                cell.Formated = templateDiscount?.(cell.Value);
            }
        }
    }

    static BuildLinkPropertyInfo(name: string, options?: propertyOption): { property: string, alias: string, fallbackProperty?: string, fallbackAlias?: string } {
        const property = options?.subProperty ?? "Name";
        let alias = options?.MetaData?.name;
        if (!alias)
            alias = `${name}${property}`.replace(/\./g, '');
        if (options?.subPropertyFallback) {
            const fallbackProperty = `${name}.${options.subPropertyFallback}`;
            const fallbackAlias = `${fallbackProperty}`.replace(/\./g, '');
            return { property, alias, fallbackProperty, fallbackAlias };
        }
        return { property, alias };
    }

    static BuildLinkPropertyParams(name: string, options?: propertyOption) {

        const propInfos = EngineTools.BuildLinkPropertyInfo(name, options);
        let propertyName = `${name}.${propInfos.property}`;
        const aliasProperty = `${propertyName} as ${propInfos.alias}`;

        const properties = [aliasProperty];
        if (propInfos.fallbackProperty) {
            const fallbackProperty = `${name}.${propInfos.fallbackProperty} as ${propInfos.fallbackAlias}`
            properties.push(fallbackProperty);
        }
        return properties;
    }
}