import * as React from 'react';

import { SortDescriptor } from '@progress/kendo-data-query';
import { ref_Campaigns } from 'hub-lib/models/ref_Campaigns.bin';
import { AdwRow, ADWColumn, IItemRow } from 'adwone-lib/index';
import { Client } from 'hub-lib/client/client.bin';
import { GetOrder, GetSort } from 'format-lib/index.bin';
import { Trad, TradProp } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { clearEmptyValues, clone, distinct, propertyOf, removeDiacritics } from 'hub-lib/tools.bin';
import { getIcon } from 'adwone-lib/index';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import { AdvertiserHierarchyFilter, Filter } from 'hub-lib/models/orientdb/ref_FilterConfigurations.bin';
import { AdwTelerikGrid } from '../Generic/AdwTelerikGrid.bin';
import { VertexGrid } from '../Generic/VertexGrid.bin';
import Loader from '../../layout/Loader';
import { Notify } from '../../../utils/Notify.bin';
import { FilterStorage, IsMMUser } from '../../../utils/localstorage.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { ConfirmDeleteContent, eDialogMode, GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import history from '../../../utils/history';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { TooltipManager } from '../../CustomTooltip';
import { CreateColumnLink } from '../Cells/CellLink';
import { storeElements } from '../../../redux/storageReducer';
import { ConfigurationPanel } from '../Messages/ConfigurationPanel';
import {
    BreadcrumbsCustomContainer,
    ConfigurationPanelContainer,
    SelectedItemsContainer,
    ToolbarAdw,
    ToolbarContainer,
} from '../Generic/ToolbarAdw';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { CustomBadge } from '../Adwone-admin/Referential/BadgesUsage.bin';
import { store } from '../../../redux/store';
import { setFilters } from '../../../redux/projectSlice';
import { MultiAdvertisersMode } from './MultiAdvertisersMode';
import { CampaignForm, DicoCreateTrad, DicoTitleCampaignTrad } from './CampaignForm';
import { eIndicateurType, IndicateurInfo } from 'adwone-engine/index.bin';
import { ToEPropType, eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { Button } from '@material-ui/core';
import { MenuItemFilters } from '../Filters/MenuItemFilters';
import { FiltersChangeTrigger } from '../Filters/FiltersChangeTrigger';
import { ePopperEvent, popperEventEmitter } from '../../ConfigurableComponents/GenericPopper.bin';
import { EstimateMode } from './EstimateMode';
import { EventEmitter } from 'events';
import { ItemNameButtonCell } from '../Cells/ItemNameButtonCell';

let timeout: any = null;
type TState = {
    editMode: eDialogMode | null;
    campaignEdited?: ref_Campaigns;
    grid?: VertexGrid<ref_Campaigns>;
    sort?: SortDescriptor[];
    config: Partial<Filter>;
    selectedItems: IItemRow<ref_Campaigns>[];
    totalItems: number;
    rmDialog: boolean;
    countMessages: number;
    countMessagesEstimated: number;
    multiAdvertisers: boolean;
    estimateMode: boolean;
};

const defaultVertexParams = {
    Active: true,
    computeBudget: true,
    getDepartments: true,
    getEstimates: true
};

const submitEvent: EventEmitter = new EventEmitter();
export class CampaignsGrid extends React.Component<any, TState> {
    constructor(props: any) {
        super(props);
        Client.log({ Category: 'navigation', Action: ref_Campaigns.name });
        this.state = {
            editMode: null,
            config: FilterStorage.GetLocalStorageFiltersObj() ?? { Source: ['ADWONE'] },
            selectedItems: [],
            totalItems: 0,
            rmDialog: false,
            countMessages: 0,
            countMessagesEstimated: 0,
            multiAdvertisers: false,
            estimateMode: false
        };
    }

    onConfChange = (filters: Partial<Filter>) => {
        this.setState({ multiAdvertisers: filters?.Group ? true : false });
        clearTimeout(timeout);
        timeout = setTimeout(() =>
            this.setState({ config: clearEmptyValues(filters) }, () => {
                const { vertexParams } = this.state.grid.props;
                this.state.grid.props.vertexParams = {
                    ...defaultVertexParams,
                    properties: vertexParams.properties,
                    ...this.state.config
                };
                this.state.grid.UpdateRows();
            }), 500);
    };

    componentDidMount() {
        const advancedFilters = FilterStorage.getAdvancedFilters();
        if (advancedFilters?.Group) {
            this.setState({ multiAdvertisers: true });
        }
        let columns: ADWColumn<ref_Campaigns>[] = [];
        let hiddenProperties: string[] = [];
        let properties: string[] = [];
        let colsMess = (property: keyof ref_Campaigns, pName?: string) => {
            let bindingPath = propertyOf<ref_Campaigns>(property);
            let columnCustom = new ADWColumn<ref_Campaigns>(
                TradProp(property, ref_Campaigns),
                bindingPath,
                ePropType.Link,
                true
            );
            columnCustom.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Campaigns>) => {
                return (dataItem.dataItem as any)?.[`${property}Name`];
            };
            columns.push(columnCustom);
            hiddenProperties.push(property);
            properties.push(`${property}.${pName ?? 'Name'} as ${property}Name`);
        };

        if (IsMMUser())
            hiddenProperties.push('Group');

        colsMess('Advertiser');
        colsMess('AdvertiserGroup');
        colsMess('Brand');
        colsMess('Product');
        colsMess('Currency');


        const colsLink = (property: keyof ref_Campaigns) => {
            const columnCustom = CreateColumnLink(ref_Campaigns, property);
            columns.push(columnCustom);
            hiddenProperties.push(property);
        };

        colsLink('Created_by');
        colsMess('Departments');

        let nameColumn = new ADWColumn<ref_Campaigns>(Trad('Name'), 'Name', ePropType.String, true);
        nameColumn.cellValue = (CellValue: any, dataItem: AdwRow<ref_Campaigns>) => {
            dataItem['Name_cellValue'] = CellValue ? removeDiacritics(dataItem.dataItem.Name) : '';
            const { Start, End } = store.getState().project?.filters;
            let incompletCampaign = null;
            if (Start && End) {
                if (new Date(Start).getTime() > new Date(dataItem.dataItem.KPIs?.MinEndDate).getTime() || new Date(End).getTime() < new Date(dataItem.dataItem.KPIs?.MaxStartDate).getTime()) {
                    incompletCampaign = "incomplet_campaign_message_info";
                }
            }

            return <ItemNameButtonCell dataItem={dataItem} warningMessage={incompletCampaign} onClick={() => ShowMessages([dataItem.dataItem])} />;
        };
        nameColumn.width = 300;
        hiddenProperties.push('Name');
        columns.push(nameColumn);


        const createColumnKPI = (name: string, field: keyof ref_Campaigns['KPIs'], valueType: eKPIType) => {
            const indInfo: IndicateurInfo = {
                type: eIndicateurType.kpi,
                name,
                field,
                valueType
            };
            const column = new ADWColumn<ref_Campaigns>(name, 'KPIs.' + field, ToEPropType(valueType))
            column.width = 140;
            column.baseColumn = indInfo;
            return column;
        }

        columns.push(createColumnKPI(TradProp('MessagesBudget', ref_Campaigns), "NetCO", eKPIType.Price));
        columns.push(createColumnKPI(Trad('messages_number'), "MessagesCount", eKPIType.Number));

        if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
            const indInfoEstimate: IndicateurInfo = {
                type: eIndicateurType.info,
                name: TradProp('EstimateNumber'),
                field: 'EstimateNumber',
                valueType: eKPIType.String
            };
            const estimateIDColumn = new ADWColumn<ref_Campaigns>(TradProp('EstimateNumber', ref_Campaigns), 'EstimateNumber', ePropType.String)
            estimateIDColumn.baseColumn = indInfoEstimate;
            columns.push(estimateIDColumn);
        }

        const grid = new VertexGrid<ref_Campaigns>({
            objectPrototype: ref_Campaigns,
            devMode: false,
            columns,
            hiddenProperties,
            order: GetOrder<ref_Campaigns>(ref_Campaigns),
            width: {
                Start: 200,
                End: 200,
                Currency: 90,
                Budget: 140,
            },
            vertexParams: {
                properties: ['*', ...properties],
                ...defaultVertexParams,
                ...this.state.config
            },
            adapt: (rows: ref_Campaigns[]) => {
                storeElements(rows);
                return rows;
            },
        });

        const sort = GetSort<ref_Campaigns>(ref_Campaigns);
        this.setState({
            grid,
            sort,
        });
    }

    error(msg: string) {
        Notify(msg, 'error');
    }
    getCountMessages = async () => {
        const { selectedItems } = this.state;
        let count = 0;
        let countEstimated = 0;

        const campaignRids = selectedItems.map(i => i.dataItem["@rid"]);
        if (campaignRids.length) {
            const results: ref_Messages[] = (
                await Client.searchVertex(ref_Messages.name, {
                    properties: ['@rid', 'Deversement'],
                    Source: ['ADWONE'],
                    Active: true,
                    Campaign: campaignRids
                })
            )?.data?.results;
            countEstimated = results.filter((m) => m.Deversement).length || 0;
            count = results.length - countEstimated;
        }

        this.setState({
            countMessages: count,
            countMessagesEstimated: countEstimated,
        });
    };
    selectionChange = (rows: IItemRow<ref_Campaigns>[]) => {
        this.setState(
            {
                selectedItems: rows,
            });
    };
    removeCampaigns = () => {
        let verteces = this.state.selectedItems.map((row) => row.dataItem['@rid']);
        Client.deleteVertex(ref_Campaigns.name, verteces)
            .then((delete_response) => {
                this.state.grid.UpdateRows();
            })
            .catch((e) => console.error(e));
    };
    render() {
        const { editMode, selectedItems, grid, totalItems, config, sort, multiAdvertisers, estimateMode } = this.state;

        if (!grid) return <Loader />;

        const confComponent = (
            <ConfigurationPanel
                elements={[
                    (RightManager.hasRight(eFunctions.ref_Campaigns, eRights.create) && {
                        type: 'component',
                        component: (
                            <CustomIconButton
                                onMouseOver={(e) =>
                                    TooltipManager.Push({ target: e.target, text: Trad('create_campaign') })
                                }
                                className="custom_btn_primary no-radius no-shadow icon-panel-item"
                                onClick={() =>
                                    this.setState({
                                        editMode: eDialogMode.create,
                                        campaignEdited: undefined
                                    })
                                }
                            >
                                {getIcon('plus')}
                            </CustomIconButton>
                        ),
                    }), MenuItemFilters({ hide: { AdvancedFilters: true, AdvertiserHierarchyComponentKeys: ['Campaign'] } }),
                    {
                        type: 'icon',
                        title: () => Trad('export'),
                        icon: 'download',
                        element: (
                            <>
                                <div className="adw-row">
                                    <CustomButton
                                        Label={Trad('CSV')}
                                        style={{ float: 'right' }}
                                        //disabled={!RightManager.hasRight(eFunctions.ref_Campaigns, eRights.export)}
                                        className="custom_btn_primary"
                                        onClick={() => grid.exportExcel('csv')}
                                    />
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        );

        return (
            <div style={{ width: '100%' }}>
                <FiltersChangeTrigger onChange={this.onConfChange} />
                {editMode && (
                    <GenericDialog
                        open
                        handleClose={() => this.setState({ editMode: null })}
                        dialogTitle=""
                        titleClass='display-none'
                        id="messages_dialog_createupdate"
                        dialogClassname="messages"
                        submitClass={'custom_btn_primary_validation'}
                        actions
                        submitAction={() => submitEvent.emit('submit')}
                        submitTitle={DicoCreateTrad()[editMode]}
                        cancelAction={() => this.setState({ editMode: null })}>
                        <div className="multiAdvertisersMode-component" style={{ zIndex: 9000, padding: 8, top: 0 }}>
                            <MultiAdvertisersMode
                                multiAdvertisers={multiAdvertisers}
                                onChange={value => this.setState({ multiAdvertisers: value })} />
                            {editMode !== eDialogMode.modify && <EstimateMode
                                estimateMode={estimateMode}
                                onChange={value => this.setState({ estimateMode: value })}
                            />}
                        </div>
                        <div style={{ zIndex: 8000, position: "relative", height: "100%" }}>
                            <CampaignForm
                                submitEvent={submitEvent}
                                campaign={this.state.campaignEdited}
                                mode={editMode}
                                multiAdvertisers={multiAdvertisers}
                                estimatedMode={estimateMode}
                                onSave={() => {
                                    this.setState({ editMode: null });
                                    this.state.grid.UpdateRows();
                                }}
                                onCancel={() => this.setState({ editMode: null })} />
                        </div>
                    </GenericDialog>
                )}

                <ToolbarAdw includeFilters hasSelectedItems={selectedItems?.length > 0} count={totalItems}>
                    <ToolbarContainer>
                        {selectedItems?.length > 0 && (
                            <SelectedItemsContainer>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <GenericDialog
                                        open={this.state.rmDialog}
                                        dialogTitle={Trad('confirmation')}
                                        handleClose={() => this.setState({ rmDialog: false })}
                                        submitAction={() => {
                                            this.removeCampaigns();
                                            this.setState({ rmDialog: false, selectedItems: [] });
                                        }}
                                        cancelAction={() => this.setState({ rmDialog: false })}
                                        submitClass="custom_btn_danger"
                                        submitTitle={Trad('yes')}
                                        startIcon={getIcon('clear')}
                                        actions={true}>
                                        <>
                                            <p>{ConfirmDeleteContent(this.state.selectedItems)}</p>
                                            <p>{this.state.countMessages + ' message(s) lié(s)'}</p>
                                            <p>{this.state.countMessagesEstimated + ' message(s) déversé(s)'}</p>
                                        </>
                                    </GenericDialog>
                                    <div className="margin-right">
                                        <CustomBadge
                                            count={this.state.selectedItems.length}
                                            icon={'campaigns'}
                                            tradClassKey={ref_Campaigns.name}
                                        />
                                    </div>
                                    <Button startIcon={getIcon('go')} className="custom_btn_secondary_white margin-right"
                                        disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.read)} onClick={() => ShowMessages(selectedItems.map(i => i.dataItem))}>
                                        {Trad("see_messages")}
                                    </Button>
                                    {/* <div style={{ marginRight: 15, float: "left", marginTop: 10 }}>{`${this.state.selectedItems.length} ${Trad("selected_items")}`}</div> */}
                                    {RightManager.hasRight(eFunctions.ref_Campaigns, eRights.delete) && <CustomButton
                                        Label={Trad('delete')}
                                        style={{ float: 'right' }}
                                        className="custom_btn_danger"
                                        startIcon={getIcon('delete')}
                                        onClick={() =>
                                            this.setState({
                                                rmDialog: true
                                            }, async () => await this.getCountMessages())
                                        }
                                    />}
                                </div>
                            </SelectedItemsContainer>
                        )}
                        <BreadcrumbsCustomContainer>
                            <BreadcrumbsCustom
                                hasSelectedItems={selectedItems?.length > 0}
                                elements={[{ text: Trad('home'), href: '/' }, { text: Trad('campaigns') }]}
                            />
                        </BreadcrumbsCustomContainer>
                    </ToolbarContainer>

                    <ConfigurationPanelContainer>{confComponent}</ConfigurationPanelContainer>

                </ToolbarAdw>

                <AdwTelerikGrid
                    footer
                    hideToolbar
                    onEdit={async (row: AdwRow<ref_Campaigns>) =>
                        this.setState({
                            editMode: eDialogMode.modify,
                            campaignEdited: row.dataItem,
                            multiAdvertisers: Boolean(row.dataItem.Group)
                        })}
                    onDuplicate={async (row: AdwRow<ref_Campaigns>) =>
                        this.setState({
                            editMode: eDialogMode.duplicate,
                            campaignEdited: row.dataItem,
                            multiAdvertisers: Boolean(row.dataItem.Group)
                        })}
                    sort={sort}
                    grid={grid}
                    selectionChange={this.selectionChange}
                    onRowInitialized={(rows) => this.setState({ totalItems: rows?.length })}
                    isCopyDisable={!RightManager.hasRight(eFunctions.ref_Campaigns, [eRights.create, eRights.update])}
                    uneditable={!RightManager.hasRight(eFunctions.ref_Campaigns, eRights.update)}
                    selectable
                    commandCellArgs={{
                        isEditable: RightManager.hasRight(eFunctions.ref_Campaigns, eRights.update)
                            && ((r: AdwRow<ref_Campaigns>) =>
                                r.dataItem.Source !== 'MAP'),
                        additionalCommands: RightManager.hasRight(eFunctions.ref_Estimates, eRights.read) ? [{
                            icon: getIcon("find_in_page"),
                            disabled: (row: AdwRow<ref_Campaigns>) => !row.dataItem?.Estimates?.length,
                            function: (row: AdwRow<ref_Campaigns>, event: React.MouseEvent<any, MouseEvent>) => {
                                event.preventDefault();
                                event.stopPropagation();
                                popperEventEmitter.emit(ePopperEvent.open, {
                                    anchorEl: event.target,
                                    placement: "bottom",
                                    buttons: row.dataItem.Estimates.map(e => ({ label: e.EstimateLib, onClick: () => e.PdfLink ? window.open(e.PdfLink, '_blank') : null }))
                                }, event)
                            }
                        }] : null
                    }}
                    isSelectable={(r: AdwRow<ref_Campaigns>) => r.dataItem.Source !== 'MAP'} />
            </div >
        );
    }
}

//const getCampaignWithBudget = (campaignRid: ref_Campaigns['@rid']) => Client.searchVertexTyped(ref_Campaigns, { '@rid': campaignRid, computeBudget: true }).then(res => res?.[0]);

export function ShowMessages(campaigns: ref_Campaigns[]) {

    const { Start, End } = store.getState().project.filters;
    const newFilter = new Filter();
    newFilter.Start = Start;
    newFilter.End = End;
    newFilter.Source = distinct([...newFilter.Source, ...campaigns.map(c => c.Source)]);
    //newFilter.Source = distinct(campaigns.map(c => c.Source));
    newFilter.Campaign = campaigns.map(c => c['@rid']);

    const advHFilter = new AdvertiserHierarchyFilter();
    if (!(campaigns.some(c => Boolean(c.Group)) && campaigns.some(c => !Boolean(c.Group)))) {
        if (campaigns.some(c => Boolean(c.Group))) {
            advHFilter.Group = distinct(campaigns.map(c => c.Group));
        } else {
            const keys: (keyof Pick<ref_Campaigns, 'AdvertiserGroup' | 'Advertiser' | 'Brand' | 'Product'>)[] = ['AdvertiserGroup', 'Advertiser', 'Brand', 'Product'];
            for (const key of keys) {
                if (!campaigns.every(c => Boolean(c[key]))) break;
                advHFilter[key] = distinct(campaigns.map(c => c[key]));
            }
        }
    }

    const Name = Trad("default");
    store.dispatch(setFilters(({
        Filters: { ...newFilter, ...advHFilter },
        Default: true,
        Name,
        Active: true,
        Owner: null,
        Table: "Table"
    })));
    history.push('/messages');
}


