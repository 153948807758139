import * as React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { Trad } from "trad-lib";

class TProps {
    discountMode?: ref_Messages["DiscountMode"];
    onChange: (mode: ref_Messages["DiscountMode"]) => void;
    disableDiscountMode?: boolean;
}

export function DiscountMode({ discountMode, disableDiscountMode, onChange }: TProps) {
    return <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue={discountMode}
        onChange={(e, value) => onChange(value as ref_Messages["DiscountMode"])} >
        <FormControlLabel
            value="cascade"
            disabled={disableDiscountMode}
            control={<Radio color="primary" />}
            label={Trad("cascade_discount")}
            labelPlacement="end" />
        <FormControlLabel
            value="cumul"
            disabled={disableDiscountMode}
            control={<Radio color="primary" />}
            label={Trad("cumul_discount")}
            labelPlacement="end" />
    </RadioGroup>
}
