
import { Client, ClientAxios, ClientHUB, redirectionFinder, URLAdwoneProvider, URLServerProvider } from "hub-lib/client/client.bin";
import io from 'socket.io-client';

import { eSocketCommand, eSocketMsg, SocketAction, SocketCommand, SocketMessage, SocketNegotiationUpdate, TaskProgressCommand } from "hub-lib/models/socket.bin";
import { closeNotify, Notify } from "./utils/Notify.bin";
import { UserExtended } from "hub-lib/models/UserExtended.bin";

import { Trad as ref_Trad } from "hub-lib/models/custom/Trad.bin";
import { MessageModelManager } from "hub-lib/models/KPIsManager.bin";
import { RightManager } from "hub-lib/models/types/rights.bin";


import numbers from 'cldr-numbers-full/main/fr/numbers.json';
import caGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/fr/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/fr/timeZoneNames.json';

import numbersGB from 'cldr-numbers-full/main/en-GB/numbers.json';
import caGregorianGB from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import dateFieldsGB from 'cldr-dates-full/main/en-GB/dateFields.json';
import timeZoneNamesGB from 'cldr-dates-full/main/en-GB/timeZoneNames.json';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import { load } from '@progress/kendo-react-intl';
import { ImportsStatus } from "hub-lib/models/ref_Imports.bin";
import { storeNegotiation } from "./redux/storeNegotiation";
import { setConversations, setNegotiation } from "./redux/negotiationEditorSlice";
import { duplicate } from "hub-lib/tools.bin";
import { Conversation } from "hub-lib/models/ref_Negotiations.bin";

import { TooltipManager as TooltipManagerAdw } from "adwone-lib/index";
import { TooltipManager } from "./components/CustomTooltip";
import { DataProvider } from 'hub-lib/provider'

import { maskRole } from "hub-lib/business/rights/rights.bin";

load(
    likelySubtags,
    currencyData,
    weekData, numbers,
    caGregorian,
    dateFields,
    timeZoneNames,

    numbersGB,
    caGregorianGB,
    dateFieldsGB,
    timeZoneNamesGB
)

ClientAxios.interceptors.response.use((response) => {
    const respData = response.data;
    if (ErrorRedirection.IsErrorRedirection(respData))
        window.location.href = respData.url;
    return response;
});

TooltipManagerAdw.Push = (p) => TooltipManager.Push(p);

RightManager.rightsProvider = () => {
    return (JSON.parse(localStorage.getItem('user')) as UserExtended)?.userPermissions
}

RightManager.roleProvider = () => {
    const user = JSON.parse(localStorage.getItem('user')) as UserExtended;

    const roleName = Object.keys(maskRole).find(key => maskRole[key] === user?.maskRights);
    return roleName as any;
}

const searchDecorator = (params: Partial<ref_Messages | ref_Campaigns>) => {
    if (params?.Group) {
        delete params.AdvertiserGroup;
        delete params.Advertiser;
        delete params.Brand;
        delete params.Product;
    }
    return params;
}

URLAdwoneProvider.provide = () => window.location.origin;

export const redirections = {
    // "/exemple/big/path": "url", // Full path
    // [ref_Trad.name]: { // Splited path
    //     "search": "https://server.adwone.com",
    // },
    [ref_Trad.name]: "https://server.adwone.com",
}

URLServerProvider.provide = (path?: string) => {
    // Custom URL for path


    // full patch then splited path
    if (path) {
        const redirectionFind = redirections[path] || redirectionFinder(redirections, path.split("/").filter(x => x));
        if (redirectionFind) return redirectionFind;
    }

    let currentURL = window?.origin;
    let devServeur = 'http://localhost:3000';

    let baseDev = 'dev.adwone.com';
    let baseDemo = 'demo.adwone.com';
    let baseUAT = 'uat.adwone.com';
    let baseAC = 'agencecompact.adwone.com';
    let baseProd1 = 'adwone.com';
    let baseProd2 = 'adwone.fr';

    let baseProd3 = 'www.adwone.com';
    let baseProd4 = 'www.adwone.fr';

    let urlDico: any = {
        "default": devServeur,
    }

    let add = (protocole: string, url: string, server: string) => {
        urlDico[`${protocole}://${url}`] = server;
    }

    /** LOCAL DEV */
    add('http', 'localhost:3000', 'http://localhost:5000');
    add('http', 'localhost:3001', 'http://localhost:3000');

    /** RELEASE DEV */
    add('http', baseDev, 'https://server.dev.adwone.com');
    add('https', baseDev, 'https://server.dev.adwone.com');

    /** RELEASE demo */
    add('http', baseDemo, 'https://server.demo.adwone.com');
    add('https', baseDemo, 'https://server.demo.adwone.com');

    /** RELEASE RECETTE */
    add('http', baseUAT, 'https://server.uat.adwone.com');
    add('https', baseUAT, 'https://server.uat.adwone.com');

    /** PROD */
    add('https', baseProd1, 'https://server.adwone.com');
    add('https', baseProd2, 'https://server.adwone.com');
    add('https', baseProd3, 'https://server.adwone.com');
    add('https', baseProd4, 'https://server.adwone.com');

    /** AC */
    add('http', baseAC, 'http://server.agencecompact.adwone.com');
    add('https', baseAC, 'https://server.agencecompact.adwone.com');

    return urlDico[currentURL ?? "default"] ?? devServeur;
}

DataProvider.search = async <Ref>(vertexName: string, params?: any, op?: any) => {
    const res = await Client.searchVertex(vertexName, { ...params, _operators: op });
    return res?.data?.results as Ref[];
}

DataProvider.getMetadata = async (vertexName: string, all: boolean = false) => {
    return (await Client.getMetadata(vertexName, all))?.data?.results;
}

DataProvider.getOJDWaves = async (params) => {
    return (await Client.getWaves(params));
}

DataProvider.getOJD = async (params) => {
    return (await Client.getOjd(params));
}

DataProvider.getVwMMDiscountClasses = async () => {
    const sourceMM = GetSrcMMUser();
    if (!sourceMM) return [];

    // Get MM View
    const views = await Client.searchVertex<vw_mm_HasDiscountClass>(vw_mm_HasDiscountClass.name, {
        in: sourceMM["@rid"],
        properties: ['out.ExternalID as ExternalID', 'Referential']
    })

    return <any>views?.data?.results ?? [];

    // const sources = await DataProvider.search<src_MM>(src_MM.name, { properties: ['@rid'] });
    // if (sources.length != 1)
    //     throw new Error(`Cannot find MM Source`);

    // // Get MM View
    // const views = await DataProvider.search<vw_mm_HasDiscountClass>(vw_mm_HasDiscountClass.name, {
    //     Referential: intersection,
    //     in: sources[0]["@rid"],
    //     properties: ['out.ExternalID as ExternalID', 'Referential']
    // })

}

var focused = true;

window.onfocus = function () {
    focused = true;
};
window.onblur = function () {
    focused = false;
};

Client.notificationEvent.addListener(eSocketMsg.negotiation, (body: SocketNegotiationUpdate) => {
    const editedNego = storeNegotiation.getState().editor.negotiation;
    if (editedNego && body?.negotiation?.["@rid"] && editedNego["@rid"] == body.negotiation["@rid"]) {

        const currentConvers = duplicate(storeNegotiation.getState().editor.negotiation.Conversations);
        const currentEditedConvers = duplicate(editedNego.Conversations);

        Object.entries(body.negotiation.Conversations).forEach(([k, v]) => {
            if (!currentEditedConvers[k]) currentEditedConvers[k] = new Conversation();
            const newExchanges = v.Exchanges.slice(currentConvers[k].Exchanges.length, v.Exchanges.length - 1);
            currentEditedConvers[k].Exchanges = { ...currentEditedConvers[k].Exchanges, ...newExchanges }
        })

        storeNegotiation.dispatch(setNegotiation(body.negotiation));
        storeNegotiation.dispatch(setConversations(currentEditedConvers));
    }
});

Client.notificationEvent.addListener(eSocketMsg.notification, (body: SocketMessage) => {
    if (focused || body.force)
        Notify(body.message, body.messageType);
});

import { store } from "./redux/store";
import { finishImports, refreshImports } from "./actions/importsActions";
import { GetCurrentLocale, SetCurrentLocale, Trad, TradProvider } from "trad-lib";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { keyStoreTrads } from "./config.bin";
import { GetSrcMMUser, GetUser, MetadataStorage } from "./utils/localstorage.bin";
import { NotifyCrons } from "hub-lib/models/ref_Crons.bin";
import { eReportStatus, ref_Reports } from "hub-lib/models/ref_Reports";
import { ErrorRedirection } from "hub-lib/errors/types";
import { vw_mm_HasDiscountClass } from "hub-lib/models/orientdb/vw_mm_HasDiscountClass.bin";

ClientHUB.AddSearchDecorator({
    vertex: ref_Messages.name,
    decorate: searchDecorator
})

ClientHUB.AddSearchDecorator({
    vertex: ref_Campaigns.name,
    decorate: searchDecorator
})

ClientHUB.AddSearchDecorator({
    vertex: ref_Agreements.name,
    decorate: searchDecorator
})

Client.notificationEvent.addListener(eSocketMsg.command, async (body: SocketCommand) => {

    switch (body.command) {
        case eSocketCommand.sendLocalstorage:
            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key != keyStoreTrads)
                    localStorageData[key] = localStorage.getItem(key);
            }
            await Client.log({ Category: "localstorage", Action: "get", Informations: localStorageData });
            break;
        case eSocketCommand.clearCacheSession:
            await Client.getUser(true);
            Notify(Trad("your_account_modified"), "warning");
            break;
        case eSocketCommand.progress:

            if (body.infos?.taskId) {
                const task = body.infos as TaskProgressCommand;
                Notify(task.message, "info", { progress: { Current: task.current, Total: task.total, taskId: task.taskId } });
                break;
            }

            if (body.infos?.cron) {
                const { cron, report } = body.infos as { cron: NotifyCrons, report: ref_Reports }
                if (report.Progress.Status === eReportStatus.DONE) {
                    if (cron.TaskParams.EstimateID) {
                        if (report.Errors?.length === report.Progress.Total) {
                            Notify(Trad("no_message_deversed"), "error");
                        } else if (cron.TaskParams.EstimateType !== "Delete") {
                            Notify(`${Trad("campaign_deversed")} ${report.Progress.Current - report.Errors?.length}/${report.Progress.Total}`, "success");
                        } else {
                            Notify(Trad("estimate_updated"), "success");
                        }
                    }
                    closeNotify();
                } else {
                    Notify(Trad(cron.TaskParams.NotificationMessage ?? "please_wait_sending"), "info", { progress: report.Progress });
                }
            }
            if (body.infos?.Report?.Status == ImportsStatus.IN_PROGRESS)
                store.dispatch(refreshImports(body.infos, body.forceUpdate))
            else if ([ImportsStatus.PENDING, ImportsStatus.FAILED, ImportsStatus.DONE, ImportsStatus.IMPORTING].includes(body.infos?.Report?.Status))
                store.dispatch(finishImports(body.infos))
            break;
        default:
            // unkwnown
            break;
    }
});

Client.notificationEvent.addListener(eSocketMsg.action, async (body: SocketAction) => {
    Notify(body.message, body?.options?.type ?? "warning", {
        action: {
            label: Trad(body.service),
            onclick: async () => {
                switch (body.service) {
                    case "create":
                        await Client.createVertex(body.vertex, body.body);
                        break;
                    case "update":
                        await Client.updateVertex(body.vertex, body.body);
                        break;
                    case "delete":
                        await Client.deleteVertex(body.vertex, body.body);
                        break;
                }
            }
        }
    });
});


let socket: SocketIOClient.Socket = null;

Client.onGetStatus.addListener("GET", async (user: UserExtended) => {
    if (!socket) {
        try {
            const { data } = await Client.GetCookie();

            socket = io(URLServerProvider.provide());
            socket.on('connect', () => {
                socket?.emit('log', { user, idSession: data })
            });

            socket.on(eSocketMsg.negotiation, (body: any) => {
                Client.notificationEvent.emit(eSocketMsg.negotiation, body);
            });

            socket.on(eSocketMsg.notification, (body: any) => {
                Client.notificationEvent.emit(eSocketMsg.notification, body);
            });

            socket.on(eSocketMsg.command, (body: any) => {
                Client.notificationEvent.emit(eSocketMsg.command, body);
            });

            socket.on(eSocketMsg.action, (body: any) => {
                Client.notificationEvent.emit(eSocketMsg.action, body);
            });

            socket.on('connect_error', (e: any) => {
                socket?.disconnect();
                socket = undefined;
            });
        } catch (error) {
            console.log('Cannot start socket.')
            console.log(error)
            //Client.log("ErrorSocket", { Tag: "ErrorSocket", Message: "Cannot create socket", Error: error })
        }
    }
});

Client.onGetStatus.addListener("DELETE", () => {
    socket?.disconnect();
    socket = undefined;
});

export async function initialize(): Promise<any> {
    await TradProvider.GetInstance().Init();
    await SetCurrentLocale(GetCurrentLocale());
}
