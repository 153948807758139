import { lnk_HasPropertyTypeExtended, MessageModelManager } from "hub-lib/models/KPIsManager.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";


type ValueChoiceCheckerArgs = { children?: any, propId: string, propValue: string }
export function ValueChoiceChecker({ children, propId, propValue }: ValueChoiceCheckerArgs) {

    const message = store.getState().messageEditor.getMessage();
    const messagePropValue =  useSelector((root: RootState) => root.messageEditor.message.ModelProperties[propId]);
    const [valueChoices, setValueChoices] = React.useState<lnk_HasPropertyTypeExtended["ValueChoices"]>();

    React.useEffect(() => {
        if (!valueChoices) {
            Promise.resolve().then(async () => {
                const MessageModelMgr = new MessageModelManager(message.Media, "Message");
                const properties: lnk_HasPropertyTypeExtended[] = await MessageModelMgr.GetProperties();
                setValueChoices(properties?.find(p => p.Id == propId).ValueChoices);
            })
        }
    })

    if (!valueChoices)
        return <></>

    if (messagePropValue != valueChoices.find(v => v.value == propValue)?.rid)
        return <></>

    return <>{children}</>
}