import * as React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { lnk_HasKPIExtended } from "hub-lib/models/KPIsManager.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradProp } from "trad-lib";
import { RootState, store } from "../../../redux/store";
import { GetMessageOffers, SetPriceOffer } from "../../../utils/Tools";
import { performanceKPIs, setMessage, setMessageSync } from '../../../redux/messageEditorSlice';
import { DiscountEditor } from "./DiscountEditor/DiscountEditor.bin";
import { BarterEditor } from "./DiscountEditor/BarterEditor";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { Client } from "hub-lib/client/client.bin";
import { CustomNumericTextBox } from "../Generic/Common.bin";
import { AdwAutocomplete } from "adwone-lib";
import { CPMFeesEditor } from "./DiscountEditor/CPMFeesEditor";
import { ModelPropertiesChoicesEditor } from "./MessageEditor/ModelPropertiesChoicesEditor";


export function CPMEditor() {
    const dispatch = useDispatch();
    const grossCPM = useSelector((root: RootState) => root.messageEditor.message.KPIs["GrossCPM"]);
    const kpiCPMRid = useSelector((root: RootState) => root.messageEditor.message.KpiCPM);
    const kpis = useSelector((root: RootState) => root.messageEditor.kpis);
    const kpiChoicesCPM = kpis.filter(kpi => performanceKPIs.includes(kpi.Name));

    return <Grid container xs={12}>
        <Grid item xs={6} className="message_details_leftcombo">
            <CustomNumericTextBox
                min={0}
                title={Trad("GrossCPM")}
                value={grossCPM}
                onChange={(e) => {
                    const message = store.getState().messageEditor.getMessage();
                    message.KPIs["GrossCPM"] = e.value;
                    dispatch(setMessage(message));
                }} />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <AdwAutocomplete options={kpiChoicesCPM}
                value={kpiChoicesCPM.find(kpi => kpi.KPI == kpiCPMRid)}
                disableClearable
                getOptionLabel={(option: lnk_HasKPIExtended) => option.Name}
                onChange={(event, v: any) => {
                    const message = store.getState().messageEditor.getMessage();
                    message.KpiCPM = v.KPI;
                    dispatch(setMessage(message));
                }}
            />
        </Grid>
    </Grid>
}

type DiscountEditorStepProps = {};
export function DiscountEditorStep({ }: DiscountEditorStepProps) {

    const dispatch = useDispatch();
    const currencyCode = useSelector((root: RootState) => root.messageEditor.currencyCode);
    //const message = { ...useSelector((root: RootState) => root.messageEditor.getMessage(), JSONEqualityComparer) }
    const media = useSelector((root: RootState) => root.messageEditor.message.Media);
    const paidPlacement = useSelector((root: RootState) => root.messageEditor.message.PaidPlacement);
    const grossVal = useSelector((root: RootState) => root.messageEditor.message.KPIs.GrossVal);
    const isGrossCPM = useSelector((root: RootState) => root.messageEditor.message.IsGrossCPM);
    const [medias, setMedias] = React.useState<ref_Media[]>();

    React.useEffect(() => {
        if (!medias)
            Promise.resolve().then(async () => setMedias(await Client.searchVertexTyped(ref_Media)))
    }, [medias]);

    return <Grid item xs={12}>

        {(media == medias?.find(m => m.Code == "PR")?.["@rid"]) &&
            <>
                <Grid item xs={6} className="message_details_leftcombo">
                    <ModelPropertiesChoicesEditor property='RightOfAsylum' />
                </Grid>
                <Grid container xs={12} className="message_details_full">
                    <Grid item xs={3}>
                        <Checkbox label={TradProp("PaidPlacement", ref_Messages)}
                            checked={!paidPlacement}
                            onChange={async (event) => {
                                const message = store.getState().messageEditor.getMessage();
                                message.PaidPlacement = !event.value;
                                if (message.Placement) {
                                    const messageOffers = await GetMessageOffers(message, { "ModelProperties.Format": message.Format });
                                    if (!message.PaidPlacement) {
                                        const offerFormat = messageOffers.find(o => !o.ModelProperties.Emplacement);
                                        await SetPriceOffer(message, offerFormat, "Format");
                                    }
                                    const offerPlacement = messageOffers.find(o => o.ModelProperties.Emplacement == message.Placement);
                                    await SetPriceOffer(message, offerPlacement, "Emplacement", message.PaidPlacement);
                                }
                                //editor.updateAll();
                                dispatch(setMessage(message));
                            }} />
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox label={TradProp("IsGrossCPM", ref_Messages)}
                            checked={isGrossCPM}
                            onChange={async (event) => {
                                const message = store.getState().messageEditor.getMessage();
                                message.IsGrossCPM = event.value;
                                dispatch(setMessage(message));
                            }}
                        />
                    </Grid>
                </Grid>
                {isGrossCPM &&
                    <CPMEditor />}
            </>
        }
        <div style={{ display: 'flex' }}>
            <div className="base_tarifs message_details_leftcombo">
                <GrossEditor />
            </div>
            <div className="base_tarifs message_details_rightcombo">
                <CustomNumericTextBox
                    min={0}
                    title={`${Trad("Brut Valorisé")} ${currencyCode}`}
                    value={grossVal}
                    onChange={(e) => {
                        const message = store.getState().messageEditor.getMessage();
                        message.KPIs.GrossVal = e.value;
                        dispatch(setMessageSync(message));
                    }} />
            </div>
        </div>

        <DiscountEditor />
        <BarterEditor />
        <CPMFeesEditor />
    </Grid >
}

let timeout = undefined;
function GrossEditor() {

    const dispatch = useDispatch();
    const currencyCode = useSelector((root: RootState) => root.messageEditor.currencyCode);
    const messageBase = store.getState().messageEditor.getMessage();
    const [isEditing, setIsEditing] = React.useState(false);
    const [gross, setGross] = React.useState(messageBase.KPIs.Gross);
    const Gross = useSelector((root: RootState) => root.messageEditor.message.KPIs?.Gross);

    React.useEffect(() => {
        setGross(Gross);
    }, [Gross])

    const dispatchValue = (message: ref_Messages) =>
        dispatch(setMessage(message));

    return <CustomNumericTextBox
        min={0}
        disabled={messageBase.IsGrossCPM}
        title={`${Trad("base_rate")} ${currencyCode}`}
        value={isEditing ? gross : Gross}
        onFocus={() => setIsEditing(true)}
        onBlur={() => {
            const message = store.getState().messageEditor.getMessage();
            if (!message.KPIs.GrossVal) {
                message.KPIs.GrossVal = message.KPIs.Gross;
                dispatchValue(message);
            }
            setIsEditing(false)
        }}
        onChange={(e) => {
            const value = e.value;
            setGross(value);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const message = store.getState().messageEditor.getMessage();
                message.KPIs.Gross = value;
                dispatchValue(message);
            }, 500);
        }} />
}