import { TreeListTextFilter } from "@progress/kendo-react-treelist";
import { Client } from "hub-lib/client/client.bin";
import * as React from "react";
import { TradProp } from "trad-lib";
import { CustomTreeList } from "../../VertexGrid/Generic/CustomTreeList";
import Template from "../Template";
import { ref_UserSessions } from "hub-lib/models/ref_UserSessions";
import { ref_Customers } from "hub-lib/models/orientdb/ref_Customers.bin";
import { User } from "hub-lib/models/orientdb/User.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { Typed } from "hub-lib/tools.bin";

type UserSessionsRow = {
    id: any;
    "@rid": string;
    label: string;
    children?: UserSessionsRow[];
    expanded?: boolean
}

type UserSessionsWrap = {
    [customer: string]: {
        [user: string]: ref_UserSessions
    }
}

export function UserSessionsGridComponent() {
    const [data, setData] = React.useState<UserSessionsRow[]>(null);

    React.useEffect(() => {
        if (!data) {
            Client.getUserSessions().then((res: UserSessionsWrap) => {
                const customerRids = Object.keys(res);
                const userRids = Object.values(res).flatMap(o => Object.keys(o));

                Promise.all([
                    Client.searchVertexTyped(ref_Customers, { "@rid": customerRids, properties: ['Company.Name as CompanyName'] }),
                    Client.searchVertexTyped(User, { "@rid": userRids })
                ]).then(([customers, users]) => {
                    const userMap = (customerRid: IRid['@rid'], userRid: IRid['@rid']) => {

                        const session: ref_UserSessions = res[customerRid][userRid];
                        const classes = Object.keys(session.Rights);

                        return Typed<UserSessionsRow>({
                            id: userRid,
                            '@rid': userRid,
                            label: users.find(c => c["@rid"] == userRid)?.name + ` (${session["@rid"]})`,
                            children: classes.map(c => Typed<UserSessionsRow>({
                                id: `${userRid}_${c}`,
                                '@rid': '',
                                label: c + ` (${session.Rights[c]?.length ?? 0})`,
                                children: session.Rights[c].map(r => Typed<UserSessionsRow>({
                                    id: `${userRid}_${r["@rid"]}`,
                                    '@rid': r["@rid"],
                                    label: r.Name,
                                    children: []
                                }))
                            }))
                        })
                    }

                    const customerMap = (rid: IRid['@rid']) => {
                        return Typed<UserSessionsRow>({
                            id: rid,
                            '@rid': rid,
                            label: customers.find(c => c["@rid"] == rid)?.['CompanyName'],
                            children: Object.keys(res[rid]).map(u => userMap(rid, u))
                        })
                    }

                    setData(Object.keys(res).map(customerMap));
                })
            })
        }
    });

    return <div className='grid_base_container'>
        <CustomTreeList
            expandField='expanded'
            subItemsField='children'
            data={data ?? []}
            rowHeight={30}
            gridProps={{
                className: 'custom-tree-list  link-mgr'
            }}
            columns={[{
                title: TradProp('@rid'),
                field: '@rid',
                filter: TreeListTextFilter,
                className: "no-wrap",
                width: '200px',
                resizable: true,
            },
            {
                title: TradProp('Name'),
                field: 'label',
                filter: TreeListTextFilter,
                expandable: true,
                resizable: true
            }]} />
    </div>
}

export const UserSessionsGrid = Template(UserSessionsGridComponent);