import {
  PivotGridAxis,
  Measure,
  sumAggregate,
  maxAggregate,
  minAggregate,
  averageAggregate,
  PivotLocalDataServiceArgs,
  Dimension
} from "@progress/kendo-react-pivotgrid";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { Client } from "hub-lib/client/client.bin";
import { TradProp, TradValue } from "trad-lib";
import { eKPIType, KPIsManagerCache, ToEPropType } from "hub-lib/models/KPIsManager.bin";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";
import { eColumnType } from "hub-lib/models/types.bin";
import { CreateIndicateur, eDirection, Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { GetCellTemplate, GetKPITemplate } from "format-lib/index.bin";
import { clone, distinct, Typed } from "hub-lib/tools.bin";
import { store } from "../../../../redux/store";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { TcdState } from "../../../../redux/tcdSlice";

type InitPivotGridArgsOptions = {
  rows?: Indicateur[]
  columns?: Indicateur[]
}

type MessageValue = {
  rows: string[];
  columns: string[];
  data: ref_Messages;
  value: number;
}

type GroupMessageValue = {
  [key: string]: MessageValue[]
}

export async function InitPivotGridArgs(filter: any, { rows, columns }: InitPivotGridArgsOptions = {}) {

  console.log(`[InitPivotGridArgs] CALL`, { filter, rows, columns });
  await DiscountManager.Load();

  const allIndicateurs = await IndicateursProvider.GetInstance().Provide();

  const indicateurs = allIndicateurs.map(i => i.indicateur);
  let kpis = allIndicateurs
    .filter(i => i.columnType != eColumnType.Property)
    .map(i => CreateIndicateur(i.indicateur));

  const metaData = await Client.getDimensions();
  const messages = await Client.searchVertexTyped(ref_Messages, {
    ...filter,
    Active: true,
    properties: ["*", ...metaData.filter(p => p.type == "@rid").map(p => `${p.field}.Name as ${p.field.replace(".", "")}Name`)]
  });

  const time0045 = new Date().getTime();
  for (const ind of indicateurs) {
    for (const message of messages) {
      const key = IndicateurToString(ind);
      message[key] = await CreateIndicateur(ind).Compute([message]);
    }
  }
  const _time0045 = new Date().getTime();
  console.log(`[InitPivotGridArgs] compute messages indicateurs ${_time0045 - time0045}ms`);

  messages.forEach(m => {
    const start = new Date(m.Start);
    m["Year"] = start.getFullYear();
  })

  console.log(`[InitPivotGridArgs] messages`, messages)

  const dimensions: PivotLocalDataServiceArgs['dimensions'] = {
    ...indicateurs.map(i => ({
      [IndicateurToString(i)]: {
        caption: i.name,
        displayValue: (item: ref_Messages) => {
          if (i.valueType == eKPIType.Rid)
            return item[`${i.field.replace(".", "")}Name`];
          return item[IndicateurToString(i)];
        },
        sortValue: (displayValue: string) => displayValue
      }
    })).reduce((a, b) => ({ ...a, ...b }), {}),
    // Year: {
    //   caption: TradProp("Year", ref_Messages),
    //   displayValue: (item: ref_Messages) => item["Year"],
    //   sortValue: (displayValue: string) => displayValue
    // }
  }

  kpis = kpis.flatMap(k => Object.values(eDirection).map(v => ({ ...clone(k), name: k.name + ` ${v}`, optionsBase: { direction: v } })));

  const measures: Measure[] = kpis.map(k => {
    const template = GetKPITemplate(k.valueType);
    const keySum = `sum${k.name}`;
    const mySumAggregate = {
      init: function (data) {
        if ((keySum in data) === false) {
          data[keySum] = [];
        }
      },
      merge: function (src: GroupMessageValue, dest: GroupMessageValue) {
        dest[keySum] = [...(dest[keySum] ?? []), ...(src[keySum] ?? [])];
      },
      accumulate: function (acc: GroupMessageValue, value: MessageValue) {
        if (!acc[keySum])
          acc[keySum] = [];

        acc[keySum].push(value);
      },
      result: function (data: GroupMessageValue) {
        if (!data[keySum])
          return 0;

        const resultValue = k.Compute(data[keySum].map(v => v.data)) ?? 0;

        if (k.optionsBase?.direction && k.optionsBase?.direction != eDirection.U) {
          let totalMessages: ref_Messages[] = null;
          switch (k.optionsBase?.direction) {
            case eDirection["%HorizontalTotal"]:

              const dicoRow = store.getState().tcd.dicoRowCross;
              const rowKeys = store.getState().tcd.rows.map(r => IndicateurToString(r));

              console.log(`[TCD] **************************`)
              console.log(`[TCD] SET`, new Set(data[keySum].flatMap(v => v.rows)))
              console.log(`[TCD] KEY SUM`, data[keySum]);
              console.log(`[TCD] DICO ROW`, dicoRow);
              console.log(`[TCD] rowKeys`, rowKeys);

              rowKeys.forEach((k, i) => {
                const valuesOfKDim = Array.from(new Set(data[keySum].flatMap(g => g.rows[i])));
                const messagesFound = distinct(valuesOfKDim.flatMap(val => dicoRow[k]?.[val] ?? []), m => m["@rid"]);
                if (!totalMessages)
                  totalMessages = messagesFound;
                else
                  totalMessages = totalMessages.filter(m => messagesFound.find(m2 => m2["@rid"] == m["@rid"]));
              })

              console.log(`[TCD] totalMessages`, totalMessages);

              // const keyRow = Array.from(new Set(data[keySum].flatMap(v => v.rows))).sort().join(";");
              // console.log(`[TCD] keyRow`, keyRow);
              // totalMessages = dicoRow.get(keyRow) ?? [];

              break;
            case eDirection["%VerticalTotal"]:
              const dicoCol = store.getState().tcd.dicoColCross;
              const colKeys = store.getState().tcd.columns.map(r => IndicateurToString(r));
              colKeys.forEach((k, i) => {
                const valuesOfKDim = Array.from(new Set(data[keySum].flatMap(g => g.columns[i])));
                const messagesFound = distinct(valuesOfKDim.flatMap(val => dicoCol[k]?.[val] ?? []), m => m["@rid"]);
                if (!totalMessages)
                  totalMessages = messagesFound;
                else
                  totalMessages = totalMessages.filter(m => messagesFound.find(m2 => m2["@rid"] == m["@rid"]));
              })

              break;

            default:
              break;
          }

          const totalValue = k.Compute(totalMessages) ?? 0;


          console.log(`[TCD] indicateur`, k);
          console.log(`[TCD] totalValue`, totalValue);
          console.log(`[TCD] resultValue`, resultValue);


          if (isNaN(resultValue) || isNaN(totalValue)) {
            console.log(`[InitPivotGridArgs] isNaN`, resultValue, totalValue, store.getState().tcd)
            console.log(`[InitPivotGridArgs] isNaN rowKey`, Array.from(new Set(data[keySum].flatMap(v => v.rows))))
            console.log(`[InitPivotGridArgs] isNaN colKey`, Array.from(new Set(data[keySum].flatMap(v => v.columns))))
            console.log(`[InitPivotGridArgs] isNaN totalMessages`, totalMessages)
          }




          if (totalValue == 0)
            return 1;
          if (typeof resultValue != "number")
            return resultValue
          return resultValue / totalValue;
        }

        return resultValue;

      },
      format: function (value) {
        if (k.optionsBase?.direction && k.optionsBase?.direction != eDirection.U) {
          if (typeof value != "number")
            return value;
          return template(value * 100) + "%";
        }
        return template(value);
      }
    };

    return Typed<Measure>({
      name: k.name,
      value: (m) => {
        const rows = store.getState().tcd.rows.map(r => CreateIndicateur(r).Compute([m]));
        const columns = store.getState().tcd.columns.map(r => CreateIndicateur(r).Compute([m]));
        return Typed<MessageValue>({
          rows,
          columns,
          data: m,
          value: CreateIndicateur(k).Compute([m]) ?? 0
        })
      },
      aggregate: mySumAggregate
    })
  })

  // const measures: Measure[] = [
  //   { name: "Total", value: (item: ref_Messages) => item.KPIs.NetCO, aggregate: sumAggregate, test: "toto" } as any,
  //   { name: "Max", value: (item: ref_Messages) => item.KPIs.NetCO, aggregate: maxAggregate },
  //   { name: "Min", value: (item: ref_Messages) => item.KPIs.NetCO, aggregate: minAggregate },
  //   { name: "Average", value: (item: ref_Messages) => item.KPIs.NetCO, aggregate: averageAggregate },
  // ];

  const defaultMeasureAxes: PivotGridAxis[] = [{ name: ["[KPI].Net"] }];
  //const defaultMeasureAxes: PivotGridAxis[] = [];

  const getIndicateurKey = (field: string) => IndicateurToString(indicateurs.find(i => i.field == field))

  const defaultRowAxes: PivotGridAxis[] =
    rows?.map((r, i) => ({ name: [IndicateurToString(r)], expand: i == 0 }))
    ?? [
      { name: [getIndicateurKey("AdvertiserGroup")], expand: true },
      { name: [getIndicateurKey("Advertiser")] },
    ];

  const defaultColumnAxes: PivotGridAxis[] =
    columns?.map((r, i) => ({ name: [IndicateurToString(r)], expand: i == 0 }))
    ?? [
      { name: [getIndicateurKey("Media")], expand: true },
      { name: [getIndicateurKey("Support")] },
    ];

  const defaultFilter: FilterDescriptor[] = [];
  const defaultSort: SortDescriptor[] = Object.keys(dimensions).map((k) => ({
    field: k,
    dir: "asc" as "asc",
  }));

  const service: PivotLocalDataServiceArgs = {
    dimensions,
    data: messages,
    measures,
    defaultRowAxes,
    defaultColumnAxes,
    defaultMeasureAxes,
    defaultSort,
    defaultFilter,
  };

  console.log(`[InitPivotGridArgs] service`, service);

  return { service, ...await IndexCells(columns, rows, messages) };
}

export async function IndexCells(columns: Indicateur[], rows: Indicateur[], data: ref_Messages[]) {
  console.log(`[InitPivotGridArgs] IndexCells`);
  const dicoRowCross: TcdState['dicoRowCross'] = {};
  const dicoColCross: TcdState['dicoColCross'] = {};

  const instancesRows = rows.map(r => CreateIndicateur(r));
  const instancesCols = columns.map(r => CreateIndicateur(r));

  await Promise.all(data.map(async msg => {
    const rowKey = await Promise.all(instancesRows.map(i => i.Compute([msg])));
    rowKey.forEach((k, i) => {
      const indicateurKey = IndicateurToString(rows[i]);
      if (!dicoRowCross[indicateurKey]) dicoRowCross[indicateurKey] = {};
      if (!dicoRowCross[indicateurKey][k]) dicoRowCross[indicateurKey][k] = []
      dicoRowCross[indicateurKey][k].push(msg);
    });

    const colKey = await Promise.all(instancesCols.map(i => i.Compute([msg])));
    colKey.forEach((k, i) => {
      const indicateurKey = IndicateurToString(columns[i]);
      if (!dicoColCross[indicateurKey]) dicoColCross[indicateurKey] = {};
      if (!dicoColCross[indicateurKey][k]) dicoColCross[indicateurKey][k] = []
      dicoColCross[indicateurKey][k].push(msg);
    });
  }));

  return { dicoRowCross, dicoColCross }
}