import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { Row } from "adwone-engine/types.bin";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { clone, GetHashCode, propertyOf } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad, TradClassName } from "trad-lib";
import { RootState } from "../../redux/store";
import { ControllableChart } from "../graphs/ControllableChart.bin";
import { IndicateursProvider } from "hub-lib/IndicateursProvider";
import React from "react";
import { Indicateur } from "adwone-engine/index.bin";
import Loader from "../layout/Loader";

const Title = ({ label }: { label: string }) => {
    return <h3 style={{ position: "absolute", top: 0, left: 0, zIndex: 1, margin: 14 }}>{label}</h3>;
};

export const TopSupportsChart = () => {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.columnsModele
    );
    const { currency, kpis: kpiPieChart } = modele.DashboardConfig;
    const filters = { ...tabFilters, ...modele?.DashboardConfig?.filters };

    return (
        <div className="dashboard-block">
            <ControllableChart
                key={"top-supports-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(ref_Messages.name),
                        vertexType: ref_Messages.name,
                        vertexProperty: propertyOf<ref_Messages>("Support"),
                    },
                ]}
                customChart={{
                    title: {
                        text: Trad("top_supports"),
                        visible: true,
                        align: "left",
                        font: "bold 14px Arial,Helvetica,sans-serif",
                    },
                    seriesItem: {
                        color: "#009BCE",
                        gap: 0.8,
                    },
                    max: {
                        nb: 5,
                        hide: true,
                    },
                }}
                type="bar"
                disableCustomLegend
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={{
                    field: propertyOf<ref_Messages>("Support"),
                    type: "@rid",
                    linkedClass: ref_Messages.name,
                }}
                KPI={kpiPieChart?.["@rid"]}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

export const MixMediasChart = () => {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.columnsModele
    );
    const currency = useSelector(
        (root: RootState) => root.project.columnsModele.DashboardConfig.currency
    );
    const filters = { ...tabFilters, ...modele?.DashboardConfig?.filters };
    return (
        <div className="dashboard-block">
            <Title label={Trad("mix_medias")} />
            <ControllableChart
                key={"mix-medias-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(ref_Messages.name),
                        vertexType: ref_Messages.name,
                        vertexProperty: propertyOf<ref_Messages>("Media"),
                    },
                ]}
                type="donut"
                disableCustomLegend
                customChart={{
                    legend: {
                        position: "right",
                        orientation: "vertical",
                        labels: {
                            margin: 4,
                            content: (e) => `${(e.percentage * 100).toFixed(2)}%\n${e.text}`,
                        },
                    },
                }}
                filters={filters as any}
                dimension={{
                    field: propertyOf<ref_Messages>("Media"),
                    type: "@rid",
                    linkedClass: ref_Messages.name,
                }}
                customKPI={(a: Row<ref_Messages>) => a?.CountData}
            />
        </div>
    );
};

export const MonthlyBudgetChart = () => {
    const [indicateurs, setIndicateurs] = React.useState<Indicateur[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.columnsModele
    );
    const { currency, kpis: kpiPieChart } = modele.DashboardConfig;
    const getIndicateur = async () => {
        const provided = (await IndicateursProvider.GetInstance().Provide()).map((i) => i.indicateur);
        const month = provided.find((i) => i.name === Trad("month"));
        const year = provided.find((i) => i.name === Trad("year"));
        setIndicateurs([month, year]);
        setLoaded(true);
    };

    const filters = { ...tabFilters, ...modele?.DashboardConfig?.filters };

    React.useEffect(() => {
        if (!loaded) getIndicateur();
    });

    if (!loaded)
        return (
            <div className="dashboard-block">
                <Loader />;
            </div>
        );

    return (
        <div className="dashboard-block">
            <ControllableChart
                key={"monthly-budget-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(ref_Messages.name),
                        vertexType: ref_Messages.name,
                        vertexProperty: propertyOf<ref_Messages>("Start"),
                    },
                ]}
                start={tabFilters?.Start}
                end={tabFilters?.End}
                customChart={{
                    title: {
                        text: Trad("monthly_budget"),
                        visible: true,
                        align: "left",
                        font: "bold 14px Arial,Helvetica,sans-serif",
                    },
                    seriesItem: {
                        color: "#009BCE",
                    },
                    fixed: 12,
                }}
                type="column"
                disableCustomLegend
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={indicateurs}
                KPI={kpiPieChart?.["@rid"]}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

export const BudgetCompanyChart = () => {
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const modele = useSelector(
        (root: RootState) => root.project.columnsModele
    );
    const currency = useSelector(
        (root: RootState) => root.project.columnsModele.DashboardConfig.currency
    );
    const kpiPieChart = useSelector(
        (root: RootState) => root.project.columnsModele.DashboardConfig.kpis
    );
    const filters = { ...tabFilters, ...modele?.DashboardConfig?.filters };
    return (
        <div className="dashboard-block">
            <Title label={Trad("budget_company")} />
            <ControllableChart
                key={"budget-company-" + GetHashCode({ filters, currency })}
                multiple
                vertexAutocomplete={[
                    {
                        label: TradClassName(ref_Messages.name),
                        vertexType: ref_Messages.name,
                        vertexProperty: propertyOf<ref_Messages>("AdvCompany_Com"),
                    },
                ]}
                type="donut"
                disableCustomLegend
                customChart={{
                    legend: {
                        position: "right",
                        orientation: "vertical",
                        labels: {
                            margin: 4,
                            content: (e) => `${(e.percentage * 100).toFixed(2)}%\n${e.text}`,
                        },
                    },
                    max: {
                        otherWhen: 0.1,
                    },
                }}
                filters={{ ...(filters as any), Currency: currency?.["@rid"] }}
                dimension={{
                    field: propertyOf<ref_Messages>("AdvCompany_Com"),
                    type: "@rid",
                    linkedClass: ref_Messages.name,
                }}
                KPI={kpiPieChart?.["@rid"]}
                KPIType={eKPIType.Price}
            />
        </div>
    );
};

