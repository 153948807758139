import * as React from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Trad, GetTimeFormat } from 'trad-lib';

export class TPros {
    selectedMonth: any;
    selectedDay: any;
    selectedMonthEvents: any;
}

export default class Events extends React.Component<TPros, any>{
    constructor(props: TPros) {
        super(props);
    }
    render() {
        const currentSelectedDay = this.props.selectedDay;
        const monthEvents = this.props.selectedMonthEvents;
        const monthEventsSorted = monthEvents.sort((a :any, b:any) => {
            if(a.allDay) return -1
            if(b.allDay) return 1
            return a.date.valueOf() - b.date.valueOf()
        })
        const monthEventsRendered = monthEventsSorted.map((event :any, index :any) => {
            return (
                <div
                    key={event.title}
                    className="event-container"
                >
                    <TransitionGroup
                        component="div"
                        className="animated-time"
                        transitionName="time"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}
                    >
                        <div className="event-time event-attribute">
                            {event.allDay ? Trad("allday") : event.date.format(GetTimeFormat())}
                        </div>
                    </TransitionGroup>
                    <TransitionGroup
                        component="div"
                        className="animated-title"
                        transitionName="title"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}
                    >
                        <div className="event-title event-attribute">
                            <div className={`pill_${event.category} pill_container`}/>
                            {event.title} <br/><div className="event-description">{event.description}</div>
                        </div>
                    </TransitionGroup>
                </div>
            );
        });

        let dayEventsRendered = [];

        for (var i = 0; i < monthEventsRendered.length; i++) {
            if (monthEvents[i].date.isSame(currentSelectedDay, "day") || monthEvents[i].end.isSame(currentSelectedDay, "day") || currentSelectedDay.isBetween(monthEvents[i].date, monthEvents[i].end,"day")) {
                dayEventsRendered.push(monthEventsRendered[i]);
            }

        }

        return (
            <div className="day-events">
                {dayEventsRendered}
                {dayEventsRendered.length === 0 &&
                <div
                className="event-container"
                >
                        <TransitionGroup
                            component="div"
                            className="animated-time-alt"
                            transitionName="title"
                            transitionAppear={true}
                            transitionAppearTimeout={500}
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={500}
                        >
                            <span className="no-events">{Trad("no_events")}</span>
                        </TransitionGroup></div>
                }
            </div>
        );
    }
}