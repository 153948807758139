import * as React from 'react'
import { Trad, TradProp } from 'trad-lib';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_Products } from 'hub-lib/models/orientdb/ref_Products.bin';
import { ref_Campaigns } from 'hub-lib/models/ref_Campaigns.bin';
import { VertecesStyleComponent } from './VertecesStyleComponent';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { ref_Messages } from 'hub-lib/models/ref_Messages.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/models/ref_SchedulerConfigurations.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ref_PropertyType } from 'hub-lib/models/orientdb/ref_PropertyType.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { FilterStorage, IsDebugMode, SchedulerStorage } from '../../../../utils/localstorage.bin';
import { eCompare } from 'hub-lib/operators.bin';
import { GenerateKey, GetSubElement, propertiesOf, propertyOf } from 'hub-lib/tools.bin';
import { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { indicateurToOptions, LabelLinesCreator, PropertyConfig } from './LabelLinesCreator';
import { CustomBadge } from '../../Filters/MenuItemFilters';
import { ModeleCreatorBase } from '../../ModeleCreator/ModeleCreatorBase';
import { RootState, store } from '../../../../redux/store';
import { ref_Visuals } from 'hub-lib/models/ref_Attachments.bin';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { IndicateurOption, IndicateursProvider } from 'hub-lib/IndicateursProvider';

class TProps {
    onChange?: (template: ref_SchedulerConfigurations) => void;
    onResizeValue?: () => void
}

class TState {
    selectedTab: number = 0;
    template: ref_SchedulerConfigurations;
    vertecesToMap: PropertyConfig[];
    labelBuilders: string[] = propertiesOf<ref_SchedulerConfigurations>("LabelBuildTop", "LabelBuild", "LabelBuildBottom");
    expanded: string = "LabelBuild";
    advancedPopup: boolean;
    pickerOpen: boolean;
}

export class SchedulerConfigDialog extends React.Component<TProps, TState>{

    constructor(props: TProps) {
        super(props);
        this.state = new TState();
    }

    generateAdvancedStyles(barProperty?: string) {
        const { vertecesToMap } = this.state;
        if (barProperty)
            return vertecesToMap.filter(s => s.key != barProperty);
        return vertecesToMap;
    }

    async componentDidMount() {
        if (IsDebugMode()) {
            console.log(`SchedulerConfigDialog componentDidMount`)
        }

        const propertyTypeFormat = (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Format" }))?.data?.results?.[0]?.["@rid"];
        const propertyTypeEmplacement = (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Emplacement" }))?.data?.results?.[0]?.["@rid"];
        const financialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Financial" }))?.data?.results?.[0]?.["@rid"];
        const commercialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Commercial" }))?.data?.results?.[0]?.["@rid"];

        let advStyles: PropertyConfig[] = [
            {
                vertex: ref_Property, params: {
                    "_operators": [{
                        property: "PropertyType",
                        value: propertyTypeEmplacement,
                        compare: eCompare.Contains
                    }]
                }, label: "Placement",
                key: propertyOf<ref_Messages>("Placement")
            },
            { vertex: ref_Property, params: { PropertyType: propertyTypeFormat }, label: "Format", key: propertyOf<ref_Messages>("Format") },
            { vertex: ref_Campaigns, key: propertyOf<ref_Messages>("Campaign"), params: { Source: store.getState().project.filters.Source ?? "ADWONE" } },
            { vertex: ref_Products, key: propertyOf<ref_Messages>("Product") },
            { vertex: ref_Brands, key: propertyOf<ref_Messages>("Brand") },
            { vertex: ref_Advertisers, key: propertyOf<ref_Messages>("Advertiser") },
            { vertex: ref_AdvertiserGroups, key: propertyOf<ref_Messages>("AdvertiserGroup") },
            { vertex: ref_BroadcastAreas, key: propertyOf<ref_Messages>("BroadcastArea") },
            { vertex: ref_Media, key: propertyOf<ref_Messages>("Media") },
            { vertex: ref_Supports, key: propertyOf<ref_Messages>("Support") },
            {
                vertex: lnk_AdvertisingCompanySupport, params: {
                    Roles: financialRid,
                    properties: ["in", "in.Name as Name", "in.Active as Active", "Default", "Roles"]
                },
                label: "AdvCompany_Fin",
                propName: "in",
                key: propertyOf<ref_Messages>("AdvCompany_Fin")
            },
            {
                vertex: lnk_AdvertisingCompanySupport, params: {
                    Roles: commercialRid,
                    properties: ["in", "in.Name as Name", "in.Active as Active", "Default", "Roles"]
                },
                label: "AdvCompany_Com",
                propName: "in",
                key: propertyOf<ref_Messages>("AdvCompany_Com")
            },
            {
                vertex: ref_Currencies,
                params: {
                    properties: ["Name", "Code", "@class"]
                },
                key: propertyOf<ref_Messages>("Currency")
            },
            { vertex: ref_Visuals, key: "ModelProperties.AdCreation" },
        ];

        const templateStored = SchedulerStorage.get();
        try {
            // Handle legacy labels
            // Conversion from propertyBuilder to indicateurBuilder
            if (templateStored) {
                let indicateurs: IndicateurOption[] = null;
                const convertToIndicateurBuilder = async (builders: ref_SchedulerConfigurations['LabelBuild']) => {
                    if (!builders?.some(b => b.Type == "property")) return builders;
                    if (!indicateurs) indicateurs = await IndicateursProvider.GetInstance().Provide();
                    return builders.map(b => {
                        if (b.Type == "property") {
                            const indicateur = indicateurs.find(i => i.indicateur.field == b.Value && !i.indicateur.options);
                            if (indicateur) return indicateurToOptions(indicateur.indicateur);
                        }
                        return b;
                    });
                }

                if (templateStored.StartLabel) templateStored.StartLabel = (await convertToIndicateurBuilder([templateStored.StartLabel]))[0];
                if (templateStored.EndLabel) templateStored.EndLabel = (await convertToIndicateurBuilder([templateStored.EndLabel]))[0];
                templateStored.LabelBuild = await convertToIndicateurBuilder(templateStored?.LabelBuild);
                templateStored.LabelBuildTop = await convertToIndicateurBuilder(templateStored?.LabelBuildTop);
                templateStored.LabelBuildBottom = await convertToIndicateurBuilder(templateStored?.LabelBuildBottom);
            }
        } catch (error) {
            console.error(error);
        }

        this.setState({
            template: templateStored,
            vertecesToMap: advStyles
        });
    }

    render() {
        const { template } = this.state;

        const validate = async () => {
            this.forceUpdate();
            const { onChange } = this.props;
            SchedulerStorage.set(template);
            onChange?.(template);
        }

        return (<>
            {template &&
                <>
                    {/** label builds */}
                    <LabelLinesCreator template={template}
                        lines={this.state.labelBuilders}
                        propertyConfigs={this.state?.vertecesToMap}
                        validate={(temp) => this.setState({ template: temp }, () => validate?.())}
                        onChange={(labels, startLabel, endLabel, barProperty) => {
                            template.LabelBuildTop = labels["LabelBuildTop"];
                            template.LabelBuild = labels["LabelBuild"];
                            template.LabelBuildBottom = labels["LabelBuildBottom"];
                            template.StartLabel = startLabel;
                            template.EndLabel = endLabel;
                            template.BarProperty = barProperty;
                            validate?.();
                        }} />

                    {/** personnalisation avancée */}
                    <AdvancedStyles
                        key={`AdvancedStyles_${template.BarProperty}`}
                        validate={(temp) => this.setState({ template: temp }, () => validate?.())}
                        onResizeValue={() => this.props.onResizeValue?.()}
                        template={template}
                        vertecesToMap={this.generateAdvancedStyles(template.BarProperty)}
                    />
                </>
            }
        </>)
    }
}

type AdvancedStylesArg = {
    validate: (template: ref_SchedulerConfigurations) => any,
    onResizeValue?: () => void,
    vertecesToMap: {
        vertex: new () => any;
        params?: any;
        label?: string;
        propName?: string;
        key: any;
    }[],
    template: ref_SchedulerConfigurations
}

function AdvancedStyles({ validate, vertecesToMap, template, onResizeValue }: AdvancedStylesArg) {

    const [selectedTab, setSelectedTab] = useState(0);
    const [resizePeriod, setResizePeriod] = useState(SchedulerStorage.get()?.resizePeriod ?? false);
    const data = useSelector((root: RootState) => root.gridMessages.data);

    const map = new Map<string, Set<any>>();
    vertecesToMap.forEach(v => {
        const hashes = new Set();
        map.set(v.key, hashes);
        data?.forEach(d => {
            const value = GetSubElement(d, v.key);
            if (value) hashes.add(value)
        });
    });

    const generateTabLabel = (label: string, vertexName: string, key: string) => {

        const tabSet = map.get(key);
        const tabSetHasHasTemplate = tabSet?.size > 0 && Array.from(tabSet).some(e => Boolean(template?.Style?.[e]));

        return <div className='badge-template'>
            {tabSetHasHasTemplate && <div className='badge-template-icon'><CustomBadge /></div>}
            <span>{label}</span>
        </div>;
    }
    React.useEffect(() => {
        const schedulerConfig = SchedulerStorage.get()
        const schedulerconfigUpdated = { ...schedulerConfig, resizePeriod }
        SchedulerStorage.set(schedulerconfigUpdated);
    }, [resizePeriod])

    return <>
        {template &&
            <>
                <TabStrip selected={selectedTab} className="advancedStyles" scrollable={true} onSelect={e => setSelectedTab(e.selected)}>
                    {vertecesToMap.map((e, i) =>
                        <TabStripTab
                            key={`TabStripTab-${i}`}
                            title={generateTabLabel((e.label && TradProp(e.label)) ?? Trad(e.vertex.name), e.vertex.name, e.key)}
                            {...generateTab(i)}>
                            <VertecesStyleComponent
                                vertex={e.vertex}
                                propName={e.propName}
                                params={e.params ?? {}}
                                propKey={e.key}
                                template={template}
                                onChange={() => validate(template)} />
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={resizePeriod}
                                        onChange={() => { setResizePeriod(!resizePeriod); onResizeValue(); }}
                                        name="subTotal"
                                        color="primary" />
                                }
                                label={Trad("resize_active_period")} />
                        </TabStripTab>)}
                </TabStrip>
            </>
        }
    </>
}

const generateTab = (index: any) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}