
import { IRid } from "./IRid.bin";
import { rid } from "./orientdb/CommonTypes.bin";

class LogBase {
    Date: Date;
    Layer: any;
    Report: any
}
export class Log extends LogBase implements IRid {
    "@rid"?: rid;
    User: string;
    CallID: any;
    checked?: boolean;
}