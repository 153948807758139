import { Referentials } from "./orientdb/Referentials.bin";
import { ref_BroadcastAreasId } from "./orientdb/ref_BroadcastAreas.bin";
import { ref_CurrenciesId } from "./orientdb/ref_Currencies.bin";
import { ref_MediaId } from "./orientdb/ref_Media.bin";
import { ref_ModelId } from "./orientdb/ref_Model.bin";
import { ref_SupportsId } from "./orientdb/ref_Supports.bin";
import { V } from "./orientdb/V.bin";
import { kpis } from "./ref_Messages.bin";

export class ref_Offers extends V implements Referentials {
    Active: boolean;
    Key: string;
    Support: ref_SupportsId;
    BroadcastArea: ref_BroadcastAreasId;
    Media: ref_MediaId;
    Model: ref_ModelId;
    Currency: ref_CurrenciesId;
    Created_at?: string;
    Updated_at?: string;
    Name: string;
    Start: Date;
    End: Date;
    ModelProperties: { [key: string]: string };
    KPIs: kpis = new kpis();
    DataImported: { [key: string]: any }[];

    Source?: "src_ADWANTED" | "src_CODIPRESSE" | "src_TSM";
}