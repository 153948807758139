import * as React from "react";
import { TradClassName } from "trad-lib";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { getIcon } from "adwone-lib/index";
import { ref_Messages } from "hub-lib/models/ref_Messages.bin";
import { ref_Campaigns } from "hub-lib/models/ref_Campaigns.bin";
import { ref_Agreements } from "hub-lib/models/ref_Agreements.bin";
import { useEffect, useState } from "react";
import { Client } from "hub-lib/client/client.bin";
import { TooltipManager } from "../../../CustomTooltip";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { propertyOf } from "hub-lib/tools.bin";

type BadgesUsageProps = { vertexName: string, value: string };
export function BagesUsage({ vertexName, value }: BadgesUsageProps) {
    return <div className="usage-icons">
        <BadgeUsage
            usageRef={ref_Agreements.name}
            icon="agreements"
            usedElement={vertexName}
            value={value} />
        {vertexName != ref_Supports.name &&
            <BadgeUsage
                usageRef={ref_Campaigns.name}
                icon="campaigns"
                usedElement={vertexName}
                value={value} />}
        {vertexName == ref_Supports.name &&
            <BadgeUsage
                usageRef={ref_Messages.name}
                icon="campaigns"
                usedElement={vertexName}
                value={value}
                distinct={propertyOf<ref_Messages>("Campaign")}
                tooltipClass={ref_Campaigns.name} />}
        <BadgeUsage
            usageRef={ref_Messages.name}
            icon="messages"
            usedElement={vertexName}
            value={value} />
    </div>
}

type CustomBadgeArgs = { count: number, icon: string, tradClassKey: string }
export function CustomBadge({ count, icon, tradClassKey }: CustomBadgeArgs) {
    return <div className="custombadge" onMouseOver={(e) => count && TooltipManager.Push({ target: e.target, text: `${count} ${TradClassName(tradClassKey)}` })} >
        <BadgeContainer>
            {/* <span className="k-icon k-i-facebook-box social" /> */}
            {getIcon(icon)}
            {count > 0 && <Badge>{count > 99 ? "99+" : count}</Badge>}
            {count === 0 && <Badge className="empty-badge">0</Badge>}
        </BadgeContainer>
    </div>
}

type BadgeUsageProps = { icon: string, usageRef: string, usedElement: string, value: string, distinct?: string, tooltipClass?: string };
export function BadgeUsage({ icon, usageRef, value, usedElement, distinct, tooltipClass }: BadgeUsageProps) {

    const [count, setCount] = useState(0);

    useEffect(() => {
        Promise.resolve().then(async () => {
            const usage = await Client.countVertex(usageRef, { filter : [{ class: usedElement, value}], distinct });
            setCount(usage.data.results ?? 0)
        });
    })

    return <CustomBadge count={count} icon={icon} tradClassKey={tooltipClass ?? usageRef} />
}