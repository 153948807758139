import { ReturnCurrencyProvider } from "hub-lib/business/ReturnCurrencyProvider.bin";
import { Client } from "hub-lib/client/client.bin";
import { lnk_ChangeRate } from "hub-lib/models/lnk_ChangeRate.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { Trad as TradCode, TradClassName, TradProvider } from "trad-lib";
import { Trad } from "hub-lib/models/custom/Trad.bin";
import { IsDebugMode } from "./utils/localstorage.bin";
import { Notify, NotifyError } from "./utils/Notify.bin";

let base = Date.prototype.toJSON;

Date.prototype.toJSON = function (...args) {

    let date = new Date(this);
    date.setHours(0, 0, 0, 0);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    let copy = new Date(date.getTime() - userTimezoneOffset);

    return base.apply(copy, args);
}

ReturnCurrencyProvider.ChangeRateProvider = async () => (await Client.searchVertex(lnk_ChangeRate.name)).data.results;
ReturnCurrencyProvider.HasCurrencyProvider = async () => (await Client.searchVertex(lnk_HasCurrency.name, { AllCompanies: true })).data.results;


type StorageTrads = {
    expires: string;
    Trads: Trad[];
};

export let keyStoreTrads = 'all-trads';

function GetStorageTrad() {

    let stored = localStorage.getItem(keyStoreTrads);
    if (stored) {
        let storageTrads: StorageTrads = <StorageTrads>JSON.parse(stored);
        if (storageTrads && !IsDebugMode()) {
            let expiration = new Date(storageTrads.expires);
            if (new Date().getTime() < expiration.getTime()) {
                return storageTrads.Trads;
            }
        }
    }
}

function SetStorageTrad(trads: Trad[]) {
    let expires = new Date();
    expires.setMinutes(expires.getMinutes() + 10);

    let storageTrads: StorageTrads = {
        expires: expires?.toString(),
        Trads: trads
    }

    localStorage.setItem(keyStoreTrads, JSON.stringify(storageTrads));
}

function RemoveTrads() {
    localStorage.removeItem(keyStoreTrads);
}

let flushTrad = (url: string) => { if (url.includes(`/${Trad.name}`)) RemoveTrads() };
Client.onRequestDone.on('POST', flushTrad);
Client.onRequestDone.on('PUT', flushTrad);
Client.onRequestDone.on('DELETE', flushTrad);


Client.onCreationVertex.addListener("successful", (arg) => {
    let baseMsg = TradCode('creation_success');

    baseMsg += ".\n";
    baseMsg += TradClassName(arg?.vertex);

    if (arg?.res && arg?.res?.length === 1 || arg?.res?.Name) {
        let name = arg?.res?.Name || arg.res[0]?.Name;
        if (name) {
            baseMsg += `: ${name}`;
        }
    }

    Notify(baseMsg, "success");
})

Client.onCreationVertex.addListener("fail", (arg) => {
    NotifyError({ ...arg, ...arg.res });
})

Client.onReadVertex.addListener("fail", (arg) => {
    NotifyError({ ...arg, ...arg.res });
})

Client.onErrorEvent.addListener("fail", (arg) => {
    NotifyError({ ...arg, ...arg.error });
})

Client.onPersistVertex.addListener("successful", (arg) => {
    let baseMsg = TradCode('persist_success');

    if (arg?.res?.length) {
        baseMsg += `: ${arg.res.length}`;
    }

    Notify(baseMsg, "success");
})

Client.onPersistVertex.addListener("fail", (arg) => {
    NotifyError({ ...arg, ...arg.res });
})

Client.onUpdateVertex.addListener("successful", (arg) => {
    let baseMsg = TradCode('update_success');

    baseMsg += ".\n";
    baseMsg += TradClassName(arg?.vertex);
    if (arg?.params && arg?.params?.length > 0 || arg?.params?.Name) {
        let name = arg?.params?.Name || arg.params[0]?.Name;
        if (name) {
            baseMsg += `: ${name}`;
        }
    }

    Notify(baseMsg, "success");
})
Client.onUpdateVertex.addListener("fail", (arg) => {
    NotifyError(arg.params);
})
Client.onDeleteVertex.addListener("successful", (arg) => {
    let baseMsg = TradCode('delete_success');

    baseMsg += ".\n";
    baseMsg += TradClassName(arg?.vertex);

    Notify(baseMsg, "success");
})
Client.onDeleteVertex.addListener("fail", (arg) => {
    NotifyError(arg.res);
})
TradProvider.TradGetter = async () => {
    let trads: Trad[] = undefined;

    let stored: Trad[] = GetStorageTrad();
    if (stored)
        return stored;

    trads = (await Client.searchVertex(Trad.name)).data.results;

    SetStorageTrad(trads);

    return trads;
}