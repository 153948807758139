
import * as React from 'react'

import { IConfigurableComponent } from "./ConfigurableComponent.bin";
import { ACrossedTable } from "../crossedTable/CrossedTable.bin";
import { CrossedTableConf, TConfState, CrossedTableConfig } from "../crossedTable/CrossedTableConf.bin";
import { Client } from 'hub-lib/client/client.bin';
import { UpdateFromServer } from '../crossedTable/DataGetter.bin';
import { CrossedTableTelerikTree } from '../crossedTable/CrossedTableTelerikTree.bin';

import { Trad } from 'trad-lib';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';

let count = 0;

export class CrossedTableConfigurable implements IConfigurableComponent {

    onConfChanged: () => void;
    private configuration: TConfState;
    private ctComponent: ACrossedTable<any, any>;

    /**
     *
     * @param conf
     * @param rid Pas au bon endroit
     */
    constructor(conf: TConfState, rid: string) {
        this.configuration = conf;
    }

    Update = async (config: CrossedTableConfig) => {
        this.ctComponent.Loading();
        let table = await UpdateFromServer(config);
        this.ctComponent.Load(table, config);
        return table;
    }

    getMainComponent = () => {
        let { crossedTable } = this.configuration;
        return (
            <div>
                {<CrossedTableTelerikTree
                    onRef={(ctComponent) => {
                        this.ctComponent = ctComponent;
                        this.Update(crossedTable)
                    }} />}
            </div>
        )
    }

    getConfComponent = () => {
        return <CrossedTableConf
            key={`CrossedTableConfKey-${count++}`}
            state={this.configuration}
            onConfChanged={(conf) => {
                this.Update(conf.crossedTable);
                this.onConfChanged?.();
                //SetLocalStorageFilters(this.getFilters(conf.crossedTable))
            }} />
    };

    static getAggregatedArgsForExports = (config: CrossedTableConfig) => {
        const document = CrossedTableConfig.ToExternalConfig(config);
        document.splitTab = {
            field: "Media",
            type: "@rid",
            linkedClass: ref_Media.name
        }
        return document;
    }

    ExportCsv = () => {
        Client.downloadExport("csv", CrossedTableConfigurable.getAggregatedArgsForExports(this.configuration.crossedTable), Trad("results"));
    }

    ExportFormated = () => {
        Client.downloadExport("formated", CrossedTableConfigurable.getAggregatedArgsForExports(this.configuration.crossedTable), Trad("results"));
    }




}

