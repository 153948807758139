import * as React from 'react'
import { TooltipManager } from '../../CustomTooltip';
import { Switch } from '@progress/kendo-react-inputs';
import { RowSize, styleGridContainer } from '../../../styles/theme';
import { Grid } from '@material-ui/core';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import { IRid } from 'hub-lib/models/IRid.bin';
import { CustomTreeList } from '../../VertexGrid/Generic/CustomTreeList';
import { TreeListRefNode } from '../../VertexGrid/Adwone-admin/Referential/ReferentialTreeList';
import { TreeListCellProps, TreeListColumnProps, TreeListTextFilter } from '@progress/kendo-react-treelist';
import { Trad } from 'trad-lib';
import { GetFlatElements } from 'hub-lib/tools.bin';
import Loader from '../Loader';

export type ListItemContext = {
    label: string;
    Rights: any;
    data: IRid[];
    allRights: any;
    allProperty: string;
    lnkProperties: string[];
    parentRid?: string;
    enabledProps?: any;
    readOnlyItemIds?: string[];
}

export const refAllItem = (itemRights: ListItemContext, onChange?: (event, data?) => void) => {
    const fakeItem = itemRights.allRights?.["@rid"] == "#-1:-1";

    return <div key={`${itemRights.label}_${Date.now()}`}>
        <div style={{ float: 'left', fontWeight: 'bold', display: "flex", alignItems: "center", height: RowSize }}>
            <span>{itemRights.label}</span>
        </div>
        <div style={{ float: 'right', display: "flex", alignItems: "center", height: RowSize }}>
            {!fakeItem &&
                <Switch
                    size='small'
                    checked={itemRights.allRights?.[itemRights.allProperty]}
                    onChange={(event) => {
                        itemRights.allRights[itemRights.allProperty] = !itemRights.allRights[itemRights.allProperty]
                        //Tous les liens du contexte sont supprimés.
                        //Dans le cas des annonceurs, les annonceurs du groupe seulement sont supprimés
                        itemRights.lnkProperties.forEach(prop => {
                            delete itemRights.Rights[prop];
                        });

                        if (onChange)
                            onChange(event);
                    }}
                />
            }
        </div>
    </div>
}

export const refListItem = (props, itemRights: ListItemContext, onChange?: (event, data?) => void) => {
    const { dataItem, ...others } = props;
    const fakeItem = dataItem["@rid"] == "#-1:-1";
    return (
        <li {...others} className={`k-item ${(itemRights?.parentRid == dataItem["@rid"]) ? "is-selected" : ""}`}>
            <div style={{ width: "100%" }}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: dataItem.Name })}
                className={`clearfix`}
                key={"moda" + dataItem["@rid"]}>
                <div className='listitem-label-rights'>
                    <span>{dataItem.Name}</span>
                </div>
                <div style={{ float: "right", display: "flex", alignItems: "center", height: RowSize }}>
                    {!fakeItem &&
                        <Switch
                            size='small'
                            disabled={itemRights.readOnlyItemIds?.includes(dataItem["@rid"])}
                            checked={itemRights.allRights?.[itemRights.allProperty]
                                || itemRights.Rights?.[itemRights.lnkProperties[0]]?.some((e: any) => e["@rid"] == dataItem["@rid"])
                                || itemRights.readOnlyItemIds?.includes(dataItem["@rid"])}
                            onChange={(event) => {
                                if (!itemRights.Rights?.[itemRights.lnkProperties[0]])
                                    itemRights.Rights[itemRights.lnkProperties[0]] = [];
                                if (!event.target.value) {
                                    if (itemRights.allRights[itemRights.allProperty]) {
                                        itemRights.Rights[itemRights.lnkProperties[0]] = itemRights.data.filter((e: any) => e["@rid"] !== dataItem["@rid"]).map(d =>
                                            ({ "@rid": d["@rid"], ...itemRights?.enabledProps }));
                                        itemRights.allRights[itemRights.allProperty] = false;
                                    }
                                    else
                                        itemRights.Rights[itemRights.lnkProperties[0]] = itemRights.Rights[itemRights.lnkProperties[0]].filter((e: any) => e["@rid"] !== dataItem["@rid"])
                                } else {
                                    itemRights.Rights[itemRights.lnkProperties[0]].push({ "@rid": dataItem["@rid"], ...itemRights?.enabledProps })
                                    if (itemRights.data.length == itemRights.Rights[itemRights.lnkProperties[0]].length) {
                                        itemRights.allRights[itemRights.allProperty] = true;
                                        itemRights.lnkProperties.forEach(prop => {
                                            delete itemRights.Rights[prop];
                                        });
                                    }
                                }
                                if (onChange)
                                    onChange(event, dataItem);
                            }}
                        />
                    }
                </div>
            </div>
        </li>
    );
};

type ListBoxRightsArgs = { context: ListItemContext, className?: string, onItemClick?: (event: ListBoxItemClickEvent) => void, onSwitchChange?: (event, data?) => void };

export function ListBoxRights({ context, className, onItemClick, onSwitchChange }: ListBoxRightsArgs) {
    return <>
        {context.Rights && context.allRights &&
            <Grid item xs={6} className={className ? className : "message_details_leftcombo"}
                style={{ ...styleGridContainer.rights, overflow: 'hidden' }}>
                {refAllItem(context, onSwitchChange)}
                <ListBox data={context.data ?? []}
                    className='listbox-rights'
                    style={styleGridContainer.listboxRights}
                    onItemClick={onItemClick}
                    item={(props) => refListItem(props, context, onSwitchChange)}
                    textField="Label" />
            </Grid>}
    </>
}

type TreeRightsState = {
    data: TreeListRefNode[],
    columns: TreeListColumnProps[]
}

export function TreeRights({ context, className, onItemClick, onSwitchChange }: ListBoxRightsArgs) {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [state, setState] = React.useState<TreeRightsState>(null);
    const { data, columns } = state ?? {};

    const updateTree = (dataItem: TreeListRefNode, checked: boolean) => {
        if (checked) {
            if (!context.Rights?.[context.lnkProperties[0]])
                context.Rights[context.lnkProperties[0]] = [];
            context.Rights[context.lnkProperties[0]].push({ "@rid": dataItem["@rid"], ...context?.enabledProps })
        }
        else if (context.Rights?.[context.lnkProperties[0]])
            context.Rights[context.lnkProperties[0]] = context.Rights[context.lnkProperties[0]].filter((e: any) => e["@rid"] !== dataItem["@rid"]);
        dataItem["Activated"] = checked && !dataItem["Disabled"];
        if (dataItem.Children)
            for (const child of dataItem.Children)
                updateTree(child, checked);
    };

    const createNode = (d: any, level: number = 0, parent?: TreeListRefNode): TreeListRefNode => {
        let node: TreeListRefNode = new TreeListRefNode();
        node = {
            id: d["@rid"] ?? "#-1:-1",
            ...d
        };
        node["lnkCurrencies"] = node?.["lnkCurrencies"]?.length ? node["lnkCurrencies"] : parent?.["lnkCurrencies"];
        const outOfPerimeter = (context.enabledProps?.["Currency"] && !node?.["lnkCurrencies"]?.some(l => l.out == context.enabledProps["Currency"]))
            || (context.enabledProps?.["Customer"] && !context.enabledProps["Customer"].includes(node["@rid"]));

        node["Disabled"] = outOfPerimeter || context.allRights?.[context.allProperty];
        node["Activated"] = !outOfPerimeter && (context.allRights?.[context.allProperty]
            || context.Rights?.[context.lnkProperties[0]]?.some((e: any) => e["@rid"] == d["@rid"]));
        if (d["children"])
            node.Children = d["children"].map(i => createNode(i, level + 1, node));
        return node;
    }

    React.useEffect(() => {
        if (!state) {
            const activeCell = ({ dataItem, onChange }: TreeListCellProps) =>
                <td>
                    <div style={{ float: "right", display: "flex", alignItems: "center", height: RowSize }}>
                        <Switch
                            size='small'
                            disabled={dataItem?.["Disabled"]}
                            checked={dataItem?.['Activated']}
                            onChange={(event) => {
                                dataItem['Activated'] = event.target.value
                                onChange({ dataItem, level: [], syntheticEvent: null })
                            }} />
                    </div>
                </td>
            setState({
                data: context.data.map((el) => createNode(el, 0)),
                columns: [{
                    field: "Name",
                    width: "100%",
                    title: Trad("Name"),
                    filter: TreeListTextFilter,
                    expandable: true,
                    resizable: true
                },
                {
                    field: "lnkCurrencies",
                    width: 70,
                    title: Trad("currency"),
                    cell: (cellProps) => {
                        const cell = (props: any) => {
                            const currency = props.dataItem?.["lnkCurrencies"]?.[0]?.["outName"]
                            return <td {...props} style={{ display: "contents" }}>{currency}</td>;
                        }
                        return <td>{cell(cellProps)}</td>
                    },
                    resizable: true
                },
                {
                    field: "Activated",
                    width: 100,
                    cell: activeCell
                }]
            });
        }
    })
    console.log(context.Rights);
    return <>
        {context.Rights && context.allRights &&
            <Grid item xs={6} className={className ? className : "message_details_leftcombo"}
                style={{ ...styleGridContainer.rights, overflow: 'hidden' }}>
                {refAllItem(context, (e, data) => {
                    context.Rights[context.lnkProperties[0]] = undefined;
                    setState(null);
                    forceUpdate();
                    if (onSwitchChange)
                        onSwitchChange(e, data);
                })}
                <div style={styleGridContainer.treeListRights}>
                    {!state && <Loader />}
                    {state && <CustomTreeList
                        data={data}
                        expandField="Expanded"
                        subItemsField="Children"
                        columns={columns}
                        gridProps={{
                            onItemChange: (e) => {
                                const item = GetFlatElements(data, 'Children')?.find(d => d.id == e.dataItem.id);
                                if (!item) {
                                    console.log(`item not found`, e.dataItem, data);
                                    return;
                                }
                                updateTree(item, e.dataItem['Activated']);
                                forceUpdate();
                                if (onSwitchChange)
                                    onSwitchChange(e, data);
                            }
                        }}
                    />}
                </div>
            </Grid>}
    </>
}