import { Referentials, ReferentialsId } from "./Referentials.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ProfilesId = rid;

export class ref_Profiles extends V implements Referentials {
	"@rid"?: ref_ProfilesId;
	Name: string;
	Active: boolean;

}