import { OUserId } from "./OUser.bin";
import { V } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export class ref_ConfigurationsBase extends V {
	"@rid"?: rid;
	DocumentType?: string;
	Active: boolean;
	Default?: boolean;
	Name: string;
	Owner: OUserId;
	Table: string;
}