import * as React from "react"
import Grid from '@material-ui/core/Grid'
import { Trad, } from 'trad-lib';
import { TextField } from '@material-ui/core';
import { TextArea } from '@progress/kendo-react-inputs';
import { GetNumberFormatCustom } from './NumberFormatCustom.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../../redux/store';
import { setMessageSync } from '../../../redux/messageEditorSlice';
import { PlacementEditor } from './MessageEditor/PlacementEditor';
import { ContainerComponent } from "../Generic/ContainerComponent";
import { TextEditor } from "../TextEditor.bin";

export function DiffusionPicker() {

    return <Grid container>
        <Grid item xs={6} className="message_details_leftcombo">
            <PlacementEditor broadcastPlacement />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <BroadcastGrossPicker />
        </Grid>
        <Grid item xs={12} className='block-container'>
            <BroadcastCommentsPicker />
        </Grid>
    </Grid>
}

function BroadcastCommentsPicker() {

    const BroadcastComments = useSelector((root: RootState) => root.messageEditor.getMessage().ModelProperties?.BroadcastComments);
    const dispatch = useDispatch();

    return <ContainerComponent title={Trad('broadcasted_comment')} className="auto-height no-padding no-margin">
        <TextEditor
            content={BroadcastComments}
            onChange={value => {
                const msg = store.getState().messageEditor.getMessage();
                msg.ModelProperties.BroadcastComments = value;
                dispatch(setMessageSync(msg))
            }} />
    </ContainerComponent>
}

function BroadcastGrossPicker() {

    const currencyCode = useSelector((root: RootState) => root.messageEditor.currencyCode);
    const BroadcastGross = useSelector((root: RootState) => root.messageEditor.getMessage().KPIs.BroadcastGross);
    const dispatch = useDispatch();

    return <TextField
        style={{ width: '100%' }}
        label={`${Trad('broadcasted_gross')} ${currencyCode}`}
        type={'Number'}
        value={BroadcastGross}
        variant="outlined"
        InputProps={{ inputComponent: GetNumberFormatCustom(eKPIType.Decimal) as any }}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
            const msg = store.getState().messageEditor.getMessage();
            const value = Number(e.target.value);
            msg.KPIs.BroadcastGross = value;
            dispatch(setMessageSync(msg));
        }}
    />;
}