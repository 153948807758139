// import { TooltipManager } from "./src"

export * from "./adw-grid/index"
export * from "./components/index"

// export type ConfigureArgs = {
//     tooltipProvider: ({ target, text }) => void
// }

// export function Configure({ tooltipProvider }: ConfigureArgs) {
//     TooltipManager.Push = tooltipProvider;
// }