

import * as React from "react";
import {
    TreeList,
    mapTree,
    extendDataItem,
    TreeListColumnProps,
    getSelectedState,
    TreeListSelectionCell,
    TreeListHeaderSelectionCell,
    TreeListCellProps,
    TreeListColumnResizeEvent,
    TreeListProps,
    TreeListCell,
    filterBy,
    orderBy,
    TreeListFilterChangeEvent
} from "@progress/kendo-react-treelist";
import { FooterRowSize, RowSize } from "../../../styles/theme";
import { clone, duplicate, GetFlatElements, GetHashCode, propertyOf } from "hub-lib/tools.bin";
import { TooltipManager } from "../../CustomTooltip";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { FooterCellTotalElement } from "./AdwTelerikGrid.bin";

type CustomTreeListArgs<T extends { id: any, [p: string]: any }> = {
    expandField?: (keyof T) & string,
    subItemsField: (keyof T) & string,
    selectedField?: (keyof T) & string,
    editField?: (keyof T) & string,
    selectable?: boolean,
    columns: TreeListColumnProps[],
    isSelectable?: (row: T) => boolean;
    onSelectionChanged?: (selectedElements: T[]) => void;
    onExpandedChanged?: (expandedElements: string[]) => void;
    filter?: FilterDescriptor[];
    onFilterChange?: (filters: FilterDescriptor[]) => void;
    data: T[],
    gridProps?: TreeListProps,
    selectedIds?: T["id"][],
    inEdit?: T[],
    rowHeight?: number,
}

export function CustomTreeList<T extends { id: any, [p: string]: any }>({ editField, filter, inEdit, rowHeight, selectedIds, expandField, subItemsField, columns, data, selectedField, selectable, onSelectionChanged, onExpandedChanged, onFilterChange, isSelectable, gridProps }: CustomTreeListArgs<T>) {

    const [selectedState, setSelectedState] = React.useState({});
    const [TreeFilter, setTreeFilter] = React.useState<FilterDescriptor[]>(undefined);


    const notifySelectionChanged = (_selectedState) => {
        onSelectionChanged?.(GetFlatElements(data, subItemsField).filter(item => _selectedState[item.id]));
    }

    const headerSelectionValue = (dataState, selectedState) => {
        let allSelected = true;
        mapTree(dataState, subItemsField, (item) => {
            allSelected = allSelected && selectedState[item.id];
            return item;
        });
        return allSelected;
    };

    const addExpandField = (dataTree) => {
        const expanded = state.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [selectedField]: selectedState[item.id],
                [expandField]: expanded.includes(item.id),
                [editField]: Boolean(inEdit?.find((i) => i.id === item.id)),
            })
        );
    };

    const processData = () => {
        const _data = state.data;
        // let filteredData = filterBy(data, dataState.filter, subItemsField);

        const mergeFilters = [...(TreeFilter ?? []), ...(filter ?? [])];

        const copy = mergeFilters?.map(t => {
            if (t?.field && _data?.some?.(d => d?.hasOwnProperty(`${t.field}_str`))) {
                const treeFilterCopy = duplicate(t);
                treeFilterCopy.field += "_str";
                return treeFilterCopy;
            }
            return t;
        });

        const filteredData = mergeFilters ? filterBy(_data, copy, subItemsField) : _data;
        const sortedData = orderBy(filteredData, state.dataState.sort, subItemsField);
        return addExpandField(sortedData);
    };

    const addTooltipCell = (col: TreeListColumnProps) => {
        if (!col.cell)
            col.cell = TooltipCell;
        return col;
    }

    const treeColumns: TreeListColumnProps[] = [
        ...(selectable ? [{
            locked: true,
            field: selectedField ?? "Selected",
            width: "41px",
            // headerSelectionValue: headerSelectionValue(data, selectedState),
            cell: CustomCellSelection({ isSelectable }),
            headerCell: TreeListHeaderSelectionCell,
        },] : []),
        ...columns].map(addTooltipCell)

    const flatElements = GetFlatElements(data, subItemsField);
    const getExpandedElements = () => flatElements
        .filter(e => e[expandField])
        .map(e => e.id);

    const [height, setHeight] = React.useState(null);
    const [state, setState] = React.useState({
        data: data,
        dataState: {
            sort: [],
            filter: [],
        },
        columns: treeColumns,
        selected: [],
        expanded: getExpandedElements(),
    });

    const finalData = processData();

    const table: any = React.useRef();
    const container: any = React.useRef();

    React.useEffect(() => {
        const checkHeight = () => {
            if (container?.current) {
                const offsetHeight = container.current.offsetHeight;
                if (offsetHeight != height)
                    setHeight(offsetHeight);
            }
        }

        window.addEventListener("resize", checkHeight)
        checkHeight();

        const stateCols = duplicate(state.columns);
        const newCols = duplicate(treeColumns);
        stateCols.forEach(c => { delete c.width; delete c.headerSelectionValue; });
        newCols.forEach(c => { delete c.width; delete c.headerSelectionValue; });
        const colsChanged = GetHashCode(stateCols) != GetHashCode(newCols);
        if (state.data != data || colsChanged) {
            const _state: typeof state = { ...state };
            if (colsChanged)
                _state.columns = treeColumns;
            _state.data = data;
            _state.expanded = getExpandedElements();
            if (colsChanged && TreeFilter) {
                setTreeFilter(undefined);
            }
            setState(_state);
        }

        if (selectedIds) {
            selectedIds = Array.from(new Set(selectedIds));

            // Si des IDs de sélections sont passés en props alors on compare avec les IDs selectionnés dans le state.
            // S'il y a une diff alors on met à jour le state en fonction des props.
            const stateSelectedIds = Object.entries(selectedState)
                .filter(([k, v]) => v)
                .map(([k, v]) => k);

            if (stateSelectedIds.length != selectedIds.length) {
                setSelectedState(selectedIds.reduce((a, b) => ({ ...a, [b]: true }), {}));
            }
        }

        return () => window.removeEventListener("resize", checkHeight)
    })

    const onExpandChange = (e) => {
        const expanded = e.value
            ? state.expanded.filter((id) => id !== e.dataItem.id)
            : [...state.expanded, e.dataItem.id]
        if (onExpandedChanged) onExpandedChanged(expanded)
        setState({
            ...state,
            expanded,
        });
    };

    const handleDataStateChange = (event) => {
        setState({ ...state, dataState: event.dataState });
    };

    const onColumnResize = (event: TreeListColumnResizeEvent) => {
        state.columns[event.index].width = event.newWidth + "px";
        if (event.end)
            setState({ ...state, columns: [...state.columns] });
    };

    const computeWidth = (cols: TreeListColumnProps[]) => {
        let width = 0;
        cols?.forEach(c => {
            switch (typeof c.width) {
                case "number":
                    width += c.width;
                    break;
                case "string":
                    width += Number(c.width.split("px")[0])
                    break;
                default:
                    break;
            }
        });
        return width + "px";
    }

    const onSelectionChange = React.useCallback(
        (event) => {
            if (!(selectable || gridProps?.selectable)) return;
            // const newSelectedState = getSelectedState({
            //     event,
            //     selectedState: selectedState,
            //     dataItemKey: propertyOf<T>("id"),
            // });

            if (!event.dataItem) return;

            const newSelectedState = clone(selectedState);
            const checked = event.syntheticEvent?.target?.checked;

            if (!checked) delete newSelectedState[event.dataItem.id];
            else newSelectedState[event.dataItem.id] = checked;

            setSelectedState(newSelectedState);
            notifySelectionChanged(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[item.id] = checked;
        });
        setSelectedState(newSelectedState);
        notifySelectionChanged(newSelectedState);
    }, []);

    if (selectable)
        state.columns[0].headerSelectionValue = headerSelectionValue(finalData, selectedState);

    const className = "custom-tree-list " + (selectable ? "custom-tree-list-selectable" : "");

    const style: React.CSSProperties = {};
    if (state.columns.every(c => c.width)) {
        // width: computeWidth(state.columns),
        style.width = computeWidth(state.columns);
        style.minWidth = computeWidth(state.columns);
    }

    return (
        <div style={{ height: "100%", position: 'relative' }}>
            <div style={{ height: `calc(100% - ${FooterRowSize}px)` }} ref={container}>
                <TreeList
                    ref={table}
                    tableProps={{
                        ref: table,
                        style
                    }}
                    scrollable="virtual"
                    resizable
                    className={className}
                    rowHeight={rowHeight ?? RowSize}
                    style={{
                        overflow: "auto",
                        height
                    }}
                    expandField={expandField}
                    subItemsField={subItemsField}
                    editField={editField}
                    onExpandChange={onExpandChange}
                    sortable={{
                        mode: "multiple",
                    }}
                    {...state.dataState}
                    data={finalData}
                    onDataStateChange={handleDataStateChange}
                    columns={state.columns}
                    onColumnResize={onColumnResize}

                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onFilterChange={(event) => {
                        setTreeFilter(event.filter);
                        onFilterChange?.(event.filter);
                    }}
                    selectedField={selectedField}
                    selectable={{
                        enabled: selectable,
                        mode: "multiple",
                    }}
                    filter={TreeFilter}
                    onSelectionChange={onSelectionChange}
                    {...gridProps} />
            </div>
            <FooterCellTotalElement count={flatElements?.length ?? 0} />
        </div>
    );
}

const CustomCellSelection = ({ isSelectable }) => {
    return function (props: TreeListCellProps) {
        const { dataItem } = props;
        const className = !isSelectable ? "" : ` selectable-${Boolean(isSelectable(dataItem))}`;
        return <TreeListSelectionCell {...props} className={props.className + className} />;
    }
}

export function TooltipCell(props: TreeListCellProps & { generateText?: (item: any, row: any) => string | Promise<string>, customRender?: (defaultRendering, dataItem) => React.ReactElement<HTMLTableCellElement, string | React.JSXElementConstructor<any>> }) {
    const { dataItem, field, generateText, customRender } = props;
    const item = dataItem[field];

    return <TreeListCell {...props} render={(defaultRendering) => {
        if (typeof defaultRendering == "string")
            return defaultRendering;

        return React.cloneElement(customRender ? customRender(defaultRendering, dataItem) : defaultRendering as any, {
            onMouseOver: async (e) => {
                const target = e.target;
                const text = generateText ? await generateText(item, dataItem) : item?.toString();
                TooltipManager.Push({ target, text })
            }
        }) as any;
    }} />
}