import { ORole, ORoleId } from "./ORole.bin";
import { OIdentity, OIdentityId } from "./OIdentity.bin";
import { rid } from "./CommonTypes.bin";

export type OUserId = rid;

export class OUser extends OIdentity {
	"@rid"?: OUserId;
	name: string;
	password: string;
	roles: ORoleId[];
	status: string;

}