export let db = {
	"ref_Sortings": {
		"mandatory": [
			"Direction",
			"Property"
		],
		"optional": []
	},
	"vw_mm_HasBrand": {
		"mandatory": [],
		"optional": [
			"Referential",
			"out",
			"in"
		]
	},
	"ORectangle": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	/*"ref_Attachments": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Content",
			"Name",
			"ContentType"
		]
	},*/
	"mm_Products": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_KPIs": {
		"mandatory": [],
		"optional": [
			"Name"
		]
	},
	"ref_AdvertiserGroups": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"codip_Rubrique": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Brands": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"ref_AgencyGroups": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"tsm_Emplacement": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"codip_Format": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"lnk_AdvertisingCompanySupport": {
		"mandatory": [],
		"optional": [
			"in",
			"Roles",
			"End",
			"Start",
			"out",
			"Default"
		]
	},
	"OFunction": {
		"mandatory": [],
		"optional": [
			"parameters",
			"idempotent",
			"name",
			"code",
			"language"
		]
	},
	"lnk_Hierarchy": {
		"mandatory": [
			"Start"
		],
		"optional": [
			"End",
			"out",
			"in"
		]
	},
	"ref_Agencies": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"OSequence": {
		"mandatory": [],
		"optional": [
			"type",
			"incr",
			"name",
			"start",
			"value"
		]
	},
	"vw_one_HasUser": {
		"mandatory": [],
		"optional": [
			"out",
			"in"
		]
	},
	"ref_Companies": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"ref_AdvertisingCompanyGroups": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"codip_Emplacement": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"vw_adw_HasSupport": {
		"mandatory": [],
		"optional": [
			"in",
			"Referential",
			"out"
		]
	},
	"ref_Contacts": {
		"mandatory": [
			"Email"
		],
		"optional": [
			"Phone",
			"Job"
		]
	},
	"ref_DiscountClasses": {
		"mandatory": [
			"Active",
			"Name",
			"Operator",
			"Rank",
			"DiscountType"
		],
		"optional": [
			"Medias",
			"LastPosition"
		]
	},
	"vw_codip_HasFormat": {
		"mandatory": [],
		"optional": [
			"out",
			"in",
			"Referential"
		]
	},
	"lnk_HasBroadcastArea": {
		"mandatory": [],
		"optional": [
			"out",
			"End",
			"Start",
			"in"
		]
	},
	"OPolygon": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"OPoint": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"ref_Departments": {
		"mandatory": [
			"Active"
		],
		"optional": []
	},
	"mm_Brands": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Property": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Medias",
			"BroadcastAreas",
			"AllBroadcastAreas",
			"Name",
			"PropertyType"
		]
	},
	"vw_mm_HasUser": {
		"mandatory": [],
		"optional": [
			"out",
			"in",
			"Referential"
		]
	},
	"lnk_UserUpdate": {
		"mandatory": [
			"Date"
		],
		"optional": [
			"Log",
			"out",
			"in"
		]
	},
	"OSchedule": {
		"mandatory": [
			"name",
			"rule",
			"function"
		],
		"optional": [
			"status",
			"arguments",
			"starttime"
		]
	},
	"mm_Advertisers": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"OShape": {
		"mandatory": [],
		"optional": []
	},
	"codip_Implantation": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Campaigns": {
		"mandatory": [
			"Name",
			"Start",
			"End"
		],
		"optional": [
			"Active",
			"Brand",
			"Budget",
			"AdvertiserGroup",
			"Currency",
			"Advertiser",
			"Product"
		]
	},
	"ref_Sources": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"ref_Columns": {
		"mandatory": [
			"Id"
		],
		"optional": []
	},
	"adw_Users": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"vw_mm_HasAdvertiser": {
		"mandatory": [],
		"optional": [
			"Referential",
			"in",
			"out"
		]
	},
	"OTriggered": {
		"mandatory": [],
		"optional": []
	},
	"vw_mm_HasProduct": {
		"mandatory": [],
		"optional": [
			"in",
			"Referential",
			"out"
		]
	},
	"vw_tsm_HasEmplacement": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"ReferentialHasViews": {
		"mandatory": [],
		"optional": []
	},
	"ref_OfferModel": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Medias",
			"BroadcastAreas",
			"Name"
		]
	},
	"mm_Users": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID",
			"Type"
		]
	},
	"vw_codip_HasRubrique": {
		"mandatory": [],
		"optional": [
			"out",
			"in",
			"Referential"
		]
	},
	"OMultiPolygon": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"ORestricted": {
		"mandatory": [],
		"optional": [
			"_allowUpdate",
			"_allowDelete",
			"_allow",
			"_allowRead"
		]
	},
	"src_AdwOne": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"vw_mm_HasAdvertiserGroup": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"vw_codip_HasCouleur": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"ref_Model": {
		"mandatory": [],
		"optional": [
			"MediaTypes",
			"DocumentType",
			"Description",
			"Country"
		]
	},
	"ref_DiscountTypes": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"ref_Countries": {
		"mandatory": [
			"Name"
		],
		"optional": [
		]
	},
	"mm_AdvertiserGroups": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"vw_adw_HasAdvertiser": {
		"mandatory": [],
		"optional": [
			"in",
			"Referential",
			"out"
		]
	},
	"vw_codip_HasDiffusion": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"vw_mm_HasAdvertisingCompanies": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"vw_tsm_HasFormat": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"src_MM": {
		"mandatory": [
			"URI"
		],
		"optional": [
			"URI_Estimates",
			"URI_Financial",
			"MasterEndpoint",
			"URI_MAP"
		]
	},
	"mm_AdvertisingCompanies": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"vw_codip_HasImplantation": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"src_CODIPRESSE": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"ref_Messages": {
		"mandatory": [
			"Support",
			"Campaign",
			"Media",
			"Start",
			"End"
		],
		"optional": [
			"Active",
			"AdditionalPlacement",
			"Brand",
			"BroadcastArea",
			"Advertiser",
			"Product",
			"TechDeliveryTimeLimit",
			"Format",
			"Status",
			"AdvCompany_Com",
			"AdvCompany_Fin",
			"TechDeliveryComments",
			"CancellationTimeLimit",
			"AdditionalFormat",
			"Currency",
			"AdvertiserGroup",
			"CancellationComments",
			"Placement",
			"EstimateId",
			"State"
		]
	},
	"src_TSM": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"User": {
		"mandatory": [
			"name",
			"status",
			"password",
			"Active",
			"qualification"
		],
		"optional": [
			"roles",
			"isDefaultPassword"
		]
	},
	"codip_Couleur": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"mm_Agencies": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Currencies": {
		"mandatory": [
			"Active",
			"Name",
			"Code"
		],
		"optional": []
	},
	"E": {
		"mandatory": [],
		"optional": []
	},
	"tsm_Couleur": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Indexables": {
		"mandatory": [],
		"optional": []
	},
	"lnk_Prospect": {
		"mandatory": [
			"Start"
		],
		"optional": [
			"End",
			"in",
			"out"
		]
	},
	"codip_Diffusion": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"OMultiLineString": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"ref_Persons": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"FirstName",
			"LastName"
		]
	},
	"lnk_HasPropertyType": {
		"mandatory": [],
		"optional": [
			"in",
			"out"
		]
	},
	"src_ADWANTED": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"lnk_Mandate": {
		"mandatory": [
			"Start"
		],
		"optional": [
			"End",
			"Media",
			"Roles",
			"out",
			"in"
		]
	},
	"mm_Supports": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"V": {
		"mandatory": [],
		"optional": []
	},
	"ref_AttachmentTypes": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"vw_codip_HasEmplacement": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"ORole": {
		"mandatory": [
			"name"
		],
		"optional": [
			"rules",
			"inheritedRole",
			"mode"
		]
	},
	"lnk_HasAttachment": {
		"mandatory": [
			"Start",
			"out",
			"in"
		],
		"optional": [
			"Type",
			"End"
		]
	},
	"codip_Encart": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"lnk_HasCompany": {
		"mandatory": [
			"in",
			"Start",
			"out"
		],
		"optional": [
			"Contact",
			"End",
			"Account"
		]
	},
	"ref_Supports": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": [
			"Covers",
			"Medias",
			"Logo",
			"Description"
		]
	},
	"ref_BroadcastAreas": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Code",
			"Name"
		]
	},
	"OGeometryCollection": {
		"mandatory": [],
		"optional": [
			"geometries"
		]
	},
	"vw_adw_HasUser": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"_studio": {
		"mandatory": [],
		"optional": []
	},
	"ref_Media": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Name",
			"Code"
		]
	},
	"ref_AdvertisingCompanies": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"ref_TableConfigurations": {
		"mandatory": [
			"Table"
		],
		"optional": [
			"Sorts",
			"Name",
			"Columns",
			"Owner",
			"Default",
			"Active"
		]
	},
	"src_PIGE": {
		"mandatory": [
			"URI"
		],
		"optional": []
	},
	"vw_codip_HasPagination": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"OLineString": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"tsm_Format": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"lnk_HasCurrency": {
		"mandatory": [
			"Start"
		],
		"optional": [
			"End",
			"out",
			"in"
		]
	},
	"vw_pig_HasSupport": {
		"mandatory": [],
		"optional": [
			"Referential",
			"out",
			"in"
		]
	},
	"ref_SourceTypes": {
		"mandatory": [],
		"optional": [
			"Name"
		]
	},
	"lnk_ChangeRate": {
		"mandatory": [
			"Start"
		],
		"optional": [
			"in",
			"Rate",
			"End",
			"Company",
			"out"
		]
	},
	"adw_Supports": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Indicators": {
		"mandatory": [
			"Active"
		],
		"optional": []
	},
	"lnk_HasKPI": {
		"mandatory": [
			"Name",
			"out",
			"in"
		],
		"optional": [
			"ValueType",
			"Id",
			"ValueChoices",
			"KPI"
		]
	},
	"ref_AdvertisingCompanyRole": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"OMultiPoint": {
		"mandatory": [],
		"optional": [
			"coordinates"
		]
	},
	"vw_codip_HasEncart": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"vw_tsm_HasCouleur": {
		"mandatory": [],
		"optional": [
			"in",
			"out",
			"Referential"
		]
	},
	"OUser": {
		"mandatory": [
			"status",
			"name",
			"password"
		],
		"optional": [
			"roles"
		]
	},
	"OIdentity": {
		"mandatory": [],
		"optional": []
	},
	"ref_Regional": {
		"mandatory": [
			"Active"
		],
		"optional": [
			"Medias",
			"BroadcastAreas"
		]
	},
	"lnk_UserCreate": {
		"mandatory": [
			"Date"
		],
		"optional": [
			"out",
			"in"
		]
	},
	"ref_AgencyRole": {
		"mandatory": [
			"Name"
		],
		"optional": []
	},
	"Views": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Products": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"pig_Advertisers": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"codip_Pagination": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"Referentials": {
		"mandatory": [
			"Active"
		],
		"optional": []
	},
	"ref_Advertisers": {
		"mandatory": [
			"Active",
			"Name"
		],
		"optional": []
	},
	"ref_KPIValues": {
		"mandatory": [
			"Active",
			"Default",
			"KPI",
			"Value"
		],
		"optional": []
	},
	"vw_mm_HasSupport": {
		"mandatory": [],
		"optional": [
			"out",
			"in",
			"Referential"
		]
	},
	"ref_PropertyType": {
		"mandatory": [],
		"optional": [
			"Type"
		]
	},
	"vw_pig_HasAdvertiser": {
		"mandatory": [],
		"optional": [
			"Referential",
			"in",
			"out"
		]
	},
	"pig_AdvertisingCompanies": {
		"mandatory": [],
		"optional": [
			"Label",
			"ExternalID"
		]
	},
	"ref_Customers": {
		"mandatory": [],
		"optional": [
			"Active",
			"Country",
			"Authentication",
			"Licences",
			"End",
			"Billing",
			"Start",
			"Type",
			"Company",
			"Authorization",
			"MasterDataKey",
			"MailsDomain",
			"Vpn",
			"Sources"
		]
	},
	"ref_Modules": {
		"mandatory": [],
		"optional": [
			"Name"
		]
	}
}