import * as React from "react"
import { Format, GetKPITemplate } from "format-lib/index.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ref_Offers } from "hub-lib/models/ref_Offers.bin";
import { currencies } from "../MessageDetails";
import { store } from "../../../../redux/store";
import { ref_OffersSerializable } from "../../../../redux/messageEditorSlice";
import { FormatExtended } from "hub-lib/models/FormatExtended.bin";
import { EmplacementExtended } from "hub-lib/models/EmplacementExtended.bin";
import { TradValue } from "trad-lib";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";

export const getElementLabel = (elmnt: IRid, offer: Pick<ref_Offers, "KPIs" | "Currency">) => {
    const baseStr = Format(elmnt);
    const priceTmplt = GetKPITemplate(eKPIType.Price);
    return offer ? `${baseStr} - ${priceTmplt(offer.KPIs.Gross)} ${currencies?.find(c => c['@rid'] === offer.Currency)?.Code}` : baseStr;
}

const IsOfferFormatExtended = (offer: ref_OffersSerializable, format: IRid["@rid"], couleur: IRid["@rid"]): boolean => {
    return offer.ModelProperties.Format == format
        && ((!couleur/* && !offer.ModelProperties.Couleur*/) || offer.ModelProperties.Couleur == couleur);
}

export const getPriceExistsComponent = (offer: Pick<ref_Offers, "@rid">) =>
    offer ? <div className='check_price has_price' /> : <div className='check_price has_no_price' />;

export const getFormatPriceExistsComponent = (format: FormatExtended): React.ReactNode =>
    getPriceExistsComponent(getFormatOffer(format?.Format, format?.Couleur));

export const getFormatOffer = (format: IRid["@rid"], couleur: IRid["@rid"], placement?: IRid["@rid"], offers?: ref_OffersSerializable[]) => {
    if (placement === undefined)
        placement = store.getState().messageEditor.message.Placement;
    return (offers ?? store.getState().messageEditor.offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, format, couleur) && o.ModelProperties.Emplacement == placement);
}

export const getDefaultFormatOffer = (format: IRid["@rid"], couleur: IRid["@rid"], offers?: ref_OffersSerializable[]) => {
    return (offers ?? store.getState().messageEditor.offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, format, couleur) && !o.ModelProperties.Emplacement);
}
export const getLabelFormat = (format: FormatExtended) =>
    TradValue(ref_Property.name, "Name", getElementLabel(format, getDefaultFormatOffer(format?.Format, format?.Couleur)));


export const getPlacementOffer = (placement: IRid["@rid"], offers?: ref_OffersSerializable[]) =>
    (offers ?? store.getState().messageEditor.offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, store.getState().messageEditor.message.Format, store.getState().messageEditor.message.ModelProperties["Couleur"])
            && o.ModelProperties.Emplacement == placement);

export const getPlacementPriceExistsComponent = (placement: EmplacementExtended) =>
    getPriceExistsComponent(getPlacementOffer(placement?.Emplacement));

export const getLabelPlacement = (placement: IRid) =>
    TradValue(ref_Property.name, "Name", getElementLabel(placement, getPlacementOffer(placement["@rid"])));
