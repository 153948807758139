import { GetCurrentLocale, Trad } from "trad-lib";

export function GetFirstDayOfYear() {
    let today = new Date();
    let firstDay = new Date('2020-01-01');
    firstDay.setFullYear(today.getFullYear());
    return firstDay;
}

export function GetLastDayOfYear() {
    let today = new Date();
    let firstDay = new Date('2020-12-31');
    firstDay.setFullYear(today.getFullYear());
    return firstDay;
}

export function GetToday(): Date {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // Return array of year and week number
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

export function getUniqueWeekNumbers(startDate: Date, endDate: Date): number[] {
    let currentDate = new Date(startDate);
    const weekNumbers = new Set<number>();

    // Assurez-vous d'inclure la semaine de la endDate
    weekNumbers.add(getWeekNumber(endDate));

    while (currentDate < endDate) {
        weekNumbers.add(getWeekNumber(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    return Array.from(weekNumbers);
}

function getFirstDayWeek(date: Date): Date {
    const jour = date.getDay(); // Récupérer le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
    const diff = date.getDate() - jour + (jour === 0 ? -6 : 1); // Calculer la différence pour obtenir le premier jour de la semaine
    const premierJourSemaine = new Date(date); // Créer une copie de la date d'origine
    premierJourSemaine.setDate(diff); // Définir la date sur le premier jour de la semaine

    return premierJourSemaine;
  }

export function getUniqueWeekYears(startDate: Date, endDate: Date): string[] {
    let currentDate = new Date(startDate);
    const weekNumbers = new Set<string>();

    // Generate Key composed of week number and the date of the first day of the week
    const generateKeyWeek = (d: Date) => getWeekNumber(d)?.toString() + "-" + FormatDate(getFirstDayWeek(d));

    // Assurez-vous d'inclure la semaine de la endDate
    weekNumbers.add(generateKeyWeek(endDate));

    while (currentDate < endDate) {
        weekNumbers.add(generateKeyWeek(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    return Array.from(weekNumbers);
}


export function getSemesterNumber(d: Date) {
    const month = d.getMonth();
    if (month < 6)
        return 0;
    return 1;
}

export function getTrimesterNumber(d: Date) {
    const month = d.getMonth();
    if (month < 3) return 0;
    if (month < 6) return 1;
    return 2;
}

export function getMonday(d: Date) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function DateNoZone(date) {

    if (!date || typeof date !== "string")
        return date;

    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}
export function DateZone(date: any): Date {

    if (!date || typeof date !== "string")
        return date;

    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
}

export function DateNoTime(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

const check = (d) => (['number', 'string'].includes(typeof d)) ? new Date(d) : d;
export function IsIntersec(s1: Date, e1: Date, s2: Date, e2: Date, strict: boolean = false) {
    s1 = check(s1); e1 = check(e1); s2 = check(s2); e2 = check(e2);
    const s1Time = s1.getTime();
    const e1Time = e1.getTime();
    const s2Time = s2.getTime();
    const e2Time = e2.getTime();
    if (!strict)
        return e2Time >= s1Time && e1Time >= s2Time;
    return (e2Time > s1Time && e1Time >= s2Time) || (e2Time >= s1Time && e1Time > s2Time);
}

export function isBetween(date: Date, start: Date, end: Date) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}

export function AddDays(currentDate: Date, days: number) {
    return new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + days));
}

export function GetDateRanges(start: Date, end: Date, split: "week" | "month" | "trimester" | "semester") {
    let current = new Date(start);
    end = new Date(end);
    let currentRange = { start: current, end: undefined };

    let getIndex: (d: Date) => number = null;
    switch (split) {
        case "week":
            getIndex = (d: Date) => getWeekNumber(d);
            break;

        case "month":
            getIndex = (d: Date) => d.getMonth();
            break;

        case "trimester":
            getIndex = (d: Date) => getTrimesterNumber(d);
            break;

        case "semester":
            getIndex = (d: Date) => getSemesterNumber(d);
            break;

        default:
            throw new Error(`unknown split time: ${split}`)
    }

    let currentIndex = getIndex(current);

    const ranges: { start: Date, end: Date }[] = [];

    while (current.getTime() < end.getTime()) {
        const previous = current;
        current = new Date(current.getTime() + 60 * 60 * 24 * 1000);
        const newIndex = getIndex(current);
        if (newIndex != currentIndex || current.getTime() >= end.getTime()) {
            currentRange.end = current.getTime() >= end.getTime() ? end : previous;
            ranges.push(currentRange);
            currentIndex = newIndex;
            currentRange = {
                start: current,
                end: undefined
            }
        }
    }

    return ranges;
}

export function FormatDate(inputFormat, includeTime: boolean = false) {
    // function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    let str = d.toLocaleDateString(GetCurrentLocale());
    if (includeTime) {
        str += ' ' + d.toLocaleTimeString(GetCurrentLocale());
    }
    return str;
}

export function GetNbDays(begin: Date, end: Date) {
    return Math.round((end.getTime() - begin.getTime()) / 1000 / 60 / 60 / 24);
}

export function getMonthName(month: string, short?: boolean): string {
    let name = Trad(`month_${Number(month) - 1}`);
    if (name.length > 5 && short) {
        if (name === "Juillet") {
            name = name.substring(0, 5);
        } else {
            name = name.substring(0, 3);
        }
    }
    return name;
}

export function setEndOfMonth(date: Date) {
    date.setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
    date.setHours(23, 59, 59, 999);
}